<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
  watch,
} from 'vue';
import api from '@/api';
import { MAX_ORGANIZATION_TAG_NAME_LENGTH } from '@/constants';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import { OrganizationTag, OrganizationTagList } from '@/types';
import { organizationTagValidate } from '@/utils/validators';

export default defineComponent({
  components: {},
  props: {
    isOpen: { type: Boolean, required: true },
    organizationTag: {
      type: Object as PropType<OrganizationTag>,
    },
    organizationTagNames: {
      type: Object as PropType<OrganizationTagList>,
      required: true,
    },
  },
  emits: {
    onClose: () => true,
    onSubmit: () => true,
  },
  setup(props, { emit }) {
    const { createSnackbar } = useSnackbar();

    const organizationTagName = ref('');
    const organizationTags = ref<OrganizationTagList>();

    onMounted(() => {
      organizationTags.value = props.organizationTagNames;
      organizationTagName.value = props.organizationTag?.name || '';
    });

    watch(
      () => props.isOpen,
      () => {
        if (props.isOpen) {
          organizationTags.value = props.organizationTagNames;
          organizationTagName.value = props.organizationTag?.name || '';
        }
      },
    );

    const currentValidate = computed(() => {
      return organizationTagValidate(
        organizationTagName.value,
        props.organizationTagNames,
        props.organizationTag,
      );
    });

    const close = () => {
      organizationTagName.value = '';
      emit('onClose');
    };

    const onSubmit = async () => {
      try {
        if (organizationTagName.value !== props.organizationTag?.name) {
          const targetOrgTag = organizationTags.value?.find(
            organizationTag => organizationTag.id === props.organizationTag?.id,
          );
          if (!targetOrgTag) throw new Error('targetOrgTag is undefined');
          await api.bulkOperateOrganizationTags({
            updateTags: [
              {
                id: targetOrgTag.id,
                name: organizationTagName.value,
              },
            ],
          });
        }
        emit('onSubmit');
        createSnackbar({
          message: '組織タグ名を変更しました',
          type: 'success',
        });
      } catch {
        createSnackbar({
          message: '組織タグ名の変更に失敗しました',
          type: 'error',
        });
      }
      close();
    };

    return {
      close,
      onSubmit,
      organizationTagName,
      MAX_ORGANIZATION_TAG_NAME_LENGTH,
      currentValidate,
    };
  },
});
</script>

<template>
  <Teleport to="body">
    <sm-dialog
      v-if="isOpen"
      class="no-padding screen-center"
      :show-header="true"
      @close="close"
    >
      <template #header>
        <p>組織タグ名の編集</p>
      </template>
      <template #body>
        <div class="modal-body">
          <div class="modal-content">
            <p class="modal-input-label c-text c-text--m">組織タグ名</p>
            <input
              class="c-textInput"
              :class="{
                'c-formInput--error': currentValidate !== undefined,
              }"
              v-model.trim="organizationTagName"
              type="text"
              placeholder="組織タグ名を入力してください"
              data-testid="organization-tag-name-input"
            />
            <div class="modal-input-desc">
              <div class="modal-error-texts" v-if="currentValidate">
                <span data-testid="organization-tag-name-error-message">
                  {{ currentValidate }}
                </span>
              </div>
              <div
                class="modal-text-counter"
                :class="{ 'modal-error-texts': currentValidate }"
              >
                <span data-testid="organization-tag-name-counter">
                  {{
                    organizationTagName.length +
                    '/' +
                    MAX_ORGANIZATION_TAG_NAME_LENGTH
                  }}
                </span>
              </div>
            </div>
          </div>
          <div class="modal-btns-container c-text c-text--m">
            <button
              type="button"
              class="o-cancel-button c-btn c-btn--auto c-btnOutline"
              data-testid="cancel-button"
              @click="close"
            >
              キャンセル
            </button>
            <button
              type="button"
              class="o-create-button c-btn c-btn--auto c-btn--AnewsPrimary"
              :class="{ disabled: currentValidate !== undefined }"
              :disabled="currentValidate !== undefined"
              data-testid="submit-button"
              @click="onSubmit()"
            >
              保存
            </button>
          </div>
        </div>
      </template>
    </sm-dialog>
  </Teleport>
</template>
<style lang="scss" scoped>
.modal-body {
  padding: 16px;
  input {
    outline: none;
  }
  .modal-btns-container {
    display: flex;
    justify-content: flex-end;

    :first-child {
      margin-right: 16px;
    }
  }

  .modal-input-label {
    margin-bottom: 4px;
  }
  .modal-input-desc {
    display: flex;
    justify-content: flex-end;
    font-size: 10px;
    margin-top: 4px;
    margin-bottom: 25px;
    color: $color-gray600;

    .modal-text-counter {
      margin-left: 5px;
    }
    .modal-error-texts {
      color: $color-orange1000;
    }
  }

  .o-create-button.disabled {
    border: none;
  }
}
</style>
