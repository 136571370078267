<script lang="ts">
import { defineComponent, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import SnackbarContainer from '@/components/common/snackbar/snackbar-container.vue';
import { gaTracker } from '@/utils/ga';
import { useGTag } from '@/utils/vue';
import { provideSnackbar } from './common/snackbar/use-snackbar';

export default defineComponent({
  setup() {
    const route = useRoute();

    const setMetaDescription = () => {
      const metaDesc = route.meta.desc ? route.meta.desc.toString() : '';
      document
        .querySelector("meta[name='description']")
        ?.setAttribute('content', metaDesc);
    };
    // Snackbarの設定
    provideSnackbar();

    onMounted(async () => {
      setMetaDescription();
      const gTag = useGTag();
      gaTracker.config(gTag);
    });

    watch(
      () => route.path,
      () => setMetaDescription(),
    );
  },
  components: {
    SnackbarContainer,
  },
});
</script>

<template>
  <div class="p-pc-app">
    <router-view></router-view>
  </div>
  <SnackbarContainer />
</template>

<style lang="scss" scoped></style>
