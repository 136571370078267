<script setup lang="ts">
import { DgrIcon, IconTypes } from '@stockmarkteam/donguri-ui';

type Props = {
  title: string;
  leftIcon?: IconTypes;
  rightIcon?: IconTypes;
};

const props = defineProps<Props>();
</script>

<template>
  <div class="title-content">
    <span class="icon">
      <slot name="titleIconLeft">
        <DgrIcon :name="leftIcon" v-if="leftIcon" />
      </slot>
    </span>
    <span class="title-text">
      <slot name="titleText">
        {{ props.title }}
      </slot>
    </span>
    <span class="icon">
      <slot name="titleIconRight">
        <DgrIcon :name="rightIcon" v-if="rightIcon" />
      </slot>
    </span>
  </div>
</template>

<style lang="scss" scoped>
.title-content {
  display: inline-block;
  .title-text {
    vertical-align: middle;
  }
  .icon {
    vertical-align: middle;
    display: inline-block;
  }
}
</style>
