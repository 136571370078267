<script lang="ts">
import { computed, defineComponent, PropType, ref, toRefs } from 'vue';
import { RouteLocationRaw } from 'vue-router';
import { DgrCheckbox } from '@stockmarkteam/donguri-ui';
import { AdminTheme } from '@/types';
import { useVirtualScroll } from '@/utils/composables/virtualScroll/useVirtualScroll';
import { formatDate } from '@/utils/formatters';
import { useGroups } from '@/utils/swr';

type Field = {
  name: string;
  displayName: string;
  type: string;
  feedType?: 'domestic' | 'foreign';
  linkTo?: (theme: AdminThemeWithCreator) => RouteLocationRaw;
  hasTooltip?: boolean;
  width?: number;
};
type AdminThemeWithCreator = AdminTheme & { creator_name: string };

export default defineComponent({
  components: {
    DgrCheckbox,
  },
  props: {
    data: { type: Array as PropType<AdminThemeWithCreator[]>, required: true },
  },
  emits: {
    'theme-selected': (_themeIds: number[]) => true,
  },
  setup(props, context) {
    const { data: tableData } = toRefs(props);
    const { data: groupList } = useGroups();
    const groupNameMap = computed(
      () => new Map(groupList.value?.groups.map(g => [g.id, g.name]) ?? []),
    );

    const headerFields: Field[] = [
      {
        name: 'name',
        displayName: 'テーマ名',
        type: 'string',
        linkTo: theme => ({
          name: 'themeEdit',
          params: { themeId: String(theme.id) },
        }),
        hasTooltip: true,
      },
      {
        name: 'access_scope',
        displayName: '公開範囲',
        type: 'string',
      },
      {
        name: 'group_name',
        displayName: 'グループ名',
        type: 'string',
        hasTooltip: true,
      },
      {
        name: 'member_count',
        displayName: 'メンバー数',
        type: 'number',
        linkTo: theme => ({
          name: 'themeAdminMembers',
          params: { themeId: String(theme.id) },
        }),
      },
      {
        name: 'description',
        displayName: '説明',
        type: 'string',
        hasTooltip: true,
      },
      {
        name: 'feed_count_average_domestic',
        displayName: '配信数（国内）',
        type: 'number',
        feedType: 'domestic',
      },
      {
        name: 'feed_count_average_foreign',
        displayName: '配信数（海外）',
        type: 'number',
        feedType: 'foreign',
      },
      {
        name: 'view_count',
        displayName: '閲覧数',
        type: 'number',
      },
      {
        name: 'mark_count',
        displayName: 'マーク数',
        type: 'number',
      },
      {
        name: 'creator_name',
        displayName: '作成者',
        type: 'string',
      },
      {
        name: 'created_at',
        displayName: '作成日',
        type: 'date',
      },
    ];

    const formatField = (field: Field, theme: AdminThemeWithCreator) => {
      const fieldName = field.name as keyof AdminThemeWithCreator;
      if (field.type === 'date') {
        return formatDate(theme[fieldName] as string);
      }
      if (field.name === 'description') {
        return theme.description === '' ? '-' : theme[field.name];
      }
      if (field.name === 'access_scope') {
        return {
          public: '全体',
          group: 'グループ',
          personal: '個人',
        }[theme.access_scope];
      }
      if (field.name === 'group_name') {
        return groupNameMap.value.get(theme.group_id ?? -1) ?? '';
      }
      if (
        field.name.startsWith('feed_count_average') &&
        theme.feed_count_average
      ) {
        return field.feedType
          ? theme.feed_count_average[field.feedType]
          : undefined;
      }
      if (field.type === 'number' && (theme[fieldName] as number) >= 100000) {
        return '99,999+';
      } else if (field.type === 'number') {
        return (theme[fieldName] as number).toLocaleString();
      }
      return theme[fieldName];
    };

    const sortData = (field: Field, order: 'asc' | 'desc') => {
      const fieldName = field.name as keyof AdminThemeWithCreator;
      const orderMultiplier = order === 'asc' ? 1 : -1;
      return [...tableData.value].sort((a, b) => {
        const fieldA = field.name in a ? a[fieldName] : formatField(field, a);
        const fieldB = field.name in b ? b[fieldName] : formatField(field, b);
        if (['string', 'date', 'link'].includes(field.type)) {
          return (
            orderMultiplier * (fieldA as string).localeCompare(fieldB as string)
          );
        } else {
          return orderMultiplier * ((fieldA as number) - (fieldB as number));
        }
      });
    };
    const sortField = ref(headerFields[0]);
    const sortOrder = ref('asc' as 'asc' | 'desc');
    const sort = (fieldName: string) => {
      const field = headerFields.find(f => f.name === fieldName);
      if (!field) return;
      if (fieldName === sortField.value.name) {
        sortOrder.value = sortOrder.value === 'asc' ? 'desc' : 'asc';
      } else {
        sortField.value = field;
        sortOrder.value = 'asc';
      }
    };
    const sortedData = computed(() =>
      sortData(sortField.value, sortOrder.value),
    );

    const selectedThemes = ref<number[]>([]);
    const selectedAll = computed({
      get: () => selectedThemes.value.length === tableData.value.length,
      set: isSelected => {
        if (isSelected) {
          selectedThemes.value = tableData.value.map(t => t.id) ?? [];
        } else {
          selectedThemes.value = [];
        }
        context.emit('theme-selected', selectedThemes.value);
      },
    });
    const selectTheme = (themeId: number) => {
      if (selectedThemes.value.includes(themeId)) {
        selectedThemes.value = selectedThemes.value.filter(t => t !== themeId);
      } else {
        selectedThemes.value.push(themeId);
      }
      context.emit('theme-selected', selectedThemes.value);
    };
    const sortedDataCount = computed(() => sortedData.value.length);
    const { scrollableElement, virtualRows, before, after } = useVirtualScroll({
      getCount: () => sortedDataCount.value,
      estimateSize: () => 57,
      overscan: 10,
    });

    return {
      headerFields,
      formatField,
      sort,
      sortField,
      sortOrder,
      sortedData,
      selectedAll,
      selectedThemes,
      selectTheme,
      scrollableElement,
      virtualRows,
      before,
      after,
    };
  },
});
</script>

<template>
  <div class="o-theme-table" ref="scrollableElement">
    <div class="theme-table-wrapper">
      <table class="theme-table" cellspacing="0">
        <thead class="sticky-header">
          <tr>
            <th>
              <span class="c-text c-text--xs">すべて</span>
              <DgrCheckbox v-model="selectedAll" class="checkbox" />
            </th>
            <th
              v-for="field in headerFields"
              :key="field.name"
              class="c-text c-text--s"
              :style="{ width: field.width ? `${field.width}px` : 'auto' }"
              :class="{ default: field.displayName === '' }"
              @click="sort(field.name)"
            >
              {{ field.displayName
              }}<span
                class="sort-icon"
                v-if="sortField.name === field.name && sortOrder == 'asc'"
                >↑</span
              ><span
                class="sort-icon"
                v-else-if="sortField.name === field.name && sortOrder == 'desc'"
                >↓</span
              >
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-if="before"
            :colspan="headerFields.length"
            :style="{ height: `${before}px` }"
          ></tr>
          <tr
            v-for="virtualRow in virtualRows"
            :key="virtualRow.key"
            :style="{
              height: `${virtualRow.size}px`,
            }"
          >
            <td>
              <DgrCheckbox
                class="checkbox"
                :model-value="
                  selectedThemes.includes(sortedData[virtualRow.index].id)
                "
                @update:model-value="
                  () => selectTheme(sortedData[virtualRow.index].id)
                "
              />
            </td>
            <td v-for="field in headerFields" :key="field.name">
              <VTooltip
                placement="bottom"
                :disabled="
                  !(
                    field.hasTooltip &&
                    formatField(field, sortedData[virtualRow.index]) !== '-'
                  )
                "
              >
                <div :class="{ 'name-wrapper': field.name === 'name' }">
                  <router-link
                    v-if="
                      field.linkTo ||
                      (field.name === 'name' && field.type === 'link')
                    "
                    :class="`link c-text c-text--m ${field.name} ${field.type}`"
                    :to="
                      field.linkTo
                        ? field.linkTo(sortedData[virtualRow.index])
                        : {
                            path: `/admin/themes/${sortedData[virtualRow.index].id}/members`,
                          }
                    "
                  >
                    {{ formatField(field, sortedData[virtualRow.index]) }}
                  </router-link>
                  <span
                    v-if="
                      sortedData[virtualRow.index].is_initial_theme &&
                      field.name === 'name'
                    "
                    class="tag c-outlineTag c-outlineTag--small"
                    >初期表示</span
                  >
                  <router-link
                    v-if="
                      field.name === 'move_to_detail' && field.type === 'link'
                    "
                    class="primary-link c-text c-text--s"
                    :to="{
                      path: `/themes/${sortedData[virtualRow.index].id}/edit`,
                    }"
                  >
                    設定
                  </router-link>
                  <div
                    :class="`c-text c-text--m ${field.name} ${field.type}`"
                    v-if="field.type !== 'link' && !field.linkTo"
                  >
                    {{ formatField(field, sortedData[virtualRow.index]) }}
                  </div>
                </div>
                <template #popper>
                  <div>
                    {{ formatField(field, sortedData[virtualRow.index]) }}
                  </div>
                </template>
              </VTooltip>
            </td>
          </tr>
          <tr
            v-if="after"
            :colspan="headerFields.length"
            :style="{ height: `${after}px` }"
          ></tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style lang="scss" scoped>
div.o-theme-table {
  height: calc(
    100vh - 162px
  ); // ヘッダーからテーブルトップまでスクロールできるように調整
  max-height: 100%;
  overflow: auto;
  border: 1px solid $color-border;
  border-radius: 4px;
}
.theme-table-wrapper {
  max-width: 100%;
  .theme-table {
    width: 100%;
    background: #fff;
    box-sizing: border-box;
    padding: 0px 16px 16px 16px;

    .sticky-header th {
      position: sticky;
      top: 0;
      z-index: 1;
      background: #fff;
    }

    tr th,
    tr td {
      white-space: nowrap;
    }
    th,
    td {
      padding: 8px 16px 8px 0;
      text-align: left;
      border-bottom: 1px solid #e6e6e6;
    }
    td {
      height: 40px;
    }
    th {
      cursor: pointer;
      padding-top: 20px;
      padding-bottom: 16px;
      &:hover {
        background: $color-gray200;
      }
    }
    tbody tr {
      &:hover {
        background: $color-gray200;
      }
    }
    tr:last-child td {
      border-bottom: 0;
    }
    .sort-icon {
      padding: 0 4px;
    }
    .checkbox {
      display: block;
      width: 16px;
      margin: 0 auto;
    }
    .name,
    .group_name,
    .description,
    .creator_name {
      display: inline-block;
      width: 160px;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
    .name-wrapper {
      display: flex;
      align-items: center;
      width: 218px; // テーマが初期表示設定された場合に表示されるアイコン分の幅も確保しておく
    }
    .access_scope {
      width: 84px;
    }
    td .number {
      width: 100%;
      text-align: right;
    }
    .tag {
      display: initial;
      white-space: pre;
    }
    .primary-link {
      color: #1da482;
      &:hover {
        text-decoration: underline;
      }
    }
    .link {
      text-decoration: underline;
    }
    .default {
      cursor: default;
    }
  }
}
</style>
