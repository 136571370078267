<script lang="ts">
import { computed, defineComponent, nextTick, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import api from '@/api';
import { updateSelectedIndustryId } from '@/api/user';
import { DgrSelectbox } from '@stockmarkteam/donguri-ui';
import AdpDocumentCard from '@/components/common/adp-document-card.vue';
import BasicEmptyFeed from '@/components/common/basic-empty-feed.vue';
import Content from '@/components/layouts/content.vue';
import Header from '@/components/layouts/header.vue';
import { IndustryArticle, Topic } from '@/types';
import { useIndustries, useIndustryArticles } from '@/utils/swr';
import { useStore } from '@/utils/vue';

export default defineComponent({
  components: {
    AdpDocumentCard,
    Header,
    Content,
    BasicEmptyFeed,
    DgrSelectbox,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const { data } = useIndustries();
    const routeTopicId = route.query.topicId;

    const industries = computed(() => {
      return data.value?.industries ?? [];
    });

    const userInfo = computed(() => store.state.userInfo.userInfo);

    const currentIndustry = computed(() =>
      industries.value.find(i => i.id === userInfo.value?.industry_id),
    );
    const currentIndustryId = computed({
      get: () => currentIndustry.value?.id,
      set: async (value: number) => {
        await updateSelectedIndustryId(value);
        store.commit('userInfo/setUserIndustryId', value);
      },
    });

    const { data: articlesData } = useIndustryArticles(currentIndustryId);

    const articles = computed(() => {
      const articles = articlesData.value?.industry_articles.slice() ?? [];
      let rank = 1;
      articles.forEach((article: IndustryArticle, index) => {
        if (index === 0) {
          article.rank = rank;
        } else if (articles[index - 1].topic_id === articles[index].topic_id) {
          rank++;
          article.rank = rank;
        } else {
          rank = 1;
          article.rank = rank;
        }
      });
      return articles;
    });

    const totalArticleCount = computed(() => articles.value.length);

    const feedType = computed(() => store.state.feedType.feedType);

    const topics = computed(() => {
      return (
        currentIndustry.value?.topics.filter(
          topic => topic.article_count > 0,
        ) ?? []
      );
    });

    const topicArticles = computed<[Topic, IndustryArticle[]][]>(() =>
      topics.value.map(t => [
        t,
        articles.value.filter(a => a.topic_id === t.id),
      ]),
    );

    const selectOptions = computed(
      () =>
        industries.value?.map(a => ({
          value: a.id,
          label: a.name,
        })) ?? [],
    );

    const getIndustryTrackingData = (topicId: number, rank: number) => {
      return {
        id: currentIndustryId.value ?? 0,
        topic: { id: topicId, rank: rank },
      };
    };

    const topicRefs = ref<HTMLElement[] | undefined>();
    const scrollToTopic = (topicId: number) => {
      const topicEl = topicRefs.value?.find(
        r => Number(r.dataset.topicId) === topicId,
      );
      if (!topicEl) return;
      nextTick(() => {
        const headerOffset = 112;
        const targetPosition =
          topicEl.getBoundingClientRect().top +
          window.pageYOffset -
          headerOffset;
        window.scrollTo({ top: targetPosition, behavior: 'smooth' });
      });
    };

    onMounted(async () => {
      await api.trackPageView({
        pageName: 'industry_news',
        pageUrl: route.fullPath,
      });
      if (routeTopicId) {
        scrollToTopic(Number(routeTopicId));
      }
    });

    const noFeedMessage = computed(() =>
      feedType.value === 'foreign'
        ? '現在、業界ニュースは海外メディアの対応を行なっておりません。'
        : currentIndustryId.value === undefined
          ? '現在、業界が未選択になっています。'
          : 'この業界のニュースはありませんでした。',
    );

    return {
      totalArticleCount,
      topicArticles,
      currentIndustryId,
      selectOptions,
      topicRefs,
      scrollToTopic,
      feedType,
      topics,
      currentIndustry,
      getIndustryTrackingData,
      noFeedMessage,
    };
  },
});
</script>

<template>
  <div class="industry-news">
    <Header
      title="業界ニュース"
      detail="業界別におすすめニュースをAIがピックアップしてお届けします"
      :is-one-line="true"
    ></Header>
    <Content>
      <div class="body">
        <div class="select-industry">
          <div class="c-text c-text--m">業界選択</div>
          <div class="spacing-08"></div>
          <DgrSelectbox
            placeholder="未選択"
            :options="selectOptions"
            v-model="currentIndustryId"
            size="small"
          ></DgrSelectbox>
        </div>
        <div class="spacing-16"></div>
        <div v-if="currentIndustry && feedType !== 'foreign'">
          <div class="topics" v-if="topics.length > 0">
            <div
              class="topic c-text c-text--m"
              v-for="topic in topics"
              :key="topic.id"
              @click="scrollToTopic(topic.id)"
            >
              {{ `${topic.name}(${topic.article_count})` }}
            </div>
          </div>
          <div class="spacing-16"></div>
          <div
            class="topic-articles"
            v-for="[topic, articles] in topicArticles"
            :key="`${topic.id}`"
          >
            <div
              ref="topicRefs"
              :data-topic-id="topic.id"
              class="topic topic-position"
            >
              <div class="spacing-16"></div>
              <div class="topic-name">
                <div class="spacing-08"></div>
                <div class="c-text c-text--l">
                  {{ topic.name }}
                </div>
              </div>
              <div class="spacing-04"></div>
              <div class="pick-up c-text c-text--m">
                {{ `「${topic.query}」に該当する記事からピックアップ` }}
              </div>
              <div class="spacing-12"></div>
            </div>
            <div v-for="article in articles" :key="article.id">
              <div class="divider"></div>
              <AdpDocumentCard
                width="100%"
                class="article-card"
                :key="`${topic.id}-${article.id}`"
                page-name="industry_news"
                :adp-document="article"
                :show-comment-count="1"
                :case-study-text="article.case_study_text"
                :trend-company-text="article.trend_company_text"
                :disable-related-articles="false"
                :industry-data="
                  getIndustryTrackingData(article.topic_id, article.rank)
                "
                feature="industry_news"
              ></AdpDocumentCard>
            </div>
          </div>
        </div>
        <div
          class="o-no-industry-articles"
          v-if="
            totalArticleCount === 0 ||
            currentIndustry === undefined ||
            feedType === 'foreign'
          "
        >
          <BasicEmptyFeed>{{ noFeedMessage }}</BasicEmptyFeed>
        </div>
      </div>
    </Content>
  </div>
</template>
<style lang="scss" scoped>
.industry-news {
  width: 100%;
  margin: -24px 0 0 0;
  .body {
    width: 616px;
  }
  .select-industry {
    display: flex;
    align-items: center;
  }
  .heading-text {
    display: flex;
    .count {
      color: $color-gray600;
    }
  }
  .icon {
    margin-left: 4px;
  }
  .o-no-industry-articles-v1 {
    width: 616px;
    flex-direction: row;
    justify-content: space-between;
    background: #ffffff;
    border: 1px solid #e6e6e6;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 16px;
    margin-top: 16px;
    .o-textarea-explanation {
      margin-bottom: 12px;
    }
    .o-link-to-home {
      color: #1da482;
    }
  }
  .topics {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .topic {
      color: #1da482;
      margin-right: 16px;
      cursor: pointer;
    }
  }
  .topic-articles {
    position: relative;
    background: #fff;
    padding: 0 16px;

    .document-card {
      border: 0;
    }

    .divider {
      width: auto;
      border-bottom: 0.5px solid $color-gray400;
      margin: 0px 16px 0px 16px;
    }

    .topic {
      &.topic-position {
        position: sticky;
        top: 135px;
        background: #fff;
        z-index: 1;
      }
      .topic-name {
        display: flex;
        align-items: center;
      }
    }
    .article-card {
      margin-bottom: 10px;
    }
  }

  .pick-up {
    color: $color-gray800;
  }
}
</style>
