<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { NotificationFirstPersonalNewsType } from '@/types';
import CardTemplate from './card-template.vue';

export default defineComponent({
  components: {
    CardTemplate,
  },
  props: {
    notification: {
      type: Object as PropType<NotificationFirstPersonalNewsType>,
      required: true,
    },
    isUnread: { type: Boolean, required: true },
  },
  setup() {
    return {};
  },
});
</script>
<template>
  <CardTemplate
    :type="notification.type"
    :url="'/personal-news'"
    :is-unread="isUnread"
    :created-at="notification.created_at"
  >
    パーソナルニュースの配信を開始しました。
  </CardTemplate>
</template>
