import { AdpDocument, Group } from '@/types';

export function sortMarkArticles(articles: AdpDocument[]) {
  return articles.slice().sort((a1, a2) => {
    const a1Marks = a1.marks;
    const a2Marks = a2.marks;
    if (a1Marks.length === 0 || a2Marks.length === 0) return 1;
    const a1LatestCommentDate = a1Marks[a1Marks.length - 1].created_at;
    const a2LatestCommentDate = a2Marks[a2Marks.length - 1].created_at;
    return Date.parse(a2LatestCommentDate) - Date.parse(a1LatestCommentDate);
  });
}

export function sortActiveGroups(
  groups: Group[],
  field: keyof Pick<
    Group & { share_count: number },
    'name' | 'created_at' | 'member_count' | 'share_count'
  >,
  order: 'asc' | 'desc',
): Group[] {
  const orderMultiplier = order === 'asc' ? 1 : -1;
  const targetGroupTuples = groups.map<[Group, string | number]>(g => {
    if (field === 'share_count') {
      return [g, g.user_comment_count + g.user_group_mark_count];
    }
    return [g, g[field]];
  });
  const sortedGroups = targetGroupTuples
    .sort((a, b) => {
      const comparisonResult =
        orderMultiplier *
        String(a[1]).localeCompare(String(b[1]), undefined, {
          numeric: true,
        });
      if (comparisonResult === 0) {
        return a[0].id - b[0].id;
      }
      return comparisonResult;
    })
    .map(tuple => tuple[0]);
  return [
    ...sortedGroups.filter(g => g.group_type === 'all_user_group'),
    ...sortedGroups.filter(g => g.group_type !== 'all_user_group'),
  ];
}
