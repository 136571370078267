<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import api from '@/api';
import { DgrIcon } from '@stockmarkteam/donguri-ui';
import { ThemeSearch, TrackingThemeSearchParams } from '@/types';
import { useTeamUsers, useUserInfo } from '@/utils/swr';

export type UpdateThemeSearchPayload = {
  id: ThemeSearch['id'];
  type: 'add' | 'delete';
};

export default defineComponent({
  components: {
    DgrIcon,
  },
  props: {
    themeSearch: { type: Object as PropType<ThemeSearch>, required: true },
    trackThemeSearchParams: {
      type: Object as PropType<TrackingThemeSearchParams>,
      required: true,
    },
    themeSearchIndex: { type: Number, required: true },
    pageUrl: { type: String, required: true },
  },
  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updateThemeSearch: (_payload: UpdateThemeSearchPayload) => true,
  },
  setup(props, { emit }) {
    const { data: userInfo } = useUserInfo();
    const { data: teamUsers } = useTeamUsers();

    const createUser = computed(() => {
      const targetUser = teamUsers.value?.users.find(
        user => user.id === props.themeSearch?.creator_id,
      );
      return targetUser ?? null;
    });

    const btnText = computed(() => {
      let text = '';
      if (props.themeSearch.is_member) {
        text = '解除';
      } else if (
        props.themeSearch.group &&
        !props.themeSearch.group.is_member
      ) {
        text = 'グループに参加して追加';
      } else {
        text = '追加';
      }
      return text;
    });

    const isButtonVisible = computed(() => {
      return (
        props.themeSearch.is_member ||
        props.themeSearch.access_scope !== 'personal' ||
        (props.themeSearch.access_scope === 'personal' &&
          props.themeSearch.user_id === userInfo.value?.id)
      );
    });

    const submit = async () => {
      if (props.themeSearch.is_member) {
        emit('updateThemeSearch', { id: props.themeSearch.id, type: 'delete' });
        await themeSearchTrackEvent('remove_theme');
      } else {
        emit('updateThemeSearch', { id: props.themeSearch.id, type: 'add' });
        await themeSearchTrackEvent('add_theme');
      }
    };

    const themeSearchTrackEvent = async (name: string) => {
      const params = {
        rank: props.themeSearchIndex + 1,
        theme: { id: props.themeSearch.id },
        search: props.trackThemeSearchParams,
      };
      await api.trackEvent(
        name,
        {
          pageName: 'theme_search',
          pageUrl: props.pageUrl,
        },
        undefined,
        undefined,
        params,
      );
    };

    return {
      createUser,
      btnText,
      submit,
      isButtonVisible,
      themeSearchTrackEvent,
    };
  },
});
</script>

<template>
  <router-link
    :to="`/themes/${themeSearch.id}`"
    custom
    v-slot="{ href, navigate }"
  >
    <a
      :href="href"
      @click="
        event => {
          themeSearchTrackEvent('view_theme');
          navigate(event);
        }
      "
      class="m-theme-search-card"
    >
      <div class="theme-content">
        <div class="theme-name c-title c-title--m">
          # {{ themeSearch.name }}
          <div class="added" v-if="themeSearch.is_member">
            <DgrIcon
              size="xs"
              name="check"
              :keep-fill="false"
              class="primary icon"
            /><span class="c-text c-text--xs">追加済み</span>
          </div>
        </div>
        <div class="theme-info c-text c-text--s">
          <span class="public" v-if="themeSearch.access_scope === 'public'">
            全体
          </span>
          <template
            v-if="themeSearch.access_scope === 'group' && themeSearch.group"
          >
            <DgrIcon
              size="small"
              :keep-fill="false"
              name="users"
              class="small group-icon"
            />
            <router-link class="link" :to="`/groups/${themeSearch.group.id}`">
              {{ themeSearch.group.name }}</router-link
            ><span v-if="!themeSearch.group.is_member">(未参加)</span>
          </template>
          <span class="personal" v-if="themeSearch.access_scope === 'personal'">
            個人
          </span>
          <span class="description" v-if="themeSearch.description"
            >・{{ themeSearch.description }}</span
          >
          <template v-if="createUser">
            <span>・作成者：</span>
            <router-link class="link" :to="`/users/${createUser.id}`">{{
              createUser.user_name
            }}</router-link>
          </template>
        </div>
      </div>
      <button
        v-if="isButtonVisible"
        class="theme-button c-btn c-btn--auto c-btn--small"
        :class="{ 'c-btn--AnewsPrimary': !themeSearch.is_member }"
        @click.prevent.stop="submit"
      >
        {{ btnText }}
      </button>
    </a>
  </router-link>
</template>

<style lang="scss" scoped>
.m-theme-search-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: white;
  border-radius: 4px;
  border: 1px solid $color-gray400;
  &:hover {
    border-color: $color-gray600;

    .theme-button {
      visibility: visible;
    }
  }

  .theme-button {
    flex-shrink: 0;
    visibility: hidden;
  }

  .theme-content {
    .theme-name {
      display: flex;
      .added {
        display: flex;
        align-items: center;
        margin-left: 4px;
        span {
          color: $color-green600;
        }
        fill: $color-green600;
      }
    }
    .theme-info {
      color: $color-gray600;
      fill: $color-gray600;
      .group-icon {
        display: inline-block;
        margin-bottom: -4px;
      }
    }
  }
}
.link {
  color: $color-gray600;
  &:hover {
    text-decoration: underline;
  }
}
</style>
