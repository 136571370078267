import { Module } from 'vuex';
import type { WebPushSubscription } from '@/types';

export type WebPushSubscriptionState = {
  subscription: WebPushSubscription | null;
  isPending: boolean;
};

export const initialWebPushSubScriptionState: WebPushSubscriptionState = {
  subscription: null,
  isPending: true,
};

const webPush: Module<WebPushSubscriptionState, unknown> = {
  namespaced: true,
  state: () => initialWebPushSubScriptionState,
  mutations: {
    updateSubscription(state, info) {
      state.subscription = info;
    },
    changePending(state, payload: boolean) {
      state.isPending = payload;
    },
  },
  actions: {
    async insertSubscription({ commit }, payload: WebPushSubscriptionState) {
      commit('updateSubscription', payload);
    },
    changePending({ commit }, payload: boolean) {
      commit('changePending', payload);
    },
  },
};

export default webPush;
