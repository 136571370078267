<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue';
import api from '@/api';
import { DgrIcon, DgrSelectbox } from '@stockmarkteam/donguri-ui';
import Loading from '@/components/common/loading.vue';
import SearchBar from '@/components/common/molecules/search-bar.vue';
import AdminContent from '@/components/layouts/admin-content.vue';
import Header from '@/components/layouts/header.vue';
import { AdminTheme } from '@/types';
import { STATES, useAdminThemes, useTeamUsers } from '@/utils/swr';
import { useStore } from '@/utils/vue';
import ThemeTable from './theme-table.vue';

type AdminThemeWithCreator = AdminTheme & { creator_name: string };

export default defineComponent({
  props: {},
  components: {
    Header,
    AdminContent,
    SearchBar,
    ThemeTable,
    Loading,
    DgrIcon,
    DgrSelectbox,
  },
  setup() {
    const store = useStore();

    const { data: teamUsers } = useTeamUsers();
    const { data: themeList, state: themeListState } = useAdminThemes();

    const query = ref('');
    const onChangeQuery = (searchQuey: string) => {
      query.value = searchQuey;
    };

    const isLoading = computed(
      () =>
        themeListState.value === STATES.PENDING ||
        themeListState.value === STATES.VALIDATING,
    );

    const teamUserNames = computed(
      () => new Map(teamUsers.value?.users.map(t => [t.id, t.user_name]) ?? []),
    );

    const themeArray = ref<AdminTheme[]>(themeList.value?.themes ?? []);
    watch(
      () => themeList.value,
      () => (themeArray.value = themeList.value?.themes ?? []),
    );

    const themes = computed(() => {
      return themeArray.value
        .map(t => {
          const hasSetting =
            viewSettingFilter.value === 'all' ||
            (viewSettingFilter.value === 'initial_theme' &&
              t.is_initial_theme) ||
            (viewSettingFilter.value === 'not_initial_theme' &&
              !t.is_initial_theme);
          const hasScope =
            scopeFilter.value === 'all' || scopeFilter.value === t.access_scope;
          if (hasSetting && hasScope) {
            return {
              ...t,
              creator_name: teamUserNames.value.get(t.creator_id ?? -1) ?? '',
            } as AdminThemeWithCreator;
          }
        })
        .filter(t => t !== undefined) as AdminThemeWithCreator[];
    });

    const filteredThemes = computed(() =>
      themes.value.filter(t =>
        (t.name + t.description + t.creator_name)
          .toLowerCase()
          .includes(query.value.toLowerCase()),
      ),
    );

    const viewSettingFilter = ref('all');
    const viewSettingOptions = [
      { value: 'all', label: 'すべての設定' },
      { value: 'initial_theme', label: '初期表示設定中' },
      { value: 'not_initial_theme', label: '未設定' },
    ];

    const scopeFilter = ref('all');
    const scopeFilterOptions = [
      { value: 'all', label: 'すべての公開範囲' },
      { value: 'public', label: '全体' },
      { value: 'group', label: 'グループ' },
      { value: 'personal', label: '個人' },
    ];

    const selectedThemeIds = ref<number[]>([]);
    const isUpdatingThemes = ref(false);
    const isButtonEnabled = computed(
      () => !isUpdatingThemes.value && selectedThemeIds.value.length > 0,
    );

    const updateInitialThemes = async (isInitialTheme: boolean) => {
      isUpdatingThemes.value = true;
      for (let themeId of selectedThemeIds.value) {
        const theme = themeArray.value.find(t => t.id === themeId);
        if (theme && theme.is_initial_theme !== isInitialTheme) {
          theme.is_initial_theme = isInitialTheme;
          const themeDetail = await api.fetchTheme(themeId);
          themeDetail.is_initial_theme = isInitialTheme;
          api.updateTheme(themeDetail);
        }
      }
      isUpdatingThemes.value = false;
    };

    const deleteThemes = async () => {
      const deleteAction = async () => {
        isUpdatingThemes.value = true;
        for (let themeId of selectedThemeIds.value) {
          const theme = themeArray.value.find(t => t.id === themeId);
          if (theme) {
            await api.deleteTheme(themeId);
            themeArray.value = themeArray.value.filter(t => t.id !== themeId);
          }
        }
        isUpdatingThemes.value = false;
      };
      const payload = {
        headerText: 'テーマを削除しますか？',
        bodyText:
          '削除したテーマは元に戻すことはできません。\n本当にテーマを削除しますか？',
        action: deleteAction,
      };
      store.commit('confirmationModal/showConfirmationModal');
      store.commit('confirmationModal/setTextAndAction', payload);
    };

    return {
      filteredThemes,
      onChangeQuery,
      viewSettingFilter,
      viewSettingOptions,
      scopeFilter,
      scopeFilterOptions,
      isButtonEnabled,
      selectedThemeIds,
      updateInitialThemes,
      deleteThemes,
      isLoading,
    };
  },
});
</script>

<template>
  <div class="theme-admin">
    <Header title="テーマ管理" header-width="100%" />
    <Loading v-if="isLoading" class="loading" />
    <AdminContent v-else>
      <div class="o-theme-admin__content">
        <SearchBar @on-change-query="onChangeQuery" />
        <div class="spacing-16"></div>
        <div class="title">
          <span class="c-title c-title--m">テーマ</span
          ><span class="count c-text c-text--s"
            >・{{ filteredThemes.length }}件</span
          >
          <VTooltip placement="bottom">
            <span class="explanation c-text c-text--s">
              <DgrIcon size="small" name="question-circle" />
              配信数などの算出方法
            </span>
            <template #popper>
              <span
                >配信数：直近1週間の1日あたりの配信数（平均）<br />
                閲覧数：直近4週間の閲覧数合計<br />
                マーク数：直近4週間のマーク数合計</span
              >
            </template>
          </VTooltip>
        </div>
        <div class="spacing-16"></div>
        <div class="controls">
          <div class="filters">
            <DgrSelectbox
              :options="viewSettingOptions"
              v-model="viewSettingFilter"
              size="small"
            />
            <DgrSelectbox
              :options="scopeFilterOptions"
              v-model="scopeFilter"
              size="small"
            />
          </div>
          <div class="actions">
            <button
              class="c-outlineBtn c-btn--auto c-btn--small c-outlineBtn--secondary"
              :class="{ disabled: !isButtonEnabled }"
              :disabled="!isButtonEnabled"
              @click="() => updateInitialThemes(true)"
            >
              初期設定にする
            </button>
            <button
              class="c-outlineBtn c-btn--auto c-btn--small c-outlineBtn--secondary"
              :class="{ disabled: !isButtonEnabled }"
              :disabled="!isButtonEnabled"
              @click="() => updateInitialThemes(false)"
            >
              初期設定から外す
            </button>
            <button
              class="c-outlineBtn c-btn--auto c-btn--small c-outlineBtn--secondary"
              :class="{ disabled: !isButtonEnabled }"
              :disabled="!isButtonEnabled"
              @click="() => deleteThemes()"
            >
              テーマを削除する
            </button>
          </div>
        </div>
        <div class="spacing-16"></div>
        <ThemeTable
          :data="filteredThemes"
          @theme-selected="themeIds => (selectedThemeIds = themeIds)"
        />
      </div>
    </AdminContent>
  </div>
</template>

<style lang="scss" scoped>
div.o-theme-admin__content {
  padding-bottom: 16px;
}

.theme-admin {
  width: 100%;
  margin: -24px 0 0 0;
  padding: 0 !important;

  .title {
    display: flex;
    align-items: center;
    .count {
      color: #b3b3b3;
    }

    .explanation {
      display: flex;
      .icon-box {
        margin-left: 12px;
        margin-right: 4px;
      }
    }
  }

  .controls {
    display: flex;
    justify-content: space-between;

    .filters {
      display: flex;
      gap: 12px;
    }

    .actions {
      display: flex;

      .c-outlineBtn {
        background: #fff;
        &:not(:last-child) {
          margin-right: 12px;
        }
      }
    }
  }
}
.loading {
  // 画面の高さ - ヘッダーの高さ
  height: calc(100vh - 65px);
}
</style>
