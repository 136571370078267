<script setup lang="ts">
/**
 * スレッドの内の1つの塊（クエリ, ソース, 回答の1セット）を表すコンポーネント
 */
import { computed, inject, nextTick, Ref, ref, watch } from 'vue';
import { trackingSurveyEvent } from '@/api/tracking';
import { DOC_TYPE_LABELS } from '@/constants';
import { DgrIcon } from '@stockmarkteam/donguri-ui';
import Divider from '@/components/common/divider.vue';
import SurveyAdpDocumentCard from '@/components/survey/document/survey-adp-document-card.vue';
import SurveyReportCard from '@/components/survey/document/survey-adp-report-card.vue';
import SurveyUserDocumentCard from '@/components/survey/document/survey-user-document-card.vue';
import SurveyContentRenderer from '@/components/survey/generate-content/survey-content-renderer.vue';
import SurveyFeedback from '@/components/survey/survey-feedback.vue';
import { DocType, FeedbackType, FilterInfo, isReport, Part } from '@/types';
import { AdpDocument, isUserDocument, UserDocument } from '@/types';
import { useCurrentRoutePath } from '@/utils/composables/useCurrentRoutePath';
import { closeAllDocumentsKey } from '@/utils/injectionKeys';
import {
  SURVEY_ERROR_MESSAGE,
  SURVEY_NO_MARKS_ERROR_MESSAGE,
} from '@/utils/survey/constants';
import { SURVEY_PAGE_NAME } from '@/utils/survey/constants';
import { useUserInfo } from '@/utils/swr';
import { getDocumentUrl } from '@/utils/user-document/common';
import { useSnackbar } from '../common/snackbar/use-snackbar';
import SkeletonSentence from './document/skeleton-sentence.vue';

interface Props {
  question: string;
  sources: Array<AdpDocument | UserDocument>;
  originMarkdownSummary: string; // LLMの出力したマークダウン形式の要約 (DOMにパースする前の状態)
  summary: Array<Part>;
  isNoHitSources: boolean; // questionで検索した結果が0件の場合
  isError: boolean; // 何かしらエラーが発生した場合 (modelが要約生成中に通信エラーなど)
  errorCause: string | undefined;
  sessionId: string; // surveysテーブルのuuidカラムの値 (要約画面のURLに含まれるID)。深掘り検索（同じスレッド）の間は同じID
  requestId: string; // survey_historiesテーブルのuuidカラムの値 (要約ブロックを識別するID)
  isInProgressSurvey: boolean; // survey実行中かどうか
  enableAnimation: boolean; // 要約の表示アニメーションを有効にするかどうか
  feedbackType: FeedbackType | undefined; // survey_feedbacksテーブルのevaluationカラムの値 (ブロックに対して実行したフィードバックの種類)
  filterInfo: FilterInfo; // 要約ブロック生成時に選択したフィルター情報
}

const props = defineProps<Props>();

const emit = defineEmits<{
  'update-document': [{ sourceIndex: number; document: AdpDocument }];
}>();

const { getCurrentRoutePath } = useCurrentRoutePath();
const { createSnackbar } = useSnackbar();
const INITIAL_DISPLAY_COUNT = 4; // ドキュメント数が多い場合、最初はこの数だけ表示し、それ以上は「すべて表示」ボタンを押すまで表示しない

// ドキュメントを表示するかどうかのフラグ (最初からドキュメントすべてを展開すると最大20件と多くスクロールが面倒なため、最初は1.5件ほどしか見せない)
const isAllDocumentsVisible = ref(false);
const scrollPosition = ref(0);
const isPartialDisplay = computed(
  () =>
    !isAllDocumentsVisible.value &&
    props.sources.length > INITIAL_DISPLAY_COUNT,
);
const closeAllDocuments = inject(closeAllDocumentsKey) as Ref<boolean>;
watch(closeAllDocuments, newValue => {
  if (newValue) isAllDocumentsVisible.value = false;
});

const displayedDocuments = computed(() => {
  if (!props.sources) return [];
  if (props.sources.length <= INITIAL_DISPLAY_COUNT) return props.sources;

  return isAllDocumentsVisible.value
    ? props.sources
    : props.sources.slice(0, INITIAL_DISPLAY_COUNT);
});

const { data: userInfo } = useUserInfo();
const userEnableTranslateTitle = computed(
  () => userInfo.value?.enable_translate_article_title,
);

// 検索に引っかかったドキュメントのみで表示するメッセージを生成する
const documentTypes = computed<Array<keyof typeof DOC_TYPE_LABELS>>(() => {
  const types = new Set<keyof typeof DOC_TYPE_LABELS>();
  if (props.sources) {
    props.sources.forEach(source => {
      types.add(source.doc_type);
    });
  }
  const order = Object.keys(DOC_TYPE_LABELS);
  return Array.from(types).sort((a, b) => order.indexOf(a) - order.indexOf(b));
});

const documentTypesMessage = computed(() => {
  const types = documentTypes.value.map(type => DOC_TYPE_LABELS[type] || type);
  const totalCount = props.sources.length;
  return `${totalCount}件の${types.join('、')}を検索しました`;
});

const DOC_TYPES_ORDER = Object.keys(DOC_TYPE_LABELS);
const filterTags = computed(() => {
  // TODO: keyのstringはファイル指定の実装時に正しい型に変更する
  const tags = new Map<(DocType | 'user_document') | string, string>();

  if (props.filterInfo) {
    if (props.filterInfo.docTypes && props.filterInfo.docTypes.length > 0) {
      const sortedDocTypes = [...props.filterInfo.docTypes].sort(
        (a, b) => DOC_TYPES_ORDER.indexOf(a) - DOC_TYPES_ORDER.indexOf(b),
      );
      sortedDocTypes.forEach(type => {
        tags.set(type, 'filter');
      });
    } else if (
      props.filterInfo.specifiedFiles &&
      props.filterInfo.specifiedFiles.length > 0
    ) {
      props.filterInfo.specifiedFiles.forEach(file => {
        tags.set(file, 'specifiedFile');
      });
    }
  }

  return Array.from(tags);
});

const showAllDocuments = async () => {
  scrollPosition.value = window.scrollY;
  isAllDocumentsVisible.value = true;

  // 計測
  await trackingSurveyEvent('hit_documents_show_all', {
    page: {
      name: 'survey',
      url: getCurrentRoutePath(),
    },
    feature: 'survey',
  });
};

const closeDocuments = async () => {
  isAllDocumentsVisible.value = false;
  await nextTick();
  window.scrollTo(0, scrollPosition.value); // Layout Shiftを防ぐため、スクロール位置を元に戻す
};

const copySummary = async () => {
  try {
    // バックエンドで脚注部分を削っているので、フロントエンドで整形してコピーする
    const urls = props.sources.map(source =>
      isUserDocument(source) ? getDocumentUrl(source) : source.url,
    );
    const footnote = urls
      .map((url, index) => `[${index + 1}] ${url}`)
      .join('\n');
    const formattedSummary =
      props.sources.length > 0
        ? `${props.question}\n\n${props.originMarkdownSummary.replace(/\^/g, '')}\n\nソース:\n${footnote}`
        : `${props.question}\n\n${props.originMarkdownSummary.replace(/\^/g, '')}`;

    await navigator.clipboard.writeText(formattedSummary);

    createSnackbar({
      message: `コピーしました`,
      type: 'success',
    });
  } catch (_e) {
    createSnackbar({
      message: `コピーできませんでした`,
      type: 'error',
    });
  }
};
</script>

<template>
  <template v-if="props.isError">
    <p class="error-message">
      {{
        errorCause === 'no_user_marks'
          ? SURVEY_NO_MARKS_ERROR_MESSAGE
          : SURVEY_ERROR_MESSAGE
      }}
    </p>
  </template>
  <template v-else>
    <div class="survey-history-container">
      <h2 class="c-title c-title--xl survey-history-content">{{ question }}</h2>
      <div v-if="filterTags.length != 0" class="filter-tags-wrapper">
        <div class="spacing-16"></div>
        <div class="survey-history-content filter-tags">
          <template v-for="([tag, type], index) in filterTags" :key="index">
            <span v-if="type === 'filter'" class="c-text c-text--s filter-tag">
              {{ filterInfo.searchScope === 'user_marks' ? 'マークした' : ''
              }}{{
                DOC_TYPE_LABELS[tag as keyof typeof DOC_TYPE_LABELS] || tag
              }}
            </span>
            <span
              v-else-if="type === 'specifiedFile'"
              class="c-tag c-tag--s c-tag--specified-file survey-history-content"
            >
              <!-- TODO: アイコンはファイルの種類によって出し分ける必要があるかも "file-pdf-fill-color" | "file-powerpoint-fill-color" | "file-v" | "file-word-fill-color" -->
              <!-- <DgrIcon name="" /> -->
              {{ tag }}
            </span>
          </template>
        </div>
      </div>
      <Divider margin="16px 0" />
      <div class="survey-history-content">
        <SurveyContentRenderer
          v-if="props.summary.length > 0"
          v-memo="[props.summary, props.enableAnimation]"
          :content-parts="props.summary"
          :enable-animation="props.enableAnimation"
        />
        <!-- 検索結果が0ヒット -->
        <template v-else-if="props.isNoHitSources">
          <p>
            「{{ props.question }}」
            に関するソースを見つけられなかったため要約を生成できませんでした。
          </p>
          <p>
            質問をより明確にするか、要約に使用する情報源を変更することで要約を作成できる可能性があります。
          </p>
          <div class="spacing-08"></div>
        </template>
        <!-- 要約 ローディング -->
        <div v-else>
          <SkeletonSentence :number-of-lines="3" />
          <div class="spacing-08"></div>
        </div>
        <div class="summary-feedback">
          <SurveyFeedback
            v-if="!props.isInProgressSurvey && props.requestId !== undefined"
            :session-id="props.sessionId"
            :request-id="props.requestId"
            :feedback-type="props.feedbackType"
          />
        </div>
      </div>
      <template v-if="!props.isNoHitSources">
        <Divider
          v-if="!props.isInProgressSurvey"
          :margin="props.sources.length > 0 ? '16px 0' : '16px 0 8px 0'"
        />
        <!-- ソース (検索結果) -->
        <div
          v-if="displayedDocuments.length > 0 && !props.isInProgressSurvey"
          class="survey-history-content document-cards"
        >
          <div class="searching-word c-title c-title--m">
            <DgrIcon name="search" :keep-fill="false" />
            <p>{{ documentTypesMessage }}</p>
          </div>
          <div class="spacing-16"></div>
          <div class="document-cards-grid">
            <div
              v-for="(document, index) in displayedDocuments"
              :key="document.id"
              :class="{
                'last-row':
                  isPartialDisplay && index >= displayedDocuments.length - 2,
              }"
            >
              <SurveyUserDocumentCard
                v-if="isUserDocument(document)"
                :user-document="document"
                :badge-number="index + 1"
                :page-name="SURVEY_PAGE_NAME"
                :feature="SURVEY_PAGE_NAME"
                :rank-in-whole-feed="index + 1"
                :contents-context="{
                  event_name: SURVEY_PAGE_NAME,
                  session_id: props.sessionId,
                }"
              />
              <SurveyReportCard
                v-else-if="isReport(document)"
                :adp-report="document"
                :badge-number="index + 1"
                :page-name="SURVEY_PAGE_NAME"
                :feature="SURVEY_PAGE_NAME"
                :rank-in-whole-feed="index + 1"
                :contents-context="{
                  event_name: SURVEY_PAGE_NAME,
                  session_id: props.sessionId,
                }"
              />
              <SurveyAdpDocumentCard
                v-else
                :adp-document="document"
                :badge-number="index + 1"
                :user-enable-translate-title="userEnableTranslateTitle"
                :is-narrow="true"
                :page-name="SURVEY_PAGE_NAME"
                :feature="SURVEY_PAGE_NAME"
                :rank-in-whole-feed="index + 1"
                :contents-context="{
                  event_name: SURVEY_PAGE_NAME,
                  session_id: props.sessionId,
                }"
                @update-document="
                  document =>
                    emit('update-document', { sourceIndex: index, document })
                "
              />
              <div
                v-if="
                  isPartialDisplay && index >= displayedDocuments.length - 2
                "
                class="gradient-overlay"
              ></div>
            </div>
          </div>
        </div>
        <div
          class="show-all-button-wrapper"
          v-if="displayedDocuments.length > 0 && !props.isInProgressSurvey"
        >
          <button
            v-if="isPartialDisplay"
            @click="showAllDocuments"
            class="visibility-button show-all-button"
          >
            すべて表示
          </button>
        </div>
        <button
          v-if="
            props.sources.length > INITIAL_DISPLAY_COUNT &&
            isAllDocumentsVisible
          "
          @click="closeDocuments"
          class="visibility-button close-button"
        >
          閉じる
        </button>
        <Divider
          v-if="displayedDocuments.length > 0 && !props.isInProgressSurvey"
          margin="8px 0"
        />

        <div
          v-if="!props.isInProgressSurvey"
          class="action-buttons-wrapper survey-history-content"
        >
          <button
            @click="copySummary"
            class="action-button c-title c-title--m c-btn--small"
          >
            <DgrIcon name="copy" />
            <p>コピーする</p>
          </button>
        </div>
      </template>
    </div>
  </template>
</template>

<style lang="scss" scoped>
.survey-history-container {
  background-color: $color-white;
  padding: 16px 0 8px;
  border-radius: 4px;
  border: solid 1px $color-gray400;
}

.survey-history-content {
  padding: 0 16px;
}

.filter-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.filter-tag {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  border: solid 1px $color-gray400;
  padding: 8px 12px;
}

.error-message {
  margin: 16px 0;
  padding-bottom: 16px;
  border-bottom: 1px solid $color-gray400;
}

.searching-word {
  display: flex;
  gap: 8px;
}

.document-cards {
  display: flex;
  flex-direction: column;
}

.document-cards-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
}

.document-cards-grid > div {
  display: flex;
  align-items: stretch;
}

/* ----- "すべて表示"で展開するまでは上限数のカードのみを表示 (最後の行の2つのドキュメントの下半分は半透明にする) ----- */
.gradient-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 70%;
  background: linear-gradient(to top, white 45%, transparent 100%);
}

.last-row {
  position: relative;
  pointer-events: none;
}
/* ------------------------------------------------------------ */

.adp-document-card-skelton-wrapper {
  margin-bottom: 16px;
}

.visibility-button {
  border: 1px solid $color-gray400;
  background-color: $color-white;
  border-radius: 4px;
  padding: 9px 16px;
  margin: 0 auto;
  cursor: pointer;
}

.show-all-button-wrapper {
  position: relative;
  height: 0;
}

.show-all-button {
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
}

.close-button {
  margin: 16px auto;
}

.generate-contents {
  &__header {
    display: flex;
    align-items: center;
    gap: 8px;
    height: 44px;
  }

  &__ai-spark-icon {
    fill: $color-gray1000;
  }
}

.summary-feedback {
  background-color: $color-gray200;
  border-radius: 4px;
}

.action-buttons-wrapper {
  display: grid;
  gap: 16px;
  align-items: center;

  .action-button {
    display: flex;
    gap: 3px;
    align-items: center;
    width: fit-content;
    border: none;
    background: transparent;
    cursor: pointer;

    &:hover {
      background-color: $color-gray400;
    }
  }
}
</style>
