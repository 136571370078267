<script lang="ts">
import { computed, defineComponent, onMounted, onUnmounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import api from '@/api/index';
import AdpDocumentCard from '@/components/common/adp-document-card.vue';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import GroupActionButtons from '@/components/group/group-action-buttons.vue';
import Content from '@/components/layouts/content.vue';
import Header from '@/components/layouts/header.vue';
import { Article } from '@/types';
import { getTabs } from '@/utils/group';
import { STATES, useComment, useGroups, useUserInfo } from '@/utils/swr';
import { useEmitter } from '@/utils/vue';

export default defineComponent({
  components: {
    GroupActionButtons,
    Header,
    Content,
    AdpDocumentCard,
  },
  props: {
    isMenuVisible: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { createSnackbar } = useSnackbar();
    const emitter = useEmitter();

    const { data: groups, mutate } = useGroups();
    const { data: userInfo } = useUserInfo();

    const commentId = computed(() => {
      if (route.params.commentId instanceof Array) {
        return route.params.commentId[0];
      }
      return route.params.commentId;
    });

    const {
      data: comment,
      state,
      mutate: mutateComment,
    } = useComment(commentId);

    const isGroupAdmin = computed(() =>
      ['admin', 'group_admin'].includes(userInfo.value?.role ?? ''),
    );

    onMounted(() => {
      emitter.on('article-updated', handleArticleUpdate);
    });

    onUnmounted(() => {
      emitter.off('article-updated', handleArticleUpdate);
    });

    const handleArticleUpdate = (article: Article) => {
      if (comment.value) {
        comment.value.article = article;
      }
    };

    const trackIntegration = async () => {
      if (comment.value) {
        const article = comment.value.article
          ? { id: comment.value.article.id }
          : undefined;
        await api.trackIntegration('comment', route, {
          article,
          comment: { id: comment.value.id },
          group: { id: comment.value.group_id },
        });
      }
    };

    watch(
      () => state.value,
      () => {
        if (state.value === STATES.ERROR) {
          createSnackbar({
            message: '指定されたコメントは存在しません',
            type: 'error',
          });
          router.replace('/');
        } else if (
          state.value === STATES.SUCCESS &&
          comment.value?.parent_comment_id
        ) {
          // 子コメントの場合、親コメントに推移する
          router.replace(`/comments/${comment.value.parent_comment_id}`);
        } else if (state.value === STATES.SUCCESS) {
          emitter.emit('select-group-menu-item', comment.value?.group_id);
          trackIntegration();
        }
      },
    );

    const group = computed(() =>
      (groups.value ?? { groups: [] }).groups.find(
        g => g.id === comment.value?.group_id,
      ),
    );

    emitter.on('comment-updated', () => {
      mutateComment();
    });

    emitter.on('comment-deleted', () => {
      mutateComment();
    });

    return {
      group,
      userInfo,
      mutate,
      comment,
      isGroupAdmin,
      getTabs,
    };
  },
});
</script>

<template>
  <div class="group-comment-container" v-if="group && comment && userInfo">
    <Header
      :title="group.name"
      :detail="`メンバー${group.member_count}人`"
      :tabs="getTabs(group, userInfo, comment.id)"
      :is-menu-visible="isMenuVisible"
    >
      <template #button><GroupActionButtons :group="group" /></template>
    </Header>
    <Content>
      <div class="group-comment-content">
        <AdpDocumentCard
          class="article-card"
          :adp-document="comment.article"
          :comment="comment"
          :group="group"
          page-name="group"
          :fold-replies="false"
          :disable-related-articles="false"
        ></AdpDocumentCard>
      </div>
    </Content>
  </div>
</template>

<style lang="scss" scoped>
.group-comment-container {
  width: 100%;
  margin: -24px 0 0 0;

  .edit-link {
    margin-left: 12px;
    padding: 4px 8px;
    border-radius: 4px;
    color: #1da482;

    &:hover {
      background: #f2f2f2;
    }
  }

  .group-comment-content {
    display: flex;
    flex-direction: column;
    width: 616px;
  }

  .article-card {
    padding: 16px;
  }
}
</style>
