import { Module } from 'vuex';
import api from '@/api';
import { AdminUserInfo, TeamUser } from '@/types';

export type TeamUsersState = {
  teamUsers: TeamUser[];
  // TODO: members.vueをリファクターして、teamUsersForAdminを削除する
  teamUsersForAdmin: AdminUserInfo[];
};

export const initialTeamUsersState: TeamUsersState = {
  teamUsers: [],
  teamUsersForAdmin: [],
};

const teamUsers: Module<TeamUsersState, unknown> = {
  namespaced: true,
  state: () => initialTeamUsersState,
  actions: {
    async fetchTeamUsers({ commit }) {
      const teamUsers = await api.fetchUsers();
      commit('setTeamUsers', teamUsers);
    },
    async fetchTeamUsersForAdmin({ commit }) {
      const teamUsers = await api.fetchUsersForAdmin();
      commit('setTeamUsersForAdmin', teamUsers);
    },
  },
  getters: {
    activeTeamUsers(state) {
      return state.teamUsers.filter(user => !user.is_deleted);
    },
    activeTeamUsersForAdmin(state) {
      return state.teamUsersForAdmin.filter(user => !user.is_deleted);
    },
  },
  mutations: {
    setTeamUsers(state, users) {
      state.teamUsers = users;
    },
    setTeamUsersForAdmin(state, users) {
      state.teamUsersForAdmin = users;
    },
  },
};

export default teamUsers;
