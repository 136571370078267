import { computed } from 'vue';
import api from '@/api';
import convertVapidKey from 'convert-vapid-public-key';
import { useStore } from '@/utils/vue';

export const useWebPush = () => {
  const store = useStore();

  /** storeに登録されている購読情報の更新状態を取得する */
  const getPendingState = () => {
    return computed(() => store.state.webPush.isPending);
  };
  /** Web Pushに関する Storeを初期化する */
  const initializeWebPushStore = async () => {
    if (!canReceiveWebPushNotification()) {
      await store.dispatch('webPush/changePending', false);
      return;
    }

    const registration = await navigator.serviceWorker.ready;
    const subscription = await registration.pushManager.getSubscription();
    const fetchedSubscription = subscription
      ? await api.fetchWebPushSubscription(subscription.endpoint)
      : undefined;

    // DBに登録されている購読情報がある場合はstoreに登録する
    if (fetchedSubscription) {
      await store.dispatch('webPush/insertSubscription', fetchedSubscription);
    }

    await store.dispatch('webPush/changePending', false);
  };

  /** storeに登録されている購読情報を取得する */
  const getSubscription = () => {
    return computed(() => store.state.webPush.subscription);
  };

  /** Web Push通知を受け取れるか */
  const canReceiveWebPushNotification = () => {
    if (!('serviceWorker' in navigator)) {
      return false;
    }
    if (!('Notification' in window)) {
      return false;
    }
    return Notification?.permission === 'granted';
  };

  /**
   * @title web push通知を受け取るための購読処理
   * @desc 前段では通知を拒否している場合や、すでに購読済みの場合returnしている
   * @desc web pushは ①通知の許可 → ②subscribe → ③ ②の情報をバックエンドにPOSTする の手順が必要
   * ここでは②③の処理を行う
   * */
  const subscribeToWebPush = async () => {
    if (!canReceiveWebPushNotification()) return;
    await store.dispatch('webPush/changePending', true);
    const swRegistration = await navigator.serviceWorker.ready;
    const subscription = await swRegistration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: convertVapidKey(
        import.meta.env.VITE_WEB_PUSH_VAPID_PUBLIC_KEY,
      ),
    });

    try {
      await api.postWebPushSubscription({
        pushSubscriptionJson: subscription.toJSON(),
        enabledMentionNotificationBrowser: true,
        enabledOtherUsersActivityNotificationBrowser: true,
      });
      await store.dispatch('webPush/insertSubscription', subscription);
      return 'SUCCESS';
    } catch {
      subscription.unsubscribe();
      return 'FAILED';
    } finally {
      await store.dispatch('webPush/changePending', false);
    }
  };

  return {
    canReceiveWebPushNotification,
    subscribeToWebPush,
    getPendingState,
    initializeWebPushStore,
    getSubscription,
  };
};
