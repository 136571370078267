<script setup lang="ts">
interface Props {
  numberOfLines: number;
}

const props = defineProps<Props>();
</script>

<template>
  <div class="skeleton loading">
    <div
      class="sentence"
      v-for="(i, index) in props.numberOfLines"
      :key="index"
    ></div>
  </div>
</template>

<style lang="scss" scoped>
.skeleton {
  display: grid;
  gap: 4px;
}

.sentence {
  width: 100%;
  height: 20px;
  border-radius: 4px;
  background: $color-gray400;
}

.skeleton.loading {
  .sentence {
    background-image: linear-gradient(
      110deg,
      rgba(230, 230, 230, 1) 25%,
      rgba(255, 255, 255, 0.35) 50%,
      rgba(230, 230, 230, 1) 75%
    );
    background-size: 80% 100%;
    background-position: -500% 0;
    background-repeat: no-repeat;
    animation: loading 1.5s infinite;
  }
}

@keyframes loading {
  to {
    background-position: 500% 0;
  }
}
</style>
