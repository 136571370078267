<script lang="ts">
import { computed, defineComponent, onMounted, ref } from 'vue';
import api from '@/api';
import { MAX_COMMENT_LENGTH } from '@/constants';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import Modal from '@/components/modals/modal.vue';
import { formatDateTime } from '@/utils/formatters';
import { useTeamUsers } from '@/utils/swr';
import { useEmitter, useStore } from '@/utils/vue';

export default defineComponent({
  components: {
    Modal,
  },
  setup() {
    const store = useStore();
    const emitter = useEmitter();
    const { createSnackbar } = useSnackbar();
    const { data: users } = useTeamUsers();

    // HACK: モーダルを開いている時点でmodal.commentに値は入っているため、undefinedになる場合を考慮しない。要リファクタリング
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const comment = store.state.modal.comment!;
    const commentContent = ref(comment.content);
    const textAreaRef = ref<HTMLTextAreaElement>();

    const updateHeight = (event: Event) => {
      const textArea = event.target as HTMLTextAreaElement;
      textArea.style.height = '22px';
      textArea.style.height = `${Math.max(90, textArea.scrollHeight)}px`;
    };

    onMounted(() => {
      const es = document.getElementsByClassName('o-comment-edit-text-area');
      if (es.length === 1) {
        var event = new Event('input', {
          bubbles: true,
          cancelable: true,
        });
        es[0].dispatchEvent(event);
      }
      textAreaRef.value?.focus();
    });

    const tooLongComment = computed(
      () => commentContent.value?.length > MAX_COMMENT_LENGTH,
    );

    const isPostingComment = ref(false);

    const canUpdateComment = () =>
      commentContent.value?.length > 0 &&
      !tooLongComment.value &&
      !isPostingComment.value;

    const hideModal = () => {
      if (isPostingComment.value === false) {
        store.commit('modal/hideModal');
      }
    };

    const updateComment = async () => {
      if (canUpdateComment()) {
        try {
          const updatedComment = await api.updateComment(
            comment.id,
            commentContent.value,
          );

          createSnackbar({
            message: 'コメントを編集しました',
            type: 'success',
          });
          emitter.emit('comment-updated', updatedComment);

          // コメントに記事が紐付いていれば記事情報も更新する
          if (comment.article && comment.article.comments) {
            const index = comment.article.comments.findIndex(
              c => c.id === comment.id,
            );

            const comments = comment.article.comments.slice();
            comments[index] = updatedComment;
            comment.article.comments = comments;
            emitter.emit('article-updated', comment.article);
          }
          hideModal();
        } catch {
          createSnackbar({
            message: 'コメントを編集できませんでした',
            type: 'error',
          });
        }
      }
    };

    const user = computed(() =>
      users.value?.users.find(u => u.id === comment.user_id),
    );

    return {
      user,
      formatDateTime,
      comment,
      commentContent,
      textAreaRef,
      updateHeight,
      tooLongComment,
      hideModal,
      updateComment,
      canUpdateComment,
      isPostingComment,
    };
  },
});
</script>

<template>
  <Modal class="m-article-share" @close="hideModal">
    <template #header>
      <div class="m-header">
        <div class="m-header-text c-text c-text--m">コメント編集</div>
      </div>
    </template>
    <template #body>
      <div class="m-body">
        <div class="user-info" v-if="user">
          <img class="user-icon" :src="user.image_url" />
          <div>
            <div class="username c-title c-title--m">{{ user.user_name }}</div>
            <div class="c-text c-text--s">
              {{ formatDateTime(comment.created_at) }}
            </div>
          </div>
        </div>
        <div class="o-comment-edit">
          <div
            class="o-error c-formBlock__text c-formBlock__text--error"
            v-show="tooLongComment"
          >
            コメントは500文字以内で入力してください。
          </div>
          <textarea
            class="o-comment-edit-text-area c-textArea c-text--m"
            ref="textAreaRef"
            :class="{
              'c-formInput--error': tooLongComment,
              'm-normal-text-area': !tooLongComment,
            }"
            v-model.trim="commentContent"
            @input="updateHeight"
            @keydown.ctrl.enter="updateComment"
            @keydown.meta.enter="updateComment"
          ></textarea>
        </div>
        <div class="m-footer">
          <div class="m-footer-buttons">
            <button
              class="m-cancel-button c-btn c-btn--auto"
              @click="hideModal"
            >
              <span v-if="!isPostingComment">キャンセル</span>
            </button>
            <button
              class="m-post-button c-btn c-btn--auto c-btn--AnewsPrimary"
              :class="{ disabled: !canUpdateComment() }"
              :disabled="!canUpdateComment()"
              @click="updateComment"
            >
              <span v-if="!isPostingComment">変更を保存</span>
            </button>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>
<style lang="scss" scoped>
.m-article-share {
  .m-body {
    box-sizing: border-box;
    padding: 16px 0;

    .user-info {
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      .user-icon {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        margin-right: 8px;
      }
    }

    .o-comment-edit {
      .o-comment-edit-text-area {
        width: 100%;
        margin-bottom: 8px;
        padding: 12px 16px;
        resize: none;
        overflow: hidden;
        min-height: 22px;
        outline: none;
      }
    }

    .o-error {
      margin-top: 8px;
    }
  }
  .m-footer {
    margin-top: 8px;
    .m-footer-buttons {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      button {
        margin-bottom: 0;
      }
      .m-cancel-button {
        margin-right: 8px;
      }
    }
  }
}
</style>
