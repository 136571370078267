<script setup lang="ts">
import { nextTick, onBeforeUnmount, onMounted, ref } from 'vue';
import { DgrIcon } from '@stockmarkteam/donguri-ui';

/**
 * Local State
 */

const textareaRef = ref<HTMLTextAreaElement>();

/**
 * Handlers
 */

const adjustContainerHeight = () => {
  const textarea = textareaRef.value;
  if (textarea) {
    textarea.style.height = 'auto'; // 一旦高さをリセット
    const height = textarea.scrollHeight;
    textarea.style.height = `${height}px`;
  }
};

onMounted(() => {
  nextTick(() => {
    adjustContainerHeight();
    window.addEventListener('resize', adjustContainerHeight);
  });
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', adjustContainerHeight);
});
</script>

<template>
  <div class="fixed-bottom-container">
    <textarea
      ref="textareaRef"
      class="c-text c-text--m"
      placeholder="1つの要約内の最大数を超えているため追加生成できません。新しい要約を生成してください。"
      disabled
      rows="1"
    ></textarea>
    <router-link
      :to="{ name: 'surveyTop' }"
      class="unstyled-link c-btn--AnewsPrimary"
    >
      <DgrIcon
        size="small"
        :keep-fill="false"
        name="sparkles-fill"
        class="ai-icon"
      />新しい要約を生成
    </router-link>
  </div>
</template>

<style lang="scss" scoped>
// チャット形式のUIの画面でしか使用しないため、入力欄を画面下部に固定する
.fixed-bottom-container {
  position: fixed;
  bottom: 16px;
  display: flex;
  align-items: center;
  max-width: var(--survey-session-width);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1;
  background-color: $color-gray400;
  // レスポンシブ対応
  width: -webkit-fill-available;
  width: -moz-available;
  margin-right: 32px;
  padding: 12px 16px;

  textarea {
    background-color: $color-gray400;
    height: auto;
    width: 100%;
    outline: none;
    border: none;
    resize: none;
    min-height: 1.5em;
    max-height: 200px;
    overflow-y: auto;
    padding: 8px;
    box-sizing: border-box;
    grid-row: span 1;
    overflow: hidden;
  }

  ::placeholder {
    color: $color-gray1000;
  }

  .unstyled-link {
    // aタグのスタイルをリセット
    text-decoration: none;
    color: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;

    // aタグをボタン風に見せるスタイル
    border-radius: 4px;
    white-space: nowrap;
    padding: 8px 16px;
    cursor: pointer;
    color: $color-white;

    .ai-icon {
      margin-right: 3px;
      fill: $color-white;
    }
  }
}
</style>
