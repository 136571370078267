import { RouteLocationNormalized, RouteRecordRaw } from 'vue-router';

const userIdProps = (route: RouteLocationNormalized): { userId: number } => ({
  userId: Number(route.params.userId),
});

const routes = [
  {
    name: 'dashboardTeamActivityDownload',
    path: 'activity-download',
    meta: { title: 'アクション履歴' },
    component: () =>
      import('@/components/dashboard/pages/team-activity-download.vue'),
  },
  {
    name: 'dashboardMemberDetail',
    path: 'members/:userId',
    meta: { title: 'メンバー利用状況' },
    props: {
      default: userIdProps,
      header: userIdProps,
    },
    components: {
      default: () => import('@/components/dashboard/pages/member-detail.vue'),
      header: () =>
        import('@/components/dashboard/molecules/member-detail-header.vue'),
    },
  },
  {
    name: 'dashboardMemberList',
    path: 'members',
    meta: { title: 'メンバー利用状況' },
    component: () => import('@/components/dashboard/pages/member-list.vue'),
  },
];

export const dashboardRouteConfig: RouteRecordRaw = {
  name: 'dashboardHome',
  path: '/dashboard',
  redirect: {
    name: 'dashboardTeamActivityDownload',
  },
  component: () => import('@/components/dashboard/layouts/main.vue'),
  children: routes,
};
