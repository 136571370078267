<script lang="ts">
import { defineComponent } from 'vue';
import qrDevelopment from '@/assets/mobile-app-qr-development.svg';
import qrProduction from '@/assets/mobile-app-qr-production.svg';
import qrStaging from '@/assets/mobile-app-qr-staging.svg';
import { ANDROID_STORE_URL, IOS_STORE_URL } from '@/constants';

export default defineComponent({
  setup() {
    const getQrImgPath = () => {
      switch (import.meta.env.VITE_ENV) {
        case 'development':
          return qrDevelopment;
        case 'staging':
          return qrStaging;
        default:
          return qrProduction;
      }
    };
    return {
      iosUrl: IOS_STORE_URL,
      androidUrl: ANDROID_STORE_URL,
      qrImgPath: getQrImgPath(),
    };
  },
});
</script>

<template>
  <div class="m-mobile-app-info">
    <div class="c-text c-text--m">アプリでもっと手軽に！</div>
    <div class="m-qr-code"><img :src="qrImgPath" /></div>
    <div class="m-download-buttons">
      <a
        class="m-download-button ios"
        :href="iosUrl"
        target="_blank"
        rel="noopener noreferrer"
        ><img src="@/assets/app-download-apple.svg" /></a
      ><a
        class="m-download-button android"
        :href="androidUrl"
        target="_blank"
        rel="noopener noreferrer"
        ><img src="@/assets/app-download-google.png"
      /></a>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.m-mobile-app-info {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
  padding: 16px 8px;
  background: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 4px;

  .m-download-buttons {
    display: flex;
    .m-download-button {
      width: 88px;
      height: 32px;

      img {
        height: 100%;
        width: 100%;
      }

      &.android {
        width: 107px;
        margin-left: 8px;
      }
    }
  }

  .m-qr-code {
    height: 48px;
    width: 48px;
    padding: 12px 0;
    img {
      height: 100%;
      width: 100%;
    }
  }
}
</style>
