<script lang="ts">
import { computed, defineComponent, PropType, ref, watch } from 'vue';
import api from '@/api';
import { MAX_IP_ADDRESS_RESTRICTION_DESCRIPTION } from '@/constants';
import LoadableButton from '@/components/common/atoms/loadable-button.vue';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import { IpAddressRestriction } from '@/types';
import { validateIpAddress } from '@/utils/ipAddress';

export default defineComponent({
  components: {
    LoadableButton,
  },
  props: {
    isOpen: { type: Boolean, required: true },
    ipAddressRestriction: {
      type: Object as PropType<IpAddressRestriction> | undefined,
    },
  },
  emits: {
    onClose: () => true,
    onSubmit: () => true,
  },
  setup(props, { emit }) {
    const { createSnackbar } = useSnackbar();

    const ipAddress = ref('');
    const description = ref('');
    const id = ref<number | undefined>(undefined);

    watch(
      () => props.isOpen,
      () => {
        if (props.isOpen) {
          const ipAddressRestriction = props.ipAddressRestriction;
          ipAddress.value = ipAddressRestriction?.ip_address || '';
          description.value = ipAddressRestriction?.description || '';
          id.value = ipAddressRestriction?.id;
        }
      },
    );

    const hideModal = () => {
      emit('onClose');
    };

    const isEditing = ref(false);

    const isInvalidIpAddress = computed(
      () => !validateIpAddress(ipAddress.value),
    );

    const isDescriptionTooLong = computed(
      () => description.value.length > MAX_IP_ADDRESS_RESTRICTION_DESCRIPTION,
    );

    const isEmptyIpAddress = computed(() => ipAddress.value === '');

    const enableToEdit = computed(
      () =>
        !isInvalidIpAddress.value &&
        !isEmptyIpAddress.value &&
        !isDescriptionTooLong.value,
    );

    const editIpAddressRestriction = async () => {
      try {
        if (!id.value) {
          throw new Error('idがありません');
        }
        isEditing.value = true;
        const editedIpAddressRestriction = {
          id: id.value,
          ip_address: ipAddress.value,
          description: description.value,
        };
        await api.putIpAddressRestriction(editedIpAddressRestriction);
        createSnackbar({
          message: 'IPアドレスの設定を変更しました',
          type: 'success',
        });
        emit('onSubmit');
      } catch {
        createSnackbar({
          message: 'IPアドレスの設定を変更できませんでした',
          type: 'error',
        });
      }
      isEditing.value = false;
      hideModal();
    };

    return {
      hideModal,
      isInvalidIpAddress,
      isEmptyIpAddress,
      isDescriptionTooLong,
      ipAddress,
      description,
      enableToEdit,
      isEditing,
      editIpAddressRestriction,
      MAX_IP_ADDRESS_RESTRICTION_DESCRIPTION,
    };
  },
});
</script>

<template>
  <Teleport to="body">
    <sm-dialog
      v-if="isOpen"
      @close="hideModal"
      class="screen-center no-padding auto-width"
    >
      <template #header>
        <div class="c-dialog__title">IPアドレスの編集</div>
      </template>
      <template #body>
        <div class="modal-body">
          <div class="modal-form">
            <div class="c-formBlock">
              <div class="c-formBlock__label">IPアドレス</div>
              <div
                class="c-formBlock__text modal-error-message"
                v-if="isInvalidIpAddress && !isEmptyIpAddress"
              >
                <span class="c-text--s"
                  >不正なIPアドレスが入力されています。</span
                >
              </div>
              <div
                class="c-formBlock__text modal-error-message"
                v-if="isEmptyIpAddress"
              >
                <span class="c-text--s">IPアドレスが空欄です。</span>
              </div>
              <input
                class="c-text c-text--m c-textInput"
                v-model="ipAddress"
                :class="{
                  'c-formInput--error': isEmptyIpAddress || isInvalidIpAddress,
                }"
              />
              <div class="spacing-08"></div>
            </div>
            <div class="spacing-08"></div>
            <div class="c-formBlock">
              <div class="c-formBlock__label">備考</div>
              <input
                class="c-text c-text--m c-textInput"
                v-model="description"
                :class="{
                  'c-formInput--error': isDescriptionTooLong,
                }"
              />
              <div
                :class="{
                  'modal-error-message': isDescriptionTooLong,
                }"
                class="modal-description-word-count"
              >
                <span class="c-text c-text--xs" v-if="isDescriptionTooLong">
                  {{
                    MAX_IP_ADDRESS_RESTRICTION_DESCRIPTION
                  }}文字以内で入力してください。
                </span>
                <span class="c-text c-text--xs">
                  {{
                    description.length +
                    '/' +
                    MAX_IP_ADDRESS_RESTRICTION_DESCRIPTION
                  }}
                </span>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="modal-ip-address-annotation c-text c-text--s">
              <div>
                IPv4の通常の表記及びCIDR表記に対応しています(一括で最大10件登録可能)
              </div>
              <div class="spacing-08"></div>
              <div>
                通常の表記（例 203.0.113.0）CIDR表記（例 203.0.113.0/24）
              </div>
            </div>
            <div class="modal-footer-buttons">
              <button
                class="modal-cancel-button c-btn c-btn--auto c-btnOutline"
                @click="hideModal"
              >
                キャンセル
              </button>
              <div class="spacing-16"></div>
              <LoadableButton
                class="modal-create-button c-btn c-btn--auto c-btn--AnewsPrimary"
                :class="{ disabled: !enableToEdit }"
                :disabled="!enableToEdit"
                @click="editIpAddressRestriction"
                :is-loading="isEditing"
              >
                保存
              </LoadableButton>
            </div>
          </div>
        </div>
      </template>
    </sm-dialog>
  </Teleport>
</template>
<style lang="scss" scoped>
.modal-body {
  width: 708px;
  .modal-form {
    padding: 16px;
    .modal-description-word-count {
      justify-content: flex-end;
      color: $color-gray600;
    }
    .modal-error-message {
      color: $color-orange1000;
    }
  }
}
.modal-footer {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid $color-gray200;
  .modal-ip-address-annotation {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    color: $color-gray600;
  }
  .modal-footer-buttons {
    display: flex;
    flex-direction: row;
  }
}
</style>
