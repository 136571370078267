<script setup lang="ts">
import { computed } from 'vue';
import GroupActionButtons from '@/components/group/group-action-buttons.vue';
import Header from '@/components/layouts/header.vue';
import { Group, UserInfo } from '@/types';
import { getTabs } from '@/utils/group';
import { urlParse } from '@/utils/parsers';

interface Props {
  group: Group;
  userInfo: UserInfo;
  isMenuVisible?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  isMenuVisible: true,
});

const description = computed(() => {
  const targetGroup = props.group;
  return targetGroup?.group_type === 'all_user_group'
    ? 'メンバー全体向けのグループです'
    : urlParse(targetGroup.description);
});
</script>

<template>
  <Header
    :title="group.name"
    :detail="description"
    :tabs="getTabs(group, userInfo)"
    content-width="948px"
    :is-menu-visible="isMenuVisible"
  >
    <template #button>
      <GroupActionButtons
        v-if="group.group_type !== 'all_user_group'"
        :group="group"
      ></GroupActionButtons>
    </template>
  </Header>
</template>
