<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import api from '@/api';
import { DgrIcon, DgrPopover } from '@stockmarkteam/donguri-ui';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import { Comment } from '@/types';
import { useUserInfo } from '@/utils/swr';
import { useEmitter, useStore } from '@/utils/vue';

export default defineComponent({
  components: {
    DgrPopover,
    DgrIcon,
  },
  props: {
    comment: { type: Object as PropType<Comment>, required: true },
  },
  setup(props) {
    const store = useStore();
    const emitter = useEmitter();
    const { data: userInfo } = useUserInfo();
    const { createSnackbar } = useSnackbar();

    const hasEditPermission = computed(
      () => userInfo.value?.id === props.comment.user_id,
    );
    const hasDeletePermission = computed(
      () =>
        userInfo.value?.role === 'admin' ||
        userInfo.value?.id === props.comment.user_id,
    );

    const openCommentEditModal = () => {
      store.commit('modal/setComment', props.comment);
      store.commit('modal/showModal', 'commentEdit');
    };

    const deleteComment = async () => {
      const deleteAction = async () => {
        await api.deleteComment(props.comment?.id as number).catch(err => {
          createSnackbar({
            message: 'コメントを削除できませんでした',
            type: 'error',
          });
          throw err;
        });
        createSnackbar({
          message: 'コメントを削除しました',
          type: 'success',
        });
        emitter.emit('comment-deleted', props.comment?.id);
        // コメントに記事が紐付いていれば記事情報を更新
        if (props.comment?.article) {
          const article = props.comment.article;
          article.comments = props.comment.article.comments.filter(
            c => c.id !== props.comment?.id,
          );
          emitter.emit('article-updated', article);
        }
      };
      const payload = {
        headerText: 'コメントを削除しますか？',
        bodyText: '',
        action: deleteAction,
      };
      store.commit('confirmationModal/showConfirmationModal');
      store.commit('confirmationModal/setTextAndAction', payload);
    };

    return {
      hasEditPermission,
      hasDeletePermission,
      userInfo,
      openCommentEditModal,
      deleteComment,
    };
  },
});
</script>

<template>
  <div class="article-comment-menu">
    <DgrPopover>
      <template #default="{ triggerProps }">
        <button
          class="ellipsis-button"
          v-if="hasEditPermission || hasDeletePermission"
          v-bind="triggerProps"
        >
          <DgrIcon size="small" name="ellipsis-h" />
        </button>
        <div class="spacing-24" v-else></div>
      </template>
      <template #content="{ close }">
        <div class="comment-menu-body comment-menu-small c-text c-text--m">
          <div
            class="comment-edit"
            v-if="hasEditPermission"
            @click="
              openCommentEditModal();
              close();
            "
          >
            コメントを編集
          </div>
          <div
            class="comment-delete"
            v-if="hasDeletePermission"
            @click="
              deleteComment();
              close();
            "
          >
            コメントを削除
          </div>
        </div>
      </template>
    </DgrPopover>
  </div>
</template>

<style lang="scss" scoped>
.article-comment-menu {
  .ellipsis-button {
    height: 32px;
    width: 32px;
    padding: 8px;
    border-radius: 4px;
    border: none;
    background: none;
    &:hover {
      background: $color-gray400;
    }
  }
}

.comment-menu-body {
  padding: 12px 0;
  background: #ffffff;

  .comment-edit,
  .comment-delete {
    padding: 2px 12px;
    cursor: pointer;
    &:hover {
      background-color: $color-gray200;
    }
  }

  .comment-delete {
    color: $color-orange1000;
  }
}
</style>
