import { computed, ref } from 'vue';
import { Props as SiteCategoriesCheckboxProps } from '@/components/theme/edit/checkbox-list.vue';
import {
  ALLIANCE_MEDIA_VALUE,
  getAllSiteCategories,
  SearchSiteCategory,
  SITE_CATEGORY_LABEL_MAP,
  SiteCategory,
} from '@/utils/siteCategories';
import { useTeamInfo } from '@/utils/swr';

const useSiteCategoryFilter = () => {
  const { data: teamInfo } = useTeamInfo();
  const selectedSearchSiteCategories = ref<SearchSiteCategory[]>([]);
  const selectedSiteCategories = computed<SiteCategory[]>(() => {
    return selectedSearchSiteCategories.value.filter(
      item => item !== ALLIANCE_MEDIA_VALUE,
    ) as SiteCategory[];
  });

  const isAllianceMediaEnabled = computed<boolean>(
    () => !!teamInfo.value?.enable_alliance_media,
  );

  const includeAllianceMedia = computed<boolean>(
    () =>
      isAllianceMediaEnabled.value &&
      selectedSearchSiteCategories.value.includes(ALLIANCE_MEDIA_VALUE),
  );

  const excludeAllianceMedia = computed<boolean>(
    () => !includeAllianceMedia.value,
  );

  const searchSiteCategoryItems = computed<SearchSiteCategory[]>(() =>
    teamInfo.value?.enable_alliance_media
      ? getAllSiteCategories()
      : getAllSiteCategories().filter(c => c !== ALLIANCE_MEDIA_VALUE),
  );

  const searchSiteCategoriesOptions = computed<
    SiteCategoriesCheckboxProps['items']
  >(() => {
    const siteCategoriesOptions = searchSiteCategoryItems.value.map(item => ({
      value: item,
      label:
        SITE_CATEGORY_LABEL_MAP[item as keyof typeof SITE_CATEGORY_LABEL_MAP],
    }));
    return siteCategoriesOptions;
  });

  const addSearchSiteCategory = (category: string) => {
    selectedSearchSiteCategories.value = [
      ...selectedSearchSiteCategories.value,
      category as SiteCategory,
    ];
  };

  const removeSearchSiteCategory = (category: string) => {
    selectedSearchSiteCategories.value = selectedSearchSiteCategories.value
      .slice()
      .filter(item => item !== category);
  };

  return {
    selectedSearchSiteCategories,
    selectedSiteCategories,
    includeAllianceMedia,
    excludeAllianceMedia,
    addSearchSiteCategory,
    removeSearchSiteCategory,
    searchSiteCategoriesOptions,
    isAllianceMediaEnabled,
  };
};

export default useSiteCategoryFilter;
