<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import Loading from '@/components/common/loading.vue';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import Header from '@/components/layouts/header.vue';
import { STATES, useOrganizationTagList } from '@/utils/swr';
import { useIsAdmin } from '@/utils/user';
import BulkEditor from './bulk-editor/bulk-editor.vue';
import ListView from './list-view/list-view.vue';
import NewTagForm from './new-tag-form.vue';

const { createSnackbar } = useSnackbar();

const { data: organizationTagsData, state, mutate } = useOrganizationTagList();
const isEditing = ref(false);
const { isAdmin } = useIsAdmin();
const organizationTagList = computed(
  () => organizationTagsData.value?.organization_tags ?? [],
);
const isLoading = computed(
  () => state.value === STATES.PENDING || state.value === STATES.VALIDATING,
);

watch(
  () => state.value,
  () => {
    if (state.value === STATES.ERROR || state.value === STATES.STALE_IF_ERROR) {
      createSnackbar({
        message: '組織タグの一覧を取得できませんでした',
        type: 'error',
      });
    }
  },
);

const shouldRenderNewTagForm = computed(
  () => organizationTagList.value?.length === 0,
);

const shouldRenderListView = computed(
  () =>
    organizationTagList.value &&
    organizationTagList.value.length > 0 &&
    !isEditing.value,
);

const shouldRenderBulkEditor = computed(
  () =>
    organizationTagList.value &&
    organizationTagList.value.length > 0 &&
    isEditing.value,
);

const startEditing = () => {
  isEditing.value = true;
};
const finishEditing = () => {
  mutate();
  isEditing.value = false;
};
const cancelEditing = () => {
  isEditing.value = false;
};
</script>

<template>
  <div class="admin-organization-tag-list">
    <Header title="組織タグ" header-width="100%" />
    <div class="contents-wrapper">
      <template v-if="isLoading">
        <Loading />
      </template>

      <template v-else-if="shouldRenderNewTagForm">
        <NewTagForm :has-permission="isAdmin" @on-success="mutate" />
      </template>

      <template v-else-if="shouldRenderListView">
        <ListView
          :organization-tag-list="organizationTagList"
          @on-edit="startEditing"
          @data-refresh="mutate"
        />
      </template>

      <template v-else-if="shouldRenderBulkEditor && organizationTagList">
        <BulkEditor
          :organization-tag-list="organizationTagList"
          @on-success="finishEditing"
          @on-cancel="cancelEditing"
        />
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">
.admin-organization-tag-list {
  width: 100%;
  height: 102vh;
  margin: -24px 0 0 0;
  padding: 0 !important;
}

.contents-wrapper {
  position: sticky;
  top: calc(64px + 65px); // グローバルヘッダーの高さ + ページヘッダーの高さ
  height: calc(
    100vh - 64px - 65px
  ); // 画面全体の高さ - グローバルヘッダーの高さ - ページヘッダーの高さ
}
</style>
