<script setup lang="ts"></script>

<template>
  <div class="wrapper">
    <div class="empty-message">
      <div class="c-title c-title--s">
        このリストに表示できるマークがありません。
      </div>
      <div class="c-text c-text--s">
        メンバーがマークをすると、リストにマークした情報が表示されます。
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.wrapper {
  height: 100%;
  display: grid;
  place-items: center;
}

.empty-message {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 16px 12px;
  gap: 16px;
  border: 1px solid $color-gray400;
  border-radius: 4px;
}
</style>
