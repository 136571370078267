import '@/assets/global.css.scss';
import app from '@/pcApp';
import DonguriUI from '@stockmarkteam/donguri-ui';
import '@stockmarkteam/donguri-ui/dist/donguri-ui.css';
import '@stockmarkteam/donguri-ui/lib/css/aseries.css';
import dgrKeepFillSvg from '@stockmarkteam/donguri-ui/lib/sprite.keep-fill.svg?raw';
import dgrOnlySymbolSvg from '@stockmarkteam/donguri-ui/lib/sprite.only-symbol.svg?raw';
import svg from '@stockmarkteam/donguri-ui/lib/sprite.symbol.svg?raw';
import FloatingVue from 'floating-vue';
import 'regenerator-runtime/runtime';
import { isMobileUser, isTabletUser } from '@/utils';
import { featureFlags } from './featureFlags';
import { initializeDayjs } from './utils/dayjs';
import { errorHandler } from './utils/errorHandler';

initializeDayjs();

// Sentry.initの中でもerrorHandlerが設定される(かつ、既に設定されていればそれも
// 呼んでくれる)ので、それより先にerrorHandlerを設定する
app.config.errorHandler = errorHandler;

app.use(DonguriUI);
app.use(FloatingVue, {
  themes: {
    tooltip: {
      html: false,
      delay: {
        show: 300,
        hide: 0,
      },
      placement: 'bottom',
    },
    white: {
      $extend: 'tooltip',
    },
    small: {
      $extend: 'tooltip',
    },
    menu: {
      triggers: ['hover'],
      popperTriggers: ['hover', 'click'],
      delay: {
        show: 300,
        hide: 0,
      },
      $extend: 'tooltip',
    },
  },
});

app.config.globalProperties.$featureFlags = featureFlags;

if (!isMobileUser()) {
  document.body.insertAdjacentHTML('afterend', svg);
  document.body.insertAdjacentHTML('afterend', dgrOnlySymbolSvg);
  document.body.insertAdjacentHTML('afterend', dgrKeepFillSvg);
}

if (isTabletUser()) {
  document
    .querySelector("meta[name='viewport']")
    ?.setAttribute('content', 'width=1024,initial-scale=0.7,user-scalable=0');
}

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register(
    import.meta.env.VITE_DEV_SW ? '/dev-sw.js?dev-sw' : '/serviceWorker.js',
    { type: 'module' },
  );
}

// リリースされるとDynamic Importしている各チャンクのハッシュ値が変わり、
// 古いコードで画面遷移するときにファイルが見つからずエラーになるため、
// その場合はリロードして新しいコードを読ませるようにする。
// 参考: https://ja.vitejs.dev/guide/build#%E8%AA%AD%E3%81%BF%E8%BE%BC%E3%81%BF%E3%82%A8%E3%83%A9%E3%83%BC%E3%81%AE%E3%83%8F%E3%83%B3%E3%83%88%E3%82%99%E3%83%AA%E3%83%B3%E3%82%AF%E3%82%99
window.addEventListener('vite:preloadError', event => {
  event.preventDefault();
  window.location.reload();
});
