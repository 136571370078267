<script setup lang="ts">
import { ref } from 'vue';
import api from '@/api';
import { DgrIcon } from '@stockmarkteam/donguri-ui';
import UserDocumentPreviewModal from '@/components/user-document/user-document-preview-modal.vue';
import { UserDocumentViewInfo, UserDocumentViewWithParentInfo } from '@/types';

const isUserDocumentPreviewModalOpen = ref(false);
interface Props {
  title: string;
  targetPage: number | undefined;
  fileTotalPages: number;
  previewImagePathTemplate: string;
  userDocumentViewInfo: UserDocumentViewInfo | UserDocumentViewWithParentInfo;
}
const props = defineProps<Props>();
const openPreviewModal = async () => {
  isUserDocumentPreviewModalOpen.value = true;
  await api.trackingUserDocumentEvent(
    'user_document_view',
    props.userDocumentViewInfo,
  );
};
</script>

<template>
  <button class="preview-button small" @click="openPreviewModal">
    <DgrIcon size="small" name="preview" />
    {{ targetPage }}ページ目を見る
  </button>
  <UserDocumentPreviewModal
    :is-open="isUserDocumentPreviewModalOpen"
    :title="title"
    :target-page="targetPage"
    :file-total-pages="fileTotalPages"
    :preview-image-path-template="previewImagePathTemplate"
    @on-close="() => (isUserDocumentPreviewModalOpen = false)"
  />
</template>

<style scoped lang="scss">
button.preview-button {
  border: none;
  background-color: $color-white;
  padding: 5px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  &:hover {
    background: $color-gray200;
  }
}
</style>
