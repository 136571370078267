<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  Ref,
  UnwrapRef,
} from 'vue';
import { useRoute } from 'vue-router';
import api from '@/api';
import { PageName } from '@/api/tracking';
import { TOOLTIP_FOR_TEAM_FEATURED_ARTICLES } from '@/constants';
import AdpDocumentCard from '@/components/common/adp-document-card.vue';
import QuestionTooltip from '@/components/common/atoms/question-tooltip.vue';
import RankBadge from '@/components/common/atoms/rank-badge.vue';
import SwrScrollingPagination from '@/components/common/swr-scrolling-pagination.vue';
import Content from '@/components/layouts/content.vue';
import Header from '@/components/layouts/header.vue';
import { Article } from '@/types';
import { useTeamFeaturedArticles, useTeamInfo } from '@/utils/swr';
import { useEmitter } from '@/utils/vue';

const PAGE_NAME: PageName = 'feature_articles';

export default defineComponent({
  components: {
    AdpDocumentCard,
    Header,
    Content,
    SwrScrollingPagination,
    RankBadge,
    QuestionTooltip,
  },
  setup() {
    const route = useRoute();
    const emitter = useEmitter();
    const { data: teamInfo } = useTeamInfo();

    const industryId = computed(() => {
      return teamInfo.value?.industry_id;
    });

    onMounted(async () => {
      await api.trackPageView({
        pageName: PAGE_NAME,
        pageUrl: route.fullPath,
      });
      emitter.on('article-updated', handleArticleUpdate);
    });

    onUnmounted(() => {
      emitter.off('article-updated', handleArticleUpdate);
    });

    const articleFilter = (article: Article, id: Article['id']) => {
      return article.id === id;
    };

    const updateArticle = (articles: Article[], updatedArticle: Article) => {
      const index = articles.findIndex(a => {
        return a.id === updatedArticle.id;
      });
      if (index >= 0) {
        articles[index].marks = updatedArticle.marks;
      }
      return articles;
    };

    const handleArticleUpdate = (article: Article) => {
      emitter.emit('pagination-items-update', {
        filterFunc: (i: Article) => articleFilter(i, article.id),
        updateFunc: (items: Article[]) => updateArticle(items, article),
      });
    };

    return {
      pageLimit: 10,
      paginationFunc: (pageRef: Ref<number>, pageLimit: number) =>
        useTeamFeaturedArticles(pageRef, pageLimit),
      dataAccessor: (
        d: UnwrapRef<ReturnType<typeof useTeamFeaturedArticles>['data']>,
      ) => d?.featured_articles,
      TOOLTIP_FOR_TEAM_FEATURED_ARTICLES,
      industryId,
      PAGE_NAME,
    };
  },
});
</script>

<template>
  <div class="o-team-activity-feed">
    <Header title="注目記事">
      <template #titleMenu>
        <QuestionTooltip
          class="icon"
          :text="TOOLTIP_FOR_TEAM_FEATURED_ARTICLES"
        ></QuestionTooltip>
      </template>
    </Header>
    <Content>
      <div class="o-feed">
        <SwrScrollingPagination
          ref="pagination"
          :page-limit="pageLimit"
          :pagination-func="paginationFunc"
          :data-accessor="dataAccessor"
        >
          <template v-slot="{ items, loaded }">
            <div
              class="o-article-card"
              v-for="(article, index) in items"
              :key="article.id"
            >
              <AdpDocumentCard
                :page-name="PAGE_NAME"
                :adp-document="article"
                :show-comment-count="1"
                :disable-related-articles="false"
                feature="team_feature_articles"
              ></AdpDocumentCard>
              <RankBadge
                class="rank-badge-outer"
                v-if="index <= 4"
                :rank="index + 1"
              ></RankBadge>
            </div>
            <div
              class="o-no-featured-articles"
              v-if="loaded && items.length === 0"
            >
              <div
                class="o-textarea-explanation o-description c-text c-text--m"
              >
                マークやコメントがないため注目記事がありません。<br />
                気になった記事があればマークやコメントをしていきましょう。
              </div>
              <router-link :to="{ name: 'anewsHome' }">
                <div class="o-link-to-home o-description c-text c-text--m">
                  ホームへ移動
                </div>
              </router-link>
            </div>
          </template>
        </SwrScrollingPagination>
      </div>
    </Content>
  </div>
</template>
<style lang="scss" scoped>
.o-team-activity-feed {
  width: 100%;
  margin: -24px 0 0 0;
  .o-feed {
    display: flex;
  }
  .icon {
    margin-left: 4px;
  }
  .o-no-featured-articles {
    width: 616px;
    flex-direction: row;
    justify-content: space-between;
    background: #ffffff;
    border: 1px solid #e6e6e6;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 16px;
    .o-textarea-explanation {
      margin-bottom: 12px;
    }
    .o-link-to-home {
      color: #1da482;
    }
  }
  .o-article-card {
    margin-bottom: 10px;
    position: relative;

    .rank-badge-outer {
      position: absolute;
      top: 10px;
      left: 10px;
    }
  }
  .right-column {
    width: 288px;
  }
}
</style>
