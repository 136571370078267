<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  reactive,
  ref,
} from 'vue';
import api from '@/api';
import { Feature, PageName } from '@/api/tracking';
import { DgrLoading } from '@stockmarkteam/donguri-ui';
import { AxiosError } from 'axios';
import dayjs, { Dayjs } from 'dayjs';
import { Article, ArticleMarkSource, Theme } from '@/types';
import { getIncludedSiteCategories } from '@/utils/siteCategories';
import AdpDocumentCard from '../common/adp-document-card.vue';
import BasicEmptyFeed from '../common/basic-empty-feed.vue';

const DEFAULT_INITIAL_PAST_ARTICLE_COUNT = 20;
const DEFAULT_PAST_ARTICLE_MAX_LIMIT_COUNT = 200;

export default defineComponent({
  props: {
    keywords: { type: Array as PropType<string[]>, default: () => [] },
    lang: { type: String as PropType<'ja' | 'en'>, default: 'ja' },
    isEntertainmentFilterEnabled: { type: Boolean, default: false },
    limit: { type: Number, default: DEFAULT_PAST_ARTICLE_MAX_LIMIT_COUNT },
    initialFetchArticleCount: {
      type: Number,
      default: DEFAULT_INITIAL_PAST_ARTICLE_COUNT,
    },
    // eslint-disable-next-line vue/prop-name-casing
    to_date: {
      type: Object as PropType<Dayjs>,
      default: () => dayjs(),
    },
    theme: { type: Object as PropType<Theme>, required: true },
    pageName: { type: String as PropType<PageName>, required: true },
    markSource: { type: String as PropType<ArticleMarkSource>, rquired: true },
    feature: { type: String as PropType<Feature>, required: true },
    rankOffset: { type: Number, required: true },
  },
  components: { AdpDocumentCard, BasicEmptyFeed, DgrLoading },
  setup(props) {
    const searchedArticles = reactive({
      list: undefined as Article[] | undefined,
    });

    const isLoading = ref(false);
    const showLoadAllButton = ref(false);
    let totalArticleCount = 0;

    const getPastArticlesApiParams = ({
      offset,
      limit,
    }: {
      offset: number;
      limit: number;
    }) => ({
      keywords: props.keywords.filter(keyword => keyword !== ''),
      lang: props.lang,
      entertainment_filter: props.theme.is_entertainment_sites_filtered,
      to_date: props.to_date.format('YYYY/MM/DD'),
      exclude_site_ids: props.theme.site_exclusion_ids,
      site_categories: getIncludedSiteCategories(
        props.theme.excluded_site_categories || [],
      ),
      include_alliance_media: !props.theme.exclude_alliance_media,
      offset: offset,
      limit: limit,
    });

    onMounted(async () => {
      isLoading.value = true;
      const searchResult = await api
        .searchPastArticles(
          getPastArticlesApiParams({
            offset: 0,
            limit: props.initialFetchArticleCount,
          }),
        )
        .catch((e: AxiosError) => {
          isLoading.value = false;
          throw e;
        });
      totalArticleCount = searchResult.result_count;
      searchedArticles.list = searchResult.articles.map(
        (article: Article, index: number) => {
          // 計測用の値を追加して返す必要がある
          return { ...article, rank: index + 1, isPastArticle: true };
        },
      );
      isLoading.value = false;
      if (searchResult.result_count > props.initialFetchArticleCount) {
        showLoadAllButton.value = true;
      }
    });

    const displayArticles = computed<Article[]>(() => {
      if (searchedArticles.list === undefined) return [];
      return searchedArticles.list;
    });

    const moreArticleCount = computed<number>(() => {
      if (searchedArticles.list === undefined) return 0;
      const pastArticleCountLimit = Math.min(totalArticleCount, props.limit);
      const moreCount = pastArticleCountLimit - props.initialFetchArticleCount;
      return Math.max(0, moreCount);
    });

    const addArticles = async () => {
      showLoadAllButton.value = false;
      isLoading.value = true;
      const searchResult = await api
        .searchPastArticles(
          getPastArticlesApiParams({
            offset: props.initialFetchArticleCount,
            limit: props.limit - props.initialFetchArticleCount,
          }),
        )
        .catch((e: AxiosError) => {
          showLoadAllButton.value = true;
          isLoading.value = false;
          throw e;
        });
      searchedArticles.list = searchedArticles.list!.concat(
        searchResult.articles.map((article: Article, index: number) => {
          // 計測用の値を追加して返す必要がある
          return { ...article, rank: index + 1, isPastArticle: true };
        }),
      );
      isLoading.value = false;
    };

    return {
      searchedArticles,
      dayjs,
      isLoading,
      showLoadAllButton,
      displayArticles,
      moreArticleCount,
      addArticles,
    };
  },
});
</script>

<template>
  <div>
    <div
      class="article-card"
      v-for="(article, index) in displayArticles"
      :key="article.id"
    >
      <AdpDocumentCard
        width="100%"
        :adp-document="article"
        :theme-id="theme.id"
        :feed-type="lang === 'ja' ? 'domestic' : 'foreign'"
        :rank-in-whole-feed="rankOffset + (article.rank - 1)"
        :show-comment-count="1"
        :hide-undisplay-button="true"
        :mark-source="markSource"
        :page-name="pageName"
        :feature="feature"
        :disable-related-articles="false"
      ></AdpDocumentCard>
      <div v-if="index !== displayArticles.length - 1" class="divider"></div>
    </div>
    <div v-if="showLoadAllButton" class="view-more-button__wrapper">
      <div class="spacing-16"></div>
      <button
        class="view-more-button o-theme-edit-save outlined c-outlineBtn c-btn--auto c-btn--small c-outlineBtn--secondary"
        @click="addArticles"
      >
        すべて表示({{ moreArticleCount }})
      </button>
    </div>
    <div v-if="searchedArticles.list && searchedArticles.list.length === 0">
      <BasicEmptyFeed>過去のニュースはありませんでした。</BasicEmptyFeed>
    </div>
    <div v-if="isLoading" class="loading-container">
      <DgrLoading class="loading" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.loading-container {
  display: flex;
  justify-content: center;
}

.view-more-button__wrapper {
  text-align: center;
  .view-more-button {
    display: inline-flex;
    align-self: center;
    padding: 4px 12px;
    border-radius: 4px;
    border: solid 1px $color-green600;
    background: #fff;
    color: $color-green600;
    cursor: pointer;

    &:hover {
      background-color: $color-green100;
    }
  }
}

.article-card {
  background: #fff;
  .document-card {
    border: 0;
  }
  .divider {
    width: auto;
    border-bottom: 0.5px solid #e6e6e6;
    margin: 0 16px 0 16px;
  }
}
</style>
