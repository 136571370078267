<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';
import ThemePerspectiveSelectForm from '@/components/common/theme-perspective-select-form.vue';
import ThemeTopicOneSelectForm from '@/components/common/theme-topic-one-select-form.vue';
import { Perspective, ThemeTopic } from '@/types';
import { useEmitter, useStore } from '@/utils/vue';

export default defineComponent({
  components: {
    ThemeTopicOneSelectForm,
    ThemePerspectiveSelectForm,
  },
  setup() {
    const emitter = useEmitter();
    const store = useStore();
    const selectedTopics = ref<ThemeTopic[] | undefined>(undefined);
    const selectThemeTopic = (topic: ThemeTopic) => {
      selectedTopics.value = [topic];
      isSelectTopic.value = false;
    };
    const isSelectTopic = ref<boolean>(true);

    const hideDialog = () => {
      store.commit('modal/hideModal');
    };

    const submit = async () => {
      emitter.emit('select-theme-topics', [
        selectedTopics.value,
        selectPerspective.value,
      ]);
      hideDialog();
    };

    onMounted(async () => {
      const headerEl = document.querySelector<HTMLElement>('.dialog-header');
      if (headerEl) {
        headerEl.style.height = '32px';
      }
    });

    const selectPerspective = ref<Perspective | undefined>(undefined);

    const selectedPerspective = (perspective: Perspective) => {
      selectPerspective.value = perspective;
      emitter.emit('select-perspective', selectPerspective.value);
    };

    return {
      selectThemeTopic,
      hideDialog,
      submit,
      isSelectTopic,
      selectedTopics,
      selectPerspective,
      selectedPerspective,
    };
  },
});
</script>

<template>
  <div>
    <sm-dialog class="auto-width auto-height screen-center" @close="hideDialog">
      <template #header>
        <div class="o-header c-text c-text--m modal-header">トピック一覧</div>
      </template>
      <template #body>
        <div v-if="isSelectTopic" class="o-body c-text c-text--m modal-body">
          <div class="c-text c-text--s">ステップ 1/2</div>
          <div class="c-title c-title--xl">あなたの興味を教えてください</div>
          <div class="spacing-12" />
          <div class="c-text c-text--m">
            あなたの興味や業務に関連しそうなトピックを選んでください。
          </div>
          <ThemeTopicOneSelectForm
            class="theme-topic-select-form"
            @select-theme-topic="selectThemeTopic"
          ></ThemeTopicOneSelectForm>
        </div>
        <div v-else class="o-body c-text c-text--m modal-body">
          <div class="c-text c-text--s">ステップ 2/2</div>
          <ThemePerspectiveSelectForm
            class="theme-topic-select-form"
            :select-perspective="selectPerspective"
            @selected-perspective="selectedPerspective"
          ></ThemePerspectiveSelectForm>
        </div>
      </template>
      <template #footer>
        <div class="o-footer c-text c-text--m">
          <button
            class="o-cancel-button c-btn c-btn--auto c-btnOutline"
            @click="hideDialog"
          >
            キャンセル
          </button>
          <template v-if="!isSelectTopic">
            <button
              class="o-cancel-button c-btn c-btn--auto c-btnOutline"
              @click="isSelectTopic = true"
            >
              戻る
            </button>
            <button
              class="o-create-button c-btn c-btn--auto c-btn--AnewsPrimary"
              @click="submit"
            >
              追加
            </button>
          </template>
        </div>
      </template>
    </sm-dialog>
  </div>
</template>
<style lang="scss" scoped>
.theme-topic-select-form {
  width: 800px;
}
.modal-body {
  overflow: auto;
  height: 50vh;
}
.back-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 20px;
  height: 20px;

  &:hover {
    background: $color-link-menu-hover;
  }
}
</style>
