<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue';
import { MAX_IP_ADDRESS_RESTRICTION_DESCRIPTION } from '@/constants';
import { validateIpAddress } from '@/utils/ipAddress';

export default defineComponent({
  props: {
    ipAddress: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    isDisplayLabel: {
      type: Boolean,
      default: true,
    },
    isDisplayDeleteButton: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['update:ipAddress', 'update:description', 'onValid', 'onDelete'],
  setup(props, { emit }) {
    const formIpAddress = ref(props.ipAddress);
    const formDescription = ref(props.description);

    const isEmptyIpAddress = computed(() => formIpAddress.value === '');
    const isInvalidIpAddress = computed(
      () => !validateIpAddress(formIpAddress.value),
    );
    const isDescriptionTooLong = computed(
      () =>
        formDescription.value.length > MAX_IP_ADDRESS_RESTRICTION_DESCRIPTION,
    );
    const isIpAddressChanged = ref(false);
    watch(formIpAddress, () => (isIpAddressChanged.value = true));

    watch([isEmptyIpAddress, isInvalidIpAddress, isDescriptionTooLong], () =>
      emit(
        'onValid',
        !(
          isEmptyIpAddress.value ||
          isInvalidIpAddress.value ||
          isDescriptionTooLong.value
        ),
      ),
    );

    return {
      formIpAddress,
      formDescription,
      MAX_IP_ADDRESS_RESTRICTION_DESCRIPTION,
      isEmptyIpAddress,
      isInvalidIpAddress,
      isDescriptionTooLong,
      isIpAddressChanged,
    };
  },
});
</script>
<template>
  <div class="ip-address-restriction-form">
    <div class="ip-address-form c-formBlock">
      <div v-if="isDisplayLabel" class="c-formBlock__label">IPアドレス</div>
      <input
        class="c-text c-text--m c-textInput"
        v-model="formIpAddress"
        placeholder="例：203.0.113.0"
        @input="
          $emit('update:ipAddress', ($event.target as HTMLInputElement).value)
        "
        :class="{
          'c-formInput--error':
            isIpAddressChanged && (isEmptyIpAddress || isInvalidIpAddress),
        }"
      />
      <div
        class="c-formBlock__text modal-error-message"
        v-if="isInvalidIpAddress && !isEmptyIpAddress"
      >
        <span class="c-text--s">不正なIPアドレスが入力されています。</span>
      </div>
      <div
        class="c-formBlock__text modal-error-message"
        v-if="isIpAddressChanged && isEmptyIpAddress"
      >
        <span class="c-text--s">IPアドレスが空欄です。</span>
      </div>
      <div class="spacing-08"></div>
    </div>
    <div class="spacing-08"></div>
    <div class="description-form c-formBlock">
      <div v-if="isDisplayLabel" class="c-formBlock__label">備考</div>
      <input
        class="c-text c-text--m c-textInput"
        v-model="formDescription"
        placeholder="例：会社用IPアドレス"
        @input="
          $emit('update:description', ($event.target as HTMLInputElement).value)
        "
        :class="{
          'c-formInput--error': isDescriptionTooLong,
        }"
      />
      <div
        :class="{
          'modal-error-message': isDescriptionTooLong,
        }"
        class="modal-description-word-count"
      >
        <span class="c-text c-text--xs" v-if="isDescriptionTooLong">
          {{
            MAX_IP_ADDRESS_RESTRICTION_DESCRIPTION
          }}文字以内で入力してください。
        </span>
        <span class="c-text c-text--xs">
          {{
            formDescription.length +
            '/' +
            MAX_IP_ADDRESS_RESTRICTION_DESCRIPTION
          }}
        </span>
      </div>
    </div>
    <div class="spacing-08"></div>
    <div
      class="delete-form-button"
      :class="{ 'delete-button-with-label': isDisplayLabel }"
    >
      <button
        v-if="isDisplayDeleteButton"
        class="c-btn c-btn--auto c-btnOutline"
        @click="$emit('onDelete')"
      >
        削除
      </button>
    </div>
  </div>
</template>
,
<style lang="scss" scoped>
.ip-address-restriction-form {
  display: flex;
  .modal-description-word-count {
    justify-content: flex-end;
    color: $color-gray600;
  }
  .modal-error-message {
    color: $color-orange1000;
  }
  .ip-address-form {
    width: 228px;
  }
  .description-form {
    flex-grow: 1;
  }
  .delete-button-with-label {
    padding-top: 26px;
  }
  .delete-form-button {
    width: 62px;
  }
}
</style>
