<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { Feature, PageName } from '@/api/tracking';
import { TOOLTIP_FOR_TEAM_FEATURED_ARTICLES } from '@/constants';
import { DgrIcon } from '@stockmarkteam/donguri-ui';
import 'lazysizes';
import ArticleCardSmall from '@/components/common/article/article-card-small.vue';
import { Article } from '@/types';

export default defineComponent({
  props: {
    pageName: { type: String as PropType<PageName>, required: true },
    feature: { type: String as PropType<Feature>, required: true },
    articles: { type: Array as PropType<Article[]>, required: true },
    themeId: { type: Number },
    title: { type: String, default: '注目の記事' },
    tooltipText: { type: String, default: TOOLTIP_FOR_TEAM_FEATURED_ARTICLES },
    linkTo: { type: String },
  },
  components: {
    ArticleCardSmall,
    DgrIcon,
  },
  setup() {},
});
</script>

<template>
  <div class="featured-article-list">
    <div class="list-title">
      <div class="text c-title c-title--s">{{ title }}</div>
      <div class="icon" v-if="articles.length > 0">
        <VTooltip placement="top" :triggers="['click']">
          <DgrIcon size="small" name="question-circle" />
          <template #popper>
            <span>{{ tooltipText }}</span>
          </template>
        </VTooltip>
      </div>
    </div>
    <div
      class="articles"
      v-for="(article, index) in articles"
      :key="article.id"
    >
      <ArticleCardSmall
        :article="article"
        :rank="index + 1"
        :show-rank-badge="true"
        :theme-id="themeId"
        :page-name="pageName"
        :feature="feature"
      ></ArticleCardSmall>
      <div class="divider" v-if="linkTo || index < articles.length - 1"></div>
    </div>
    <div
      class="featured-article-page-link c-title c-title--s"
      v-if="linkTo && articles.length > 0"
    >
      <router-link class="link" :to="linkTo">もっと見る</router-link>
    </div>
    <div class="empty-articles" v-if="articles && articles.length === 0">
      <div class="image-wrap">
        <img class="image" src="@/assets/state-empty-articles.png" />
      </div>
      <div class="message c-text c-text--m">
        記事に対してマークやコメントをすることで注目の記事がランキング表示されます。
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.featured-article-list {
  padding: 16px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  box-sizing: border-box;
  background: #ffffff;

  .list-title {
    display: flex;
    margin-bottom: 16px;

    .icon {
      margin-left: 4px;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .divider {
    height: 1px;
    background: #e6e6e6;
    margin: 12px 0;
  }
  .featured-article-page-link {
    display: flex;
    justify-content: center;

    .link {
      padding: 4px;
      color: #1da482;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .empty-articles {
    .image-wrap {
      display: flex;
      justify-content: center;
      .image {
        width: 200px;
        height: 108px;
      }
    }
    .message {
      margin-top: 12px;
      padding: 4px;
    }
  }
}
</style>
