<script setup lang="ts" generic="ResponseType, ItemType">
import { onBeforeUnmount, onMounted, onUpdated, ref, Ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { Pagination } from '@/utils';
import { STATES, useSWRVWithState } from '@/utils/swr';
import { useEmitter } from '@/utils/vue';

const props = withDefaults(
  defineProps<{
    pageLimit: number;
    paginationFunc: (
      page: Ref<number>,
      offset: Ref<number>,
      limit: number,
    ) => ReturnType<typeof useSWRVWithState<ResponseType>>;
    dataAccessor: (
      response: ResponseType | undefined,
    ) => ItemType[] | undefined;
    pageInfoAccessor: (
      response: ResponseType | undefined,
    ) => { next: number | null; page: number } | undefined;
    scrollTarget?: 'window' | 'self';
  }>(),
  {
    scrollTarget: 'window',
  },
);

const route = useRoute();
const emitter = useEmitter();

// MEMO: propsのdestructuringはtoRefsを使うべきだが、
// コンポーネントの作りとして、paginationFuncやdataAccessorの
// 変更には対応していないので、そのままdestructuringしている。
const { pageLimit, paginationFunc, dataAccessor, pageInfoAccessor } = props;

const container: Ref<ItemType[]> = ref([]);

const page = ref(1);
const offset = ref(0);
const { data, state, error, isValidating } = paginationFunc(
  page,
  offset,
  pageLimit,
);

const pageHistories: number[] = [];

const responseItems = () => dataAccessor(data?.value) ?? [];
const responsePageInfo = () => pageInfoAccessor(data?.value);

let pagination: Pagination | undefined = undefined;

const emit = defineEmits<{
  error: [status?: number];
  fetch: [data: ResponseType];
}>();

watch(
  () => error.value,
  () => {
    if (data.value === undefined && error.value) {
      emit('error', error.value.response?.status);
    }
  },
  { immediate: true },
);

const addPage = () => {
  const pageInfo = responsePageInfo();
  if (
    state.value === STATES.SUCCESS &&
    pageInfo !== undefined &&
    pageInfo.next !== null
  ) {
    page.value = pageInfo.page + 1;
    offset.value = pageInfo.next;
  }
};

if (props.scrollTarget === 'window') {
  pagination = new Pagination(addPage, 0);
}

// キャッシュデータと新規データを置換するハンドリングを行うため、itemsの置換位置特定を目的としてpageHistoryを利用する
watch(
  data,
  () => {
    const items = responseItems();
    const pageInfo = responsePageInfo();

    if (pageInfo === undefined) return;

    if (items.length > 0) {
      let startIndex: number;
      let deleteCount = 0;
      const history = pageHistories.at(pageInfo.page);

      // swrvのキャッシュがアクティブかどうかで条件分岐している
      if (history !== undefined) {
        startIndex = pageHistories
          .slice(0, pageInfo.page)
          .reduce((sum, count) => sum + count, 0);
        deleteCount = history;
      } else {
        startIndex = container.value.length;
      }
      pageHistories[pageInfo.page] = items.length;
      container.value.splice(startIndex, deleteCount, ...items);

      if (data.value !== undefined) {
        emit('fetch', data.value);
      }
    }
  },
  { immediate: true },
);

onUpdated(() => {
  if (pagination?.checkOverContentHeightByScrollElement()) {
    addPage();
  }
});

const reset = () => {
  page.value = 1;
  container.value.splice(0, container.value.length);
  pageHistories.splice(0, pageHistories.length);
};

watch(
  () => route.path,
  () => reset(),
);

const handleCreate = (item: ItemType) => {
  container.value.unshift(item);
};
const handleUpdate = (funcs: {
  filterFunc: (i: ItemType) => boolean;
  updateFunc: (items: ItemType[]) => void;
}) => {
  const items = container.value.filter(funcs.filterFunc);
  if (items.length > 0) {
    funcs.updateFunc(items);
  }
};
const handleDelete = (deleteFunc: (items: ItemType[]) => void) => {
  deleteFunc(container.value);
};

const scrollerRef = ref<HTMLElement>();
onMounted(() => {
  if (props.scrollTarget === 'self') {
    pagination = new Pagination(addPage, 0, {
      element: scrollerRef.value,
    });
  }
  emitter.on('pagination-item-create', handleCreate);
  emitter.on('pagination-items-update', handleUpdate);
  emitter.on('pagination-item-delete', handleDelete);
});

onBeforeUnmount(() => {
  pagination?.removeEvent();
  reset();
  emitter.off('pagination-item-create', handleCreate);
  emitter.off('pagination-items-update', handleUpdate);
  emitter.off('pagination-item-delete', handleDelete);
});
</script>
<template>
  <div class="items" ref="scrollerRef">
    <slot :items="container" :loading="isValidating"></slot>
  </div>
</template>
