<script setup lang="ts">
import { DgrCheckbox } from '@stockmarkteam/donguri-ui';

export interface Props {
  items: { label: string; value: string }[];
  checkedValues: string[];
  disabled?: boolean;
}

const props = defineProps<Props>();

interface Emits {
  (e: 'checkedItem', value: string): void;
  (e: 'unCheckedItem', value: string): void;
}

const emit = defineEmits<Emits>();

const isCheckedItem = (item: string) => props.checkedValues.includes(item);

const toggleCheckedItem = (item: string) => {
  if (isCheckedItem(item)) {
    emit('unCheckedItem', item);
  } else {
    emit('checkedItem', item);
  }
};
</script>

<template>
  <div class="checkbox">
    <DgrCheckbox
      v-for="item in items"
      :key="item.value"
      class="item c-text c-text--m"
      :model-value="isCheckedItem(item.value)"
      :disabled="disabled"
      @update:model-value="toggleCheckedItem(item.value)"
      >{{ item.label }}
    </DgrCheckbox>
  </div>
</template>

<style lang="scss" scoped>
.checkbox {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
</style>
