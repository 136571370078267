import type { OrganizationTagList } from '@/types';
import { checkIsTooLongOrganizationTagName } from '@/utils/validators';
import type {
  BulkOperationApiParams,
  FormData,
  FormDataItem,
  NewTagDataItem,
  UpdatedTagDataItem,
} from './types';

export const checkIsDuplicatedName = ({
  id,
  formData,
}: {
  id: FormDataItem['id'];
  formData: FormData;
}): boolean =>
  Object.values(formData).some(formDataItem => {
    if (formDataItem.id === id) {
      return false;
    }

    if (formDataItem.isDeleted) {
      return false;
    }

    return formDataItem.name.toLowerCase() === formData[id].name.toLowerCase();
  });

export const checkIsValidTagName = ({
  id,
  formData,
}: {
  id: FormDataItem['id'];
  formData: FormData;
}): boolean => {
  const name = formData[id].name;

  return (
    name.length > 0 &&
    !checkIsTooLongOrganizationTagName(name) &&
    !checkIsDuplicatedName({ id, formData })
  );
};

export const checkIsValidAllTagNames = (formData: FormData) => {
  const activeTagNames = Object.values(formData).filter(
    formDataItem => !formDataItem.isDeleted,
  );

  const hasInvalidLengthTagName = activeTagNames.some(
    ({ name }) => name.length <= 0 || checkIsTooLongOrganizationTagName(name),
  );

  if (hasInvalidLengthTagName) {
    return false;
  }

  const uniqueActiveTagNames = [
    ...new Set(
      activeTagNames.map(formDataItem => formDataItem.name.toLowerCase()),
    ),
  ];

  return activeTagNames.length === uniqueActiveTagNames.length;
};

export const initializeFormData = (
  organizationTagList: OrganizationTagList,
): FormData =>
  organizationTagList.reduce(
    (accData, organizationTag) => ({
      ...accData,
      [organizationTag.id]: {
        id: organizationTag.id,
        name: organizationTag.name,
        displayOrder: organizationTag.order,
        previousName: organizationTag.name,
        previousOrder: organizationTag.order,
        isNewTag: false,
        isDeleted: false,
        memberCount: organizationTag.member_count,
      },
    }),
    {} as FormData,
  );

export const isNewTagDataItem = (
  formDataItem: FormDataItem,
): formDataItem is NewTagDataItem => formDataItem.isNewTag;

export const isUpdatedTagDataItem = (
  formDataItem: FormDataItem,
): formDataItem is UpdatedTagDataItem => !formDataItem.isNewTag;

export const transformFormDataToApiParams = (
  formData: FormData,
): BulkOperationApiParams => {
  const isValidAllTagNames = checkIsValidAllTagNames(formData);

  if (!isValidAllTagNames) {
    return {};
  }

  const newOrderMapById = Object.values(formData)
    .sort((a, b) => a.displayOrder - b.displayOrder)
    .filter(item => !item.isDeleted)
    .reduce(
      (accMap, item, index) => {
        accMap[item.id] = index + 1;
        return accMap;
      },
      {} as Record<FormDataItem['id'], number>,
    );

  return Object.values(formData).reduce((accParams, formDataItem) => {
    if (isUpdatedTagDataItem(formDataItem)) {
      if (formDataItem.isDeleted) {
        return {
          ...accParams,
          deleteTagIds: [...(accParams.deleteTagIds ?? []), formDataItem.id],
        };
      } else {
        const updatedTag: Required<BulkOperationApiParams>['updateTags'][number] =
          {
            id: formDataItem.id,
          };

        const hasChangedName = formDataItem.name !== formDataItem.previousName;

        if (hasChangedName) {
          updatedTag.name = formDataItem.name;
        }

        const hasChangedOrder =
          newOrderMapById[formDataItem.id] !== formDataItem.previousOrder;

        if (hasChangedOrder) {
          updatedTag.order = newOrderMapById[formDataItem.id];
        }

        if (hasChangedName || hasChangedOrder) {
          return {
            ...accParams,
            updateTags: [...(accParams.updateTags ?? []), updatedTag],
          };
        } else {
          return accParams;
        }
      }
    }

    if (isNewTagDataItem(formDataItem)) {
      if (formDataItem.isDeleted) {
        return accParams;
      }

      return {
        ...accParams,
        newTags: [
          ...(accParams.newTags ?? []),
          {
            name: formDataItem.name,
            order: newOrderMapById[formDataItem.id],
          },
        ],
      };
    }

    return accParams;
  }, {} as Required<BulkOperationApiParams>);
};
