<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import api from '@/api';
import { NOTIFICATION_GROUPINGS, NOTIFICATION_TYPE } from '@/constants';
import { DgrIcon, DgrToggleButton } from '@stockmarkteam/donguri-ui';
import PopupDialogNotificationCard from '@/components/notifications/header/popup-dialog-notification-card.vue';
import NotificationMark from '@/components/notifications/notification-mark.vue';
import {
  NotificationGroupingName,
  NotificationItem,
  NotificationType,
  NotificationUnviewedAndUnreadCounts,
} from '@/types';
import { useWebPush } from '@/utils/composables/useWebPush';
import { useNotificationsUnviewedUnreadCount } from '@/utils/swr';
import { useEmitter, useStore } from '@/utils/vue';

type UnreadCount = NotificationUnviewedAndUnreadCounts<
  Record<string, readonly NotificationType[]>
>;

type TrackingData = {
  page: {
    name: string;
    url: string;
  };
};

const store = useStore();
const isOpen = computed(() => store.state.notificationPopup.isOpen);
const position = computed(() => store.state.notificationPopup.position);
const { canReceiveWebPushNotification: canReceiveWebPushNotification } =
  useWebPush();

/**
 * メンション通知のマークを非表示にする
 */
const isHideMentionNotificationMark = ref(false);

/**
 * 未読・未閲覧の数
 */
const unviewedUnreadCountData = computed(
  () => store.state.notification.unviewedUnreadCountData,
);

const notificationPopup = ref<HTMLDivElement>();
const unreadOnly = ref(false);
const currentNotificationType = ref<NotificationGroupingName>('mentions');

// currentNotificationTypeの値からtabのラベルを取得する
const notificationTabLabel = computed(() => {
  switch (currentNotificationType.value) {
    case 'mentions':
      return 'メンション';
    case 'members':
      return 'メンバーの動き';
    case 'others':
      return 'その他';
    default:
      {
        const _exhaustiveCheck: never = currentNotificationType.value;
      }
      return '';
  }
});

/**
 * 既読状態の通知の取得mutation
 */
const { mutate: mutateUnviewedUnreadCount } =
  useNotificationsUnviewedUnreadCount(NOTIFICATION_GROUPINGS);

/**
 * ポップアップを閉じる
 */
const close = () => {
  store.commit('notificationPopup/close');
};

/**
 * ポップアップの外側をクリックした時にポップアップを閉じる
 * @param e
 */
const closePopup = (e: MouseEvent) => {
  if (!notificationPopup.value) return;
  if (!notificationPopup.value?.contains(e.target as Node)) close();
};

const notificationItems = ref<NotificationItem[]>([]);
const page = ref(1);
const pageLimit = 10;
const order = 'desc';

/**
 * ダイアログの開閉を監視
 */
watch(isOpen, async isOpen => {
  const initialGroup: NotificationGroupingName = 'mentions';
  if (isOpen) {
    resetPopupStatus();
    currentNotificationType.value === initialGroup;
    unreadOnly.value = false;
    fetchNotifications();

    // ポップアップを開いた操作に伴い、初期表示のタブグループ(メンション)の通知マークを非表示にする
    // memo: 実際のデータを既読にすると「新着」の表示が消えてしまうため、マークの非表示のみ行う
    setTimeout(() => {
      isHideMentionNotificationMark.value = true;
    }, 1000);

    document.addEventListener('click', closePopup);
  } else {
    // 初期表示のタブグループを閲覧済みにする
    await store.dispatch('notification/viewNotificationGrouping', {
      grouping: NOTIFICATION_GROUPINGS[initialGroup],
    });
    mutateUnviewedUnreadCount();
    // ダイアログが閉じたらドキュメントに付与したクリックイベントを削除する
    document.removeEventListener('click', closePopup);
  }
});

/**
 * 通知グループを閲覧済みにする
 * @param groupName
 */
const viewNotificationGroup = async (groupName: NotificationGroupingName) => {
  await store.dispatch('notification/viewNotificationGrouping', {
    grouping: NOTIFICATION_GROUPINGS[groupName],
  });
  await mutateUnviewedUnreadCount();
};

// memo: メンション以外の通知グループは未読状態を仕様として持っていないように振る舞う
/**
 * 通知グループを閲覧済み・既読にする
 * @param groupName
 */
const viewAndReadNotificationGroup = async (
  groupName: NotificationGroupingName,
) => {
  await viewNotificationGroup(groupName);
  await store.dispatch('notification/readNotificationGrouping', {
    grouping: NOTIFICATION_GROUPINGS[groupName],
  });
  await mutateUnviewedUnreadCount();
};

/** 通知を既読にする */
const readNotification = async (notification: NotificationItem) => {
  api.trackEvent(
    'notification_view',
    {
      pageName: 'notification',
      pageUrl: route.fullPath,
    },
    undefined,
    undefined,
    {
      notification: {
        id: notification.id,
        type: notification.type,
      },
    },
  );

  if (notification.read_at) return;
  await store.dispatch('notification/read', { notification });
  await mutateUnviewedUnreadCount();
};

/** 未読状態の通知かどうか */
const isUnreadNotification = (notification: NotificationItem) => {
  return isShowingUnreadState.value && !notification.read_at;
};

/**
 * 新着メンション数(表示状態のメンション数)
 */
const unviewedMentionCount = computed(() => {
  const unreadCount = unviewedUnreadCountData.value.counts;
  if (!unreadCount) return 0;
  return unreadCount.mentions?.unviewed_count || 0;
});
watch(unviewedMentionCount, () => {
  if (isOpen.value && unviewedMentionCount.value > 0) {
    isHideMentionNotificationMark.value = false;
  }
});

/**
 * 未読と未表示を分ける通知タイプかどうか
 */
const shouldSeparateUnreadState = (grouping: NotificationGroupingName) => {
  return ['mentions'].includes(grouping);
};

/** 未読の通知も表示するかどうか */
const isShowingUnreadState = computed(() =>
  shouldSeparateUnreadState(currentNotificationType.value),
);

/** タブ単位の未読情報の有無 */
function hasNotificationTabGroup(groupName: string) {
  const unreadCount = (
    unviewedUnreadCountData.value as { counts?: UnreadCount }
  )?.counts;

  if (unreadCount) {
    // countsプロパティが存在する場合の処理
    return unreadCount[groupName]?.unviewed_count;
  }
  return false;
}

/**
 * コメントリアクションの重複を除去する
 */
const aggregateCommentReaction = (items: NotificationItem[]) => {
  const commentIdsWithCommentReactions = new Set<number>();
  const result: NotificationItem[] = [];

  items.forEach(item => {
    if (item.type === NOTIFICATION_TYPE.COMMENT_REACTION && item.comment) {
      if (commentIdsWithCommentReactions.has(item.comment.id)) {
        return;
      }
      commentIdsWithCommentReactions.add(item.comment.id);
    }
    result.push(item);
  });

  return result;
};

const emitter = useEmitter();
/**
 * 指定のタブグループの通知を全て既読にする
 * @param groupName タブグループ名
 */
const readAllGroup = async (groupName: NotificationGroupingName) => {
  // notificationItemsのread_atを現在日にする（表示上の既読状態を表す）
  notificationItems.value.forEach(item => (item.read_at = String(new Date())));

  api.trackEvent('notifications_all_read', {
    pageName: 'notification',
  });

  await api.readNotifications(NOTIFICATION_GROUPINGS[groupName]);
  emitter.emit('pagination-items-update', {
    filterFunc: (_item: NotificationItem) => true,
    updateFunc: (items: NotificationItem[]) =>
      items.forEach(item => (item.read_at = String(new Date()))),
  });
  await mutateUnviewedUnreadCount();
};

const nextPage = () => {
  page.value++;
  fetchNotifications();
};

/** フェッチ処理中であるかどうか */
const isLoading = ref(false);
/** 取得した情報が終端に達したか */
const isEndOfNotifications = ref(false);

const route = useRoute();
/** 通知をフェッチする */
const fetchNotifications = async () => {
  if (!isOpen.value) return;
  if (isLoading.value) return;
  isLoading.value = true;

  const trackingData: TrackingData = {
    page: {
      name: `お知らせ/${notificationTabLabel.value}`,
      url: route.fullPath,
    },
  };
  const notifications = await store.dispatch(
    'notification/fetchNotifications',
    {
      limit: pageLimit,
      page: page.value,
      order,
      notificationTypes: NOTIFICATION_GROUPINGS[currentNotificationType.value],
      unreadOnly: isShowingUnreadState.value ? unreadOnly.value : false,
      trackingData,
    },
  );

  if (notifications.length < pageLimit) {
    isEndOfNotifications.value = true;
  }
  notificationItems.value.push(...notifications);
  isLoading.value = false;
};

const resetPopupStatus = () => {
  page.value = 1;
  notificationItems.value = [];
  isEndOfNotifications.value = false;
};

/** Web Push通知を受け取ったときのハンドラー */
const handleWebPushNotificationMessageEvent = async (event: MessageEvent) => {
  if (event.data.type === 'notification-received') {
    if (!isOpen.value) return;

    if (currentNotificationType.value === 'mentions') {
      isHideMentionNotificationMark.value = false;
    }

    if (isLoading.value) return;
    isLoading.value = true;

    const newNotification: NotificationItem | undefined = (
      await store.dispatch('notification/fetchNotifications', {
        limit: 1,
        page: 1,
        order,
        notificationTypes:
          NOTIFICATION_GROUPINGS[currentNotificationType.value],
        unreadOnly: isShowingUnreadState.value ? unreadOnly.value : false,
        trackingData: {},
      })
    )[0];

    if (!newNotification) {
      isLoading.value = false;
      return;
    }

    notificationItems.value = [newNotification, ...notificationItems.value];

    if (!isEndOfNotifications.value) {
      notificationItems.value = notificationItems.value.slice(0, -1);
    }

    isLoading.value = false;
  }
};

/**
 * 通知タイプの変更を監視
 */
watch(
  [currentNotificationType, unreadOnly],
  () => {
    // タブ切り替えされたらページ表示内容をリセット
    resetPopupStatus();
    fetchNotifications();
  },
  { immediate: true },
);

onMounted(() => {
  if (canReceiveWebPushNotification()) {
    navigator.serviceWorker.addEventListener(
      'message',
      handleWebPushNotificationMessageEvent,
    );
  }
});

onBeforeUnmount(() => {
  document.removeEventListener('click', closePopup);
  if (canReceiveWebPushNotification()) {
    navigator.serviceWorker.removeEventListener(
      'message',
      handleWebPushNotificationMessageEvent,
    );
  }
});

const emptyMessage = computed(() => {
  switch (currentNotificationType.value) {
    case 'mentions':
      return 'あなた宛のメンションはまだありません。';
    case 'members':
      return 'メンバーの動きはまだありません。';
    case 'others':
      return 'その他のお知らせはまだありません。';
    default:
      {
        const _exhaustiveCheck: never = currentNotificationType.value;
      }
      return '';
  }
});
</script>

<template>
  <div
    class="notification-popup"
    ref="notificationPopup"
    v-if="isOpen"
    :style="position"
  >
    <div class="notification-popup-sticky-header">
      <div class="notification-popup-header">
        <h3 class="c-title c-title--m">お知らせ</h3>
        <RouterLink
          to="/notifications/mentions"
          type="button"
          class="with-icon c-btn c-btn--small c-btn--auto c-btnOutline o-back-button button-green"
          @click="close"
          target="_blank"
          rel="noopener noreferrer"
        >
          <DgrIcon name="external-link" :keep-fill="false" class="primary" />
          全部見る
        </RouterLink>
      </div>
      <div class="notification-popup-tab">
        <button
          role="button"
          @click="
            currentNotificationType = 'mentions';
            viewNotificationGroup('mentions');
          "
          :class="[
            'notification-popup-tab-button',
            currentNotificationType === 'mentions' ? 'active' : '',
          ]"
          data-vitest="notification-popup-tab-button-mentions"
        >
          メンション
          <NotificationMark
            data-vitest="notification-mention-unviewed-mark"
            v-if="
              hasNotificationTabGroup('mentions') &&
              !isHideMentionNotificationMark
            "
            :top="'8px'"
            :right="'14px'"
          />
        </button>
        <button
          role="button"
          @click="
            currentNotificationType = 'members';
            viewAndReadNotificationGroup('members');
          "
          :class="[
            'notification-popup-tab-button',
            currentNotificationType === 'members' ? 'active' : '',
          ]"
        >
          メンバーの動き
          <NotificationMark
            data-vitest="notification-members-unviewed-mark"
            v-if="hasNotificationTabGroup('members')"
            :top="'8px'"
            :right="'14px'"
          />
        </button>
        <button
          @click="
            currentNotificationType = 'others';
            viewAndReadNotificationGroup('others');
          "
          role="button"
          :class="[
            'notification-popup-tab-button',
            currentNotificationType === 'others' ? 'active' : '',
          ]"
        >
          その他
          <NotificationMark
            data-vitest="notification-others-unviewed-mark"
            v-if="hasNotificationTabGroup('others')"
            :top="'8px'"
            :right="'28px'"
          />
        </button>
      </div>
    </div>
    <div class="notification-popup-content">
      <div class="notification-popup-content-heading">
        <template v-if="currentNotificationType === 'mentions'">
          <div class="notification-popup-content-notification-mantion-count">
            <template v-if="!!unviewedMentionCount && !unreadOnly">
              <span class="c-title--m">新着</span>
              <span class="c-text c-text--s text-gray-600"
                >・{{ unviewedMentionCount }}件</span
              ></template
            >
          </div>
          <div class="notification-popup-content-controls">
            <DgrToggleButton v-model="unreadOnly" class="c-text c-text--s">
              未読のみ表示する
            </DgrToggleButton>
            <button
              @click="async () => await readAllGroup('mentions')"
              type="button"
              class="c-text c-text--s notification-popup-content-all-mentions-read-button"
            >
              全て既読にする
            </button>
          </div>
        </template>
      </div>
      <div>
        <div class="notification-popup-content-timeline">
          <TransitionGroup name="notifications">
            <div
              v-for="(notification, index) in aggregateCommentReaction(
                notificationItems,
              )"
              :key="notification.id"
            >
              <div
                v-if="
                  isShowingUnreadState &&
                  unviewedMentionCount > 0 &&
                  index === unviewedMentionCount
                "
                class="existing-mentions c-title c-title--m"
              >
                今までのメンション
              </div>
              <div @click="close">
                <PopupDialogNotificationCard
                  :notification="notification"
                  :is-unread="isUnreadNotification(notification)"
                  @read-notification="readNotification(notification)"
                />
              </div>
            </div>
          </TransitionGroup>

          <div
            class="notification-empty"
            v-if="!isLoading && notificationItems.length == 0"
          >
            {{ emptyMessage }}
          </div>
          <div class="pagination">
            <button
              v-if="notificationItems.length > 0 && !isEndOfNotifications"
              class="c-text c-text--s notification-popup-content-all-mentions-read-button"
              @click="nextPage"
            >
              過去のお知らせを表示する
            </button>
            <span
              class="c-text c-text--s"
              v-if="notificationItems.length > 0 && isEndOfNotifications"
            >
              すべてのお知らせを表示しました
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.notification-popup {
  z-index: var(--z-modal);
  position: fixed;
  background-color: white;
  min-width: 200px;
  height: 680px;
  width: 492px;
  max-width: 96vw;
  max-height: 80vh;
  border-radius: 8px;
  border: 1px solid $color-gray400;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  overflow-y: scroll;

  &-sticky-header {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
  }

  &-header {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .primary {
      fill: $color-green600;
    }
  }

  &-tab {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    border-bottom: 1px solid $color-gray400;

    &-button {
      padding: 4px 24px;
      min-width: 120px;
      line-height: 22px;
      height: inherit;
      border: none;
      border-radius: 0;
      background-color: transparent;
      font-size: 14px;
      font-weight: bold;
      color: $color-gray800;
      position: relative;
      cursor: pointer;
      box-sizing: border-box;

      &:hover {
        background-color: $color-gray200;
      }

      &.active {
        color: $color-green600;
        border-bottom: 2px solid $color-green600;
      }
    }
  }

  &-content {
    padding: 16px;
    height: auto;
    box-sizing: border-box;

    &-heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-controls {
      display: flex;
      gap: 16px;
      align-items: center;
    }

    &-notification-mantion-count {
      display: flex;
      gap: 4px;
      align-items: center;
    }

    &-timeline {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    /** 全て既読にするボタン */
    &-all-mentions-read-button {
      color: $color-green600;
      font-family: 'Noo Sans CJK JP';
      font-style: normal;
      font-weight: 700;
      text-decoration-line: underline;
      background-color: inherit;
      border: none;
      display: inline;
      padding: 0;
    }
  }
}

// window幅が1040px以下の場合通知アイコンに追従しきれなくなるので右側に寄せるよう調整
@media (max-width: 1040px) {
  .notification-popup {
    right: 1vw !important;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px;
}

.notification-empty {
  font-size: 14px;
  font-style: normal;
  padding: 16px;
}

/* TransitionGroup(notifications)用のスタイル */
.notifications-enter-active,
.notifications-leave-active {
  transition: all 0.1s ease;
}
.notifications-enter-from,
.notifications-leave-to {
  opacity: 0;
  transform: translateY(10px);
}

.button-green {
  border: 1px solid $color-green600;
  color: $color-green600;
}

.text-gray-600 {
  color: $color-gray600;
}
</style>
