<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  PropType,
  reactive,
  ref,
  toRefs,
  watch,
} from 'vue';
import { DgrCheckbox, DgrIcon } from '@stockmarkteam/donguri-ui';
import SearchBar from '@/components/common/molecules/search-bar.vue';

export default defineComponent({
  components: {
    SearchBar,
    DgrIcon,
    DgrCheckbox,
  },
  props: {
    optionName: { type: String, required: true },
    options: {
      type: Array as PropType<{ name: string; count?: number }[]>,
      required: true,
    },
    selectedOptions: { type: Array as PropType<string[]>, default: () => [] },
  },
  setup(props, context) {
    const { optionName } = props;
    const { options, selectedOptions } = toRefs(props);

    const internalSelectedOptions = reactive({ items: [] as string[] });

    const resetSelectedOptions = () =>
      (internalSelectedOptions.items = [...selectedOptions.value]);

    const isSelectionOpen = ref(false);
    const multiselectRef = ref<HTMLDivElement>();
    const closeSelection = (e?: Event) => {
      const eventTargets = (e?.composedPath() ?? []) as HTMLElement[];
      if (
        multiselectRef.value &&
        (!e || !eventTargets.includes(multiselectRef.value))
      ) {
        isSelectionOpen.value = false;
      }
      resetSelectedOptions();
    };
    onMounted(async () => {
      document.addEventListener('click', closeSelection);
    });

    onUnmounted(() => {
      document.removeEventListener('click', closeSelection);
    });

    const title = computed(() =>
      selectedOptions.value.length === 0 ||
      selectedOptions.value.length === options.value.length
        ? `すべての${optionName}`
        : `${selectedOptions.value.length}${optionName}`,
    );

    const filterQuery = ref('');
    const setFilterQuery = (value: string) => (filterQuery.value = value);
    const filteredOptions = computed(() =>
      filterQuery.value
        ? options.value.filter(o =>
            o.name.toLowerCase().includes(filterQuery.value.toLowerCase()),
          )
        : options.value,
    );

    const toggleOptionSelection = (option: string, isSelected: boolean) => {
      if (isSelected) {
        internalSelectedOptions.items.push(option);
      } else {
        internalSelectedOptions.items = internalSelectedOptions.items.filter(
          o => o !== option,
        );
      }
    };

    const selectAll = () => {
      internalSelectedOptions.items = options.value.map(o => o.name);
    };
    const deselectAll = () => {
      internalSelectedOptions.items = [];
    };
    const confirmSelection = () => {
      context.emit('selectionUpdated', [...internalSelectedOptions.items]); // eslint-disable-line vue/require-explicit-emits
      closeSelection();
    };

    watch(
      () => selectedOptions.value,
      () => (internalSelectedOptions.items = [...selectedOptions.value]),
    );

    watch(isSelectionOpen, val => context.emit(val ? 'open' : 'close'));

    return {
      internalSelectedOptions,
      isSelectionOpen,
      title,
      filterQuery,
      setFilterQuery,
      filteredOptions,
      toggleOptionSelection,
      selectAll,
      deselectAll,
      confirmSelection,
      multiselectRef,
    };
  },
});
</script>

<template>
  <div class="multi-select">
    <div
      class="multi-select-box"
      ref="multiselectRef"
      @click="isSelectionOpen = !isSelectionOpen"
    >
      <div class="c-selectBox c-selectBox--small">
        <span class="c-selectBox__placeholder c-text--m">
          {{ title }}
        </span>
        <DgrIcon size="small" name="angle-down" class="c-selectBox__arrow" />
      </div>
    </div>
    <div
      class="option-select dashboard-card"
      v-if="isSelectionOpen"
      @click.stop="() => undefined"
    >
      <div class="option-search-bar">
        <SearchBar @on-change-query="setFilterQuery" />
      </div>
      <div class="option-list">
        <div
          class="options-empty c-text c-text--m"
          v-if="options.length === 0 && filterQuery.length === 0"
        >
          未設定
        </div>
        <div
          class="options-empty c-text c-text--m"
          v-if="filteredOptions.length === 0 && filterQuery.length > 0"
        >
          指定した{{ optionName }}は存在しません。
        </div>
        <div
          class="option"
          v-for="option in filteredOptions"
          :key="option.name"
        >
          <DgrCheckbox
            class="checkbox-label c-text c-text--m"
            :model-value="internalSelectedOptions.items.includes(option.name)"
            @update:model-value="toggleOptionSelection(option.name, $event)"
          >
            <span :class="{ empty: option.name === '' }">
              {{ option.name === '' ? '未記入' : option.name }}
            </span>
            <span v-if="option.count !== undefined">
              ({{ option.count }})
            </span>
          </DgrCheckbox>
        </div>
      </div>
      <div class="option-buttons">
        <button class="dashboard-button--borderless" @click="selectAll">
          すべて選択
        </button>
        <button class="dashboard-button--borderless" @click="deselectAll">
          選択解除
        </button>
        <button class="dashboard-button--primary" @click="confirmSelection">
          適用
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.multi-select {
  position: relative;

  .multi-select-box {
    width: fit-content;
    .c-selectBox__placeholder.c-text--s {
      line-height: 16px;
    }
  }

  .option-select {
    position: absolute;
    z-index: var(--z-dropdown);
    width: 320px;
    right: 0;
    padding: 12px;
    box-shadow: 0 1px 5px rgba(74, 74, 74, 0.25);

    .option-search-bar {
      margin-bottom: 20px;
    }
    .option-list {
      max-height: 240px;
      overflow-y: scroll;

      .options-empty {
        color: $color-text-secondary;
        margin-bottom: 14px;
      }
      .option {
        .empty {
          color: $color-gray600;
        }
        .checkbox-label {
          display: flex;
          margin-bottom: 8px;
        }
      }
    }
    .option-buttons {
      display: flex;
      justify-content: space-between;
      padding-top: 12px;
      border-top: 1px solid $color-border;

      .dashboard-button--borderless {
        color: $color-green600;
      }
    }
  }
}
</style>
