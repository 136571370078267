<script lang="ts">
import { computed, defineComponent, PropType, reactive, ref } from 'vue';
import { EMPTY_KEYWORD_FEED_MESSAGE } from '@/constants';
import AdpDocumentCard from '@/components/common/adp-document-card.vue';
import AdpDocumentCardSkeleton from '@/components/common/adp-document/adp-document-card-skeleton.vue';
import ThemeEmptyFeed from '@/components/common/theme-empty-feed.vue';
import { Article, Theme } from '@/types';
import { useTheme, useThemeKeywordFeed, useUserInfo } from '@/utils/swr';
import { useEmitter, useStore } from '@/utils/vue';

export default defineComponent({
  components: {
    AdpDocumentCard,
    AdpDocumentCardSkeleton,
    ThemeEmptyFeed,
  },
  props: {
    theme: { type: Object as PropType<Theme>, required: true },
  },
  setup(props) {
    const store = useStore();
    const emitter = useEmitter();
    const { data: userInfo } = useUserInfo();
    const enableToEditTheme = computed(
      () =>
        userInfo.value?.role !== 'viewer' ||
        (props.theme.access_scope === 'personal' &&
          props.theme.user_id === userInfo.value?.id),
    );

    const { data: themeResponse } = useTheme(props.theme.id);
    const themeDetail = computed(() => themeResponse.value?.themes);
    const feedType = computed(() => store.state.feedType.feedType);

    const removedArticles = reactive({ items: [] as number[] });
    emitter.on(
      'article-removed',
      ({
        themeId,
        articleId,
      }: {
        themeId: Theme['id'];
        articleId: Article['id'];
      }) => {
        if (themeId === props.theme.id) {
          removedArticles.items.push(articleId);
        }
      },
    );

    const { data: domesticFeed } =
      feedType.value === 'all'
        ? useThemeKeywordFeed(
            props.theme.id,
            ref('domestic'),
            undefined,
            ref(1),
            3,
          )
        : { data: { value: undefined } };
    const { data: foreignFeed } =
      feedType.value === 'all'
        ? useThemeKeywordFeed(
            props.theme.id,
            ref('foreign'),
            undefined,
            ref(1),
            3,
          )
        : { data: { value: undefined } };

    const { data } =
      feedType.value !== 'all'
        ? useThemeKeywordFeed(props.theme.id, feedType, undefined, ref(1), 5)
        : { data: { value: undefined } };

    const allArticles = computed(() => {
      if (feedType.value === 'all') {
        return domesticFeed.value && foreignFeed.value
          ? [
              ...domesticFeed.value.keyword_feeds,
              ...foreignFeed.value.keyword_feeds,
            ]
          : undefined;
      } else {
        return data.value ? data.value.keyword_feeds : undefined;
      }
    });

    const articles = computed(() =>
      allArticles.value?.filter(a => !removedArticles.items.includes(a.id)),
    );

    const hasArticles = computed(() => (articles.value ?? []).length > 0);

    return {
      themeDetail,
      feedType,
      userInfo,
      enableToEditTheme,
      articles,
      hasArticles,
      EMPTY_KEYWORD_FEED_MESSAGE,
    };
  },
});
</script>
<template>
  <div class="o-picked-up-articles" :key="feedType">
    <div class="articles" v-if="hasArticles">
      <div
        class="o-article"
        v-for="(article, index) in articles"
        :key="article.id"
      >
        <div class="spacing-08" v-if="index !== 0"></div>
        <AdpDocumentCard
          :id="`${theme.id}-${article.id}`"
          page-name="home"
          :feature="'themes_pickup'"
          :adp-document="article"
          :theme-id="theme.id"
          :show-comment-count="1"
          :feed-type="feedType"
          :disable-related-articles="false"
        ></AdpDocumentCard>
      </div>
    </div>
    <ThemeEmptyFeed
      v-else-if="articles && themeDetail"
      :display-feed-type="feedType"
      :theme-feed-type="feedType === 'foreign' ? 'foreign' : 'domestic'"
      doc-type="article"
      :theme="themeDetail"
      :enable-to-edit-theme="enableToEditTheme"
      >{{ EMPTY_KEYWORD_FEED_MESSAGE }}</ThemeEmptyFeed
    >
    <AdpDocumentCardSkeleton v-else :loading="true" />
    <div class="o-footer">
      <router-link :to="{ name: 'themeFeed', params: { themeId: theme.id } }">
        <button
          class="c-btn c-btn--small c-btn--AnewsPrimary c-text c-text--m o-load-more-button"
        >
          もっと見る
        </button>
      </router-link>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.o-picked-up-articles {
  .o-footer {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    button {
      outline: none;
    }
  }
}
</style>
