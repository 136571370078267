import { UserInfo, UserSettings } from '@/types';

export function isMypageRestricted(
  userInfo: UserInfo | undefined,
  targetUserInfo: UserInfo,
  targetUserSettings: UserSettings,
): boolean {
  if (targetUserSettings.mypage_access_scope === 'public') {
    return false;
  } else {
    return !(userInfo?.id === targetUserInfo.id);
  }
}

export function mypageAccessScope(
  targetUserSettings: UserSettings | UserInfo,
): string {
  if (targetUserSettings.mypage_access_scope === 'public') {
    return '・公開範囲：全体';
  } else {
    return '・公開範囲：自分のみ';
  }
}
