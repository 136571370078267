<script setup lang="ts">
import GroupButtons from '@/components/group/group-buttons.vue';
import GroupEditButton from '@/components/group/group-edit-button.vue';
import GroupToggleButton from '@/components/group/group-toggle-button.vue';
import { Group } from '@/types';

interface Props {
  group: Group;
}
const props = defineProps<Props>();
</script>

<template>
  <GroupButtons>
    <GroupEditButton :group="props.group" />
    <GroupToggleButton :group="props.group" />
  </GroupButtons>
</template>

<style lang="scss" scoped>
.o-buttons {
  flex-shrink: 0;
  display: flex;
  gap: 8px;
}
</style>
