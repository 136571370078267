<script setup lang="ts">
import { nextTick, watch } from 'vue';
import {
  useWindowVirtualScroll,
  Props as useWindowVirtualScrollProps,
} from '@/utils/composables/virtualScroll/useWindowVirtualScroll';

interface Props {
  estimateSize: useWindowVirtualScrollProps['estimateSize']; // 仮想スクロール内の各アイテムのheight(px)
  overscan: useWindowVirtualScrollProps['overscan']; // 見えている範囲外に描画するアイテム数(スクロール時に要素が欠けて見えてしまう時に調整するために使用)
  count: number;
  isDataLoaded: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  overscan: 0,
});

const {
  scrollableElement,
  virtualRows,
  totalSize,
  rowVirtualizer,
  scrollMargin,
} = useWindowVirtualScroll({
  getCount: () => props.count,
  estimateSize: props.estimateSize,
  overscan: props.overscan,
});

watch(
  () => props.isDataLoaded,
  () => {
    if (props.isDataLoaded)
      nextTick(() => {
        scrollMargin.value = scrollableElement.value?.offsetTop ?? 0;
      });
  },
);
</script>

<template>
  <div ref="scrollableElement">
    <div
      class="container"
      :style="{
        height: `${totalSize}px`,
      }"
    >
      <div
        class="virtual-row"
        v-for="virtualRow in virtualRows"
        :key="virtualRow.index"
        :style="{
          height: `${virtualRow.size}px`,
          transform: `translateY(${
            virtualRow.start - (rowVirtualizer.options.scrollMargin ?? 0)
          }px)`,
        }"
      >
        <slot :index="virtualRow.index"></slot>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.container {
  width: 100%;
  position: relative;
}

.virtual-row {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
</style>
