<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    loading: { type: Boolean, default: false },
  },
  setup() {
    return {};
  },
});
</script>

<template>
  <div class="adp-document-card-skeleton" :class="{ loading: loading }">
    <div class="m-sentences-skeleton">
      <div class="m-line1"></div>
      <div class="m-line2"></div>
      <div class="m-line3"></div>
    </div>
    <div class="m-image-skeleton"></div>
  </div>
</template>
<style lang="scss" scoped>
.adp-document-card-skeleton {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding: 16px;
  width: 100%;
  background: #ffffff;
  align-items: center;
  margin-bottom: 1px;
  justify-content: space-between;
  .m-image-skeleton {
    width: 80px;
    height: 80px;
    background: #e6e6e6;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
  }
  .m-sentences-skeleton {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: 16px;
    .m-line1,
    .m-line2,
    .m-line3 {
      height: 8px;
      background: #e6e6e6;
      border-radius: 4px;
      margin: 6px 0;
    }
    .m-line1 {
      width: 255px;
    }
    .m-line2 {
      width: 191px;
    }
    .m-line3 {
      width: 113px;
    }
  }
}

.adp-document-card-skeleton.loading {
  .m-image-skeleton {
    background-image: linear-gradient(
      110deg,
      rgba(230, 230, 230, 1) 25%,
      rgba(255, 255, 255, 0.35) 50%,
      rgba(230, 230, 230, 1) 75%
    );
    background-size: 80% 100%;
    background-position: -500% 0;
    background-repeat: no-repeat;
    animation: loading 1.5s infinite;
  }
  .m-sentences-skeleton {
    .m-line1,
    .m-line2,
    .m-line3 {
      background-image: linear-gradient(
        110deg,
        rgba(230, 230, 230, 1) 25%,
        rgba(255, 255, 255, 0.35) 50%,
        rgba(230, 230, 230, 1) 75%
      );
      background-size: 80% 100%;
      background-position: -500% 0;
      background-repeat: no-repeat;
      animation: loading 1.5s infinite;
    }
  }
}
@keyframes loading {
  to {
    background-position: 500% 0;
  }
}
</style>
