<script lang="ts">
import { DgrIcon } from '@stockmarkteam/donguri-ui';

const defaultRange: DateRange = { from: new Date(), to: new Date() };
</script>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { FIRST_PUBLISHED_AT, searchPresetDates } from '@/constants';
import DropdownDateRangeCalendar from '@/components/common/dropdown-date-range-calendar.vue';
import { DateRange, DateRangePreset } from '@/types';

type ApplyDateType = { presetKey: string; value: DateRange };

interface Props {
  title: string;
  range: DateRange;
  preset: DateRangePreset[];
  min: Date;
  max?: Date;
  isOpen: boolean;
  disabled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  range: () => defaultRange,
  preset: () => searchPresetDates,
  min: () => FIRST_PUBLISHED_AT,
});

interface Emits {
  (e: 'toggleIsOpen'): void;
  (e: 'applyDate', value: ApplyDateType): void;
}

const emit = defineEmits<Emits>();

const from = ref(props.range.from);
const to = ref(props.range.to);

const emitEvent = (payload: ApplyDateType) => emit('applyDate', payload);

watch(
  () => [props.range.from, props.range.to],
  () => {
    from.value = props.range.from;
    to.value = props.range.to;
  },
);
</script>

<template>
  <div class="search-menu-dropdown-calendar">
    <div
      class="title-icon c-title c-title--s"
      @click="() => emit('toggleIsOpen')"
    >
      <span>{{ title }}</span>
      <DgrIcon
        size="small"
        :name="isOpen ? 'chevron-up' : 'chevron-down'"
        class="small"
      />
    </div>
    <div v-if="isOpen" class="items">
      <DropdownDateRangeCalendar
        :range="{
          from: from,
          to: to,
        }"
        :preset="preset"
        :min="min"
        :max="max"
        :disabled="disabled"
        @apply-date="emitEvent"
        is-search
      ></DropdownDateRangeCalendar>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.search-menu-dropdown-calendar {
  padding: 0px 16px;
  margin: 17px 0px;

  .title-icon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2px;

    box-sizing: border-box;
    height: 24px;
    cursor: pointer;
  }

  .items {
    margin-top: 12px;
    margin-bottom: 20px;
    :deep(.popup) {
      top: 3px;
    }
  }
}
</style>
