import { Module } from 'vuex';
import { UserInfo } from '@/types';

export type UserInfoState = {
  userInfo: UserInfo | undefined;
};

export const initialUserInfoState = {
  userInfo: undefined,
};

const userInfo: Module<UserInfoState, unknown> = {
  namespaced: true,
  state: {
    userInfo: undefined,
  },
  mutations: {
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
    },
    setUserIndustryId(state, industry_id: UserInfo['industry_id']) {
      if (state.userInfo) {
        state.userInfo = {
          ...state.userInfo,
          industry_id,
        };
      }
    },
  },
};

export default userInfo;
