<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import api from '@/api/index';
import SettingToggleCard from '@/components/common/atoms/setting-toggle-card.vue';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import Content from '@/components/layouts/content.vue';
import Header from '@/components/layouts/header.vue';
import { useWebPush } from '@/utils/composables/useWebPush';
import { useUserSettingsNotification } from '@/utils/swr';

const route = useRoute();
const { createSnackbar } = useSnackbar();

const isSubmit = ref(false);

const { canReceiveWebPushNotification, getSubscription, getPendingState } =
  useWebPush();

// useUserSettingsNotificationを使用したい場合は↓の処理を展開してください
const endpoint = computed(() => {
  const subscription = getSubscription();
  return subscription.value?.endpoint;
});
const { data: userSettingsNotification, mutate } =
  useUserSettingsNotification(endpoint);

const isPending = computed(() => {
  const pendingState = getPendingState();
  return pendingState.value;
});

const enabledSystemNotificationMail = ref(false);
const enabledOtherUsersActivityNotificationMail = ref(false);
const enabledMentionNotificationMail = ref(false);
const enabledOtherUsersActivityNotificationBrowser = ref(false);
const enabledMentionNotificationBrowser = ref(false);

watch(
  userSettingsNotification,
  settings => {
    if (!settings) return;
    enabledSystemNotificationMail.value =
      settings.enabled_system_notification_mail;
    enabledOtherUsersActivityNotificationMail.value =
      settings.enabled_other_users_activity_notification_mail;
    enabledMentionNotificationMail.value =
      settings.enabled_mention_notfication_mail;
    enabledOtherUsersActivityNotificationBrowser.value =
      settings.enabled_other_users_activity_notification_browser ?? false;
    enabledMentionNotificationBrowser.value =
      settings.enabled_mention_notification_browser ?? false;
  },
  { immediate: true },
);

const submit = async () => {
  if (!userSettingsNotification.value) return;

  isSubmit.value = true;
  const requestData = {
    ...(endpoint.value
      ? {
          web_push: {
            endpoint: endpoint.value,
            enabled_mention_notification_browser:
              enabledMentionNotificationBrowser.value,
            enabled_other_users_activity_notification_browser:
              enabledOtherUsersActivityNotificationBrowser.value,
          },
        }
      : {}),
    enabled_mention_notfication_mail: enabledMentionNotificationMail.value,
    enabled_other_users_activity_notification_mail:
      enabledOtherUsersActivityNotificationMail.value,
    enabled_system_notification_mail: enabledSystemNotificationMail.value,
  };
  try {
    await api.updateUserSettingsNotification(requestData, {
      pageName: 'notification',
      pageUrl: route.fullPath,
    });
    mutate();
    createSnackbar({
      message: '設定を更新しました',
      type: 'success',
    });
  } catch (e) {
    createSnackbar({
      message: '設定を更新できませんでした',
      type: 'error',
    });
    throw e;
  } finally {
    isSubmit.value = false;
  }
};

const webPushSettingsDisabled = computed(() => {
  return !canReceiveWebPushNotification() || isPending.value || !endpoint.value;
});
</script>

<template>
  <div class="user-settings" v-if="userSettingsNotification">
    <Header title="通知" />
    <Content>
      <div class="settings-card">
        <section class="settings-section">
          <h2 class="c-formBlock__label">メール</h2>
          <div class="setting-toggles">
            <SettingToggleCard
              title="＠メンション"
              v-model="enabledMentionNotificationMail"
            >
              あなたにメンションがあったとき、メール配信します。
            </SettingToggleCard>
            <SettingToggleCard
              title="メンバーの動き"
              v-model="enabledOtherUsersActivityNotificationMail"
            >
              あなたがグループやテーマに招待された時のみメール配信します。
            </SettingToggleCard>
            <SettingToggleCard
              title="その他"
              v-model="enabledSystemNotificationMail"
            >
              その他タブに新着があったとき、メール配信します。
            </SettingToggleCard>
          </div>
        </section>
        <section>
          <h2 class="c-formBlock__label">ブラウザ</h2>
          <div
            v-if="!isPending && (!canReceiveWebPushNotification() || !endpoint)"
            class="c-text c-text--xs text-red"
          >
            <p>現在ご利用のブラウザはまだ通知許可が設定されていません。</p>
            <p>許可をオンにするにはブラウザ設定から許可してください。</p>
            <p>
              ※ポップアップを拒否している場合は、ブラウザ設定より許可をしてください。
            </p>
          </div>
          <div class="setting-toggles">
            <SettingToggleCard
              title="@メンション"
              v-model="enabledMentionNotificationBrowser"
              :disabled="webPushSettingsDisabled"
            >
              あなたにメンションがあった時、ブラウザの通知機能を通してお知らせします。
            </SettingToggleCard>
            <SettingToggleCard
              title="メンバーの動き"
              v-model="enabledOtherUsersActivityNotificationBrowser"
              :disabled="webPushSettingsDisabled"
            >
              メンバーの動きタブに新着があった時、ブラウザの通知機能を通してお知らせします。
            </SettingToggleCard>
          </div>
        </section>
        <div>
          <button
            class="inline-button c-btn c-btn--small c-btn--AnewsPrimary"
            @click="submit"
            :class="{ disabled: isPending || isSubmit }"
            :disabled="isPending || isSubmit"
          >
            保存
          </button>
        </div>
      </div>
    </Content>
  </div>
</template>

<style lang="scss" scoped>
.user-settings {
  width: 100%;
  margin: -24px 0 0 0;
}
.settings-card {
  display: flex;
  flex-direction: column;
  gap: 24px;
  box-sizing: border-box;
  width: 440px;
  padding: 16px;
  background: #fff;
  border: 1px solid $color-border;
  border-radius: $border-radius;
}

.settings-section {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.c-formBlock__label {
  margin-bottom: 12px;
}

.setting-toggles {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 12px;
}

// 汎化可能クラス
.inline-button {
  display: inline-block;
  width: auto;
}
.text-red {
  color: $color-orange1000;
}
</style>
