<script setup lang="ts">
import { computed } from 'vue';
import api from '@/api';
import { ContentsContext, Feature, PageName } from '@/api/tracking';
import noImage1to1 from '@/assets/noimage-1to1.jpg';
import { DOC_TYPE_LABELS } from '@/constants';
import { DgrIcon } from '@stockmarkteam/donguri-ui';
import DocumentSentenceDisplay from '@/components/common/adp-document/document-sentence-display.vue';
import UserDocumentOpenPreviewModalButton from '@/components/user-document/user-document-open-preview-modal-button.vue';
import {
  UserDocument,
  UserDocumentEventName,
  UserDocumentViewInfo,
} from '@/types';
import { imageErrorHandler } from '@/utils/errorHandler';
import { formatDate } from '@/utils/formatters';
import { getPageUrl } from '@/utils/user-document/common';
import { getIsPageNumberAvailable } from '@/utils/user-document/common';
import { featureFlags } from '@/featureFlags';

interface Props {
  userDocument: UserDocument;
  badgeNumber: number;
  /* これより下は計測で使用 */
  pageName: PageName;
  feature: Feature;
  rankInWholeFeed: number;
  contentsContext: ContentsContext;
}

const props = defineProps<Props>();
const imageUrl = computed(() => props.userDocument.image_url || noImage1to1);
const isPageNumberAvailable = computed(() =>
  getIsPageNumberAvailable(props.userDocument),
);
const documentUrl = computed(() => {
  if (!isPageNumberAvailable.value) return props.userDocument.url;
  const pageNumber = props.userDocument.page_number;
  return getPageUrl(pageNumber ?? 1, props.userDocument);
});

const modifiedAt = computed(() =>
  props.userDocument.modified_at
    ? formatDate(props.userDocument.modified_at)
    : undefined,
);

const folderName = computed(() => {
  const path = props.userDocument.path;
  return path.split('/').slice(-1)[0];
});

const highlightKeywords = computed(() => {
  const hit_keywords = props.userDocument.hit_keywords ?? [];
  const synonym_keywords = props.userDocument.synonym_keywords ?? [];
  return [...hit_keywords, ...synonym_keywords]
    .filter(keyword => keyword.length > 0)
    .sort(keyword => -keyword.length);
});

const fileTypeIcon = computed(() => {
  switch (props.userDocument.file_type) {
    case 'PDF':
      return 'file-pdf-fill-color';
    case 'Word':
      return 'file-word-fill-color';
    case 'PowerPoint':
      return 'file-powerpoint-fill-color';
    default:
      return 'file-v';
  }
});

const trackingUserDocumentEvent = async (eventName: UserDocumentEventName) =>
  await api.trackingUserDocumentEvent(eventName, {
    page: { name: props.pageName },
    feature: props.feature,
    rank: props.rankInWholeFeed,
    user_document: { id: props.userDocument.id },
    contents_context: props.contentsContext,
    view_type: 'original',
  } as UserDocumentViewInfo);

const ANDEV_5318_PREVIEW_MODAL = featureFlags.ANDEV_5318_PREVIEW_MODAL;

const userDocumentViewInfo = {
  page: {
    name: props.pageName,
  },
  feature: props.feature,
  rank: props.rankInWholeFeed,
  user_document: { id: props.userDocument.id },
  contents_context: props.contentsContext,
  view_type: 'preview',
} as UserDocumentViewInfo;
</script>

<template>
  <div class="user-document-card">
    <div class="user-document-left">
      <div class="user-document-data-source-info c-text c-text--xs">
        <div class="doc-type-label c-title c-title--xs">
          {{ badgeNumber }}.{{ DOC_TYPE_LABELS[userDocument.doc_type] }}
        </div>
        <div class="user-document-page-number">
          <div>
            <DgrIcon :name="fileTypeIcon" />
          </div>
          <div class="spacing-02"></div>
          <template v-if="isPageNumberAvailable">
            <div class="page-number">
              ページ：{{ userDocument.page_number }}
            </div>
          </template>
        </div>
        <div class="spacing-04"></div>
        <component
          :is="userDocument.folder_url ? 'a' : 'span'"
          :href="userDocument.folder_url"
          target="_blank"
          rel="noopener noreferrer"
          class="user-document-data-source-name"
          @click="trackingUserDocumentEvent('user_document_folder_view')"
        >
          <DgrIcon :keep-fill="false" name="folder" />
          <div class="spacing-04"></div>
          <div class="user-document-data-source-name-text">
            {{ folderName }}
          </div>
        </component>
      </div>
      <div class="spacing-04"></div>
      <div class="user-document-title c-title c-title--xm">
        <a
          :href="documentUrl"
          target="_blank"
          rel="noopener noreferrer"
          @click="trackingUserDocumentEvent('user_document_view')"
        >
          {{ userDocument.title }}
        </a>
      </div>
      <div class="spacing-04"></div>
      <div class="document-sentence-display">
        <DocumentSentenceDisplay
          :text="userDocument.chunk_text"
          :highlight-keywords="highlightKeywords"
          :line-clamp="2"
        ></DocumentSentenceDisplay>
      </div>
      <div class="spacing-04"></div>
      <div class="user-document-update-info">
        <div class="c-text c-text--s">更新日：{{ modifiedAt }}</div>
        <div class="c-text c-text--s">
          更新者：{{ userDocument.last_updated_by }}
        </div>
      </div>
      <div class="user-document-bottom-buttons" v-if="ANDEV_5318_PREVIEW_MODAL">
        <UserDocumentOpenPreviewModalButton
          :title="userDocument.title"
          :target-page="userDocument.page_number"
          :file-total-pages="userDocument.file_total_pages"
          :user-document-view-info="userDocumentViewInfo"
          :preview-image-path-template="
            userDocument.preview_image_path_template
          "
        />
      </div>
    </div>
    <div>
      <a
        :href="documentUrl"
        target="_blank"
        rel="noopener noreferrer"
        @click="trackingUserDocumentEvent('user_document_view')"
      >
        <img
          class="user-document-thumbnail"
          loading="lazy"
          :src="imageUrl"
          :data-src="imageUrl"
          @error="imageErrorHandler($event, '3to1')"
        />
      </a>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.user-document-card {
  width: 100%;
  display: flex;
  background-color: white;
  padding: 16px;
  column-gap: 16px;
  box-sizing: border-box;
  border: 1px solid $color-gray400;
  border-radius: 4px;
  overflow: hidden;

  .user-document-left {
    flex: 1;
    min-width: 0; // 子要素の幅が親要素の幅を超えないようにする

    .user-document-update-info {
      display: flex;
      color: $color-gray800;
      font-weight: 400;
      column-gap: 8px;
      padding: 4px 0;
      .user-document-page {
        border: 1px solid $color-gray800;
        border-radius: 2px;
        padding: 0 2px;
      }
    }
  }
  .doc-type-label {
    background-color: $color-gray1000;
    color: $color-white;
    padding: 4px;
    margin-right: 6px;
    border-radius: 2px;
    font-weight: 700;
  }

  .user-document-thumbnail {
    width: 150px;
    height: 150px;
    object-fit: contain;
    font-weight: 400;
  }
  .user-document-data-source-info {
    padding: 4px 0;
    color: $color-gray800;
    font-weight: 500;
    display: flex;
    align-items: center;
    .user-document-page-number {
      display: flex;
      align-items: center;
      border-right: 1px solid $color-gray400;
      padding-right: 10px;
      .page-number {
        white-space: nowrap;
      }
      .number-badge {
        margin-left: 10px;
      }
    }
    .user-document-data-source-name {
      display: flex;
      align-items: center;
      fill: $color-gray600;
      .user-document-data-source-name-text {
        overflow: hidden;
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }
      &a:hover {
        .user-document-data-source-name-text {
          text-decoration: underline;
        }
      }
    }
  }
  .user-document-title {
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    word-break: break-all;
    font-weight: 500;
    a:hover {
      text-decoration: underline;
    }
  }

  .document-sentence-display {
    border-left: 1px solid $color-gray400;
    padding-left: 13px;
  }
}
</style>
