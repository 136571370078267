<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import api from '@/api';
import { Feature, PageName } from '@/api/tracking';
import Avatar from '@/components/common/atoms/avatar.vue';
import MentionAutocompleteTextarea from '@/components/common/molecules/mention-autocomplete-textarea.vue';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import Modal from '@/components/modals/modal.vue';
import { useUserInfo } from '@/utils/swr';
import { useStore } from '@/utils/vue';

export default defineComponent({
  components: {
    Modal,
    Avatar,
    MentionAutocompleteTextarea,
  },
  setup() {
    const disabledButton = ref(false);
    const { data: myInfo } = useUserInfo();
    const route = useRoute();
    const store = useStore();
    const { createSnackbar } = useSnackbar();

    const isPostingComment = ref(false);
    const hideModal = () => {
      store.commit('modal/hideModal');
    };
    const commentTextRef =
      ref<InstanceType<typeof MentionAutocompleteTextarea>>();
    const group = store.state.modal.group;
    if (group === undefined) throw new Error('group is undefined');

    const isPostEnabled = () => {
      if (disabledButton.value) return false;
      return (commentTextRef.value?.trimmedComment.length ?? 0) > 0;
    };

    const callPostComment = () => {
      if (commentTextRef.value?.trimmedComment !== '') {
        commentTextRef.value?.postComment();
      }
    };

    const post = async () => {
      if (!commentTextRef.value) return;
      hideModal();
      isPostingComment.value = true;
      const comment = commentTextRef.value.trimmedComment;
      const mention = commentTextRef.value.mention;
      if (comment.length === 0) return;
      const trackingBaseData = {
        pageName: 'group' as PageName,
        feature: 'group_activities' as Feature,
        pageUrl: route.fullPath,
      };

      try {
        const createdComment = await api.sendComment({
          groupId: Number(group.id),
          content: comment,
          mention,
          adpDocument: undefined,
          trackingBaseData: trackingBaseData,
        });
        store.commit('modal/setWithoutArticleComment', createdComment);
        createSnackbar({
          message: 'コメントを投稿しました',
          type: 'success',
        });
      } catch {
        createSnackbar({
          message: 'コメントを投稿できませんでした',
          type: 'error',
        });
      }
      isPostingComment.value = false;
    };

    return {
      hideModal,
      myInfo,
      disabledButton,
      commentTextRef,
      isPostingComment,
      isPostEnabled,
      post,
      callPostComment,
    };
  },
});
</script>

<template>
  <Modal @close="hideModal" :show-header="false">
    <template #body>
      <div class="m-body ccwam-body">
        <div class="ccwam-myinfo-area">
          <router-link
            v-if="myInfo"
            :to="`/users/${myInfo.id}`"
            custom
            v-slot="{ href, navigate }"
          >
            <a
              :href="href"
              @click="
                hideModal();
                navigate($event);
              "
            >
              <div class="ccwam-myinfo">
                <Avatar :image-url="myInfo.image_url"></Avatar>
                <div class="c-title c-title--m user-name">
                  {{ myInfo.user_name }}
                </div>
              </div>
            </a>
          </router-link>
        </div>
        <MentionAutocompleteTextarea
          class="comment-text-area ccwam-text-area"
          ref="commentTextRef"
          placeholder="コメントを書く（URLのみの投稿はできません）"
          @post-comment="post"
          :disabled="isPostingComment"
          :min-height="100"
          :show-url-preview="true"
          @update-button-status="disabledButton = $event"
        ></MentionAutocompleteTextarea>
        <div class="ccwam-btn-area">
          <button
            class="c-outlineBtn c-btn--auto c-outlineBtn--secondary"
            @click="hideModal"
          >
            キャンセル
          </button>
          <button
            class="c-btn c-btn--auto c-btn--AnewsPrimary"
            :disabled="!isPostEnabled() || isPostingComment"
            :class="{ disabled: !isPostEnabled() || isPostingComment }"
            @click="callPostComment"
          >
            投稿
          </button>
        </div>
      </div>
    </template>
  </Modal>
</template>
<style lang="scss" scoped>
.ccwam-body {
  padding-top: 10px;
  padding-bottom: 10px;

  .ccwam-btn-area {
    justify-content: flex-end;
    display: flex;
    margin-top: 8px;

    .c-outlineBtn:first-child {
      margin-right: 10px;
    }
  }
}

.ccwam-myinfo-area {
  margin-bottom: 10px;
  .ccwam-myinfo {
    display: flex;
    align-items: center;
    .user-name {
      margin-left: 8px;
    }
  }
}
</style>
