import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

export function initializeDayjs(): void {
  dayjs.extend(isSameOrBefore);
  dayjs.extend(relativeTime, {
    thresholds: [
      { l: 's', r: 59, d: 'second' },
      { l: 'm', r: 1 },
      { l: 'mm', r: 59, d: 'minute' },
      { l: 'h', r: 1 },
      { l: 'hh', r: 23, d: 'hour' },
      { l: 'd', r: 1 },
      { l: 'dd', r: 29, d: 'day' },
      { l: 'M', r: 1 },
      { l: 'MM', r: 11, d: 'month' },
      { l: 'y' },
      { l: 'yy', d: 'year' },
    ],
    rounding: Math.floor,
  });
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.tz.setDefault('Asia/Tokyo');
  dayjs.locale('ja');
}
