import { MAX_ORGANIZATION_TAG_NAME_LENGTH } from '@/constants';
import { OrganizationTag, OrganizationTagList } from '@/types';

export const validateMessages = {
  organizationTagBlank: '1文字以上で入力してください',
  organizationTagLong: `${MAX_ORGANIZATION_TAG_NAME_LENGTH}文字以内で入力してください`,
  organizationTagDuplicated: '既に存在している組織タグです',
};

type ErrorType =
  | 'invalid-not'
  | 'only-not-keywords'
  | 'only-hyphen'
  | 'duplicate-keyword'
  | 'vertical-bar'
  | 'greater-than'
  | 'less-than'
  | 'unmatched-parenthesis'
  | 'unmatched-double-quote'
  | 'creating-and-condition'
  | 'creating-or-condition'
  | 'no-space-parenthesis'
  | 'not-condition-on-parenthesis'
  | 'empty-parenthesis'
  | 'parenthesis-without-keywords';

export const ErrorMessages: Record<ErrorType, string> = {
  'invalid-not':
    'ハイフン(-)と単語の間にスペースが入っており、NOT条件が機能していない箇所があります。',
  'only-not-keywords': 'NOT条件単体でのテーマ設定はできません。',
  'only-hyphen': 'ハイフン(-)のみでのテーマ設定はできません。',
  'duplicate-keyword': '重複しているキーワード設定があります。',
  'vertical-bar': 'バーティカルバー（|）は使用できません。',
  'greater-than': '不等号（>）は使用できません。',
  'less-than': '不等号（<）は使用できません。',
  'unmatched-parenthesis': '()で閉じられていない箇所があります。',
  'unmatched-double-quote':
    'ダブルクオーテーション（"）で閉じられていない箇所があります。',
  'creating-and-condition': 'AND条件が指定されていない箇所があります。',
  'creating-or-condition': 'OR条件が指定されていない箇所があります。',
  'no-space-parenthesis': '()と他のキーワードの間に空白が必要です。',
  'not-condition-on-parenthesis': '()はNOT条件を使用できません。',
  'empty-parenthesis':
    'クオーテーションを使用する際は、キーワードを囲ってください。',
  'parenthesis-without-keywords':
    '()を使用する際は、キーワードを囲ってください。',
} as const;

function splitRespectingQuotes(str: string) {
  // https://stackoverflow.com/a/18647776
  const quoteStrRegex = /[^\s"]+|"([^"]*)"/gi;
  const result: string[] = [];

  let match: string[] | null = [];
  do {
    // execを呼ぶと次の結果が出る
    match = quoteStrRegex.exec(str);
    if (match !== null) {
      // match[1]があったら正規表現のグループになる（ `"..."` の部分）
      // match[0]は他の文字列
      result.push(match[1] ? match[1] : match[0]);
    }
  } while (match !== null);
  return result;
}

function convertFullWidthChar(str: string) {
  return str
    .replace(/（/g, '(')
    .replace(/）/g, ')')
    .replace(/“/g, '"')
    .replace(/”/g, '"');
}

function separateIntoUniqueWords(keyword: string): string[] {
  // 複数スペースや全角スペースは１つの半角スペースにする
  // 引用符に含まれたNOTキーワードの対応： -"hoge fuga" -> "-hoge fuga" にする
  const normalizedKeywords = keyword
    .toLowerCase()
    .replace(/\s+/g, ' ')
    .replace(/(^|\s)-"/g, '$1"-');
  const keywords = splitRespectingQuotes(normalizedKeywords);
  return Array.from(new Set(keywords));
}

const replaceParenthesisInDoubleQuotation = (keyword: string): string => {
  // "で囲まれた文字列内の()を<>で置き換える
  const regexSurroundedByDoubleQuotation = /"(.*?)"/g;
  return keyword.replace(regexSurroundedByDoubleQuotation, (_, p1) =>
    p1.replace(/\(/g, '<').replace(/\)/g, '>'),
  );
};

const distinguishParenthesisInKeywords = (keyword: string): string => {
  // 文字列として使われる()と、式として使われる()を区別するため、文字列として使われる()を<>で置き換える

  // ""でキーワードが囲まれた場合に対応するため"で囲まれた文字列内の()を<>で置き換える
  // 例: "食事摂取基準（2025年度版）"
  // ""で囲まれた文字列 , -""で囲まれた文字列 , その他の文字列 で分解する
  const regex = /"[^"]*"|-"[^"]*"|\S+/g;
  const formattedTokens =
    replaceParenthesisInDoubleQuotation(keyword)
      .match(regex)
      ?.map(token =>
        // "("が含まれており、先頭が"("でないかつ末尾が")"であれば、文字列として使われる()と判定する
        token.includes('(') && !token.startsWith('(') && token.endsWith(')')
          ? token.replace(/\(/g, '<').replace(/\)/g, '>')
          : token,
      ) || [];
  return formattedTokens.join(' ');
};

export const validateKeyword = (keyword: string): ErrorType[] => {
  const errors: ErrorType[] = [];
  const uniqueWords = separateIntoUniqueWords(keyword);
  if (uniqueWords.length > 0) {
    if (uniqueWords.length === 1 && uniqueWords[0] === '-') {
      errors.push('only-hyphen');
    } else if (uniqueWords.includes('-')) {
      errors.push('invalid-not');
    } else if (uniqueWords.filter(k => !k.startsWith('-')).length === 0) {
      errors.push('only-not-keywords');
    }
  }

  const convertedKeywordsStr = convertFullWidthChar(keyword);
  // ダブルクオーテーション内の括弧を引いて開き括弧と閉じ括弧の数を比較する
  // ダブルクオートでsplitして奇数番目にある括弧をダブルクオーテーション内の括弧として計算
  const countUnclosedParentheses = (str: string) => {
    let openParenthesisCount = 0;
    let closeParenthesisCount = 0;
    // ダブルクオートにある括弧はカウントしないので i += 2でループする
    const splitStr = str.split('"');
    for (let i = 0; i < splitStr.length; i += 2) {
      openParenthesisCount += splitStr[i].match(/\(/g)?.length ?? 0;
      closeParenthesisCount += splitStr[i].match(/\)/g)?.length ?? 0;
    }
    return Math.abs(closeParenthesisCount - openParenthesisCount);
  };
  if (countUnclosedParentheses(convertedKeywordsStr) !== 0) {
    errors.push('unmatched-parenthesis');
  }
  if ((convertedKeywordsStr.match(/"/g) || []).length % 2 !== 0) {
    errors.push('unmatched-double-quote');
  }
  if ((convertedKeywordsStr.match(/-\(/g) || []).length > 0) {
    errors.push('not-condition-on-parenthesis');
  }
  if (
    (
      replaceParenthesisInDoubleQuotation(convertedKeywordsStr).match(
        /\(\s*\)/g,
      ) || []
    ).length > 0
  ) {
    errors.push('parenthesis-without-keywords');
  }
  if (
    // (?!\(\(*), (?!\S\)\)+) -> 2重括弧を許容
    // (\S\(\s|\S\(\S|\s\)\S|\S\)\S) -> 括弧の外側にスペースがないことを許容しない。
    (
      distinguishParenthesisInKeywords(convertedKeywordsStr).match(
        /(?!\(\(*)(?!\S\)\)+)(?!-\()(\S\(\s|\S\(\S|\s\)\S|\S\)\S)/g,
      ) || []
    ).length > 0
  ) {
    errors.push('no-space-parenthesis');
  }
  // ダブルクオーテーションの `" "` は ` ` になる、`''`は `'` になる
  if (
    keyword.includes('""') ||
    uniqueWords.filter(k => k === ' ' || k.match(/(^""?$)|(^''?$)/)).length > 0
  ) {
    errors.push('empty-parenthesis');
  }
  if ((keyword.match(/\|/g) || []).length > 0) {
    errors.push('vertical-bar');
  }
  if ((keyword.match(/>/g) || []).length > 0) {
    errors.push('greater-than');
  }
  if ((keyword.match(/</g) || []).length > 0) {
    errors.push('less-than');
  }
  if (uniqueWords.slice(-1)[0] === 'and') {
    errors.push('creating-and-condition');
  }
  if (uniqueWords.slice(-1)[0] === 'or') {
    errors.push('creating-or-condition');
  }

  return errors;
};

export const validateMultiKeywords = (keywordList: string[]): ErrorType[] => {
  const errors: ErrorType[] = [];
  const allKeywords: string[] = [];

  keywordList.forEach(keyword => {
    const uniqueWords = separateIntoUniqueWords(keyword);
    if (uniqueWords.length > 0) {
      allKeywords.push(uniqueWords.sort().join(' '));
    }
  });

  if (allKeywords.length > new Set(allKeywords).size) {
    errors.push('duplicate-keyword');
  }
  return errors;
};

export const validateKeywordList = (keywordList: string[]): ErrorType[] => {
  return [
    ...keywordList.flatMap(validateKeyword),
    ...validateMultiKeywords(keywordList),
  ];
};

export const checkIsBlank = (value: string) => {
  return value?.trim() === '';
};

export const checkIsTooLongOrganizationTagName = (value: string): boolean => {
  return value.length > MAX_ORGANIZATION_TAG_NAME_LENGTH;
};

export const organizationTagValidate = (
  editedOrganizationTagName: string,
  organizationTags: OrganizationTagList,
  originOrganizationTag: OrganizationTag | undefined,
) => {
  if (checkIsBlank(editedOrganizationTagName)) {
    return organizationTagValidateMessages.blank;
  }
  if (checkIsTooLongOrganizationTagName(editedOrganizationTagName)) {
    return organizationTagValidateMessages.long;
  }
  if (
    organizationTags.some(
      organizationTag => organizationTag.name === editedOrganizationTagName,
    ) &&
    editedOrganizationTagName.toLocaleLowerCase() !==
      originOrganizationTag?.name.toLocaleLowerCase()
  ) {
    return organizationTagValidateMessages.duplicated;
  }
  return undefined;
};

const organizationTagValidateMessages = {
  blank: validateMessages.organizationTagBlank,
  long: validateMessages.organizationTagLong,
  duplicated: validateMessages.organizationTagDuplicated,
} as const;

export const validateWebhookUrl = (url: string): boolean => {
  if (!URL.canParse(url)) return false;

  const parsedUrl = new URL(url);
  const hostname = parsedUrl.hostname;

  // TeamsコネクタのIncoming Webhookは.office.comドメイン
  // Power Automate WorkflowsのWebhookは.azure.comドメイン
  return hostname.endsWith('.office.com') || hostname.endsWith('.azure.com');
};
