<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import api from '@/api';
import {
  MAX_GROUP_DESCRIPTION_LENGTH,
  MAX_GROUP_NAME_LENGTH,
} from '@/constants';
import { DgrCheckbox, DgrIcon } from '@stockmarkteam/donguri-ui';
import { isAxiosError } from 'axios';
import MSTeamsSettingsCard from '@/components/common/ms-teams-settings-card.vue';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import GroupButtons from '@/components/group/group-buttons.vue';
import Content from '@/components/layouts/content.vue';
import Header from '@/components/layouts/header.vue';
import { MSTeamsSettingsWithType } from '@/types';
import { getTabs, hasEditPermission } from '@/utils/group';
import { useGroups, useTeamInfo, useThemeList, useUserInfo } from '@/utils/swr';
import { useStore } from '@/utils/vue';

export default defineComponent({
  components: {
    Header,
    Content,
    MSTeamsSettingsCard,
    GroupButtons,
    DgrIcon,
    DgrCheckbox,
  },
  props: {
    isMenuVisible: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const { createSnackbar } = useSnackbar();

    const feedType = computed(() => store.state.feedType.feedType);

    const { data: myInfo } = useUserInfo();
    const { data: teamInfo } = useTeamInfo();
    const inputGroupName = ref('');
    const inputGroupDesc = ref('');
    const isEnableOverlookedMail = ref(true);
    const tooLongGroupName = computed(() => {
      return inputGroupName.value.length > MAX_GROUP_NAME_LENGTH;
    });
    const tooLongGroupDesc = computed(() => {
      return inputGroupDesc.value.length > MAX_GROUP_DESCRIPTION_LENGTH;
    });
    const updatable = computed(() => {
      return (
        inputGroupName.value.length > 0 &&
        !tooLongGroupName.value &&
        !tooLongGroupDesc.value
      );
    });
    const groupId = computed(() => Number(route.params.groupId));

    const { data: groups, mutate } = useGroups();
    const { data: themes } = useThemeList(feedType);
    const group = computed(() =>
      (groups.value ?? { groups: [] }).groups.find(g => g.id === groupId.value),
    );

    const initialGroupName = ref(group.value?.name);
    const initialGroupDesc = ref('');
    const settings = ref<MSTeamsSettingsWithType | undefined>(undefined);

    const fetchGroupDetail = async () => {
      let groupDetail;
      try {
        groupDetail = await api.fetchGroup(groupId.value);
      } catch (e) {
        router.push({ name: 'groupList' });
        throw e;
      }
      if (groupDetail.group_type === 'all_user_group') {
        router.push({ name: 'groupList' });
      }
      initialGroupName.value = groupDetail.name;
      initialGroupDesc.value = groupDetail.description;
      inputGroupName.value = groupDetail.name;
      inputGroupDesc.value = groupDetail.description ?? '';
      isEnableOverlookedMail.value = groupDetail.enabled_overlooked_mail;
    };

    onMounted(async () => {
      await fetchGroupDetail();
      await fetchTeamsSettings();
    });

    const fetchTeamsSettings = async () => {
      try {
        const teamsSettings = await api.fetchGroupMSTeamsSettings(
          groupId.value,
        );
        settings.value = {
          settings: teamsSettings,
          submittable: true,
          type: 'group',
        };
      } catch (e) {
        if (isAxiosError(e) && e.response?.status === 404) {
          settings.value = {
            settings: {
              webhook_url: '',
              enabled_ms_teams_notification: false,
              enabled_comment_content: true,
              enabled_group_mark_content: true,
            },
            type: 'group',
            submittable: true,
          };
          return;
        }
        throw e;
      }
    };

    watch(
      () => group.value,
      () =>
        group.value && !hasEditPermission(group.value, myInfo.value)
          ? router.push({ name: 'groupList' })
          : undefined,
    );

    const updateGroup = async () => {
      if (!updatable.value || !settings.value?.submittable) return;
      try {
        await api.updateGroup(
          groupId.value,
          inputGroupName.value,
          inputGroupDesc.value,
          isEnableOverlookedMail.value,
        );
        if (teamInfo.value?.enable_ms_teams_integration) {
          await api.updateGroupMSTeamsSettings(
            groupId.value,
            settings.value.settings,
          );
        }
        createSnackbar({
          message: 'グループを更新しました',
          type: 'success',
        });
        router.push(`/groups/${groupId.value}`);
      } catch (e) {
        createSnackbar({
          message: 'グループの設定を更新できませんでした',
          type: 'error',
        });
        throw e;
      }
    };

    const deleteGroup = async () => {
      const _deleteGroup = async () => {
        try {
          await api.deleteGroup(groupId.value);
          router.push({ name: 'groupList' });
          createSnackbar({
            message: 'グループを削除しました',
            type: 'success',
          });
        } catch {
          createSnackbar({
            message: 'グループを削除できませんでした',
            type: 'error',
          });
        }
      };
      let bodyText =
        '削除したグループは元に戻すことはできません。\n本当にグループを削除しますか？';
      if (
        (themes.value?.themes || []).filter(
          t => t.access_scope === 'group' && t.group_id === groupId.value,
        ).length > 0
      ) {
        bodyText =
          '削除したグループは元に戻すことはできません。\nまた、このグループを削除するとこのグループで公開範囲を指定しているテーマも一緒に削除されます。\n削除したくないテーマは公開範囲設定を変更してください。一度削除したテーマは復元できません。\n本当にグループを削除しますか？';
      }
      const payload = {
        headerText: 'グループを削除しますか？',
        bodyText,
        action: _deleteGroup,
      };
      store.commit('confirmationModal/showConfirmationModal');
      store.commit('confirmationModal/setTextAndAction', payload);
    };

    const moveToGroupMembersPage = () => {
      router.push({ name: 'groupMembers' });
    };

    const navigateToGroupActivity = () => {
      router.push({ name: 'groupActivity' });
    };

    return {
      teamInfo,
      initialGroupName,
      initialGroupDesc,
      inputGroupName,
      inputGroupDesc,
      isEnableOverlookedMail,
      tooLongGroupName,
      tooLongGroupDesc,
      MAX_GROUP_NAME_LENGTH,
      MAX_GROUP_DESCRIPTION_LENGTH,
      updateGroup,
      updatable,
      deleteGroup,
      moveToGroupMembersPage,
      group,
      settings,
      getTabs,
      myInfo,
      mutate,
      navigateToGroupActivity,
    };
  },
});
</script>

<template>
  <div class="o-group-edit" v-if="group && myInfo">
    <Header
      title="グループ編集"
      content-width="912px"
      :is-menu-visible="isMenuVisible"
    >
      <template #button>
        <GroupButtons>
          <button
            @click="navigateToGroupActivity()"
            class="c-btn c-btn--auto c-btn--small c-btnOutline"
          >
            キャンセル
          </button>
          <button
            class="o-save-button c-btn c-btn--small c-btn--AnewsPrimary"
            :class="{ disabled: !updatable || !settings?.submittable }"
            :disabled="!updatable || !settings?.submittable"
            @click="updateGroup"
          >
            保存
          </button>
        </GroupButtons>
      </template>
    </Header>
    <Content>
      <div class="o-group-edit-wrap">
        <div class="o-group-edit-body">
          <div
            class="o-group-name-label c-formBlock__label c-title c-titile--m"
          >
            グループ名
          </div>
          <div
            class="o-error c-formBlock__text c-formBlock__text--error"
            v-show="tooLongGroupName"
          >
            グループ名は {{ MAX_GROUP_NAME_LENGTH }}文字以内で入力してください。
          </div>
          <input
            class="o-group-name-input c-text c-text--m c-textInput"
            ref="groupNameInput"
            :class="{
              'c-formInput--error': tooLongGroupName,
              'm-normal-text-area': !tooLongGroupName,
            }"
            v-model.trim="inputGroupName"
            @keydown.ctrl.enter="updateGroup"
            @keydown.meta.enter="updateGroup"
          />
          <div
            class="o-group-desc-label c-formBlock__label c-title c-titile--m"
          >
            グループの説明
          </div>
          <div
            class="o-error c-formBlock__text c-formBlock__text--error"
            v-show="tooLongGroupDesc"
          >
            グループの説明は{{
              MAX_GROUP_DESCRIPTION_LENGTH
            }}文字以内で入力してください。
          </div>
          <textarea
            class="o-group-desc-input c-text c-text--m c-textArea"
            :class="{
              'c-formInput--error': tooLongGroupDesc,
              'm-normal-text-area': !tooLongGroupDesc,
            }"
            v-model.trim="inputGroupDesc"
            @keydown.ctrl.enter="updateGroup"
            @keydown.meta.enter="updateGroup"
          ></textarea>
          <div>
            <div
              class="o-group-mail-setting-label c-formBlock__label c-title c-titile--m"
            >
              メール配信設定
            </div>
            <DgrCheckbox
              class="o-checkbox-label c-text c-text--m"
              v-model="isEnableOverlookedMail"
              >読み逃しメールを配信</DgrCheckbox
            >
            <div class="o-checkbox-mail-setting c-text c-text--s">
              当日コメントやグループマークがあった記事を配信します。
            </div>
          </div>
          <div class="spacing-16"></div>
          <div
            class="c-title c-title--m"
            v-if="teamInfo && teamInfo.enable_ms_teams_integration"
          >
            Teams連携
          </div>
          <div
            class="o-group-ms-teams-settings"
            v-if="teamInfo && teamInfo.enable_ms_teams_integration"
          >
            <MSTeamsSettingsCard
              title="このグループの情報をTeamsに配信"
              v-model="settings"
            >
              Microsoft Teamsのチャネルに、このグループの情報を配信します。
            </MSTeamsSettingsCard>
          </div>
        </div>
        <div class="o-group-delete-section">
          <button
            class="group-delete-btn outlined c-btn--auto c-btn--small"
            @click="deleteGroup"
          >
            <DgrIcon size="small" name="trash" :keep-fill="false" />
            <span>削除</span>
          </button>
        </div>
      </div>
    </Content>
  </div>
</template>
<style lang="scss" scoped>
.o-group-edit {
  width: 100%;
  margin: -24px 0 0 0;
}

.title {
  color: #b3b3b3;
}

.o-save-button {
  width: 92px;
}

.o-group-edit-wrap {
  margin-top: 40px;
}

.o-group-edit-body {
  width: 616px;
  background-color: white;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 20px 16px;
}
.o-group-edit-nav {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.o-chevron-right {
  margin: 0 8px;
}
.o-group-desc-label,
.o-group-mail-setting-label {
  margin-top: 12px;
}
.o-group-name-input {
  width: 320px;
  outline: none;
}
.o-group-desc-input {
  width: 448px;
  height: 160px;
  resize: none;
  border-radius: 4px;
  outline: none;
  margin-bottom: 16px;
}
.o-error {
  font-size: 12px;
  margin-top: -4px;
}
.o-group-delete {
  padding: 20px 16px;
  width: 616px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 4px;
}
.o-delete-text {
  margin-bottom: 8px;
}
.o-group-ms-teams-settings {
  margin: 8px 0 16px 0;
}
.o-label {
  margin-bottom: 8px;
}
.o-group-delete-section {
  margin-top: 32px;
}
.o-checkbox-mail-setting {
  margin: 4px 0 0 20px;
  color: $color-gray600;
}

.group-delete-btn {
  display: block;
  background: #ffffff;
  &.outlined:not(:disabled) {
    border: solid 1px $color-orange1000;
    color: $color-orange1000;
    @media (any-hover: hover) {
      &:hover {
        background-color: $color-gray200;
      }
    }
  }
  .icon-box {
    display: inline-block;
    fill: $color-orange1000;
    margin-bottom: -2px;
    margin-right: 2px;
  }
}
</style>
