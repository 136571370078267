<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { DgrCheckbox, DgrIcon, DgrPopover } from '@stockmarkteam/donguri-ui';
import SearchBar from '@/components/common/molecules/search-bar.vue';
import { TagSelectItem } from '@/types';

const props = withDefaults(
  defineProps<{
    tagList: TagSelectItem[];
    targetTags: string[];
    placeholder: string;
    disabled?: boolean;
    emptyFilterMessage?: string;
    isShowCount?: boolean;
  }>(),
  {
    disabled: false,
    emptyFilterMessage: '指定したタグはありません。',
    isShowCount: true,
  },
);

const emit = defineEmits<{
  'filter-articles': [tags: string[]];
}>();

const filterQuery = ref('');
const filterTags = (query: string): void => {
  filterQuery.value = query;
};
const filteredArticleTags = computed(() => {
  return props.tagList.filter(tag => {
    return tag.name.includes(filterQuery.value);
  });
});

const selectedTags = ref([...props.targetTags]);
const selectTag = (tagName: string, checked: boolean): void => {
  if (checked) {
    selectedTags.value.push(tagName);
  } else {
    const index = selectedTags.value.indexOf(tagName);
    selectedTags.value.splice(index, 1);
  }
};
const isSelectedTag = (tagName: string) => selectedTags.value.includes(tagName);
watch(
  () => props.targetTags,
  targetTags => {
    selectedTags.value = [...targetTags];
  },
);

const handleSelectAllTags = (): void => {
  const filteredTagNameList = filteredArticleTags.value.map(tag => {
    return tag.name;
  });
  filteredTagNameList.forEach(tag => {
    const index = selectedTags.value.indexOf(tag);
    if (index >= 0) {
      selectedTags.value.splice(index, 1);
    }
  });
  selectedTags.value.push(...filteredTagNameList);
};

const handleDeselectAllTags = (): void => {
  const filteredTagNameList = filteredArticleTags.value.map(tag => {
    return tag.name;
  });
  filteredTagNameList.forEach(tagName => {
    const index = selectedTags.value.indexOf(tagName);
    if (index >= 0) {
      selectedTags.value.splice(index, 1);
    }
  });
};

const handleTagFilterArticles = (): void => {
  emit('filter-articles', selectedTags.value);
};

// activity-filter.vueのドロップダウン部分から使われるケースのために
// DgrPopoverに--z-dropdownと同じz-indexを指定しておく
const dropdownZIndex = Number(
  getComputedStyle(document.body).getPropertyValue('--z-dropdown'),
);
</script>

<template>
  <DgrPopover :z-index="dropdownZIndex">
    <template #default="{ triggerProps }">
      <button
        type="button"
        v-bind="triggerProps"
        class="c-selectBox c-selectBox--small c-text c-text--s"
        style="line-height: 12px"
        :disabled="props.disabled"
      >
        {{ placeholder }}

        <DgrIcon
          name="angle-down"
          size="small"
          class="c-selectBox__arrow"
          :keep-fill="false"
        />
      </button>
    </template>
    <template #content="{ close }">
      <div class="tag-select">
        <div class="tag-search-bar">
          <SearchBar @on-change-query="filterTags"></SearchBar>
        </div>
        <div class="tag-list">
          <div
            class="tags-empty c-text c-text--m"
            v-if="
              (!tagList || tagList.length === 0) && filterQuery.length === 0
            "
          >
            未設定
          </div>
          <div
            class="tags-empty c-text c-text--m"
            v-if="filteredArticleTags.length === 0 && filterQuery.length > 0"
          >
            {{ emptyFilterMessage }}
          </div>
          <div class="tag" v-for="tag in filteredArticleTags" :key="tag.id">
            <DgrCheckbox
              class="checkbox-label c-text c-text--m"
              :model-value="isSelectedTag(tag.name)"
              @update:model-value="selectTag(tag.name, $event)"
              >{{
                isShowCount ? tag.name + '(' + tag.count + ')' : tag.name
              }}</DgrCheckbox
            >
          </div>
        </div>
        <div class="btn-area">
          <button
            class="select-all-btn c-text c-text--m"
            @click="handleSelectAllTags"
          >
            すべて選択
          </button>
          <button
            class="select-deselect-btn c-text c-text--m"
            @click="handleDeselectAllTags"
          >
            選択解除
          </button>
          <button
            class="tag-filter-btn c-btn c-btn--small c-btn--AnewsPrimary"
            @click="
              handleTagFilterArticles();
              close();
            "
            data-testid="tag-filter-apply-btn"
          >
            適用
          </button>
        </div>
      </div>
    </template>
  </DgrPopover>
</template>

<style lang="scss" scoped>
button[disabled] {
  color: $color-gray600;
  cursor: not-allowed;

  .c-selectBox__arrow {
    fill: $color-gray600;
  }

  &:hover {
    background: white;
  }
}

.tag-select {
  position: absolute;
  z-index: var(--z-dropdown);
  width: 320px;
  padding-top: 16px;
  background: #ffffff;
  border: 1px solid $color-gray400;
  box-sizing: border-box;
  box-shadow: 0px 1px 5px rgba(74, 74, 74, 0.25);
  border-radius: 4px;

  .tag-search-bar {
    margin-bottom: 20px;
    padding: 0 16px;
  }

  .tag-list {
    max-height: 240px;
    overflow-y: scroll;
    padding: 0 16px;

    .tags-empty {
      color: $color-gray600;
      margin-bottom: 14px;
    }

    .tag {
      .checkbox-label {
        display: block;
        margin-bottom: 8px;
        word-break: break-word;
      }
    }
  }

  .btn-area {
    display: flex;
    align-items: center;
    padding: 12px;
    border-top: 1px solid $color-gray400;

    .select-all-btn,
    .select-deselect-btn {
      height: 32px;
      padding: 4px;
      background-color: transparent;
      color: $color-green600;
      border: none;
      cursor: pointer;
      align-items: center;
      border-radius: 4px;

      &:hover {
        background-color: $color-gray200;
      }
    }

    .select-all-btn {
      margin-right: 8px;
    }

    .tag-filter-btn {
      margin: 0 0 0 auto;
      width: 60px;
      align-items: center;
    }
  }
}
</style>
