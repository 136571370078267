import { computed, ref } from 'vue';
import {
  useWindowVirtualizer,
  VirtualizerOptions,
} from '@tanstack/vue-virtual';

export type Props = {
  getCount: () => number;
} & Pick<VirtualizerOptions<Window, Element>, 'estimateSize' | 'overscan'>;

/** window基準の仮想スクロール */
export const useWindowVirtualScroll = ({
  getCount,
  estimateSize,
  overscan = 0,
}: Props) => {
  const scrollableElement = ref<HTMLElement | null>(null);
  const scrollMargin = ref(0);
  const rowVirtualizerOptions = computed(() => {
    return {
      count: getCount(),
      estimateSize,
      scrollMargin: scrollMargin.value,
      overscan,
    };
  });
  const rowVirtualizer = useWindowVirtualizer(rowVirtualizerOptions);
  const virtualRows = computed(() => rowVirtualizer.value.getVirtualItems());
  const totalSize = computed(() => rowVirtualizer.value.getTotalSize());

  return {
    rowVirtualizer,
    virtualRows,
    scrollMargin,
    scrollableElement,
    totalSize,
  };
};
