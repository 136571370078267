<script lang="ts" setup>
import { useStore } from 'vuex';
import { Group } from '@/types';
import { useEmitter } from '@/utils/vue';

const store = useStore();
const emitter = useEmitter();
const props = defineProps<{
  group: Group;
}>();

const openCommentCreateModalWithoutArticleInfo = () => {
  store.commit('modal/setGroup', props.group);
  store.commit('modal/showModal', 'commentCreateWithoutArticle');
};
// グループへのコメント追加時に、コメントUIをactivity先頭に追加するための処理
store.subscribe(mutation => {
  if (mutation.type === 'modal/setWithoutArticleComment') {
    emitter.emit('pagination-item-create', mutation.payload);
  }
});
</script>

<template>
  <button
    class="c-btn c-btn--small group-activity-add-comment-btn"
    @click="openCommentCreateModalWithoutArticleInfo"
  >
    グループにコメントする
  </button>
</template>

<style lang="scss" scoped>
.group-activity-add-comment-btn {
  width: auto;
  padding-left: 8px;
  padding-right: 8px;
  background-color: white;
  border: 1px solid $color-green600;
  color: $color-green600;
}
</style>
