import axios from 'axios';
import { KeywordRecommendResponse } from '@/types';

export async function fetchKeywordsRecommend(
  queries: string[],
  minScore?: number,
): Promise<KeywordRecommendResponse> {
  const { data } = await axios.post<KeywordRecommendResponse>(
    '/keyword_recommendation',
    {
      queries,
      minScore,
    },
  );
  return data;
}
