import { searchCache } from '@/apiCache';
import axios from 'axios';
import Qs from 'qs';
import {
  Article,
  OnlyTrackingParam,
  ReportSearchRequestParam,
  SearchResultResponse,
} from '@/types';
import { searchExpandedQueryCache } from '@/utils/searchExpandedQueryCache';
import {
  getEventDataForSearchAPI,
  trackingSearchEvent,
} from '@/utils/trackingHelper';

export async function reportSearch(
  searchParams: ReportSearchRequestParam & OnlyTrackingParam,
): Promise<{
  result_count: number;
  search_tracking_session_id: string;
  expanded_queries: string[];
  articles: Article[];
  exceed_max_result_window: boolean | null;
}> {
  let data: SearchResultResponse;
  const queryStr = Qs.stringify(searchParams, { arrayFormat: 'brackets' });
  const url = `/report_search?${queryStr}`;

  // 同じ検索結果でも初回以降の検索ではcached_expanded_queriesが新たに追加されるため、
  // searchCacheはcached_expanded_queriesを除いたパラメータをキーとしてキャッシュする
  // また計測に関するパラメータは除外する
  const {
    cached_expanded_queries: _c,
    feature: _f,
    request_id: _r,
    search_query: _s,
    rank: _ra,
    ...searchCacheParams
  } = searchParams;
  const searchCacheKey = JSON.stringify(searchCacheParams);
  const cache = searchCache.getCache(searchCacheKey);

  if (cache) {
    data = cache;
  } else {
    data = (await axios.get<SearchResultResponse>(url)).data;

    if (
      !searchParams.cached_expanded_queries &&
      searchParams.query &&
      data.expanded_queries &&
      data.expanded_queries.length > 0
    ) {
      searchExpandedQueryCache.setCache(
        searchParams.query,
        data.expanded_queries,
      );
      searchParams.cached_expanded_queries = data.expanded_queries;
    }
    if (data.search_tracking_session_id)
      searchCache.setCache(searchCacheKey, data);
  }

  // 計測
  await trackingSearchEvent(
    'search',
    getEventDataForSearchAPI(
      searchParams,
      data.search_articles,
      data.search_result_count,
      data.search_tracking_session_id,
    ),
  );

  return {
    result_count: data.search_result_count,
    search_tracking_session_id: data.search_tracking_session_id,
    expanded_queries: data.expanded_queries,
    articles: data.search_articles,
    exceed_max_result_window: data.exceed_max_result_window,
  };
}
