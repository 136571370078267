<script lang="ts">
import { computed, defineComponent, onMounted, ref } from 'vue';
import { DgrIcon } from '@stockmarkteam/donguri-ui';
import { ThemeList } from '@/types';
import { useStore } from '@/utils/vue';

export default defineComponent({
  components: {
    DgrIcon,
  },
  setup(_, context) {
    const store = useStore();
    const hasFetchedThemes = computed(
      () => store.state.themeList.hasFetchedThemes,
    );
    const memberThemes = computed<ThemeList[]>(
      () => store.getters['themeList/memberThemes'],
    );
    const favoriteThemes = computed<ThemeList[]>(
      () => store.getters['themeList/favoriteThemes'],
    );
    const userInfo = computed(() => store.state.userInfo.userInfo);
    const selectedFavoriteThemes = ref<ThemeList[]>([]);

    onMounted(
      () => (selectedFavoriteThemes.value = favoriteThemes.value.slice()),
    );

    const favoritePossibleThemes = computed<ThemeList[]>(() => {
      return memberThemes.value.filter(
        t => t.access_scope !== 'personal' || t.user_id === userInfo.value?.id,
      );
    });

    const isFavoriteTheme = (theme: ThemeList): boolean | undefined => {
      return selectedFavoriteThemes.value.some(
        favoriteTheme => favoriteTheme.id === theme.id,
      );
    };

    const isSelectedThemesEmpty = (): boolean => {
      return selectedFavoriteThemes.value.length === 0;
    };

    const selectFavoriteTheme = (theme: ThemeList): void => {
      const result = selectedFavoriteThemes.value.find(
        favoriteTheme => favoriteTheme.id === theme.id,
      );
      if (result) {
        selectedFavoriteThemes.value = selectedFavoriteThemes.value.filter(
          favoriteTheme => favoriteTheme.id !== theme.id,
        );
      } else {
        selectedFavoriteThemes.value.push(theme);
      }
    };

    const handleFavoriteTheme = async (): Promise<void> => {
      // 新たに選択したテーマを追加する
      selectedFavoriteThemes.value.forEach(theme => {
        const result = favoriteThemes.value.find(
          favoriteTheme => favoriteTheme.id === theme.id,
        );

        if (!result) context.emit('toggleFavorite', theme); // eslint-disable-line vue/require-explicit-emits
      });

      // 選択解除したお気に入りテーマを削除する
      favoriteThemes.value.forEach(favoriteTheme => {
        const result = selectedFavoriteThemes.value.find(
          theme => theme.id === favoriteTheme.id,
        );

        if (!result) context.emit('toggleFavorite', favoriteTheme); // eslint-disable-line vue/require-explicit-emits
      });
    };

    return {
      memberThemes,
      favoritePossibleThemes,
      hasFetchedThemes,
      isFavoriteTheme,
      isSelectedThemesEmpty,
      selectFavoriteTheme,
      handleFavoriteTheme,
    };
  },
});
</script>

<template>
  <div class="o-theme-favorite-board">
    <div class="o-explanation" v-if="memberThemes.length === 0">
      <img class="o-image" src="@/assets/top.png" />
      <div class="o-sentence">
        <div class="o-sentence1 c-title c-title--l">Anewsへようこそ！</div>
        <div class="o-sentence2 o-text c-text c-text--m">
          興味のあるテーマを探して<br />
          ニュースを取得できるようにしましょう。
        </div>
      </div>
      <router-link :to="{ name: 'themeSearch' }">
        <button class="c-btn c-btn--small c-btn--AnewsPrimary">
          <DgrIcon
            size="small"
            :keep-fill="false"
            name="search"
            class="small"
          />
          <div class="theme-search c-text c-text--m">テーマを探す</div>
        </button>
      </router-link>
    </div>
    <div class="o-explanation" v-if="favoritePossibleThemes.length !== 0">
      <div class="o-sentence">
        <div class="o-sentence1 c-title c-title--l">テーマのお気に入り</div>
        <div class="o-sentence2 o-text c-text c-text--m">
          追加したテーマをお気に入りしてみましょう。<br />
          トップページで表示されたり、最新ニュースがメールで届くようになります。
        </div>
      </div>
      <div class="select-button-area">
        <div
          class="m-button"
          v-for="theme in favoritePossibleThemes"
          :key="theme.id"
          :class="{ 'o-favorite': isFavoriteTheme(theme) }"
          @click.prevent="selectFavoriteTheme(theme)"
        >
          <div
            class="m-status c-text c-text--m"
            :class="{ 'o-favorite': isFavoriteTheme(theme) }"
          >
            {{ `# ${theme.name}` }}
          </div>
          <DgrIcon
            v-if="isFavoriteTheme(theme)"
            size="small"
            name="star-fill"
            :keep-fill="false"
            class="favorite"
          />
          <DgrIcon
            v-else
            size="small"
            name="star"
            :keep-fill="false"
            class="primary"
          />
        </div>
      </div>
      <div class="apply-button-area">
        <button
          class="c-btn c-btn--small c-btn--AnewsPrimary c-text c-text--m"
          @click.prevent="handleFavoriteTheme()"
          :class="{ disabled: isSelectedThemesEmpty() }"
          :disabled="isSelectedThemesEmpty()"
        >
          適用
        </button>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.o-theme-favorite-board {
  .o-explanation {
    text-align: center;
    flex-direction: row;
    width: 616px;
    background: #ffffff;
    border: 1px solid #e6e6e6;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 16px 0;
    img {
      object-fit: cover;
      width: 119px;
      height: 86px;
    }
    .o-sentence {
      .o-sentence1 {
        margin-top: 12px;
      }
      .o-sentence2 {
        margin: 8px 0;
      }
    }
    button {
      margin: 16px auto 0;
      width: 136px;
      color: white;
      fill: white;
    }
    .select-button-area {
      display: flex;
      max-width: 616px;
      flex-wrap: wrap;
      padding: 0 16px;
      justify-content: center;
      .m-button {
        display: flex;
        padding: 5px 4px;
        background: #ffffff;
        border: 1px solid #e6e6e6;
        box-sizing: border-box;
        border-radius: 4px;
        align-items: center;
        margin: 8px 6px 0;
        cursor: pointer;
        &:hover {
          background: #f2f2f2;
        }
        &.o-favorite {
          background: #eaf6f2;
          border: 1px solid #1da482;
          box-sizing: border-box;
          border-radius: 4px;
        }
        .m-status {
          color: #4a4a4a;
          margin-right: 5px;
          &.o-favorite {
            color: #1da482;
          }
        }
        .primary {
          fill: $color-green600;
        }
        .favorite {
          fill: #ef811f; /* donguri-uiで定義されているastartegy-primaryの色 */
        }
      }
    }
    .apply-button-area {
      button {
        margin: 16px auto 0;
        width: 65px;
      }
    }
  }
}
.o-text {
  color: #4a4a4a;
}
.o-bold-text {
  font-weight: bold;
}
</style>
