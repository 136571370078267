<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
  watch,
} from 'vue';
import api from '@/api/index';
import { KEYWORD_RECOMMEND_DEBOUNCE_TIME } from '@/constants';
import { isAxiosError } from 'axios';
import { SimilarKeyword } from '@/types';
import { debounce } from '@/utils/debounce';

export default defineComponent({
  props: {
    keywordList: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  setup(props, context) {
    const keywordRecommends = computed(() => {
      if (!similarKeywords.value) return [];
      return similarKeywords.value.filter(k => !props.keywordList.includes(k));
    });

    const similarKeywordsResponse = ref<SimilarKeyword>({});
    const similarKeywords = ref<string[]>([]);
    const isLoading = ref(false);
    const isError = ref(false);

    onMounted(async () => {
      await updateKeywordRecommend();
    });

    const updateKeywordRecommend = async () => {
      isLoading.value = true;
      await getKeywordsRecommend(props.keywordList);

      similarKeywords.value = Object.keys(similarKeywordsResponse.value)
        .filter(k => !props.keywordList.includes(k))
        .slice(0, 10);
      isLoading.value = false;
    };

    const getKeywordsRecommend = async (queries: string[]) => {
      isError.value = false;
      try {
        const data = await api.fetchKeywordsRecommend(queries);
        similarKeywordsResponse.value = data.similar_keywords;
        isError.value = !!data.error_code;
      } catch (e) {
        if (isAxiosError(e)) {
          similarKeywordsResponse.value = {};
          isError.value = true;
        } else {
          throw e;
        }
      }
    };

    const addKeywordRecommend = (recommend: string) => {
      const rank =
        Object.keys(similarKeywordsResponse.value).indexOf(recommend) + 1;
      context.emit('add-keyword-recommend', recommend, rank); // eslint-disable-line vue/require-explicit-emits
    };

    const isEmptySimilarKeywordsResponse = computed(
      () => Object.keys(similarKeywordsResponse.value).length === 0,
    );

    watch(
      () => props.keywordList,
      debounce(() => {
        if (isEmptySimilarKeywordsResponse.value) {
          // レコメンドがない状態の場合は画面上のレコメンドを更新
          updateKeywordRecommend();
        } else {
          // レコメンドがある状態の場合は現状のkeywordListでのRecommendの取得のみを行う(画面上には反映しない)
          getKeywordsRecommend(props.keywordList);
        }
      }, KEYWORD_RECOMMEND_DEBOUNCE_TIME),
    );

    return {
      keywordRecommends,
      updateKeywordRecommend,
      isLoading,
      isError,
      addKeywordRecommend,
      isEmptySimilarKeywordsResponse,
    };
  },
});
</script>

<template>
  <div class="recommend">
    <div class="title">
      <div
        v-if="keywordRecommends.length > 0 || isError"
        class="c-title c-title--s"
      >
        おすすめのキーワード
      </div>
      <div
        v-if="!isEmptySimilarKeywordsResponse && keywordRecommends.length < 10"
        class="new-recommend c-title c-title--s"
      >
        新しい候補があります
        <button
          @click="updateKeywordRecommend"
          class="update-button c-outlineBtn--auto c-btn--text"
        >
          更新
        </button>
      </div>
    </div>
    <div v-if="isLoading" class="loading"><div class="loader"></div></div>
    <div v-else-if="isError" class="error c-text c-text--m">
      取得できませんでした。一時的な問題の可能性があります。<br />
      時間をおいて再読み込みしてください。
      <button
        class="c-outlineBtn c-btn c-btn--auto"
        @click="updateKeywordRecommend"
      >
        再読み込み
      </button>
    </div>
    <div v-else>
      <div class="recommend-list">
        <button
          v-for="recommend in keywordRecommends"
          :key="recommend"
          class="recommend-item c-btn c-btn--auto c-btnOutline"
          @click="addKeywordRecommend(recommend)"
        >
          {{ recommend }}
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.recommend {
  margin-top: 16px;
}
.title {
  display: flex;
  justify-content: space-between;
}
.new-recommend {
  display: flex;
}
.update-button {
  margin-left: 10px;
  color: $color-green600;

  padding: 0;
  height: auto;

  &:hover {
    background: #ffffff;
    color: $color-green600;
  }
}
.recommend-list {
  margin: 8px 0;
}
.recommend-item {
  display: inline-block;
  border-radius: 20px;
  height: 30px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.loading {
  display: grid;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.error {
  margin-top: 8px;
  color: $color-orange1000;

  button {
    margin-top: 8px;
  }
}

.loader {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: solid 4px;
  border-color: $color-green400 #00000010 #00000010;
  position: relative;
  animation-name: spin;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
