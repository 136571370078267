<script lang="ts">
import { defineComponent, onMounted } from 'vue';
import { userSession } from '@/utils/userSession';
import { useStore } from '@/utils/vue';

export default defineComponent({
  setup() {
    const store = useStore();
    const hideModal = () => {
      store.commit('modal/hideModal');
    };

    onMounted(async () => {
      userSession.setIsShownTranslateNotification();
    });

    return {
      hideModal,
    };
  },
});
</script>

<template>
  <div>
    <sm-dialog @close="hideModal" class="screen-center auto-height">
      <template #header>
        <div class="m-header">
          <div class="m-header-text c-title c-title--m">
            海外メディアの記事配信について
          </div>
        </div>
      </template>
      <template #body>
        <div class="m-body">
          <div class="c-text c-text--m">
            海外メディアの記事についてはタイトルが自動で日本語に翻訳されて表示されます。<br />
            翻訳がされていない状態で表示したい場合は
            <router-link
              :to="{ name: 'userSettings' }"
              custom
              v-slot="{ href, navigate }"
              ><a
                :href="href"
                @click="
                  event => {
                    hideModal();
                    navigate(event);
                  }
                "
                class="c-textLink c-textLink--underbar"
                >マイページの設定</a
              ></router-link
            >より変更が可能です。
          </div>
        </div>
      </template>
    </sm-dialog>
  </div>
</template>
<style lang="scss" scoped></style>
