/**
 * IPアドレスのバリデーション
 * - CIDR表記ありなしいずれも対応
 * - プライベートIPアドレスは許可しない
 * - IPv4のみ対応
 */
export const validateIpAddress = (ipAddress: string): boolean => {
  const regex =
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(?:\/([0-9]|[1-2][0-9]|3[0-2]))?$/;
  const match = ipAddress.match(regex);
  if (!match) {
    return false;
  }

  const firstOctet = Number(match[1]);
  const secondOctet = Number(match[2]);
  // プライベートIPアドレスは許可しない
  if (
    firstOctet === 10 ||
    (firstOctet === 172 && secondOctet >= 16 && secondOctet <= 31) ||
    (firstOctet === 192 && secondOctet === 168)
  ) {
    return false;
  }
  // ループバックアドレスは許可しない
  else if (firstOctet === 127) {
    return false;
  }

  return true;
};
