import axios from 'axios';
import { PostWebPushSubscriptionResponse } from '@/types';
import { userSession } from '@/utils/userSession';

export async function postWebPushSubscription({
  pushSubscriptionJson,
  enabledMentionNotificationBrowser,
  enabledOtherUsersActivityNotificationBrowser,
}: {
  pushSubscriptionJson: PushSubscriptionJSON;
  enabledMentionNotificationBrowser: boolean;
  enabledOtherUsersActivityNotificationBrowser: boolean;
}): Promise<PostWebPushSubscriptionResponse> {
  const myUserId = userSession.getUserId();

  const result = await axios.post<PostWebPushSubscriptionResponse>(
    `/web_push_subscriptions`,
    {
      user_id: myUserId,
      subscription: pushSubscriptionJson,
      enabled_mention_notification_browser: enabledMentionNotificationBrowser,
      enabled_other_users_activity_notification_browser:
        enabledOtherUsersActivityNotificationBrowser,
    },
  );

  return result.data;
}
