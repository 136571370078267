<script setup lang="ts">
import { computed } from 'vue';
import api from '@/api';
import { ContentsContext } from '@/api/tracking';
import { DgrIcon } from '@stockmarkteam/donguri-ui';
import UserDocumentOpenPreviewModalButton from '@/components/user-document/user-document-open-preview-modal-button.vue';
import {
  UserDocumentEntity,
  UserDocumentEventName,
  UserDocumentViewWithParentInfo,
} from '@/types';
import {
  getDocumentUrl,
  getFileTypeIcon,
  getFolderName,
  getIsPageNumberAvailable,
  getModifiedAt,
} from '@/utils/user-document/common';
import { featureFlags } from '@/featureFlags';

interface Props {
  userDocumentSimilarDocument: UserDocumentEntity;
  parentUserDocument: UserDocumentEntity;
  parentRank: number | undefined;
  rankInParent: number;
  contentsContext: ContentsContext;
}

const trackingSimilarUserDocumentEvent = async (
  eventName: UserDocumentEventName,
) =>
  await api.trackingUserDocumentEvent(eventName, {
    page: {
      name: 'user_document_search',
    },
    feature: 'user_document_search',
    rank: props.rankInParent,
    user_document: {
      id: props.userDocumentSimilarDocument.id,
    },
    parent_user_document: {
      rank: props.parentRank,
      user_document: {
        id: props.parentUserDocument.id,
      },
    },
    contents_context: props.contentsContext as Extract<
      ContentsContext,
      { event_name: 'user_document_search' }
    >,
    view_type: 'original',
  } as UserDocumentViewWithParentInfo);

const props = defineProps<Props>();
const userDocumentSimilarDocument = computed(
  () => props.userDocumentSimilarDocument,
);
const isPageNumberAvailable = computed(() =>
  getIsPageNumberAvailable(props.userDocumentSimilarDocument),
);
const fileTypeIcon = computed(() =>
  getFileTypeIcon(props.userDocumentSimilarDocument),
);
const folderName = computed(() =>
  getFolderName(props.userDocumentSimilarDocument),
);
const documentUrl = computed(() =>
  getDocumentUrl(props.userDocumentSimilarDocument),
);
const modifiedAt = computed(() =>
  getModifiedAt(props.userDocumentSimilarDocument),
);

const ANDEV_5318_PREVIEW_MODAL = featureFlags.ANDEV_5318_PREVIEW_MODAL;

const userDocumentViewInfo = {
  page: {
    name: 'user_document_search',
  },
  feature: 'user_document_search',
  rank: props.rankInParent,
  user_document: {
    id: props.userDocumentSimilarDocument.id,
  },
  parent_user_document: {
    rank: props.parentRank,
    user_document: {
      id: props.parentUserDocument.id,
    },
  },
  contents_context: props.contentsContext as Extract<
    ContentsContext,
    { event_name: 'user_document_search' }
  >,
  view_type: 'preview',
} as UserDocumentViewWithParentInfo;
</script>

<template>
  <div class="user-document-similar-document-card">
    <div class="user-document-content">
      <div class="user-document-data-source-info c-text c-text--xs">
        <div class="user-document-page-number">
          <div>
            <DgrIcon :name="fileTypeIcon" />
          </div>
          <div class="spacing-02"></div>
          <template v-if="isPageNumberAvailable">
            <div class="page-number">
              ページ：{{ userDocumentSimilarDocument.page_number }}
            </div>
          </template>
        </div>
        <div class="spacing-04"></div>
        <component
          :is="userDocumentSimilarDocument.folder_url ? 'a' : 'span'"
          :href="userDocumentSimilarDocument.folder_url"
          target="_blank"
          rel="noopener noreferrer"
          class="user-document-data-source-name"
          @click="trackingSimilarUserDocumentEvent('user_document_folder_view')"
        >
          <DgrIcon :keep-fill="false" name="folder" />
          <div class="spacing-04"></div>
          <div class="user-document-data-source-name-text">
            {{ folderName }}
          </div>
        </component>
      </div>
      <div class="spacing-04"></div>
      <div class="user-document-title c-title c-title--xm">
        <a
          :href="documentUrl"
          target="_blank"
          rel="noopener noreferrer"
          @click="trackingSimilarUserDocumentEvent('user_document_view')"
        >
          {{ userDocumentSimilarDocument.title }}
        </a>
      </div>
      <div class="spacing-04"></div>
      <div class="user-document-update-info">
        <div class="c-text c-text--s">更新日：{{ modifiedAt }}</div>
        <div class="c-text c-text--s">
          更新者：{{ userDocumentSimilarDocument.last_updated_by }}
        </div>
      </div>
      <div v-if="ANDEV_5318_PREVIEW_MODAL">
        <UserDocumentOpenPreviewModalButton
          :title="userDocumentSimilarDocument.filename"
          :target-page="userDocumentSimilarDocument.page_number"
          :file-total-pages="userDocumentSimilarDocument.file_total_pages"
          :preview-image-path-template="
            userDocumentSimilarDocument.preview_image_path_template
          "
          :user-document-view-info="userDocumentViewInfo"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.user-document-similar-document-card {
  width: 100%;
  background-color: white;
  box-sizing: border-box;
  display: flex;

  .user-document-content {
    width: 100%;
    padding: 8px 16px;
    .user-document-update-info {
      display: flex;
      color: $color-gray800;
      font-weight: 400;
      column-gap: 8px;
      padding: 4px 0;
    }
  }
  .user-document-data-source-info {
    padding: 4px 0;
    color: $color-gray800;
    font-weight: 500;
    display: flex;
    align-items: center;
    .user-document-page-number {
      display: flex;
      align-items: center;
      border-right: 1px solid $color-gray400;
      padding-right: 10px;
      .page-number {
        white-space: nowrap;
      }
    }
    .user-document-data-source-name {
      display: flex;
      align-items: center;
      fill: $color-gray600;
      .user-document-data-source-name-text {
        overflow: hidden;
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        line-clamp: 1;
        -webkit-line-clamp: 1;
      }
      &a:hover {
        .user-document-data-source-name-text {
          text-decoration: underline;
        }
      }
    }
  }
  .user-document-title {
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    line-clamp: 1;
    -webkit-line-clamp: 1;
    word-break: break-all;
    font-weight: 500;
    a:hover {
      text-decoration: underline;
    }
  }
}
</style>
