<script lang="ts">
import { computed, defineComponent } from 'vue';
import ItemList from '@/components/menu/common/item-list.vue';
import SectionWrapper from '@/components/menu/sections/section-wrapper.vue';
import { sortActiveGroups } from '@/utils/sorters';
import {
  useGroups,
  useGroupUnreadActivityCounts,
  useUserInfo,
} from '@/utils/swr';
import { useEmitter, useStore } from '@/utils/vue';

type ItemListProps = InstanceType<typeof ItemList>['$props'];
type ItemsProps = ItemListProps['items'];

export default defineComponent({
  components: {
    ItemList,
    SectionWrapper,
  },
  setup() {
    const store = useStore();
    const emitter = useEmitter();
    const { data: userInfo } = useUserInfo();
    const { data: groups, mutate } = useGroups();
    const { data: groupUnreadActivityCounts } = useGroupUnreadActivityCounts();

    const memberGroups = computed(() => {
      if (groups.value) {
        return sortActiveGroups(groups.value.groups, 'name', 'asc').filter(
          group => group.is_member,
        );
      }
      return [];
    });
    const isCreateGroup = computed(() => {
      return ['group_admin', 'admin'].includes(userInfo.value?.role ?? '');
    });

    const items = computed(() => {
      let items: ItemsProps = [
        {
          id: 'group_search',
          name: 'グループ検索',
          route: { name: 'groupList' },
          icon: 'search',
          isSelectedExactRoute: true,
        },
      ];
      if (isCreateGroup.value) {
        items.push({
          id: 'group_create',
          name: 'グループ作成',
          onClick: () => store.commit('modal/showModal', 'groupCreate'),
          icon: 'plus',
        });
      }
      return items;
    });

    emitter.on('update-group-list', () => {
      mutate();
      store.dispatch('groups/fetchGroups', userInfo.value?.id);
    });

    const getUnreadCount = (groupId: number) =>
      groupUnreadActivityCounts.value?.unread_activity_counts[
        String(groupId)
      ] ?? 0;

    const groupItems = computed(
      () =>
        memberGroups.value.map(g => ({
          id: g.id,
          name: g.name,
          route: {
            name: 'groupActivity',
            params: { groupId: String(g.id) },
          },
          icon: 'users',
          count: getUnreadCount(g.id),
          onClick: () => {
            if (groupUnreadActivityCounts.value && getUnreadCount(g.id)) {
              groupUnreadActivityCounts.value.unread_activity_counts[
                String(g.id)
              ] = 0;
            }
          },
        })) as ItemsProps,
    );

    return {
      items,
      groupItems,
    };
  },
});
</script>
<template>
  <SectionWrapper>
    <ItemList :items="items" />
    <ItemList
      title="グループ"
      :items="groupItems"
      :key="`groups-${groupItems.length}`"
    />
  </SectionWrapper>
</template>
<style lang="scss" scoped></style>
