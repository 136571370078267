import { Group, Theme, UserInfo } from '@/types';

// テーマ編集権限
export const themeEditable = (
  userInfo: UserInfo | undefined,
  theme: Theme | undefined,
  group: Group | undefined,
): boolean => {
  // 管理者は全てのテーマを編集できる
  if (userInfo?.role === 'admin') return true;
  // グループ管理者または編集者は所属するグループのテーマを編集できる
  else if (
    ['group_admin', 'editor'].includes(userInfo?.role ?? '') &&
    theme?.access_scope === 'group' &&
    group?.is_member
  )
    return true;
  // グループ管理者または編集者は全体テーマを編集できる
  else if (
    ['group_admin', 'editor'].includes(userInfo?.role ?? '') &&
    theme?.access_scope === 'public'
  )
    return true;
  // 全てのユーザーは自身の個人テーマを編集できる
  else if (
    theme?.access_scope === 'personal' &&
    theme?.user_id === userInfo?.id
  )
    return true;

  return false;
};

// 記事の非表示権限
export const articleHideable = (
  userInfo: UserInfo | undefined,
  theme: Theme | undefined,
  group: Group | undefined,
): boolean => {
  // 管理者は全てのテーマ内フィードを非表示にできる
  if (userInfo?.role === 'admin') return true;
  // 全てのユーザーは全体テーマ内のフィードを非表示にできる
  else if (theme?.access_scope == 'public') return true;
  // 自分の個人テーマを非表示にできる
  else if (
    theme?.access_scope === 'personal' &&
    theme?.user_id === userInfo?.id
  )
    return true;
  // 所属しているグループのテーマ内フィードを非表示にできる
  else if (theme?.access_scope === 'group' && group?.is_member) return true;

  return false;
};
