<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import api from '@/api/index';
import { DgrSelectbox } from '@stockmarkteam/donguri-ui';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import Content from '@/components/layouts/content.vue';
import Header from '@/components/layouts/header.vue';
import OrganizationTagLabels from '@/components/others/organization-tags/organization-tag-labels.vue';
import { MypageAccessScope } from '@/types';
import { useUserInfo, useUserSettingsProfile } from '@/utils/swr';

const route = useRoute();
const { createSnackbar } = useSnackbar();

const { data: userInfo } = useUserInfo();
const { data: userSettings } = useUserSettingsProfile();

const selectedScope = ref<MypageAccessScope>('public');

watch(
  () => userSettings.value,
  () => {
    if (!userSettings.value) return;
    selectedScope.value = userSettings.value.mypage_access_scope;
  },
  { immediate: true },
);

const scopes = computed<{ label: string; value: MypageAccessScope }[]>(() => {
  if (!userSettings.value) return [];
  return [
    { label: '全体', value: 'public' },
    { label: '自分のみ', value: 'personal' },
  ];
});

const submit = async () => {
  if (!userSettings.value) return;
  userSettings.value.mypage_access_scope = selectedScope.value;
  try {
    await api.updateUserSettingsProfile(userSettings.value, {
      pageName: 'profile',
      pageUrl: route.fullPath,
    });
    createSnackbar({
      message: '設定を更新しました',
      type: 'success',
    });
  } catch (e) {
    createSnackbar({
      message: '設定を更新できませんでした',
      type: 'error',
    });
    throw e;
  }
};
</script>
<template>
  <div class="user-settings" v-if="userInfo && userSettings">
    <Header title="プロフィール"></Header>
    <Content>
      <div class="settings-card">
        <div class="c-formBlock">
          <div class="c-formBlock__label">メールアドレス</div>
          <span class="c-formBlock__text">{{ userInfo.email }}</span>
        </div>
        <div class="c-formBlock">
          <div class="c-formBlock__label">名前</div>
          <span class="c-formBlock__text">{{ userInfo.user_name }}</span>
        </div>
        <div class="c-formBlock">
          <div class="c-formBlock__label">マークの公開範囲</div>
          <span class="c-formBlock__text"
            >自分がマークをした記事の公開範囲を指定することができます。</span
          >
          <DgrSelectbox
            class="scope-select"
            :options="scopes"
            v-model="selectedScope"
            options-max-height="240px"
          ></DgrSelectbox>
        </div>

        <div class="c-formBlock__label">所属中の組織タグ</div>
        <p class="c-formBlock__text">
          管理者のみ「組織タグ管理」から追加・編集できます
        </p>
        <OrganizationTagLabels
          class="organization-tag-list"
          :labels="userInfo.organization_tags"
          :deletable="false"
        />
        <div class="spacing-24"></div>
        <button class="c-btn c-btn--small c-btn--AnewsPrimary" @click="submit">
          保存
        </button>
      </div>
    </Content>
  </div>
</template>
<style lang="scss" scoped>
.user-settings {
  width: 100%;
  margin: -24px 0 0 0;

  .settings-card {
    box-sizing: border-box;
    width: 440px;
    padding: 16px;
    background: #fff;
    border: 1px solid $color-border;
    border-radius: $border-radius;

    .c-formBlock {
      margin-bottom: 12px;
    }

    .scope-select {
      display: block;
      width: 200px;
    }

    .c-btn {
      width: 60px;
      margin: 0;
    }

    .organization-tag-list {
      margin-top: 4px;
      min-height: 50px;
      align-items: start;
    }
  }
}
</style>
