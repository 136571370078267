<script lang="ts">
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import api from '@/api';
import { TrackingBaseData } from '@/api/tracking';
import { DgrToggleButton } from '@stockmarkteam/donguri-ui';
import UserTable from '@/components/admin/themes/user-table.vue';
import HeaderDetailContent from '@/components/common/header/header-detail-content.vue';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import AdminContent from '@/components/layouts/admin-content.vue';
import Header from '@/components/layouts/header.vue';
import AddUsersModal, {
  AddableUser,
} from '@/components/modals/add-users-modal.vue';
import ThemeTitleContent from '@/components/theme/theme-title-content.vue';
import { ThemeUser, UserInfo } from '@/types';
import {
  STATES,
  useGroups,
  useGroupUsers,
  useTeamUsersWithAddUserOptions,
  useThemeList,
  useThemeUsers,
} from '@/utils/swr';
import { getThemeHeaderDetail } from '@/utils/theme';
import { checkMoveToAdmin } from '@/utils/user';
import { useEmitter, useStore } from '@/utils/vue';

export default defineComponent({
  components: {
    Header,
    HeaderDetailContent,
    AdminContent,
    UserTable,
    AddUsersModal,
    ThemeTitleContent,
    DgrToggleButton,
  },
  setup() {
    const emitter = useEmitter();
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const { createSnackbar } = useSnackbar();

    const feedType = computed(() => store.state.feedType.feedType);
    const { data: themes } = useThemeList(feedType);
    const { data: groups } = useGroups();
    const { data: teamUserList, state: teamUsersState } =
      useTeamUsersWithAddUserOptions();
    const isOpenAddUsersModal = ref(false);
    const isOpenAddFavoriteUsersModal = ref(false);

    const isAddThemeAndFavorite = ref(true);

    const themeId = Number(route.params.themeId);

    const theme = computed(() =>
      themes.value?.themes.find(t => t.id === themeId),
    );

    const trackingData: TrackingBaseData = {
      pageName: 'theme_users_admin',
      pageUrl: route.fullPath,
    };

    const fields = [
      {
        name: 'is_favorite',
        displayName: 'お気に入り',
        type: 'boolean',
      },
      {
        name: 'role',
        displayName: '権限',
        type: 'role',
      },
    ];
    const {
      data: themeUsers,
      mutate,
      state: themeUsersState,
    } = useThemeUsers(themeId);

    onMounted(() => checkMoveToAdmin(router));

    emitter.on('theme-member-added', () => {
      mutate();
    });

    const openAddUsersModal = async () => {
      isOpenAddUsersModal.value = true;
    };

    const closeAddUsersModal = () => {
      isOpenAddUsersModal.value = false;
      isAddThemeAndFavorite.value = true;
    };

    const openAddFavoriteUsersModal = async () => {
      isOpenAddFavoriteUsersModal.value = true;
    };

    const closeAddFavoriteUsersModal = () => {
      isOpenAddFavoriteUsersModal.value = false;
    };

    const isModalLoading = computed(
      () =>
        themeUsersState.value === STATES.PENDING ||
        themeUsersState.value === STATES.VALIDATING ||
        teamUsersState.value === STATES.PENDING ||
        teamUsersState.value === STATES.VALIDATING,
    );

    const addableUserList = computed<AddableUser[]>(() => {
      const alreadyAddedUserIds =
        themeUsers.value?.theme_users.map(user => user.id) ?? [];
      return (
        teamUserList.value?.users.filter(teamUser => {
          return !alreadyAddedUserIds.includes(teamUser.id);
        }) ?? []
      );
    });

    const addableFavoriteThemeUsers = computed<AddableUser[]>(() => {
      const notFavoriteUserIds = (themeUsers.value?.theme_users ?? [])
        .filter(u => !u.is_favorite)
        .map(u => u.id);
      return (
        teamUserList.value?.users.filter(u =>
          notFavoriteUserIds.includes(u.id),
        ) ?? []
      );
    });

    const isShowAddUsersModalContractFilter = computed(() => {
      return (
        teamUserList.value?.users.some(
          user => user.managed_contract_name_by_current_user !== '',
        ) ?? false
      );
    });

    const groupId = computed(() => theme.value?.group_id ?? undefined);
    const { data: groupUsers } = useGroupUsers(groupId);
    const groupUserIds = computed(() =>
      groupUsers.value?.group_users.map(gu => gu.id),
    );

    const addUsers = async (userIds: number[]) => {
      const isAddFavorite = isAddThemeAndFavorite.value;
      const groupInviteUsers = userIds.filter(
        i => !groupUserIds.value?.includes(i),
      );
      if (
        theme.value?.access_scope === 'group' &&
        theme.value?.group_id &&
        groupInviteUsers.length > 0
      ) {
        await api.createGroupInvitation(theme.value.group_id, groupInviteUsers);
      }
      if (isAddFavorite) {
        // お気に入りAPIはメンバー追加も行うので `createThemeInvitation` を呼ばない
        await api.createThemeFavorites(themeId, userIds, trackingData);
      } else {
        await api.createThemeInvitation(themeId, userIds, trackingData);
      }
      createSnackbar({
        message: `選択したメンバーをテーマに招待しました${
          isAddFavorite ? '(お気に入り登録済み)' : ''
        }`,
        type: 'success',
      });
      mutate();
      closeAddUsersModal();
    };

    const addFavoriteUsers = async (userIds: number[]) => {
      await api.createThemeFavorites(themeId, userIds, trackingData);
      createSnackbar({
        message: '選択したメンバーのお気に入り登録が完了しました',
        type: 'success',
      });
      mutate();
      closeAddUsersModal();
    };

    const removeFavorite = async (user: UserInfo | ThemeUser) => {
      await api.deleteThemeFavorites(themeId, [user.id], trackingData);
      createSnackbar({
        message: 'お気に入りを解除しました',
        type: 'success',
      });
      mutate();
    };

    const removeMember = async (user: UserInfo | ThemeUser) => {
      await api.deleteThemeInvitation(themeId, [user.id], trackingData);
      createSnackbar({
        message: 'メンバーを解除しました',
        type: 'success',
      });
      mutate();
    };

    const userActions = [
      {
        name: 'お気に入り解除',
        func: removeFavorite,
        enabled: (user: UserInfo | ThemeUser) =>
          (user as ThemeUser).is_favorite ?? false,
      },
      {
        name: 'メンバー解除',
        func: removeMember,
        enabled: (_: UserInfo | ThemeUser) => true,
      },
    ];

    const isPersonalTheme = computed(
      () => theme.value?.access_scope === 'personal',
    );

    const detail = computed(() =>
      theme.value && groups.value?.groups && teamUserList.value?.users
        ? getThemeHeaderDetail(
            theme.value,
            groups?.value.groups,
            teamUserList.value?.users,
            true,
          )
        : { firstLine: '', secondLine: '' },
    );

    return {
      theme,
      groups,
      teamUserList,
      fields,
      themeUsers,
      openAddUsersModal,
      isOpenAddUsersModal,
      closeAddUsersModal,
      addUsers,
      addableUserList,
      isShowAddUsersModalContractFilter,
      isModalLoading,
      userActions,
      isAddThemeAndFavorite,
      isOpenAddFavoriteUsersModal,
      openAddFavoriteUsersModal,
      closeAddFavoriteUsersModal,
      addableFavoriteThemeUsers,
      addFavoriteUsers,
      isPersonalTheme,
      detail,
    };
  },
});
</script>

<template>
  <div
    class="theme-memeber-admin"
    v-if="theme && groups && teamUserList && themeUsers"
  >
    <Header
      :title="`${theme.name}`"
      :detail="detail.firstLine"
      section="テーマ管理"
      :section-link="{ name: 'themeAdmin' }"
      icon="hashtag"
      header-width="100%"
    >
      <template #titleContent>
        <ThemeTitleContent :title="`${theme.name}`" left-icon="hashtag" />
      </template>
      <template #detail-content>
        <HeaderDetailContent :is-responsive="true">
          <template #detail>
            {{ detail.firstLine }}
            <span class="settings-link">
              <router-link
                :to="{ name: 'themeEdit', params: { themeId: theme.id } }"
                >編集</router-link
              >
            </span>
          </template></HeaderDetailContent
        >
        <HeaderDetailContent
          :detail="detail.secondLine"
          :is-responsive="true"
        />
      </template>
    </Header>
    <AdminContent>
      <div class="content">
        <div class="members">
          <div class="header">
            <span
              ><span class="c-title c-title--m">メンバー</span
              ><span class="favorite-count c-text c-text--s"
                >・メンバー{{ themeUsers.theme_users.length }}（お気に入り：{{
                  themeUsers.theme_users.filter(u => u.is_favorite).length
                }}名）</span
              ></span
            >
            <div v-if="!isPersonalTheme" class="action-buttons">
              <div class="add-favorite-button">
                <button
                  class="favorite-button c-outlineBtn c-btn--auto c-text c-text--m"
                  @click="openAddFavoriteUsersModal()"
                >
                  お気に入り追加
                </button>
              </div>
              <div class="add-member-button">
                <button
                  class="m-create-button c-btn c-btn--auto c-btn--AnewsPrimary c-text c-text--m"
                  @click="openAddUsersModal()"
                >
                  メンバー追加
                </button>
              </div>
            </div>
          </div>
          <div>
            <UserTable
              class="table"
              :fields="fields"
              :data="themeUsers.theme_users"
              :actions="userActions"
            ></UserTable>
          </div>
        </div>
      </div>
    </AdminContent>
    <AddUsersModal
      :is-open="isOpenAddUsersModal"
      :joinable-users="addableUserList"
      :is-use-contract-option="isShowAddUsersModalContractFilter"
      :is-loading="isModalLoading"
      @on-close="closeAddUsersModal"
      @on-submit="addUsers"
    >
      <template #toggle>
        <DgrToggleButton
          v-model="isAddThemeAndFavorite"
          class="c-text c-text--s"
        >
          お気に入り登録する
        </DgrToggleButton>
      </template>
    </AddUsersModal>
    <AddUsersModal
      title="お気に入り登録するメンバーを追加"
      subtitle="お気に入り登録していないメンバー"
      no-users-message="お気に入り登録できるメンバーはいません。"
      :disable-filters="true"
      :is-open="isOpenAddFavoriteUsersModal"
      :joinable-users="addableFavoriteThemeUsers"
      :is-loading="isModalLoading"
      @on-close="closeAddFavoriteUsersModal"
      @on-submit="addFavoriteUsers"
    />
  </div>
</template>

<style lang="scss" scoped>
.theme-memeber-admin {
  width: 100%;
  margin: -24px 0 0 0;
}
.settings-link {
  a {
    margin-left: 0.5em;
    color: #1da482;
    &:hover {
      border-bottom: solid 1px;
    }
  }
}
.content {
  display: flex;
  justify-content: center;

  .action-buttons {
    display: flex;
    gap: 16px;
  }

  .favorite-button {
    background: #fff;
    border-color: #1da482;
    color: #1da482;
  }

  .members {
    width: 100%;
    display: flex;
    flex-direction: column;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;

      .favorite-count {
        color: #b3b3b3;
      }
    }

    .table {
      width: 100%;
    }
  }
}
</style>
