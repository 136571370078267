import { PageName } from '@/api/tracking';
import { DOC_TYPE_LABELS } from '@/constants';
import { DocType, SearchScope } from '@/types';

// 要約v1で使用する、検索対象の絞り込み項目
export const TARGET_SOURCES = (
  enableTechnicalLiterature: boolean,
  enableUserDocument: boolean,
) =>
  [
    {
      section: '公開情報',
      options: [
        {
          label: 'ニュース',
          value: 'article',
        },
        ...(enableTechnicalLiterature
          ? [
              {
                label: '論文',
                value: 'research_paper',
              },
              {
                label: '特許',
                value: 'patent',
              },
            ]
          : []),
        {
          label: 'レポート',
          value: 'report',
        },
      ],
    },
    ...(enableUserDocument
      ? [
          {
            section: '社内情報',
            options: [
              {
                label: 'すべて',
                value: 'user_document',
              },
            ],
          },
        ]
      : []),
  ] as Array<{
    section: string;
    options: Array<{
      label: (typeof DOC_TYPE_LABELS)[keyof typeof DOC_TYPE_LABELS];
      value: keyof typeof DOC_TYPE_LABELS;
    }>;
  }>;

// TARGET_SOURCESのoptionsの全てのvalue
export const ALL_DOC_TYPES = (
  enableTechnicalLiterature: boolean,
  enableUserDocument: boolean,
) =>
  TARGET_SOURCES(enableTechnicalLiterature, enableUserDocument).flatMap(
    source => source.options.map(option => option.value),
  ) as Array<DocType | 'user_document'>;

// マークした情報の要約で使用する、検索対象の絞り込み項目
export const TARGET_SOURCE_LABELS = (
  searchScope: SearchScope,
  enableTechnicalLiterature: boolean,
  enableUserDocument: boolean,
) =>
  [
    {
      label: 'ニュース',
      value: 'article',
    },
    ...(enableTechnicalLiterature
      ? [
          {
            label: '論文',
            value: 'research_paper',
          },
          {
            label: '特許',
            value: 'patent',
          },
        ]
      : []),
    ...(searchScope === 'all'
      ? [
          {
            label: 'レポート',
            value: 'report',
          },
        ]
      : []),
    ...(enableUserDocument && searchScope === 'all'
      ? [
          {
            label: '社内情報',
            value: 'user_document',
          },
        ]
      : []),
  ] as Array<{
    label: (typeof DOC_TYPE_LABELS)[keyof typeof DOC_TYPE_LABELS];
    value: keyof typeof DOC_TYPE_LABELS;
  }>;

export const DOC_TYPES_FOR_SCOPE = (
  searchScope: SearchScope,
  enableTechnicalLiterature: boolean,
  enableUserDocument: boolean,
) =>
  TARGET_SOURCE_LABELS(
    searchScope,
    enableTechnicalLiterature,
    enableUserDocument,
  ).map(option => option.value) as Array<DocType | 'user_document'>;

// surveyのstreamingデータのtype
export const STREAMING_DATA_TYPES = {
  TOKEN: 'token', // 要約本文
  SOURCE: 'source', // 要約に使用したソース (検索にヒットしたドキュメント)
  NO_HIT_SOURCES: 'no_hit_sources', // 検索で1件もドキュメントがヒットしなかった時に返ってくる
  SOURCES_DONE: 'sources_done', // 要約に使用したソースが全て返却し終わったら返ってくる
  ERROR: 'error', // モデルが要約を生成する過程のどこかでエラーが発生した時に返ってくる
  SURVEY_DONE: 'survey_done', // surveyのAPIが完了した時に返ってくる
} as const;

// 計測で使用するsurveyの機能やページ名
export const SURVEY_PAGE_NAME: Extract<PageName, 'survey'> = 'survey' as const;

export const SURVEY_ERROR_MESSAGE =
  '申し訳ありませんが、現在こちらの回答を生成することができません。後ほどお試しください。';

export const SURVEY_NO_MARKS_ERROR_MESSAGE =
  'マークされた情報が存在しないため回答を生成できませんでした。記事にマークしてから再度お試しください。';
