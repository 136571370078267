<script lang="ts">
import { computed, defineComponent } from 'vue';
import noImage1to1 from '@/assets/noimage-1to1.jpg';

const defaultSize = 'l';

export default defineComponent({
  props: {
    imageUrl: String,
    size: {
      type: String,
      default: defaultSize,
    },
  },
  components: {},
  setup(props) {
    const imageSrc = computed(() => props.imageUrl ?? noImage1to1);
    const allowedSizes = ['xs', 's', 'm', 'l', 'xl'];
    const sizeClass = computed(
      () =>
        `avatar-size--${
          allowedSizes.includes(props.size) ? props.size : defaultSize
        }`,
    );

    return {
      imageSrc,
      sizeClass,
    };
  },
});
</script>

<template>
  <div class="avatar">
    <img class="avatar-image" :src="imageSrc" :class="sizeClass" />
  </div>
</template>

<style lang="scss" scoped>
.avatar {
  .avatar-image {
    object-fit: cover;
    border-radius: 50%;
    border: 1px solid #e6e6e6;
  }
  .avatar-size--xl {
    width: 72px;
    height: 72px;
  }
  .avatar-size--l {
    width: 48px;
    height: 48px;
  }
  .avatar-size--m {
    width: 36px;
    height: 36px;
  }
  .avatar-size--s {
    width: 32px;
    height: 32px;
  }
  .avatar-size--xs {
    width: 24px;
    height: 24px;
  }
}
</style>
