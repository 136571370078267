import { reactive } from 'vue';
import { DocType, FeedType, Theme } from '@/types';

type Option = {
  disabled: boolean;
  name: string;
  label: string;
};

export const useThemeDisabledOption = ({
  theme,
  themeFeedType,
  docType,
}: {
  theme: Theme;
  themeFeedType: FeedType;
  docType: DocType;
}): {
  disabledOption: Option;
} => {
  const options = reactive<Record<string, Option>>({
    researchPaper: {
      disabled: false,
      name: '論文',
      label: '表示する技術文献の「論文」',
    },
    patent: {
      disabled: false,
      name: '特許',
      label: '表示する技術文献の「特許」',
    },
    foreignFeed: {
      disabled: false,
      name: '海外メディア',
      label: '「海外メディアの情報を収集する」',
    },
  });

  if (docType === 'research_paper') {
    options.researchPaper.disabled = !theme.is_research_paper_enabled;
  }
  if (docType === 'patent') {
    options.patent.disabled = !theme.is_patent_enabled;
  }
  if (themeFeedType === 'foreign') {
    options.foreignFeed.disabled = !theme.is_foreign_media_enabled;
  }

  const disabledOptionKey = Object.keys(options).filter(
    key => options[key].disabled,
  )[0];
  const disabledOption = options[disabledOptionKey];

  return {
    disabledOption,
  };
};
