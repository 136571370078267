<script lang="ts">
import { defineComponent } from 'vue';
import { DgrIcon } from '@stockmarkteam/donguri-ui';

export default defineComponent({
  components: {
    DgrIcon,
  },
  emits: ['hideSupportContent'],
  setup() {
    return {};
  },
});
</script>

<template>
  <div class="o-theme-edit-support">
    <div class="times-icon-row" @click="$emit('hideSupportContent')">
      <DgrIcon size="small" name="times" class="times-icon" />
    </div>
    <div class="o-theme-edit-support-detail">
      <div>
        <span class="c-title c-title--xm"
          >はじめてテーマを作成する方におすすめ</span
        ><br />
        <div class="o-create-theme-movie c-text c-text--m">
          <a
            href="https://anews-stockmark.zendesk.com/hc/ja/articles/4413707816857"
            target="_blank"
            rel="noopener noreferrer"
            >テーマ作成の動画をみる</a
          ><DgrIcon
            size="small"
            name="external-link"
            :keep-fill="false"
            class="primary"
          />
        </div>
      </div>
      <img src="@/assets/icon-beginner.png" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.o-theme-edit-support {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 12px 12px 16px 16px;
  margin-bottom: 16px;

  .times-icon-row {
    display: flex;
    justify-content: end;
    margin-bottom: 4px;

    .times-icon {
      padding: 4px;
      border-radius: 4px;
    }
    :hover {
      background: $color-gray400;
    }
  }

  .o-theme-edit-support-detail {
    display: flex;
    justify-content: space-between;
  }

  .o-create-theme-movie {
    margin-top: 8px;

    display: flex;
    align-items: center;

    a {
      color: $color-green600;
      &:hover {
        text-decoration: underline;
      }
    }
    fill: $color-green600;
  }
}
</style>
