// マイページのルート名
export const MYPAGE_ROUTE_NAMES = {
  profile: 'mypageProfile',
  marks: 'mypageMarks',
  views: 'mypageViews',
  comments: 'mypageComments',
  export: 'mypageExport',
  myTags: 'mypageMyTags',
} as const;

// ユーザーページのルート名
const USER_ROUTE_NAMES = {
  profile: 'userProfile',
  marks: 'userMarks',
  views: 'userViews',
  comments: 'userComments',
  export: 'userExport',
} as const;

// マイページのmeta
export const MYPAGE_ROUTE_META = {
  title: 'マイページ',
  type: 'mypage',
} as const;

// ユーザーページ->マイページへのリダイレクト用name対応map
export const NAMES_FOR_REDIRECT_FROM_USER_TO_MYPAGE = {
  [USER_ROUTE_NAMES.profile]: MYPAGE_ROUTE_NAMES.profile,
  [USER_ROUTE_NAMES.marks]: MYPAGE_ROUTE_NAMES.marks,
  [USER_ROUTE_NAMES.views]: MYPAGE_ROUTE_NAMES.views,
  [USER_ROUTE_NAMES.comments]: MYPAGE_ROUTE_NAMES.comments,
  [USER_ROUTE_NAMES.export]: MYPAGE_ROUTE_NAMES.export,
};
