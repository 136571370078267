<script setup lang="ts">
import { ref } from 'vue';
import api from '@/api';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';

interface Props {
  isOpen: boolean;
  myTagId: number;
  myTagName: string;
}
const props = defineProps<Props>();
const emit = defineEmits<{
  close: [];
  submit: [
    {
      myTagId: number;
    },
  ];
}>();
const close = () => {
  emit('close');
};
const { createSnackbar } = useSnackbar();
const isLoading = ref(false);

const submit = async () => {
  isLoading.value = true;
  try {
    await api.deleteMyTag(props.myTagId);
    createSnackbar({
      message: 'マイタグを削除しました',
      type: 'success',
    });
    emit('submit', { myTagId: props.myTagId });
  } catch (e) {
    createSnackbar({
      message: 'マイタグの削除に失敗しました',
      type: 'error',
    });
    throw e;
  } finally {
    isLoading.value = false;
  }
};
</script>

<template>
  <Teleport to="body">
    <sm-dialog
      v-if="isOpen"
      class="no-padding screen-center auto-height"
      :show-header="true"
      @close="close"
    >
      <template #header>
        <p>マイタグを削除</p>
      </template>
      <template #body>
        <div class="modal-body">
          <div class="modal-content">
            <p class="c-text c-text--m">{{ myTagName }}を削除します。</p>
          </div>
          <div class="modal-buttons-container c-text c-text--m">
            <button
              type="button"
              class="o-cancel-button c-btn c-btn--auto c-btnOutline"
              @click="close"
            >
              キャンセル
            </button>
            <button
              type="button"
              class="c-btn c-btn--auto c-btn--negative"
              @click="submit"
              :disabled="isLoading"
              :class="{ disabled: isLoading }"
            >
              削除
            </button>
          </div>
        </div>
      </template>
    </sm-dialog>
  </Teleport>
</template>

<style lang="scss" scoped>
.modal-body {
  padding: 16px;
  input {
    outline: none;
  }
  .modal-content {
    margin-bottom: 25px;
  }
  .modal-buttons-container {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
}
</style>
