import { Module } from 'vuex';
import {
  ContentsContext,
  Feature,
  IndustryData,
  PageName,
  SearchData,
} from '@/api/tracking';
import {
  AdpDocument,
  Article,
  ArticleMarkSource,
  Comment,
  FeedType,
  Group,
  Theme,
} from '@/types';

type ModalType =
  | 'themeCreate'
  | 'articleShare'
  | 'groupCreate'
  | 'commentCreate'
  | 'commentCreateWithoutArticle'
  | 'commentEdit'
  | 'themeSettingsInfo'
  | 'groupMark'
  | 'translateNotification'
  | 'mark'
  | 'themeTopicSelect'
  | 'tagListModal';

export type ModalState = {
  modalOpen: boolean;
  modalType?: ModalType;
  article?: AdpDocument;
  theme?: Theme;
  comment?: Comment;
  pageName?: PageName;
  feature?: Feature;
  feedType?: FeedType;
  markSource?: ArticleMarkSource;
  rankInWholeFeed?: number;
  group?: Group;
  isSharing: boolean;
  searchData?: SearchData;
  industryData?: IndustryData;
  description?: string;
  withoutArticleComment?: Comment;
  contentsContext?: ContentsContext;
  execution?: string;
};

export const initialModalState: ModalState = {
  modalType: undefined,
  modalOpen: false,
  article: undefined,
  theme: undefined,
  comment: undefined,
  pageName: undefined,
  feature: undefined,
  feedType: undefined,
  markSource: undefined,
  rankInWholeFeed: undefined,
  group: undefined,
  isSharing: false,
  searchData: undefined,
  industryData: undefined,
  description: '',
  withoutArticleComment: undefined,
  contentsContext: undefined,
  execution: undefined,
};

const modal: Module<ModalState, unknown> = {
  namespaced: true,
  state: {
    ...initialModalState,
  },
  mutations: {
    showModal(state: ModalState, modalType: ModalType): void {
      state.modalType = modalType;
      state.modalOpen = true;
    },
    setArticle(state: ModalState, article: Article): void {
      state.article = article;
    },
    setTheme(state: ModalState, theme: Theme): void {
      state.theme = theme;
    },
    setComment(state: ModalState, comment: Comment): void {
      state.comment = comment;
    },
    setPageName(state: ModalState, pageName: PageName): void {
      state.pageName = pageName;
    },
    setFeature(state: ModalState, feature: Feature): void {
      state.feature = feature;
    },
    setFeedType(state: ModalState, feedType: FeedType): void {
      state.feedType = feedType;
    },
    setMarkSource(state: ModalState, markSource: ArticleMarkSource): void {
      state.markSource = markSource;
    },
    setRankInWholeFeed(state: ModalState, rank: number): void {
      state.rankInWholeFeed = rank;
    },
    setGroup(state: ModalState, group: Group): void {
      state.group = group;
    },
    setIsSharing(state: ModalState, isSharing: boolean) {
      state.isSharing = isSharing;
    },
    setSearchData(state: ModalState, searchData: SearchData) {
      state.searchData = searchData;
    },
    setIndustryData(state: ModalState, industryData: IndustryData) {
      state.industryData = industryData;
    },
    setDescription(state: ModalState, description: string) {
      state.description = description;
    },
    setContentsContext(state: ModalState, contentsContext: ContentsContext) {
      state.contentsContext = contentsContext;
    },
    setExecution(state: ModalState, execution: string) {
      state.execution = execution;
    },
    hideModal(state: ModalState): void {
      state.modalType = undefined;
      state.modalOpen = false;
      state = { ...initialModalState };
    },
    setWithoutArticleComment(state: ModalState, comment: Comment): void {
      state.withoutArticleComment = comment;
    },
  },
};

export default modal;
