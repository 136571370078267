import { Ref } from 'vue';
import dayjs, { Dayjs } from 'dayjs';
import {
  Execution,
  ExecutionInformation,
  FeedType,
  MediaCounts,
  MultipleExecutionMediaCounts,
  PersonalNewsCounts,
  TeamInfo,
  UserInfo,
} from '@/types';

export const PERSONAL_NEWS_MORNING_UPDATE_HOUR = 7;
export const PERSONAL_NEWS_AFTERNOON_UPDATE_HOUR = 13;

export const MULTI_EXECUTION_MAX_ARTICLE_COUNT = 50;
export const ALL_MEDIA_INITIAL_VISIBLE_ARTICLE_COUNT = 10;
export const HOME_VISIBLE_ARTICLE_COUNT = 5;
export const HOME_ALL_MEDIA_VISIBLE_ARTICLE_COUNT = 3;

const now = () => dayjs();

export const latestPersonalFeedDatetime = (
  sentFirstPersonalNewsFeedAt?: Dayjs,
) => {
  if (
    sentFirstPersonalNewsFeedAt &&
    now().isSame(sentFirstPersonalNewsFeedAt, 'day')
  ) {
    return sentFirstPersonalNewsFeedAt;
  }
  return now().hour() >= PERSONAL_NEWS_MORNING_UPDATE_HOUR
    ? now()
    : now().subtract(1, 'day').endOf('day');
};

export const isAfternoonFeedEnabled = (date: Dayjs) =>
  now().isAfter(date, 'day') ||
  now().hour() >= PERSONAL_NEWS_AFTERNOON_UPDATE_HOUR;

export const executionInformationForDate = (
  date: Dayjs,
  execution: Execution,
  sentFirstPersonalNewsFeedAt?: Dayjs,
): ExecutionInformation => {
  const isFirstExecutionDay =
    sentFirstPersonalNewsFeedAt !== undefined &&
    date.isSame(sentFirstPersonalNewsFeedAt, 'day');
  const isFirstExecution =
    isFirstExecutionDay &&
    ((execution === 'morning' &&
      sentFirstPersonalNewsFeedAt.hour() <
        PERSONAL_NEWS_AFTERNOON_UPDATE_HOUR) ||
      (execution === 'afternoon' &&
        sentFirstPersonalNewsFeedAt.hour() >=
          PERSONAL_NEWS_AFTERNOON_UPDATE_HOUR));
  return {
    isFirstExecution,
    execution,
    executionTime: isFirstExecution
      ? sentFirstPersonalNewsFeedAt.format('H:mm')
      : getExecutionTime(execution),
  };
};

export const getMorningFeedData = (
  feedLastUpdatedAt: Dayjs,
  sentFirstPersonalNewsFeedAt?: Dayjs,
): { date: Dayjs; execution: Execution } => {
  // 初パーソナルニュースは0~7時の場合、前日の2回目のフィードとして作れるのでそれを取得するために以下の判定
  // メモ：
  // - 当日として表示したいので `executionInfoMorning` は `feedLastUpdatedAt` のままを使う
  if (
    sentFirstPersonalNewsFeedAt &&
    sentFirstPersonalNewsFeedAt.isSame(now(), 'day') &&
    sentFirstPersonalNewsFeedAt?.hour() < PERSONAL_NEWS_MORNING_UPDATE_HOUR
  ) {
    return {
      date: sentFirstPersonalNewsFeedAt.endOf('day').subtract(1, 'day'),
      execution: 'afternoon',
    };
  } else {
    return {
      date: feedLastUpdatedAt,
      execution: 'morning',
    };
  }
};

const getExecutionTime = (execution: Execution) =>
  execution === 'morning'
    ? `0${PERSONAL_NEWS_MORNING_UPDATE_HOUR}:00`
    : `${PERSONAL_NEWS_AFTERNOON_UPDATE_HOUR}:00`;

export type Tab = {
  name: string;
  count?: number;
  linkTo: { name: string };
  disabled?: boolean;
  hasNotification?: boolean;
};

const getCount = (
  counts: MediaCounts,
  feedType: FeedType,
  countAllMedia: boolean,
) => {
  return countAllMedia
    ? counts.domestic + counts.foreign
    : counts[feedType as 'domestic' | 'foreign'];
};

const defaultCounts = {
  domestic: 0,
  foreign: 0,
};
const countArticles = (
  personalNewsCounts: PersonalNewsCounts,
  feedType: FeedType,
  countAllMedia: boolean,
) => {
  const newsCounts = personalNewsCounts.articles;

  const cs = newsCounts as MultipleExecutionMediaCounts;
  const useAfternoonCount = isAfternoonFeedEnabled(now());
  return (
    getCount(cs.morning as MediaCounts, feedType, countAllMedia) +
    (useAfternoonCount
      ? getCount(cs.afternoon as MediaCounts, feedType, countAllMedia)
      : 0)
  );
};

const getFeedTabCounts = (
  feedType: FeedType,
  userInfo: UserInfo,
  personalNewsCounts: PersonalNewsCounts | undefined,
  team: TeamInfo | undefined,
) => {
  const counts = {
    news: undefined as number | undefined,
    research_papers: undefined as number | undefined,
    patents: undefined as number | undefined,
  };
  if (userInfo.has_personal_news && personalNewsCounts) {
    const countAllMedia =
      feedType === 'all' && (team?.enable_foreign_lang_media ?? false);

    counts.news = countArticles(personalNewsCounts, feedType, countAllMedia);

    const researchPaperCounts = personalNewsCounts.research_papers ?? {
      ...defaultCounts,
    };
    counts.research_papers = getCount(
      researchPaperCounts,
      feedType,
      countAllMedia,
    );

    const patentCount =
      feedType === 'foreign' ? 0 : personalNewsCounts.patents.domestic;
    counts.patents = patentCount;
  }
  return counts;
};

export const getTabs = (
  feedType: Ref<FeedType>,
  team: Ref<TeamInfo | undefined>,
  userInfo: Ref<UserInfo | undefined>,
  personalNewsCounts: Ref<PersonalNewsCounts | undefined>,
): Tab[] | undefined => {
  const enableTechnicalLiterature = team.value?.enable_technical_literature;
  if (enableTechnicalLiterature && userInfo.value?.has_personal_news) {
    const tabCounts = getFeedTabCounts(
      feedType.value,
      userInfo.value,
      personalNewsCounts.value,
      team.value,
    );

    return [
      {
        name: 'ニュース',
        count: tabCounts.news,
        linkTo: { name: 'personalNews' },
      },
      {
        name: '論文',
        count: tabCounts.research_papers,
        linkTo: { name: 'personalResearchPapers' },
      },
      {
        name: '特許',
        count: tabCounts.patents,
        linkTo: { name: 'personalPatents' },
      },
    ];
  } else {
    return undefined;
  }
};
