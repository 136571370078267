import { computed, ComputedRef, Ref } from 'vue';
import { AdpDocument } from '@/types';
import {
  filterPestNewsCategories,
  groupNewsCategoriesByPest,
  NewsCategoriesByPest,
} from '@/utils/news_category';

export const useNewsCategories = ({
  adpDocument,
}: {
  adpDocument:
    | Ref<AdpDocument | undefined>
    | ComputedRef<AdpDocument | undefined>;
}): {
  newsCategoriesByPest: ComputedRef<NewsCategoriesByPest | null>;
  pestCategories: ComputedRef<string[] | null>;
} => {
  const newsCategoriesByPest = computed(() => {
    if (
      !adpDocument.value ||
      adpDocument.value.doc_type !== 'article' ||
      adpDocument.value.news_categories === null
    ) {
      return null;
    }

    return groupNewsCategoriesByPest(adpDocument.value.news_categories);
  });

  const pestCategories = computed(() => {
    if (
      !adpDocument.value ||
      adpDocument.value.doc_type !== 'article' ||
      adpDocument.value.news_categories === null
    ) {
      return null;
    }

    return filterPestNewsCategories(adpDocument.value.news_categories);
  });

  return {
    newsCategoriesByPest,
    pestCategories,
  };
};
