<script lang="ts" setup>
import { computed, ref } from 'vue';
import Draggable from 'vuedraggable';
import { MAX_ORGANIZATION_TAG_NAME_LENGTH } from '@/constants';
import { DgrCheckbox, DgrIcon } from '@stockmarkteam/donguri-ui';
import {
  checkIsBlank,
  checkIsTooLongOrganizationTagName,
  validateMessages,
} from '@/utils/validators';
import type { FormData, FormDataItem } from './types';
import { checkIsDuplicatedName, checkIsValidTagName } from './utils';

const props = defineProps<{
  formData: FormData;
  sortedFormItemList: FormDataItem[];
}>();

const emit = defineEmits<{
  onUpdateCheckbox: [
    {
      tagIds: Array<keyof FormData>;
      newIsDeleted: boolean;
    },
  ];
  onDragFormItem: [{ oldIndex: number; newIndex: number }];
}>();

const form = computed(() => props.formData);
const formItemList = computed(() => props.sortedFormItemList);
const hasCheckedDeleteAllTagCheckbox = computed({
  get: () => Object.values(form.value).every(tag => tag.isDeleted),
  set: newIsDeleted => {
    const tagIds = Object.keys(form.value).map(id => Number(id));

    emit('onUpdateCheckbox', {
      tagIds,
      newIsDeleted,
    });
  },
});
const isEditingFormListId = ref<number | null>(null);
const startEditing = (formItemId: number) => {
  isEditingFormListId.value = formItemId;
};
const finishEditing = () => {
  isEditingFormListId.value = null;
};

const toggleDeleteTagCheckbox = (formItem: FormDataItem) => {
  emit('onUpdateCheckbox', {
    tagIds: [formItem.id],
    newIsDeleted: !formItem.isDeleted,
  });
};
const handleDragFormItem = ({
  oldIndex,
  newIndex,
}: {
  oldIndex: number;
  newIndex: number;
}) => {
  emit('onDragFormItem', {
    oldIndex,
    newIndex,
  });
};
</script>
<template>
  <div class="page-wrapper">
    <div class="table-title">
      <span class="c-text--m">組織タグ</span>
      <span class="tag-count" data-testid="tag-count">
        ・{{ formItemList.length }}件
      </span>
    </div>

    <div class="table-container">
      <div class="table-inner">
        <table class="table">
          <thead>
            <tr class="table-header-row">
              <th class="table-header table-header--all-tag-delete-checkbox">
                <div class="container">
                  <span class="c-text--xs">削除する</span>
                  <DgrCheckbox
                    v-model="hasCheckedDeleteAllTagCheckbox"
                    data-testid="all-tag-delete-checkbox"
                  ></DgrCheckbox>
                </div>
              </th>

              <th class="table-header table-header--tag-name">
                <span class="c-text--s"> 組織タグ名 </span>
              </th>

              <th class="table-header table-header--member-count">
                <span class="c-text--s">メンバー数</span>
              </th>
            </tr>
          </thead>

          <Draggable
            :list="[...formItemList]"
            handle=".drag-icon"
            item-key="id"
            tag="tbody"
            ghost-class="ghost"
            animation="150"
            @end="handleDragFormItem"
          >
            <template
              #item="{
                element: formItem,
                index,
              }: {
                element: FormDataItem;
                index: number;
              }"
            >
              <tr
                class="table-body-row"
                :class="{ 'table-body-row--first': index === 0 }"
              >
                <td
                  class="table-body table-body--delete-checkbox"
                  :class="{
                    isEditing: isEditingFormListId === formItem.id,
                  }"
                >
                  <div class="container">
                    <DgrIcon name="grip-vertical" class="drag-icon" />
                    <DgrCheckbox
                      :model-value="formItem.isDeleted"
                      @update:model-value="toggleDeleteTagCheckbox(formItem)"
                      data-testid="tag-delete-checkbox"
                    ></DgrCheckbox>
                  </div>
                </td>

                <td class="table-body table-body--tag-name">
                  <textarea
                    autocomplete="off"
                    v-model="formItem.name"
                    :placeholder="
                      formItem.isNewTag
                        ? '部署、プロジェクト、チームなど'
                        : formItem.previousName
                    "
                    class="c-textArea text-area"
                    :class="{
                      'c-formTextarea--error':
                        !formItem.isDeleted &&
                        !checkIsValidTagName({
                          id: formItem.id,
                          formData: form,
                        }),
                    }"
                    :disabled="formItem.isDeleted"
                    @focus="startEditing(formItem.id)"
                    @blur="finishEditing"
                    rows="1"
                  />
                  <div
                    v-if="isEditingFormListId === formItem.id"
                    class="updated-tag-name-counter-container"
                    data-testid="updated-tag-name-counter-container"
                  >
                    <span
                      v-if="checkIsBlank(formItem.name)"
                      class="updated-tag-name-counter updated-tag-name-counter__error"
                      data-testid="updated-tag-empty-name-error"
                      >{{ validateMessages.organizationTagBlank }}</span
                    >
                    <span
                      v-else-if="
                        checkIsTooLongOrganizationTagName(formItem.name)
                      "
                      class="updated-tag-name-counter updated-tag-name-counter__error"
                      data-testid="updated-tag-name-too-long-error"
                      >{{ validateMessages.organizationTagLong }}</span
                    >
                    <span
                      v-else-if="
                        checkIsDuplicatedName({
                          id: formItem.id,
                          formData: form,
                        })
                      "
                      class="updated-tag-name-counter updated-tag-name-counter__error"
                      data-testid="updated-tag-name-duplicated-error"
                    >
                      {{ validateMessages.organizationTagDuplicated }}
                    </span>
                    <span
                      class="updated-tag-name-counter"
                      :class="{
                        'updated-tag-name-counter__error': !checkIsValidTagName(
                          {
                            id: formItem.id,
                            formData: form,
                          },
                        ),
                      }"
                      data-testid="updated-tag-name-counter"
                      >{{
                        `${formItem.name.length}/${MAX_ORGANIZATION_TAG_NAME_LENGTH}`
                      }}</span
                    >
                  </div>
                </td>

                <td
                  class="table-body table-body--member-count"
                  :class="{
                    isEditing: isEditingFormListId === formItem.id,
                  }"
                >
                  <span class="c-text--m">{{ formItem.memberCount }}</span>
                </td>
              </tr>
            </template>
          </Draggable>
        </table>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.page-wrapper {
  width: 606px;
  height: 100%;
}
.table-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 16px;
}
.tag-count {
  font-size: 12px;
  font-weight: normal;
  color: $color-gray600;
}
.table-container {
  height: 100%;
  padding-bottom: 16px;
}
.table-inner {
  border-radius: $border-radius;
  background-color: #fff;
  border: 1px solid $color-border;
  max-height: 100%;
  overflow: auto;
}
.table {
  width: 100%;
  border-collapse: collapse;
  tr th {
    white-space: nowrap;
  }
  thead {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 1;
  }
}
.table-header-row {
  width: calc(100% - 32px);
  margin: 0 16px;
  padding: 16px 0;
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
  border-bottom: 1px solid $color-border;
}
.table-header {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  &--all-tag-delete-checkbox {
    width: 68px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding-right: 7px;
    & > .container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  &--tag-name {
    width: 416px;
    padding-top: 10px;
    padding-left: 12px;
    justify-content: flex-start;
  }
  &--member-count {
    flex: 1;
    justify-content: flex-end;
    padding-top: 10px;
    padding-right: 8px;
  }
}
.table-body-row {
  width: calc(100% - 32px);
  margin: 0 16px;
  padding-bottom: 12px;
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
  &--first {
    padding-top: 12px;
  }
  &.ghost {
    visibility: hidden;
  }
}
.table-body {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  &--delete-checkbox {
    width: 68px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding-right: 19px;
    & > .container {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      & > .drag-icon {
        cursor: move;
        fill: #e6e6e6;
      }
    }
    &.isEditing {
      padding-bottom: 15px;
    }
  }
  &--tag-name {
    width: 416px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .c-textInput {
      outline: none;
    }
    .updated-tag-name-counter {
      font-size: 10px;
      color: $color-gray600;
      &__error {
        color: $color-orange1000;
      }
    }
    .updated-tag-name-counter-container {
      display: flex;
      justify-content: flex-end;
      gap: 4px;
    }
  }
  &--member-count {
    flex: 1;
    justify-content: flex-end;
    &.isEditing {
      padding-bottom: 15px;
    }
  }
}
.text-area {
  resize: vertical;
  width: 100%;
}
</style>
