<script lang="ts">
import { defineComponent, nextTick, onUnmounted, ref } from 'vue';
import { DgrIcon } from '@stockmarkteam/donguri-ui';

export default defineComponent({
  components: {
    DgrIcon,
  },
  setup(_, context) {
    const actionMenuRef = ref<HTMLDivElement>();
    const actionMenuPopupRef = ref<HTMLDivElement>();
    const isOpen = ref(false);
    const preventMouseWheelEvent = (event: WheelEvent) => {
      event.preventDefault();
    };

    const setPosition = (
      actionMenuPopupElem: HTMLDivElement,
      actionMenuRect: DOMRect,
      actionMenuPopupRect: DOMRect,
    ) => {
      const heightBetweenWindowBottomAndActionMenuBottom =
        window.innerHeight - actionMenuRect.bottom;
      const heightUsedByActionMenuPopup =
        actionMenuPopupRect.height + actionMenuRect.height;
      const widthBeteweenWindowRightAndActionMenuRight =
        window.innerWidth - actionMenuRect.right;
      if (
        heightUsedByActionMenuPopup >=
        heightBetweenWindowBottomAndActionMenuBottom
      ) {
        // ポップアップメニューが下にはみ出す場合
        actionMenuPopupElem.style.top = `${
          actionMenuRect.top - actionMenuPopupRect.height
        }px`;
      } else {
        actionMenuPopupElem.style.top = `${
          actionMenuRect.top + actionMenuRect.height
        }px`;
      }
      if (
        actionMenuPopupRect.width >= widthBeteweenWindowRightAndActionMenuRight
      ) {
        // ポップアップメニューが右にはみ出す場合
        actionMenuPopupElem.style.right = `${
          window.innerWidth - actionMenuRect.right - 40
        }px`;
      } else {
        actionMenuPopupElem.style.left = `${actionMenuRect.left}px`;
      }
    };

    onUnmounted(() => {
      document.removeEventListener('click', closeEventHandler, {
        capture: true,
      });
      document.removeEventListener('wheel', preventMouseWheelEvent);
    });

    const clickMenu = (e: Event) => {
      if (isOpen.value) {
        closeMenu(e);
        return;
      }
      document.addEventListener('click', closeEventHandler);
      isOpen.value = true;
      context.emit('clickMenu', e); // eslint-disable-line vue/require-explicit-emits
      document.addEventListener('wheel', preventMouseWheelEvent, {
        passive: false,
      });
      // ポップアップメニューがDOMに追加された後に位置を調整
      nextTick(() => {
        const actionMenuRect = actionMenuRef.value?.getBoundingClientRect();
        const actionMenuPopupRect =
          actionMenuPopupRef.value?.getBoundingClientRect();
        if (actionMenuRect && actionMenuPopupRect && actionMenuPopupRef.value)
          setPosition(
            actionMenuPopupRef.value,
            actionMenuRect,
            actionMenuPopupRect,
          );
      });
    };

    const closeEventHandler = (e: Event) => {
      if (!isOpen.value || !actionMenuRef.value) {
        return;
      }
      const eventTargets = e?.composedPath() ?? [];
      if (eventTargets.includes(actionMenuRef.value)) {
        return;
      }
      closeMenu(e);
    };

    const closeMenu = (e: Event) => {
      document.removeEventListener('click', closeEventHandler);
      document.removeEventListener('wheel', preventMouseWheelEvent);
      isOpen.value = false;
      context.emit('closeMenu', e); // eslint-disable-line vue/require-explicit-emits
    };

    return {
      actionMenuRef,
      actionMenuPopupRef,
      clickMenu,
      closeMenu,
      isOpen,
    };
  },
});
</script>

<template>
  <div class="m-action-menu">
    <button
      ref="actionMenuRef"
      class="action-menu-button"
      :class="{ opened: isOpen }"
      aria-label="ポップアップメニューを開く"
      data-testid="member-table-action-menu"
      @click="clickMenu"
      @keydown.esc="closeMenu"
    >
      <DgrIcon name="ellipsis-h" />
      <div ref="actionMenuPopupRef" class="action-menu-popup" v-if="isOpen">
        <slot />
      </div>
    </button>
  </div>
</template>

<style lang="scss" scoped>
.m-action-menu {
  position: relative;
}

.action-menu-button {
  display: flex;
  justify-content: center;
  border: none;
  background-color: inherit;
  padding: 8px;
  border-radius: 4px;
  &:hover {
    background-color: $color-gray200;
  }
  &.opened {
    background-color: $color-gray200;
  }
}

.action-menu-popup {
  position: fixed;
  z-index: var(--z-action-menu);
}
</style>
