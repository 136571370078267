<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import api from '@/api';
import { TrackingAutocompleteData } from '@/api/tracking';
import { KEYWORD_COMPLETION_DEBOUNCE_TIME } from '@/constants';
import { DgrIcon } from '@stockmarkteam/donguri-ui';
import { CompletionItem } from '@/types';
import { DebounceRef } from '@/utils/debounce';
import { escapeRegExpSpecialCharacters } from '@/utils/regexp';
import { useKeywordCompletion } from '@/utils/swr';

const ITEM_HEIGHT = 40;

interface Props {
  modelValue?: string;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: '',
});

const emit = defineEmits<{
  'update:modelValue': [_value: string];
  select: [_value: string];
}>();

const inputValue = ref(props.modelValue);
watch(
  () => props.modelValue,
  value => (inputValue.value = value),
);

// キーボード操作時は候補の選択中も元のキーワードを覚えておき、
// 補完候補はその元のキーワードに基いて表示する
const originalValue = ref<string | undefined>(undefined);
const handledValue = computed(() => originalValue.value ?? inputValue.value);

// focusIndexは-1がinputにフォーカスがあるときで、
// 0以上がcompletionsの各項目にフォーカスがあるときを表す
const focusIndex = ref(-1);
const resetFocus = () => {
  originalValue.value = undefined;
  focusIndex.value = -1;
};

const debouncedInput = DebounceRef(
  computed(() => ({ text: handledValue.value })),
  KEYWORD_COMPLETION_DEBOUNCE_TIME,
);
const { data: completionsData, isValidating } =
  useKeywordCompletion(debouncedInput);
const completions = computed<CompletionItem[]>(() => {
  if (completionsData.value === undefined) return [];

  return completionsData.value.candidates
    .filter(item => item.text !== handledValue.value)
    .map(item => {
      const splitter = new RegExp(
        '(' + escapeRegExpSpecialCharacters(handledValue.value) + ')',
        'i',
      );
      const words = item.text.split(splitter).filter(w => w.trim() !== '');
      return {
        keyword: item.text,
        splitKeywords: [words[0], words.slice(1).join('')],
        score: item.score,
      };
    })
    .sort((a, b) => b.score - a.score);
});
const dropdownHeight = computed(() => {
  return `${completions.value.length * ITEM_HEIGHT}px`;
});
watch(isValidating, v => {
  if (v) {
    resetFocus();
  }
});

const isEditing = ref(false);
const onInput = (event: InputEvent) => {
  if (!event.target) return;
  const newValue = (event.target as HTMLInputElement).value;
  isEditing.value = newValue.trim() !== '';
  inputValue.value = newValue;
  resetFocus();
  emit('update:modelValue', newValue);
};
const onFocus = () => {
  isEditing.value = inputValue.value.trim() !== '';
};
const dropdownRef = ref<HTMLElement>();
const onBlur = () => {
  // blurですぐに閉じてしまうとdropdown上のクリックイベントが
  // 拾えないので少しずらす
  setTimeout(() => {
    isEditing.value = false;
    resetFocus();
    if (dropdownRef.value) {
      dropdownRef.value.scrollTop = 0;
    }
  }, 200);
};

let isComposing = false;
const onCompositionStart = () => {
  isComposing = true;
};
const onCompositionEnd = () => {
  isComposing = false;
};

const buttonRefs = ref<HTMLElement[]>();
const onKeyDown = (event: KeyboardEvent) => {
  if (isComposing) return;

  switch (event.key) {
    case 'ArrowDown':
    case 'ArrowUp':
      {
        event.preventDefault();
        if (originalValue.value === undefined) {
          originalValue.value = inputValue.value;
        }
        let nextIndex = focusIndex.value;
        if (event.key === 'ArrowDown') {
          nextIndex++;
          if (nextIndex >= completions.value.length) {
            nextIndex = -1;
          }
        } else {
          nextIndex--;
          if (nextIndex < -1) {
            nextIndex = completions.value.length - 1;
          }
        }
        focusIndex.value = nextIndex;
        if (nextIndex === -1) {
          inputValue.value = originalValue.value;
        } else {
          inputValue.value = completions.value[nextIndex].keyword;
          const button = buttonRefs.value?.find(
            b => Number(b.dataset.index) === nextIndex,
          );
          button?.scrollIntoView({
            block: 'nearest',
          });
        }
      }
      break;
    case 'Enter':
      event.preventDefault();
      if (focusIndex.value >= 0) {
        select(completions.value[focusIndex.value].keyword, focusIndex.value);
      } else {
        select(inputValue.value, -1);
      }
      break;
    case 'Escape':
      event.preventDefault();
      isEditing.value = false;
      break;
  }
};

const route = useRoute();
const select = async (value: string, index: number) => {
  const inputText = handledValue.value;

  isEditing.value = false;
  inputValue.value = value;
  resetFocus();
  emit('update:modelValue', value);
  emit('select', value);

  // tracking
  if (index >= 0) {
    const trackData: TrackingAutocompleteData = {
      pageName: route.name ?? '',
      pageUrl: route.fullPath,
      keywords: [value],
      input_text: inputText,
      themeId: route.params.themeId ? Number(route.params.themeId) : undefined,
      rank: index + 1, // ログは1はじまりのルール
    };
    await api.trackAutocompleteEvent(trackData);
  }
};

const handlers = {
  input: onInput,
  focus: onFocus,
  blur: onBlur,
  compositionstart: onCompositionStart,
  compositionend: onCompositionEnd,
  keydown: onKeyDown,
};
</script>
<template>
  <div>
    <slot :value="inputValue" :handlers="handlers">
      <input
        class="c-text c-text--m c-textInput"
        :value="inputValue"
        v-on="handlers"
      />
    </slot>
    <div class="dropdown-anchor">
      <div
        class="dropdown"
        :class="{ open: isEditing }"
        :style="{ '--dropdown-height': dropdownHeight }"
        ref="dropdownRef"
      >
        <button
          v-for="(completionItem, index) in completions"
          :key="`${index}_${completionItem.keyword}`"
          class="item"
          :class="{ focused: focusIndex === index }"
          :data-index="index"
          type="button"
          ref="buttonRefs"
          @click="select(completionItem.keyword, index)"
        >
          <DgrIcon name="search" class="search-icon" />
          <span class="c-text c-text--m">{{
            completionItem.splitKeywords[0]
          }}</span>
          <span class="c-title c-title--m">{{
            completionItem.splitKeywords[1]
          }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.dropdown-anchor {
  position: relative;
  width: 100%;
}

.dropdown {
  position: absolute;
  z-index: var(--z-dropdown);
  background: white;
  width: 100%;
  max-height: 0;
  box-shadow: 0px 1px 5px rgba(74, 74, 74, 0.25);
  border-radius: 4px;
  transition: max-height 0.1s ease-out;
  overflow-y: hidden;

  &.open {
    max-height: var(--dropdown-height);
  }
}

.item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
  width: 100%;
  border: none;
  background: white;
  padding: 0;
  margin: 0;

  &:hover {
    background: $color-gray200;
  }

  &.focused {
    background: $color-gray400;
  }
}

.search-icon {
  margin: 0 8px;
}
</style>
