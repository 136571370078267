import dayjs, { Dayjs } from 'dayjs';

const today = () => dayjs().startOf('day');
export const getToday = (): Date => today().toDate();

export function getADayAgo(): Date {
  return today().subtract(1, 'day').toDate();
}

export function getAWeekAgo(): Date {
  return today().subtract(1, 'week').toDate();
}

export function getAMonthAgo(): Date {
  return getNMonthsAgo(today(), 1);
}

export function getThreeMonthsAgo(): Date {
  return getNMonthsAgo(today(), 3);
}

export function getAHalfYearAgo(): Date {
  return getNMonthsAgo(today(), 6);
}

export function getAYearAgo(): Date {
  return getNYearsAgo(today(), 1);
}

export function getTwoYearsAgo(): Date {
  return getNYearsAgo(today(), 2);
}

/**
 * 指定された日数前の日付を生成する関数
 * @param daysAgo 日数
 * @returns 指定された日数前の日付
 */
export const getNDaysAgoToString = (daysAgo: number): string => {
  return dayjs().subtract(daysAgo, 'days').format('YYYY-MM-DD');
};

const getNMonthsAgo = (date: Date | Dayjs, n: number): Date => {
  return dayjs(date).subtract(n, 'months').toDate();
};

const getNYearsAgo = (date: Date | Dayjs, n: number): Date => {
  return dayjs(date).subtract(n, 'years').toDate();
};
