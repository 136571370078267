import { useRoute } from 'vue-router';

export function useCurrentRoutePath() {
  const route = useRoute();

  /**
   * 現在のルートのパスを取得する (クエリパラメータは含まれない)
   * @returns {string} 現在のルートのパス
   *
   * WARNING: クエリパラメータが不要な場合は、無闇にroute.fullPathを使わないこと。
   * 現在Anewsでは社内情報に関するデータを扱っており、クエリパラメータが機微な情報にあたる可能性がある（要約メニュー、 社内情報検索機能など）。
   * そのため、計測において基本は`route.path`を使用し、`route.fullPath`を使用する場合は影響範囲をしっかり調査した上で機微情報が漏れないように注意すること。
   */
  const getCurrentRoutePath = (): string => {
    return route.path;
  };

  return { getCurrentRoutePath };
}
