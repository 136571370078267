<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>

<template>
  <div class="content">
    <div class="content-body">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.content {
  width: max-content;
  margin: 0 auto;
  .content-body {
    width: 948px;
    padding: 24px 0 0 8px;
    margin-left: 8px;
  }
}
</style>
