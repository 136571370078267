<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { DgrIcon } from '@stockmarkteam/donguri-ui';
import SearchSuggestionsInput from '@/components/search/search-suggestions-input.vue';
import { useStore } from '@/utils/vue';

const router = useRouter();
const route = useRoute();
const store = useStore();

const SEARCH_MAX_LENGTH = 200;

const inputRef = ref<HTMLInputElement>();
const query = ref('');
const teamInfo = computed(() => store.state.teamInfo.teamInfo);

watch(
  () => route.query,
  () => {
    query.value =
      typeof route.query.query === 'string' ? route.query.query : '';
  },
);

onMounted(() => {
  if (route.query.query && typeof route.query.query === 'string') {
    query.value = route.query.query;
  }
});

const search = () => {
  if (query.value === route.query.query) return;
  if (!query.value || query.value.trim() === '') return;
  const nextPageName =
    route.meta.type !== 'search'
      ? 'searchAll'
      : teamInfo.value?.enable_technical_literature
        ? route.name ?? 'searchAll'
        : 'searchNews';
  router.push({
    name: nextPageName,
    query: { ...route.query, query: query.value },
  });
};

const deleteHistory = () => inputRef.value?.focus();
</script>

<template>
  <div class="m-keyword-search-bar">
    <SearchSuggestionsInput
      v-model="query"
      @search="search"
      @delete-history="deleteHistory"
    >
      <template #default="{ value, handlers }">
        <input
          ref="inputRef"
          class="c-textInput"
          :class="{ 'too-long': query.length >= SEARCH_MAX_LENGTH }"
          type="text"
          placeholder="検索ワードを入力してください"
          :value="value"
          v-on="handlers"
          :maxlength="SEARCH_MAX_LENGTH"
          v-tooltip.bottom="
            query.length >= SEARCH_MAX_LENGTH
              ? '200文字以内で入力してください'
              : undefined
          "
        />
      </template>
    </SearchSuggestionsInput>
    <button
      class="m-search-button"
      :disabled="query.trim() === ''"
      @click="search"
    >
      <DgrIcon name="search" :keep-fill="false" />
    </button>
  </div>
</template>
<style lang="scss" scoped>
.m-keyword-search-bar {
  display: flex;
  margin-left: 130px;
  .c-textInput {
    padding-right: 54px;
    width: 520px;
    outline: none;

    &.too-long {
      border-color: $color-orange1000;
    }
  }
  .m-search-button {
    margin: 4px 5px 0 -50px;
    padding: 4px 11px;
    height: 32px;
    border: 0;
    background: #fff;
    &:disabled {
      cursor: default;
    }
    &:hover:not(:disabled) {
      background: #f2f2f2;
    }
    .icon-box {
      padding: 0;
      fill: #b3b3b3;
    }
  }
}
</style>
