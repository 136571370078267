<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import api from '@/api';
import { DgrIcon, DgrPopover, IconTypes } from '@stockmarkteam/donguri-ui';
import SearchBar from '@/components/common/molecules/search-bar.vue';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import AdminContent from '@/components/layouts/admin-content.vue';
import Header from '@/components/layouts/header.vue';
import { formatDate } from '@/utils/formatters';
import { useAdminGroups } from '@/utils/swr';
import { checkMoveToAdmin } from '@/utils/user';
import { useStore } from '@/utils/vue';
import AdminBaseTable, { Field } from '../admin-base-table.vue';

type AdminTableItem = {
  id: number;
  name: string;
  description: string;
  memberCount: number;
  createdAt: Date;
  formattedCreatedAt: string;
  has_theme: boolean;
};

const { data: groups } = useAdminGroups();
const store = useStore();
const { createSnackbar } = useSnackbar();
const router = useRouter();
const query = ref('');

onMounted(() => {
  checkMoveToAdmin(router);
});

const onChangeQuery = (searchQuery: string) => {
  query.value = searchQuery.toLowerCase();
};

const filteredItems = computed<AdminTableItem[]>(() => {
  if (!groups.value) return [];
  return groups.value.groups
    .filter(
      g =>
        g.name.toLowerCase().includes(query.value) ||
        g.description.toLowerCase().includes(query.value),
    )
    .map(g => ({
      id: g.id,
      name: g.name,
      has_theme: g.has_theme,
      description:
        g.description.length > 50
          ? g.description.slice(0, 50) + '…'
          : g.description.length === 0
            ? '-'
            : g.description,
      memberCount: g.member_count,
      createdAt: new Date(g.created_at),
      formattedCreatedAt: formatDate(g.created_at),
    }));
});

const fields: Field<AdminTableItem>[] = [
  {
    name: 'name',
    displayName: 'グループ名',
    isSortable: true,
    type: 'string',
    fieldPosition: 'left',
    cellPosition: 'left',
  },
  {
    name: 'description',
    displayName: '説明',
    isSortable: true,
    type: 'string',
    fieldPosition: 'left',
    cellPosition: 'left',
  },
  {
    name: 'memberCount',
    displayName: 'メンバー数',
    isSortable: true,
    type: 'number',
    fieldPosition: 'left',
    cellPosition: 'center',
  },
  {
    name: 'createdAt',
    displayName: '作成日',
    isSortable: true,
    type: 'date',
    fieldPosition: 'left',
    cellPosition: 'left',
  },
  {
    name: 'actions',
    displayName: '',
    isSortable: false,
    type: 'string',
    fieldPosition: 'center',
    cellPosition: 'center',
  },
];

const deleteGroup = async (group: AdminTableItem) => {
  const _deleteGroup = async () => {
    try {
      await api.deleteGroup(group.id);
      const gs = groups.value ?? { groups: [] };
      gs.groups = [...gs.groups.filter(g => g.id !== group.id)];
      createSnackbar({
        message: 'グループを削除しました',
        type: 'success',
      });
    } catch {
      createSnackbar({
        message: 'グループを削除できませんでした',
        type: 'error',
      });
    }
  };
  let bodyText =
    '削除したグループは元に戻すことはできません。\n本当にグループを削除しますか？';
  if (group.has_theme) {
    bodyText =
      '削除したグループは元に戻すことはできません。\nまた、このグループを削除するとこのグループで公開範囲を指定しているテーマも一緒に削除されます。\n削除したくないテーマは公開範囲設定を変更してください。一度削除したテーマは復元できません。\n本当にグループを削除しますか？';
  }
  const payload = {
    headerText: 'グループを削除しますか？',
    bodyText,
    action: _deleteGroup,
  };
  store.commit('confirmationModal/showConfirmationModal');
  store.commit('confirmationModal/setTextAndAction', payload);
};

type Action = {
  name: string;
  func: (g: AdminTableItem) => void;
  icon: IconTypes;
  isSeparator: false;
};
type Separator = {
  name: string;
  isSeparator: true;
};
const actions: (Action | Separator)[] = [
  {
    name: 'メンバーを編集',
    func: (g: AdminTableItem) =>
      router.push({
        name: 'groupMembers',
        params: { groupId: g.id.toString() },
      }),
    icon: 'pencil',
    isSeparator: false,
  },
  {
    name: 'separator-1',
    isSeparator: true,
  },
  {
    name: 'グループを編集',
    func: (g: AdminTableItem) =>
      router.push({
        name: 'groupEdit',
        params: { groupId: g.id.toString() },
      }),
    icon: 'pencil',
    isSeparator: false,
  },
  {
    name: 'separator-2',
    isSeparator: true,
  },
  {
    name: '削除',
    func: deleteGroup,
    icon: 'trash',
    isSeparator: false,
  },
];
</script>

<template>
  <div class="group-admin">
    <Header title="グループ管理" header-width="100%"></Header>
    <AdminContent>
      <div class="o-group-admin__content">
        <SearchBar @on-change-query="onChangeQuery"></SearchBar>
        <div class="spacing-16"></div>
        <div class="title">
          <span class="c-title c-title--m">グループ</span
          ><span class="count c-text c-text--s"
            >・{{ filteredItems.length }}件</span
          >
        </div>
        <div class="spacing-16"></div>
        <div class="o-group-table">
          <AdminBaseTable
            :is-sticky-header="true"
            :fields="fields"
            :items="filteredItems"
            class="admin-base-table"
          >
            <template #cell-name="{ item }">
              <router-link :to="`/groups/${item.id}`">{{
                item.name
              }}</router-link>
            </template>
            <template #cell-createdAt="{ item }">
              {{ item.formattedCreatedAt }}
            </template>
            <template #cell-actions="{ item }">
              <DgrPopover>
                <template #default="{ triggerProps }">
                  <button v-bind="triggerProps" type="button" class="actions">
                    <DgrIcon name="ellipsis-h" />
                  </button>
                </template>
                <template #content="{ close }">
                  <div class="item-container">
                    <template v-for="action in actions" :key="action.name">
                      <div class="separator" v-if="action.isSeparator"></div>
                      <button
                        class="item c-text c-text--m"
                        v-else
                        @click="
                          action.func(item);
                          close();
                        "
                      >
                        <DgrIcon
                          size="small"
                          :name="action.icon"
                          class="icon small"
                          v-if="action.icon"
                        />{{ action.name }}
                      </button>
                    </template>
                  </div>
                </template>
              </DgrPopover>
            </template>
          </AdminBaseTable>
        </div>
      </div>
    </AdminContent>
  </div>
</template>

<style lang="scss" scoped>
.group-admin {
  width: 100%;
  margin: -24px 0 0 0;
  padding: 0 !important;
  .o-group-table {
    margin: 0 0 0 0;
    height: calc(100vh - 274px);
  }
  .admin-base-table {
    max-height: 100%;
  }
  .count {
    color: $color-gray600;
  }
  .actions {
    width: 40px;
    padding: 0;
    border: none;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;

    background: none;
    &:hover {
      background: $color-gray200;
    }
  }
}

.item-container {
  padding: 12px 0;

  .item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 30px;
    padding: 4px 12px;
    border: none;
    border-radius: 0;

    background: none;
    &:hover {
      background: $color-gray400;
    }
    .icon {
      margin-right: 4px;
    }
  }
  .separator {
    margin: 8px 0;
    border-top: 1px solid $color-gray400;
  }
}
</style>
