import { createTrackingData, TrackingBaseData } from '@/api/tracking';
import axios from 'axios';
import { AdpDocument } from '@/types';

export async function excludeArticle(
  themeId: number | undefined,
  adpDocument: AdpDocument,
  trackingBaseData: TrackingBaseData,
  rankInWholeFeed?: number,
): Promise<{ id: number }> {
  const { data } = await axios.post<{ id: number }>(`/article_exclusions`, {
    theme_id: themeId,
    article_id: adpDocument.id,
    doc_type: adpDocument.doc_type,
    tracking_data: createTrackingData({
      adpDocument: adpDocument,
      themeId: themeId ?? undefined,
      rankInWholeFeed,
      ...trackingBaseData,
    }),
  });
  return data;
}

export async function deleteExcludeArticle(id: number): Promise<void> {
  await axios.delete<void>(`/article_exclusions/${id}`);
}
