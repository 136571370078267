<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import api from '@/api';
import {
  MAX_IP_ADDRESS_RESTRICTION_FORM,
  MAX_IP_ADDRESS_RESTRICTIONS,
} from '@/constants';
import { DgrIcon } from '@stockmarkteam/donguri-ui';
import { isAxiosError } from 'axios';
import LoadableButton from '@/components/common/atoms/loadable-button.vue';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import IpAddressRestrictionForm from './ip-address-restriction-form.vue';
import { IpAddressRestrictionFormType } from './types';

export default defineComponent({
  components: {
    LoadableButton,
    IpAddressRestrictionForm,
    DgrIcon,
  },
  props: {
    isOpen: { type: Boolean, required: true },
    currentIpAddressRestrictionsCount: { type: Number, required: true },
  },
  emits: {
    onClose: () => true,
    onSubmit: () => true,
  },
  setup(props, { emit }) {
    const { createSnackbar } = useSnackbar();

    const ipAddress = ref('');
    const description = ref('');
    const isCreating = ref(false);
    const isCreatable = computed(
      () =>
        ipAddressRestrictionForms.value.every(form => form.isValid) &&
        !isOverIpAddressRestrictionsCount.value,
    );

    // 既に登録されているIpアドレスとフォームの数を合わせた数が上限を超えているかどうか
    const isOverIpAddressRestrictionsCount = computed(
      () => totalIpAddressRestrictionsCount.value > MAX_IP_ADDRESS_RESTRICTIONS,
    );

    // 既に登録されているIpアドレスとフォームの数が上限に達しているかどうか
    const isIpAddressRestrictionsAtLimit = computed(
      () =>
        totalIpAddressRestrictionsCount.value === MAX_IP_ADDRESS_RESTRICTIONS,
    );

    // モーダル内のフォームの数が上限に達しているかどうか(モーダル内でフォームは10件まで表示可能)
    const isFormAddable = computed(
      () =>
        ipAddressRestrictionForms.value.length <
        MAX_IP_ADDRESS_RESTRICTION_FORM,
    );

    const totalIpAddressRestrictionsCount = computed(
      () =>
        props.currentIpAddressRestrictionsCount +
        ipAddressRestrictionForms.value.length,
    );

    const hideModal = () => {
      ipAddressRestrictionForms.value = [
        {
          formId: 0,
          ipAddress: '',
          description: '',
          isValid: false,
        },
      ];
      emit('onClose');
    };

    const ipAddressRestrictionForms = ref<IpAddressRestrictionFormType[]>([
      {
        formId: 0,
        ipAddress: '',
        description: '',
        isValid: false,
      },
    ]);

    const isAddFormButtonDisabled = computed(
      () => isIpAddressRestrictionsAtLimit.value || !isFormAddable.value,
    );

    const addForm = () => {
      const lastForm =
        ipAddressRestrictionForms.value[
          ipAddressRestrictionForms.value.length - 1
        ];
      ipAddressRestrictionForms.value.push({
        formId: lastForm.formId + 1,
        ipAddress: '',
        description: '',
        isValid: false,
      });
    };

    const deleteForm = (formId: number) => {
      ipAddressRestrictionForms.value = ipAddressRestrictionForms.value.filter(
        form => form.formId !== formId,
      );
    };

    const createIpAddressRestrictions = async () => {
      isCreating.value = true;
      const ipAddressRestrictions = ipAddressRestrictionForms.value.map(
        form => ({
          ip_address: form.ipAddress,
          description: form.description,
        }),
      );
      try {
        await api.postIpAddressRestrictionBulkCreate(ipAddressRestrictions);
        createSnackbar({
          message: 'IPアドレスの追加が完了しました',
          type: 'success',
        });
        emit('onSubmit');
      } catch (error) {
        if (
          isAxiosError(error) &&
          error.response?.data.error.includes(
            'Each team can have up to 20 IP address restrictions',
          )
        ) {
          createSnackbar({
            message:
              '保存できませんでした<br>既に別のユーザーによって処理されています。',
            type: 'error',
          });
        } else {
          createSnackbar({
            message: '保存できませんでした',
            type: 'error',
          });
        }
      }
      isCreating.value = false;
      hideModal();
    };

    return {
      hideModal,
      createIpAddressRestrictions,
      isCreating,
      ipAddress,
      description,
      ipAddressRestrictionForms,
      addForm,
      deleteForm,
      isCreatable,
      isOverIpAddressRestrictionsCount,
      isIpAddressRestrictionsAtLimit,
      isAddFormButtonDisabled,
    };
  },
});
</script>

<template>
  <Teleport to="body">
    <sm-dialog
      v-if="isOpen"
      @close="hideModal"
      class="screen-center no-padding auto-width"
    >
      <template #header>
        <div class="c-dialog__title">IPアドレスの追加</div>
      </template>
      <template #body>
        <div class="modal-body">
          <div class="modal-form">
            <div
              v-for="(form, index) in ipAddressRestrictionForms"
              :key="form.formId"
            >
              <IpAddressRestrictionForm
                :is-display-label="index === 0"
                :is-display-delete-button="index !== 0"
                v-model:ip-address="form.ipAddress"
                v-model:description="form.description"
                @on-delete="deleteForm(form.formId)"
                @on-valid="form.isValid = $event"
              ></IpAddressRestrictionForm>
              <div class="spacing-12"></div>
            </div>
            <div>
              <span
                class="add-form-button c-text c-text--s"
                :class="{ disabled: isAddFormButtonDisabled }"
                @click="addForm"
              >
                <DgrIcon name="plus-small" :keep-fill="false" />
                <div class="button-text">追加</div>
              </span>
            </div>
            <div
              v-if="isIpAddressRestrictionsAtLimit"
              class="at-limit-message c-text c-text--s"
            >
              <div>これ以上追加することができません。</div>
              <div>登録可能なIPアドレスの上限数を超えてしまいます。</div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="modal-ip-address-annotation c-text c-text--s">
              <div>
                IPv4の通常の表記及びCIDR表記に対応しています(一括で最大10件登録可能)
              </div>
              <div class="spacing-08"></div>
              <div>
                通常の表記（例 203.0.113.0）CIDR表記（例 203.0.113.0/24）
              </div>
            </div>
            <div class="modal-footer-buttons">
              <button
                class="modal-cancel-button c-btn c-btn--auto c-btnOutline"
                @click="hideModal"
              >
                キャンセル
              </button>
              <div class="spacing-16"></div>
              <LoadableButton
                class="modal-create-button c-btn c-btn--auto c-btn--AnewsPrimary"
                :class="{ disabled: !isCreatable }"
                :disabled="!isCreatable"
                @click="createIpAddressRestrictions"
                :is-loading="isCreating"
              >
                保存
              </LoadableButton>
            </div>
          </div>
        </div>
      </template>
    </sm-dialog>
  </Teleport>
</template>

<style lang="scss" scoped>
.modal-body {
  width: 710px;
  height: 80vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  .modal-form {
    overflow: scroll;
    padding: 16px;
    .add-form-button {
      display: inline-flex;
      color: $color-green600;
      fill: $color-green600;
      cursor: pointer;
      .button-text {
        position: relative;
        top: 4px;
      }
      &.disabled {
        color: $color-gray600;
        fill: $color-gray600;
        pointer-events: none;
      }
    }
  }
  .at-limit-message {
    color: $color-orange1000;
  }
}
.modal-footer {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid $color-gray200;
  .modal-ip-address-annotation {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    color: $color-gray600;
  }
  .modal-footer-buttons {
    display: flex;
    flex-direction: row;
  }
}
</style>
