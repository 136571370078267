<template>
  <div class="adp-document-card-skeleton loading">
    <div class="m-sentences-skeleton">
      <div class="m-line1"></div>
      <div class="m-line1"></div>
      <div class="m-line2"></div>
      <div class="spacing-16"></div>
      <div class="m-line3"></div>
      <div class="m-line2"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.adp-document-card-skeleton {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding: 16px 0;
  width: 100%;
  background: #ffffff;
  align-items: center;
  margin-bottom: 1px;
  justify-content: space-between;
  .m-sentences-skeleton {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .m-line1,
    .m-line2,
    .m-line3 {
      height: 16px;
      background: #e6e6e6;
      border-radius: 10px;
      margin: 6px 0;
    }
    .m-line1 {
      width: 82.5%;
    }
    .m-line2 {
      width: 37.5%;
    }
    .m-line3 {
      width: 80%;
    }
  }
}

.adp-document-card-skeleton.loading {
  .m-image-skeleton {
    background-image: linear-gradient(
      110deg,
      rgba(230, 230, 230, 1) 25%,
      rgba(255, 255, 255, 0.35) 50%,
      rgba(230, 230, 230, 1) 75%
    );
    background-size: 80% 100%;
    background-position: -500% 0;
    background-repeat: no-repeat;
    animation: loading 1.5s infinite;
  }
  .m-sentences-skeleton {
    .m-line1,
    .m-line2,
    .m-line3 {
      background-image: linear-gradient(
        110deg,
        rgba(230, 230, 230, 1) 25%,
        rgba(255, 255, 255, 0.35) 50%,
        rgba(230, 230, 230, 1) 75%
      );
      background-size: 80% 100%;
      background-position: -500% 0;
      background-repeat: no-repeat;
      animation: loading 1.5s infinite;
    }
  }
}
@keyframes loading {
  to {
    background-position: 500% 0;
  }
}
</style>
