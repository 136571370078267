<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import api from '@/api';
import { isAxiosError } from 'axios';
import dayjs from 'dayjs';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import {
  Article,
  Comment,
  EmojiReaction,
  PostEmojiReactionError,
} from '@/types';
import { useStore } from '@/utils/vue';

export default defineComponent({
  setup(_, context) {
    const store = useStore();
    const { createSnackbar } = useSnackbar();
    const userInfo = computed(() => store.state.userInfo.userInfo);

    const isTogglingReaction = ref(false);

    const findTargetEmojiReaction = (
      comment: Comment,
      reaction: string,
      userId: number,
    ): EmojiReaction | undefined => {
      return comment.emoji_reactions.find(
        r => r.reaction === reaction && r.user_id === userId,
      );
    };

    const deleteEmojiReaction = async (
      comment: Comment,
      targetReaction: EmojiReaction,
    ): Promise<void> => {
      // ローカルでリアクション削除し、リクエストに失敗したらローカルの状態を元に戻す
      const reactionsBeforeUpdate = comment.emoji_reactions.slice();
      comment.emoji_reactions = comment.emoji_reactions.filter(reaction => {
        return !(
          reaction.user_id === userInfo.value?.id &&
          reaction.reaction === targetReaction.reaction
        );
      });
      try {
        await api.deleteReaction(comment.id, targetReaction.id);
      } catch (e) {
        comment.emoji_reactions = reactionsBeforeUpdate;
        throw e;
      }
    };

    const postEmojiReaction = async (
      comment: Comment,
      reaction: string,
    ): Promise<void> => {
      if (!userInfo.value) return;
      // ローカルでリアクション追加し、リクエストに失敗したらローカルの状態を元に戻す
      const reactionsBeforeUpdate = comment.emoji_reactions.slice();
      comment.emoji_reactions.push({
        id: 0,
        user_id: userInfo.value?.id,
        user_name: userInfo.value?.user_name,
        reaction: reaction,
        created_at: dayjs().format(),
      });
      try {
        const createdReaction = await api.postEmojiReaction(
          comment.id,
          reaction,
        );
        // 作成されたリアクションIDで更新
        comment.emoji_reactions[comment.emoji_reactions.length - 1].id =
          createdReaction.id;
      } catch (e) {
        comment.emoji_reactions = reactionsBeforeUpdate;
        if (
          isAxiosError<PostEmojiReactionError>(e) &&
          e.response?.data.type === 'too_many_reaction_types'
        ) {
          createSnackbar({
            message: e.response?.data.message,
            type: 'error',
          });
        }
        throw e;
      }
    };

    const toggleEmojiReaction = async (
      article: Article,
      comment: Comment,
      reaction: string,
    ): Promise<void> => {
      if (isTogglingReaction.value || !userInfo.value) return;
      isTogglingReaction.value = true;
      const targetReaction = findTargetEmojiReaction(
        comment,
        reaction,
        userInfo.value.id,
      );
      if (targetReaction) {
        await deleteEmojiReaction(comment, targetReaction).catch(() => {
          return;
        });
      } else {
        await postEmojiReaction(comment, reaction).catch(() => {
          return;
        });
      }
      // 記事と記事のコメント一覧があれば更新してイベントを発火
      if (article && article.comments) {
        const index = article.comments.findIndex(c => c.id === comment.id);
        article.comments[index] = comment;
        context.emit('article-updated', article); // eslint-disable-line vue/require-explicit-emits
      }
      isTogglingReaction.value = false;
    };
    return {
      toggleEmojiReaction,
    };
  },
});
</script>

<template>
  <div class="m-comment-box">
    <slot :toggle-emoji-reaction="toggleEmojiReaction"></slot>
  </div>
</template>

<style lang="scss" scoped>
.m-comment-box {
}
</style>
