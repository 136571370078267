<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { DgrCheckbox, DgrIcon } from '@stockmarkteam/donguri-ui';
import { useOutsideClick } from '@/utils/composables/useOutsideClick';

/**
 * Types
 */

type Option = {
  label: string;
  value: string;
};

type Section = {
  sectionName: string;
  options: Option[];
};

/**
 * Props
 */

interface Props {
  initialFormValue: string[];
  sections: Section[];
  selectedLabel?: string;
}
const props = withDefaults(defineProps<Props>(), {
  selectedLabel: 'カテゴリー',
});

/**
 * Emits
 */

const emit = defineEmits<{
  submit: [value: string[]];
}>();

/**
 * Local State
 */

// フィルターの開閉状態と操作
const isOpen = ref(false);

const openFilter = () => {
  isOpen.value = true;
};
const closeFilter = () => {
  isOpen.value = false;
  restoreFormValue();
};
const toggleFilter = () => {
  isOpen.value ? closeFilter() : openFilter();
};

// フィルターのクリックイベント(フィルターの外側をクリックした場合は閉じる)
const containerRef = ref<Element>();
const isClickedOutsideFilter = useOutsideClick([containerRef]);
watch(isClickedOutsideFilter, () => {
  if (isClickedOutsideFilter.value) {
    closeFilter();
  }
});

// フォームの値と操作
const initialFormValue = computed(() => props.initialFormValue);
const formValue = ref<string[]>(initialFormValue.value);
watch(initialFormValue, () => {
  formValue.value = initialFormValue.value;
});

const submitFormWithClose = () => {
  emit('submit', formValue.value);
  closeFilter();
};
const restoreFormValue = () => {
  formValue.value = initialFormValue.value;
};
const resetFormValue = () => {
  formValue.value = [];
};
const toggleCheckbox = (option: string) => {
  formValue.value = formValue.value.includes(option)
    ? formValue.value.filter(v => v !== option)
    : [...formValue.value, option];
};
</script>

<template>
  <div ref="containerRef" class="container">
    <button class="filter-toggle-button" @click="toggleFilter">
      <span class="c-text c-text--s">{{ selectedLabel }}</span>
      <DgrIcon size="small" name="angle-down" class="c-selectBox__arrow" />
    </button>

    <div v-if="isOpen" @click.stop="() => undefined" class="popup-container">
      <div class="section-container">
        <div v-for="section in props.sections" :key="section.sectionName">
          <p
            class="c-text--s"
            :class="{ 'section-name': section.options.length > 0 }"
          >
            {{ section.sectionName }}
          </p>

          <div class="c-text--m option-container">
            <template v-for="option in section.options" :key="option.value">
              <DgrCheckbox
                :model-value="formValue.includes(option.value)"
                @update:model-value="() => toggleCheckbox(option.value)"
              >
                {{ option.label }}
              </DgrCheckbox>
            </template>
          </div>
        </div>
      </div>

      <div class="button-container">
        <button
          class="deselect-button c-text c-text--m"
          @click="resetFormValue"
        >
          選択解除
        </button>
        <button class="dashboard-button--primary" @click="submitFormWithClose">
          適用
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.container {
  position: relative;
}

.filter-toggle-button {
  display: flex;
  align-items: center;
  color: $color-gray1000;
  background: #fff;
  height: 32px;
  border: 1px solid $color-border;
  width: 100%;
  justify-content: space-between;
  padding-left: 8px;
  padding-right: 8px;
  gap: 4px;
}

.popup-container {
  position: absolute;
  z-index: var(--z-dropdown);
  box-shadow: 0 1px 5px rgba(74, 74, 74, 0.25);
  background: #fff;
  width: 240px;
  border-radius: 4px;
}

.section-container {
  display: flex;
  flex-direction: column;
  padding: 16px;
  max-height: 320px;
  overflow-y: auto;
  gap: 16px;
}

.section-name {
  margin-bottom: 8px;
}

.option-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.button-container {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 16px;
  border-top: 1px solid $color-gray400;
}

.deselect-button {
  height: 32px;
  padding: 4px;
  background-color: transparent;
  color: $color-green600;
  border: none;
  cursor: pointer;
  align-items: center;
  border-radius: 4px;

  &:hover {
    background-color: $color-gray200;
  }
}
</style>
