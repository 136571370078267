import { onMounted, onUnmounted } from 'vue';
import { provideSnackbarAPI, useSnackbarAPI } from '@stockmarkteam/donguri-ui';
import mitt from 'mitt';

export type SnackbarType = {
  link: { link: string; suffixText: string };
};

type SnackbarParams = Parameters<
  ReturnType<typeof useSnackbarAPI<SnackbarType>>['create']
>[0];

export const provideSnackbar = () => {
  provideSnackbarAPI({
    max: 1,
    duration: 10000,
  });
};

const snackbarEmitter = mitt<{ create: SnackbarParams }>();

export const defineSnackbarCreator = () => {
  const { create } = useSnackbarAPI<SnackbarType>();

  onMounted(() => snackbarEmitter.on('create', create));
  onUnmounted(() => snackbarEmitter.off('create', create));
};

export const mockSnackbarCreator = (
  mockFn: (params: SnackbarParams) => void,
) => {
  snackbarEmitter.on('create', mockFn);
};

const createSnackbar = (option: SnackbarParams) => {
  snackbarEmitter.emit('create', option);
};

export const useSnackbar = () => {
  return { createSnackbar };
};
