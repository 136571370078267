<script lang="ts">
import { defineComponent, onMounted, reactive } from 'vue';
import api from '@/api';
import { Site } from '@/types';
import { useStore } from '@/utils/vue';

export default defineComponent({
  setup() {
    const store = useStore();
    const hideModal = () => {
      store.commit('modal/hideModal');
    };
    const sites = reactive<{ items: Site[] }>({ items: [] });

    onMounted(async () => {
      sites.items = await api.getSites(true);
    });

    return {
      hideModal,
      sites,
    };
  },
});
</script>

<template>
  <div>
    <sm-dialog @close="hideModal" class="screen-center">
      <template #header>
        <div class="m-header">
          <span class="m-header-text">ニュース配信設定について</span>
        </div>
      </template>
      <template #body>
        <div class="m-body">
          <div class="c-title c-title--m">エンタメ記事の除外機能について</div>
          <div class="c-text c-text--m">
            除外機能を有効にすることで以下の記事配信がされなくなります。
            <ul>
              <li>AIによりエンタメ記事と判定された記事</li>
              <li>下記に記載されているメディアの記事</li>
            </ul>
          </div>
          <div class="sites">
            <div class="site" v-for="site in sites.items" :key="site.id">
              <div class="name c-text c-text--m">{{ site.name }}</div>
              <div class="url c-text c-text--s">{{ site.hostname }}</div>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="m-footer"></div>
      </template>
    </sm-dialog>
  </div>
</template>
<style lang="scss" scoped>
.sites {
  max-height: 200px;
  overflow-y: scroll;
  margin-top: 12px;

  .site {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
    .name {
      word-wrap: nowrap;
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
    .url {
      color: #b3b3b3;
    }
  }
}

.m-body ul {
  padding-left: 20px;
}
</style>
