import axios from 'axios';
import Qs from 'qs';
import {
  FollowListMarkSortType,
  FollowListResourceType,
  FollowListType,
} from '@/types';

export async function createFollowList(
  resources: { id: number; type: FollowListResourceType }[],
  sort: FollowListType['sort'],
): Promise<FollowListType> {
  const { data } = await axios.post('/follow_lists', {
    resources,
    sort,
  });
  return data;
}

export async function deleteFollowList(
  followListId: FollowListType['id'],
): Promise<void> {
  await axios.delete(`/follow_lists/${followListId}`);
}

export async function updateFollowListOrder(
  followListOrdersById: {
    id: FollowListType['id'];
    order: number;
  }[],
): Promise<Omit<FollowListType, 'sort' | 'resources'>> {
  const { data } = await axios.put(
    `/follow_lists/order?${Qs.stringify(
      { orders_by_id: followListOrdersById },
      {
        arrayFormat: 'brackets',
      },
    )}`,
  );
  return data;
}

export async function updateFollowListSort(
  followListId: number,
  sort: FollowListMarkSortType,
): Promise<void> {
  await axios.put(`/follow_lists/${followListId}/sort`, {
    follow_list_id: followListId,
    sort,
  });
}
