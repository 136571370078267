import {
  SEARCH_EXPANDED_QUERY_CACHE_STORE_NAME,
  USER_DOCUMENT_SEARCH_EXPANDED_QUERY_CACHE_STORE_NAME,
} from '@/constants';
import axios from 'axios';
import Qs from 'qs';
import { AdpDocument, DocType } from '@/types';
import { searchExpandedQueryCache } from '@/utils/searchExpandedQueryCache';

export async function fetchDocument(
  articleId: number,
  docType: DocType,
): Promise<AdpDocument> {
  const { data } = await axios.get<AdpDocument>(`/${docType}s/${articleId}`);
  return data;
}

export async function getDocumentsCount(
  query: string,
  url: string,
  storeName:
    | typeof USER_DOCUMENT_SEARCH_EXPANDED_QUERY_CACHE_STORE_NAME
    | typeof SEARCH_EXPANDED_QUERY_CACHE_STORE_NAME,
  docTypes?: DocType[],
): Promise<number> {
  const params = {
    query: query,
    doc_types: docTypes,
    cached_expanded_queries: null as string[] | null,
  };

  const expandedQueryCache = query
    ? await searchExpandedQueryCache.getCache(query, storeName)
    : null;
  if (expandedQueryCache) {
    params.cached_expanded_queries = expandedQueryCache;
  }

  const fullUrl = `${url}?${Qs.stringify(params, {
    arrayFormat: 'brackets',
  })}`;
  const data = (
    await axios.get<{ count: number; expanded_queries: string[] }>(fullUrl)
  ).data;

  if (
    !expandedQueryCache &&
    query &&
    data.expanded_queries &&
    data.expanded_queries.length > 0
  ) {
    searchExpandedQueryCache.setCache(query, data.expanded_queries, storeName);
  }
  return data.count;
}
