import { Module } from 'vuex';
import { AdminUserInfo } from '@/types';

export type UserEditModalState = {
  userEditModalOpen: boolean;
  type: 'create' | 'edit';
  targetUserInfo: AdminUserInfo | undefined;
};

export const initialUserEditModalState: UserEditModalState = {
  userEditModalOpen: false,
  type: 'create',
  targetUserInfo: undefined,
};

const userEditModal: Module<UserEditModalState, unknown> = {
  namespaced: true,
  state: () => initialUserEditModalState,
  mutations: {
    showUserEditModal(state, payload) {
      state.userEditModalOpen = true;
      state.type = payload.type;
      state.targetUserInfo = payload.user;
    },
    hideUserEditModal(state) {
      state.userEditModalOpen = false;
    },
  },
};

export default userEditModal;
