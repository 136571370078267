<script setup lang="ts">
import { computed, nextTick, ref, watch } from 'vue';
import { DOC_TYPE_LABELS } from '@/constants';
import { DgrCheckbox, DgrIcon } from '@stockmarkteam/donguri-ui';
import ToggleSwitch, {
  ToggleSwitchItem,
} from '@/components/common/molecules/toggle-switch.vue';
import { DocType, SearchScope } from '@/types';
import { useOutsideClick } from '@/utils/composables/useOutsideClick';
import {
  ALL_DOC_TYPES,
  DOC_TYPES_FOR_SCOPE,
  TARGET_SOURCE_LABELS,
  TARGET_SOURCES,
} from '@/utils/survey/constants';
import { useTeamInfo } from '@/utils/swr';
import { featureFlags } from '@/featureFlags';

/**
 * Props
 */

const props = defineProps<{
  position: 'top' | 'bottom';
}>();

/**
 * Emits
 */

const emit = defineEmits<{
  'apply-filter': [value: { searchScope: SearchScope; docTypes: string[] }];
}>();

/**
 * Local State
 */

const DOC_TYPES_ORDER = Object.keys(DOC_TYPE_LABELS);

// 契約状態
const { data: teamInfo } = useTeamInfo();
const enableTechnicalLiterature = computed(
  () => teamInfo.value?.enable_technical_literature ?? false,
);
const enableUserDocument = computed(
  () => teamInfo.value?.enable_user_document ?? false,
);

// フィルターの開閉状態と操作
const isOpen = ref(false);
const containerRef = ref<Element>();
const popupRef = ref<Element>();
const openFilter = () => {
  isOpen.value = true;
  nextTick(() => {
    adjustPopupPosition();
  });
};
const closeFilter = (reset = true) => {
  isOpen.value = false;
  if (reset) {
    restoreFormValue();
  }
};
const toggleFilter = () => {
  isOpen.value ? closeFilter() : openFilter();
};

// フィルターのクリックイベント(フィルターの外側をクリックした場合は閉じる)
const isClickedOutsideFilter = useOutsideClick([containerRef]);
watch(isClickedOutsideFilter, () => {
  if (isClickedOutsideFilter.value) {
    closeFilter();
  }
});

// フォームの値と操作
const isUserMarkSurveyEnabled = featureFlags.ANDEV_5481_USER_MARK_SURVEY;

const originalSearchScope = defineModel<SearchScope>('searchScope', {
  default: 'all',
  required: true,
});
const searchScope = ref<SearchScope>(
  isUserMarkSurveyEnabled ? originalSearchScope.value : 'all',
);
const searchScopeToActiveOption = (searchScope: SearchScope) =>
  searchScope === 'all' ? 'left' : 'right';
const toggleSwitchItem = computed<ToggleSwitchItem>(() => ({
  leftLabelName: 'すべての情報',
  rightLabelName: 'マークした情報',
  activeOption: searchScopeToActiveOption(searchScope.value),
}));
const toggleSearchScope = async () => {
  if (!isUserMarkSurveyEnabled) return;

  searchScope.value = searchScope.value === 'user_marks' ? 'all' : 'user_marks';
};

watch(originalSearchScope, () => {
  searchScope.value = originalSearchScope.value;
});

const originDocTypes = defineModel('docTypes', {
  default: [],
  required: true,
  type: Array<DocType | 'user_document'>,
});
const docTypes = ref<Array<DocType | 'user_document'>>(originDocTypes.value);
const isFiltering = computed(
  () => JSON.stringify(originDocTypes.value) !== JSON.stringify([]),
);

watch(originDocTypes, () => {
  docTypes.value = sortDocTypes(originDocTypes.value);
  if (originDocTypes.value.length === 0) {
    searchScope.value = 'all';
  }
});

/**
 * Handlers
 */

const toggleCheckbox = (option: DocType | 'user_document') => {
  docTypes.value = docTypes.value.includes(option)
    ? docTypes.value.filter(v => v !== option)
    : [...docTypes.value, option];
};

const restoreFormValue = () => {
  searchScope.value = originalSearchScope.value;
  docTypes.value = sortDocTypes(originDocTypes.value);
};

const resetFormValue = async () => {
  originalSearchScope.value = 'all';
  originDocTypes.value = [];
  await nextTick();
  closeFilter();
};

const applyFilterWithClose = async () => {
  if (docTypes.value.length === 0) {
    resetFormValue();
  }
  docTypes.value = sortDocTypes(
    docTypes.value.filter(d =>
      isUserMarkSurveyEnabled
        ? DOC_TYPES_FOR_SCOPE(
            searchScope.value,
            enableTechnicalLiterature.value,
            enableUserDocument.value,
          ).includes(d)
        : ALL_DOC_TYPES(
            enableTechnicalLiterature.value,
            enableUserDocument.value,
          ).includes(d),
    ),
  );
  originalSearchScope.value = searchScope.value;
  originDocTypes.value = docTypes.value;

  await nextTick();
  emit('apply-filter', {
    searchScope: searchScope.value,
    docTypes: docTypes.value,
  });
  closeFilter(false);
};

const sortDocTypes = (docTypes: Array<DocType | 'user_document'>) => {
  return docTypes.sort(
    (a, b) => DOC_TYPES_ORDER.indexOf(a) - DOC_TYPES_ORDER.indexOf(b),
  );
};

/**
 * ポップアップの位置を調整する関数
 * - 基本的にはボタンの下にポップアップを表示するが、画面下部にスペースがない場場合は上部に表示する
 *
 * NOTE: 画面下部のスペースとポップアップの高さで自動的に上か下かを判断するのがベストだが、実装スピードを優先してpropsで決め打ちにしている
 */
const adjustPopupPosition = () => {
  if (!containerRef.value || !popupRef.value) return;
  if (props.position === 'bottom') return;

  const containerTop = containerRef.value.getBoundingClientRect().top;
  (popupRef.value as HTMLDivElement).style.top =
    `${containerTop - popupRef.value.getBoundingClientRect().height}` + 'px';
};
</script>

<template>
  <div ref="containerRef" class="filter-container">
    <button class="filter-toggle-button" @click="toggleFilter">
      <span class="c-text c-text--m">{{
        isFiltering ? '絞り込み適用中' : '検索対象絞り込み'
      }}</span>
      <DgrIcon name="angle-down" class="c-selectBox__arrow" />
    </button>
    <div
      ref="popupRef"
      v-if="isOpen"
      @click.stop="() => undefined"
      class="popup-container"
    >
      <template v-if="isUserMarkSurveyEnabled">
        <div class="section-container">
          <ToggleSwitch
            class="search-scope-switch"
            :toggle-switch-item="toggleSwitchItem"
            @toggle-switch="toggleSearchScope"
          ></ToggleSwitch>
        </div>
        <div class="section-container doc-type-container c-text c-text--m">
          <template
            v-for="option in TARGET_SOURCE_LABELS(
              searchScope,
              enableTechnicalLiterature,
              enableUserDocument,
            )"
            :key="option.value"
          >
            <DgrCheckbox
              :model-value="docTypes.includes(option.value)"
              @update:model-value="() => toggleCheckbox(option.value)"
            >
              {{ option.label }}
            </DgrCheckbox>
          </template>
        </div>
      </template>
      <template v-else>
        <div class="section-container">
          <template
            v-for="source in TARGET_SOURCES(
              enableTechnicalLiterature,
              enableUserDocument,
            )"
            :key="source.section"
          >
            <p class="c-title c-title--m">{{ source.section }}</p>
            <div class="c-text--m option-container">
              <template v-for="option in source.options" :key="option.value">
                <DgrCheckbox
                  :model-value="docTypes.includes(option.value)"
                  @update:model-value="() => toggleCheckbox(option.value)"
                >
                  {{ option.label }}
                </DgrCheckbox>
              </template>
            </div>
          </template>
        </div>
      </template>
      <div class="button-container">
        <button
          class="dashboard-button--borderless"
          @click="resetFormValue"
          data-testid="reset-button"
        >
          リセット
        </button>
        <div class="right-buttons">
          <button
            class="dashboard-button"
            @click="() => closeFilter()"
            data-testid="cancel-button"
          >
            キャンセル
          </button>
          <div class="spacing-16"></div>
          <button
            class="dashboard-button--primary"
            @click="applyFilterWithClose"
            data-testid="apply-button"
          >
            適用
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.filter-container {
  position: relative;
}

.filter-toggle-button {
  display: flex;
  align-items: center;
  color: $color-gray1000;
  background: #fff;
  height: 32px;
  border: 1px solid $color-border;
  width: 100%;
  justify-content: space-between;
  padding: 4px 8px;
  gap: 4px;
}

.popup-container {
  position: fixed;
  z-index: var(--z-dropdown);
  box-shadow: 0 1px 5px rgba(74, 74, 74, 0.25);
  background: #fff;
  width: 456px;
  border-radius: 4px;
}

.section-container {
  padding: 16px;
  max-height: 320px;
  overflow-y: auto;
  display: grid;
  grid-template-columns: 0.8fr 1fr;
  gap: 16px;
  align-items: center;

  & + .section-container {
    padding-top: 0;
  }
}

.doc-type-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.option-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.button-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 16px;
  border-top: 1px solid $color-gray400;
}

.right-buttons {
  display: flex;
}

.dashboard-button--borderless {
  color: $color-green600;
}

.search-scope-switch {
  width: 280px;
}
</style>
