<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import Draggable from 'vuedraggable';
import api from '@/api';
import { DgrToggleButton } from '@stockmarkteam/donguri-ui';
import Loading from '@/components/common/loading.vue';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import Header from '@/components/layouts/header.vue';
import { FollowListType } from '@/types';
import { useFollowLists } from '@/utils/swr';
import { userSession } from '@/utils/userSession';
import CreateFollowListModal from './create-follow-list-modal.vue';
import FollowList from './follow-list.vue';

const route = useRoute();
const { createSnackbar } = useSnackbar();

// NOTE: 計測ログで使用する際に定数で保持しないとタイミング次第では変更されてしまうため、定数で保持している
const currentPath = route.path;

const isVisibleComment = ref(userSession.getFollowListCommentsVisible());
const setCommentVisibility = (value: boolean) => {
  isVisibleComment.value = value;
  userSession.setFollowListCommentsVisible(value);
};
const computedIsVisibleComment = computed({
  get: () => isVisibleComment.value,
  set: value => setCommentVisibility(value),
});

const isCreateDialogShown = ref(false);
const showCreateDialog = () => {
  if (isCreateButtonDisabled.value) {
    return;
  }
  isCreateDialogShown.value = true;
};
const cancelCreate = () => {
  isCreateDialogShown.value = false;
};

const { data, mutate, state } = useFollowLists();
watch(state, () => {
  if (state.value === 'ERROR') {
    createSnackbar({
      message: 'フォローリストの取得に失敗しました',
      type: 'error',
    });
  }
});
const isLoading = computed(() => state.value === 'PENDING');
const submitCreate = async (followList: FollowListType) => {
  isCreateDialogShown.value = false;
  await api.trackingFollowListEvent('follow_list_add', {
    page: {
      name: 'follow_list',
      url: currentPath,
    },
    follow_list: {
      id: followList.id,
      type: followList.resources[0].type,
      ...(followList.resources[0].type === 'organization_tag'
        ? { organization_tag: { id: followList.resources[0].id } }
        : {}),
      ...(followList.resources[0].type === 'user'
        ? // NOTE: 現状では1名しか入らないが、後続で複数人追加の可能性があるため配列で対応している
          { users: [{ id: followList.resources[0].id }] }
        : {}),
      order: followListLength.value + 1,
      sort: followList.sort === 'recommended' ? 'おすすめ順' : '新着順',
    },
  });
  await mutate();
};
const followListLength = computed(() => {
  return data?.value ? data?.value.follow_lists.length : 0;
});
const isCreateButtonDisabled = computed(() => followListLength.value >= 10);

const onDelete = async (followListId: number) => {
  try {
    await api.deleteFollowList(followListId);
    await mutate();
  } catch (error) {
    createSnackbar({
      message: 'フォローリストの削除に失敗しました',
      type: 'error',
    });
    if (error instanceof Error) {
      throw error;
    }
    throw new Error('予期しないエラーが発生しました');
  }
};

/**
 * @example { start: 0, end: 2 }
 * @return [0, 1, 2]
 */
const createRangeArray = ({
  start,
  end,
}: {
  start: number;
  end: number;
}): number[] => {
  return Array.from({ length: end - start + 1 }, (_, index) => start + index);
};

const onDrag = async ({
  oldIndex,
  newIndex,
}: {
  oldIndex: number;
  newIndex: number;
}): Promise<void> => {
  const isIncreased = oldIndex < newIndex;
  const ranges = createRangeArray(
    isIncreased
      ? {
          start: oldIndex,
          end: newIndex,
        }
      : {
          start: newIndex,
          end: oldIndex,
        },
  );
  const params = ranges.map((order, index) => {
    if (!data.value)
      throw new Error('data is undefined from follow list onDrag');
    return {
      id: data.value.follow_lists[ranges[index]].id,
      order: order + 1,
    };
  });
  try {
    await api.updateFollowListOrder(params);
  } catch (error) {
    createSnackbar({
      message: 'リストの並び替えに失敗しました',
      type: 'error',
    });
    if (error instanceof Error) {
      throw error;
    }
    throw new Error('予期しないエラーが発生しました');
  } finally {
    await mutate();
  }
};
</script>

<template>
  <div class="follow-lists">
    <Header
      title="フォローリスト"
      detail="メンバーがマークした情報を一覧で確認することができます。"
      :align-left="true"
    />
    <div class="contents-area">
      <template v-if="isLoading">
        <Loading class="overlay" />
      </template>
      <DgrToggleButton
        class="c-text c-text--s comment-toggle toggle-button"
        v-model="computedIsVisibleComment"
        >コメントを表示</DgrToggleButton
      >
      <div class="header-button-wrapper" v-if="followListLength >= 3">
        <VTooltip placement="bottom" :disabled="!isCreateButtonDisabled">
          <button
            class="dashboard-button--primary header-button"
            :disabled="isCreateButtonDisabled"
            @click="showCreateDialog"
            :class="{ disabled: isCreateButtonDisabled }"
          >
            +リストを追加
          </button>
          <template #popper>
            <span>
              リストは最大10個まで作成できます。<br />リストを削除してから追加してください。
            </span>
          </template>
        </VTooltip>
      </div>
      <div
        class="lists-area"
        v-if="data?.follow_lists && data.follow_lists.length > 0"
      >
        <div class="drag-area" />
        <Draggable
          tag="ul"
          :list="data.follow_lists"
          class="lists"
          item-key="id"
          :move="() => true"
          handle=".drag-icon"
          @update="onDrag"
          id="follow-lists"
          ghost-class="ghost"
          animation="150"
        >
          <template #item="{ element: followList }">
            <li :key="followList.id">
              <FollowList
                :follow-list-id="followList.id"
                :is-visible-comment="isVisibleComment"
                @delete="() => onDelete(followList.id)"
              />
            </li>
          </template>
        </Draggable>
        <div v-if="followListLength < 3" class="additional-list-message">
          <button class="dashboard-button--primary" @click="showCreateDialog">
            +リストを追加
          </button>
        </div>
        <div class="drag-area right" />
      </div>
    </div>
    <Teleport to="body" v-if="isCreateDialogShown">
      <CreateFollowListModal
        @close="cancelCreate"
        @submit="followList => submitCreate(followList)"
      />
    </Teleport>
  </div>
</template>

<style lang="scss" scoped>
.follow-lists {
  width: 100%;
  margin: -24px 0 0 0;
  /* NOTE: main-view.vueのpadding-bottomが適応されるための回避策 */
  padding-bottom: 0 !important;
}
.contents-area {
  display: grid;
  grid-template:
    'comment-toggle add-button' auto
    'lists          lists     ' minmax(0, 1fr)
    / 1fr 1fr;
  gap: 16px;

  /* 全体(100vh) - 64px(全体のHeader) - 81px(フォローリストのHeader) - 40px(padding * 2) */
  height: calc(100vh - 64px - 81px - 40px);
  padding: 20px 0 20px 60px;
}
.toggle-button {
  margin-left: 20px;
}

.header-button {
  margin-right: 20px;
}
.overlay {
  height: 100vh;
}
.comment-toggle {
  grid-area: comment-toggle;
  align-self: center;
  justify-self: start;
}
.contents-area > .header-button-wrapper {
  justify-self: end;
}
.contents-area > .dashboard-button--primary {
  grid-area: add-button;
  width: 152px;
  align-self: center;
}
.lists-area {
  grid-area: lists;
  display: flex;
  overflow-x: auto;
}
.drag-area {
  height: 100%;
  min-width: 20px;
  background-color: $color-gray200;
  position: sticky;
  z-index: 1;
  left: 0;
  &.right {
    right: 0;
  }
}
.lists {
  display: flex;
  gap: 16px;
  list-style: none;
}
.ghost {
  visibility: hidden;
}
.additional-list-message {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  flex-direction: column;
  background: $color-gray400;
  padding: 8px;
  border-radius: 4px;
  min-width: 174px;
  max-width: 400px;
  width: 100%;
  margin-left: 16px;
}
.additional-list-button {
  color: white;
  background-color: $color-gray600;
  cursor: not-allowed;
  border: none;
}
.message-box {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.empty-image {
  max-width: 64px;
}
</style>
