<script setup lang="ts">
import { Feature, PageName } from '@/api/tracking';
import AdpDocumentList from '@/components/common/adp-document/adp-document-list.vue';
import {
  ArticleMarkSource,
  FeedType,
  Theme,
  ThemeFeedPatent,
  ThemeFeedResearchPaper,
} from '@/types';

type Props = {
  docType: 'research_paper' | 'patent';
  feedType: FeedType;
  formattedFeedDate?: string;
  domesticDocuments: (ThemeFeedResearchPaper | ThemeFeedPatent)[];
  foreignDocuments: (ThemeFeedResearchPaper | ThemeFeedPatent)[];
  theme?: Theme;
  enableToEditTheme?: boolean;
};
const props = defineProps<Props>();

const isPersonalNews = props.theme === undefined;

const title = props.docType === 'research_paper' ? '論文ジャーナル' : '特許';

const maxFeedCount = isPersonalNews ? 50 : 200;

const pageName: PageName = isPersonalNews
  ? `personal_${props.docType}s`
  : `${props.docType}s`;
const feature: Feature = isPersonalNews
  ? `personal_${props.docType}_feed`
  : `${props.docType}_feed`;
const markSource: ArticleMarkSource = isPersonalNews
  ? 'personal_feed'
  : 'theme';
</script>

<template>
  <div class="technical-literature-list">
    <template v-if="feedType === 'all' || feedType === 'domestic'">
      <AdpDocumentList
        :title="`国内${title}`"
        :formatted-feed-date="formattedFeedDate"
        :display-feed-type="feedType"
        :theme-feed-type="'domestic'"
        :doc-type="docType"
        :theme="theme"
        :enable-to-edit-theme="enableToEditTheme"
        :adp-documents="domesticDocuments"
        :initial-feed-count="feedType === 'all' ? 20 : 200"
        :max-feed-count="maxFeedCount"
        :page-name="pageName"
        :feature="feature"
        :mark-source="markSource"
      />
    </template>
    <template
      v-if="
        docType === 'research_paper' &&
        (feedType === 'all' || feedType === 'foreign')
      "
    >
      <AdpDocumentList
        :title="`海外${title}`"
        :formatted-feed-date="formattedFeedDate"
        :display-feed-type="feedType"
        :theme-feed-type="'foreign'"
        :doc-type="docType"
        :theme="theme"
        :enable-to-edit-theme="enableToEditTheme"
        :adp-documents="foreignDocuments"
        :initial-feed-count="feedType === 'all' ? 20 : 200"
        :max-feed-count="maxFeedCount"
        :page-name="pageName"
        :feature="feature"
        :mark-source="markSource"
      />
    </template>
  </div>
</template>

<style lang="scss" scoped>
.technical-literature-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
</style>
