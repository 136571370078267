import { Module } from 'vuex';

export type NotificationPopupState = {
  isOpen: boolean;
  position: {
    top?: string;
    right?: string;
    bottom?: string;
    left?: string;
  };
};

export const initialNotificationPopupState: NotificationPopupState = {
  isOpen: false,
  position: {},
};

const notificationPopup: Module<NotificationPopupState, unknown> = {
  namespaced: true,
  state: () => initialNotificationPopupState,
  mutations: {
    show(state, payload) {
      state.isOpen = true;
      state.position = payload.position;
    },
    close(state) {
      state.isOpen = false;
    },
  },
};

export default notificationPopup;
