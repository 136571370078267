<script lang="ts">
import { computed, defineComponent, nextTick, PropType, toRefs } from 'vue';
import { DgrIcon } from '@stockmarkteam/donguri-ui';
import { INTERCOM_TOUR_IDS, startTourOfIntercom } from '@/utils/intercom';
import { useStore } from '@/utils/vue';

export default defineComponent({
  components: {
    DgrIcon,
  },
  props: {
    actionedArticleCounts: {
      type: Object as PropType<{ domestic: number; foreign: number }>,
      required: true,
    },
  },
  emits: ['open-left-menu'],
  setup(props, context) {
    const store = useStore();
    const feedType = computed(() => store.state.feedType.feedType);

    const isFeedIncludingDomestic = computed(() =>
      ['all', 'domestic'].includes(feedType.value),
    );
    const isFeedIncludingForeign = computed(() =>
      ['all', 'foreign'].includes(feedType.value),
    );

    const { actionedArticleCounts } = toRefs(props);
    const requiredActionCount = 10;
    const actionCount = computed(() => {
      return isFeedIncludingForeign.value
        ? actionedArticleCounts.value.foreign
        : actionedArticleCounts.value.domestic;
    });
    const isPreparingFeed = computed(
      () => actionCount.value >= requiredActionCount,
    );

    const navigateToIndustry = async () => {
      context.emit('open-left-menu');
      await nextTick();
      startTourOfIntercom(INTERCOM_TOUR_IDS.SHOW_INDUSTRY);
    };
    const navigateToLatestMarks = async () => {
      context.emit('open-left-menu');
      await nextTick();
      startTourOfIntercom(INTERCOM_TOUR_IDS.SHOW_LATEST_MARKS);
    };

    const navigateToSearch = () => {
      startTourOfIntercom(INTERCOM_TOUR_IDS.SHOW_SEARCH);
    };

    return {
      feedType,
      requiredActionCount,
      actionCount,
      isPreparingFeed,
      isFeedIncludingDomestic,
      isFeedIncludingForeign,
      navigateToIndustry,
      navigateToLatestMarks,
      navigateToSearch,
    };
  },
});
</script>

<template>
  <div class="personal-feed-container">
    <div
      class="o-no-personal-feed"
      :class="{ 'o-no-personal-feed-preparing': isPreparingFeed }"
    >
      <div class="o-title c-text c-text--l" v-if="isPreparingFeed">
        あなた専用のニュースを準備しています...
      </div>
      <div class="o-explanation o-explanation-preparing" v-if="isPreparingFeed">
        <div class="o-description c-text c-text--m">
          ニュースを選別するための情報が集まりました。<br />
          <span class="c-title">あなた専用のニュース</span>をお届けします！
        </div>
        <img src="@/assets/state-prepare-feed-small.png" />
      </div>
      <div class="o-explanation" v-if="!isPreparingFeed">
        <div class="o-description c-text c-text--m">
          10件の{{ isFeedIncludingForeign ? '国内・海外' : '' }}記事を<span
            class="c-title o-text-emphasis"
            >閲覧</span
          >や<span class="c-title o-text-emphasis">マーク</span>、<span
            class="c-title o-text-emphasis"
            >コメント</span
          >をすることで<br />
          <span class="c-title"
            >あなた専用の{{
              feedType === 'foreign' ? '海外メディア' : ''
            }}ニュース</span
          >が配信されるようになります！
        </div>
        <img src="@/assets/state-empty-mark.png" />
      </div>
      <div class="o-progress-bar-container">
        <template
          v-for="(n, i) in requiredActionCount"
          :key="`filled-bar-${i}`"
        >
          <div
            :class="
              i < actionCount
                ? 'o-progress-bar o-progress-filled'
                : 'o-progress-bar'
            "
          ></div>
          <div
            :class="
              i < actionCount
                ? 'o-progress-point o-progress-filled'
                : 'o-progress-point'
            "
          ></div>
        </template>
      </div>
      <div class="o-progress-numbers">
        <span
          class="o-progress-number"
          v-for="(n, i) in requiredActionCount"
          :key="i"
          >{{ i + 1 }}</span
        >
      </div>
      <div class="spacing-16"></div>
      <template v-if="!isPreparingFeed">
        <div class="o-hint c-text c-text--m">
          <a
            href="https://anews-stockmark.zendesk.com/hc/ja/articles/4413097891481-%E3%83%91%E3%83%BC%E3%82%BD%E3%83%8A%E3%83%AB%E3%83%8B%E3%83%A5%E3%83%BC%E3%82%B9%E3%82%92%E9%85%8D%E4%BF%A1%E3%81%95%E3%81%9B%E3%82%8B"
            target="_blank"
            rel="noopener noreferrer"
            >学習のヒント</a
          ><DgrIcon size="small" name="external-link" />
        </div>
        <div class="o-instruction c-text c-text--m">
          <span class="c-title">どうやって記事を探しますか？</span>
          目的にあった方法をご案内します。
        </div>
        <div class="o-actions c-text c-text--m">
          <button
            class="c-textLink c-textLink--underbar"
            @click="navigateToIndustry"
          >
            業界の情報を集めたい
          </button>
          <button
            class="c-textLink c-textLink--underbar"
            @click="navigateToSearch"
          >
            キーワードから探したい
          </button>
          <button
            class="c-textLink c-textLink--underbar"
            @click="navigateToLatestMarks"
          >
            みんなの注目から探したい
          </button>
        </div>
      </template>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.o-no-personal-feed {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background: white;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  max-width: 616px;
  padding: 14px 48px 20px 48px;

  &.o-no-personal-feed-preparing {
    padding: 20px 48px 20px 24px;
  }
  .o-feed-loading {
    height: 148px;
  }
  .o-explanation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 12px;
    &.o-explanation-preparing {
      padding: 0 0 12px 50px;
      img {
        width: 120px;
        height: 120px;
        margin-top: -18px;
      }
    }
    img {
      width: 100px;
      height: 100px;
      padding-right: 10px;
    }
    .o-description {
      .o-text-emphasis {
        color: #1da482;
      }
    }
  }
  &.o-no-personal-feed-preparing {
    .o-progress-bar-container,
    .o-progress-numbers {
      margin-left: 24px;
    }
  }
  .o-progress-bar-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    .o-progress-bar {
      display: inline-block;
      width: 51px;
      height: 4px;
      border-radius: 1px;
      background-image: linear-gradient(to right, #44b8a3 50%, #e6e6e6 50%);
      background-size: 200% 100%;
      background-position: right bottom;
      transition: all 0.5s ease-out;
    }
    .o-progress-point {
      display: inline-block;
      margin: 0 -6px 0 -6px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-image: linear-gradient(to right, #44b8a3 50%, #e6e6e6 50%);
      background-size: 200% 100%;
      background-position: right bottom;
      transition: all 0.1s ease-out 0.5s;
      z-index: 0;
    }
    .o-progress-filled {
      background-position: left bottom !important;
    }
  }
  .o-progress-number {
    display: inline-block;
    width: 0;
    overflow: visible;
    font-size: 12px;
    line-height: 16px;
    margin-left: 51px;
    &:first-child {
      margin-left: 48px;
    }
  }

  .o-hint {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    a {
      text-decoration: underline;
    }
  }

  .o-instruction {
    margin: 32px auto;
  }

  .o-actions {
    display: flex;
    justify-content: space-around;
    align-items: center;

    button {
      background: none;
      border: none;
      padding: 0;
    }
  }
}
</style>
