<script setup lang="ts">
import {
  computed,
  nextTick,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watch,
} from 'vue';
import api from '@/api';
import { DgrLoading } from '@stockmarkteam/donguri-ui';
import dayjs, { Dayjs } from 'dayjs';
import ArticlesDay, {
  DateArticles,
  FeedData,
  MultipleExecutionFeedData,
} from '@/components/personal-news/articles-day.vue';
import { Article } from '@/types';
import { formatDateForFeedLabel } from '@/utils/formatters';
import {
  getMorningFeedData,
  isAfternoonFeedEnabled,
  latestPersonalFeedDatetime,
  MULTI_EXECUTION_MAX_ARTICLE_COUNT,
} from '@/utils/personalNews';
import { useUserInfo } from '@/utils/swr';
import { useEmitter, useStore } from '@/utils/vue';

const store = useStore();
const emitter = useEmitter();
const { data: userInfo } = useUserInfo();

const feedType = computed(() => store.state.feedType.feedType);
const sentFirstPersonalNewsFeedAt = computed(() =>
  userInfo.value?.sent_first_personal_news_feed_at
    ? dayjs(userInfo.value?.sent_first_personal_news_feed_at)
    : undefined,
);

const feedLastUpdatedAt = computed(() =>
  latestPersonalFeedDatetime(sentFirstPersonalNewsFeedAt.value),
);

const isLoadingInitial = ref(true);

const getExecutionData = (
  feedType: 'domestic' | 'foreign',
  execution: 'morning' | 'afternoon',
  date: Dayjs,
): FeedData => {
  const load = async () => {
    const data = articlesByDate.find(d => d.date === date)?.[feedType].value[
      execution
    ];
    if (!data) return;

    data.isFetchingData = true;

    let dateParam = date;
    let executionParam = execution;
    if (execution === 'morning') {
      const morningFeedData = getMorningFeedData(
        date,
        sentFirstPersonalNewsFeedAt.value,
      );
      dateParam = morningFeedData.date;
      executionParam = morningFeedData.execution;
    }
    const response = await api.getPersonalNews(
      feedType,
      dateParam,
      1,
      MULTI_EXECUTION_MAX_ARTICLE_COUNT,
      executionParam,
    );
    data.articles = response.articles;
    data.isFetchingData = false;
    data.isLoaded = true;
  };

  return {
    articles: [] as Article[],
    isLoaded: false,
    isFetchingData: false,
    load: load,
  };
};

const getMultipleExecutionFeedData = (
  feedType: 'domestic' | 'foreign',
  date: Dayjs,
): MultipleExecutionFeedData => {
  return {
    morning: getExecutionData(feedType, 'morning', date),
    afternoon: isAfternoonFeedEnabled(date)
      ? getExecutionData(feedType, 'afternoon', date)
      : emptyFeedData(),
  };
};

const emptyFeedData = (): FeedData => ({
  articles: [] as Article[],
  isLoaded: true,
  isFetchingData: false,
  load: () => undefined,
});
const multipleExecutionFeedData = (): MultipleExecutionFeedData => ({
  morning: emptyFeedData(),
  afternoon: emptyFeedData(),
});

const articlesByDate: DateArticles[] = [] as DateArticles[];
const setArticlesForDate = (feedLastUpdatedAt: Dayjs, dayNumber: number) => {
  const date = feedLastUpdatedAt.subtract(dayNumber, 'days');

  const data: DateArticles = {
    date: date,
    dateStr: date.format('YYYY-MM-DD'),
    dateLabel: formatDateForFeedLabel(date, feedLastUpdatedAt),
    domestic: ref(
      ['all', 'domestic'].includes(feedType.value)
        ? getMultipleExecutionFeedData('domestic', date)
        : multipleExecutionFeedData(),
    ),
    foreign: ref(
      ['all', 'foreign'].includes(feedType.value)
        ? getMultipleExecutionFeedData('foreign', date)
        : multipleExecutionFeedData(),
    ),
  };
  articlesByDate[dayNumber] = data;
};

const shouldLoadDate = (date: Dayjs, data: DateArticles) =>
  isAfternoonFeedEnabled(date)
    ? data.domestic.value.morning.articles.length +
        data.domestic.value.afternoon.articles.length +
        data.foreign.value.morning.articles.length +
        data.foreign.value.afternoon.articles.length ===
      0
    : data.domestic.value.morning.articles.length +
        data.foreign.value.morning.articles.length ===
      0;

const loadDate = async (date: Dayjs, data: DateArticles) => {
  if (isAfternoonFeedEnabled(date)) {
    await Promise.all([
      data.domestic.value.morning.load(),
      data.foreign.value.morning.load(),
      data.domestic.value.afternoon.load(),
      data.foreign.value.afternoon.load(),
    ]);
  } else {
    await Promise.all([
      data.domestic.value.morning.load(),
      data.foreign.value.morning.load(),
    ]);
  }
};

let feedObserver: IntersectionObserver | undefined = undefined;
const feedRefs = ref<HTMLElement[] | undefined>();
const initializeFeedsObserver = () => {
  feedObserver?.disconnect();
  // スクロールしたら次の日のフィードが画面に表示する時にデータを取得する
  feedObserver = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach(entry => {
        if (entry.intersectionRatio > 0) {
          const dateStr = (entry.target as HTMLElement).dataset.dateStr;
          const date = dayjs(dateStr);
          const data = articlesByDate
            .slice(1)
            .find(d => d.date.isSame(date, 'day'));
          if (data && shouldLoadDate(date, data)) {
            loadDate(date, data);
          }

          observer.unobserve(entry.target);
        }
      });
    },
    { threshold: 0.1, rootMargin: '120px 0px 0px 0px' },
  );
  feedRefs.value?.forEach((el, i) => {
    if (i === 0) return;
    feedObserver?.observe(el);
  });
};

const initialize = async () => {
  isLoadingInitial.value = true;
  feedObserver?.disconnect();

  // デフォルトは7日間だが、初パーソナルニュースは１週間以内ならその日までにする
  const now = dayjs().set('hour', 12).utc();
  let dateCount = 7;
  if (sentFirstPersonalNewsFeedAt.value) {
    const firstPersonalFeed = sentFirstPersonalNewsFeedAt.value
      .set('hour', 4)
      .utc();
    dateCount = Math.min(7, now.diff(firstPersonalFeed, 'days') + 1);
  }
  if (dateCount > 0) {
    for (let i = 0; i < dateCount; i++) {
      setArticlesForDate(feedLastUpdatedAt.value, i);
    }

    await loadDate(articlesByDate[0].date, articlesByDate[0]);
  }

  isLoadingInitial.value = false;

  await nextTick();

  initializeFeedsObserver();
};

watch(
  () => feedType.value,
  async () => {
    removedArticles.items = [];
    await initialize();
  },
);

onMounted(async () => {
  await initialize();
  emitter.on('personal-feed-article-removed', removePersonalNewsArticle);
  emitter.on('personal-feed-article-restored', restorePersonalNewsArticle);
});

onUnmounted(() => {
  emitter.off('personal-feed-article-removed', removePersonalNewsArticle);
  emitter.off('personal-feed-article-restored', restorePersonalNewsArticle);
  feedObserver?.disconnect();
});

const removedArticles = reactive({ items: [] as number[] });

const removeArticle = ({ articleId }: { articleId: number }) => {
  removedArticles.items.push(articleId);
};
const restoreArticle = ({ articleId }: { articleId: number }) => {
  const removed = [...removedArticles.items];
  if (removed.includes(articleId)) {
    removedArticles.items = removed.filter(a => a !== articleId);
  }
};

const removePersonalNewsArticle = (article: Article) =>
  removeArticle({ articleId: article.id });
const restorePersonalNewsArticle = (article: Article) => {
  restoreArticle({ articleId: article.id });
};

const loadToDate = async (date: Dayjs) => {
  const notLoadedIndicies = articlesByDate
    .filter(
      d =>
        d.date.isAfter(date, 'day') &&
        (!d.domestic.value.morning.isLoaded ||
          !d.foreign.value.morning.isLoaded),
    )
    .map(d => articlesByDate.indexOf(d));
  for (let d of notLoadedIndicies) {
    await loadDate(date, articlesByDate[d]);
  }
};

const dateSelectRefs = ref<HTMLElement[] | undefined>();

const selectDate = async (dateStr: string) => {
  const targetElement = dateSelectRefs.value?.find(r => {
    return r.dataset.dateStr === dateStr;
  });
  if (!targetElement) return;

  await loadToDate(dayjs(dateStr));

  nextTick(() => {
    // FIXME: スクロールエリアをwindowではなくてこのコンポーネント内に
    //        閉じた上で、element.scrollIntoView()にしたい
    const headerOffset = 112;
    const dividerOffset = 16;
    const targetPosition =
      targetElement.getBoundingClientRect().top +
      window.scrollY -
      headerOffset -
      dividerOffset;
    window.scrollTo({ top: targetPosition, behavior: 'smooth' });
  });
};
</script>
<template>
  <div class="feed-articles-container">
    <div v-if="!isLoadingInitial" class="feed-articles">
      <div
        v-for="(data, i) in articlesByDate"
        ref="feedRefs"
        :data-date-str="data.dateStr"
        :key="data.dateLabel"
      >
        <div v-if="i === 1" class="past-news-title c-title c-title--xm">
          過去のニュース
        </div>
        <div
          ref="dateSelectRefs"
          :data-date-str="data.dateStr"
          class="articles"
        >
          <div>
            <ArticlesDay
              v-if="feedType === 'all' || feedType === 'domestic'"
              :articles-by-date="articlesByDate"
              :date="data.date"
              :date-str="data.dateStr"
              :date-label="data.dateLabel"
              :sent-first-personal-news-feed-at="sentFirstPersonalNewsFeedAt"
              :feed-data="data.domestic.value"
              :removed-article-ids="removedArticles.items"
              :display-feed-type="feedType"
              :theme-feed-type="'domestic'"
              @select-date="selectDate"
            />
          </div>

          <div>
            <ArticlesDay
              v-if="feedType === 'all' || feedType === 'foreign'"
              :articles-by-date="articlesByDate"
              :date="data.date"
              :date-str="data.dateStr"
              :date-label="data.dateLabel"
              :sent-first-personal-news-feed-at="sentFirstPersonalNewsFeedAt"
              :feed-data="data.foreign.value"
              :removed-article-ids="removedArticles.items"
              :display-feed-type="feedType"
              :theme-feed-type="'foreign'"
              @select-date="selectDate"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="isLoadingInitial" class="loading-container">
      <DgrLoading class="loading" />
    </div>
  </div>
</template>
<style lang="scss" scoped>
.feed-articles-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 540px;

  .title {
    display: flex;
    align-items: center;
    height: 24px;
    margin-bottom: 16px;

    .count {
      color: #b3b3b3;
    }
  }

  .divider {
    width: auto;
    border-bottom: 0.5px solid #e6e6e6;
    margin: 0px 16px 0px 16px;
  }

  .feed-articles {
    display: flex;
    flex-direction: column;
  }

  .date-divider {
    position: relative;
    top: 16px;
    width: 616px;
    height: 1px;
    background: #e6e6e6;
  }

  .date-select {
    position: sticky;
    top: 112px;
    z-index: var(--z-dropdown);
    display: flex;
    justify-content: center;
  }

  .article-card {
    margin-bottom: 8px;
  }

  .articles {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    .document-card {
      border: 0;
    }
  }

  .loading {
    align-self: center;
  }

  .loading-container {
    width: 616px;
    display: flex;
    flex-direction: column;
  }

  .past-news-title {
    margin-bottom: 24px;
  }
}
</style>
