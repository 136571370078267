<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import { NotificationCommentReactionType } from '@/types';
import { getArticlePageUrl } from '@/utils';
import CardTemplate from './card-template.vue';

// WARN: 通知の表記を変更するときは serviceWorkerHelper.ts の変更漏れがないかも確認する
// WARN: 通知のURLを変更するときは web-push.vue の変更漏れがないかも確認する

export default defineComponent({
  components: {
    CardTemplate,
  },
  props: {
    notification: {
      type: Object as PropType<NotificationCommentReactionType>,
      required: true,
    },
    isUnread: { type: Boolean, required: true },
  },
  setup(props) {
    const url = computed(() => {
      if (props.notification.comment)
        return props.notification.document
          ? getArticlePageUrl(
              props.notification.document,
              props.notification.comment?.id,
            )
          : `/comments/${props.notification.comment.id}`;
      else return undefined;
    });

    return {
      url,
    };
  },
});
</script>
<template>
  <CardTemplate
    :type="notification.type"
    :url="url"
    :is-unread="isUnread"
    :created-at="notification.created_at"
    :user-id="notification.creator_id"
    :image-url="notification.creator_image_url"
    :content-title="notification.document?.title"
    :preview="notification.comment?.content"
    :group-name="notification.group?.name"
    :reactions="notification.comment_reactions"
  >
    あなたのコメントに新しいリアクションがあります。
  </CardTemplate>
</template>
