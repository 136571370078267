<script setup lang="ts">
import { useRoute } from 'vue-router';
import api from '@/api';
import router from '@/routers/pcRouter';
import {
  buildPathToNotificationTarget,
  deserializePushedNotification,
  UNDEFINED_PATH,
} from '@/utils/webPush';

// なかった場合は一旦home画面に遷移させる。基本的にはならない想定

const route = useRoute();

async function main() {
  const notification = deserializePushedNotification(route.query);
  try {
    await api.webPushTrackEvent('web_notification_click', {
      notification: { id: notification.id, type: notification.type },
      user_agent: navigator.userAgent,
    });
    await api.readNotification(notification.id);
    await router.replace(buildPathToNotificationTarget(notification));
  } catch (error) {
    router.replace(UNDEFINED_PATH);
    if (error instanceof Error) throw error;
    throw new Error('fail web push click');
  }
}

main();
</script>

<template><div /></template>
