<script lang="ts">
import { defineComponent, PropType } from 'vue';
import TagItem from '@/components/common/atoms/tag.vue';

export default defineComponent({
  components: {
    TagItem,
  },
  props: {
    label: { type: String },
    selectedTags: { type: Array as PropType<string[]>, default: () => [] },
    isEditing: { type: Boolean, default: true },
    showTooltip: { type: Boolean, default: true },
    isPreventOverflow: { type: Boolean, default: true },
  },
  setup(_, context) {
    const deleteTag = (item: string) => {
      context.emit('deleteTag', item); // eslint-disable-line vue/require-explicit-emits
    };

    return {
      deleteTag,
    };
  },
});
</script>

<template>
  <div class="selected-tag-list-row">
    <span class="selected-tag-list-label c-text c-text--s">
      {{ label }}
    </span>
    <TagItem
      class="selected-tag-item"
      v-for="item in selectedTags"
      :key="item"
      :name="item"
      :is-editing="isEditing"
      :show-tooltip="showTooltip"
      :is-prevent-overflow="isPreventOverflow"
      @delete-tag="deleteTag(item)"
    ></TagItem>
  </div>
</template>

<!-- FIXME: 影響範囲を調べて scoped をつける -->
<!-- eslint-disable-next-line vue/enforce-style-attribute -->
<style lang="scss">
.selected-tag-list-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  row-gap: 8px;

  .selected-tag-list-label {
    color: $color-gray600;
  }

  .selected-tag-item {
    margin-left: 8px;
    height: 32px;
  }
}
</style>
