<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import api from '@/api';
import Content from '@/components/layouts/content.vue';
import Header from '@/components/layouts/header.vue';
import MyTagListTable from '@/components/user/my-tags/my-tag-list-table.vue';
import {
  MyTag,
  MyTagSortAttribute,
  MyTagSortOrder,
  MyTagSortTypes,
  MyTagWithCount,
  UserSettings,
} from '@/types';
import { mypageAccessScope as myPageAccessScopeUtil } from '@/utils/mypageAccessScope';
import {
  STATES,
  useMyTagTotalCount,
  useUserOrganizationTags,
} from '@/utils/swr';
import { getTabs } from '@/utils/user';
import { userSession } from '@/utils/userSession';
import { useEmitter } from '@/utils/vue';
import AddMyTagModal from './my-tags/add-my-tag-modal.vue';
import DeleteMyTagModal from './my-tags/delete-my-tag-modal.vue';
import EditMyTagModal from './my-tags/edit-my-tag-modal.vue';

const store = useStore();
const emitter = useEmitter();
const userId = computed(() => userSession.getUserId() ?? undefined);
const targetUserSettings = ref<UserSettings | undefined>(undefined);
const targetUserInfo = computed(() => store.state.userActions.targetUserInfo);
const { data: userOrganizationTags } = useUserOrganizationTags(userId);
const myPageAccessScope = computed(() => {
  if (!targetUserSettings.value) {
    return '';
  }

  return myPageAccessScopeUtil(targetUserSettings.value);
});
const route = useRoute();

const sortAttribute = ref<MyTagSortAttribute | undefined>(undefined);
const sortOrder = ref<MyTagSortOrder | undefined>(undefined);

const isOpenCreateMyTagModal = ref(false);
const isOpenEditMyTagModal = ref(false);
const isOpenDeleteMyTagModal = ref(false);
const modifiableMyTag = ref<{ id: number; name: string } | undefined>(
  undefined,
);

const sortType = computed<MyTagSortTypes | undefined>(() => {
  if (sortAttribute.value === undefined || sortOrder.value === undefined) {
    return undefined;
  }
  return `${sortAttribute.value}_${sortOrder.value}`;
});

const {
  data: myTagTotalCount,
  state: myTagTotalCountLoadingState,
  mutate: myTagTotalCountMutate,
} = useMyTagTotalCount(userId);

onMounted(() => {
  api.trackPageView({
    pageName: 'my_tags',
    pageUrl: route.path,
  });
});

const sortItems = (attribute: MyTagSortAttribute) => {
  if (sortAttribute.value === attribute) {
    sortOrder.value = sortOrder.value === 'asc' ? 'desc' : 'asc';
  } else {
    sortAttribute.value = attribute;
    sortOrder.value = 'asc';
  }
};

const openCreateTagModal = () => {
  isOpenCreateMyTagModal.value = true;
};
const closeCreateTagModal = () => {
  isOpenCreateMyTagModal.value = false;
};

const openEditTagModal = ({
  myTagId,
  tagName,
}: {
  myTagId: number;
  tagName: string;
}) => {
  modifiableMyTag.value = { id: myTagId, name: tagName };
  isOpenEditMyTagModal.value = true;
};
const closeEditTagModal = () => {
  isOpenEditMyTagModal.value = false;
};

const openDeleteTagModal = ({
  myTagId,
  tagName,
}: {
  myTagId: number;
  tagName: string;
}) => {
  modifiableMyTag.value = { id: myTagId, name: tagName };
  isOpenDeleteMyTagModal.value = true;
};
const closeDeleteTagModal = () => {
  isOpenDeleteMyTagModal.value = false;
};

const submitCreateTagModal = ({ myTag }: { myTag: MyTag }) => {
  emitter.emit('pagination-item-create', {
    ...myTag,
    total_count: 0,
  });
  closeCreateTagModal();
  myTagTotalCountMutate();
};

const submitEditTagModal = ({
  myTagId,
  tagName,
}: {
  myTagId: number;
  tagName: string;
}) => {
  emitter.emit('pagination-items-update', {
    filterFunc: (_: MyTagWithCount) => true,
    updateFunc: (myTags: MyTagWithCount[]) => {
      myTags.forEach(tag => {
        if (tag.id === myTagId) {
          tag.name = tagName;
        }
      });
    },
  });
  closeEditTagModal();
};
const submitDeleteTagModal = ({ myTagId }: { myTagId: number }) => {
  emitter.emit('pagination-item-delete', (items: MyTagWithCount[]) => {
    const index = items.findIndex(c => c.id === myTagId);
    if (index >= 0) {
      items.splice(index, 1);
    }
  });
  myTagTotalCountMutate();
  closeDeleteTagModal();
};

store.dispatch('userActions/fetchTargetUserInfo', userId.value);
</script>

<template>
  <div class="my-tag-view">
    <Header
      :title="targetUserInfo.user_name"
      :detail="`${targetUserInfo.email} ${myPageAccessScope}`"
      :avatar-image-url="targetUserInfo.image_url"
      :tabs="getTabs()"
      :organization-tag-list="userOrganizationTags?.organization_tags"
    ></Header>
    <Content>
      <div class="tag-list-content-header">
        <div class="title">
          <span class="c-title c-title--m">マイタグ</span>
          <span
            v-if="
              myTagTotalCountLoadingState === STATES.PENDING ||
              myTagTotalCountLoadingState === STATES.VALIDATING ||
              myTagTotalCount?.my_tag_count === undefined
            "
            class="count c-text c-text--s"
            >・- 件</span
          >
          <span v-else class="count c-text c-text--s"
            >・{{ myTagTotalCount.my_tag_count }}件</span
          >
        </div>
        <div>
          <button
            class="my-tag-add-button o-create-button c-btn c-btn--auto c-btn--AnewsPrimary"
            @click="openCreateTagModal"
          >
            作成
          </button>
        </div>
      </div>
      <MyTagListTable
        :sort-type="sortType"
        @open-edit-tag-modal="openEditTagModal"
        @open-delete-tag-modal="openDeleteTagModal"
        @sort-items="sortItems"
      />
    </Content>
    <AddMyTagModal
      :is-open="isOpenCreateMyTagModal"
      @close="closeCreateTagModal"
      @submit="submitCreateTagModal"
    />
    <EditMyTagModal
      v-if="modifiableMyTag"
      :is-open="isOpenEditMyTagModal"
      :my-tag-id="modifiableMyTag.id"
      :my-tag-name="modifiableMyTag.name"
      @close="closeEditTagModal"
      @submit="submitEditTagModal"
    />
    <DeleteMyTagModal
      v-if="modifiableMyTag"
      :is-open="isOpenDeleteMyTagModal"
      :my-tag-id="modifiableMyTag.id"
      :my-tag-name="modifiableMyTag.name"
      @close="closeDeleteTagModal"
      @submit="submitDeleteTagModal"
    />
  </div>
</template>

<style lang="scss" scoped>
.my-tag-view {
  width: 100%;
  margin: -24px 0 0 0;
}

.c-btn {
  height: fit-content;
  padding: 6px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.count {
  color: $color-gray600;
}

.tag-list-content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 21px;
}
</style>
