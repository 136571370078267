<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
  Ref,
  ref,
  UnwrapRef,
  watch,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import api from '@/api';
import { DgrSelectbox } from '@stockmarkteam/donguri-ui';
import { AxiosError } from 'axios';
import AdpDocumentCard from '@/components/common/adp-document-card.vue';
import AdpDocumentExport from '@/components/common/adp-document/adp-document-export.vue';
import AdpDocumentInfoDefault from '@/components/common/adp-document/adp-document-info-default.vue';
import AdpDocumentInfo from '@/components/common/adp-document/adp-document-info.vue';
import SelectedTagList from '@/components/common/multi-select/selected-tag-list.vue';
import TagSelect from '@/components/common/multi-select/tag-select.vue';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import SwrScrollingPagination from '@/components/common/swr-scrolling-pagination.vue';
import GroupHeader from '@/components/group/group-header.vue';
import Content from '@/components/layouts/content.vue';
import {
  AdpDocument,
  ArticleUpdateOption,
  isDocType,
  TagSelectItem,
} from '@/types';
import { formatDate } from '@/utils/formatters';
import { getTabs } from '@/utils/group';
import { urlParse } from '@/utils/parsers';
import { ContentPosition, syncContentScrollWithViewport } from '@/utils/scroll';
import { selectArticle } from '@/utils/selectArticle';
import {
  STATES,
  useDocument,
  useGroupMarks,
  useGroupMarksCount,
  useGroups,
  useGroupTags,
  useTeamUsers,
  useUserInfo,
} from '@/utils/swr';
import { useEmitter, useStore } from '@/utils/vue';

const CONTENT_HEADER_HEIGHT = 160;

export default defineComponent({
  components: {
    GroupHeader,
    AdpDocumentCard,
    Content,
    SwrScrollingPagination,
    AdpDocumentInfo,
    AdpDocumentInfoDefault,
    AdpDocumentExport,
    TagSelect,
    SelectedTagList,
    DgrSelectbox,
  },
  props: {
    isMenuVisible: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const emitter = useEmitter();
    const store = useStore();
    const { createSnackbar } = useSnackbar();

    const { data: groups, mutate, state } = useGroups();
    const { data: myInfo } = useUserInfo();

    const selectedArticles = reactive({ items: [] as AdpDocument[] });

    const memoExists = ref<string>(
      (route.query.memo_exists as string) ?? 'all',
    );
    const selectMemoOptions = [
      { value: 'all', label: 'すべての記事' },
      { value: 'true', label: 'グループメモした記事' },
      { value: 'false', label: 'グループメモしていない記事' },
    ];
    const targetTags = ref({
      items: [] as string[],
    });

    const groupId = computed(() => {
      if (route.params.groupId instanceof Array) {
        return route.params.groupId[0];
      }
      return route.params.groupId;
    });
    const hasEditInfoPermission = computed(() =>
      myInfo.value !== undefined || group.value !== undefined
        ? myInfo.value!.role === 'admin' || group.value!.is_member
        : false,
    );
    const { data: groupMarkArticlesAndCount, mutate: mutateGroupMarks } =
      useGroupMarksCount(groupId, memoExists, targetTags);
    const markCount = computed(() => groupMarkArticlesAndCount.value?.count);

    const docType = computed(() => {
      let docType = null;
      if (route.query.doc_type instanceof Array) {
        docType = route.query.doc_type[0];
      } else {
        docType = route.query.doc_type;
      }
      if (isDocType(docType)) {
        return docType;
      }
      return null;
    });
    const documentId = computed<string | null>(() => {
      if (route.query.document_id instanceof Array) {
        return route.query.document_id[0];
      }
      return route.query.document_id;
    });
    const isShowingSpecificDocument = computed(
      () => docType.value && documentId.value,
    );
    const documentOption = computed(() => ({
      group_id_for_memo_and_tag: Number(groupId.value),
    }));
    const { data: documentData } = useDocument(
      documentId,
      docType,
      documentOption,
    );

    const { data: tagsData, mutate: mutateTags } = useGroupTags(groupId.value);
    const tags = computed<{ tags: TagSelectItem[] }>(() => ({
      tags: tagsData.value?.tags.map(t => ({ ...t, id: t.name })) ?? [],
    }));

    const group = computed(() =>
      (groups.value ?? { groups: [] }).groups.find(
        g => g.id === Number(groupId.value),
      ),
    );

    const trackPageView = async () => {
      const previousRoute = store.state.route.previousRoute;
      const hasPreviousRoute = !!previousRoute?.name;
      const isSnackbar =
        hasPreviousRoute &&
        String(previousRoute.params.groupId) !== String(groupId.value);
      await api.trackPageView({
        pageName: 'group',
        pageUrl: route.fullPath,
        group: {
          id: Number(route.params.groupId),
        },
        fromUrl: previousRoute?.path ?? null,
        fromSnackbarLink: isSnackbar ? 'article_group_mark' : undefined,
      });
    };

    const initialize = () => {
      if (validateGroup()) {
        trackPageView();
        if (group.value?.is_member) {
          store.commit('modal/setGroup', group.value);
        }
      }
    };

    let resizeObserver: ResizeObserver | undefined = undefined;

    const handleUpdate = (article: AdpDocument) => {
      emitter.emit('pagination-items-update', {
        filterFunc: (i: AdpDocument) => articleFilter(i, article.id),
        updateFunc: (items: AdpDocument[]) => updateArticle(items, article),
      });
    };

    onMounted(() => {
      initialize();
      window.addEventListener('scroll', handleScroll);
      window.addEventListener('resize', handleResize);
      handleScroll();
      if (rightContent.value) {
        resizeObserver = new ResizeObserver(() => handleResize());
        resizeObserver.observe(rightContent.value);
      }
      emitter.on('article-updated', handleUpdate);
    });

    onUnmounted(() => {
      store.commit('modal/setGroup', undefined);
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
      resizeObserver?.disconnect();
      emitter.off('article-updated', handleUpdate);
    });

    watch(
      () => groupId.value,
      () => initialize(),
    );

    watch(
      () => state.value,
      () => {
        if (state.value === STATES.SUCCESS) {
          initialize();
        }
      },
    );

    watch(
      () => route.query,
      () => {
        selectedArticles.items = [];
      },
    );

    const validateGroup = () => {
      // グループから他のページに移動したらバリデーションが必要ない
      if (groupId.value === undefined) return false;

      if (state.value === STATES.SUCCESS && group.value === undefined) {
        createSnackbar({
          message: '指定されたグループは存在しません',
          type: 'error',
        });
        router.push({ name: 'anewsHome' });
        return false;
      }
      return true;
    };

    const isSelected = (item: AdpDocument) => {
      return selectedArticles.items.map(i => i.id).includes(item.id);
    };

    const enableHover = (article: AdpDocument): boolean | undefined => {
      return !isSelected(article);
    };

    const toggleSelected = (e: MouseEvent, item: AdpDocument) => {
      selectedArticles.items = selectArticle(e, item, selectedArticles.items);
    };

    const articleFilter = (article: AdpDocument, id: AdpDocument['id']) => {
      return article.id === id;
    };

    const updateArticle = (
      articles: AdpDocument[],
      updatedArticle: AdpDocument,
    ) => {
      const index = articles.findIndex(c => {
        return c.id === updatedArticle.id;
      });
      if (index >= 0) {
        articles[index].marks = updatedArticle.marks;
        articles[index].memo = updatedArticle.memo;
        articles[index].tags = updatedArticle.tags;
      }
      return articles;
    };

    const updateArticleInfo = async (
      article: AdpDocument,
      updateOptions: ArticleUpdateOption,
    ) => {
      const groupIdNum = Number(groupId.value);
      try {
        if (groupIdNum) {
          if (updateOptions.shouldUpdateMemo) {
            if (article.memo) {
              await api.updateGroupArticleMemo(
                groupIdNum,
                article.id,
                article.doc_type,
                article.memo,
                article.lang,
              );
            } else {
              await api
                .deleteGroupArticleMemo(
                  groupIdNum,
                  article.id,
                  article.doc_type,
                  article.lang,
                )
                .catch((err: AxiosError) => {
                  if (err.response?.status !== 404) {
                    throw err;
                  }
                });
            }
          }
          if (updateOptions.shouldUpdateTags) {
            await api.updateGroupArticleTags(
              groupIdNum,
              article.id,
              article.doc_type,
              article.tags || [],
              article.lang,
            );
          }
          selectedArticles.items = selectedArticles.items.map(
            selectedArticle =>
              selectedArticle.id === article.id ? article : selectedArticle,
          );
          await api.trackEvent(
            'set_article_option',
            {
              pageName: 'group',
              pageUrl: route.fullPath,
              feature: 'group_marks',
            },
            article.id,
            groupIdNum,
            {
              tags: article.tags,
              memo: article.memo,
            },
          );
          mutateTags();
          emitter.emit('pagination-items-update', {
            filterFunc: (i: AdpDocument) => articleFilter(i, article.id),
            updateFunc: (items: AdpDocument[]) => updateArticle(items, article),
          });
          emitter.emit('article-updated', article);
          createSnackbar({
            message: '記事情報を更新しました',
            type: 'success',
          });
        }
      } catch {
        createSnackbar({
          message: '記事情報を更新できませんでした',
          type: 'error',
        });
      }
    };

    const deleteArticle = (articles: AdpDocument[], id: AdpDocument['id']) => {
      const index = articles.findIndex(c => c.id === id);
      if (index >= 0) {
        articles.splice(index, 1);
      }
    };

    const removeGroupMark = async (article: AdpDocument) => {
      try {
        const groupMark = article.group_marks?.filter(
          gm => gm.group_id == group.value?.id,
        )[0].id;
        if (groupMark) {
          await api.deleteGroupMark(groupMark);
          mutateGroupMarks();
          mutateTags();
          emitter.emit('pagination-item-delete', (items: AdpDocument[]) =>
            deleteArticle(items, article.id),
          );
          createSnackbar({
            message: 'グループマークを外しました',
            type: 'success',
          });
        }
      } catch {
        createSnackbar({
          message: 'グループマークを外せませんでした',
          type: 'error',
        });
      }
    };

    const handleTagFilterArticles = (tags: string[]) => {
      targetTags.value.items = tags;
    };

    const removeTag = (tagName: string) => {
      targetTags.value.items = targetTags.value.items.filter(
        t => t !== tagName,
      );
    };

    watch(
      () => [targetTags.value.items, memoExists.value],
      async () => {
        const memoFilterValue =
          memoExists.value === 'true'
            ? 'with_memo'
            : memoExists.value === 'false'
              ? 'without_memo'
              : 'all';
        await api.trackEvent(
          'mark_filter',
          {
            pageName: 'group',
            pageUrl: route.fullPath,
            feature: 'group_marks',
          },
          undefined,
          Number(groupId.value),
          {
            filter: { tags: targetTags.value.items, memo: memoFilterValue },
          },
        );
      },
    );

    const getGroupMark = (adpDocument: AdpDocument) => {
      return adpDocument.group_marks?.find(
        gm => gm.group_id == group.value?.id,
      );
    };

    const getGroupMarkThemeId = (adpDocument: AdpDocument) => {
      return getGroupMark(adpDocument)?.theme_id;
    };

    const getGroupMarkCreatorId = (adpDocument: AdpDocument) => {
      return getGroupMark(adpDocument)?.creator_id;
    };

    const { data: users } = useTeamUsers();

    const getSelectedArticleGroupMarkUser = computed(() => {
      return users.value?.users.find(
        u => u.id === getGroupMark(selectedArticles.items[0])?.creator_id,
      );
    });

    const rightColumn = ref<HTMLElement | undefined>();
    const rightContent = ref<HTMLElement | undefined>();
    let prevWindowScrollY = 0;
    let contentPosition: ContentPosition = 'top';
    const handleResize = (event?: Event) => handleScroll(event, true);
    const handleScroll = (_event?: Event, reset = false): void => {
      if (!rightColumn.value || !rightContent.value) return;
      const data = syncContentScrollWithViewport(
        rightContent.value,
        rightColumn.value,
        prevWindowScrollY,
        contentPosition,
        CONTENT_HEADER_HEIGHT,
        0,
        reset,
      );
      if (data) {
        prevWindowScrollY = data.windowScrollY;
        contentPosition = data.contentPosition;
      }
    };

    return {
      group,
      mutate,
      urlParse,
      formatDate,
      myInfo,
      hasEditInfoPermission,
      pageLimit: 10,
      paginationFunc: (pageRef: Ref<number>, pageLimit: number) =>
        useGroupMarks(groupId, pageRef, pageLimit, memoExists, targetTags),
      dataAccessor: (d: UnwrapRef<ReturnType<typeof useGroupMarks>['data']>) =>
        d?.marked_articles,
      getTabs,
      isSelected,
      enableHover,
      toggleSelected,
      selectedArticles,
      removeGroupMark,
      tags,
      updateArticleInfo,
      markCount,
      targetTags,
      handleTagFilterArticles,
      memoExists,
      selectMemoOptions,
      removeTag,
      getGroupMark,
      getSelectedArticleGroupMarkUser,
      getGroupMarkThemeId,
      getGroupMarkCreatorId,
      rightColumn,
      rightContent,
      isShowingSpecificDocument,
      documentId,
      documentData,
    };
  },
});
</script>

<template>
  <div class="group" v-if="group && myInfo">
    <GroupHeader
      :group="group"
      :user-info="myInfo"
      :is-menu-visible="isMenuVisible"
    />
    <Content>
      <div class="group-content">
        <div class="action-bar">
          <div class="left-side-container">
            <template v-if="!isShowingSpecificDocument">
              <span class="c-title c-title--m"
                >グループマーク<span class="c-text c-text--s" :key="markCount"
                  >・ {{ markCount }}件</span
                ></span
              >
              <DgrSelectbox
                class="o-memo-filter"
                v-model="memoExists"
                :options="selectMemoOptions"
                size="small"
              ></DgrSelectbox>
              <div class="spacing-16"></div>
              <div class="tag-box">
                <TagSelect
                  v-if="tags"
                  :tag-list="tags.tags"
                  :target-tags="targetTags.items"
                  @filter-articles="handleTagFilterArticles"
                  :empty-filter-message="'指定したグループタグはありません。'"
                  :placeholder="`グループタグ ${
                    targetTags.items.length > 0 ? targetTags.items.length : ''
                  }`"
                ></TagSelect>
              </div>
            </template>
          </div>
          <AdpDocumentExport
            class="export-button"
            v-if="selectedArticles.items.length > 0"
            :adp-documents="selectedArticles.items"
            page-name="group"
            :group-id="group.id"
          ></AdpDocumentExport>
        </div>
        <SelectedTagList
          class="selected-tags"
          v-if="targetTags.items.length > 0"
          label="選択中のグループタグ"
          :selected-tags="targetTags.items"
          :is-editing="true"
          :show-tooltip="true"
          @delete-tag="removeTag"
        ></SelectedTagList>
        <div class="spacing-16"></div>
        <div class="mark-page-content">
          <div class="article-area">
            <AdpDocumentCard
              v-if="isShowingSpecificDocument && documentData"
              class="article-card"
              :key="documentId ?? undefined"
              page-name="group"
              :adp-document="documentData"
              :group="group"
              :theme-id="getGroupMarkThemeId(documentData)"
              :feature="'group_marks'"
              :show-comment="false"
              :is-selected="isSelected(documentData)"
              :enable-hover="enableHover(documentData)"
              @click="toggleSelected($event, documentData)"
              @click-remove-group-mark="removeGroupMark(documentData)"
              :is-use-remove-group-mark="true"
            ></AdpDocumentCard>
            <SwrScrollingPagination
              v-else-if="!isShowingSpecificDocument"
              :key="`${[memoExists, targetTags.items]}`"
              :page-limit="pageLimit"
              :pagination-func="paginationFunc"
              :data-accessor="dataAccessor"
            >
              <template v-slot="{ items, loaded }">
                <AdpDocumentCard
                  class="article-card"
                  v-for="article in items"
                  :key="article.id"
                  page-name="group"
                  :adp-document="article"
                  :group="group"
                  :theme-id="getGroupMarkThemeId(article)"
                  :feature="'group_marks'"
                  :show-comment="false"
                  :is-selected="isSelected(article)"
                  :enable-hover="enableHover(article)"
                  @click="toggleSelected($event, article)"
                  @click-remove-group-mark="removeGroupMark(article)"
                  :is-use-remove-group-mark="true"
                ></AdpDocumentCard>
                <div class="no-marks" v-if="loaded && items.length === 0">
                  <div class="content">
                    <div class="c-text c-text--m">
                      {{
                        markCount === 0
                          ? '該当する記事はありませんでした。'
                          : 'お探しの条件に合う記事がありません'
                      }}
                    </div>
                  </div>
                  <img src="@/assets/state-empty-article.png" />
                </div>
              </template>
            </SwrScrollingPagination>
          </div>
          <div class="side-content" ref="rightColumn">
            <div class="side-content-body" ref="rightContent">
              <AdpDocumentInfo
                class="article-info"
                v-if="selectedArticles.items.length === 1"
                :editable="hasEditInfoPermission"
                :adp-document="selectedArticles.items[0]"
                :creator="getSelectedArticleGroupMarkUser"
                :tag-list="tags.tags"
                @article-info-updated="updateArticleInfo"
                :is-group-view="true"
              ></AdpDocumentInfo>
              <AdpDocumentInfoDefault
                :is-group-view="true"
                v-else
              ></AdpDocumentInfoDefault>
            </div>
          </div>
        </div>
      </div>
    </Content>
  </div>
</template>

<style lang="scss" scoped>
.group {
  width: 100%;
  margin: -24px 0 0 0;

  .export-button {
    width: 200px;
  }

  .edit-link {
    margin-left: 12px;
    padding: 4px 8px;
    border-radius: 4px;
    color: #1da482;

    &:hover {
      background: #f2f2f2;
    }
  }

  .group-content {
    display: flex;
    flex-direction: column;

    .mark-page-content {
      display: flex;
    }

    .action-bar {
      display: flex;
      align-items: center;
      width: 920px;
      height: 32px;
      margin-bottom: 16px;
      justify-content: space-between;

      .left-side-container {
        display: flex;
        align-items: center;

        .c-text--s {
          color: #b3b3b3;
        }

        .o-memo-filter {
          margin-left: 16px;
        }
      }

      .c-text--s {
        color: #b3b3b3;
      }
    }

    .article-area {
      width: 616px;
      margin-right: 16px;

      .article-card {
        margin-bottom: 12px;
        cursor: pointer;
      }
    }

    .article-info {
      width: 288px;
      margin-bottom: 16px;
    }

    .no-marks {
      display: flex;
      padding: 8px 32px;
      background-color: #fff;
      border: 1px solid #e6e6e6;
      border-radius: 4px;
      justify-content: space-between;

      .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      img {
        width: 100px;
        height: 100px;
      }
    }

    .description-container {
      width: 288px;

      .inner {
        background-color: #fff;
        border: 1px solid #e6e6e6;
        padding: 16px;
        border-radius: 4px;
        margin-bottom: 12px;

        & > *:not(:last-child) {
          margin-bottom: 8px;
        }

        .description {
          word-wrap: break-word;
          white-space: pre-wrap;
        }

        .no-description {
          color: #b3b3b3;
        }
      }
    }

    .o-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .member-list-link {
        color: #1da482;
        padding: 4px 8px;

        &:hover {
          background-color: #f2f2f2;
          border-radius: 4px;
          cursor: pointer;
        }
      }
    }
  }

  .side-content {
    position: relative;
    box-sizing: border-box;
    width: 288px;

    .side-content-body {
      padding-bottom: 80px;
      width: 288px;
    }
  }

  .selected-tags {
    width: 920px;
  }
}
</style>
@/utils/composables/useSelectMemos
