<script lang="ts">
import { defineComponent, ref } from 'vue';
import { DgrIcon } from '@stockmarkteam/donguri-ui';

export default defineComponent({
  components: {
    DgrIcon,
  },
  props: { text: { type: String, required: true } },
  setup() {
    const showTooltip = ref(false);

    return {
      showTooltip,
    };
  },
});
</script>
<template>
  <div class="question-tooltip">
    <div class="icon">
      <DgrIcon
        size="small"
        name="question-circle"
        v-tooltip.top="{
          content: text,
          triggers: ['click'],
          autoHide: true,
        }"
      />
    </div>
  </div>
</template>
<style lang="scss" scoped>
.question-tooltip {
  .icon {
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
