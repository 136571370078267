<script lang="ts" setup>
import { computed } from 'vue';

const emit = defineEmits(['click-tab']);

const props = defineProps<{
  text: string;
  isSelected: boolean;
  // TODO: Header改修の際に下記optional propsを削除する
  count?: number;
  hasNotification?: boolean;
}>();

const padding = computed(() =>
  props.hasNotification === undefined ? '6px 10px' : '6px 20px',
);
</script>

<template>
  <div class="m-text-tab-item" @click="emit('click-tab')">
    <div
      class="m-text-tab-item-body c-title c-title--m"
      :class="{ 'm-selected-tab-item': isSelected }"
    >
      {{ text }}
      <span v-if="count !== undefined" class="count c-text c-text--xs">{{
        count
      }}</span>
      <div class="notification-mark" v-if="hasNotification"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.m-text-tab-item-body {
  position: relative;
  padding: v-bind(padding);
  height: 26px;
  min-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid #ffffff;
  color: $color-gray800;
  &:hover {
    border-bottom: 2px solid $color-gray200;
    background: $color-gray200;
    cursor: pointer;
  }
  .count {
    color: $color-gray800;
    margin-left: 8px;
  }
  .notification-mark {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 6px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: $color-orange800;
  }
}
.m-selected-tab-item {
  color: $color-green600;
  border-bottom: 2px solid $color-green600;
  &:hover {
    background: none;
    border-bottom: 2px solid $color-green600;
  }
  .count {
    color: $color-green600;
  }
}
</style>
