<script lang="ts">
import { defineComponent, reactive, ref } from 'vue';
import api from '@/api';
import noImage1to1 from '@/assets/noimage-1to1.jpg';
import { MAX_ARTICLE_MEMO_LENGTH } from '@/constants';
import AdpDocumentCard from '@/components/common/adp-document-card.vue';
import InputTag from '@/components/common/atoms/input-tag.vue';
import TextCounter from '@/components/common/molecules/text-counter.vue';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import { useCurrentRoutePath } from '@/utils/composables/useCurrentRoutePath';
import { useUserInfo, useUserTags } from '@/utils/swr';
import { useStore } from '@/utils/vue';

export default defineComponent({
  components: {
    InputTag,
    AdpDocumentCard,
    TextCounter,
  },
  setup() {
    const store = useStore();
    const { createSnackbar } = useSnackbar();
    const { getCurrentRoutePath } = useCurrentRoutePath();
    const { data: userInfo } = useUserInfo();
    // HACK: モーダルを開いている時点でmodal.article, modal.theme, modal.pageNameに値は入っているため、undefinedになる場合を考慮しない。要リファクタリング
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const article = store.state.modal.article!;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const theme = store.state.modal.theme!;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const pageName = store.state.modal.pageName!;
    const feature = store.state.modal.feature;
    const feedType = store.state.modal.feedType;
    const markSource = store.state.modal.markSource;
    const rankInWholeFeed = store.state.modal.rankInWholeFeed;
    const searchData = store.state.modal.searchData;
    const contentsContext = store.state.modal.contentsContext;
    const execution = store.state.modal.execution;

    const noImage = ref(noImage1to1);
    const { data: tags } = useUserTags();
    const selectedTags = reactive({ list: [] as string[] });
    const editingMemo = ref('');
    const isMarkedGroup = ref(false);

    const hideDialog = () => {
      store.commit('modal/hideModal');
    };

    const submit = async () => {
      try {
        const createdMark = await api.sendMark(
          article,
          theme?.id,
          markSource,
          {
            pageName: pageName,
            pageUrl: getCurrentRoutePath(),
            feature: feature,
            feedType: feedType,
          },
          rankInWholeFeed,
          searchData,
          undefined,
          undefined,
          contentsContext,
          execution,
        );
        if (selectedTags.list.length > 0) {
          await api.updateUserArticleTags(
            article.id,
            article.doc_type,
            selectedTags.list,
            article.lang,
          );
        }
        if (editingMemo.value !== '') {
          await api.updateUserArticleMemo(
            article.id,
            article.doc_type,
            editingMemo.value,
            article.lang,
          );
        }

        await api.trackEvent(
          'set_article_option',
          {
            pageName: pageName,
            pageUrl: getCurrentRoutePath(),
            feature: feature,
          },
          article.id,
          undefined,
          {
            tags: selectedTags.list,
            memo: editingMemo.value,
          },
        );

        article.marks.push({
          ...createdMark,
          user_name: userInfo.value?.user_name ?? '',
        });
        createSnackbar({
          type: 'link',
          message: 'マイページ',
          params: {
            link: `/users/${userInfo.value?.id}`,
            suffixText: 'にマークしました',
          },
        });
      } catch {
        createSnackbar({
          message: 'マークできませんでした',
          type: 'error',
        });
      }
      hideDialog();
    };

    return {
      userInfo,
      article,
      noImage,
      tags,
      selectedTags,
      editingMemo,
      isMarkedGroup,
      hideDialog,
      submit,
      theme,
      pageName,
      feature,
      MAX_ARTICLE_MEMO_LENGTH,
    };
  },
});
</script>

<template>
  <div
    class="o-dialog-group-mark"
    v-if="userInfo && userInfo.id && tags && tags.user_tags"
  >
    <sm-dialog class="auto-height auto-width screen-center" @close="hideDialog">
      <template #header>
        <div class="o-header c-text c-text--m">マーク</div>
      </template>
      <template #body>
        <div class="o-body c-text c-text--m">
          <AdpDocumentCard
            :adp-document="article"
            :theme-id="theme ? theme.id : undefined"
            :show-comment="false"
            :show-user-action="false"
            :page-name="pageName"
            :feature="feature"
          ></AdpDocumentCard>
          <div class="spacing-08"></div>
          <InputTag
            :tag-list="tags.user_tags"
            v-model="selectedTags.list"
            placeholder="マイタグを入力後、改行でマイタグを追加"
            :is-editing="true"
            :is-group="false"
          ></InputTag>
          <div class="c-formBlock">
            <div class="c-formBlock__label">マイメモ</div>
            <textarea
              class="o-article-memo c-textArea"
              v-model.trim="editingMemo"
              :maxlength="MAX_ARTICLE_MEMO_LENGTH"
              placeholder="マイメモを入力"
              @keydown.ctrl.enter="submit"
              @keydown.meta.enter="submit"
            ></textarea>
            <TextCounter
              :text="editingMemo"
              :max-length="MAX_ARTICLE_MEMO_LENGTH"
            />
          </div>
        </div>
      </template>
      <template #footer>
        <div class="o-footer c-text c-text--m">
          <button
            class="o-create-button c-btn c-btn--auto c-btn--AnewsPrimary"
            :class="{ disabled: isMarkedGroup }"
            :disabled="isMarkedGroup"
            @click="submit"
          >
            マーク
          </button>
        </div>
      </template>
    </sm-dialog>
  </div>
</template>
<style lang="scss" scoped>
.o-dialog-group-mark {
  .o-article {
    display: flex;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #e6e6e6;
    .o-article-info {
      width: 484px;
      padding: 16px;
      box-sizing: border-box;
      .o-article-info__title {
        font-family: Noto Sans CJK JP;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
      }
      .o-article-info__media {
        color: #b3b3b3;
      }
    }
    .o-article-image {
      width: 100px;
      height: 100px;
      object-fit: cover;
      object-position: 50% 25%;
      border-left: 1px solid #e6e6e6;
      border-radius: 0 3px 3px 0;
    }
  }
  .o-article-memo {
    height: 140px;
    resize: none;
    outline: none;
  }
}
textarea::placeholder {
  color: #b3b3b3;
}

.o-body {
  width: 616px;
}
.o-footer {
  margin-top: -16px;
}
</style>
