<script setup lang="ts">
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { DgrIcon } from '@stockmarkteam/donguri-ui';
import { Group } from '@/types';
import { hasEditPermission } from '@/utils/group';
import { useUserInfo } from '@/utils/swr';

interface Props {
  group: Group;
}

const props = defineProps<Props>();

const router = useRouter();
const route = useRoute();
const { data: userInfo } = useUserInfo();

const navigateToGroupEdit = () => {
  router.push({ name: 'groupEdit', params: { groupId: props.group?.id } });
};

const isEditingGroup = computed(() => {
  return route.name === 'groupEdit';
});

const isSearchingGroup = computed(() => {
  return route.name === 'groupList';
});

const showEditButton = computed(() => {
  return (
    props.group.group_type === 'specific_user_group' &&
    hasEditPermission(props.group, userInfo.value) &&
    // グループ編集画面、グループ検索画面には編集ボタンを表示しない
    !isEditingGroup.value &&
    !isSearchingGroup.value
  );
});
</script>

<template>
  <button
    class="group-edit-btn c-btn c-btn--small"
    v-if="showEditButton"
    @click.prevent="() => navigateToGroupEdit()"
  >
    <DgrIcon
      size="small"
      name="pencil"
      class="pencil-icon"
      :keep-fill="false"
    />
    編集
  </button>
</template>

<style lang="scss" scoped>
.group-edit-btn {
  color: $color-green600;
  border-color: $color-green600;
  width: fit-content;
  cursor: pointer;
  &:hover {
    background-color: $color-green100;
  }
  .pencil-icon {
    fill: currentColor;
  }
}
</style>
