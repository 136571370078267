<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { DgrIcon } from '@stockmarkteam/donguri-ui';
import PasswordInput from '@/components/common/atoms/password-input.vue';
import {
  isPasswordContentValid,
  isPasswordLengthValid,
  isPasswordOnlyValidCharacters,
  isPasswordValid,
  MIN_PASSWORD_LENGTH,
  NOT_USING_REQUIRED_TYPE_CHARACTERS_MESSAGE,
  TOO_SHORT_PASSWORD_MESSAGE,
  USING_INVALID_CHARACTER_MESSAGE,
  VALID_SYMBOLS,
} from '@/utils/password';

type Emits = {
  'password-changed': [{ password: string; isValid: boolean }];
};

const emit = defineEmits<Emits>();

const password = ref('');
const passwordForValidation = ref('');

const isValid = computed(() => {
  return isPasswordValid(password.value, passwordForValidation.value);
});

const invalidPassword = computed(() => {
  return (
    tooShortPassword.value ||
    usingInvalidCharacter.value ||
    notUsingRequiredTypeCharacters.value
  );
});

const tooShortPassword = computed(() => {
  if (password.value === '') return false;
  return !isPasswordLengthValid(password.value);
});

const notUsingRequiredTypeCharacters = computed(() => {
  if (password.value === '') return false;
  return !isPasswordContentValid(password.value);
});

const usingInvalidCharacter = computed(() => {
  if (password.value === '') return false;
  return !isPasswordOnlyValidCharacters(password.value);
});

const twoPasswordUnmatched = computed(() => {
  if (password.value === '' || passwordForValidation.value === '') return false;
  return password.value !== passwordForValidation.value;
});

watch(
  () => [password.value, isValid.value],
  to => {
    emit('password-changed', {
      password: String(to[0]),
      isValid: Boolean(to[1]),
    });
  },
);

const passwordErrorMessage = computed<string>(() => {
  const errorMessages: string[] = [];
  if (notUsingRequiredTypeCharacters.value)
    errorMessages.push(NOT_USING_REQUIRED_TYPE_CHARACTERS_MESSAGE);

  if (usingInvalidCharacter.value)
    errorMessages.push(USING_INVALID_CHARACTER_MESSAGE);

  if (tooShortPassword.value) errorMessages.push(TOO_SHORT_PASSWORD_MESSAGE);

  return errorMessages.join('\n');
});
</script>
<template>
  <div class="o-password-form c-formBlock">
    <div class="o-form-label c-formBlock__label">
      <label for="new-password">新しいパスワード</label>
      <div class="tooltip">
        <VTooltip placement="bottom">
          <div class="icon">
            <DgrIcon size="small" name="question-circle" />
          </div>
          <template #popper>
            <span>利用できる記号：</span>
            <pre>{{ VALID_SYMBOLS.join(' ') }}</pre>
          </template>
        </VTooltip>
      </div>
    </div>
    <div class="spacing-04"></div>
    <div class="c-formBlock__text c-text c-text--m">
      大文字・小文字・数字・記号をそれぞれ1文字以上含みかつ{{
        MIN_PASSWORD_LENGTH
      }}文字以上
    </div>
    <div
      class="o-error-messages c-formBlock__text c-formBlock__text--error"
      data-testid="password-error-message"
    >
      <div
        role="alert"
        class="c-text--s password-error-message"
        v-show="passwordErrorMessage"
      >
        {{ passwordErrorMessage }}
      </div>
    </div>
    <PasswordInput
      input-id="new-password"
      v-model="password"
      :has-error="invalidPassword"
    ></PasswordInput>
    <div class="spacing-16"></div>
    <div class="o-form-label c-formBlock__label">
      <label for="new-password-reenter">パスワード再入力</label>
    </div>
    <div
      class="c-formBlock__text c-formBlock__text--error"
      v-show="twoPasswordUnmatched"
      data-testid="password-for-validation-error-message"
    >
      <div role="alert" class="c-text--s">パスワードが一致していません。</div>
    </div>
    <PasswordInput
      input-id="new-password-reenter"
      v-model="passwordForValidation"
      :has-error="twoPasswordUnmatched"
    ></PasswordInput>
  </div>
</template>

<style lang="scss" scoped>
.o-password-form {
  input {
    outline: none;
  }
}
.o-error-messages {
  display: block;

  .password-error-message {
    white-space: pre-wrap;
  }
}
</style>
