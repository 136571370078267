<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue';
import { NON_THEME_PERSPECTIVE } from '@/constants';
import { DgrIcon, DgrRadio } from '@stockmarkteam/donguri-ui';
import { Perspective } from '@/types';
import { useThemePerspectives } from '@/utils/swr';

export default defineComponent({
  components: {
    DgrRadio,
    DgrIcon,
  },
  emits: {
    selectedPerspective: (_perspective: Perspective) => true,
  },
  setup(_props, context) {
    const { data: themePerspectivesResponse } = useThemePerspectives();
    const themePerspectives = computed(() =>
      !themePerspectivesResponse.value?.theme_perspectives ||
      themePerspectivesResponse.value.theme_perspectives.length === 0
        ? [NON_THEME_PERSPECTIVE]
        : themePerspectivesResponse.value.theme_perspectives,
    );
    const themePerspectiveItems = computed(() =>
      themePerspectives.value.map(perspective => ({
        label: perspective.question,
        value: perspective.id,
        description: perspective.description,
      })),
    );

    watch(themePerspectives, () => {
      if (selectedPerspective.value === undefined) {
        selectedPerspective.value = themePerspectives.value[0].id;
      }
    });

    const selectedPerspective = ref<number | undefined>(undefined);

    watch(selectedPerspective, () => {
      const perspective = themePerspectives.value.find(
        perspective => perspective.id === selectedPerspective.value,
      );
      if (perspective) context.emit('selectedPerspective', perspective);
    });

    return {
      themePerspectives,
      selectedPerspective,
      themePerspectiveItems,
    };
  },
});
</script>

<template>
  <div>
    <div class="c-title c-title--xl">特にどのような目的で知りたいですか？</div>
    <div class="spacing-12" />
    <div class="c-text c-text--m">
      より関連する情報をお届けするために、もう一つ教えてください。<br />
      選択したトピックについて、特にどのような目的で知りたいですか？
    </div>
    <div class="spacing-32" />
    <div class="c-text c-text--m">
      情報収集の目的を教えてください（組み合わせによっては、現時点で情報がない場合がありますのでご了承ください）
    </div>
    <div class="spacing-12" />
    <DgrRadio
      :items="themePerspectiveItems"
      v-model="selectedPerspective"
      orientation="vertical"
      class="c-text c-text--m"
    >
      <template #trailing="{ item }">
        <DgrIcon
          v-if="item.description"
          name="question-circle-fill"
          size="small"
          class="description-icon"
          v-tooltip.right="{
            theme: 'white',
            content: item.description,
            triggers: ['click'],
            autoHide: true,
          }"
        />
      </template>
    </DgrRadio>
  </div>
</template>

<style scoped lang="scss">
.description-icon {
  margin-left: 2px;
  cursor: pointer;
}
</style>
