import { SummaryCitation, SummaryMessage } from '@/types';

/* 社内情報検索機能のトライアルの訴求をする施策で使用するダミーデータ */
/* ${base_url}/search/user_document_demo?query=${検索文字列}のページで使用 */

// 問い合わせページに遷移するモーダルの背後で表示する社内情報カードのダミーデータ
// 本来`src/api/index.ts`のuserDocumentSearchで取得するデータ
export const DUMMY_USER_DOCUMENTS_RESP = {
  result_count: 100,
  exceed_max_result_window: null,
  search_tracking_session_id: 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
  expanded_queries: [],
  last_updated_by: ['山田太郎', '鈴木二郎'],
  articles: [
    {
      id: '01HYX7XGVNA61NMRPB5CQ1QYV7',
      title: '入社オンボーディング_Day1_イントロダクション_高橋.pdf',
      url: 'https://dummy.app.box.com/file/1234',
      image_url: 'test/image/url',
      lang: 'ja',
      datasource_id: '1234567890123',
      storage_type: '',
      storage_file_id: '1234567890123',
      filename: '入社オンボーディング_Day1_イントロダクション_高橋.pdf',
      path: 'Box',
      folder_url: 'https://dummy.app.box.com/folder/1234',
      file_type: 'PDF',
      last_updated_by: '山田太郎',
      modified_at: '2024-04-24T00:00:00.000Z',
      doc_type: 'user_document',
      page_number: 20,
      chunk_text:
        '...社内情報検索機能がリリース ✓ 調査レポートや技術報告書、実験データな ど、適切な管理が困難で埋もれがちな社内情 報の検索・要約生成が可能な「社内情報検索 機能」 2024 年 5 月リリース ✓ 社内外情報の横...',
      hit_keywords: ['社内情報検索機能'],
      is_read: false,
    },
    {
      id: '01HYX7XGVNV12YMZ4CZ02BVCN9',
      title: 'JP Stockmark_Intro_240423.pdf',
      url: 'https://dummy.app.box.com/file/1234',
      image_url: 'test/image/url',
      lang: 'ja',
      datasource_id: '1234567890123',
      storage_type: '',
      storage_file_id: '1491019148433',
      filename: 'JP Stockmark_Intro_240423.pdf',
      path: 'Box',
      folder_url: 'https://dummy.app.box.com/folder/1234',
      file_type: 'PDF',
      last_updated_by: '山田太郎',
      modified_at: '2024-04-24T00:00:00.000Z',
      doc_type: 'user_document',
      page_number: 21,
      chunk_text:
        '...社内情報検索機能の追加で、社内外情報のまとめて検索を可能に Before After 知識と概念を 自動的に補完 関連する外部情報/ニュースと、 自社の強み/製品を結びつけることができない 社内外の関連情報 を自動的...',
      hit_keywords: ['社内情報検索機能'],
      is_read: false,
    },
    {
      id: '01HYX7XGVNX0KXXBYPZ9HZN3N9',
      title: '弊社技術のご紹介及びご提案_20240110.pdf',
      url: 'https://dummy.app.box.com/file/1234',
      image_url: 'test/image/url',
      lang: 'ja',
      datasource_id: '1234567890123',
      storage_type: '',
      storage_file_id: '1491102378362',
      filename: '弊社技術のご紹介及びご提案_20240110.pdf',
      path: 'Box',
      folder_url: 'https://dummy.app.box.com/folder/1234',
      file_type: 'PDF',
      last_updated_by: '鈴木次郎',
      modified_at: '2024-04-24T00:00:00.000Z',
      doc_type: 'user_document',
      page_number: 63,
      chunk_text:
        '...機能概要 | 社内情報活用 63 * 初版リリース以降に随時開発予定 *画面は開発中のものです。 散在する情報を、面倒な操作なく 1箇所に集めて横断検索できます*1 社内→社外、社外→社内を自由に 横断し情報探索...',
      hit_keywords: [],
      is_read: false,
    },
    {
      id: '01HYX7XGVNZ621TNPKVAAE5R1Q',
      title: '【汎用案内資料】Anews社内外情報v2.3_20240401.pdf',
      url: 'https://dummy.app.box.com/file/1234',
      image_url: 'test/image/url',
      lang: 'ja',
      datasource_id: '1234567890123',
      storage_type: '',
      storage_file_id: '1491017574101',
      filename: '【汎用案内資料】Anews社内外情報v2.3_20240401.pdf',
      path: 'Box',
      folder_url: 'https://dummy.app.box.com/folder/1234',
      file_type: 'PDF',
      last_updated_by: '鈴木次郎',
      modified_at: '2024-04-24T00:00:00.000Z',
      doc_type: 'user_document',
      page_number: 5,
      chunk_text:
        '...機能概要 | 社内情報活用 5 * 画面は開発中のもので す。 社内情報を、1箇所に集めて横 断検索できます *1 社内→社外、社外→社内を自由 に横断し情報探索を支援します 社内外を横断して探索できる 日々の情報が...',
      hit_keywords: [],
      is_read: false,
    },
    {
      id: '01HYX7XGVNECH5735JQ23GDR70',
      title: 'STMK紹介&今後のディスカッション_20231213.pdf',
      url: 'https://dummy.app.box.com/file/1234',
      image_url: 'test/image/url',
      lang: 'ja',
      datasource_id: '1234567890123',
      storage_type: '',
      storage_file_id: '1491106678929',
      filename: 'STMK紹介&今後のディスカッション_20231213.pdf',
      path: 'Box',
      folder_url: 'https://dummy.app.box.com/folder/1234',
      file_type: 'PDF',
      last_updated_by: '山田太郎',
      modified_at: '2024-04-24T00:00:00.000Z',
      doc_type: 'user_document',
      page_number: 5,
      chunk_text:
        '...機能概要 | 社内情報活用 33 *画面は開発中のものです。 社内情報を、1箇所に集めて 横断検索できます*1 社内→社外、社外→社内を自由 に横断し情報探索を支援します 社内外を横断して探索できる 日々の情報が...',
      hit_keywords: [],
      is_read: false,
    },
    {
      id: '01HYX7XGVNJGYRZ9YNQPY39JFQ',
      title: 'STMK紹介&今後のディスカッション_20231226.pdf',
      url: 'https://dummy.app.box.com/file/1234',
      image_url: 'test/image/url',
      lang: 'ja',
      datasource_id: '1234567890123',
      storage_type: '',
      storage_file_id: '1491100679938',
      filename: 'STMK紹介&今後のディスカッション_20231226.pdf',
      path: 'Box',
      folder_url: 'https://dummy.app.box.com/folder/1234',
      file_type: 'PDF',
      last_updated_by: '鈴木次郎',
      modified_at: '2024-04-24T00:00:00.000Z',
      doc_type: 'user_document',
      page_number: 5,
      chunk_text:
        '...機能概要 | 社内情報活用 33 *画面は開発中のものです。 社内情報を、1箇所に集めて 横断検索できます*1 社内→社外、社外→社内を自由 に横断し情報探索を支援します 社内外を横断して探索できる 日々の情報が...',
      hit_keywords: [],
      is_read: false,
    },
    {
      id: '01HYX7XGVN5K1675W834FGCA3P',
      title: 'STMK紹介&今後のディスカッション_20231121.pdf',
      url: 'https://smbox.app.box.com/file/1491104812559',
      image_url: 'test/image/url',
      lang: 'ja',
      datasource_id: '1234567890123',
      storage_type: '',
      storage_file_id: '1491104812559',
      filename: 'STMK紹介&今後のディスカッション_20231121.pdf',
      path: 'Box',
      folder_url: 'https://dummy.app.box.com/folder/1234',
      file_type: 'PDF',
      last_updated_by: '山田太郎',
      modified_at: '2024-04-24T00:00:00.000Z',
      doc_type: 'user_document',
      page_number: 5,
      chunk_text:
        '...機能概要 | 社内情報活用 33 *画面は開発中のものです。 社内情報を、1箇所に集めて 横断検索できます*1 社内→社外、社外→社内を自由 に横断し情報探索を支援します 社内外を横断して探索できる 日々の情報が...',
      hit_keywords: [],
      is_read: false,
    },
  ],
};

// 要約生成のダミーデータ
// 本来`src/api/index.ts`のsummaryStreamで取得するデータ
export const DUMMY_SUMMARY_RESP: Array<
  SummaryMessage | { type: 'citations'; citations: SummaryCitation[] }
> = [
  {
    type: 'message',
    text: '社内情報検索機能は、社内外の情報をワンストップで横断できる機能です。以下は、検索結果から得られた情報です。',
  },
  {
    type: 'citations',
    citations: [
      {
        type: 'citation',
        text: '日本ガイシとPoCを開始し、ストックマークの独自開発LLMモデルを活用して、社内文書も活用した独自ナレッジグラフを構築しています。',
        sources: [
          {
            index: 0,
            doc_type: 'user_document',
            lang: 'ja',
            id: 1,
          },
        ],
      },
      {
        type: 'citation',
        text: '社内情報検索機能により、社内外情報を一括で検索し、検索結果の要約生成が可能になります。',
        sources: [
          {
            index: 0,
            doc_type: 'user_document',
            lang: 'ja',
            id: 2,
          },
          {
            index: 1,
            doc_type: 'user_document',
            lang: 'ja',
            id: 3,
          },
        ],
      },
    ],
  },
];
