import { ref } from 'vue';

/**
 * 入力補完のためのキーボード操作を提供する
 *
 * @returns focusIndex: フォーカスがある項目のインデックス
 * @returns onKeyDown: キーボード操作時の処理
 * @returns resetIndex: フォーカスのリセット処理
 */
export function useKeyboardNavigation() {
  /**
   * focusIndexは-1が対象の要素（inputなど）にフォーカスがあるときで、
   * 0以上が入力補完の各項目にフォーカスがあるときを表す
   */
  const focusIndex = ref(-1);

  const onKeyDown = (
    event: KeyboardEvent,
    itemCount: number,
    onSelect: (index: number) => void,
  ) => {
    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault();
        focusIndex.value++;
        if (focusIndex.value >= itemCount) focusIndex.value = -1;
        break;
      case 'ArrowUp':
        event.preventDefault();
        focusIndex.value--;
        if (focusIndex.value < -1) focusIndex.value = itemCount - 1;
        break;
      case 'Enter':
        event.preventDefault();
        onSelect(focusIndex.value);
        break;
      case 'Escape':
        event.preventDefault();
        focusIndex.value = -1;
        break;
    }
  };

  const resetIndex = () => {
    focusIndex.value = -1;
  };

  return {
    focusIndex,
    onKeyDown,
    resetIndex,
  };
}
