<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { DgrIcon } from '@stockmarkteam/donguri-ui';
import Avatar from '@/components/common/atoms/avatar.vue';
import Loading from '@/components/common/loading.vue';
// FIXME: 現状unmountさせるためkeyを渡しているが一時的な対応なので、修正予定
// SearchBarの中にもqueryを持っているため、queryは使っている側から渡すようにする
import SearchBar from '@/components/common/molecules/search-bar.vue';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import { STATES, useOrganizationTagDetail } from '@/utils/swr';

interface Props {
  tagId: number;
  hasPermission: boolean;
}
const props = defineProps<Props>();

interface Emits {
  (e: 'onClose'): () => true;
}
const emit = defineEmits<Emits>();

const { createSnackbar } = useSnackbar();
const router = useRouter();
const isLoading = ref(false);

const tagId = computed(() => props.tagId);

const filterQuery = ref('');
const filteredTagUsers = computed(() => {
  if (!tag.value) return [];
  if (!filterQuery.value) return tag.value.users;

  return tag.value.users.filter(user => {
    const includesUserName = user.user_name
      .toLowerCase()
      .includes(filterQuery.value.toLowerCase());
    const includesEmail = user.email
      .toLowerCase()
      .includes(filterQuery.value.toLowerCase());

    return includesUserName || includesEmail;
  });
});

const sortDirection = ref<'asc' | 'desc'>('asc');
const sortedUsers = computed(() => {
  if (!filteredTagUsers.value) return [];

  return [...filteredTagUsers.value].sort((a, b) => {
    if (a.user_name < b.user_name) {
      return sortDirection.value === 'asc' ? -1 : 1;
    }
    if (a.user_name > b.user_name) {
      return sortDirection.value === 'asc' ? 1 : -1;
    }
    return 0;
  });
});

const { data: tag, state } = useOrganizationTagDetail(tagId);

watch(
  () => state.value,
  () => {
    if (state.value === STATES.ERROR || state.value === STATES.STALE_IF_ERROR) {
      createSnackbar({
        message: '組織タグの詳細を取得できませんでした',
        type: 'error',
      });
      emit('onClose');
    }
    if (state.value === STATES.PENDING || state.value === STATES.VALIDATING) {
      isLoading.value = true;
    }
    if (state.value === STATES.SUCCESS) {
      isLoading.value = false;
    }
  },
);
watch(
  () => props.tagId,
  () => {
    isLoading.value = false;
    filterQuery.value = '';
  },
);

const handleChangeFilterQuery = (query: string) => {
  filterQuery.value = query;
};

const handleToggleSortDirection = () => {
  sortDirection.value = sortDirection.value === 'asc' ? 'desc' : 'asc';
};

const handleMoveToTagDetail = () => {
  router.push({
    name: 'organizationTagDetail',
    params: { organizationTagId: tagId.value },
  });
};

const handleClose = () => {
  emit('onClose');
};

const sidePeakButtonLabelText = computed(() => {
  if (props.hasPermission) {
    return '全画面で確認・編集する';
  } else {
    return '全画面で確認する';
  }
});
</script>

<template>
  <div class="container" data-testid="side-peak">
    <template v-if="isLoading">
      <Loading class="loading" z-index="10" />
    </template>

    <template v-if="tag">
      <div class="header">
        <button
          type="button"
          @click="handleMoveToTagDetail"
          class="move-to-tag-detail-button outlined c-outlineBtn c-btn--auto c-btn--small c-outlineBtn--secondary"
          :disabled="isLoading"
        >
          {{ sidePeakButtonLabelText }}
        </button>

        <button type="button" class="close-button" @click="handleClose">
          <DgrIcon name="times" />
        </button>
      </div>

      <div class="body">
        <div class="tag-name-container">
          <p class="c-text--m">{{ tag.name }}</p>
        </div>

        <div class="search-bar-label-container">
          <p class="c-text--s">メンバー</p>
        </div>

        <div
          v-if="tag.users.length > 0"
          class="search-bar-container"
          data-testid="search-bar-container"
        >
          <SearchBar
            @on-change-query="handleChangeFilterQuery"
            data-testid="search-bar"
            :key="tagId"
          ></SearchBar>
        </div>

        <template v-if="sortedUsers.length <= 0">
          <div class="empty-user-list" data-testid="empty-user-state">
            <p class="c-text--m">メンバーがいません</p>
            <img src="@/assets/state-empty-article.png" />
          </div>
        </template>

        <template v-else>
          <div class="users-list-container" data-testid="users-list-container">
            <div
              class="users-list-header"
              @click="handleToggleSortDirection"
              data-testid="toggle-sort-direction-button"
            >
              <p class="c-text--s">名前</p>

              <DgrIcon
                v-if="sortDirection === 'asc'"
                name="long-arrow-up"
                class="sort-direction-arrow-container"
              />
              <DgrIcon
                v-else
                name="long-arrow-down"
                class="sort-direction-arrow-container"
              />
            </div>

            <ul class="users-list">
              <li
                v-for="user in sortedUsers"
                :key="user.id"
                data-testid="users-list-item"
              >
                <div>
                  <router-link :to="`/users/${user.id}`">
                    <Avatar :image-url="user.image_url" size="xs"></Avatar>
                  </router-link>
                </div>

                <div class="user-text-container">
                  <div class="name-container">
                    <router-link
                      :to="`/users/${user.id}`"
                      class="c-text--m name"
                    >
                      {{ user.user_name }}
                    </router-link>
                  </div>

                  <div class="email-container">
                    <p class="c-text--xs email">{{ user.email }}</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<style scoped lang="scss">
.container {
  width: 378px;
  background-color: #fff;
  border-radius: $border-radius 0 0 $border-radius;
  border: 1px solid $color-border;
  box-sizing: border-box;
  position: relative;
  min-height: 320px;
}

.loading {
  border-radius: $border-radius 0 0 $border-radius;
}

.header {
  height: 64px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid $color-border;
  box-sizing: border-box;
  z-index: 100;
}
.move-to-tag-detail-button {
  &:not(:disabled) {
    border: solid 1px $color-green600;
    color: $color-green600;

    &:hover {
      background-color: $color-green100;
    }
  }

  &:disabled {
    cursor: default;
    color: #fff;
    background: $color-gray400;
  }
}

.close-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;

  &:hover {
    opacity: 0.8;
  }
}

.body {
  max-height: calc(100% - 64px);
  padding: 16px;
  box-sizing: border-box;
  overflow: auto;
}

.tag-name-container {
  margin-bottom: 12px;
  text-align: center;
}

.empty-user-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 32px;

  img {
    width: 96px;
  }
}

.search-bar-label-container {
  margin-bottom: 8px;
}

.search-bar-container {
  margin-bottom: 16px;
}

.users-list-container {
  border: 1px solid $color-border;
  box-sizing: border-box;
  padding: 16px 16px 0;
  border-radius: $border-radius;
}

.users-list-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  padding: 8px 0;
  border-bottom: 1px solid $color-border;
  box-sizing: border-box;
  cursor: pointer;

  &:hover {
    background-color: $color-btn-hover;
  }
}

.icon-box {
  margin-top: 2px;
  width: 14px !important;
  height: 14px !important;
}

.users-list {
  li {
    display: flex;
    justify-content: flex-start;
    gap: 12px;
    align-items: center;
    padding: 4px 0;
    border-bottom: 1px solid $color-border;
    box-sizing: border-box;

    &:last-child {
      border-bottom: none;
    }
  }
}

.user-text-container {
  overflow: hidden;
}

.name-container {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.name {
  &:hover {
    text-decoration: underline;
  }
}

.email-container {
  overflow-wrap: break-word;
}
.email {
  color: $color-text-secondary;
}
</style>
