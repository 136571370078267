<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import api from '@/api';
import TechnicalLiteratureFeed from '@/components/common/technical-literature/technical-literature-feed.vue';
import Content from '@/components/layouts/content.vue';
import Header from '@/components/layouts/header.vue';
import { ThemeFeedResearchPaper } from '@/types';
import { getTabs } from '@/utils/personalNews';
import {
  usePersonalResearchPaperFeed,
  useTeamInfo,
  useUserInfo,
  useUserPersonalNewsDocumentCounts,
} from '@/utils/swr';
import { userSession } from '@/utils/userSession';
import { useEmitter, useStore } from '@/utils/vue';

const store = useStore();
const router = useRouter();
const route = useRoute();
const emitter = useEmitter();
const userId = userSession.getUserId();
const { data: userInfo } = useUserInfo();
const { data: team } = useTeamInfo();
const { data: personalNewsCounts, mutate: mutateCounts } =
  useUserPersonalNewsDocumentCounts();

const feedCount = computed(() => {
  const counts = personalNewsCounts.value?.research_papers ?? {
    domestic: 0,
    foreign: 0,
  };
  return feedType.value === 'all'
    ? counts.domestic + counts.foreign
    : counts[feedType.value];
});

const updateFeedCount = () => mutateCounts();

onMounted(() => {
  api.trackPageView({
    pageName: 'personal_research_papers',
    pageUrl: route.fullPath,
  });
  emitter.on('personal-feed-article-removed', updateFeedCount);
  emitter.on('personal-feed-article-restored', updateFeedCount);
});

onUnmounted(() => {
  emitter.off('personal-feed-article-removed', updateFeedCount);
  emitter.off('personal-feed-article-restored', updateFeedCount);
});

watch(
  () => userInfo.value,
  () => {
    if (!userInfo.value?.has_personal_news) {
      router.push({ name: 'personalNews' });
    }
  },
);

const enableTechnicalLiterature = computed(
  () => team.value?.enable_technical_literature,
);

watch(
  () => team.value,
  () => {
    if (!enableTechnicalLiterature.value) {
      router.push({ name: 'personalNews' });
    }
  },
);

const feedType = computed(() => store.state.feedType.feedType);

const tabs = computed(() =>
  getTabs(feedType, team, userInfo, personalNewsCounts),
);

const { data: domesticResponse, state: domesticState } =
  usePersonalResearchPaperFeed(feedType, userId ?? 0, 'domestic', ref(1), 50);
const { data: foreignResponse, state: foreignState } =
  usePersonalResearchPaperFeed(feedType, userId ?? 0, 'foreign', ref(1), 50);

const domesticPapers = computed(() => ({
  items:
    domesticResponse.value?.research_paper_feeds ??
    ([] as ThemeFeedResearchPaper[]),
  state: domesticState.value,
}));
const foreignPapers = computed(() => ({
  items:
    foreignResponse.value?.research_paper_feeds ??
    ([] as ThemeFeedResearchPaper[]),
  state: foreignState.value,
}));
</script>

<template>
  <div class="personal-news">
    <Header
      title="パーソナルニュース"
      detail="あなた専用のニュースをお届けします"
      :tabs="tabs"
      :is-one-line="true"
    />
    <Content>
      <div class="feed-content" v-if="userInfo">
        <TechnicalLiteratureFeed
          feed="personal"
          doc-type="research_paper"
          :feed-type="feedType"
          :feed-count="feedCount"
          :feed-date="
            userInfo.personal_news_research_papers_updated_at ?? undefined
          "
          :domestic-documents="domesticPapers"
          :foreign-documents="foreignPapers"
        />
      </div>
    </Content>
  </div>
</template>

<style lang="scss" scoped>
.personal-news {
  width: 100%;
  margin: -24px 0 0 0;

  .feed-content {
    display: flex;
    flex-direction: column;
    width: 616px;
  }
}
</style>
