<script setup lang="ts">
import { computed } from 'vue';
import { ContentsContext, Feature, PageName } from '@/api/tracking';
import noImage1to1 from '@/assets/noimage-1to1.jpg';
import { DOC_TYPE_LABELS } from '@/constants';
import AdpDocumentCardLink from '@/components/common/adp-document/adp-document-card-link.vue';
import DocumentSentenceDisplay from '@/components/common/adp-document/document-sentence-display.vue';
import { Report } from '@/types';
import { imageErrorHandler } from '@/utils/errorHandler';
import { formatDate } from '@/utils/formatters';

interface Props {
  adpReport: Report;
  badgeNumber: number;
  /* これ以下は計測で使用 */
  pageName: PageName;
  feature: Feature;
  rankInWholeFeed: number;
  contentsContext: ContentsContext;
}

const props = defineProps<Props>();
const imageUrl = computed(() => props.adpReport.image_url || noImage1to1);
const publishedAt = computed(() =>
  props.adpReport.published_at
    ? formatDate(props.adpReport.published_at)
    : undefined,
);

const highlightKeywords = computed(() => {
  const hit_keywords = props.adpReport.hit_keywords ?? [];
  const synonym_keywords = props.adpReport.synonym_keywords ?? [];
  return [...hit_keywords, ...synonym_keywords]
    .filter(keyword => keyword.length > 0)
    .sort(keyword => -keyword.length);
});
</script>

<template>
  <div class="report-card">
    <div class="report-left">
      <div class="report-site-name c-text c-text--s">
        <div class="doc-type-label c-title c-title--xs">
          {{ badgeNumber }}.{{ DOC_TYPE_LABELS[adpReport.doc_type] }}
        </div>
        <div class="report-site-name-text">{{ adpReport.site_name }}</div>
      </div>
      <div class="spacing-04"></div>
      <div class="report-title c-title c-title--xm">
        <AdpDocumentCardLink
          :adp-document="adpReport"
          :rank-in-whole-feed="rankInWholeFeed"
          :page-name="pageName"
          :page-number="adpReport.page_number"
          :feature="feature"
          :contents-context="contentsContext"
        >
          {{ adpReport.title }}
        </AdpDocumentCardLink>
      </div>
      <div class="spacing-04"></div>
      <div class="document-sentence-display">
        <DocumentSentenceDisplay
          v-if="adpReport.chunk_text"
          :text="adpReport.chunk_text ?? ''"
          :highlight-keywords="highlightKeywords"
          :line-clamp="3"
        ></DocumentSentenceDisplay>
      </div>
      <div class="spacing-04" v-if="adpReport.chunk_text"></div>
      <div class="report-bottom">
        <div class="report-date c-text c-text--s">{{ publishedAt }}</div>
        <div class="report-page c-text c-text--xs" v-if="adpReport.page_number">
          PDF：{{ adpReport.page_number }}ページ
        </div>
      </div>
    </div>
    <div class="report-image">
      <AdpDocumentCardLink
        :adp-document="adpReport"
        :rank-in-whole-feed="rankInWholeFeed"
        :page-name="pageName"
        :page-number="adpReport.page_number"
        :feature="feature"
        :contents-context="contentsContext"
      >
        <img
          class="report-thumbnail"
          loading="lazy"
          :src="imageUrl"
          :data-src="imageUrl"
          @error="imageErrorHandler($event, '3to1')"
        />
      </AdpDocumentCardLink>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.report-card {
  width: 'auto';
  display: flex;
  background-color: white;
  padding: 16px;
  column-gap: 16px;
  box-sizing: border-box;
  border: 1px solid $color-gray400;
  border-radius: 4px;

  .report-left {
    width: 100%;
    .report-bottom {
      display: flex;
      color: $color-gray800;
      font-weight: 400;
      column-gap: 8px;
      .report-page {
        border: 1px solid $color-gray800;
        border-radius: 2px;
        padding: 0 2px;
      }
    }
  }
  .report-thumbnail {
    width: 150px;
    height: 150px;
    object-fit: contain;
    font-weight: 400;
  }
  .report-site-name {
    color: $color-gray800;
    font-weight: 500;
    display: flex;
    align-items: center;
    .report-site-name-text {
      max-width: 155px;
      overflow: hidden;
      display: -webkit-box;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
    .doc-type-label {
      background-color: $color-gray1000;
      color: $color-white;
      padding: 4px;
      margin-right: 6px;
      border-radius: 2px;
      font-weight: 700;
    }
    .animation-delay {
      animation: text-animation 1s steps(1, end) forwards;
      opacity: 0;
    }
    @keyframes text-animation {
      to {
        opacity: 1;
      }
    }
  }
  .report-title {
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    word-break: break-all;
    font-weight: 500;
    a:hover {
      text-decoration: underline;
    }
  }

  .document-sentence-display {
    border-left: 1px solid $color-gray400;
    padding-left: 13px;
  }
}
</style>
