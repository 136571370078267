import { Module } from 'vuex';

export type ConfirmationModalState = {
  headerText: string;
  bodyText: string;
  confirmationType: string;
  btnText: string;
  cancelBtnText: string;
  warningText?: string;
  isBtnDisabled?: boolean;
  action: () => Promise<void>;
  confirmationModalOpen: boolean;
  shouldShowLoading: boolean;
};

export const initialConfirmationModalState: ConfirmationModalState = {
  headerText: '',
  bodyText: '',
  confirmationType: '',
  btnText: '',
  cancelBtnText: '',
  isBtnDisabled: false,
  warningText: '',
  action: () => Promise.resolve(),
  confirmationModalOpen: false,
  shouldShowLoading: false,
};

const confirmationModal: Module<ConfirmationModalState, unknown> = {
  namespaced: true,
  state: {
    headerText: '',
    bodyText: '',
    confirmationType: '',
    btnText: '',
    cancelBtnText: '',
    isBtnDisabled: false,
    warningText: '',
    action: Object(),
    confirmationModalOpen: false,
    shouldShowLoading: false,
  },

  actions: {
    async executeAction(context) {
      await context.state.action();
    },
  },

  mutations: {
    setTextAndAction(state, payload) {
      state.headerText = payload.headerText;
      state.bodyText = payload.bodyText;
      state.confirmationType = payload.confirmationType;
      state.btnText = payload.btnText ?? '削除';
      state.cancelBtnText = payload.cancelBtnText ?? 'キャンセル';
      state.isBtnDisabled = payload.isBtnDisabled ?? false;
      state.warningText = payload.warningText ?? '';
      state.shouldShowLoading = payload.shouldShowLoading ?? false;
      state.action = payload.action;
    },
    showConfirmationModal(state) {
      state.confirmationModalOpen = true;
    },
    hideConfirmationModal(state) {
      state.confirmationModalOpen = false;
    },
  },
};

export default confirmationModal;
