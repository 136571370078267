<script setup lang="ts">
import { computed, Ref, UnwrapRef } from 'vue';
import { DgrIcon, DgrLoading, DgrPopover } from '@stockmarkteam/donguri-ui';
import SwrScrollingPagination from '@/components/common/swr-scrolling-pagination.vue';
import { GroupTagSortAttribute, GroupTagSortTypes } from '@/types';
import { useGroupTagItems } from '@/utils/swr';

const pageLimit = 50;

const props = defineProps<{
  groupId: number;
  sortType: GroupTagSortTypes | undefined;
}>();

const emit = defineEmits<{
  openEditTagModal: [params: { groupTagId: number; tagName: string }];
  openDeleteTagModal: [params: { groupTagId: number; tagName: string }];
  sortItems: [sortType: GroupTagSortAttribute];
}>();

const openEditTagModal = (params: { groupTagId: number; tagName: string }) => {
  emit('openEditTagModal', params);
};
const openDeleteTagModal = (params: {
  groupTagId: number;
  tagName: string;
}) => {
  emit('openDeleteTagModal', params);
};

const sortItems = (sortType: GroupTagSortAttribute) => {
  emit('sortItems', sortType);
};

const groupId = computed(() => props.groupId);
const sortType = computed(() => props.sortType);

const paginationFunc = (pageRef: Ref<number>, pageLimit: number) =>
  useGroupTagItems(groupId, sortType, pageRef, pageLimit);

const dataAccessor = (
  data: UnwrapRef<ReturnType<typeof useGroupTagItems>['data']>,
) => data;
</script>

<template>
  <div class="group-tag-list-content">
    <SwrScrollingPagination
      :key="`${groupId}-${sortType}`"
      :page-limit="pageLimit"
      :pagination-func="paginationFunc"
      :data-accessor="dataAccessor"
      scroll-target="self"
      class="table-container"
    >
      <template v-slot="{ items, loaded }">
        <table class="table">
          <thead>
            <tr class="table-header-row">
              <th
                class="table-header-cell ta-l c-text c-text--s sortable-table-header"
                @click="sortItems('name')"
              >
                <span>グループタグ名</span>
                <span v-if="sortType === 'name_asc'"> ↑ </span>
                <span v-else-if="sortType === 'name_desc'"> ↓ </span>
              </th>
              <th
                width="80"
                class="table-header-cell ta-l c-text c-text--s sortable-table-header"
                @click="sortItems('article_count')"
              >
                <span>記事数</span>
                <span v-if="sortType === 'article_count_asc'"> ↑ </span>
                <span v-else-if="sortType === 'article_count_desc'"> ↓ </span>
              </th>
              <th width="80" class="table-header-cell"></th>
            </tr>
          </thead>
          <tbody v-if="!loaded">
            <tr>
              <td colspan="3">
                <div class="loading-container">
                  <DgrLoading />
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="items.length === 0">
            <tr>
              <td colspan="3">
                <div class="empty-text-container">
                  <span class="c-text c-text--m">グループタグがありません</span>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-for="groupTagItem in items" :key="groupTagItem.id">
              <td>
                <div class="cell-inner-block group-tag-name c-text--m">
                  <span>{{ groupTagItem.name }}</span>
                </div>
              </td>
              <td>
                <div class="cell-inner-block group-tag-count-text c-text--m">
                  <span>{{ groupTagItem.total_count }}</span>
                </div>
              </td>
              <td>
                <div class="cell-inner-block group-tag-action-button-container">
                  <DgrPopover>
                    <template #default="{ triggerProps }">
                      <button class="action-button" v-bind="triggerProps">
                        <DgrIcon name="ellipsis-h" />
                      </button>
                    </template>
                    <template #content="{ close }">
                      <div class="item-container">
                        <button
                          class="item c-text c-text--m"
                          @click="
                            close();
                            openEditTagModal({
                              groupTagId: groupTagItem.id,
                              tagName: groupTagItem.name,
                            });
                          "
                        >
                          <DgrIcon
                            size="small"
                            :name="'pencil'"
                            class="icon small"
                          />
                          編集
                        </button>
                        <div class="separator"></div>
                        <button
                          class="item c-text c-text--m"
                          @click="
                            close();
                            openDeleteTagModal({
                              groupTagId: groupTagItem.id,
                              tagName: groupTagItem.name,
                            });
                          "
                        >
                          <DgrIcon
                            size="small"
                            :name="'trash'"
                            class="icon small"
                          />削除
                        </button>
                      </div>
                    </template>
                  </DgrPopover>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </template>
    </SwrScrollingPagination>
  </div>
</template>

<style lang="scss" scoped>
.table-container {
  border-radius: 4px;
  background-color: $color-white;
  border: 1px solid $color-border;
  padding: 0 16px;
  overflow-y: auto;
  max-height: calc(100vh - 280px);
}

.table {
  width: 100%;
  border-spacing: 0 0;

  tr:first-child {
    td {
      padding-top: 6px;
    }
  }
  tr:last-child {
    td {
      padding-bottom: 16px;
    }
  }
  td,
  th {
    height: 40px;
  }

  thead {
    background-color: $color-white;
    position: sticky;
    top: 0;
    z-index: 1;
  }
}

.cell-inner-block {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid $color-border;
}

.group-tag-count-text {
  padding-right: 12px;
  justify-content: flex-end;
}

.group-tag-action-button-container {
  justify-content: flex-end;
}

.group-tag-name {
  padding-left: 8px;
  justify-content: flex-start;
}

.action-button {
  display: flex;
  justify-content: center;
  width: 32px;
  height: 32px;
  padding: 8px;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: inherit;
  border: none;

  &:hover {
    border: none;
    background-color: $color-gray200;
  }
}

.table-header-cell {
  text-indent: 8px;
  padding: 12px 0;
  border-bottom: 1px solid $color-border;
}

.sortable-table-header {
  cursor: pointer;
  &:hover {
    background-color: $color-gray200;
  }
}

.ta-l {
  text-align: left;
}
.ta-r {
  text-align: right;
}

.item-container {
  padding: 0;

  .item {
    display: flex;
    align-items: center;
    width: 100%;
    border: none;
    background: none;

    &:hover {
      background: $color-gray400;
    }
    .icon {
      margin-right: 4px;
    }
  }
  .separator {
    border-top: 1px solid $color-gray400;
  }
}
</style>
