<script setup lang="ts">
interface Props {
  margin?: string;
}

const props = withDefaults(defineProps<Props>(), {
  margin: '0',
});
</script>

<template>
  <div class="divider" :style="{ margin: props.margin }"></div>
</template>

<style scoped lang="scss">
.divider {
  width: 100%;
  height: 0px;
  border-top: solid 1px $color-gray400;
}
</style>
