<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    headerHeight: { type: Number, default: 64 },
    additionalHeight: { type: Number, default: 0 },
  },
  // eslint-disable-next-line vue/component-api-style
  data() {
    return {
      height: `calc(100vh - ${this.headerHeight + this.additionalHeight}px)`,
    };
  },
  setup() {
    return {};
  },
});
</script>

<template>
  <div class="section-wrapper">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.section-wrapper {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  padding-bottom: 36px; // メニュー折りたたみボタンに被らないため

  height: v-bind(height);
  overflow-y: auto;
  overflow-x: hidden;

  background: white;
}
</style>
