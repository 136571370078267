<script setup lang="ts">
import { computed, ref } from 'vue';
import { DgrLoading } from '@stockmarkteam/donguri-ui';
import dayjs from 'dayjs';
import PersonalNewsCountdown from '@/components/common/personal_news/personal-news-countdown.vue';
import PersonalNewsArticleList from '@/components/home/personal-news-article-list.vue';
import {
  executionInformationForDate,
  getMorningFeedData,
  HOME_ALL_MEDIA_VISIBLE_ARTICLE_COUNT,
  HOME_VISIBLE_ARTICLE_COUNT,
  isAfternoonFeedEnabled,
  latestPersonalFeedDatetime,
} from '@/utils/personalNews';
import {
  STATES,
  usePersonalNewsMultipleExecution,
  useUserInfo,
} from '@/utils/swr';
import { useEmitter, useStore } from '@/utils/vue';

const store = useStore();
const emitter = useEmitter();
const { data: userInfo, mutate } = useUserInfo();
emitter.on('article-updated', () => {
  mutate();
});

const emit = defineEmits(['open-left-menu']);

const feedType = computed(() => store.state.feedType.feedType);

const feedCount = computed(() =>
  feedType.value === 'all'
    ? HOME_ALL_MEDIA_VISIBLE_ARTICLE_COUNT
    : HOME_VISIBLE_ARTICLE_COUNT,
);

const sentFirstPersonalNewsFeedAt = computed(() =>
  userInfo.value?.sent_first_personal_news_feed_at
    ? dayjs(userInfo.value?.sent_first_personal_news_feed_at)
    : undefined,
);

const feedLastUpdatedAt = computed(() =>
  latestPersonalFeedDatetime(sentFirstPersonalNewsFeedAt.value),
);

const hasPersonalNews = computed(
  () => userInfo.value?.has_personal_news ?? false,
);

const morningFeedData = computed(() =>
  getMorningFeedData(
    feedLastUpdatedAt.value,
    sentFirstPersonalNewsFeedAt.value,
  ),
);

const { data: domesticFeedMorning, state: domesticStateMorning } =
  usePersonalNewsMultipleExecution(
    feedType,
    'domestic',
    morningFeedData.value.date,
    morningFeedData.value.execution,
    ref(1),
    feedCount,
  );
const { data: foreignFeedMorning, state: foreignStateMorning } =
  usePersonalNewsMultipleExecution(
    feedType,
    'foreign',
    morningFeedData.value.date,
    morningFeedData.value.execution,
    ref(1),
    feedCount,
  );
const { data: domesticFeedAfternoon, state: domesticStateAfternoon } =
  usePersonalNewsMultipleExecution(
    feedType,
    'domestic',
    feedLastUpdatedAt.value,
    'afternoon',
    ref(1),
    feedCount,
  );
const { data: foreignFeedAfternoon, state: foreignStateAfternoon } =
  usePersonalNewsMultipleExecution(
    feedType,
    'foreign',
    feedLastUpdatedAt.value,
    'afternoon',
    ref(1),
    feedCount,
  );

const isLoadedMorning = computed(
  () =>
    domesticStateMorning.value === STATES.SUCCESS ||
    foreignStateMorning.value === STATES.SUCCESS,
);
const isLoadedAfternoon = computed(
  () =>
    domesticStateAfternoon.value === STATES.SUCCESS ||
    foreignStateAfternoon.value === STATES.SUCCESS,
);

const executionInfoMorning = computed(() =>
  executionInformationForDate(
    feedLastUpdatedAt.value,
    'morning',
    sentFirstPersonalNewsFeedAt.value,
  ),
);
const executionInfoAfternoon = computed(() =>
  executionInformationForDate(
    feedLastUpdatedAt.value,
    'afternoon',
    sentFirstPersonalNewsFeedAt.value,
  ),
);
</script>

<template>
  <div class="personal-feed" v-if="hasPersonalNews">
    <div class="title c-text c-text--l">
      パーソナルニュース<span class="subtitle c-text c-text--m"
        >・あなた専用のニュースをお届けします</span
      >
    </div>
    <template
      v-if="
        (isAfternoonFeedEnabled(feedLastUpdatedAt) ||
          executionInfoAfternoon.isFirstExecution) &&
        !executionInfoMorning.isFirstExecution
      "
    >
      <PersonalNewsArticleList
        :execution-info="executionInfoAfternoon"
        :domestic-articles="domesticFeedAfternoon?.personal_feeds ?? []"
        :foreign-articles="foreignFeedAfternoon?.personal_feeds ?? []"
        :previous-total-feed-count="0"
        :is-loaded="isLoadedAfternoon"
      />
      <div class="spacing-24"></div>
    </template>
    <template v-if="!executionInfoAfternoon.isFirstExecution">
      <PersonalNewsArticleList
        :execution-info="executionInfoMorning"
        :domestic-articles="domesticFeedMorning?.personal_feeds ?? []"
        :foreign-articles="foreignFeedMorning?.personal_feeds ?? []"
        :previous-total-feed-count="0"
        :is-loaded="isLoadedMorning"
      />
    </template>
  </div>
  <div class="no-articles" v-else-if="userInfo">
    <PersonalNewsCountdown
      v-if="userInfo.personal_news_counts"
      :actioned-article-counts="
        userInfo.personal_news_counts ?? { domestic: 0, foreign: 0 }
      "
      @open-left-menu="emit('open-left-menu')"
    ></PersonalNewsCountdown>
    <div class="loading-container" v-else>
      <DgrLoading class="loading" />
    </div>
  </div>
</template>
<style lang="scss" scoped>
.personal-feed {
  display: flex;
  flex-direction: column;
  background: white;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 20px 24px;

  .title {
    margin-bottom: 4px;
  }
  .subtitle {
    color: #717171;
  }
}
.loading-container {
  display: flex;
  flex-direction: column;
  background: white;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 14px 48px 20px 48px;
  min-height: 300px;
  .loading {
    margin: 0 auto;
  }
}
</style>
