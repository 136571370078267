<script lang="ts">
import { defineComponent, PropType, ref, watch } from 'vue';
import DatePicker from 'vue-datepicker-next';
import { SERVICE_START_DATE } from '@/constants';
import ja from 'date-format-parse/lib/locale/ja';
import dayjs from 'dayjs';
import { formatDate } from '@/utils/formatters';

const YESTERDAY = dayjs().endOf('day').subtract(1, 'day').toDate();

DatePicker.locale('ja', {
  formatLocale: ja,
  yearFormat: 'YYYY年',
  monthFormat: 'MMM',
  monthBeforeYear: false,
});
const { Calendar } = DatePicker;

function isValidDate(date: number | Date) {
  return !isNaN(date as number) && date instanceof Date;
}

export default defineComponent({
  components: { DatePicker, Calendar },
  props: {
    disabledUntil: {
      type: Date as PropType<Date>,
      default: () => SERVICE_START_DATE,
    },
    disabledLaterThan: {
      type: Date as PropType<Date>,
      default: () => YESTERDAY,
    },
    date: {
      type: Date as PropType<Date> | undefined,
    },
    isSearch: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  setup(props, context) {
    const open = ref(false);
    const currentDate = ref(
      props.isSearch ? props.date : props.date ?? YESTERDAY,
    );

    // 参考：https://codesandbox.io/s/range-picker-with-one-calendar-jvv5s?file=/src/App.vue
    // (https://github.com/mengxiong10/vue2-datepicker/issues/631#issuecomment-916589214)
    // １つのカレンダーでstartとendを選択できるためのメソッド
    const handleSelect = (date: Date | undefined) => {
      currentDate.value = date;
      context.emit('datesChanged', date); // eslint-disable-line vue/require-explicit-emits
    };

    // 選択可能の期間：デフォルトではAnewsのリリースから昨日まで
    const disabledDate = (date: Date) => {
      return date < props.disabledUntil || date > props.disabledLaterThan;
    };

    const clickCancel = () => {
      open.value = false;
    };

    // datepicker以外にクリックしたらclose=falseになるのでキャンセルのようにする
    // clickApplyでもこの処理が発生するけど何も変わらない（previousDatesはもうdateと同じ）
    watch(
      () => open.value,
      () => {
        if (!open.value) {
          clickCancel();
        }
      },
    );

    watch(
      () => props.date,
      () => {
        currentDate.value = props.date;
      },
    );

    const timerId = ref<ReturnType<typeof setTimeout>>();

    const change = (event: Event) => {
      const value = (event.target as HTMLInputElement).value;
      if (value === '' && props.isSearch) {
        handleSelect(undefined);
        return;
      }
      const date = new Date(value);

      if (timerId.value) {
        clearTimeout(timerId.value);
      }
      timerId.value = setTimeout(inputEnd.bind(undefined, date), 1000);
    };

    const inputEnd = (date: Date) => {
      if (!isValidDate(date)) return;
      if (disabledDate(date)) return;
      handleSelect(date);
    };

    const decisionDate = (event: Event) => {
      const value = (event.target as HTMLInputElement).value;
      if (value === '' && props.isSearch) {
        handleSelect(undefined);
        return;
      }
      const date = new Date(value);
      inputEnd(date);
    };

    return {
      open,
      currentDate,
      handleSelect,
      disabledDate,
      clickCancel,
      formatDate,
      change,
      decisionDate,
    };
  },
});
</script>

<template>
  <div class="datepicker">
    <DatePicker
      type="date"
      :range="true"
      :value="date"
      v-model:open="open"
      @clickoutside="clickCancel"
      prefix-class="datepicker"
    >
      <template v-slot:input>
        <input
          class="c-text c-text--m"
          :value="
            currentDate ? formatDate(currentDate.toISOString()) : undefined
          "
          @input="change"
          @keydown.enter="decisionDate"
        />
      </template>
      <template v-slot:content>
        <Calendar
          :value="currentDate"
          :disabled-date="disabledDate"
          @pick="handleSelect"
          @clickoutside="clickCancel"
        ></Calendar>
      </template>
    </DatePicker>
  </div>
</template>

<style lang="scss" scoped>
:deep(.datepicker-datepicker-range) {
  width: initial;
  cursor: pointer;
}
:deep(.datepicker-input-wrapper) {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border: none;

  .c-text {
    margin-right: 4px;
  }

  input {
    width: 90px;
    height: 32px;
    text-align: center;
    padding: 0px 8px;
    border-color: $color-gray400;
  }

  i {
    display: none !important;
  }
}
</style>

<!-- eslint-disable-next-line vue/enforce-style-attribute -->
<style lang="scss">
// date pickerとカレンダーのcssはグローバルに設定する
$namespace: 'datepicker';
$default-color: #414141;
$primary-color: $color-green600;
@import 'vue-datepicker-next/scss/index.scss';

.datepicker-datepicker-main.datepicker-datepicker-popup {
  box-sizing: border-box;
  padding: 16px;
  margin: 0;
  border: 1px solid $color-border;
  border-radius: $border-radius;
  box-shadow: 0 1px 5px rgba(74, 74, 74, 0.25);
  transition: none;
  /** 
    memo: どのような状況でもカレンダーコンポーネントが前面に表示され、操作ができることを意図した指定になります
    Zag.jsのmodal状態でダイアログ外のものがpointer-events:noneの影響を受けることと、他のコンポーネントの背面になってしまうことを防止します
  */
  z-index: 99999;
  pointer-events: all !important;

  .datepicker-calendar-header {
    // donguri-uiのbuttonスタイルをリセット
    button {
      display: initial;
      height: initial;
      padding: 0;
      margin: 0;
      border-radius: initial;
      border: initial;

      &:hover {
        background-color: initial;
        border-radius: initial;
        border: initial;
      }
    }

    .datepicker-btn-icon-double-left,
    .datepicker-btn-icon-double-right {
      display: initial !important;
    }
  }

  .datepicker-table {
    border-spacing: 4px;
  }
  .datepicker-calendar-content .cell {
    border-radius: $border-radius;
    width: 26px;
    height: 26px;
    &:not(.not-current-month):not(.disabled):not(.active):not(.in-range) {
      border: 1px solid $color-border;
      &:hover {
        background-color: $color-border;
      }
    }
    &.active,
    &.in-range {
      color: #fff;
      border-color: $color-green600;
      background-color: $color-green600;
    }
  }
  .datepicker-datepicker-footer {
    border: none;
    display: flex;
    justify-content: flex-end;

    .dashboard-button:first-child {
      margin-right: 8px;
    }
  }
}
</style>
