<script lang="ts" setup>
import { DgrIcon } from '@stockmarkteam/donguri-ui';

defineProps<{ labels: string[]; deletable: boolean }>();
const emit = defineEmits<{
  (event: 'deleteTag', payload: string): void;
}>();
const deleteTag = (label: string) => {
  emit('deleteTag', label);
};
</script>

<template>
  <div class="labels">
    <div v-for="label in labels" :key="label" class="label">
      {{ label }}
      <button v-if="deletable" @click="deleteTag(label)" class="close-icon">
        <DgrIcon name="times" size="xs" />
      </button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.labels {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
}
.label {
  color: $color-gray1000;
  border: 1px solid $color-gray400;
  font-size: 14px;
  line-height: 22px;
  padding: 4px 12px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  gap: 8px;
}
.close-icon {
  width: 12px;
  height: 12px;
  padding: 2px;
  box-sizing: unset;
  border: none;
  font: inherit;
  color: inherit;
  background: none;
}
</style>
