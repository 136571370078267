<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import { NotificationUpdateThemeFeedType } from '@/types';
import { formatThemeName } from '@/utils/formatters';
import CardTemplate from './card-template.vue';

export default defineComponent({
  components: {
    CardTemplate,
  },
  props: {
    notification: {
      type: Object as PropType<NotificationUpdateThemeFeedType>,
      required: true,
    },
    isUnread: { type: Boolean, required: true },
  },
  setup(props) {
    const url = computed(() =>
      props.notification.theme.is_deleted
        ? undefined
        : `/themes/${props.notification.theme.id}`,
    );
    const formattedThemeName = computed(() =>
      formatThemeName(
        props.notification.theme.name,
        props.notification.theme.is_deleted,
      ),
    );

    return {
      url,
      formattedThemeName,
    };
  },
});
</script>
<template>
  <CardTemplate
    :type="notification.type"
    :url="url"
    :is-unread="isUnread"
    :created-at="notification.created_at"
    >{{ `テーマ「${formattedThemeName}」を更新しました。` }}
  </CardTemplate>
</template>
