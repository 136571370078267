import { useRouter } from 'vue-router';
import api from '@/api';
import { userDocumentSearchCache } from '@/apiCache';
import { StorageDetails, UseStorage } from './types';

const isConnected = async () =>
  (await api.headTeamAnewsStorageSyncHistory()) === 200;

const updateDetails = async (item: StorageDetails) => {
  if (await isConnected()) {
    item.isConnected = true;
    try {
      const status = await api.getTeamStorageStatus('anews_storage');
      switch (status.status) {
        case 'syncing':
          item.status = 'SYNCING';
          break;
        case 'synced':
          item.status = 'SYNCED';
          break;
        case 'sync_failed':
          item.status = 'SYNC_FAILED';
          break;
      }
      item.fileCount = status.file_count?.toLocaleString() ?? '-';
      item.syncStartedAt = status.sync_started_at ?? '-';
      item.syncEndedAt = status.sync_ended_at ?? '-';
    } catch {
      item.status = 'FETCH_ERROR';
      item.fileCount = '-';
      item.syncStartedAt = '-';
      item.syncEndedAt = '-';
      return;
    }
  } else {
    item.isConnected = false;
    item.status = 'NOT_CONNECTED';
    item.fileCount = '-';
    item.syncStartedAt = '-';
    item.syncEndedAt = '-';
  }
  item.isLoaded = true;
};

export const useAnewsStorage: () => UseStorage = () => {
  const router = useRouter();
  const connect = (item: StorageDetails) => {
    router.push({
      name: 'anewsStorageUpload',
      query: { storageStatus: item.status },
    });
  };

  const disconnect = (item: StorageDetails) => {
    return async () => {
      // 連携情報を削除
      await api.deleteTeamAnewsStorageDatasource();
      await updateDetails(item);
      // 認証情報を削除後、検索できなくなるため、社内情報に関するキャッシュをクリア
      userDocumentSearchCache.cleanup();
    };
  };

  return {
    connect,
    disconnect,
    updateDetails,
  };
};
