<script lang="ts">
import { DgrIcon, SizeTypes } from '@stockmarkteam/donguri-ui';
import { DateRange } from '@/types';

const defaultRange: DateRange = { from: new Date(), to: new Date() };
</script>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { FIRST_PUBLISHED_AT, searchPresetDates } from '@/constants';
import Datepicker from '@/components/common/datepicker.vue';
import { DateRangePreset } from '@/types';
import { formatDate } from '@/utils/formatters';
import { useOutsideClick } from '@/utils/composables/useOutsideClick';

type FontSizeType = 'small' | 'normal';

interface Props {
  range: DateRange;
  preset: DateRangePreset[];
  min: Date;
  max?: Date;
  disabled?: boolean;
  fontSize?: FontSizeType; // dongri-uiのクラスを付与 => 'small': c-text--s, 'normal': c-text--m
  iconSize?: SizeTypes; // 上記同様にiconのサイズ調整が可能
  isSearch?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  range: () => defaultRange,
  preset: () => searchPresetDates,
  min: () => FIRST_PUBLISHED_AT,
  fontSize: 'normal',
  iconSize: 'default',
  isSearch: false,
});

const emit = defineEmits<{
  'apply-date': [value: { presetKey: string; value: DateRange }];
}>();

const from = ref(props.range.from);
const to = ref(props.range.to);
const isShowingPopup = ref(false);
const rootRef = ref<Element | undefined>();
const fromDatePickerRef = ref<InstanceType<typeof Datepicker>>();
const toDatePickerRef = ref<InstanceType<typeof Datepicker>>();
const isClickedOutside = useOutsideClick([
  rootRef,
  '.datepicker-datepicker-main',
]);

const minForFrom = computed(() => {
  return props.min;
});

const maxForFrom = computed(() => {
  return to.value;
});

const minForTo = computed(() => {
  return from.value;
});

const maxForTo = computed(() => {
  return props.max;
});

/**
 * 期間指定してセレクトボックスの枠外を触られるなどの場合を考慮して、
 * 常にpropsの値を参照して表示する
 */
const presetLabel = computed(() => {
  const fromValue = props.range.from;
  const toValue = props.range.to;
  const found = props.preset.find(p => {
    if (fromValue && toValue && p.range.from && p.range.to) {
      return (
        formatDate(p.range.from.toISOString()) ===
          formatDate(fromValue.toISOString()) &&
        formatDate(p.range.to.toISOString()) ===
          formatDate(toValue.toISOString())
      );
    } else if (!fromValue && !toValue) {
      return true;
    }
    return false;
  });

  if (found) {
    return found.label;
  }

  const from = props.range.from?.toISOString() ?? '';
  const to = props.range.to?.toISOString() ?? '';
  return `${formatDate(from)} - ${formatDate(to)}`;
});

const togglePopup = () => {
  if (isShowingPopup.value) {
    isShowingPopup.value = false;
  } else {
    isShowingPopup.value = true;
  }
};

const isOpenCalendar = ref(true);

const fromDatesChanged = (date: Date) => {
  from.value = date;
  if (fromDatePickerRef.value) fromDatePickerRef.value.open = false;
};

const toDatesChanged = (date: Date) => {
  to.value = date;
  if (toDatePickerRef.value) toDatePickerRef.value.open = false;
};

const presetSelected = (selectedItem: string) => {
  const selectedPreset = props.preset.find(p => p.label === selectedItem);
  if (!selectedPreset) return;

  from.value = selectedPreset.range.from;
  to.value = selectedPreset.range.to;

  emitEvent();
};

const getPresetKey = (): string => {
  let presetKey = 'custom';
  const fromValue = from.value;
  const toValue = to.value;

  const found = props.preset.find(p => {
    if (fromValue && toValue && p.range.from && p.range.to) {
      return (
        formatDate(p.range.from.toISOString()) ===
          formatDate(fromValue.toISOString()) &&
        formatDate(p.range.to.toISOString()) ===
          formatDate(toValue.toISOString())
      );

      // すべての期間
    } else if (!fromValue && !toValue) {
      return true;
    }
    return false;
  });

  if (found) {
    presetKey = found.key;
  }
  return presetKey;
};

const emitEvent = () => {
  emit('apply-date', {
    presetKey: getPresetKey(),
    value: {
      from: from.value,
      to: to.value,
    },
  });

  isShowingPopup.value = false;
};

watch(
  () => [props.range.from, props.range.to],
  () => {
    from.value = props.range.from;
    to.value = props.range.to;
  },
);

watch(isClickedOutside, newValue => {
  if (newValue) {
    isShowingPopup.value = false;
  }
});
</script>

<template>
  <div ref="rootRef" class="daterange-selectbox">
    <button
      class="label"
      :class="{ disabled: disabled }"
      :disabled="disabled"
      @click="togglePopup"
    >
      <div>
        <div
          class="c-text"
          :class="fontSize === 'small' ? 'c-text--s' : 'c-text--m'"
        >
          {{ presetLabel }}
        </div>
      </div>
      <DgrIcon name="angle-down" class="icon-side-padding" :size="iconSize" />
    </button>

    <div class="popup-anchor">
      <div v-if="isShowingPopup && !disabled" class="popup">
        <button
          v-for="item in preset"
          :key="item.label"
          class="preset-item c-btn c-btnOutline"
          @click="presetSelected(item.label)"
        >
          {{ item.label }}
        </button>

        <div
          class="period-title c-text c-text--m"
          @click="isOpenCalendar = !isOpenCalendar"
        >
          <span>期間指定</span>
          <DgrIcon
            size="small"
            :name="isOpenCalendar ? 'chevron-up' : 'chevron-down'"
            class="toggle-icon"
          />
        </div>
        <template v-if="isOpenCalendar">
          <div class="datepicker-row">
            <div class="from-picker">
              <Datepicker
                class="datepicker"
                ref="fromDatePickerRef"
                :disabled-until="minForFrom"
                :disabled-later-than="maxForFrom"
                :date="from"
                @dates-changed="fromDatesChanged"
                :is-search="isSearch"
              />
            </div>
            <span style="margin-left: 4px; margin-right: 8px">〜</span>
            <div class="to-picker">
              <Datepicker
                class="datepicker"
                ref="toDatePickerRef"
                :disabled-until="minForTo"
                :disabled-later-than="maxForTo"
                :date="to"
                @dates-changed="toDatesChanged"
                :is-search="isSearch"
              />
            </div>
          </div>
          <div style="display: flex; justify-content: flex-end">
            <button
              class="o-submit-button c-btn c-btnOutline"
              @click="emitEvent"
            >
              適用
            </button>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.label {
  display: flex;
  align-items: center;
  color: $color-gray1000;
  background: #fff;
  height: 32px;
  border: 1px solid $color-border;
  width: 100%;
  justify-content: space-between;
  padding-left: 8px;
  padding-right: 8px;
  .icon {
    padding-left: 4px;
  }

  .about-range {
    color: $color-gray600;
    text-align: right;
  }

  &.disabled {
    color: $color-gray400;
    cursor: not-allowed;

    .about-range {
      color: $color-gray400;
    }

    :deep(.icon-box) {
      fill: $color-gray400;
    }
  }

  // donguri-uiのsm-selectboxのアイコン横スペースを再現するためにどうしても必要
  .icon-side-padding {
    padding-left: 4px;
    padding-right: 0;
  }
}

.popup-anchor {
  position: relative;
  align-self: flex-end;
  z-index: 1;

  .popup {
    position: absolute;
    border-radius: 4px;
    width: 256px;
    padding: 12px 8px;
    background: white;
    box-shadow: 0 1px 5px rgb(74 74 74 / 25%);

    .preset-item {
      height: auto;
      padding: 0;
      border: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 12px;

      &:hover {
        background-color: #ffffff;
      }
    }

    .period-title {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      gap: 2px;
      cursor: pointer;

      .toggle-icon {
        margin-left: 8px;
      }
    }

    .datepicker-row {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .o-submit-button {
      width: 60px;
      height: auto;
      margin: 0;
      padding: 0;
      border: 0;
      color: $color-green600;
      margin-top: 12px;
      &:hover {
        background-color: #ffffff;
      }
    }
  }
}
</style>
