import { AdpDocument, Part, UserDocument } from '@/types';

const SPACING_CLASS_08 = 'spacing-08';
const DELAY_PER_CHAR = 0.005;
const NON_SPACING_NODE_NAMES: ReadonlyArray<Node['nodeName']> = [
  'UL',
  'OL',
  'TABLE',
]; // `\n`だけの文字列の場合に、spacingクラスを付与したdivを追加しないnodeName

/**
 * - 元のDOM構造を引き継ぎつつ、`[^${num}]`の正規表現にマッチする記事引用インデックス番号部分をコンポーネントに置き換えるためのparser
 * - 実際の描画は`summary-render-content.vue`で行っている
 *
 * @param node パース対象のNode
 * @param accumulatedDelay 累積のアニメーション遅延時間
 * @param parentDomOrder 親要素のDOM順序
 */
export const processNode = (
  node: Node,
  accumulatedDelay = 0,
  parentDomOrder = 1,
  sourceDocuments: Array<AdpDocument | UserDocument> = [],
): { processedParts: Part[]; updatedDelay: number } => {
  const parts: Part[] = [];
  let currentDelay = accumulatedDelay;

  node.childNodes.forEach((child: Node) => {
    if (child.nodeType === Node.TEXT_NODE) {
      const citationRegex = /\[\^(\d+)\]/g;
      let lastIndex = 0;
      let match;
      const text = child.nodeValue || '';

      // 改行文字列だけの場合は、donguri-uiの`spacingクラス`を適用したdivタグを追加する
      // ただし、 「スペースを追加したくない要素内の場合」 と 「直前が見出し要素の場合」 は過度な隙間やスタイル崩れが発生するので追加しない
      if (text === '\n') {
        if (isInsideNonSpacingElement(child) || isPreviousHeading(parts))
          return;
        currentDelay += DELAY_PER_CHAR;
        parts.push({
          type: 'element',
          tagName: 'div',
          attributes: { class: SPACING_CLASS_08 },
          children: [],
          delay: currentDelay,
          parentDomOrder: parentDomOrder,
        });
        return;
      }

      while ((match = citationRegex.exec(text)) !== null) {
        const citationNumber = parseInt(match[1]);
        const sourceDocument = sourceDocuments[citationNumber - 1]; // バックエンドではGPTに引用した記事のインデックスを1始まりで渡しているため、マイナス1する
        if (sourceDocument) {
          if (lastIndex < match.index) {
            const partialText = text.slice(lastIndex, match.index);
            const textParts: Part[] = partialText
              .split('')
              .map((char, index) => ({
                type: 'element',
                tagName: 'span',
                attributes: {},
                children: [
                  {
                    type: 'text',
                    content: char,
                    delay: currentDelay + index * DELAY_PER_CHAR,
                    parentDomOrder: parentDomOrder,
                  },
                ],
                delay: currentDelay + index * DELAY_PER_CHAR,
                parentDomOrder: parentDomOrder,
              }));
            parts.push(...textParts);
            currentDelay += partialText.length * DELAY_PER_CHAR;
          }
          currentDelay += DELAY_PER_CHAR;
          parts.push({
            type: 'citationNumberBadge',
            number: citationNumber,
            sourceDocument,
            delay: currentDelay,
            parentDomOrder: parentDomOrder,
          });
          lastIndex = citationRegex.lastIndex;
        }
      }

      if (lastIndex < text.length) {
        const remainingText = text.slice(lastIndex);
        const textParts: Part[] = remainingText
          .split('')
          .map((char, index) => ({
            type: 'element',
            tagName: 'span',
            attributes: {},
            children: [
              {
                type: 'text',
                content: char,
                delay: currentDelay + index * DELAY_PER_CHAR,
                parentDomOrder: parentDomOrder,
              },
            ],
            delay: currentDelay + index * DELAY_PER_CHAR,
            parentDomOrder: parentDomOrder,
          }));
        parts.push(...textParts);
        currentDelay += remainingText.length * DELAY_PER_CHAR;
      }
    } else if (child.nodeType === Node.ELEMENT_NODE) {
      const parentDomDelay = currentDelay + DELAY_PER_CHAR;
      const element = child as Element;
      const { processedParts, updatedDelay } = processNode(
        element,
        parentDomDelay,
        parentDomOrder,
        sourceDocuments,
      );
      currentDelay = updatedDelay;

      parts.push({
        type: 'element',
        tagName: element.tagName.toLowerCase(),
        attributes: Array.from(element.attributes).reduce<{
          [key: string]: string;
        }>((attrs, attr) => {
          attrs[attr.name] = attr.value;
          return attrs;
        }, {}),
        children: processedParts,
        delay: parentDomDelay,
        parentDomOrder: parentDomOrder,
      });
    }
    parentDomOrder++;
  });
  return { processedParts: parts, updatedDelay: currentDelay };
};

/**
 * spacingクラスを付与したdivを追加したくない要素内か判定する
 */
const isInsideNonSpacingElement = (node: Node): boolean => {
  let currentNode: Node | null = node; // 引数として渡された node を上書きしないように新しい変数を用意する
  while (currentNode) {
    if (NON_SPACING_NODE_NAMES.includes(currentNode.nodeName)) return true;
    currentNode = currentNode.parentNode as Node;
  }
  return false;
};

/** 直前がh1やh2などの見出し要素か判定する */
const isPreviousHeading = (parts: Part[]): boolean => {
  if (parts.length === 0) {
    return false;
  }
  const lastPart = parts[parts.length - 1];
  return (
    lastPart.type === 'element' &&
    ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(lastPart.tagName)
  );
};
