import { UserInfo } from '@/types';

declare global {
  // declare globalでグローバル変数を宣言するにはvarを使う必要がある
  // 参考: https://zenn.dev/qnighy/articles/9c4ce0f1b68350#declare-global
  /* eslint-disable-next-line no-var */
  var krt:
    | ((
        action: 'send',
        type: 'identify',
        info: {
          user_id: string;
          isInternalUser: boolean;
          team_id: string;
        },
      ) => void)
    | undefined;
}

const STOCKMARK_MAIL_DOMAIN = '@stockmark.co.jp';
const STOCKMARK_TEAM_ID = 1;

export const setupWicle = (userInfo: UserInfo): void => {
  if (import.meta.env.VITE_ENV !== 'development') {
    // 顧客環境内のサポートユーザーもあるので、メールの@stockmark.co.jpのドメインで判定しつつ、
    // STOCKMARKチームにはメールが@stockmark.co.jpではないユーザーもいるのでチームIDでも判定する
    const isInternalUser =
      userInfo.email.endsWith(STOCKMARK_MAIL_DOMAIN) ||
      userInfo.team_id === STOCKMARK_TEAM_ID;

    const wicleScriptId = 'wicle-script';
    let wicleScript = document.getElementById(wicleScriptId);
    if (!wicleScript) {
      const wicleCode = document.createElement('script');
      wicleCode.setAttribute('type', 'text/javascript');
      wicleCode.textContent =
        '!function(n){var o=window[n]=function(){var n=[].slice.call(arguments);return o.x?o.x.apply(0,n):o.q.push(n)};o.q=[],o.i=Date.now(),o.allow=function(){o.o="allow"},o.deny=function(){o.o="deny"}}("krt")';
      document.head.appendChild(wicleCode);

      wicleScript = document.createElement('script');
      wicleScript.setAttribute('id', wicleScriptId);
      wicleScript.setAttribute('type', 'text/javascript');
      wicleScript.setAttribute('defer', '');
      wicleScript.setAttribute(
        'src',
        'https://cdn-edge.karte.io/220753d2eff550c41445b311a7d7580a/edge.js',
      );
      document.head.appendChild(wicleScript);
    }

    window.krt?.('send', 'identify', {
      user_id: String(userInfo.id),
      isInternalUser: isInternalUser,
      team_id: String(userInfo.team_id),
    });
  }
};

export const resetWicle = (): void => {
  window.krt?.('send', 'identify', {
    user_id: '',
    isInternalUser: false,
    team_id: '',
  });
};
