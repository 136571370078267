<script lang="ts">
import {
  computed,
  defineComponent,
  nextTick,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  watch,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import api from '@/api';
import {
  MAX_DESCRIPTION_LENGTH,
  MAX_KEYWORDS_LENGTH,
  MAX_SITE_EXCLUSIONS,
  MAX_THEME_NAME_LENGTH,
  SITE_API_DEBOUNCE_WAIT_MS,
  SITES_COUNT_PER_REQUEST,
  THEME_PREVIEW_DEBOUNCE_TIME,
} from '@/constants';
import {
  DgrCheckbox,
  DgrIcon,
  DgrRadio,
  DgrSelectbox,
} from '@stockmarkteam/donguri-ui';
import AutocompleteInput from '@/components/common/autocomplete-input.vue';
import Loading from '@/components/common/loading.vue';
import TextTabItem from '@/components/common/molecules/text-tab-item.vue';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import ThemeKeywordEditable from '@/components/common/theme-keyword-editable.vue';
import ThemeKeywordRecommend from '@/components/common/theme-keyword-recommend.vue';
import Header from '@/components/layouts/header.vue';
import CheckboxList from '@/components/theme/edit/checkbox-list.vue';
import SiteSelect from '@/components/theme/edit/site-select.vue';
import ThemeEditSupport from '@/components/theme/edit/theme-edit-support.vue';
import ThemePreview from '@/components/theme/edit/theme-preview.vue';
import ThemeTitleContent from '@/components/theme/theme-title-content.vue';
import { DocType, Perspective, Site, Theme, ThemeTopic } from '@/types';
import { Lang } from '@/types';
import { themeEditable } from '@/utils/authorization';
import useSiteCategoryFilter from '@/utils/composables/useSiteCategoryFilter';
import { debounce, DebounceRef } from '@/utils/debounce';
import { formatTimestamp } from '@/utils/formatters';
import {
  getAllSiteCategories,
  getExcludedSiteCategories,
  getIncludedSiteCategories,
  getSiteCategoriesValueForSearch,
  SearchSiteCategory,
  SiteCategory,
} from '@/utils/siteCategories';
import { ALLIANCE_MEDIA_VALUE } from '@/utils/siteCategories';
import {
  STATES,
  useGroups,
  useJobTypes,
  useTeamInfo,
  useTeamUsers,
  useThemeList,
  useUserInfo,
} from '@/utils/swr';
import {
  getSelectedQueries,
  getTabs,
  getThemeHeaderDetail,
} from '@/utils/theme';
import { userSession } from '@/utils/userSession';
import {
  ErrorMessages,
  validateKeyword,
  validateKeywordList,
  validateMultiKeywords,
} from '@/utils/validators';
import { useEmitter, useStore } from '@/utils/vue';

type EditKeywordType = 'ベーシック' | 'カスタム';

type RouteQuery = {
  query: string;
  selectedSearchSiteCategories: SearchSiteCategory[];
  lang: Lang[];
  isEntertainmentSitesFiltered: string;
};

export default defineComponent({
  components: {
    AutocompleteInput,
    Header,
    SiteSelect,
    ThemePreview,
    TextTabItem,
    ThemeKeywordEditable,
    ThemeKeywordRecommend,
    ThemeEditSupport,
    Loading,
    ThemeTitleContent,
    CheckboxList,
    DgrIcon,
    DgrCheckbox,
    DgrSelectbox,
    DgrRadio,
  },
  props: {
    isMenuVisible: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const emitter = useEmitter();
    const { createSnackbar } = useSnackbar();

    const {
      selectedSiteCategories,
      selectedSearchSiteCategories,
      addSearchSiteCategory,
      removeSearchSiteCategory,
      searchSiteCategoriesOptions,
      includeAllianceMedia,
      excludeAllianceMedia,
      isAllianceMediaEnabled,
    } = useSiteCategoryFilter();

    const routeQuery = ref<RouteQuery | undefined>(undefined);
    const feedType = computed(() => store.state.feedType.feedType);

    const { data: userInfo } = useUserInfo();
    const { data: groups } = useGroups();
    const { data: teamUsers } = useTeamUsers();
    const { data: teamInfo } = useTeamInfo();
    const {
      data: themes,
      state: themeState,
      mutate: themeMutate,
    } = useThemeList(feedType);
    const { data: jobTypes, state: jobTypesState } = useJobTypes();

    const themeInfo = ref<Theme | undefined>(undefined);

    const tabs = computed(() =>
      !isCreate.value && themeInfo.value?.id
        ? getTabs(feedType.value, themeInfo.value, teamInfo.value)
        : undefined,
    );
    const enabledSystemNotificationMail = computed(
      () => userInfo.value?.enabled_system_notification_mail,
    );

    const editKeywordsTabs: EditKeywordType[] = ['ベーシック', 'カスタム'];
    const selectedEditKeywordType = ref<EditKeywordType>('ベーシック');
    const newKeyword = ref('');
    const newKeywordErrors = computed(() => {
      const errors = validateKeyword(newKeyword.value);
      if (newKeyword.value) {
        const uniqueKeywords = Array.from(new Set(keywordList.value));
        errors.push(
          ...validateMultiKeywords([newKeyword.value, ...uniqueKeywords]),
        );
      }
      const errorMessages = errors.map(error => ErrorMessages[error]);
      if (
        newKeyword.value.length > 0 &&
        newKeyword.value.length +
          trimmedKeywords.value.replace(/\n/g, '').length >
          MAX_KEYWORDS_LENGTH
      ) {
        errorMessages.push(
          `キーワードは合計で${MAX_KEYWORDS_LENGTH}文字以内になるように入力してください。`,
        );
      }
      return errorMessages;
    });
    const canAddNewKeyword = computed(
      () => newKeyword.value !== '' && newKeywordErrors.value.length === 0,
    );
    const addKeywordToList = () => {
      if (newKeyword.value === '') return;

      keywordList.value.unshift(newKeyword.value);
      newKeyword.value = '';
    };
    const removeKeywordFromList = (index: number) => {
      const backup = keywordList.value.slice();
      keywordList.value.splice(index, 1);
      createSnackbar({
        message: `キーワードを削除しました`,
        type: 'success',
        undoFunc: () => {
          keywordList.value = backup;
        },
      });
    };
    const updateKeywordList = (index: number, keyword: string) => {
      keywordList.value.splice(index, 1, keyword);
    };

    const themeId = ref(Number(route.params.themeId ?? 0));
    const trimmedThemeName = ref('');
    const trimmedDescription = ref('');
    const trimmedKeywords = computed({
      get: () => keywordList.value.join('\n'),
      set: (keywords: string) => {
        if (keywords.trim() === '') keywordList.value = [];
        else keywordList.value = keywords.split('\n');
      },
    });
    const isTooltipKeywordVisible = ref(false);

    const isForeignFeedAvailable = computed(
      () => teamInfo.value?.enable_foreign_lang_media,
    );
    const isTechnicalResearchAvailable = computed(
      () => teamInfo.value?.enable_technical_literature,
    );
    const isChineseFeedAvailable = computed(
      () => teamInfo.value?.enable_chinese_lang_media,
    );

    const isForeignMediaEnabled = ref(
      teamInfo.value?.enable_foreign_lang_media ?? false,
    );
    const isResearchPaperEnabled = ref(
      isTechnicalResearchAvailable.value ?? false,
    );
    const isPatentEnabled = ref(isTechnicalResearchAvailable.value ?? false);
    const isChinese = ref(false);
    const isEntertainmentSitesFiltered = ref(true);
    const isInitialTheme = ref(false);
    const siteLimit = ref(MAX_SITE_EXCLUSIONS);
    const selectedScope = ref('');
    const themeNameRef = ref<HTMLImageElement>();
    const selectedTopicKeywordList: ThemeTopic[] = [];
    const isEmailNotificationEnabled = ref<boolean>(true);

    const sites = reactive({ items: [] as Site[] });
    const initialSelectedSites = reactive({ items: [] as Site[] });
    const selectedSites = reactive({ items: [] as Site[] });
    const syntaxErrorKeywords = reactive({ items: [] as string[] });

    const isCreate = computed(() => route.name === 'themeCreate');
    const groupList = computed(() => groups.value?.groups ?? []);
    const teamUsersList = computed(() => teamUsers.value?.users ?? []);
    const themeGroup = computed(() =>
      groups.value?.groups.find(g => g.id === themeInfo.value?.group_id),
    );
    const excludeSiteKeyword = ref('');
    const hasNextExcludeSites = ref(false); // 指定した数以上のデータがあるかどうかのフラグ

    // サイト情報を取得する
    const setSiteItems = async () => {
      // 指定した数以上のデータがあるか判断したいので + 1している
      const siteList = await api.getSites(
        false,
        1,
        SITES_COUNT_PER_REQUEST + 1,
        excludeSiteKeyword.value,
        selectedSites?.items.map(s => s.id),
      );
      sites.items = siteList.slice(0, SITES_COUNT_PER_REQUEST);
      hasNextExcludeSites.value = siteList.length > SITES_COUNT_PER_REQUEST;
    };

    const hasPermission = computed(
      () =>
        isCreate.value ||
        themeEditable(userInfo.value, themeInfo.value, themeGroup.value),
    );
    const hasThemeScopeSetting = computed(
      () =>
        selectedScope.value !== '' &&
        hasPermission.value &&
        scopes.value.length > 0 &&
        groupList.value.length > 0 &&
        (isCreate.value || themeInfo.value?.access_scope),
    );
    const hasInitialThemeSetting = computed(
      () =>
        selectedScope.value === 'public' && userInfo.value?.role === 'admin',
    );
    const keywordList = ref<string[]>([]);
    const debouncedKeywordList = DebounceRef(
      keywordList,
      THEME_PREVIEW_DEBOUNCE_TIME,
    );
    const tooLongThemeName = computed(() => {
      return trimmedThemeName.value.length > MAX_THEME_NAME_LENGTH;
    });
    const tooLongDescription = computed(
      () => trimmedDescription.value.length > MAX_DESCRIPTION_LENGTH,
    );
    const tooLongKeywords = computed(
      () =>
        trimmedKeywords.value.replace(/\n/g, '').length > MAX_KEYWORDS_LENGTH,
    );
    const keywordErrors = computed(() =>
      validateKeywordList(keywordList.value),
    );
    const debouncedKeywordErrors = DebounceRef(
      keywordErrors,
      THEME_PREVIEW_DEBOUNCE_TIME,
    );
    const submitBtnText = computed(() => (isCreate.value ? '作成' : '保存'));
    const submitBtnWithoutEmailText = computed(
      () => `${submitBtnText.value}（テーマ更新後のメール通知なし）`,
    );
    const isSaving = ref(false);

    const keywordsErrorMessages = computed(() => {
      const errorMessages: string[] = [];
      if (tooLongKeywords.value) {
        errorMessages.push(
          `キーワードは${MAX_KEYWORDS_LENGTH}文字以内で入力してください。`,
        );
      }
      errorMessages.push(
        ...keywordErrors.value.map(error => ErrorMessages[error]),
      );
      if (
        keywordErrors.value.length === 0 &&
        syntaxErrorKeywords.items.length > 0
      ) {
        errorMessages.push('以下の入力内容に誤りがあります。');
        syntaxErrorKeywords.items.forEach(function (keyword) {
          errorMessages.push('・' + keyword);
        });
      }
      return errorMessages;
    });

    const isAdminEditOtherUserTheme = computed(
      () =>
        userInfo.value?.role === 'admin' &&
        themeInfo.value?.access_scope === 'personal' &&
        userInfo.value?.id !== themeInfo.value?.user_id,
    );

    const scopes = computed(() => {
      const groupScopes = (groups.value?.groups ?? [])
        .filter(
          g =>
            themeInfo.value?.group_id === g.id ||
            (g.group_type === 'specific_user_group' && g.is_member),
        )
        .map(g => ({ label: g.name, value: `group-${g.id}` }));
      const viewerScopes = [{ label: '自分のみ', value: 'personal' }];
      const publicPersonalScopes = [
        { label: '全体', value: 'public' },
        { label: '自分のみ', value: 'personal' },
      ];
      if (isAdminEditOtherUserTheme.value) {
        const userName = teamUsers.value?.users.find(
          u => u.id === themeInfo.value?.user_id,
        )?.user_name;
        publicPersonalScopes.push({
          label: `${userName}のみ`,
          value: `personal-${themeInfo.value?.user_id}`,
        });
      }
      const scopes = publicPersonalScopes.concat(groupScopes);
      return userInfo.value?.role === 'viewer' ? viewerScopes : scopes;
    });

    const enableToUpdate = computed(() => {
      return (
        trimmedThemeName.value &&
        trimmedKeywords.value &&
        !tooLongThemeName.value &&
        !tooLongDescription.value &&
        !tooLongKeywords.value &&
        keywordErrors.value.length === 0 &&
        ((isAllianceMediaEnabled.value && includeAllianceMedia.value) ||
          selectedSiteCategories.value.length)
      );
    });

    const isStockmarkUser = computed(() => {
      const email = userInfo.value?.email ?? '';
      return (
        email.includes('@stockmark.co.jp') || email.includes('@anews.co.jp')
      );
    });

    onBeforeUnmount(() => {
      window.removeEventListener('click', hideTooltip);
    });

    onMounted(async () => {
      nextTick(() => themeNameRef.value?.focus());
      window.addEventListener('click', hideTooltip);
      await updateThemeInfo();
      setSelectedScope();
      await setSiteItems();
      if (
        themeInfo.value?.is_chinese &&
        !teamInfo.value?.enable_chinese_lang_media
      ) {
        router.push({ name: 'themeFeed' });
      }

      // 検索画面からの遷移でqueryが渡されたとき
      if (route.query) {
        const searchQuery = route.query.query;
        if (typeof searchQuery === 'string') {
          trimmedThemeName.value = searchQuery;
          keywordList.value.push(searchQuery);
        }
        const searchExpandedQueries = route.query.expandedQueries;
        if (searchExpandedQueries && Array.isArray(searchExpandedQueries)) {
          const filteredSearchExpandedQueries = searchExpandedQueries.filter(
            x => x !== searchQuery,
          );
          filteredSearchExpandedQueries.forEach(expandedQuery => {
            if (typeof expandedQuery === 'string')
              keywordList.value.push(expandedQuery);
          });
        } else if (typeof searchExpandedQueries === 'string') {
          keywordList.value.push(searchExpandedQueries);
        }
        routeQuery.value = route.query as unknown as RouteQuery;
        router.replace({
          query: undefined,
        });
      }
    });

    watch(
      // テーマ編集画面からテーマ作成画面に遷移した際にフォームの値をリセットする
      () => route.path,
      () => {
        if (isCreate.value) {
          trimmedThemeName.value = '';
          trimmedDescription.value = '';
          trimmedKeywords.value = '';
          selectedSites.items = [];
          setIsForeignMediaEnabled();
          setIsResearchPaperEnabled();
          setIsPatentEnabled();
          setIsChinese();
          isEntertainmentSitesFiltered.value = true;
          isInitialTheme.value = false;
          selectedScope.value =
            userInfo.value?.role === 'viewer' ? 'personal' : 'public';
          setSelectedSearchSiteCategories();
        }
      },
    );

    watch(
      () => teamInfo.value,
      () => {
        setIsForeignMediaEnabled();
        setIsResearchPaperEnabled();
        setIsPatentEnabled();
        setIsChinese();
      },
    );

    watch(
      () => isChinese.value,
      () => {
        if (isChinese.value) {
          isForeignMediaEnabled.value = true;
          isEntertainmentSitesFiltered.value = false;
        } else {
          isForeignMediaEnabled.value = false;
          isEntertainmentSitesFiltered.value = true;
        }
      },
    );

    watch(
      () => excludeSiteKeyword.value,
      debounce(() => {
        setSiteItems();
      }, SITE_API_DEBOUNCE_WAIT_MS),
    );

    watch(
      () => routeQuery.value,
      () => {
        if (isCreate.value) {
          setIsForeignMediaEnabled();
          setIsEntertainmentSitesFiltered();
          setSelectedSearchSiteCategories();
        }
      },
    );

    // リリース時点で職種データが未登録の場合、トピックが登録されていないためキーワード追加の導線を非表示にする
    const isJobTypeReady = computed(
      () =>
        jobTypesState.value === STATES.SUCCESS &&
        jobTypes.value &&
        jobTypes.value.job_types.length > 0,
    );

    const updateThemeInfo = async () => {
      if (!themeId.value) {
        return;
      }
      try {
        themeInfo.value = await api.fetchTheme(themeId.value);
        initialSelectedSites.items.push(
          ...(themeInfo.value?.excluded_sites ?? []),
        );
        selectedSites.items.push(...(themeInfo.value?.excluded_sites ?? []));
        isEmailNotificationEnabled.value =
          themeInfo.value?.is_email_notification_enabled ?? true;
      } catch {
        redirectToHome('指定されたテーマは存在しません');
        return;
      }

      if (themeInfo.value?.is_deleted) {
        redirectToHome('指定されたテーマは存在しません');
        return;
      }
      trimmedThemeName.value = themeInfo.value.name;
      trimmedDescription.value = themeInfo.value.description ?? '';
      keywordList.value = [...themeInfo.value.keywords];
      setIsForeignMediaEnabled();
      setIsResearchPaperEnabled();
      setIsPatentEnabled();
      setIsChinese();
      isEntertainmentSitesFiltered.value =
        themeInfo.value.is_entertainment_sites_filtered;
      isInitialTheme.value = themeInfo.value.is_initial_theme;
      if (Array.isArray(themeInfo.value.excluded_site_categories)) {
        selectedSearchSiteCategories.value = [
          ...getIncludedSiteCategories(
            themeInfo.value.excluded_site_categories as SiteCategory[],
          ),
          ...(!themeInfo.value.exclude_alliance_media
            ? [ALLIANCE_MEDIA_VALUE]
            : []),
        ] as SiteCategory[];
      } else {
        selectedSearchSiteCategories.value = [
          ...getSiteCategoriesValueForSearch(getAllSiteCategories()),
          ...(!themeInfo.value.exclude_alliance_media
            ? [ALLIANCE_MEDIA_VALUE]
            : []),
        ] as SiteCategory[];
      }
    };

    const setSelectedScope = () => {
      if (userInfo.value?.role === 'viewer') {
        selectedScope.value = 'personal';
      } else if (themeInfo.value?.access_scope === 'group') {
        selectedScope.value = `group-${themeInfo.value.group_id}`;
      } else if (themeInfo.value?.access_scope === 'personal') {
        if (isAdminEditOtherUserTheme.value) {
          selectedScope.value = `personal-${themeInfo.value.user_id}`;
        } else {
          selectedScope.value = `personal`;
        }
      } else {
        selectedScope.value = 'public';
      }
    };

    const redirectToHome = (message: string) => {
      router.push({ name: 'anewsHome' });
      createSnackbar({
        message: message,
        type: 'error',
      });
    };

    const setIsForeignMediaEnabled = () => {
      if (routeQuery.value && Array.isArray(routeQuery.value.lang)) {
        // 検索画面の「メディア種別」で「国内」のみ選択されている場合は
        // テーマ作成画面の「ニュース配信設定」> 「海外メディアの情報を収集する」の初期状態を未チェックにする
        if (
          routeQuery.value.lang.length === 1 &&
          routeQuery.value.lang[0] === 'ja'
        ) {
          isForeignMediaEnabled.value = false;
        }
      } else {
        isForeignMediaEnabled.value = teamInfo.value?.enable_foreign_lang_media
          ? themeInfo.value?.is_foreign_media_enabled ?? true
          : false;
      }
    };

    const setIsEntertainmentSitesFiltered = () => {
      if (
        routeQuery.value &&
        Object.prototype.hasOwnProperty.call(
          routeQuery.value,
          'isEntertainmentSitesFiltered',
        )
      ) {
        isEntertainmentSitesFiltered.value = !!Number(
          routeQuery.value.isEntertainmentSitesFiltered,
        );
      }
    };

    const setSelectedSearchSiteCategories = () => {
      if (Array.isArray(routeQuery.value?.selectedSearchSiteCategories)) {
        selectedSearchSiteCategories.value = routeQuery.value
          ?.selectedSearchSiteCategories as SearchSiteCategory[];
      } else {
        selectedSearchSiteCategories.value = getAllSiteCategories();
      }
    };

    const setIsResearchPaperEnabled = () => {
      isResearchPaperEnabled.value = isTechnicalResearchAvailable.value
        ? themeInfo.value?.is_research_paper_enabled ?? true
        : false;
    };

    const setIsPatentEnabled = () => {
      isPatentEnabled.value = isTechnicalResearchAvailable.value
        ? themeInfo.value?.is_patent_enabled ?? true
        : false;
    };

    const setIsChinese = () => {
      isChinese.value =
        teamInfo.value?.enable_foreign_lang_media &&
        teamInfo.value?.enable_chinese_lang_media
          ? themeInfo.value?.is_chinese ?? false
          : false;
    };

    const hideTooltip = () => {
      isTooltipKeywordVisible.value = false;
    };

    const showThemeSettingsInfoModal = () => {
      store.commit('modal/showModal', 'themeSettingsInfo');
    };

    const selectScope = ({ value }: { value: string }) => {
      selectedScope.value = value;
    };

    const saveTheme = async (skipEmail = false) => {
      isSaving.value = true;
      if (selectedScope.value !== 'public') {
        isInitialTheme.value = false;
      }
      if (isCreate.value) {
        await createTheme(skipEmail);
      } else {
        await updateTheme(skipEmail);
      }
      isSaving.value = false;
    };

    const createTheme = async (skipEmail = false) => {
      isCreatingTheme.value = true;
      const [scope, group_id] = selectedScope.value.split('-');
      try {
        const createdTheme = await api.createTheme(
          trimmedThemeName.value,
          trimmedDescription.value,
          keywordList.value,
          [],
          [],
          isForeignMediaEnabled.value || isChinese.value,
          isResearchPaperEnabled.value,
          isPatentEnabled.value,
          isChinese.value,
          isEntertainmentSitesFiltered.value,
          isInitialTheme.value,
          selectedSites.items.map(i => i.id),
          scope as 'personal' | 'public' | 'group',
          scope === 'group' ? Number(group_id) : undefined,
          undefined,
          skipEmail,
          getExcludedSiteCategories(selectedSiteCategories.value),
          isAllianceMediaEnabled.value ? excludeAllianceMedia.value : false,
        );
        await store.dispatch('themeList/fetchThemeList');
        await themeMutate();
        await api.addUserTheme(createdTheme.id);
        createdTheme.is_member = true;
        const listTheme = themes.value?.themes.find(
          t => t.id === createdTheme.id,
        );
        if (listTheme != undefined) listTheme.is_member = true;
        store.commit('themeList/updateTheme', listTheme);

        emitter.emit(
          'theme-updated',
          themes.value?.themes.find(t => t.id === createdTheme.id),
        );

        router.push({ path: `/themes/${createdTheme.id}` });
        const message = `テーマを作成しました。現在テーマを更新中です。更新後に${
          skipEmail ? 'お知らせ画面' : 'お知らせ画面とメール'
        }で通知します。`;
        createSnackbar({
          message,
          type: 'success',
        });
      } catch {
        createSnackbar({
          message: 'テーマを作成できませんでした',
          type: 'error',
        });
      } finally {
        isCreatingTheme.value = false;
      }
    };

    const updateTheme = async (skipEmail = false) => {
      const [scope, target_id] = selectedScope.value.split('-');
      const updatedThemeInfo = {
        id: themeId.value,
        name: trimmedThemeName.value,
        description: trimmedDescription.value,
        keywords: keywordList.value,
        is_foreign_media_enabled: isForeignMediaEnabled.value,
        is_research_paper_enabled: isResearchPaperEnabled.value,
        is_patent_enabled: isPatentEnabled.value,
        is_chinese: isChinese.value,
        is_entertainment_sites_filtered: isEntertainmentSitesFiltered.value,
        is_initial_theme: isInitialTheme.value,
        site_exclusion_ids: selectedSites.items.map(i => i.id),
        access_scope: scope as 'personal' | 'public' | 'group',
        group_id:
          scope === 'group' && target_id ? Number(target_id) : undefined,
        user_id:
          scope === 'personal' && target_id ? Number(target_id) : undefined,
        excluded_site_categories: getExcludedSiteCategories(
          selectedSiteCategories.value,
        ),
        exclude_alliance_media: isAllianceMediaEnabled.value
          ? excludeAllianceMedia.value
          : false,
      };

      const isUpdatingFeedSettings =
        !isEqual(keywordList.value, themeInfo.value?.keywords ?? []) ||
        isForeignMediaEnabled.value !==
          themeInfo.value?.is_foreign_media_enabled ||
        isChinese.value !== themeInfo.value.is_chinese ||
        isEntertainmentSitesFiltered.value !==
          themeInfo.value.is_entertainment_sites_filtered ||
        isInitialTheme.value !== themeInfo.value.is_initial_theme ||
        !isEqual(
          initialSelectedSites.items.map(s => s.id).sort(),
          selectedSites.items.map(s => s.id).sort(),
        ) ||
        !isEqual(
          getExcludedSiteCategories(selectedSiteCategories.value).sort(),
          themeInfo.value.excluded_site_categories?.sort() ?? [],
        ) ||
        (isAllianceMediaEnabled &&
          themeInfo.value.exclude_alliance_media !==
            excludeAllianceMedia.value);
      const update = async () => {
        try {
          await api.updateTheme(updatedThemeInfo, { skipEmail });

          const listTheme = themes.value?.themes.find(
            t => t.id === Number(themeId.value),
          );
          if (
            listTheme !== undefined &&
            themeInfo.value !== undefined &&
            !listTheme.is_member &&
            updatedThemeInfo.access_scope === 'personal'
          ) {
            api.addUserTheme(themeId.value);
            themeInfo.value.is_member = true;
            listTheme.is_member = true;
            store.commit('themeList/updateTheme', listTheme);
          }
        } catch {
          createSnackbar({
            message: 'テーマの設定を更新できませんでした',
            type: 'error',
          });
        }
        await store.dispatch('themeList/fetchThemeList');

        emitter.emit('theme-updated', {});

        router.push({ name: 'themeFeed' });
        let message = 'テーマを保存しました。';
        if (isUpdatingFeedSettings) {
          message += `現在テーマを更新中です。更新後に${
            skipEmail ? 'お知らせ画面' : 'お知らせ画面とメール'
          }で通知します。`;
        }
        createSnackbar({
          message: message,
          type: 'success',
        });
      };

      if (
        themeInfo.value?.access_scope !== 'personal' &&
        scope === 'personal'
      ) {
        const payload = {
          headerText: 'テーマスコープを変更しますか？',
          btnText: '変更',
          confirmationType: 'submit',
          bodyText:
            'このテーマの公開範囲を自分のみに変更すると、他ユーザーのお気に入りリストからこのテーマが消えてしまいますが、よろしいですか？',
          action: update,
        };
        store.commit('confirmationModal/showConfirmationModal');
        store.commit('confirmationModal/setTextAndAction', payload);
      } else {
        await update();
      }
    };

    const setSyntaxErrorKeywords = (errorKeywords: string[]) => {
      syntaxErrorKeywords.items = errorKeywords;
    };

    const isEqual = <T extends string | number>(arr1: T[], arr2: T[]) => {
      return (
        arr1.length === arr2.length &&
        arr1.filter(a => arr2.includes(a)).length === arr1.length
      );
    };

    const deleteTheme = () => {
      const deleteAction = async () => {
        try {
          await api.deleteTheme(themeId.value);
        } catch (err) {
          createSnackbar({
            message: 'テーマを削除できませんでした',
            type: 'error',
          });
          throw err;
        }
        await store.dispatch('themeList/fetchThemeList');

        emitter.emit('theme-updated', {});

        router.push({ name: 'anewsHome' });
        createSnackbar({
          message: 'テーマを削除しました',
          type: 'success',
        });
      };
      const payload = {
        headerText: 'テーマを削除しますか？',
        bodyText:
          '削除したテーマは元に戻すことはできません。\n本当にテーマを削除しますか？',
        action: deleteAction,
      };
      store.commit('confirmationModal/showConfirmationModal');
      store.commit('confirmationModal/setTextAndAction', payload);
    };

    type IsChinese = 'chinese' | 'not chinese';
    const isChineseItems: {
      value: IsChinese;
      label: string;
    }[] = [
      {
        value: 'not chinese',
        label: '国内メディア・海外メディア',
      },
      {
        value: 'chinese',
        label: '中国語メディア（β版）',
      },
    ];
    const isChineseValue = computed<IsChinese>({
      get: () => (isChinese.value ? 'chinese' : 'not chinese'),
      set: value => {
        isChinese.value = value === 'chinese';
      },
    });

    // topicTheme選択モーダル表示
    const openThemeTopicSelect = () => {
      store.commit('modal/showModal', 'themeTopicSelect');
    };

    // topicTheme選択モーダルから受け取り
    emitter.on(
      'select-theme-topics',
      async ([selectTopics, selectPerspective]: [
        ThemeTopic[],
        Perspective,
      ]) => {
        const selectedQueries: string[] = getSelectedQueries(
          selectTopics,
          selectPerspective,
          isForeignMediaEnabled.value,
        );
        const querySet = new Set(selectedQueries);
        const uniqueQueries = Array.from(new Set(selectedQueries));

        keywordList.value.forEach(k => querySet.delete(k));
        keywordList.value.push(...Array.from(querySet));

        // テーマ作成時にTopic名のトラッキング用に値を保持
        selectTopics.forEach(topic => {
          const existTopic = selectedTopicKeywordList.some(
            selectedTopic => selectedTopic.name === topic.name,
          );
          if (!existTopic) selectedTopicKeywordList.push(topic);
        });

        // キーワード追加のトラッキング
        const trackKeywordsAddParams = {
          pageName: 'theme_setting',
          pageUrl: route.fullPath,
          keywords: uniqueQueries,
          from: 'theme_topics',
          topics: selectTopics.map(x => x.name),
          theme: {},
          perspectives: selectPerspective ? [selectPerspective.question] : [],
        };
        if (themeId.value != 0)
          trackKeywordsAddParams.theme = { id: themeId.value };
        await api.trackThemeEvent('add_keywords', trackKeywordsAddParams);
      },
    );

    const addKeywordRecommend = async (keyword: string, rank: number) => {
      keywordList.value.unshift(keyword);

      // キーワード追加のトラッキング
      const trackKeywordsAddParams = {
        pageName: 'theme_setting',
        pageUrl: route.fullPath,
        keywords: [keyword],
        from: 'keyword_recommendation',
        rank: rank,
        topics: [],
        theme: {},
        perspectives: [],
      };
      if (themeId.value != 0)
        trackKeywordsAddParams.theme = { id: themeId.value };
      await api.trackThemeEvent('add_keywords', trackKeywordsAddParams);
    };

    const isCreatingTheme = ref(false);

    const showSupportContent = ref(userSession.getShowThemeSupportContent());

    const hideSupportContent = () => {
      userSession.setShowThemeSupportContent(false);
      showSupportContent.value = userSession.getShowThemeSupportContent();
    };

    const title = computed(() => {
      return isCreate.value ? 'テーマ作成' : 'テーマ編集';
    });

    const icon = computed(() => {
      return isCreate.value ? 'question-circle-gray600' : '';
    });

    const navigateToThemeFeed = () => {
      router.push({ name: 'themeFeed' });
    };

    const headerContentWidth = 'calc(440px + 16px + 904px)'; // テーマ設定の幅 + gap(16px) + プレビューの幅

    const docTypes = computed(
      () =>
        ['article'].concat(
          isResearchPaperEnabled.value ? ['research_paper'] : [],
          isPatentEnabled.value ? ['patent'] : [],
        ) as DocType[],
    );

    return {
      STATES,
      themeNameRef,
      themeId,
      userInfo,
      groupList,
      teamUsersList,
      teamInfo,
      tabs,
      themes,
      themeState,
      trimmedThemeName,
      trimmedDescription,
      trimmedKeywords,
      isTooltipKeywordVisible,
      isForeignMediaEnabled,
      isResearchPaperEnabled,
      isPatentEnabled,
      isChinese,
      isChineseValue,
      isChineseItems,
      isForeignFeedAvailable,
      isTechnicalResearchAvailable,
      isChineseFeedAvailable,
      isEntertainmentSitesFiltered,
      isInitialTheme,
      siteLimit,
      sites,
      selectedSites,
      scopes,
      selectedScope,
      keywordList,
      debouncedKeywordList,
      keywordErrors,
      debouncedKeywordErrors,
      keywordsErrorMessages,
      tooLongThemeName,
      tooLongDescription,
      tooLongKeywords,
      isCreate,
      themeInfo,
      hasPermission,
      hasThemeScopeSetting,
      hasInitialThemeSetting,
      enableToUpdate,
      submitBtnText,
      submitBtnWithoutEmailText,
      isStockmarkUser,
      showThemeSettingsInfoModal,
      selectScope,
      saveTheme,
      deleteTheme,
      setSyntaxErrorKeywords,
      syntaxErrorKeywords,
      isSaving,
      openThemeTopicSelect,
      isJobTypeReady,
      editKeywordsTabs,
      selectedEditKeywordType,
      newKeyword,
      newKeywordErrors,
      canAddNewKeyword,
      addKeywordToList,
      removeKeywordFromList,
      updateKeywordList,
      MAX_THEME_NAME_LENGTH,
      isCreatingTheme,
      addKeywordRecommend,
      MAX_KEYWORDS_LENGTH,
      hideSupportContent,
      showSupportContent,
      getTabs,
      getThemeHeaderDetail,
      formatTimestamp,
      title,
      icon,
      navigateToThemeFeed,
      isEmailNotificationEnabled,
      enabledSystemNotificationMail,
      excludeSiteKeyword,
      hasNextExcludeSites,
      addSearchSiteCategory,
      removeSearchSiteCategory,
      selectedSearchSiteCategories,
      searchSiteCategoriesOptions,
      selectedSiteCategories,
      includeAllianceMedia,
      isAllianceMediaEnabled,
      headerContentWidth,
      docTypes,
    };
  },
});
</script>
<template>
  <div class="o-theme-edit">
    <Header
      :title="title"
      :icon="icon"
      :is-icon-direction-reverse="isCreate"
      :content-width="headerContentWidth"
      :align-left="true"
      :is-menu-visible="isMenuVisible"
    >
      <template #titleContent>
        <ThemeTitleContent :title="title">
          <template #titleIconRight v-if="isCreate">
            <VTooltip placement="top" theme="menu" :disabled="!isCreate">
              <DgrIcon
                :keep-fill="false"
                name="question-circle"
                class="question-icon"
                v-if="icon"
              />
              <template #popper>
                <a
                  class="help-link"
                  href="https://anews-stockmark.zendesk.com/hc/ja/articles/360051832714"
                  target="_blank"
                  rel="noopener noreferrer"
                  >テーマ作成のマニュアルを見る</a
                >
              </template>
            </VTooltip>
          </template>
        </ThemeTitleContent>
      </template>
      <template #button>
        <div id="buttons-wrapper" class="o-buttons theme-buttons">
          <button
            v-if="!isCreate"
            @click="navigateToThemeFeed"
            class="c-btn c-btn--auto c-btn--small c-btnOutline"
          >
            キャンセル
          </button>
          <button
            class="c-btn c-btn--auto c-btn--small c-btn--AnewsPrimary"
            v-if="hasPermission"
            :class="{ disabled: !enableToUpdate || isSaving }"
            :disabled="!enableToUpdate || isSaving"
            @click="
              saveTheme(
                enabledSystemNotificationMail
                  ? !isEmailNotificationEnabled
                  : true,
              )
            "
            data-testid="save-theme-button"
          >
            {{ submitBtnText }}
          </button>
        </div>
      </template></Header
    >

    <div>
      <div class="o-theme-edit__wrapper">
        <div class="edit-content" :key="`theme-${themeId}`">
          <ThemeEditSupport
            v-if="showSupportContent && isCreate"
            @hide-support-content="hideSupportContent"
          ></ThemeEditSupport>
          <div class="o-theme-edit-form">
            <div class="o-theme-name">
              <div class="o-textarea-label c-title c-title--m">テーマ名</div>
              <div
                class="m-error c-formBlock__text c-formBlock__text--error"
                v-show="tooLongThemeName"
              >
                テーマ名は{{
                  MAX_THEME_NAME_LENGTH
                }}文字以内で入力してください。
              </div>
              <input
                class="o-theme-name-input c-text c-text--m c-textInput"
                ref="themeNameRef"
                :class="{
                  'c-formInput--error': tooLongThemeName,
                  'm-normal-text-area': !tooLongThemeName,
                }"
                :disabled="!hasPermission"
                v-model.trim="trimmedThemeName"
              />
            </div>
            <div class="o-theme-description">
              <div class="o-textarea-label c-title c-title--m">説明</div>
              <div
                class="m-error c-formBlock__text c-formBlock__text--error"
                v-show="tooLongDescription"
              >
                説明は50文字以内で入力してください。
              </div>
              <input
                class="o-theme-description-input c-text c-text--m c-textInput"
                :class="{
                  'c-formInput--error': tooLongDescription,
                  'm-normal-text-area': !tooLongDescription,
                }"
                :disabled="!hasPermission"
                v-model.trim="trimmedDescription"
              />
            </div>
          </div>
          <div class="o-theme-edit-form">
            <div v-if="isChineseFeedAvailable" class="o-theme-feed-type">
              <div class="o-textarea-label c-title c-title--m">
                配信するメディア
              </div>
              <DgrRadio
                class="c-text c-text--m"
                :items="isChineseItems"
                :disabled="!isCreate"
                v-model="isChineseValue"
              />
            </div>
            <div class="o-theme-keyword">
              <div class="o-theme-edit-keyword-label-wrap">
                <div
                  class="o-theme-edit-keyword-label c-formBlock__label c-title c-title--m"
                >
                  キーワード
                </div>
              </div>
              <div
                v-if="isChinese"
                class="o-textarea-explanation c-text c-text--m"
              >
                中国語（簡体字）で入力してください。
              </div>
              <div class="o-tabs">
                <TextTabItem
                  v-for="tab in editKeywordsTabs"
                  :key="tab"
                  :text="tab"
                  :is-selected="tab === selectedEditKeywordType"
                  @click-tab="selectedEditKeywordType = tab"
                />
              </div>
              <template v-if="selectedEditKeywordType === 'ベーシック'">
                <template v-if="hasPermission">
                  <div
                    v-if="isJobTypeReady && !isChinese"
                    class="o-add-topic-keyword-button c-btn c-btn--medium"
                    @click="openThemeTopicSelect"
                  >
                    トピックからキーワードを追加
                  </div>
                  <div class="m-add-new-keyword-label c-title c-title--s">
                    <!-- m-add-new-keyword-label を目印にIntercomでヘルプを表示する -->
                    キーワードを入力して追加
                  </div>
                  <div
                    class="m-new-keyword-error c-formBlock__text c-formBlock__text--error"
                    v-for="(errorMessage, errorIndex) in newKeywordErrors"
                    :key="errorIndex + '_' + errorMessage"
                  >
                    {{ errorMessage }}
                  </div>
                  <form
                    class="o-new-keyword-form"
                    @submit.prevent="addKeywordToList"
                  >
                    <AutocompleteInput
                      v-model.trim="newKeyword"
                      class="new-keyword"
                      @select="addKeywordToList"
                    >
                      <template #default="{ value, handlers }">
                        <input
                          :placeholder="'再生可能エネルギー AND 充電'"
                          class="c-text c-text--m c-textInput"
                          :class="{
                            'c-formInput--error': newKeywordErrors.length > 0,
                          }"
                          :value="value"
                          v-on="handlers"
                        />
                      </template>
                    </AutocompleteInput>
                    <button
                      class="button c-btn c-btn--auto c-btn--small c-btn--AnewsPrimary"
                      :class="{ disabled: !canAddNewKeyword }"
                      :disabled="!canAddNewKeyword"
                    >
                      追加
                    </button>
                  </form>
                </template>
                <div v-if="keywordList.length > 0" class="c-title c-title--s">
                  設定キーワード
                </div>
                <div
                  v-if="tooLongKeywords"
                  class="m-error c-formBlock__text c-formBlock__text--error"
                >
                  キーワードは合計で{{
                    MAX_KEYWORDS_LENGTH
                  }}文字以内になるように入力してください。
                </div>
                <div class="o-keyword-list">
                  <ThemeKeywordEditable
                    v-for="(keyword, index) in keywordList"
                    :key="index"
                    :value="keyword"
                    :other-keywords="keywordList.filter((_, i) => i !== index)"
                    :has-syntax-error="
                      syntaxErrorKeywords.items.includes(keyword)
                    "
                    :disabled="!hasPermission"
                    @input="updateKeywordList(index, $event)"
                    @remove="removeKeywordFromList(index)"
                  />
                </div>
                <ThemeKeywordRecommend
                  v-if="keywordList.length > 0 && hasPermission"
                  :keyword-list="keywordList"
                  @add-keyword-recommend="addKeywordRecommend"
                />
              </template>
              <template v-if="selectedEditKeywordType === 'カスタム'">
                <div
                  v-if="isJobTypeReady && !isChinese && hasPermission"
                  class="o-add-topic-keyword-button c-btn c-btn--medium"
                  @click="openThemeTopicSelect"
                >
                  トピックからキーワードを追加
                </div>
                <div class="m-custom-keywords-label c-title c-title--s">
                  <!-- m-custom-keywords-label を目印にIntercomでヘルプを表示する -->
                  検索クエリを入力。クエリごとに改行
                </div>
                <div
                  class="m-error c-formBlock__text c-formBlock__text--error"
                  v-for="errorMessage in keywordsErrorMessages"
                  :key="errorMessage"
                >
                  {{ errorMessage }}
                </div>
                <textarea
                  class="o-theme-keywords-input m-normal-text-area c-text c-text--m c-textInput"
                  :class="{
                    'c-formInput--error':
                      tooLongKeywords || keywordErrors.length > 0,
                  }"
                  :disabled="!hasPermission"
                  v-model.trim="trimmedKeywords"
                  :placeholder="'再生可能エネルギー AND 充電'"
                ></textarea>
              </template>
            </div>
          </div>
          <div class="o-theme-edit-form" v-if="hasPermission">
            <div class="o-theme-settings" v-if="!isChinese">
              <div class="o-theme-edit-foreign-feed">
                <div
                  class="o-theme-edit-foreign-feed-label c-formBlock__label c-title c-title--m"
                >
                  ニュース配信設定
                  <DgrIcon
                    size="small"
                    name="question-circle"
                    @click="showThemeSettingsInfoModal"
                  />
                </div>
              </div>
              <div class="o-theme-edit-foreign-feed-item">
                <DgrCheckbox
                  v-model="isForeignMediaEnabled"
                  class="c-text c-text--m"
                  :disabled="!isForeignFeedAvailable"
                >
                  海外メディアの情報を収集する
                  <span class="c-text c-text--s" v-if="!isForeignFeedAvailable">
                    オプション契約が必要です
                  </span>
                </DgrCheckbox>
              </div>
              <div class="o-theme-edit-entertainment-filter-item">
                <DgrCheckbox
                  v-model="isEntertainmentSitesFiltered"
                  class="c-text c-text--m"
                >
                  エンタメ記事を除外する
                </DgrCheckbox>
              </div>
              <div class="theme-site-category">
                <div class="c-formBlock__label c-title c-title--m">
                  表示するサイト種別
                </div>
              </div>
              <CheckboxList
                :items="searchSiteCategoriesOptions"
                :checked-values="selectedSearchSiteCategories"
                @checked-item="addSearchSiteCategory"
                @un-checked-item="removeSearchSiteCategory"
              />
              <div class="theme-technical-literature">
                <div class="c-formBlock__label">
                  <span class="c-title c-title--m">表示する技術文献</span>
                  <span
                    class="c-text c-text--s disabled"
                    v-if="!isTechnicalResearchAvailable"
                    >（オプション契約が必要です）</span
                  >
                </div>
                <div class="options">
                  <DgrCheckbox
                    v-model="isResearchPaperEnabled"
                    :disabled="!isTechnicalResearchAvailable"
                    class="c-text c-text--m"
                  >
                    論文
                  </DgrCheckbox>
                  <DgrCheckbox
                    v-model="isPatentEnabled"
                    :disabled="!isTechnicalResearchAvailable"
                    class="c-text c-text--m"
                  >
                    特許
                  </DgrCheckbox>
                </div>
              </div>
            </div>
            <div class="o-theme-sites" v-if="hasPermission && !isChinese">
              <div class="o-textarea-label c-title c-title--m">
                除外メディア
              </div>
              <div class="o-textarea-explanation c-text c-text--m">
                配信されるニュースから除外したいメディアを{{
                  siteLimit
                }}件まで登録することができます。
              </div>
              <SiteSelect
                v-model="selectedSites.items"
                v-model:keyword="excludeSiteKeyword"
                :items="sites.items"
                :max-items="siteLimit"
                :has-next-exclude-sites="hasNextExcludeSites"
              ></SiteSelect>
            </div>
            <div class="o-theme-scope" v-if="hasThemeScopeSetting">
              <div class="o-textarea-label c-title c-title--m">公開範囲</div>
              <DgrSelectbox
                class="theme-scope-select"
                :options="scopes"
                v-model="selectedScope"
                options-max-height="240px"
              ></DgrSelectbox>
            </div>
            <div class="o-initial-theme" v-if="hasInitialThemeSetting">
              <div class="o-textarea-label c-title c-title--m">
                初期表示設定
              </div>
              <div class="o-textarea-explanation c-text c-text--m">
                初期メンバーが追加された時にデフォルトで表示することができます。
              </div>
              <div class="o-theme-edit-initial-theme-item">
                <DgrCheckbox v-model="isInitialTheme" class="c-text c-text--m">
                  有効にする
                </DgrCheckbox>
              </div>
            </div>
            <div class="o-skip-email">
              <div class="o-textarea-label c-title c-title--m">
                メール配信設定
              </div>
              <div class="c-text c-text--m">
                メール通知を受け取る際は<router-link
                  :to="{ name: 'userSettingsNotification' }"
                  ><span class="underline">通知</span></router-link
                >のその他にチェックを入れてください。
              </div>
              <div class="o-theme-edit-initial-theme-item">
                <DgrCheckbox
                  v-model="isEmailNotificationEnabled"
                  :disabled="!enabledSystemNotificationMail"
                  class="c-text c-text--m"
                >
                  テーマ更新後のメール通知を受け取る
                </DgrCheckbox>
              </div>
            </div>
          </div>
          <button
            class="o-theme-delete outlined c-btn--auto c-btn--small"
            v-if="!isCreate && hasPermission"
            @click="deleteTheme"
          >
            <DgrIcon size="small" name="trash" :keep-fill="false" />
            <span>削除</span>
          </button>
        </div>
        <ThemePreview
          v-if="hasPermission && !isChinese"
          :keywords="debouncedKeywordList"
          :keyword-errors="debouncedKeywordErrors"
          :is-foreign-feed-available="isForeignFeedAvailable"
          :is-foreign-media-enabled="isForeignMediaEnabled"
          :doc-types="docTypes"
          :is-technical-literature-available="isTechnicalResearchAvailable"
          :is-entertainment-filter-enabled="isEntertainmentSitesFiltered"
          :excluded-sites="selectedSites.items"
          :site-categories="selectedSearchSiteCategories"
          :is-alliance-media-enabled="isAllianceMediaEnabled"
          class="o-theme-preview"
          @syntax-error-keywords="setSyntaxErrorKeywords"
        ></ThemePreview>
      </div>
    </div>
    <Teleport to="body" v-if="isCreatingTheme">
      <Loading position="fixed" z-index="var(--z-modal)" />
    </Teleport>
  </div>
</template>
<style lang="scss" scoped>
div.o-theme-edit {
  width: 100%;
  margin: -24px 0 0 0;
  padding-bottom: 0 !important; // src/components/layouts/main-view.vue で指定されているpadding-bottom: 80px を打ち消す

  .o-theme-edit__wrapper {
    display: flex;
    margin: 24px 24px 0px 24px;
    gap: 16px;
  }

  .o-submit-buttons {
    display: flex;
    gap: 16px;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
    button.o-theme-edit-save {
      margin-top: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }
    button.o-theme-delete {
      margin-top: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      background: none;
      &.outlined:not(:disabled) {
        border: solid 1px $color-orange1000;
        color: $color-orange1000;
        @media (any-hover: hover) {
          &:hover {
            background-color: $color-gray200;
          }
        }
      }
      .icon-box {
        display: inline-block;
        fill: $color-orange1000;
        margin-bottom: -2px;
        margin-right: 2px;
      }
    }
  }

  button.o-theme-delete {
    margin-top: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    background: #ffffff;
    &.outlined:not(:disabled) {
      border: solid 1px $color-orange1000;
      color: $color-orange1000;
      @media (any-hover: hover) {
        &:hover {
          background-color: $color-gray200;
        }
      }
    }
    .icon-box {
      display: inline-block;
      fill: $color-orange1000;
      margin-bottom: -2px;
      margin-right: 2px;
    }
  }

  button.o-theme-edit-save {
    margin-top: 16px;

    &.outlined:not(:disabled) {
      border: solid 1px $color-green600;
      color: $color-green600;

      @media (any-hover: hover) {
        &:hover {
          background-color: $color-green100;
        }
      }
    }
  }

  .o-theme-edit-form {
    margin-bottom: 16px;
    padding: 16px;
    background: #ffffff;
    border: 1px solid #e6e6e6;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    flex-direction: column;

    .o-textarea-label {
      margin-bottom: 4px;
    }
    div.o-theme-description,
    div.o-theme-keyword,
    div.o-theme-sites,
    div.o-theme-scope,
    div.o-initial-theme,
    div.o-theme-delete,
    div.o-skip-email {
      display: flex;
      flex-direction: column;
      margin-top: 12px;
      .o-textarea-explanation {
        margin-bottom: 4px;
      }
      .o-normal-text-area {
        border: 1px solid #e6e6e6;
      }
      input,
      textarea {
        outline: none;
        overflow: hidden;
        box-sizing: border-box;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        padding: 10px 12px;
        max-width: 35vw;
      }
      .o-theme-edit-keyword-label-wrap {
        display: flex;
        justify-content: space-between;
      }
      .o-theme-edit-keyword-label {
        display: flex;
        justify-content: start;
        align-items: center;
        .icon-box {
          cursor: pointer;
          margin-left: 4px;
          margin-top: 1px;
          fill: #b3b3b3;
        }
      }
      .o-add-topic-keyword-button {
        margin-top: 8px;
        margin-bottom: 24px;
        color: $color-green600;
        border-color: $color-green600;
        width: fit-content;
        cursor: pointer;
        &:hover {
          background-color: $color-green100;
        }
      }
      .theme-scope-select {
        width: 220px;
      }
    }

    div.o-theme-sites,
    div.o-theme-scope,
    div.o-initial-theme {
      margin-top: 24px;
    }

    div.o-skip-email {
      gap: 4px;
    }

    .o-tabs {
      display: flex;
      align-items: flex-start;
      border-bottom: solid 1px #e6e6e6;
      margin: 0 0 16px 0;
    }
    .o-new-keyword-form {
      display: flex;
      align-items: center;
      justify-content: stretch;
      margin-bottom: 16px;
      gap: 8px;
      .new-keyword {
        flex: 1;
      }
      .button {
        flex: 0 0 auto;
      }
    }
    .o-keyword-list {
      margin: 8px 0;
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      gap: 4px;
    }

    .m-add-new-keyword-label {
      margin-bottom: 8px;
    }
    .m-new-keyword-error {
      font-size: 12px;
      line-height: 12px;
    }
    .m-custom-keywords-label {
      margin-bottom: 8px;
    }

    div.o-theme-keyword {
      textarea {
        height: 400px;
        width: 448px;
        overflow: auto;
        max-width: 100%;
      }
    }
    .o-theme-settings {
      .o-theme-edit-foreign-feed {
        display: flex;
        justify-content: flex-start;
      }
      .o-theme-edit-foreign-feed-label {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .icon-box {
          cursor: pointer;
          margin-left: 4px;
          margin-top: 1px;
          fill: #b3b3b3;
        }
      }
      .o-theme-edit-foreign-feed-item,
      .o-theme-edit-entertainment-filter-item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .o-foreign-feed-message {
          margin-left: 4px;
        }
      }

      .theme-site-category {
        margin-top: 24px;
      }

      .theme-technical-literature {
        margin-top: 24px;

        .options {
          display: flex;
          justify-content: flex-start;
          margin-top: 8px;
          gap: 8px;
        }
      }
      .o-theme-edit-entertainment-filter-item {
        margin-top: 8px;
      }
      .o-foreign-feed-checkbox {
        display: flex;
        align-items: center;
      }
      .o-foreign-feed-input {
        cursor: pointer;
        height: 16px;
        width: 16px;
      }
    }

    .disabled {
      color: #b3b3b3;
    }
  }
  .o-theme-edit-form-delete {
    padding: 16px;
    background: #ffffff;
    border: 1px solid #e6e6e6;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    div.o-theme-delete {
      display: flex;
      flex-direction: column;
      .o-textarea-label {
        font-weight: bold;
        margin-bottom: 4px;
      }
      .o-textarea-explanation {
        margin-bottom: 4px;
      }
      .o-normal-text-area {
        border: 1px solid #e6e6e6;
      }
      input,
      textarea {
        outline: none;
        overflow: hidden;
        box-sizing: border-box;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        padding: 10px 12px;
        max-width: 35vw;
      }
    }
  }

  .o-delete-button {
    margin-top: 8px;
  }

  .question-icon {
    margin-left: 8px;
    fill: $color-gray600;
  }
}
.m-error {
  font-size: 12px;
  margin-top: -4px;
}
.edit-content {
  padding-bottom: 20px;
  width: 440px;
}
.o-theme-name-input:focus {
  outline: none;
}
.o-theme-feed-type {
  margin-bottom: 24px;
}
.footer-popup {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  cursor: pointer;
  padding: 8px;

  &:hover {
    background: $color-gray200;
  }
}

.o-theme-preview {
  position: sticky;
  top: 152px;
  height: calc(
    /* viewportのheight - 共通ヘッダーのheight - テーマ編集画面のヘッダーのheight - テーマ編集画面のヘッダーとプレビューのmargin - プレビューとviewport・bottomのmargin */
      100vh - 64px - 67px - 24px - 24px
  );
}

.o-buttons {
  flex-shrink: 0;
  display: flex;
  gap: 8px;
}

.underline {
  text-decoration: underline;
}
</style>
