import { Module } from 'vuex';
import api from '@/api';
import { searchCache } from '@/apiCache';
import { DUMMY_USER_DOCUMENTS_RESP } from '@/components/search/promotion-user-document-trial/promo-dummy-data';
import {
  AdpDocument,
  Article,
  Lang,
  NewsCategoryWithCount,
  OnlyTrackingParam,
  ReportSearchRequestParam,
  SearchRequestParam,
  UserDocument,
  UserDocumentSearchRequestParam,
} from '@/types';
import { deduplicateArticles, deduplicateUserDocuments } from '@/utils';
import { SearchSiteCategory } from '@/utils/siteCategories';

export type SearchConditionKey =
  | 'all'
  | 'news'
  | 'research_paper'
  | 'patent'
  | 'report'
  | 'user_document'
  | 'user_document_demo';

export type SearchCondition = {
  lang: Lang[];
  period: string;
  from: Date | undefined;
  to: Date | undefined;
  sort: string;
  is_entertainment_sites_filtered?: boolean;
  site_categories: SearchSiteCategory[];
  last_updated_by?: string[];
  news_categories: string[];
};

export type SearchResultsState = {
  resultCount: number | null;
  resultArticles: AdpDocument[];
  resultUserDocuments: UserDocument[];
  searchTrackingSessionId?: string;
  expandedQueries: string[];
  isSearching: boolean;
  searchCondition: SearchCondition;
  exceedMaxResultWindow: boolean | null;
  lastUpdatedBy: string[];
  currentRequestId: number | null;
  newsCategories: NewsCategoryWithCount[];
  isSearchNewsCategories: boolean;
};

const commonSearchCondition: Pick<
  SearchCondition,
  | 'period'
  | 'from'
  | 'to'
  | 'sort'
  | 'site_categories'
  | 'last_updated_by'
  | 'news_categories'
> = {
  period: 'all',
  from: undefined,
  to: undefined,
  sort: 'recommended_v2',
  site_categories: [],
  last_updated_by: [],
  news_categories: [],
};

const defaultSearchCondition: SearchCondition = {
  lang: [],
  is_entertainment_sites_filtered: true,
  ...commonSearchCondition,
};

export const initialSearchResultsState: SearchResultsState = {
  resultCount: null,
  resultArticles: [],
  resultUserDocuments: [],
  searchTrackingSessionId: undefined,
  expandedQueries: [],
  isSearching: false,
  searchCondition: Object.assign({}, defaultSearchCondition),
  exceedMaxResultWindow: false,
  lastUpdatedBy: [],
  currentRequestId: null,
  newsCategories: [],
  isSearchNewsCategories: true,
};

function updateSearchCaches(document: Article): void {
  searchCache.getSearchCacheKeys().forEach(key => {
    const cacheKey = key.split('.')[1];
    const data = searchCache.getCache(cacheKey);
    if (
      data &&
      data.search_tracking_session_id &&
      data.search_articles.find(a => a.id === document.id)
    ) {
      const newArticles = data.search_articles.map(a =>
        a.id === document.id ? document : a,
      );
      data.search_articles = newArticles;
      searchCache.updateCache(cacheKey, data);
    }
  });
}

const searchResults: Module<SearchResultsState, unknown> = {
  namespaced: true,
  state: () => initialSearchResultsState,
  getters: {
    searchCondition: state => (_key: SearchConditionKey) => {
      return state.searchCondition;
    },
  },
  actions: {
    async search(
      { state, commit },
      payload: SearchRequestParam & OnlyTrackingParam,
    ) {
      try {
        // NOTE: 絞り込みの条件をシームレスに変更できるようしたことで、最新状態の絞り込み結果を表示することの担保ができないため
        // 最新状態の絞り込みのレスポンスのみを表示結果として適用する目的で、requestIdを設定する
        // requestIdがない状態でのバグ詳細 : https://anews.atlassian.net/browse/ANDEV-4103?focusedCommentId=39510
        const requestId = Date.now();
        commit('setCurrentRequestId', requestId);
        commit('setIsSearching', true);
        const result = await api.search(payload);
        if (state.currentRequestId !== requestId) return;
        commit('setResultCount', result.result_count);
        commit('setSearchTrackingSessionId', result.search_tracking_session_id);
        commit('setExpandedQueries', result.expanded_queries);
        commit(
          'setResultArticles',
          deduplicateArticles(state.resultArticles.concat(result.articles)),
        );
        commit('setExceedMaxResultWindow', result.exceed_max_result_window);
        if (state.isSearchNewsCategories)
          commit('setNewsCategories', result.news_categories);
        commit('setIsSearchNewsCategories', true);
      } finally {
        commit('setIsSearching', false);
      }
    },
    async reportSearch(
      { state, commit },
      payload: ReportSearchRequestParam & OnlyTrackingParam,
    ) {
      try {
        // NOTE: 絞り込みの条件をシームレスに変更できるようしたことで、最新状態の絞り込み結果を表示することの担保ができないため
        // 最新状態の絞り込みのレスポンスのみを表示結果として適用する目的で、requestIdを設定する
        // requestIdがない状態でのバグ詳細 : https://anews.atlassian.net/browse/ANDEV-4103?focusedCommentId=39510
        const requestId = Date.now();
        commit('setCurrentRequestId', requestId);
        commit('setIsSearching', true);
        const result = await api.reportSearch(payload);
        if (state.currentRequestId !== requestId) return;
        commit('setResultCount', result.result_count);
        commit('setSearchTrackingSessionId', result.search_tracking_session_id);
        commit('setExpandedQueries', result.expanded_queries);
        commit(
          'setResultArticles',
          deduplicateArticles(state.resultArticles.concat(result.articles)),
        );
        commit('setExceedMaxResultWindow', result.exceed_max_result_window);
      } finally {
        commit('setIsSearching', false);
      }
    },
    async userDocumentSearch(
      { state, commit },
      payload: UserDocumentSearchRequestParam & OnlyTrackingParam,
    ) {
      try {
        // NOTE: 絞り込みの条件をシームレスに変更できるようしたことで、最新状態の絞り込み結果を表示することの担保ができないため
        // 最新状態の絞り込みのレスポンスのみを表示結果として適用する目的で、requestIdを設定する
        // requestIdがない状態でのバグ詳細 : https://anews.atlassian.net/browse/ANDEV-4103?focusedCommentId=39510
        const requestId = Date.now();
        commit('setCurrentRequestId', requestId);
        commit('setIsSearching', true);
        const result = await api.userDocumentSearch(payload);
        if (state.currentRequestId !== requestId) return;
        commit('setResultCount', result.result_count);
        commit('setSearchTrackingSessionId', result.search_tracking_session_id);
        commit('setExpandedQueries', result.expanded_queries);
        commit('setLastUpdatedBy', result.last_updated_by);
        commit(
          'setResultUserDocuments',
          deduplicateUserDocuments(
            state.resultUserDocuments.concat(result.articles),
          ),
        );
        commit('setExceedMaxResultWindow', result.exceed_max_result_window);
      } finally {
        commit('setIsSearching', false);
      }
    },
    // 社内情報検索機能のトライアルを訴求する施策
    // https://anews.atlassian.net/browse/ANDEV-4409
    async userDocumentSearchDemo({ state, commit }) {
      try {
        commit('setIsSearching', true);
        commit('setResultCount', DUMMY_USER_DOCUMENTS_RESP.result_count);
        commit('setLastUpdatedBy', DUMMY_USER_DOCUMENTS_RESP.last_updated_by);
        commit(
          'setResultUserDocuments',
          deduplicateUserDocuments(
            state.resultUserDocuments.concat(
              DUMMY_USER_DOCUMENTS_RESP.articles as UserDocument[],
            ),
          ),
        );
      } finally {
        commit('setIsSearching', false);
      }
    },
    async updateArticleLocally({ state, commit }, { article }) {
      const index = state.resultArticles.findIndex(a => a.id === article.id);
      if (index === -1) return;
      const updatedArticles = state.resultArticles.slice();
      updatedArticles[index] = article;
      commit('setResultArticles', updatedArticles);
      updateSearchCaches(article);
    },
    async updateArticle({ state, commit }, { articleId, docType }) {
      const index = state.resultArticles.findIndex(a => a.id == articleId);
      if (index !== -1) {
        const updatedArticles = state.resultArticles.slice();
        const article = (await api.fetchDocument(
          articleId,
          docType,
        )) as AdpDocument;
        const oldArticle = updatedArticles[index];
        article.hit_keywords = oldArticle.hit_keywords;
        article.synonym_keywords = oldArticle.synonym_keywords;
        updatedArticles[index] = article;
        commit('setResultArticles', updatedArticles);
      }
    },
    reset({ commit }) {
      commit('setResultCount', null);
      commit('setResultArticles', []);
      commit('setResultUserDocuments', []);
      commit('setExceedMaxResultWindow', false);
    },
  },
  mutations: {
    setResultCount(state, count: number | null) {
      state.resultCount = count;
    },
    setSearchTrackingSessionId(state, sessionId: string) {
      state.searchTrackingSessionId = sessionId;
    },
    setExpandedQueries(state, queries: string[]) {
      state.expandedQueries = queries;
    },
    setResultArticles(state, articles: AdpDocument[]) {
      state.resultArticles = articles;
    },
    setResultUserDocuments(state, userDocuments: UserDocument[]) {
      state.resultUserDocuments = userDocuments;
    },
    setExceedMaxResultWindow(state, exceedMaxResultWindow: boolean | null) {
      state.exceedMaxResultWindow = exceedMaxResultWindow;
    },
    setNewsCategories(state, newsCategories: NewsCategoryWithCount[]) {
      state.newsCategories = newsCategories;
    },
    setIsSearchNewsCategories(state, isSearchNewsCategories: boolean) {
      state.isSearchNewsCategories = isSearchNewsCategories;
    },
    setSearchCondition(
      state,
      param: { key: SearchConditionKey; value: SearchCondition },
    ) {
      state.searchCondition = param.value;
    },
    setLanguageCondition(
      state,
      param: { key: SearchConditionKey; value: Lang[] },
    ) {
      const searchCondition = state.searchCondition;
      searchCondition['lang'] = param.value;
      searchCondition['news_categories'] = [];
      state.searchCondition = searchCondition;
    },
    setSiteCategoryCondition(
      state,
      param: { key: SearchConditionKey; value: SearchSiteCategory[] },
    ) {
      const searchCondition = state.searchCondition;
      searchCondition['site_categories'] = param.value;
      searchCondition['news_categories'] = [];
      state.searchCondition = searchCondition;
    },
    setIsSearching(state, value: boolean) {
      state.isSearching = value;
    },
    setLastUpdatedByCondition(
      state,
      param: { key: SearchConditionKey; value: string[] },
    ) {
      state.searchCondition['last_updated_by'] = param.value;
    },
    setLastUpdatedBy(state, lastUpdatedBy: string[]) {
      state.lastUpdatedBy = lastUpdatedBy;
    },
    resetCondition(state) {
      state.searchCondition = Object.assign({}, defaultSearchCondition);
    },
    setCurrentRequestId(state, value: number | null) {
      state.currentRequestId = value;
    },
  },
};
export default searchResults;
