<script setup lang="ts">
// FIXME: SearchBarの中にもqueryを持っているため、queryは使っている側から渡すようにする
// 現状使っている側とこのコンポーネントで2重にstateを管理している状態になっている
import { ref, watch } from 'vue';
import { DgrIcon } from '@stockmarkteam/donguri-ui';

interface Props {
  placeholder?: string;
}

withDefaults(defineProps<Props>(), {
  placeholder: '検索…',
});

const query = ref('');

const emit = defineEmits<{
  'on-change-query': [query: string];
  'on-search-query': [query: string];
}>();

watch(
  () => query.value,
  val => emit('on-change-query', val),
);

let isComposing = false;
const onCompositionStart = () => {
  isComposing = true;
};
const onCompositionEnd = () => {
  isComposing = false;
};

const onKeyDown = (event: KeyboardEvent) => {
  if (isComposing) return;

  if (event.key === 'Enter') {
    event.preventDefault();
    emit('on-search-query', query.value);
  }
};
</script>

<template>
  <div class="m-search-bar">
    <DgrIcon size="small" name="search" class="m-search-icon" />
    <input
      class="m-query"
      @compositionstart="onCompositionStart"
      @compositionend="onCompositionEnd"
      @keydown="onKeyDown"
      v-model="query"
      :placeholder="placeholder"
    />
  </div>
</template>
<style lang="scss" scoped>
.m-search-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  .m-search-icon {
    position: relative;
    left: 10px;
  }

  .m-query {
    width: 100%;
    background: #ffffff;
    border: 1px solid #e6e6e6;
    box-sizing: border-box;
    border-radius: 4px;
    outline: none;
    margin-left: -16px;
    padding-left: 34px;
  }
}

::placeholder {
  font-size: 14px;
  line-height: 38px;
  color: #b3b3b3;
}
</style>
