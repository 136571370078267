<script setup lang="ts">
import { computed } from 'vue';
import { FIRST_PUBLISHED_AT, searchPresetDates } from '@/constants';
import { DgrToggleButton } from '@stockmarkteam/donguri-ui';
import SearchMenuCheckbox from '@/components/menu/search-menu/search-menu-checkbox.vue';
import SearchMenuDropdownCalendar from '@/components/menu/search-menu/search-menu-dropdown-calendar.vue';
import { DateRange } from '@/types';
import { getToday } from '@/utils/dates';
import { SiteCategoryItems } from '@/utils/siteCategories';

interface Props {
  // メディア種別
  isMediaTypeVisible: boolean | undefined;
  isOpenMediaType: boolean;
  mediaTypeItems: { label: string; value: string }[];
  checkedMediaTypeItems: string[];

  // 期間
  isOpenDropDownCalendar: boolean;
  startDate: Date | undefined;
  endDate: Date | undefined;

  // サイト種別
  isOpenSiteCategories: boolean;
  siteCategoryItems: SiteCategoryItems[];
  checkedSiteCategoryItems: string[];

  // エンタメフィルタ
  isEntertainmentFilter: boolean | undefined;
}

// デフォルト値を設定しない場合は以下を使用してください。
const props = defineProps<Props>();

type Emits = {
  // メディア種別
  'open-media-type': [];
  'add-all-media-type': [];
  'remove-all-media-type': [];
  'add-media-type': [value: string];
  'remove-media-type': [value: string];

  // 期間
  'open-drop-down-calendar': [];
  'apply-date': [value: { presetKey: string; value: DateRange }];

  // サイト種別
  'open-site-categories': [];
  'remove-all-site-category': [];
  'add-site-category': [value: string];
  'remove-site-category': [value: string];

  // エンタメフィルタ
  'toggle-entertainment-filter': [];
};

const emit = defineEmits<Emits>();

const computedIsEntertainmentFilter = computed({
  get: () => props.isEntertainmentFilter,
  set: () => emit('toggle-entertainment-filter'),
});
</script>

<template>
  <div class="divider"></div>

  <SearchMenuCheckbox
    title="サイト種別"
    :items="siteCategoryItems"
    :checked-values="checkedSiteCategoryItems"
    :is-open="isOpenSiteCategories"
    @toggle-is-open="emit('open-site-categories')"
    @un-checked-all-item="emit('remove-all-site-category')"
    @checked-item="(item: string) => emit('add-site-category', item)"
    @un-checked-item="(item: string) => emit('remove-site-category', item)"
  ></SearchMenuCheckbox>

  <template v-if="isMediaTypeVisible">
    <div class="divider"></div>

    <SearchMenuCheckbox
      title="メディア種別"
      :items="mediaTypeItems"
      :checked-values="checkedMediaTypeItems"
      :is-open="isOpenMediaType"
      @toggle-is-open="emit('open-media-type')"
      @checked-all-item="emit('add-all-media-type')"
      @un-checked-all-item="emit('remove-all-media-type')"
      @checked-item="(item: string) => emit('add-media-type', item)"
      @un-checked-item="(item: string) => emit('remove-media-type', item)"
    ></SearchMenuCheckbox>
  </template>

  <div class="divider"></div>

  <SearchMenuDropdownCalendar
    title="期間"
    :is-open="isOpenDropDownCalendar"
    :range="{
      from: startDate,
      to: endDate,
    }"
    :preset="searchPresetDates"
    :min="FIRST_PUBLISHED_AT"
    :max="getToday()"
    @toggle-is-open="emit('open-drop-down-calendar')"
    @apply-date="value => emit('apply-date', value)"
  ></SearchMenuDropdownCalendar>

  <div class="divider"></div>

  <div class="entertainment-filter">
    <span class="c-title c-title--s">エンタメ記事フィルタ</span>

    <DgrToggleButton
      class="toggle"
      v-model="computedIsEntertainmentFilter"
    ></DgrToggleButton>
  </div>
</template>

<style lang="scss" scoped>
.divider {
  height: 1px;
  padding: 0;
  margin: 4px 0px;
  background: $color-gray400;

  &:first-of-type {
    margin-top: 0;
  }
}

.entertainment-filter {
  padding-left: 16px;
  margin-top: 20px;

  .toggle {
    margin-top: 12px;

    &.disabled {
      cursor: not-allowed;

      :deep(.toggle-box) {
        background: $color-gray400;
        pointer-events: none;
      }
    }
  }
}
</style>
