import { Module } from 'vuex';
import api from '@/api';
import { Group } from '@/types';

export type GroupsState = {
  groups: Group[];
};

export const initialGroupsState = {
  groups: [],
};

const groups: Module<GroupsState, unknown> = {
  namespaced: true,
  state: {
    groups: [],
  },
  mutations: {
    setGroups(state, groups) {
      state.groups = groups;
    },
  },
  actions: {
    async fetchGroups({ commit }, userId) {
      const groups = await api.fetchGroups(userId);
      commit('setGroups', groups);
    },
  },
};

export default groups;
