import { Module } from 'vuex';
import api from '@/api';
import { TeamInfo } from '@/types';

export type TeamInfoState = {
  teamInfo: TeamInfo | undefined;
};

export const initialTeamInfoState: TeamInfoState = {
  teamInfo: undefined,
};

const teamInfo: Module<TeamInfoState, unknown> = {
  namespaced: true,
  state: {
    teamInfo: undefined,
  },
  actions: {
    async fetchTeamInfo({ commit, state }) {
      const teamInfo = await api.fetchTeamInfo();
      state.teamInfo = teamInfo;
      commit('setTeamInfo', teamInfo);
    },
  },
  mutations: {
    setTeamInfo(state, teamInfo) {
      state.teamInfo = teamInfo;
    },
  },
};

export default teamInfo;
