<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import Avatar from '@/components/common/atoms/avatar.vue';
import HeaderDetailContent from '@/components/common/header/header-detail-content.vue';
import TextTabItem from '@/components/common/molecules/text-tab-item.vue';
import { OrganizationTagDetail } from '@/types';
import { useXScrollbarWidth } from '@/utils/composables/useXScrollbarWidth';
import { Tab } from '@/utils/personalNews';
import { useStore } from '@/utils/vue';

type RouterLinkTo = string | { name: string; params?: any }; // eslint-disable-line @typescript-eslint/no-explicit-any

type Props = {
  title?: string;
  detail?: string;
  section?: string;
  avatarImageUrl?: string;
  emptyDetailMessage?: string;
  icon?: string;
  clickable?: boolean;
  tabs?: Tab[];
  alignLeft?: boolean;
  sectionLink?: RouterLinkTo;
  isOneLine?: boolean;
  contentWidth?: string;
  headerWidth?: string;
  isIconDirectionReverse?: boolean;
  organizationTagList?: OrganizationTagDetail[];
  isMenuVisible?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  isMenuVisible: true,
  headerWidth: '948px',
  contentWidth: '616px',
});

defineEmits(['click-info']);

const store = useStore();
const route = computed(() => useRoute());

const xScrollBarWidth = useXScrollbarWidth();

const isEqual = (
  object1: Record<string, unknown> | undefined,
  object2: Record<string, unknown> | undefined,
): boolean => {
  const o1 = object1 ?? {};
  const o2 = object2 ?? {};
  const keys = Object.keys(o1);
  return (
    keys.filter(k => String(o1[k]) === String(o2[k])).length === keys.length
  );
};
const isSelected = (linkTo: RouterLinkTo) => {
  if (typeof linkTo === 'string') {
    return route.value.path == linkTo;
  } else {
    return (
      route.value.name === linkTo.name &&
      isEqual(route.value.params, linkTo.params)
    );
  }
};

const headerRef = ref<HTMLInputElement>();
let observer: IntersectionObserver | undefined = undefined;

onMounted(() => {
  if (!headerRef.value) return;
  observer = new IntersectionObserver(entry => {
    store.commit('header/changeIsSticky', !entry[0].isIntersecting);
  });
  observer.observe(headerRef.value);
});
</script>

<template>
  <div class="header-wrap">
    <div
      ref="headerRef"
      class="header"
      :class="{ 'no-tab': !tabs }"
      :style="{
        '--header-width': headerWidth,
        '--xscroll-bar-width': `${xScrollBarWidth}px`,
      }"
    >
      <div
        class="header-content-wrap"
        :class="{
          'align-center': !alignLeft,
        }"
      >
        <div
          class="header-content"
          :style="{ '--header-content-width': contentWidth }"
          :class="{
            'is-menu-visible': props.isMenuVisible,
          }"
        >
          <div class="info-wrapper">
            <Avatar
              class="avatar-image"
              v-if="avatarImageUrl"
              :image-url="avatarImageUrl"
              size="l"
            ></Avatar>
            <div class="info">
              <div class="section" v-if="section">
                <router-link
                  class="section-content link c-text c-text--m"
                  v-if="sectionLink"
                  :to="sectionLink"
                  >{{ section }}</router-link
                >
                <div class="section-content c-text c-text--m" v-else>
                  {{ section }}
                </div>
              </div>
              <div class="title">
                <div
                  class="text c-title c-title--l"
                  @click="$emit('click-info')"
                >
                  <slot name="titleContent">{{ title }}</slot>
                </div>
                <div class="detail c-text c-text--m" v-if="isOneLine">
                  <HeaderDetailContent v-if="detail" :detail="`・${detail}`" />
                </div>
                <slot name="titleMenu"></slot>
              </div>
              <template v-if="organizationTagList && !isOneLine">
                <div class="tag-list-container c-text c-text--s">
                  <div class="c-text--sm tag-list-text">
                    組織タグ
                    <div class="organization-tag-links">
                      <router-link
                        class="c-bold--s"
                        v-for="organizationTag in organizationTagList"
                        :to="`/others/organization_tags/${organizationTag.id}`"
                        :key="organizationTag.id"
                      >
                        {{ organizationTag.name }}
                      </router-link>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <slot name="button"></slot>
        </div>
        <div
          class="detail c-text c-text--s"
          v-if="!organizationTagList && !isOneLine"
          :style="{ '--header-content-width': contentWidth }"
          :class="{ clickable }"
          @click="$emit('click-info')"
        >
          <slot name="detail-content">
            <HeaderDetailContent
              v-if="detail"
              :detail="detail"
              :is-menu-visible="isMenuVisible"
              :is-responsive="true"
            />
          </slot>
        </div>
      </div>
    </div>
    <div class="tab-list-wrap" :class="{ 'align-left': alignLeft }" v-if="tabs">
      <div class="m-type-tab-list">
        <div
          v-for="tab in tabs"
          :key="tab.name"
          :style="{ 'margin-top': '-4px' }"
        >
          <component
            :is="tab.disabled ? 'span' : 'router-link'"
            :to="tab.linkTo"
          >
            <TextTabItem
              :text="tab.name"
              :count="tab.count"
              :is-selected="isSelected(tab.linkTo)"
              :has-notification="tab.hasNotification"
            ></TextTabItem>
          </component>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.header-wrap {
  /* headerとtabをそれぞれstickyにしたいので、全体はdisplay: contentsにして
     stickyの親にならないようにする */
  display: contents;
}
.header {
  &.no-tab {
    position: sticky;
    top: 64px;
    z-index: var(--z-content-header);
  }

  /* ブラウザの幅が小さく横スクロールできるとき、スクロールしても
     余白が生じないようにmin-widthを設定しておく */
  min-width: max-content;
  background: #ffffff;
  padding: 16px 24px;
  border-bottom: solid 1px #e6e6e6;
  .header-content-wrap {
    width: var(--header-width);

    display: flex;
    flex-flow: column;
    gap: 8px;

    .detail {
      margin-top: 2px;
      color: #717171;
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
  }

  .header-content {
    width: min(
      var(--header-content-width),
      calc(
        100vw -
          (
            80px // 常時表示されている左メニューのwidth
            + var(--xscroll-bar-width, 0) // ページのx軸スクロールバーのwidth
            + 24px // ヘッダーのpadding-left
            + 24px + 5.5px
              // グローバルヘッダーのmargin-right + グローバルヘッダーのアイコンのmargin-right
          )
      )
    );
    &.is-menu-visible {
      width: min(
        var(--header-content-width),
        calc(
          100vw -
            (
              80px // 常時表示されている左メニューのwidth
              + 288px //  折りたたみ可能な左メニューのwidth
              + var(--xscroll-bar-width, 0) // ページのx軸スクロールバーのwidth
              + 24px // ヘッダーのpadding-left
              + 24px + 5.5px
                // グローバルヘッダーのmargin-right + グローバルヘッダーのアイコンのmargin-right
            )
        )
      );
    }
    display: flex;
    justify-content: space-between;
    gap: 32px;

    .info-wrapper {
      display: flex;
      align-items: center;
      width: 100%;

      .info {
        min-width: 0;
      }
    }

    .avatar-image {
      margin-right: 16px;
    }

    .section-content {
      margin-bottom: 4px;
      color: #717171;
      &.link {
        &:hover {
          text-decoration: underline;
        }
      }
    }

    .title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .title-content {
        display: flex;
        align-items: center;
        .title-icon {
          display: inline-block;
          vertical-align: middle;
        }
      }

      .title-icon-direction-reverse {
        flex-direction: row-reverse;
        .icon-box {
          margin-left: 8px;
        }
      }

      .text {
        line-height: 30px;
      }
    }

    .tag-list-container {
      margin-top: 2px;
      .tag-list-text {
        white-space: nowrap;
        overflow: hidden;
        display: inline-flex;
        align-items: center;
        color: $color-gray800;
        .clickable-tag-list-text {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
  .clickable {
    cursor: pointer;
    :hover {
      text-decoration: underline;
    }
  }
  .empty-detail {
    color: #b3b3b3;
  }
}
.tab-list-wrap {
  position: sticky;
  top: 64px;
  z-index: var(--z-content-header);

  /* ブラウザの幅が小さく横スクロールできるとき、スクロールしても
     余白が生じないようにmin-widthを設定しておく */
  min-width: max-content;
  background: #ffffff;
  padding: 0 24px;
  display: flex;
  justify-content: center;
  border-bottom: solid 1px #e6e6e6;
  .m-type-tab-list {
    width: 948px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    background-color: white;
    padding-top: 4px;
    box-sizing: border-box;
    border-radius: 4px;
  }

  &.align-left {
    justify-content: start;
  }
}
.align-center {
  margin: 0 auto;
}

.organization-tag-links {
  display: flex;
  width: 500px; /** 構造上調整が難しい状態のためマジックナンバーで対応 */
  flex-wrap: wrap;
  min-height: 20px;
  max-height: 60px;
  overflow-y: auto;
  flex-shrink: 0;
  align-items: center;
  a {
    color: $color-gray800;
    text-decoration: underline;
    display: inline-block;
    margin-left: 4px;
  }
}
</style>
