<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref, Ref } from 'vue';
import { useRoute } from 'vue-router';
import api from '@/api';
import { PageName } from '@/api/tracking';
import { DgrLoading } from '@stockmarkteam/donguri-ui';
import { AxiosError } from 'axios';
import AdpDocumentCard from '@/components/common/adp-document-card.vue';
import AdpDocumentInfoDefault from '@/components/common/adp-document/adp-document-info-default.vue';
import AdpDocumentInfo from '@/components/common/adp-document/adp-document-info.vue';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import {
  AdpDocument,
  ArticleUpdateOption,
  Comment,
  DocType,
  Group,
  GroupActivitySortType,
  Lang,
  TagSelectItem,
} from '@/types';
import { ContentPosition, syncContentScrollWithViewport } from '@/utils/scroll';
import { selectArticle } from '@/utils/selectArticle';
import { useGroupActivities, useTeamUsers, useUserInfo } from '@/utils/swr';
import { useEmitter } from '@/utils/vue';
import GroupActivitiesInfinityScroller from './group-activities-infinity-scroller.vue';

const props = defineProps<{
  group: Group;
  activitiesCount: number | undefined;
  isShowArticleCardComment: boolean;
  selectedTags: string[];
  trackingSessionId: string;
  sortType: GroupActivitySortType;
  selectedMemoExistsTypeForApi: boolean | undefined;
  startDate: string | undefined;
  endDate: string | undefined;
  selectedLangForApi: Lang[];
  selectedDocTypesForApi: DocType[];
  searchQueryForCountApi: string;
  selectedPestNewsCategories: string[];
  articleTagList: TagSelectItem[];
}>();

const PAGE_NAME: PageName = 'group_activities';
const route = useRoute();
const { createSnackbar } = useSnackbar();

const rightColumn: Ref<HTMLElement | undefined> = ref<
  HTMLElement | undefined
>();
const rightContent: Ref<HTMLElement | undefined> = ref<
  HTMLElement | undefined
>();

const selectedArticles = ref<AdpDocument[]>([]);

let prevWindowScrollY = 0;
let resizeObserver: ResizeObserver | undefined = undefined;
let contentPosition: ContentPosition = 'top';

onMounted(async () => {
  window.addEventListener('scroll', handleScroll);
  window.addEventListener('resize', handleResize);
  handleScroll();
  if (rightContent.value) {
    resizeObserver = new ResizeObserver(() => handleResize());
    resizeObserver.observe(rightContent.value);
  }
});

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll);
  window.removeEventListener('resize', handleResize);
  resizeObserver?.disconnect();
});

const { data: users } = useTeamUsers();
// 右カラムの共有者というUIのために使用される
// 現在のgroupでgroup markをつけたユーザーを取得して表示するため
const selectedArticleGroupMarkUser = computed(() => {
  const selectedGroupMark = selectedArticles.value[0].group_marks.find(
    gm => gm.group_id === props.group.id,
  );

  return users.value?.users.find(u => {
    return u.id === selectedGroupMark?.creator_id;
  });
});

const hasGroupMark: (article: AdpDocument) => boolean = (
  article: AdpDocument,
) => {
  return (
    article.group_marks.find(gm => gm.group_id === props.group.id) !== undefined
  );
};

const removeGroupMark = async (article: AdpDocument) => {
  try {
    const index = article.group_marks.findIndex(
      gm => gm.group_id === props.group.id,
    );
    if (index === -1) {
      throw new Error('targetGroupMark is not found');
    }
    await api.deleteGroupMark(article.group_marks[index].id);
    article.group_marks.splice(index, 1);
    createSnackbar({
      message: 'グループマークを外しました',
      type: 'success',
    });
  } catch {
    createSnackbar({
      message: 'グループマークを外せませんでした',
      type: 'error',
    });
  }
};

// ADP card を選択した時の処理
const toggleSelected = (e: MouseEvent, item: AdpDocument) => {
  selectedArticles.value = selectArticle(e, item, selectedArticles.value);
};
// ADP card に渡すtracking用のdata
const contentsContext = computed(() => {
  return {
    event_name: 'group_activities_view',
    session_id: props.trackingSessionId,
  } as const;
});

// FIXME: stickyで対応する。この処理は削除する
// window sizeを変更した時に右カラムを追従させるため
const handleResize = (event?: Event) => handleScroll(event, true);
// scrollが発生した時に動かしたい処理。右カラムを追従させるため
const CONTENT_HEADER_HEIGHT = 260;
const handleScroll = (_?: Event, reset = false): void => {
  if (!rightColumn.value || !rightContent.value) return;
  const data = syncContentScrollWithViewport(
    rightContent.value,
    rightColumn.value,
    prevWindowScrollY,
    contentPosition,
    CONTENT_HEADER_HEIGHT,
    0,
    reset,
  );
  if (data) {
    prevWindowScrollY = data.windowScrollY;
    contentPosition = data.contentPosition;
  }
};
// ADP card が選択されているかどうかを判定する。classのみに使用されている
const isSelected = (item: AdpDocument) => {
  return selectedArticles.value.map(i => i.id).includes(item.id);
};

const { data: myInfo } = useUserInfo();
const hasEditInfoPermission = computed(() =>
  myInfo.value !== undefined || props.group !== undefined
    ? myInfo.value!.role === 'admin' || props.group.is_member
    : false,
);

const emitter = useEmitter();
// 記事情報の更新処理
const articleFilter = (article: AdpDocument, id: AdpDocument['id']) => {
  return article.id === id;
};
const updateArticle = (
  articles: AdpDocument[],
  updatedArticle: AdpDocument,
) => {
  const index = articles.findIndex(c => {
    return c.id === updatedArticle.id;
  });
  if (index >= 0) {
    articles[index].marks = updatedArticle.marks;
    articles[index].memo = updatedArticle.memo;
    articles[index].tags = updatedArticle.tags;
  }
  return articles;
};
const updateArticleInfo = async (
  article: AdpDocument,
  updateOptions: ArticleUpdateOption,
) => {
  try {
    if (updateOptions.shouldUpdateMemo) {
      if (article.memo) {
        await api
          .updateGroupArticleMemo(
            props.group.id,
            article.id,
            article.doc_type,
            article.memo,
            article.lang,
          )
          .catch((err: AxiosError) => {
            throw err;
          });
      } else {
        await api
          .deleteGroupArticleMemo(
            props.group.id,
            article.id,
            article.doc_type,
            article.lang,
          )
          .catch((err: AxiosError) => {
            if (err.response?.status !== 404) {
              throw err;
            }
          });
      }
    }

    if (updateOptions.shouldUpdateTags) {
      await api
        .updateGroupArticleTags(
          props.group.id,
          article.id,
          article.doc_type,
          article.tags || [],
          article.lang,
        )
        .catch((err: AxiosError) => {
          throw err;
        });
    }

    selectedArticles.value = selectedArticles.value.map(selectedArticle =>
      selectedArticle.id === article.id ? article : selectedArticle,
    );
    emitter.emit('pagination-items-update', {
      filterFunc: (i: AdpDocument) => articleFilter(i, article.id),
      updateFunc: (items: AdpDocument[]) => updateArticle(items, article),
    });
    await api.trackEvent(
      'set_article_option',
      {
        pageName: 'group',
        pageUrl: route.fullPath,
        feature: 'group_activities',
      },
      article.id,
      props.group.id,
      {
        tags: article.tags,
        memo: article.memo,
      },
    );
    createSnackbar({
      message: '記事情報を更新しました',
      type: 'success',
    });
  } catch {
    createSnackbar({
      message: '記事情報を更新できませんでした',
      type: 'error',
    });
  }
};

// コメントの削除処理
const deleteComment = (comments: Comment[], id: Comment['id']) => {
  const parentIndex = comments.findIndex(c => c.id === id);
  if (parentIndex >= 0) {
    comments.splice(parentIndex, 1);
  } else {
    comments.forEach(comment => {
      const idx = comment.children?.findIndex(child => {
        return child.id === id;
      });
      if (idx >= 0) {
        comment.children.splice(idx, 1);
      }
    });
  }
};
const handleCommentDelete = (id: Comment['id']) => {
  emitter.emit('pagination-item-delete', (items: Comment[]) =>
    deleteComment(items, id),
  );
};
onMounted(async () => {
  emitter.on('comment-deleted', handleCommentDelete);
});
onUnmounted(() => {
  emitter.off('comment-deleted', handleCommentDelete);
});

const getActivities = (
  pageRef: Ref<number>,
  offsetRef: Ref<number>,
  pageLimit: number,
) => {
  return useGroupActivities(
    props.group.id,
    pageRef,
    offsetRef,
    pageLimit,
    props.sortType,
    props.selectedTags,
    props.selectedMemoExistsTypeForApi,
    props.startDate,
    props.endDate,
    props.selectedLangForApi,
    props.selectedDocTypesForApi,
    props.searchQueryForCountApi,
    props.selectedPestNewsCategories,
  );
};
type ResponseType = {
  activities: (AdpDocument | Comment)[];
  next: number | null;
  page: number;
  tracking_session_id: string | undefined;
};
const dataAccessor = (response?: ResponseType) => {
  return response?.activities;
};
const pageInfoAccessor = (response?: ResponseType) => {
  return response?.page !== undefined && response?.next !== undefined
    ? {
        page: response.page,
        next: response.next,
      }
    : undefined;
};

const handleFetchActivities = (response: ResponseType) => {
  emitter.emit(
    'change-tracking-session-id-for-group-activity',
    response?.tracking_session_id,
  );
  selectedArticles.value = [];
};
</script>

<template>
  <div class="group-activity-content">
    <div class="group-activity-card-list-area">
      <div v-if="activitiesCount === 0">
        <div class="no-activity">
          <div class="content">
            <div class="c-text c-text--m">
              該当するアクティビティはありませんでした。
            </div>
          </div>
          <img src="@/assets/state-prepare-feed-small.png" />
        </div>
      </div>
      <GroupActivitiesInfinityScroller
        :key="
          [
            sortType,
            group.id,
            selectedTags.join(','),
            selectedMemoExistsTypeForApi,
            startDate,
            endDate,
            selectedLangForApi.join(','),
            selectedDocTypesForApi.join(','),
            searchQueryForCountApi,
            selectedPestNewsCategories.join(','),
          ].join('-')
        "
        :page-limit="10"
        :pagination-func="getActivities"
        :data-accessor="dataAccessor"
        :page-info-accessor="pageInfoAccessor"
        @fetch="handleFetchActivities"
        class="group-activity-card-list"
      >
        <template v-slot="{ items: activities, loading }">
          <template v-for="activity in activities">
            <!-- NOTE: activities は comment or article -->
            <!-- NOTE: urlがあるのはarticleのみ -->
            <div
              v-if="'url' in activity"
              :key="`with-document-${activity.id}`"
              class="group-activity-card"
            >
              <AdpDocumentCard
                width="100%"
                :adp-document="activity"
                :theme-id="activity.theme_id"
                :group="group"
                :show-comment="isShowArticleCardComment"
                :page-name="PAGE_NAME"
                :feature="'group_activities'"
                @click="toggleSelected($event, activity)"
                :enable-hover="!isSelected(activity)"
                :is-selected="isSelected(activity)"
                @click-remove-group-mark="removeGroupMark(activity)"
                :is-use-remove-group-mark="hasGroupMark(activity)"
                :show-tag-list="true"
                :priority-display-tags="selectedTags"
                :contents-context="contentsContext"
              ></AdpDocumentCard>
            </div>
            <!-- NOTE: commentでかつコメントを表示に設定していた場合に表示する -->
            <template v-else-if="isShowArticleCardComment">
              <div
                :key="`only-comment-${activity.id}`"
                class="group-activity-card"
              >
                <AdpDocumentCard
                  width="100%"
                  :theme-id="activity.theme_id"
                  :comment="activity"
                  :page-name="PAGE_NAME"
                  :feature="'group_activities'"
                  :show-tag-list="true"
                  :priority-display-tags="selectedTags"
                  :contents-context="contentsContext"
                ></AdpDocumentCard>
              </div>
            </template>
          </template>
          <DgrLoading v-if="loading" class="loading-spinner" />
        </template>
      </GroupActivitiesInfinityScroller>
    </div>
    <div class="side-content" ref="rightColumn">
      <div class="side-content-body" ref="rightContent">
        <AdpDocumentInfo
          class="article-info"
          v-if="selectedArticles.length === 1"
          :editable="hasEditInfoPermission"
          :adp-document="selectedArticles[0]"
          :creator="selectedArticleGroupMarkUser"
          :tag-list="articleTagList"
          @article-info-updated="updateArticleInfo"
          :is-group-view="true"
          :group-id="group.id"
          :page-name="PAGE_NAME"
          :has-document-export="true"
        ></AdpDocumentInfo>
        <AdpDocumentInfoDefault
          :is-group-view="true"
          :adp-document="selectedArticles"
          :page-name="PAGE_NAME"
          :group-id="group.id"
          :has-document-export="true"
          v-else
        ></AdpDocumentInfoDefault>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.loading-spinner {
  width: 100%;
  margin: 0 auto;
}

.no-activity {
  display: flex;
  padding: 8px 32px;
  background-color: #fff;
  border: 1px solid $color-gray400;
  border-radius: 4px;
  justify-content: space-between;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  img {
    width: 100px;
    height: 100px;
  }
}

.group-activity-card-list-area {
  width: 616px;
}

.group-activity-card-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.side-content {
  position: relative;
  width: 288px;
  margin-left: 16px;
  box-sizing: border-box;
  padding-bottom: 80px;
}

.side-content-body {
  bottom: auto;
  width: 288px;
  padding-bottom: 80px;

  .article-info {
    margin-bottom: 20px;
  }
}

.group-activity-content {
  display: flex;
}
</style>
