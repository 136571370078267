import axios from 'axios';
import { Site } from '@/types';

export async function getSites(
  isEntertainment = false,
  page: number | null = null,
  limit: number | null = null,
  keyword: string | null = null,
  excludeSiteIds: number[] | null = null,
): Promise<Site[]> {
  const params: {
    is_entertainment: boolean;
    page?: number | null;
    limit?: number | null;
    keyword?: string | null;
    excludeSiteIds?: number[] | null;
  } = { is_entertainment: isEntertainment };
  if (page) params.page = page;
  if (limit) params.limit = limit;
  if (keyword) params.keyword = keyword;
  if (excludeSiteIds && excludeSiteIds.length > 0)
    params.excludeSiteIds = excludeSiteIds;

  const { data } = await axios.get<{ sites: Site[] }>(`/sites`, {
    params: params,
  });
  return data.sites;
}
