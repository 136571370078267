<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
  Ref,
  ref,
  UnwrapRef,
  watch,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import api from '@/api';
import { Feature, PageName } from '@/api/tracking';
import { HTTP_URL_REGEXP } from '@/constants';
import Avatar from '@/components/common/atoms/avatar.vue';
import MentionAutocompleteTextarea from '@/components/common/molecules/mention-autocomplete-textarea.vue';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import GroupCommentList from '@/components/group/group-comment-list.vue';
import GroupHeader from '@/components/group/group-header.vue';
import Content from '@/components/layouts/content.vue';
import { Article, Comment, GroupUser } from '@/types';
import { formatDate } from '@/utils/formatters';
import { getTabs, isGroupAdmin } from '@/utils/group';
import { isHttpUrl, urlParse } from '@/utils/parsers';
import {
  STATES,
  useGroupComments,
  useGroups,
  useTeamUsers,
  useUserInfo,
} from '@/utils/swr';
import { useEmitter, useStore } from '@/utils/vue';

export default defineComponent({
  components: {
    GroupHeader,
    Avatar,
    Content,
    MentionAutocompleteTextarea,
    GroupCommentList,
  },
  props: {
    isMenuVisible: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const emitter = useEmitter();
    const store = useStore();
    const { createSnackbar } = useSnackbar();

    const { data: groups, mutate, state } = useGroups();
    const { data: myInfo } = useUserInfo();
    const groupMembers = reactive({ list: [] as GroupUser[] });
    const { data: teamUsers } = useTeamUsers();
    const disabledButton = ref(false);

    const hasCommentPermission = computed(
      () => myInfo.value?.role === 'admin' || group.value?.is_member,
    );
    const groupId = computed(() => {
      if (route.params.groupId instanceof Array) {
        return route.params.groupId[0];
      }
      return route.params.groupId;
    });

    const group = computed(() =>
      (groups.value ?? { groups: [] }).groups.find(
        g => g.id === Number(groupId.value),
      ),
    );

    const trackPageView = async () => {
      await api.trackPageView({
        pageName: 'group',
        pageUrl: route.fullPath,
        group: {
          id: Number(route.params.groupId),
        },
      });
    };

    const updateLastViewedTimeForGroup = async () => {
      if (group.value?.is_member) {
        await api.updateLastViewedTimeForGroup(group.value.id);
      }
    };

    const handleCreate = (comment: Comment) => {
      if (comment.group_id === Number(groupId.value)) {
        emitter.emit('pagination-item-create', comment);
      }
    };
    const handleUpdate = (article: Article) => {
      emitter.emit('pagination-items-update', {
        filterFunc: (i: Comment) => commentFilter(i, article.id),
        updateFunc: (items: Comment[]) => updateComment(items, article),
      });
    };

    onMounted(() => {
      if (validateGroup()) {
        trackPageView();
        updateLastViewedTimeForGroup();
        fetchGroupMembers();
        emitter.on('comment-created', handleCreate);
        emitter.on('article-updated', handleUpdate);
      }
    });

    onUnmounted(() => {
      emitter.off('comment-created', handleCreate);
      emitter.off('article-updated', handleUpdate);
    });

    watch(
      () => groupId.value,
      () => {
        if (validateGroup()) {
          trackPageView();
          fetchGroupMembers();
        }
      },
    );
    watch(
      () => group.value,
      () => {
        if (validateGroup()) {
          updateLastViewedTimeForGroup();
        }
      },
    );

    watch(
      () => store.state.modal.isSharing,
      () => {
        if (store.state.modal.isSharing) {
          const comment = store.state.modal.comment;
          const article = store.state.modal.article;
          emitter.emit('pagination-item-create', {
            ...comment,
            article,
          });
          store.commit('modal/setIsSharing', false);
        }
      },
    );

    const validateGroup = () => {
      // グループから他のページに移動したらバリデーションが必要ない
      if (groupId.value === undefined) return false;

      if (state.value === STATES.SUCCESS && group.value === undefined) {
        createSnackbar({
          message: '指定されたグループは存在しません',
          type: 'error',
        });
        router.push({ name: 'anewsHome' });
        return false;
      }
      return true;
    };

    const commentTextRef =
      ref<InstanceType<typeof MentionAutocompleteTextarea>>();

    const isPostEnabled = () => {
      if (disabledButton.value) {
        return false;
      }
      return (
        (commentTextRef.value ?? { trimmedComment: '' }).trimmedComment.length >
        0
      );
    };

    const isPostingComment = ref(false);
    const post = async () => {
      if (isPostEnabled()) {
        isPostingComment.value = true;
        if (commentTextRef.value) {
          const comment = commentTextRef.value.trimmedComment;
          const mention = commentTextRef.value.mention;
          const trackingBaseData = {
            pageName: 'group' as PageName,
            feature: 'group_comments' as Feature,
            pageUrl: route.fullPath,
          };
          if (comment.length > 0) {
            try {
              const matched = comment.match(HTTP_URL_REGEXP);
              let url;
              if (matched && isHttpUrl(matched[0])) {
                url = matched[0];
              }
              let article: Article | undefined;
              if (url) {
                article = await api
                  .createArticleShare(url, trackingBaseData)
                  .catch(() => undefined);
              }
              const createdComment = await api.sendComment({
                groupId: Number(groupId.value),
                content: comment,
                mention,
                trackingBaseData,
                adpDocument: article,
              });
              emitter.emit('pagination-item-create', {
                ...createdComment,
                article,
              });
              createSnackbar({
                message: 'コメントを投稿しました',
                type: 'success',
              });
            } catch {
              createSnackbar({
                message: 'コメントを投稿できませんでした',
                type: 'error',
              });
            }
          }
        }
        isPostingComment.value = false;
        commentTextRef.value?.resetTrimmedComment();
      }
    };

    const callPostComment = () => {
      if (commentTextRef.value?.trimmedComment !== '') {
        commentTextRef.value?.postComment();
      }
    };

    const fetchGroupMembers = async () => {
      groupMembers.list = await api.fetchGroupMembers(Number(groupId.value));
    };

    const filteredGroupMembers = computed(() => {
      const adminMemberList: GroupUser[] = groupMembers.list
        .filter(member => isGroupAdmin(member.role))
        .sort((a, b) => {
          return a.created_at.localeCompare(b.created_at);
        });

      const normalMemberList: GroupUser[] = groupMembers.list
        .filter(member => !isGroupAdmin(member.role))
        .sort((a, b) => {
          return a.created_at.localeCompare(b.created_at);
        });

      const MAX_DISPLAY_NUMBER = 5;
      const memberList = adminMemberList
        .concat(normalMemberList)
        .slice(0, MAX_DISPLAY_NUMBER);

      return memberList;
    });

    const userIconUrl = (userId: GroupUser['id']) => {
      const targetUser = teamUsers.value?.users.find(
        user => user.id === userId,
      );
      return targetUser ? targetUser.image_url : '';
    };

    const commentFilter = (comment: Comment, id: number) => {
      return comment.article?.id === id;
    };

    const updateComment = (comments: Comment[], updatedArticle: Article) => {
      comments.forEach(c => {
        if (c.article?.id === updatedArticle.id) {
          c.article = updatedArticle;
        }
      });
      return comments;
    };

    return {
      group,
      filteredGroupMembers,
      hasCommentPermission,
      userIconUrl,
      mutate,
      urlParse,
      formatDate,
      myInfo,
      commentTextRef,
      callPostComment,
      post,
      isPostEnabled,
      isPostingComment,
      pageLimit: 10,
      paginationFunc: (pageRef: Ref<number>, pageLimit: number) =>
        useGroupComments(groupId, pageRef, pageLimit),
      dataAccessor: (
        d: UnwrapRef<ReturnType<typeof useGroupComments>['data']>,
      ) => d?.group_comments,
      getTabs,
      disabledButton,
      isGroupAdmin,
    };
  },
});
</script>

<template>
  <div class="group" v-if="group && myInfo">
    <GroupHeader
      :group="group"
      :user-info="myInfo"
      :is-menu-visible="isMenuVisible"
    />
    <Content>
      <div class="group-content">
        <div class="comment-area">
          <div class="new-comment" v-if="hasCommentPermission">
            <router-link v-if="myInfo" :to="`/users/${myInfo.id}`">
              <div class="user-info">
                <Avatar :image-url="myInfo.image_url"></Avatar>
                <div class="c-title c-title--m user-name">
                  {{ myInfo.user_name }}
                </div>
              </div>
            </router-link>
            <MentionAutocompleteTextarea
              class="comment-text-area"
              ref="commentTextRef"
              @post-comment="post"
              placeholder="コメントを書く（URLのみの投稿はできません）"
              :disabled="isPostingComment"
              :min-height="60"
              :show-url-preview="true"
              @update-button-status="disabledButton = $event"
            ></MentionAutocompleteTextarea>
            <div class="btn-area">
              <button
                class="o-post-button c-btn c-btn--AnewsPrimary"
                :class="{ disabled: !isPostEnabled() || isPostingComment }"
                :disabled="!isPostEnabled() || isPostingComment"
                @click="callPostComment"
              >
                投稿
              </button>
            </div>
          </div>
          <GroupCommentList
            class="comment-list"
            :class="{ 'no-new-comment': !hasCommentPermission }"
            page-name="group"
            feature="group_comments"
            :group="group"
            :page-limit="pageLimit"
            :pagination-func="paginationFunc"
            :data-accessor="dataAccessor"
          >
            <template #emptyState>
              <div
                class="no-comments"
                :class="{ 'no-new-comment': !hasCommentPermission }"
              >
                <div class="content">
                  <div class="c-text c-text--m">コメントはまだありません。</div>
                  <div class="c-text c-text--m">
                    感じたことや気づいたことなど、気軽に投稿してみてください！
                  </div>
                </div>
                <img src="@/assets/state-prepare-feed-small.png" />
              </div>
            </template>
          </GroupCommentList>
        </div>
        <div class="side-content" v-if="group.group_type !== 'all_user_group'">
          <div class="member-list" v-if="hasCommentPermission">
            <div class="o-header">
              <div class="member-title c-title c-title--s">
                メンバー・{{ group.member_count }}人
              </div>
              <router-link
                class="member-list-link c-title c-title--s"
                :to="`/groups/${group.id}/members`"
                >すべて表示
              </router-link>
            </div>
            <div v-for="member in filteredGroupMembers" :key="member.id">
              <router-link class="member-content" :to="`/users/${member.id}`">
                <Avatar
                  class="member-image"
                  :image-url="userIconUrl(member.id)"
                  size="s"
                ></Avatar>
                <div class="member-name c-text c-text--m">
                  {{ member.user_name }}
                </div>
                <div
                  class="member-authority c-text c-text--m"
                  v-if="isGroupAdmin(member.role)"
                >
                  管理者
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </Content>
  </div>
</template>

<style lang="scss" scoped>
.group {
  width: 100%;
  margin: -24px 0 0 0;

  .edit-link {
    margin-left: 12px;
    padding: 4px 8px;
    border-radius: 4px;
    color: #1da482;

    &:hover {
      background: #f2f2f2;
    }
  }

  .group-content {
    display: flex;

    .comment-area {
      width: 616px;
      margin-right: 16px;

      .new-comment {
        background-color: #fff;
        padding: 16px;
        border-radius: 4px;
        border: 1px solid #e6e6e6;

        .user-info {
          display: flex;

          .user-name {
            margin-left: 8px;
            display: flex;
            align-items: center;

            &:hover {
              text-decoration: underline;
            }
          }
        }

        .comment-text-area {
          margin-top: 10px;
        }

        .btn-area {
          margin-top: 8px;
          display: flex;
          justify-content: flex-end;

          .c-btn {
            width: 60px;
            height: 38px;
            padding: 0;
          }
        }
      }

      .comment-list,
      .no-comments {
        margin-top: 32px;
      }

      .no-new-comment {
        margin-top: 0;
      }

      .no-comments {
        display: flex;
        padding: 8px 32px;
        background-color: #fff;
        border: 1px solid #e6e6e6;
        border-radius: 4px;
        justify-content: space-between;

        .content {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        img {
          width: 100px;
          height: 100px;
        }
      }
    }

    .description-container {
      width: 288px;

      .inner {
        background-color: #fff;
        border: 1px solid #e6e6e6;
        padding: 16px;
        border-radius: 4px;
        margin-bottom: 12px;

        & > *:not(:last-child) {
          margin-bottom: 8px;
        }

        .description {
          word-wrap: break-word;
          white-space: pre-wrap;
        }

        .no-description {
          color: #b3b3b3;
        }
      }
    }

    .o-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .member-list-link {
        color: #1da482;
        padding: 4px 8px;

        &:hover {
          background-color: #f2f2f2;
          border-radius: 4px;
          cursor: pointer;
        }
      }
    }

    .member-list {
      background-color: #fff;
      border: 1px solid #e6e6e6;
      padding: 12px 16px;
      border-radius: 4px;

      & > *:not(:last-child) {
        margin-bottom: 4px;
      }

      .member-content {
        display: flex;
        align-items: center;

        .member-image {
          margin-right: 8px;
        }

        .member-name {
          margin-right: 8px;

          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }

        .member-authority {
          color: #b3b3b3;
        }
      }
    }
  }
}
</style>
