<script setup lang="ts">
interface Props {
  numberOfLines: number;
}

const props = defineProps<Props>();
</script>

<template>
  <div class="skeleton loading">
    <div class="title">
      <div v-for="(i, index) in props.numberOfLines" :key="index"></div>
    </div>
    <div class="thumbnail"></div>
  </div>
</template>

<style lang="scss" scoped>
.skeleton {
  display: grid;
  background: $color-white;
  padding: 16px 16px 60px;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgb(0 0 0 / 0.1);
  grid-template:
    'title thumbnail' auto
    / 1fr 80px;
  gap: 16px;
}

.title {
  grid-area: title;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  margin: 0 8px 8px 16px;

  div {
    width: 100%;
    height: 20px;
    border-radius: 4px;
    background: $color-gray400;
  }
}
.thumbnail {
  grid-area: thumbnail;
  width: 80px;
  height: 80px;
  background: $color-gray400;
  border-radius: 4px;
}

.skeleton.loading {
  .title div,
  .thumbnail {
    background-image: linear-gradient(
      110deg,
      rgb(230 230 230 / 1) 25%,
      rgb(255 255 255 / 0.35) 50%,
      rgb(230 230 230 / 1) 75%
    );
    background-size: 80% 100%;
    background-position: -500% 0;
    background-repeat: no-repeat;
    animation: loading 1.5s infinite;
  }
}

@keyframes loading {
  to {
    background-position: 500% 0;
  }
}
</style>
