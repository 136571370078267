<script lang="ts">
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import api from '@/api';
import {
  MAX_GROUP_DESCRIPTION_LENGTH,
  MAX_GROUP_NAME_LENGTH,
} from '@/constants';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import { sortActiveGroups } from '@/utils/sorters';
import { useGroups } from '@/utils/swr';
import { useEmitter, useStore } from '@/utils/vue';

export default defineComponent({
  components: {},
  setup() {
    const router = useRouter();
    const store = useStore();
    const emitter = useEmitter();
    const { createSnackbar } = useSnackbar();

    const trimmedGroupName = ref('');
    const trimmedGroupDesc = ref('');
    const groupNameInput = ref<HTMLInputElement>();
    const { data: groups, mutate } = useGroups();
    onMounted(() => groupNameInput.value?.focus());
    const tooLongGroupName = computed(() => {
      return trimmedGroupName.value.length > MAX_GROUP_NAME_LENGTH;
    });
    const tooLongGroupDesc = computed(() => {
      return trimmedGroupDesc.value.length > MAX_GROUP_DESCRIPTION_LENGTH;
    });
    const existingGroupName = computed(() => {
      return groups.value?.groups
        .map(g => g.name)
        .includes(trimmedGroupName.value);
    });
    const createEnable = computed(() => {
      return (
        trimmedGroupName.value.length > 0 &&
        !tooLongGroupName.value &&
        !tooLongGroupDesc.value &&
        !existingGroupName.value
      );
    });
    const hideModal = () => {
      store.commit('modal/hideModal');
      emitter.emit('close-modal');
    };
    const createGroup = async () => {
      if (!createEnable.value) return;
      try {
        await api.createGroup(trimmedGroupName.value, trimmedGroupDesc.value);
        await mutate();
        hideModal();
        if (groups.value) {
          const group = sortActiveGroups(
            groups.value.groups,
            'created_at',
            'desc',
          ).find(g => g.name === trimmedGroupName.value);
          router.push(`/groups/${group?.id ?? undefined}`);
        } else {
          router.push(`/groups`);
        }
        createSnackbar({
          message: 'グループを作成しました',
          type: 'success',
        });
      } catch {
        createSnackbar({
          message: 'グループを作成できませんでした',
          type: 'error',
        });
      }
    };
    return {
      trimmedGroupName,
      trimmedGroupDesc,
      createEnable,
      hideModal,
      tooLongGroupName,
      tooLongGroupDesc,
      existingGroupName,
      createGroup,
      MAX_GROUP_NAME_LENGTH,
      MAX_GROUP_DESCRIPTION_LENGTH,
      groupNameInput,
    };
  },
});
</script>

<template>
  <div class="o-group-create">
    <sm-dialog class="auto-height auto-width screen-center" @close="hideModal">
      <template #header>
        <div class="o-header c-text c-text--m">グループを新しく作成</div>
      </template>
      <template #body>
        <div class="o-body c-text c-text--m">
          <div class="o-group-name">
            <div class="o-group-name-text">グループ名</div>
            <div
              class="o-error c-formBlock__text c-formBlock__text--error"
              v-show="tooLongGroupName"
            >
              グループ名は{{
                MAX_GROUP_NAME_LENGTH
              }}文字以内で入力してください。
            </div>
            <div
              class="o-error c-formBlock__text c-formBlock__text--error"
              v-show="existingGroupName"
            >
              既に使用されているグループ名は設定できません。
            </div>
            <input
              class="o-group-name-input c-text c-text--m c-textInput"
              ref="groupNameInput"
              :class="{
                'c-formInput--error': tooLongGroupName || existingGroupName,
                'm-normal-text-area': !tooLongGroupName && !existingGroupName,
              }"
              v-model.trim="trimmedGroupName"
              @keydown.ctrl.enter="createGroup"
              @keydown.meta.enter="createGroup"
            />
          </div>
          <div class="o-group-desc">
            <div class="o-group-desc-text">グループの説明</div>
            <div
              class="o-error c-formBlock__text c-formBlock__text--error"
              v-show="tooLongGroupDesc"
            >
              グループの説明は{{
                MAX_GROUP_DESCRIPTION_LENGTH
              }}文字以内で入力してください。
            </div>
            <textarea
              class="o-group-desc-input c-text c-text--m c-textArea"
              :class="{
                'c-formInput--error': tooLongGroupDesc,
                'm-normal-text-area': !tooLongGroupDesc,
              }"
              v-model.trim="trimmedGroupDesc"
              @keydown.ctrl.enter="createGroup"
              @keydown.meta.enter="createGroup"
            ></textarea>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="o-footer c-text c-text--m">
          <button
            class="o-cancel-button c-btn c-btn--auto c-btnOutline"
            @click="hideModal"
          >
            キャンセル
          </button>
          <button
            class="o-create-button c-btn c-btn--auto c-btn--AnewsPrimary"
            :class="{ disabled: !createEnable }"
            :disabled="!createEnable"
            @click="createGroup"
          >
            作成
          </button>
        </div>
      </template>
    </sm-dialog>
  </div>
</template>
<style lang="scss" scoped>
.o-group-create {
  .o-group-name-text,
  .o-group-desc-text {
    margin-bottom: 4px;
  }
  .o-error {
    font-size: 12px;
    margin-top: -4px;
  }
  .o-group-name {
    margin-bottom: 12px;
  }
  .o-group-name-input {
    width: 320px;
    height: 40px;
    outline: none;
  }
  .o-group-desc-input {
    width: 448px;
    height: 160px;
    outline: none;
  }
  .o-cancel-button,
  .o-create-button {
    width: 120px;
    height: 40px;
    outline: none;
  }
  .o-footer {
    margin-top: -16px;
  }
}
</style>
