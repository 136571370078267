<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { getPreviewImageUrls } from '@/utils/user-document/common';

interface Props {
  isOpen: boolean;
  targetPage: number | undefined;
  fileTotalPages: number;
  previewImagePathTemplate: string;
  title: string;
}
const props = defineProps<Props>();
const currentViewPage = ref<number>(1);

const hideModal = () => {
  emit('onClose');
};

const emit = defineEmits<{
  onClose: [void];
}>();

const previewImageUrls = computed(() =>
  getPreviewImageUrls(props.fileTotalPages, props.previewImagePathTemplate),
);

// 特定のidまでスクロールする
const scrollToId = (id: string) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: 'instant' });
  }
};

// 画像が読み込まれた際にスクロールする
const imageLoaded = (page: number) => {
  if (page === props.targetPage) {
    scrollToId(`preview-${page}`);
    currentViewPage.value = page;
    intersectionObserverSetting();
  }
};

const modalBodyRef = ref<HTMLElement | null>();
const imageObserver = ref<IntersectionObserver | null>();
const intersectionObserverSetting = () => {
  const observerOptions = {
    root: modalBodyRef.value, // モーダル内でスクロールを監視
    rootMargin: '0px',
    threshold: 1.0,
  };

  imageObserver.value = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const target = entry.target;
        const id = target.id;
        currentViewPage.value = Number(id.replace('preview-center-', ''));
      }
    });
  }, observerOptions);

  // 各画像の中心を監視する
  const imageCenters = modalBodyRef.value?.querySelectorAll('.image-center');
  imageCenters?.forEach(img => imageObserver.value?.observe(img));
};

watch(
  () => props.isOpen,
  async () => {
    if (!props.isOpen) {
      imageObserver.value?.disconnect();
    }
  },
);

const getImageLoadingStrategy = (index: number) =>
  props.targetPage &&
  index < props.targetPage + 5 &&
  index > props.targetPage - 5
    ? 'eager'
    : 'lazy';
</script>

<template>
  <Teleport to="body">
    <sm-dialog
      v-if="isOpen"
      @close="hideModal"
      class="screen-center no-padding auto-width"
    >
      <template #header>
        <div class="c-dialog__title modal-header">
          {{ title }}
        </div>
      </template>
      <template #body>
        <div id="modal-body" ref="modalBodyRef">
          <div
            v-for="(url, i) in previewImageUrls"
            :key="url"
            class="preview-image"
          >
            <img
              @load="imageLoaded(i + 1)"
              :src="url"
              :id="`preview-${i + 1}`"
              :loading="getImageLoadingStrategy(i + 1)"
            />
            <!-- 交差監視用dom -->
            <!-- ビューポートを超えた画像がある場合、交差監視ができない状態が発生するためダミーの矩形を使用 -->
            <div
              class="image-center"
              :class="{
                'image-center-first': i == 0,
                'image-center-last': i == previewImageUrls.length - 1,
              }"
              :id="`preview-center-${i + 1}`"
            ></div>
          </div>
          <div class="c-text c-text--s page-counter">
            {{ currentViewPage }} / {{ fileTotalPages }}
          </div>
        </div>
      </template>
    </sm-dialog>
  </Teleport>
</template>

<style lang="scss" scoped>
.modal-header {
  padding: 5px 0px;
}
#modal-body {
  --modal-body-height: 80vh;
  max-height: var(--modal-body-height);
  overflow: scroll;
  padding: 16px 16px 56px 16px;
  position: relative;
  .preview-image {
    position: relative;
    --image-min-height: 300px;
    img {
      margin-bottom: 16px;
      min-height: var(--image-min-height);
      width: 60vw;
    }
    .image-center {
      --image-center-height: calc(var(--modal-body-height) * 0.8);
      width: 1px;
      height: var(--image-center-height);
      background-color: transparent;
      position: absolute;
      left: 50%;
      top: calc(50% - var(--image-center-height) / 2);
    }
    .image-center-first {
      top: 0;
    }
    .image-center-last {
      top: auto;
      bottom: 0;
    }
  }
  .page-counter {
    --page-counter-width: 80px;
    position: fixed;
    text-align: center;
    background-color: $color-white;
    padding: 16px;
    border-radius: 90px;
    border: 1px solid $color-gray400;
    bottom: 10px;
    left: calc(50% - var(--page-counter-width) / 2);
    width: var(--page-counter-width);
  }
}
</style>
