<script setup lang="ts">
import { computed } from 'vue';
import { COMMENT_SHARE, MAX_TOOLTIP_LIST_LENGTH } from '@/constants';
import { DgrIcon } from '@stockmarkteam/donguri-ui';

const props = defineProps<{
  commentUsers: {
    id: number;
    name: string;
    commentId: number;
  }[];
}>();

const commentCount = computed(() => props.commentUsers.length);

const tooltipContents = computed(() => {
  return props.commentUsers.slice(0, MAX_TOOLTIP_LIST_LENGTH);
});

const otherCommentUsersCount = computed(() => {
  return commentCount.value - MAX_TOOLTIP_LIST_LENGTH;
});
</script>

<template>
  <VTooltip theme="menu" placement="top">
    <button class="comment-button">
      <DgrIcon
        class="comment-icon"
        size="small"
        name="comment"
        alt="comment icon"
      />
      <div
        class="comment-total-count c-title c-title--s"
        v-if="commentCount > 0"
      >
        {{ commentCount }}
      </div>
    </button>

    <template #popper>
      <template v-if="tooltipContents.length === 0">
        {{ COMMENT_SHARE }}
      </template>
      <template v-else>
        <template v-for="user in tooltipContents" :key="user.id">
          <router-link
            :to="{
              name: 'groupComment',
              params: { commentId: user.commentId },
            }"
          >
            ・{{ user.name }}
          </router-link>
          <br />
        </template>
        <p v-if="otherCommentUsersCount > 0">
          {{ `他${otherCommentUsersCount}名` }}
        </p>
      </template>
    </template>
  </VTooltip>
</template>

<style lang="scss" scoped>
.comment-button {
  all: unset;
  display: flex;
  align-items: center;
  padding: 8px 10px;
  width: fit-content;
  min-width: 32px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: $color-gray200;
  }

  .comment-icon {
    margin-right: 4px;
  }
}
</style>
