<script setup lang="ts">
import api from '@/api';
import { ContentsContext } from '@/api/tracking';
import CitationSourceNumberBadge from '@/components/survey/generate-content/citation-source-number-badge.vue';
import SurveyContentRenderer from '@/components/survey/generate-content/survey-content-renderer.vue';
import {
  AdpDocument,
  isAdpDocument,
  isDocument,
  Part,
  UserDocument,
} from '@/types';
import { useCurrentRoutePath } from '@/utils/composables/useCurrentRoutePath';
import { useEmitter } from '@/utils/vue';

interface Props {
  contentParts: Array<Part>;
  enableAnimation: boolean;
  contentsContext?: ContentsContext;
}

const props = defineProps<Props>();

const emitter = useEmitter();

const { getCurrentRoutePath } = useCurrentRoutePath();

const citationClicked = ({
  adpDocument,
}: {
  adpDocument: AdpDocument | UserDocument;
  citationNumber: number;
}) => {
  if (!adpDocument) return;
  if (!isDocument(adpDocument)) return;

  emitter.emit('article-updated', { ...adpDocument, is_read: true });
  if (isAdpDocument(adpDocument)) {
    api.sendView({
      adpDocument: adpDocument,
      trackingBaseData: {
        pageName: 'survey',
        pageUrl: getCurrentRoutePath(),
        feature: 'survey',
      },
      contentsContext: props.contentsContext,
    });
  }
};
</script>

<template>
  <template v-for="(part, i) in props.contentParts" :key="i">
    <span
      v-if="part.type === 'text'"
      :class="{ typewriter: props.enableAnimation }"
      :style="props.enableAnimation ? { animationDelay: `${part.delay}s` } : {}"
    >
      {{ part.content }}
    </span>
    <span
      v-else-if="part.type === 'citationNumberBadge'"
      class="citation-number-badge"
      :class="{ typewriter: props.enableAnimation }"
      :style="props.enableAnimation ? { animationDelay: `${part.delay}s` } : {}"
    >
      <CitationSourceNumberBadge
        :number="part.number!"
        :source-document="part.sourceDocument!"
        @link-clicked="v => citationClicked(v)"
      />
    </span>
    <component
      v-else
      :is="part.tagName"
      v-bind="part.attributes"
      :style="props.enableAnimation ? { animationDelay: `${part.delay}s` } : {}"
      :class="{ typewriter: props.enableAnimation }"
    >
      <SurveyContentRenderer
        :key="`children-${i}`"
        v-if="part.children"
        :content-parts="part.children"
        :enable-animation="props.enableAnimation"
      />
    </component>
  </template>
</template>

<style lang="scss" scoped>
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'NotoSansCJKjp-Bold';
  margin: 20px 0 8px;
  font-size: 16px;
}

p,
li {
  font-size: 16px;
  line-height: 1.7;
}

li {
  margin: 8px 0 8px 2em;
}

strong {
  font-family: 'NotoSansCJKjp-Bold';
  font-weight: bold;
}

// パーサー(domParser.ts)側でタグ名で判定してクラスを付与するロジックを入れたくなかったため、描画側のスタイルで対応
table {
  width: 100%;
  border-collapse: collapse;
  font-size: 16px;
  line-height: 1.7;
  background-color: $color-white;
}

thead th {
  background-color: $color-gray200;
  font-family: 'NotoSansCJKjp-Medium';
  font-weight: 700;
  border: 1px solid $color-gray400;
  padding: 8px;
  text-align: center;
  // 基本は`table-layout: auto;`の幅に任せるが、ヘッダーの最後の列に「参考」や「出典」が出力される場合に、2文字でも改行されてしまうパターンがあるため、最低限の幅を設定
  min-width: 50px;
}

tbody td {
  border: 1px solid $color-gray400;
  padding: 8px;
  text-align: left;
}

pre {
  line-height: 1.7;
  overflow-x: auto;
}

.typewriter {
  // NOTE: 秒数が短いと、アニメーションが効かず、文字落ちすることがあるため2秒に設定
  animation: text-animation 2s steps(1, end) forwards;
  opacity: 0;
}

@keyframes text-animation {
  to {
    opacity: 1;
  }
}

.citation-number-badge {
  margin: 0 1px;
  vertical-align: text-bottom;
}
</style>
