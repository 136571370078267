import { fetchDocument } from '@/api/document';
import { TrackingBaseData } from '@/api/tracking';
import axios from 'axios';
import { Article, UrlPreviewWithInputUrl } from '@/types';

export async function fetchUrlPreview(
  url: string,
): Promise<UrlPreviewWithInputUrl> {
  const { data } = await axios.get<{ article_preview: UrlPreviewWithInputUrl }>(
    `/article_shares/preview?url=${encodeURIComponent(url)}`,
  );

  // urlとarticle_preview.urlが異なる場合があり,引数のurlも返したいのでここで詰める
  data.article_preview.input_url = url;
  return data.article_preview;
}

export async function createArticleShare(
  url: string,
  trackingBaseData: TrackingBaseData,
): Promise<Article> {
  const { data } = await axios.post<{ article_id: number }>(`/article_shares`, {
    url,
    tracking_data: {
      page: {
        name: trackingBaseData.pageName,
        url: trackingBaseData.pageUrl,
      },
    },
  });
  return (await fetchDocument(data.article_id, 'article')) as Article;
}
