<script setup lang="ts">
import { onMounted, onUnmounted, ref, watch } from 'vue';
import { DgrIcon } from '@stockmarkteam/donguri-ui';
import { FeedType } from '@/types';

interface Props {
  feedType: FeedType;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  'on-select': [{ option: FeedType }];
}>();

const { feedType } = props;
const options = new Map<FeedType, string>([
  ['all', '国内・海外メディア両方'],
  ['domestic', '国内メディアのみ'],
  ['foreign', '海外メディアのみ'],
]);

const selectedOption = ref<FeedType>(feedType);

watch(
  () => props.feedType,
  val => selectOption(val),
);

const isOptionsVisible = ref(false);

const hideMenu = () => (isOptionsVisible.value = false);

const selectOption = (option: FeedType) => {
  if (selectedOption.value !== option) {
    selectedOption.value = option;
    emit('on-select', { option: option });
  }
  hideMenu();
};

onMounted(() => {
  document.addEventListener('click', hideMenu);
});

onUnmounted(() => {
  document.removeEventListener('click', hideMenu);
});
</script>

<template>
  <div class="media-type-selection" @click.stop="() => undefined">
    <div class="title c-text c-text--xs">メディアの表示</div>
    <div>
      <div
        class="selected c-text c-text--m"
        @click.stop.prevent="() => (isOptionsVisible = !isOptionsVisible)"
      >
        {{ options.get(selectedOption) }}<DgrIcon name="angle-down" />
      </div>
      <div v-if="isOptionsVisible" class="options">
        <div
          v-for="[option, label] in Array.from(options.entries())"
          :key="option"
          class="option c-text c-text--m"
          @click.stop.prevent="() => selectOption(option)"
        >
          {{ label }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.media-type-selection {
  .title {
    /* margin-top: 16px; */
  }
  .selected {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }
  .options {
    position: absolute;
    top: 100%;
    left: 0;
    width: 271px;
    background: #fff;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    box-shadow: 0px 1px 3px rgba(74, 74, 74, 0.4);
    cursor: pointer;

    .option {
      padding: 8px 16px;

      &:not(:last-child) {
        border-bottom: 1px solid #e6e6e6;
      }
      &:hover {
        background: #f2f2f2;
      }
    }
  }
}
</style>
