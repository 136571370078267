import { Router } from 'vue-router';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import { userSession } from '@/utils/userSession';

export function redirectUnauthorized(
  userId: number | undefined,
  router: Router,
) {
  if (userSession.getUserId() !== userId) {
    router.push({ name: 'anewsHome' });

    const { createSnackbar } = useSnackbar();
    createSnackbar({
      message: '権限がありません',
      type: 'error',
    });
  }
}
