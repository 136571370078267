<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import { DISPLAY_LATEST_COMMENTS_COUNT } from '@/constants';
import ArticleComment from '@/components/common/comment/article-comment.vue';
import { AdpDocument, UserInfo } from '@/types';

export default defineComponent({
  props: {
    article: { type: Object as PropType<AdpDocument>, required: true },
    user: { type: Object as PropType<UserInfo>, required: true },
  },
  components: {
    ArticleComment,
  },
  setup(props) {
    const comments = computed(() => props.article?.comments ?? []);

    // 表示するコメント
    const displayComments = computed(() => {
      if (!comments.value || comments.value.length === 0) return [];
      return [...comments.value]
        .sort((x, y) => {
          if (x.created_at < y.created_at) return 1;
          if (x.created_at > y.created_at) return -1;
          return 0;
        })
        .slice(0, DISPLAY_LATEST_COMMENTS_COUNT);
    });

    // グループごとのコメント数
    const groupCommentCounts = computed(() => {
      let groupIds = displayComments.value.map(g => g.group_id);
      groupIds = [...new Set(groupIds)];
      return groupIds?.map(groupId => {
        const groupComment = (comments.value ?? []).filter(
          comment => comment.group_id === groupId,
        );

        // 記事に対してのグループ内の返信を含めたコメント総数
        const groupCommentsCount = groupComment
          .map(x => [x.id, x.children.map(y => y.id)])
          .flat(2).length;

        return {
          group_id: groupId,
          comment_count: groupCommentsCount,
        };
      });
    });

    // グループコメント数を含むコメント
    const formatComments = computed(() => {
      return displayComments.value.map(comment => {
        const groupCommentCount = groupCommentCounts.value.find(
          x => x.group_id === comment.group_id,
        );
        return {
          comment: comment,
          group_comments_count: groupCommentCount?.comment_count,
        };
      });
    });

    return {
      formatComments,
    };
  },
});
</script>

<template>
  <div>
    <div class="c-title c-title--xm">最新のコメント</div>
    <div class="spacing-16"></div>
    <template v-if="formatComments.length > 0">
      <div
        v-for="formatComment in formatComments"
        :key="formatComment.comment.id"
      >
        <ArticleComment
          :article="article"
          :comment="formatComment.comment"
          :group-comments-count="formatComment.group_comments_count"
          :omitting="false"
          :show-avatar="true"
          :show-reply-comment="false"
          :show-group="true"
          :show-reply-button="false"
          :is-summary-group="false"
          :show-join-group-button="false"
          :fold-replies="true"
          :is-display-only="true"
        ></ArticleComment>
        <div class="spacing-04"></div>
      </div>
    </template>
  </div>
</template>
