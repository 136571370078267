<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import { MAX_NAME_LENGTH } from '@/constants';
import { NotificationForceThemeFavoriteType } from '@/types';
import { formatThemeName, formatUserName } from '@/utils/formatters';
import CardTemplate from './card-template.vue';

// WARN: 通知の表記を変更するときは serviceWorkerHelper.ts の変更漏れがないかも確認する
// WARN: 通知のURLを変更するときは web-push.vue の変更漏れがないかも確認する

export default defineComponent({
  components: {
    CardTemplate,
  },
  props: {
    notification: {
      type: Object as PropType<NotificationForceThemeFavoriteType>,
      required: true,
    },
    isUnread: { type: Boolean, required: true },
  },
  setup(props) {
    const url = computed(() =>
      props.notification.theme.is_deleted
        ? undefined
        : `/themes/${props.notification.theme.id}`,
    );
    const formattedUserName = computed(() =>
      formatUserName(
        props.notification.creator_name,
        props.notification.is_deleted_creator,
        MAX_NAME_LENGTH,
      ),
    );
    const formattedThemeName = computed(() =>
      formatThemeName(
        props.notification.theme.name,
        props.notification.theme.is_deleted,
      ),
    );

    return {
      url,
      formattedUserName,
      formattedThemeName,
    };
  },
});
</script>
<template>
  <CardTemplate
    :type="notification.type"
    :url="url"
    :is-unread="isUnread"
    :created-at="notification.created_at"
    :user-id="notification.creator_id"
    :image-url="notification.creator_image_url"
  >
    {{ formattedUserName }}さんが、あなたのお気に入りテーマに「#{{
      formattedThemeName
    }}」を登録しました。
  </CardTemplate>
</template>
