<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import { ThemeTopic } from '@/types';
import { useThemeTopicCategories } from '@/utils/swr';

export default defineComponent({
  props: {
    filter: {
      type: String,
      default: '',
    },
    selectedThemeTopic: {
      type: Object as PropType<ThemeTopic>,
      default: null,
    },
  },
  setup(props, context) {
    const { data: categoriesResponse } = useThemeTopicCategories();
    const themeTopicCategories = computed(() => {
      if (!categoriesResponse.value) return [];
      if (!props.filter) return categoriesResponse.value.theme_topic_categories;

      const filter = props.filter.toLowerCase();
      return categoriesResponse.value.theme_topic_categories.flatMap(
        categories => {
          const theme_topics = categories.theme_topics.filter(
            topic =>
              topic.name.toLowerCase().includes(filter) ||
              topic.query_en?.toLowerCase().includes(filter) ||
              topic.query_ja?.toLowerCase().includes(filter),
          );

          if (theme_topics.length === 0) return [];
          return [
            {
              ...categories,
              theme_topics,
            },
          ];
        },
      );
    });

    const selectThemeTopic = (topic: ThemeTopic) => {
      context.emit('select-theme-topic', topic); // eslint-disable-line vue/require-explicit-emits
    };

    const tooltipTopics = (topic: ThemeTopic) => {
      return [topic.query_ja, topic.query_en].filter(query => !!query);
    };

    return {
      themeTopicCategories,
      selectThemeTopic,
      tooltipTopics,
    };
  },
});
</script>

<template>
  <div>
    <div v-if="themeTopicCategories.length > 0" class="c-title c-title--m">
      トピック
    </div>
    <div
      class="topic-select-form-item"
      v-for="category in themeTopicCategories"
      :key="category.id"
    >
      <div class="theme-topic-category-name">{{ category.name }}</div>
      <div>
        <div class="labels">
          <template v-for="topic in category.theme_topics" :key="topic.id">
            <VTooltip>
              <button
                class="c-btn c-btn--auto c-btnOutline"
                :class="{
                  selected:
                    selectedThemeTopic && topic.id === selectedThemeTopic.id,
                }"
                @click="selectThemeTopic(topic)"
              >
                {{ topic.name }}
              </button>
              <template #popper>
                <div v-for="topicName in tooltipTopics(topic)" :key="topicName">
                  ・{{ topicName }}
                </div>
              </template>
            </VTooltip>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.topic-select-form-item {
  display: grid;
  grid-template-columns: 136px 1fr;
  padding: 25px 0px;
  border-bottom: 1px solid #e6e6e6;
}

.selected {
  border: 1px solid $color-green400;
  background: $color-green100;
  &:hover {
    background: $color-green100;
  }
}

.labels {
  display: flex;
  flex-wrap: wrap;
  gap: 16px 14px;
  padding-top: 4px;
}
</style>
