<script lang="ts">
import { defineComponent } from 'vue';
import dayjs from 'dayjs';
import {
  formatDateForComment,
  formatDateTime,
  showRelativeTime,
} from '@/utils/formatters';

export default defineComponent({
  props: {
    datetime: { type: String, required: true },
    hideTooltip: { type: Boolean, default: false },
  },
  setup(props) {
    const day = dayjs(props.datetime);

    const showTooltip = !props.hideTooltip && showRelativeTime(day);

    return {
      day,
      showTooltip,
      formatDateForComment,
      formatDateTime,
    };
  },
});
</script>

<template>
  <span v-if="showTooltip" v-tooltip.top="formatDateTime(datetime)">{{
    formatDateForComment(datetime)
  }}</span
  ><span v-else>{{ formatDateForComment(datetime) }}</span>
</template>
