<script setup lang="ts">
import { computed, Ref, ref, UnwrapRef, watch } from 'vue';
import { useRoute } from 'vue-router';
import api from '@/api';
import { NOTIFICATION_GROUPINGS, NOTIFICATION_TYPE } from '@/constants';
import { DgrToggleButton } from '@stockmarkteam/donguri-ui';
import SwrScrollingPagination from '@/components/common/swr-scrolling-pagination.vue';
import Content from '@/components/layouts/content.vue';
import Header from '@/components/layouts/header.vue';
import { NotificationGroupingName, NotificationItem } from '@/types';
import {
  useNotifications,
  useNotificationsUnviewedUnreadCount,
} from '@/utils/swr';
import { useEmitter } from '@/utils/vue';
import CommentReactionCard from './cards/comment-reaction-card.vue';
import FirstPersonalNewsCard from './cards/first-personal-news-card.vue';
import ForceThemeFavoriteCard from './cards/force-theme-favorite-card.vue';
import GroupCommentCard from './cards/group-comment-card.vue';
import GroupInvitationCard from './cards/group-invitation-card.vue';
import GroupMarkCard from './cards/group-mark-card.vue';
import MentionCard from './cards/mention-card.vue';
import ReplyCommentCard from './cards/reply-comment-card.vue';
import ThemeInvitationCard from './cards/theme-invitation-card.vue';
import UpdateThemeFeedCard from './cards/update-theme-feed-card.vue';

const props = defineProps<{
  notificationGrouping: NotificationGroupingName;
}>();

const route = useRoute();

const { data: unviewedUnreadCountData, mutate: mutateUnviewedUnreadCount } =
  useNotificationsUnviewedUnreadCount(NOTIFICATION_GROUPINGS);

const getUnviewedCount = (grouping: NotificationGroupingName) =>
  unviewedUnreadCountData.value?.counts[grouping].unviewed_count ?? 0;

const tabs = computed(() => {
  const getHasNotification = (grouping: NotificationGroupingName) =>
    getUnviewedCount(grouping) > 0 && props.notificationGrouping !== grouping;

  return [
    {
      name: 'メンション',
      linkTo: {
        name: 'mentionsNotifications',
      },
      hasNotification: getHasNotification('mentions'),
    },
    {
      name: 'メンバーの動き',
      linkTo: {
        name: 'membersNotifications',
      },
      hasNotification: getHasNotification('members'),
    },
    {
      name: 'その他',
      linkTo: {
        name: 'othersNotifications',
      },
      hasNotification: getHasNotification('others'),
    },
  ];
});

const shouldSeparateUnreadState = (
  grouping: NotificationGroupingName | undefined,
) => grouping === 'mentions';
const isShowingUnreadState = computed(() =>
  shouldSeparateUnreadState(props.notificationGrouping),
);

const unviewedCount = ref(0);
const unviewedAndUnreadCount = ref(0);
const isLoadingUnviewedUnreadCount = ref(false);

const currentUnviewedCount = computed(() =>
  unreadOnly.value ? unviewedAndUnreadCount.value : unviewedCount.value,
);

watch(
  () => props.notificationGrouping,
  async newGrouping => {
    isLoadingUnviewedUnreadCount.value = true;
    await mutateUnviewedUnreadCount();
    unviewedCount.value = unviewedAndUnreadCount.value =
      getUnviewedCount(newGrouping);
    isLoadingUnviewedUnreadCount.value = false;

    if (unviewedCount.value === 0) return;

    const notificationGrouping = NOTIFICATION_GROUPINGS[newGrouping];
    const promises = [api.viewNotifications(notificationGrouping)];
    if (!shouldSeparateUnreadState(newGrouping)) {
      promises.push(api.readNotifications(notificationGrouping));
    }
    await Promise.all(promises);
    mutateUnviewedUnreadCount();
  },
  { immediate: true },
);

const unreadOnly = ref(false);

const swrScrollingPaginationKey = computed(() => {
  return `${props.notificationGrouping}-${unreadOnly.value ? 'unread' : 'all'}`;
});

const pageLimit = 10;

const paginationFunc = computed(() => {
  return (pageRef: Ref<number>, pageLimit: number) => {
    const groupingName = tabs.value.find(
      tab => tab.linkTo.name === route.name,
    )?.name;

    return useNotifications(
      pageRef,
      pageLimit,
      NOTIFICATION_GROUPINGS[props.notificationGrouping],
      isShowingUnreadState.value ? unreadOnly.value : false,
      {
        page: {
          name: 'お知らせ/' + (groupingName ?? ''),
          url: route.fullPath,
        },
      },
    );
  };
});

const dataAccessor = (
  data: UnwrapRef<ReturnType<typeof useNotifications>['data']>,
) => data?.notifications;

const emitter = useEmitter();

const hasUnreadItems = computed(
  () =>
    (unviewedUnreadCountData.value?.counts[props.notificationGrouping]
      .unread_count ?? 0) > 0,
);

const isUnreadNotification = (notification: NotificationItem) =>
  isShowingUnreadState.value && !notification.read_at;

const read = async (notification: NotificationItem) => {
  api.trackEvent(
    'notification_view',
    {
      pageName: 'notification',
      pageUrl: route.fullPath,
    },
    undefined,
    undefined,
    {
      notification: {
        id: notification.id,
        type: notification.type,
      },
    },
  );

  if (!isUnreadNotification(notification)) {
    return;
  }

  const id = notification.id;
  await api.readNotification(id);
  emitter.emit('pagination-items-update', {
    filterFunc: (item: NotificationItem) => item.id === id,
    updateFunc: (items: NotificationItem[]) =>
      items.forEach(item => (item.read_at = String(new Date()))),
  });
  mutateUnviewedUnreadCount();

  // 通知カードをミドルクリックで既読にしたとき用の処理
  unviewedAndUnreadCount.value -= 1;
};

const readAll = async () => {
  api.trackEvent('notifications_all_read', {
    pageName: 'notification',
  });

  await api.readNotifications(
    NOTIFICATION_GROUPINGS[props.notificationGrouping],
  );
  emitter.emit('pagination-items-update', {
    filterFunc: (_item: NotificationItem) => true,
    updateFunc: (items: NotificationItem[]) =>
      items.forEach(item => (item.read_at = String(new Date()))),
  });
  mutateUnviewedUnreadCount();

  unviewedAndUnreadCount.value = 0;
};

const emptyMessage = computed(() => {
  const currentGrouping = props.notificationGrouping;
  switch (currentGrouping) {
    case 'mentions':
      return 'あなた宛のメンションはまだありません。';
    case 'members':
      return 'メンバーの動きはまだありません。';
    case 'others':
      return 'その他のお知らせはまだありません。';
    default:
      {
        const _exhaustiveCheck: never = currentGrouping;
      }
      return '';
  }
});

const aggregateCommentReaction = (items: NotificationItem[]) => {
  const commentIdsWithCommentReactions = new Set<number>();
  const result: NotificationItem[] = [];

  items.forEach(item => {
    if (item.type === NOTIFICATION_TYPE.COMMENT_REACTION && item.comment) {
      if (commentIdsWithCommentReactions.has(item.comment.id)) {
        return;
      }
      commentIdsWithCommentReactions.add(item.comment.id);
    }
    result.push(item);
  });

  return result;
};
</script>

<template>
  <div class="o-notifications">
    <Header title="お知らせ" :tabs="tabs"></Header>
    <Content>
      <!--
        :keyにgroupingとunreadOnlyを含むキーを指定することで、
        それぞれのケースごとに、別のSwrScrollingPaginationの
        インスタンスが作成されるようにする
      -->
      <SwrScrollingPagination
        ref="pagination"
        :key="swrScrollingPaginationKey"
        :page-limit="pageLimit"
        :pagination-func="paginationFunc"
        :data-accessor="dataAccessor"
      >
        <template v-slot="{ items, loaded }">
          <div v-if="isShowingUnreadState" class="o-controller">
            <DgrToggleButton v-model="unreadOnly" class="c-text c-text--s">
              未読のみ表示する
            </DgrToggleButton>
            <button
              class="read-all c-outlineBtn c-btn--auto c-btn--small"
              type="button"
              :disabled="!loaded || items.length === 0 || !hasUnreadItems"
              @click="readAll"
            >
              全て既読にする
            </button>
          </div>

          <div
            v-if="isShowingUnreadState"
            class="new-arrival c-title c-title--m"
          >
            <template v-if="currentUnviewedCount > 0">
              新着<span>・{{ currentUnviewedCount }}件</span>
            </template>
            <template v-else-if="!isLoadingUnviewedUnreadCount">
              今までのメンション
            </template>
            <!-- layout shift防止 -->
            <template v-else> &nbsp; </template>
          </div>

          <div class="o-notification-timeline" v-if="loaded && items.length">
            <TransitionGroup name="notifications">
              <template
                v-for="(notification, index) in aggregateCommentReaction(items)"
                :key="notification.id"
              >
                <div
                  v-if="
                    isShowingUnreadState &&
                    currentUnviewedCount > 0 &&
                    index === currentUnviewedCount
                  "
                  class="existing-mentions c-title c-title--m"
                >
                  今までのメンション
                </div>

                <MentionCard
                  v-if="notification.type === NOTIFICATION_TYPE.MENTION"
                  :notification="notification"
                  :is-unread="isUnreadNotification(notification)"
                  @click="read(notification)"
                />

                <GroupInvitationCard
                  v-if="
                    notification.type === NOTIFICATION_TYPE.GROUP_INVITATION
                  "
                  :notification="notification"
                  :is-unread="isUnreadNotification(notification)"
                  @click="read(notification)"
                />

                <ReplyCommentCard
                  v-if="notification.type === NOTIFICATION_TYPE.REPLY_COMMENT"
                  :notification="notification"
                  :is-unread="isUnreadNotification(notification)"
                  @click="read(notification)"
                />

                <CommentReactionCard
                  v-if="
                    notification.type === NOTIFICATION_TYPE.COMMENT_REACTION
                  "
                  :notification="notification"
                  :is-unread="isUnreadNotification(notification)"
                  @click="read(notification)"
                />

                <ThemeInvitationCard
                  v-if="
                    notification.type === NOTIFICATION_TYPE.THEME_INVITATION
                  "
                  :notification="notification"
                  :is-unread="isUnreadNotification(notification)"
                  @click="read(notification)"
                />

                <FirstPersonalNewsCard
                  v-if="
                    notification.type === NOTIFICATION_TYPE.FIRST_PERSONAL_NEWS
                  "
                  :notification="notification"
                  :is-unread="isUnreadNotification(notification)"
                  @click="read(notification)"
                />

                <UpdateThemeFeedCard
                  v-if="
                    notification.type === NOTIFICATION_TYPE.UPDATE_THEME_FEED
                  "
                  :notification="notification"
                  :is-unread="isUnreadNotification(notification)"
                  @click="read(notification)"
                />

                <ForceThemeFavoriteCard
                  v-if="
                    notification.type === NOTIFICATION_TYPE.FORCE_THEME_FAVORITE
                  "
                  :notification="notification"
                  :is-unread="isUnreadNotification(notification)"
                  @click="read(notification)"
                />

                <GroupMarkCard
                  v-if="notification.type === NOTIFICATION_TYPE.GROUP_MARK"
                  :notification="notification"
                  :is-unread="isUnreadNotification(notification)"
                  @click="read(notification)"
                />

                <GroupCommentCard
                  v-if="notification.type === NOTIFICATION_TYPE.GROUP_COMMENT"
                  :notification="notification"
                  :is-unread="isUnreadNotification(notification)"
                  @click="read(notification)"
                />
              </template>
            </TransitionGroup>
          </div>
          <div
            class="o-empty-notification c-text c-text--s"
            v-if="loaded && items.length == 0"
          >
            {{ emptyMessage }}
          </div>
        </template>
      </SwrScrollingPagination>
    </Content>
  </div>
</template>

<style lang="scss" scoped>
.o-notifications {
  width: 100%;
  margin: -24px 0 0 0;
  .loading {
    display: flex;
    gap: 8px;
  }
  .o-controller {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
    width: 626px;
    margin-bottom: 16px;
  }
  .read-all {
    background: white;
    &:not(:disabled) {
      border: solid 1px $color-green600;
      color: $color-green600;

      @media (any-hover: hover) {
        &:hover {
          background-color: $color-green100;
        }
      }
    }
    &:disabled {
      color: $color-gray600;
      @media (any-hover: hover) {
        &:hover {
          background-color: none;
        }
      }
      cursor: not-allowed;
    }
  }
  .new-arrival {
    color: $color-gray1000;
    margin-bottom: 12px;
    span {
      color: $color-gray600;
    }
  }

  .o-empty-notification {
    width: 616px;
  }
  .o-notification-timeline {
    width: 616px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .existing-mentions {
      color: $color-gray1000;
      margin-top: 28px;
    }
  }
  .o-empty-notification {
    padding: 16px;
    background: #ffffff;
    border: 1px solid #e6e6e6;
    box-sizing: border-box;
    border-radius: 4px;

    .o-empty-title {
      margin-bottom: 4px;
    }
  }

  /* TransitionGroup(notifications)用のスタイル */
  .notifications-move:not(.existing-mentions),
  .notifications-enter-active:not(.existing-mentions) {
    transition: all 0.5s ease;
  }
}
</style>
