<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from 'vue';
import { useRoute } from 'vue-router';
import api from '@/api';
import {
  DgrIcon,
  DgrSelectbox,
  SelectboxItem,
} from '@stockmarkteam/donguri-ui';
import Loading from '@/components/common/loading.vue';
import WindowVirtualScroll from '@/components/common/window-virtual-scroll.vue';
import Header from '@/components/layouts/header.vue';
import ThemeSearchCard, {
  UpdateThemeSearchPayload,
} from '@/components/theme/theme-search-card.vue';
import {
  Group,
  ThemeList,
  ThemeSearch,
  TrackingThemeSearchParams,
} from '@/types';
import { useUserInfo } from '@/utils/swr';
import { userSession } from '@/utils/userSession';
import { useEmitter, useStore } from '@/utils/vue';

type StringSortKey = 'created_at' | 'name';
type NumberSortKey = 'member_count';
type SortOrder = 'asc' | 'desc';

type SortKey = `${NumberSortKey | StringSortKey}_${SortOrder}`;
type FilterKey = 'all' | 'added' | 'not_added' | 'joined' | 'not_joined';

export default defineComponent({
  components: {
    ThemeSearchCard,
    Header,
    Loading,
    WindowVirtualScroll,
    DgrIcon,
    DgrSelectbox,
  },
  setup() {
    const store = useStore();
    const emitter = useEmitter();
    const route = useRoute();
    const query = ref('');
    const isLoading = ref<boolean>(true);
    const sortList: SelectboxItem<SortKey>[] = [
      { value: 'member_count_desc', label: 'メンバー数の多い順' },
      { value: 'created_at_desc', label: '新しい順' },
      { value: 'created_at_asc', label: '古い順' },
      { value: 'name_asc', label: 'テーマ名（昇順）' },
      { value: 'name_desc', label: 'テーマ名（降順）' },
    ];
    const filterList: SelectboxItem<FilterKey>[] = [
      { value: 'all', label: 'すべてのテーマ' },
      { value: 'added', label: '追加済みのテーマ' },
      { value: 'not_added', label: '未追加のテーマ' },
      { value: 'joined', label: '参加グループのテーマ' },
      { value: 'not_joined', label: '未参加グループのテーマ' },
    ];
    const selectedSort = ref(sortList[0]);
    const selectedFilter = ref(filterList[0]);
    const themeSearchList = reactive({ list: [] as ThemeSearch[] });
    const bkupThemeSearchList = reactive({ list: [] as ThemeSearch[] });
    const { data: userInfo } = useUserInfo();
    const isSearched = ref(false);

    const themeSearchListCount = computed(() => themeSearchList.list.length);
    watch(
      () => query.value,
      () => {
        filterThemes();
      },
    );

    onMounted(async () => {
      const resThemeSearch: ThemeList[] = await api.fetchThemeList();
      const myUserId = userSession.getUserId();
      const groups = await api.fetchGroups(myUserId!);
      const memberCounts = await api.getThemeMemberCounts();
      setData(resThemeSearch, groups, memberCounts);
      filterThemes();
      isLoading.value = false;

      await api.trackPageView({
        pageName: 'theme_search',
        pageUrl: route.path,
      });
    });

    const keywordFilter = () => {
      if (query.value === '') return;
      // 初回ロード時にも実行されるので、queryが空の場合（初期状態）は実行しない
      isSearched.value = true;
      const lowerQuery = query.value.toLowerCase();
      themeSearchList.list = themeSearchList.list.filter(
        (themeSearch: ThemeSearch) => {
          return themeSearch.name.toLowerCase().includes(lowerQuery);
        },
      );
    };

    const selectedSortValue = computed({
      get: () => selectedSort.value.value,
      set: value => {
        isSearched.value = true;
        const sortValue = sortList.find(s => s.value === value);
        if (sortValue) {
          selectedSort.value = sortValue;
        }
        filterThemes();
      },
    });

    const sort_as_string = (sortObj: StringSortKey, sortType: SortOrder) => {
      if (sortType === 'asc') {
        themeSearchList.list.sort((x, y) =>
          x[sortObj].localeCompare(y[sortObj], 'ja'),
        );
      } else {
        themeSearchList.list.sort(
          (x, y) => -x[sortObj].localeCompare(y[sortObj], 'ja'),
        );
      }
    };

    const sort_as_number = (sortObj: NumberSortKey, sortType: SortOrder) => {
      if (sortType === 'asc') {
        themeSearchList.list.sort((x, y) => x[sortObj] - y[sortObj]);
      } else {
        themeSearchList.list.sort((x, y) => y[sortObj] - x[sortObj]);
      }
    };

    const selectedFilterValue = computed({
      get: () => selectedFilter.value.value,
      set: value => {
        isSearched.value = true;
        const filterValue = filterList.find(s => s.value === value);
        if (filterValue) {
          selectedFilter.value = filterValue;
        }
        filterThemes();
      },
    });

    const sortThemeSearch = () => {
      switch (selectedSort.value.value) {
        case 'member_count_desc':
          sort_as_number('member_count', 'desc');
          break;

        case 'member_count_asc':
          sort_as_number('member_count', 'asc');
          break;

        case 'created_at_desc':
          sort_as_string('created_at', 'desc');
          break;

        case 'created_at_asc':
          sort_as_string('created_at', 'asc');
          break;

        case 'name_asc':
          sort_as_string('name', 'asc');
          break;

        case 'name_desc':
          sort_as_string('name', 'desc');
          break;

        default:
          break;
      }
    };

    const filterThemeSearch = () => {
      switch (selectedFilter.value.value) {
        case 'added':
          themeSearchList.list = themeSearchList.list.filter(
            (themeSearch: ThemeSearch) => {
              return themeSearch.is_member;
            },
          );
          break;

        case 'not_added':
          themeSearchList.list = themeSearchList.list.filter(
            (themeSearch: ThemeSearch) => {
              return !themeSearch.is_member;
            },
          );
          break;

        case 'joined':
          themeSearchList.list = themeSearchList.list.filter(
            (themeSearch: ThemeSearch) => {
              if (themeSearch.group) {
                return themeSearch.group.is_member;
              }
            },
          );
          break;

        case 'not_joined':
          themeSearchList.list = themeSearchList.list.filter(
            (themeSearch: ThemeSearch) => {
              if (themeSearch.group) {
                return !themeSearch.group.is_member;
              }
            },
          );
          break;

        default:
          break;
      }
    };

    const filterThemes = () => {
      themeSearchList.list = bkupThemeSearchList.list;
      keywordFilter();
      sortThemeSearch();
      filterThemeSearch();

      // 条件（クエリ・ソート・フィルタ）の変更時に動作させる
      addThemeSearchTrackEvent();
    };

    const updateThemeSearch = async (targetTheme: UpdateThemeSearchPayload) => {
      const targetIndex = themeSearchList.list
        .map(targetTheme => targetTheme.id)
        .indexOf(targetTheme.id);
      const theme = themeSearchList.list[targetIndex];

      if (targetTheme.type === 'add') {
        theme.is_member = true;
        if (theme.group && !theme.group.is_member) {
          theme.group.is_member = true;
          await api.joinGroup(theme.group.id);
        }
        await api.addUserTheme(theme.id);
      } else {
        theme.is_member = false;
        await api.deleteUserTheme(theme.id);
      }
      store.commit('themeList/updateTheme', theme);
      emitter.emit('theme-updated', theme);
    };

    const setData = (
      resThemeSearch: ThemeList[],
      groups: Group[],
      themeMemberCounts: Record<string, number>,
    ) => {
      themeSearchList.list = resThemeSearch
        .filter(t => !t.is_deleted)
        .map(themeSearch => {
          const group = groups.find(g => g.id === Number(themeSearch.group_id));
          return {
            ...themeSearch,
            group,
            member_count: themeMemberCounts[String(themeSearch.id)] ?? 0,
          };
        });
      if (userInfo.value?.role !== 'admin') {
        themeSearchList.list = themeSearchList.list.filter(
          t =>
            ['public', 'group'].includes(t.access_scope) ||
            t.user_id === userInfo.value?.id,
        );
      }
      bkupThemeSearchList.list = themeSearchList.list;
    };

    const trackThemeSearchParams = computed<TrackingThemeSearchParams>(() => {
      return {
        query: query.value,
        by_user: isSearched.value,
        sort: selectedSort.value.label,
        filter: selectedFilter.value.label,
        result_count: themeSearchList.list.length,
      };
    });

    const addThemeSearchTrackEvent = async () => {
      // テーマ検索のトラッキング
      await api.trackEvent(
        'search_theme',
        {
          pageName: 'theme_search',
          pageUrl: route.fullPath,
        },
        undefined,
        undefined,
        trackThemeSearchParams.value,
      );
    };
    const estimateSizeForVirtualItems = () => 80; // 仮想スクロール内の各itemの高さ(72px) + item間の間隔(8px)

    return {
      query,
      sortList,
      filterList,
      selectedSort,
      selectedFilter,
      themeSearchList,
      selectedSortValue,
      selectedFilterValue,
      keywordFilter,
      filterThemes,
      updateThemeSearch,
      trackThemeSearchParams,
      pageUrl: route.fullPath,
      isLoading,
      themeSearchListCount,
      estimateSizeForVirtualItems,
    };
  },
});
</script>

<template>
  <div class="o-theme-search">
    <Header title="テーマ検索" :align-left="true"></Header>
    <Loading v-if="isLoading" class="loading" />
    <div class="o-theme-search__content" v-else>
      <div class="o-theme-search__keyword">
        <button class="m-search-button" @click="filterThemes">
          <DgrIcon size="small" name="search" :keep-fill="false" />
        </button>
        <input
          class="c-textInput"
          type="text"
          placeholder="テーマ名で検索"
          v-model="query"
        />
      </div>
      <div class="spacing-16"></div>
      <div class="o-theme-search__select">
        <div class="o-theme-search__counts">
          <div class="c-title c-title--m">
            テーマ<span class="c-text c-text--s count"
              >・{{ themeSearchList.list.length }}件</span
            >
          </div>
        </div>
        <div class="spacing-16"></div>
        <DgrSelectbox
          :options="sortList"
          v-model="selectedSortValue"
          size="small"
        ></DgrSelectbox>
        <div class="spacing-16"></div>
        <DgrSelectbox
          :options="filterList"
          v-model="selectedFilterValue"
          size="small"
        >
        </DgrSelectbox>
        <div class="spacing-16"></div>
      </div>
      <div class="spacing-16"></div>
      <WindowVirtualScroll
        :is-data-loaded="!isLoading"
        :count="themeSearchListCount"
        :overscan="10"
        :estimate-size="estimateSizeForVirtualItems"
      >
        <template #default="{ index }">
          <ThemeSearchCard
            :theme-search="themeSearchList.list[index]"
            :track-theme-search-params="trackThemeSearchParams"
            :theme-search-index="index"
            :page-url="pageUrl"
            @update-theme-search="updateThemeSearch"
          ></ThemeSearchCard>
        </template>
      </WindowVirtualScroll>
      <div
        class="o-theme-search__no-search-result"
        v-if="themeSearchList.list.length === 0"
      >
        <div class="c-text c-text--m">該当するテーマはありません</div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.o-theme-search {
  width: 100%;
  margin: -24px 0 0 0;
  .o-theme-search__content {
    padding: 24px;
  }
}
.o-theme-search__keyword {
  display: flex;
  align-items: center;
  .c-textInput {
    padding-left: 36px;
    outline: none;
    margin-left: -36px;
  }
  .m-search-button {
    position: relative;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    height: 32px;
    border: 0;
    background: #fff;
    &:disabled {
      cursor: default;
    }
    &:hover:not(:disabled) {
      background: #f2f2f2;
    }
    .icon-box {
      padding: 0;
      fill: #b3b3b3;
    }
  }
}
.o-theme-search__select {
  display: flex;
  align-items: center;
  .o-theme-search__counts {
    .count {
      color: #b3b3b3;
    }
  }
}
.o-theme-search__no-search-result {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.loading {
  // 画面の高さ - ヘッダーの高さ
  height: calc(100vh - 65px);
}
</style>
