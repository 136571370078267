<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  reactive,
  ref,
  watch,
} from 'vue';
import api from '@/api';
import { DgrIcon } from '@stockmarkteam/donguri-ui';
import AdpDocumentCardSkeleton from '@/components/common/adp-document/adp-document-card-skeleton.vue';
import TextTabItem from '@/components/common/molecules/text-tab-item.vue';
import ToggleSwitch, {
  ToggleSwitchItem,
} from '@/components/common/molecules/toggle-switch.vue';
import { DocType, Site, ThemePreview, ThemePreviewItem } from '@/types';
import { docTypeDisplayNameMap } from '@/utils/adpDocument';
import { formatDayOfWeek } from '@/utils/formatters';
import { formatDate } from '@/utils/formatters';
import {
  ALLIANCE_MEDIA_VALUE,
  getIncludeAllianceMediaValueForSearch,
  getSiteCategoriesValueForSearch,
  SearchSiteCategory,
} from '@/utils/siteCategories';
import { useUserSettingsNews } from '@/utils/swr';
import ThemePreviewAdpDocument from './theme-preview-adp-document.vue';

export default defineComponent({
  props: {
    keywords: { type: Array as PropType<string[]>, default: () => [] },
    keywordErrors: { type: Array as PropType<string[]>, default: () => [] },
    isForeignFeedAvailable: { type: Boolean, default: false },
    isForeignMediaEnabled: { type: Boolean, default: false },
    docTypes: { type: Array as PropType<DocType[]>, default: () => [] },
    isTechnicalLiteratureAvailable: { type: Boolean, default: false },
    isEntertainmentFilterEnabled: { type: Boolean, default: false },
    excludedSites: { type: Array as PropType<Site[]>, default: () => [] },
    siteCategories: {
      type: Array as PropType<SearchSiteCategory[]>,
      default: () => [],
    },
    isAllianceMediaEnabled: { type: Boolean, default: false },
  },
  components: {
    ThemePreviewAdpDocument,
    AdpDocumentCardSkeleton,
    ToggleSwitch,
    TextTabItem,
    DgrIcon,
  },
  setup(props, context) {
    const resThemePreview = reactive({ list: [] as ThemePreview[] });
    const hitCount = ref(0);
    const hasKeywords = ref(false);
    const isHit = ref(true);
    const isFetched = ref(false);
    const isNoSiteCategoriesAndExcludeAllianceMedia = ref(false);
    const { data: userSettings } = useUserSettingsNews();

    const docType = ref<DocType>('article');

    onMounted(() => {
      setPreviewDocuments();
    });

    watch(
      () => props.isEntertainmentFilterEnabled,
      () => {
        setTimeout(() => {
          setPreviewDocuments();
        }, 250);
      },
    );

    watch(
      () => props.excludedSites,
      () => {
        setTimeout(() => {
          setPreviewDocuments();
        }, 250);
      },
      { deep: true },
    );

    watch(
      () => props.keywords,
      () => {
        setTimeout(() => {
          setPreviewDocuments();
        }, 250);
      },
      { deep: true },
    );

    watch(
      () => props.siteCategories,
      () => {
        setTimeout(() => {
          setPreviewDocuments();
        }, 250);
      },
    );

    watch(docType, () => setPreviewDocuments());

    const enableTranslatedDocumentTitle = computed(
      () => userSettings.value?.enable_translate_article_title ?? false,
    );

    const setPreviewDocuments = async () => {
      isFetched.value = false;
      const keywords = props.keywords.filter(keyword => keyword !== '');
      // 「表示するサイト種別」の項目が1つも選択されていない場合
      if (
        !props.siteCategories.length ||
        (!props.isAllianceMediaEnabled &&
          props.siteCategories.length === 1 &&
          props.siteCategories[0] === ALLIANCE_MEDIA_VALUE)
      ) {
        resThemePreview.list = [];
        hitCount.value = 0;
        isNoSiteCategoriesAndExcludeAllianceMedia.value = true;
        isHit.value = false;
        isFetched.value = true;
        return;
      } else if (
        props.keywords.length === 0 ||
        (props.keywords.length === 1 && props.keywords[0] === '')
      ) {
        resThemePreview.list = [];
        hitCount.value = 0;
        hasKeywords.value = false;
      } else if (props.keywordErrors.length > 0) {
        resThemePreview.list = [];
        hitCount.value = 0;
      } else {
        const searchResult = await api.getThemePreviewDocuments(
          docType.value,
          keywords,
          50,
          feedType.value,
          props.isEntertainmentFilterEnabled,
          props.excludedSites.map(i => i.id),
          getSiteCategoriesValueForSearch(props.siteCategories),
          props.isAllianceMediaEnabled &&
            getIncludeAllianceMediaValueForSearch(props.siteCategories),
        );

        if (searchResult.hasError && searchResult.failedKeywords) {
          context.emit('syntaxErrorKeywords', searchResult.failedKeywords); // eslint-disable-line vue/require-explicit-emits
          resThemePreview.list = [];
          hitCount.value = 0;
        } else if (searchResult.hasError) {
          context.emit('syntaxErrorKeywords', []); // eslint-disable-line vue/require-explicit-emits
          resThemePreview.list = [];
          hitCount.value = 0;
        } else {
          context.emit('syntaxErrorKeywords', []); // eslint-disable-line vue/require-explicit-emits
          resThemePreview.list = searchResult.articles;
          hitCount.value = searchResult.count ?? 0;
        }

        hasKeywords.value = true;
      }
      isHit.value = resThemePreview.list.length > 0;
      isFetched.value = true;
      isNoSiteCategoriesAndExcludeAllianceMedia.value = false;
    };

    const dateList = computed(() => {
      const publishedAtDuplicateList = resThemePreview.list.map(doc =>
        formatDate(doc['published_at']),
      );
      const publishedAtList = [...new Set(publishedAtDuplicateList)];
      return publishedAtList;
    });

    const themePreviewDocumentList = computed(() => {
      const previewdocumentList: ThemePreviewItem[] = [];
      dateList.value.forEach(date => {
        const documentList = resThemePreview.list.filter(
          adpDocument => formatDate(adpDocument.published_at) === date,
        );
        previewdocumentList.push({ date: date, adpDocuments: documentList });
      });
      return previewdocumentList;
    });

    const feedType = ref<'ja' | 'en'>('ja');
    const feedTypeToActiveOption = (feedType: 'ja' | 'en') =>
      feedType === 'ja' ? 'left' : 'right';
    const toggleSwitchProp = ref<ToggleSwitchItem>({
      leftLabelName: '国内メディア',
      rightLabelName: '海外メディア',
      activeOption: feedTypeToActiveOption(feedType.value),
    });

    const toggleFeedType = async () => {
      feedType.value = feedType.value === 'ja' ? 'en' : 'ja';
      toggleSwitchProp.value.activeOption = feedTypeToActiveOption(
        feedType.value,
      );
      setPreviewDocuments();
    };

    const allDocTypes: DocType[] = ['article', 'research_paper', 'patent'];

    const docTypeDisplayNameMapForThemePreview = {
      ...docTypeDisplayNameMap,
      article: 'ニュース',
    };

    return {
      resThemePreview,
      hitCount,
      setPreviewDocuments,
      dateList,
      themePreviewDocumentList,
      isHit,
      isFetched,
      hasKeywords,
      feedType,
      toggleSwitchProp,
      toggleFeedType,
      formatDayOfWeek,
      isNoSiteCategoriesAndExcludeAllianceMedia,
      enableTranslatedDocumentTitle,
      docType,
      allDocTypes,
      docTypeDisplayNameMapForThemePreview,
    };
  },
});
</script>

<template>
  <div
    class="o-theme-preview__wrapper"
    :class="{
      'no-hit': !isHit,
      loading: !isFetched,
    }"
  >
    <div class="title c-title c-title--m">
      プレビュー
      <DgrIcon
        size="small"
        name="question-circle"
        v-tooltip.top="{
          content: 'テーマ設定の参考として表示しています。',
          theme: 'menu',
        }"
      />
      <span v-if="hitCount > 0" class="theme-preview-hit-count c-text c-text--m"
        >{{ hitCount.toLocaleString() }}件</span
      >
    </div>
    <ToggleSwitch
      class="m-feed-switch"
      v-if="isForeignFeedAvailable"
      :toggle-switch-item="toggleSwitchProp"
      @toggle-switch="toggleFeedType"
    ></ToggleSwitch>
    <div v-if="isTechnicalLiteratureAvailable" class="tab-list">
      <div
        v-for="docTypeTab in allDocTypes"
        :key="docTypeTab"
        :style="{ 'margin-top': '-4px' }"
      >
        <TextTabItem
          :text="docTypeDisplayNameMapForThemePreview[docTypeTab]"
          :is-selected="docTypeTab === docType"
          @click-tab="() => (docType = docTypeTab)"
        ></TextTabItem>
      </div>
    </div>
    <div
      v-if="
        docType === 'patent' &&
        isForeignFeedAvailable &&
        isForeignMediaEnabled &&
        feedType === 'en'
      "
      class="o-theme-preview__nohit c-text c-text--m"
    >
      海外特許の配信は行っておりません。
    </div>
    <div
      v-else-if="docType !== 'article' && !docTypes.includes(docType)"
      class="o-theme-preview__nohit c-text c-text--m"
    >
      「表示する技術文献」の{{
        docTypeDisplayNameMapForThemePreview[docType]
      }}にチェックが入っていません。チェックを入れることで参考記事を閲覧することができます。
    </div>
    <div class="o-theme-preview" v-else-if="isFetched">
      <div
        class="o-theme-preview__nohit"
        v-if="
          isForeignFeedAvailable && feedType === 'en' && !isForeignMediaEnabled
        "
      >
        <div class="c-text c-text--m">
          「海外メディアの情報を収集する」にチェックが入っていません。チェックを入れることで参考記事を閲覧することができます。
        </div>
      </div>
      <div class="o-theme-preview__hit" v-else-if="isHit">
        <div
          class="o-theme-preview__content"
          v-for="preview in themePreviewDocumentList"
          :key="preview.date"
        >
          <div v-if="docType === 'article'" class="adp-document-date__content">
            <div class="adp-document-date__text c-text c-text--s">
              {{ formatDayOfWeek(preview.date) }}
            </div>
          </div>
          <div
            v-for="adpDocument in preview.adpDocuments"
            :key="adpDocument.url"
            :class="`theme-preview-item ${adpDocument.doc_type}`"
          >
            <ThemePreviewAdpDocument
              :adp-document="adpDocument"
              :display-translated-document-title="
                feedType === 'en' && enableTranslatedDocumentTitle
              "
            ></ThemePreviewAdpDocument>
          </div>
        </div>
      </div>
      <div
        class="o-theme-preview__nohit"
        v-else-if="isNoSiteCategoriesAndExcludeAllianceMedia"
      >
        <div class="c-text c-text--m">
          表示するサイト種別で1つ以上項目を選択してください。
        </div>
      </div>
      <div class="o-theme-preview__nohit" v-else-if="hasKeywords && !isHit">
        <div class="c-text c-text--m">
          設定しているキーワードに一致する情報は見つかりませんでした。
        </div>
      </div>
      <div class="o-theme-preview__nohit" v-else-if="!hasKeywords">
        <div class="c-text c-text--m">キーワードが設定されていません。</div>
      </div>
    </div>
    <AdpDocumentCardSkeleton
      class="adp-document-card-skelton"
      v-else
      :loading="!isFetched"
    ></AdpDocumentCardSkeleton>
  </div>
</template>

<style lang="scss" scoped>
.o-theme-preview__wrapper {
  width: 440px;
  height: 70vh;
  padding: 16px;
  background-color: white;
  overflow-y: scroll;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 4px;
  min-width: 440px;
  max-width: 904px;
  flex-grow: 1;
  .m-feed-switch {
    width: 391px;
  }

  .title {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .icon-box {
      margin-left: 4px;
    }

    .theme-preview-hit-count {
      margin-left: 16px;
    }
  }
}
.no-hit {
  height: 180px;
}
.loading {
  height: 220px;
}
.o-theme-preview__content {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  .adp-document-date__content {
    display: flex;
    align-items: center;
    .adp-document-date__text {
      box-shadow: 0px 1px 3px rgba(74, 74, 74, 0.4);
      border-radius: 4px;
      padding: 4px;
      width: 56px;
      text-align: center;
    }
  }
  .adp-document-date__content::before,
  .adp-document-date__content::after {
    content: '';
    flex-grow: 1;
    height: 1px;
    background: #e6e6e6;
  }
}
.theme-preview-item.article {
  margin-top: 8px;
}
.theme-preview-item.article:not(:last-child) {
  border-bottom: 1px solid #e6e6e6;
}
.theme-preview-item.research_paper,
.theme-preview-item.patent {
  padding-top: 8px;
  border-top: 1px solid #e6e6e6;
}
.o-theme-preview__nohit {
  padding-top: 16px;
}
.adp-document-card-skelton {
  height: 128px;
  padding: 0;
}
.tab-list {
  display: flex;
  width: 391px;
  margin: 16px 0;
  border-bottom: 1px solid #e6e6e6;

  .m-text-tab-item {
    width: calc(391px / 3);
  }
}
</style>
