// axiosでのstreamingがうまくいかないのでfetchで実装
import axios from 'axios';
import Qs from 'qs';
import { ChatGptPrompt } from '@/types';
import {
  CompanySolutions,
  IndustrySolutions,
  UserSolutions,
} from '@/utils/labs/reports/types';

export async function* reportStreamChat(prompts: ChatGptPrompt[]) {
  const completion = await fetch('api/v1/labs/report/stream_chat', {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({
      prompts: prompts,
    }),
  });

  const reader = completion.body
    ?.pipeThrough(new TextDecoderStream())
    .getReader();

  if (completion.status !== 200 || !reader) {
    throw new Error('Request failed');
  }

  while (true) {
    const { done, value } = await reader.read();
    if (done) break;
    yield value;
  }

  reader.releaseLock();
}

export async function reportChat(prompts: ChatGptPrompt[]): Promise<string> {
  const response = await axios.post<string>('labs/report/chat', {
    prompts: prompts,
  });
  return response.data;
}

export async function reportChatFunctionCalling(
  prompts: ChatGptPrompt[],
  tools: any[], // eslint-disable-line @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any | null> {
  const response = await axios.post<string>('labs/report/chat_fc', {
    prompts: prompts,
    tools: tools,
  });
  return response.data;
}

// axiosでのstreamingがうまくいかないのでfetchで実装
export async function* reportStmkllmStreamChat(message: string) {
  const completion = await fetch('api/v1/labs/report/stmkllm_stream_chat', {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({
      message: message,
    }),
  });

  const reader = completion.body
    ?.pipeThrough(new TextDecoderStream())
    .getReader();

  if (completion.status !== 200 || !reader) {
    throw new Error('Request failed');
  }

  while (true) {
    const { done, value } = await reader.read();
    if (done) break;
    yield value;
  }

  reader.releaseLock();
}

export async function getUserSolutions(
  langs: string[],
  limit: number,
  company_name: string | null,
): Promise<UserSolutions> {
  const params = {
    langs,
    limit,
    company_name,
  };
  const query = Qs.stringify(params, {
    arrayFormat: 'brackets',
  });
  const response = await axios.get<UserSolutions>(
    `labs/report/user_solutions?${query}`,
  );
  return response.data;
}

export async function getKeywordSolutionSearch(
  keyword: string,
  langs: string[],
  limit: number,
  groupby: string,
  fromDate?: string,
  toDate?: string,
): Promise<IndustrySolutions[] | CompanySolutions[]> {
  const params = {
    keyword: keyword,
    langs: langs,
    limit: limit,
  };
  const query = Qs.stringify(params, {
    arrayFormat: 'brackets',
  });
  let uri = `labs/report/keyword_solution_search?${query}`;
  if (groupby !== '') {
    uri += `&groupby=${groupby}`;
  }
  if (fromDate) {
    uri += `&from_date=${fromDate}`;
  }
  if (toDate) {
    uri += `&to_date=${toDate}`;
  }
  const response = await axios.get<IndustrySolutions[] | CompanySolutions[]>(
    uri,
  );
  return response.data;
}
