import { getDocumentsCount } from '@/api/document';
import { searchCache } from '@/apiCache';
import { SEARCH_EXPANDED_QUERY_CACHE_STORE_NAME } from '@/constants';
import axios from 'axios';
import Qs from 'qs';
import {
  Article,
  DocType,
  NewsCategoryWithCount,
  OnlyTrackingParam,
  SearchRequestParam,
  SearchResultResponse,
  ThemePreview,
} from '@/types';
import { searchExpandedQueryCache } from '@/utils/searchExpandedQueryCache';
import { SiteCategory } from '@/utils/siteCategories';
import {
  getEventDataForSearchAPI,
  trackingSearchEvent,
} from '@/utils/trackingHelper';

export async function search(
  searchParams: SearchRequestParam & OnlyTrackingParam,
  isLabs: boolean = false, // Labsの検索では計測をしない
): Promise<{
  result_count: number;
  search_tracking_session_id: string;
  expanded_queries: string[];
  articles: Article[];
  exceed_max_result_window: boolean | null;
  news_categories: NewsCategoryWithCount[];
}> {
  let data: SearchResultResponse;
  const queryStr = Qs.stringify(searchParams, { arrayFormat: 'brackets' });
  const url = `/search?${queryStr}`;

  // 同じ検索結果でも初回以降の検索ではcached_expanded_queriesが新たに追加されるため、
  // searchCacheはcached_expanded_queriesを除いたパラメータをキーとしてキャッシュする
  // また計測に関するパラメータは除外する
  const {
    cached_expanded_queries: _c,
    feature: _f,
    request_id: _r,
    search_query: _s,
    rank: _ra,
    ...searchCacheParams
  } = searchParams;
  const searchCacheKey = JSON.stringify(searchCacheParams);
  const cache = searchCache.getCache(searchCacheKey);

  if (cache) {
    data = cache;
  } else {
    data = (await axios.get<SearchResultResponse>(url)).data;

    if (
      !searchParams.cached_expanded_queries &&
      searchParams.query &&
      data.expanded_queries &&
      data.expanded_queries.length > 0
    ) {
      searchExpandedQueryCache.setCache(
        searchParams.query,
        data.expanded_queries,
      );
      searchParams.cached_expanded_queries = data.expanded_queries;
    }

    if (data.search_tracking_session_id)
      searchCache.setCache(searchCacheKey, data);
  }

  if (!isLabs) {
    // 計測
    await trackingSearchEvent(
      'search',
      getEventDataForSearchAPI(
        searchParams,
        data.search_articles,
        data.search_result_count,
        data.search_tracking_session_id,
      ),
    );
  }

  return {
    result_count: data.search_result_count,
    search_tracking_session_id: data.search_tracking_session_id,
    expanded_queries: data.expanded_queries,
    articles: data.search_articles,
    exceed_max_result_window: data.exceed_max_result_window,
    news_categories: data.news_categories,
  };
}

export async function allOpenDocumentsCount(
  query: string,
  docTypes?: DocType[],
): Promise<number> {
  return getDocumentsCount(
    query,
    '/search/all_open_documents_count',
    SEARCH_EXPANDED_QUERY_CACHE_STORE_NAME,
    docTypes,
  );
}

export async function searchPastArticles({
  keywords,
  lang,
  to_date,
  sort,
  entertainment_filter,
  page,
  limit,
  offset,
  exclude_site_ids,
  site_categories,
  include_alliance_media,
}: {
  keywords: string[];
  lang?: 'ja' | 'en';
  to_date?: string;
  sort?: string;
  entertainment_filter?: boolean;
  page?: number;
  limit?: number;
  offset?: number;
  exclude_site_ids?: number[];
  site_categories?: SiteCategory[];
  include_alliance_media?: boolean;
}): Promise<{ result_count: number; articles: Article[] }> {
  const { data } = await axios.get<SearchResultResponse>(
    `/search/past_articles`,
    {
      params: {
        keywords,
        lang,
        to_date,
        sort,
        page,
        limit,
        entertainment_filter,
        offset,
        exclude_site_ids,
        site_categories,
        include_alliance_media,
      },
    },
  );
  return {
    result_count: data.search_result_count,
    articles: data.search_articles,
  };
}

export async function getThemePreviewDocuments(
  docType: DocType,
  keywords: string[],
  limit: number,
  lang: 'ja' | 'en' = 'ja',
  entertainment_filter = false,
  site_exclusion_ids: number[] = [],
  site_categories: SiteCategory[] = [],
  include_alliance_media: boolean = false,
): Promise<{
  hasError: boolean;
  articles: ThemePreview[];
  count: number;
  failedKeywords: string[];
}> {
  return await axios
    .post<SearchResultResponse>(`/search/theme_preview`, {
      doc_types: [docType],
      keywords: keywords,
      limit: limit,
      lang: lang,
      entertainment_filter: entertainment_filter,
      site_exclusion_ids: site_exclusion_ids,
      site_categories: docType === 'article' ? site_categories : undefined,
      include_alliance_media:
        docType === 'article' ? include_alliance_media : undefined,
    })
    .then(response => {
      return {
        hasError: false,
        articles: response.data.search_articles,
        count: response.data.search_result_count,
        failedKeywords: [],
      };
    })
    .catch(error => {
      return {
        hasError: true,
        articles: [],
        count: 0,
        failedKeywords: error.response?.data.failed_keywords ?? [],
      };
    });
}
