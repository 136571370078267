<script lang="ts">
import { computed, defineComponent } from 'vue';

export default defineComponent({
  props: {
    rank: { type: Number },
  },
  setup(props) {
    const color = computed(() => {
      switch (props.rank) {
        case 1:
          return '#e95532';
        case 2:
          return '#F5AC33';
        case 3:
          return '#1DA482';
        default:
          return '#B3B3B3';
      }
    });
    return { color };
  },
});
</script>

<template>
  <div class="rank-badge c-title c-title--xs" :style="{ background: color }">
    {{ rank }}
  </div>
</template>

<style scoped lang="scss">
.rank-badge {
  color: #ffffff;
  border-radius: 8px;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
}
</style>
