import { PAGE_NUMBER_PLACEHOLDER } from '@/constants';
import { UserDocumentEntity } from '@/types';
import { formatDate } from '@/utils/formatters';

export const getPageUrl = (page: number, doc: UserDocumentEntity) => {
  const url = doc.url;
  const id = doc.id;
  if (url.includes('box.com'))
    return `${url}#p=${page}`; // Box
  else if (url.includes('sharepoint.com'))
    return `${url}#page=${page}`; // SharePoint
  else if (url === '')
    return `${window.location.origin}${window.location.pathname}#/user_documents/${id}?page=${page}`; // AnewsStorage
  return url;
};

export const getIsPageNumberAvailable = (doc: UserDocumentEntity) => {
  return doc.file_type !== 'Word' || doc.url === '';
};

export const getFileTypeIcon = (doc: UserDocumentEntity) => {
  switch (doc.file_type) {
    case 'PDF':
      return 'file-pdf-fill-color';
    case 'Word':
      return 'file-word-fill-color';
    case 'PowerPoint':
      return 'file-powerpoint-fill-color';
    default:
      return 'file-v';
  }
};

export const getFolderName = (doc: UserDocumentEntity) => {
  const path = doc.path;
  return path.split('/').slice(-1)[0];
};

export const getDocumentUrl = (doc: UserDocumentEntity) => {
  if (!getIsPageNumberAvailable(doc)) return doc.url;
  const pageNumber = doc.page_number;
  return getPageUrl(pageNumber ?? 1, doc);
};

export const getModifiedAt = (doc: UserDocumentEntity) => {
  return doc.modified_at ? formatDate(doc.modified_at) : undefined;
};

export const getPreviewImageUrls = (
  file_total_pages: number | undefined,
  preview_image_path_template: string,
) => {
  return [...Array(file_total_pages ?? 0)].map((_, i) =>
    preview_image_path_template.replace(
      PAGE_NUMBER_PLACEHOLDER,
      (i + 1).toString(),
    ),
  );
};
