<script setup lang="ts">
import { ref } from 'vue';
import api from '@/api';
import { DgrIcon } from '@stockmarkteam/donguri-ui';
import Avatar from '@/components/common/atoms/avatar.vue';
import ActionBalloon from '@/components/common/molecules/balloon.vue';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import {
  useOrganizationTagDetail,
  useOrganizationTagJoinableUsers,
} from '@/utils/swr';

const { createSnackbar } = useSnackbar();

const props = defineProps({
  /** 表示対象のユーザ情報 */
  user: {
    type: Object,
    required: true,
  },
  /** 管理者権限を持つユーザか */
  isAdminUser: {
    type: Boolean,
    required: true,
  },
  organizationTagId: {
    type: Number,
    required: true,
  },
});

/** 操作ポップアップの表示フラグ */
const isOpenPopup = ref(false);

const { mutate: joinableUsersMutate } = useOrganizationTagJoinableUsers(
  props.organizationTagId,
);

const { mutate } = useOrganizationTagDetail(props.organizationTagId);

/** 処理中フラグ */
const isSubmitting = ref(false);
/**
 * ユーザを組織タグから削除する
 */
const handleRemoveUser = async (userId: number) => {
  isSubmitting.value = true;
  const result = await api.deleteOrganizationTagUser({
    organizationTagId: props.organizationTagId,
    userId,
  });

  if (result.isSuccess) {
    createSnackbar({
      message: 'メンバーを解除しました',
      type: 'success',
    });
    joinableUsersMutate();
    mutate();
  } else {
    createSnackbar({
      message: result.message,
      type: 'error',
    });
  }

  isSubmitting.value = false;
};
</script>
<template>
  <div class="user-info-container">
    <div>
      <router-link :to="`/users/${user.id}`">
        <Avatar :image-url="user.image_url" size="xs"></Avatar>
      </router-link>
    </div>
    <div class="user-text-container">
      <div class="name-container">
        <router-link
          :to="`/users/${user.id}`"
          class="c-text--m name"
          data-testid="user-name"
        >
          {{ user.user_name }}
        </router-link>
      </div>

      <div class="email-container">
        <p class="c-text--xs email" data-testid="user-email">
          {{ user.email }}
        </p>
      </div>
    </div>
  </div>
  <div class="m-action-menu" v-if="isAdminUser">
    <button
      ref="actionMenuRef"
      class="action-menu-button"
      :class="{ opened: isOpenPopup }"
      data-testid="member-table-action-menu"
      @click="isOpenPopup = true"
      @keydown.esc="isOpenPopup = false"
    >
      <DgrIcon name="ellipsis-h" />
    </button>
    <ActionBalloon
      :is-show="isOpenPopup"
      :balloon-position="`left bottom`"
      :position="`fixed`"
      @on-click-outside="() => (isOpenPopup = false)"
    >
      <div class="remove-button-container">
        <button
          type="button"
          class="text-button"
          @click="handleRemoveUser(user.id)"
          data-testid="remove-button"
        >
          解除
        </button>
      </div>
    </ActionBalloon>
  </div>
</template>
<style lang="scss" scoped>
.name:hover {
  text-decoration: underline;
}

.user-info-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  width: 85%;
}

.email-container {
  overflow-wrap: break-word;
}
.email {
  color: $color-text-secondary;
}

.user-text-container {
  overflow: hidden;
}

.name-container {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.m-action-menu {
  position: relative;
  width: 40px;
  height: 40px;
}
.action-menu-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 0;
  border: none;
  box-sizing: border-box;
  background-color: inherit;
  border-radius: 4px;
  &:hover {
    background-color: $color-gray200;
  }
  &.opened {
    background-color: $color-gray200;
  }
}
.text-button {
  word-wrap: nowrap;
  white-space: nowrap;
  background: inherit;
  border: none;
  border-radius: 0;
  display: inline-block;
  text-align: center;
  width: 100%;
  text-align: left;
  font-size: 14px;
  line-height: 1.5;
  height: auto;
  &:hover {
    border: 0px;
    background: $color-gray200;
  }
}
</style>
