<script setup lang="ts">
import { computed, onMounted, onUnmounted } from 'vue';
import { useRoute } from 'vue-router';
import api from '@/api';
import PersonalNewsCountdown from '@/components/common/personal_news/personal-news-countdown.vue';
import Content from '@/components/layouts/content.vue';
import Header from '@/components/layouts/header.vue';
import ArticlesWeek from '@/components/personal-news/articles-week.vue';
import {
  getTabs,
  PERSONAL_NEWS_AFTERNOON_UPDATE_HOUR,
  PERSONAL_NEWS_MORNING_UPDATE_HOUR,
} from '@/utils/personalNews';
import {
  useTeamInfo,
  useUserInfo,
  useUserPersonalNewsDocumentCounts,
} from '@/utils/swr';
import { useEmitter, useStore } from '@/utils/vue';

const emit = defineEmits(['open-left-menu']);

const store = useStore();
const route = useRoute();
const emitter = useEmitter();
const { data: userInfo } = useUserInfo();
const { data: team } = useTeamInfo();
const { data: personalNewsCounts, mutate: mutateFeedCount } =
  useUserPersonalNewsDocumentCounts();

const feedType = computed(() => store.state.feedType.feedType);

const tabs = computed(() =>
  getTabs(feedType, team, userInfo, personalNewsCounts),
);

const updateFeedCount = () => mutateFeedCount();

const hasPersonalNews = computed(
  () => userInfo.value?.has_personal_news ?? false,
);

onMounted(() => {
  api.trackPageView({
    pageName: 'personal_news',
    pageUrl: route.fullPath,
  });
  emitter.on('personal-feed-article-removed', updateFeedCount);
  emitter.on('personal-feed-article-restored', updateFeedCount);
});

onUnmounted(() => {
  emitter.off('personal-feed-article-removed', updateFeedCount);
  emitter.off('personal-feed-article-restored', updateFeedCount);
});
</script>

<template>
  <div class="personal-news">
    <Header
      title="パーソナルニュース"
      detail="あなた専用のニュースをお届けします"
      :tabs="tabs"
      :is-one-line="true"
    />
    <Content>
      <div class="feed-content" v-if="userInfo">
        <div class="articles" v-if="hasPersonalNews">
          <div class="title">
            <span class="c-title c-title--xm">今日のニュース</span>
            <span class="subtitle c-text c-text--m"
              >パーソナルニュースは毎日2回更新されます(朝{{
                PERSONAL_NEWS_MORNING_UPDATE_HOUR
              }}時・昼{{ PERSONAL_NEWS_AFTERNOON_UPDATE_HOUR }}時)</span
            >
          </div>
          <ArticlesWeek />
        </div>
        <div class="no-articles" v-else>
          <PersonalNewsCountdown
            :actioned-article-counts="
              userInfo.personal_news_counts ?? { domestic: 0, foreign: 0 }
            "
            @open-left-menu="emit('open-left-menu')"
          />
        </div>
      </div>
    </Content>
  </div>
</template>

<style lang="scss" scoped>
.personal-news {
  width: 100%;
  margin: -24px 0 0 0;

  .feed-content {
    display: flex;
    flex-direction: column;

    .title {
      display: flex;
      flex-direction: column;
      gap: 4px;
      margin-bottom: 16px;

      .subtitle {
        color: #b3b3b3;
      }
    }
  }
}
</style>
