<script lang="ts">
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  PropType,
  ref,
} from 'vue';
import { DgrIcon } from '@stockmarkteam/donguri-ui';
import { ThemeUser, UserInfo } from '@/types';

type Field = {
  name: string;
  displayName: string;
  type: string;
};

/* actionsは右側の…メニューにあるもの
 * 例：
 * [
 *  {
 *    name: 'グループから外す',
 *    func: (user) => removeMember(user),
 *    enabled: (user) => user.role != 'group_admin',
 *  }
 * ]
 */
type Action = {
  name: string;
  func: (user: UserInfo | ThemeUser) => void;
  enabled: (user: UserInfo | ThemeUser) => boolean;
};

export default defineComponent({
  components: {
    DgrIcon,
  },
  props: {
    fields: { type: Array as PropType<Field[]>, required: true },
    data: { type: Array as PropType<UserInfo[] | ThemeUser[]>, required: true },
    initialSortField: { type: Object as PropType<Field> },
    initialSortOrder: {
      type: String as PropType<'asc' | 'desc'>,
      default: 'asc',
    },
    actions: { type: Array as PropType<Action[]> },
  },
  setup(props) {
    const headerFields = [
      {
        name: 'user_name',
        displayName: '名前',
        type: 'string',
      },
      ...props.fields,
    ];
    const formatRole = (role: string) => {
      return {
        admin: '管理者',
        group_admin: 'グループ管理者',
        editor: '編集者',
        viewer: '閲覧者',
      }[role];
    };

    const formatField = (field: Field, user: UserInfo | ThemeUser) => {
      const fieldName = field.name as keyof UserInfo & keyof ThemeUser;
      switch (field.type) {
        case 'boolean':
          // FIXME: 型パズルを解く
          return (user[fieldName] as unknown as boolean) === true ? '○' : '';
        case 'role':
          return formatRole(user[fieldName] as string);
        default:
          return user[fieldName];
      }
    };

    const sortData = (field: Field, order: 'asc' | 'desc') => {
      const fieldName = field.name as keyof UserInfo & keyof ThemeUser;
      const orderMultiplier = order === 'asc' ? 1 : -1;
      return [...props.data].sort((a, b) => {
        if (field.type === 'string') {
          return (
            orderMultiplier *
            (a[fieldName] as string).localeCompare(b[fieldName] as string)
          );
        } else if (field.type === 'role') {
          const roleToNum = (role: string) =>
            ['admin', 'group_admin', 'editor', 'viewer'].indexOf(role);
          return (
            orderMultiplier *
            (roleToNum(a[fieldName] as string) -
              roleToNum(b[fieldName] as string))
          );
        } else if (field.type === 'boolean') {
          return (
            -1 *
            orderMultiplier *
            ((a[fieldName] as number) - (b[fieldName] as number))
          );
        } else {
          return (
            orderMultiplier *
            ((a[fieldName] as number) - (b[fieldName] as number))
          );
        }
      });
    };

    const sortField = ref(props.initialSortField ?? headerFields[0]);
    const sortOrder = ref(props.initialSortOrder);

    const sort = (fieldName: string) => {
      const field = headerFields.find(f => f.name === fieldName);
      if (!field) return;

      if (field.name === sortField.value.name) {
        sortOrder.value = sortOrder.value === 'asc' ? 'desc' : 'asc';
      } else {
        sortField.value = field;
        sortOrder.value = 'asc';
      }
    };
    const sortedData = computed(() =>
      sortData(sortField.value, sortOrder.value),
    );

    const actionMenuUser = ref<UserInfo | ThemeUser | undefined>(undefined);

    const closeActionMenu = () => (actionMenuUser.value = undefined);

    onMounted(() => document.addEventListener('click', closeActionMenu));
    onBeforeUnmount(() =>
      document.removeEventListener('click', closeActionMenu),
    );

    return {
      headerFields,
      formatField,
      sort,
      sortField,
      sortOrder,
      sortedData,
      actionMenuUser,
    };
  },
});
</script>

<template>
  <table class="user-table" cellspacing="0">
    <thead>
      <tr>
        <th
          class="c-text c-text--s"
          v-for="field in headerFields"
          :key="field.name"
          @click="sort(field.name)"
        >
          {{ field.displayName
          }}<span
            class="sort-icon"
            v-if="sortField.name === field.name && sortOrder == 'asc'"
            >↑</span
          ><span
            class="sort-icon"
            v-else-if="sortField.name === field.name && sortOrder == 'desc'"
            >↓</span
          >
        </th>
        <th v-if="actions"></th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="user in sortedData" :key="user.email">
        <td class="user-info">
          <img class="avatar" :src="user.image_url" />
          <div class="user-detail">
            <span class="c-text c-text--m">{{ user.user_name }}</span
            ><span class="email c-text c-text--xs">{{ user.email }}</span>
          </div>
        </td>
        <td v-for="field in fields" :key="field.name">
          <span class="c-text c-text--m">{{ formatField(field, user) }}</span>
        </td>
        <td class="actions" v-if="actions">
          <div class="action-icon" @click.stop="actionMenuUser = user">
            <DgrIcon name="ellipsis-h" />
          </div>
          <div class="action-menu" v-if="actionMenuUser === user">
            <div
              class="item c-text c-text--m"
              v-for="action in actions.filter(a => a.enabled(user))"
              :key="action.name"
              :class="{ disabled: !action.enabled(user) }"
              @click="
                () => (action.enabled(user) ? action.func(user) : undefined)
              "
            >
              {{ action.name }}
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<style lang="scss" scoped>
.user-table {
  background: #fff;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 4px 16px 16px 16px;

  th,
  td {
    padding: 8px 32px 8px 0;
    text-align: left;
    border-bottom: 1px solid #e6e6e6;
  }
  td {
    height: 40px;
  }
  th {
    cursor: pointer;
    padding-top: 16px;
    padding-bottom: 16px;
    white-space: nowrap;
    &:hover {
      background: $color-gray200;
    }
  }
  tbody tr {
    &:hover {
      background: $color-gray200;
    }
  }
  tr:last-child td {
    border-bottom: 0;
  }

  .sort-icon {
    padding: 0 4px;
  }

  .user-info {
    display: flex;
    align-items: center;
    .avatar {
      width: 24px;
      height: 24px;
      border-radius: 12px;
      margin-right: 12px;
    }
    .user-detail {
      display: flex;
      flex-direction: column;

      .email {
        color: #b3b3b3;
      }
    }
  }

  .actions {
    position: relative;
    width: 40px;
    padding-right: 0;

    .action-icon {
      display: flex;
      justify-content: center;
      width: 32px;
      padding: 8px;
      border-radius: 4px;
      &:hover {
        background-color: #f2f2f2;
      }
    }

    .action-menu {
      position: absolute;
      right: 0;
      min-width: 140px;
      padding: 12px 0;
      background: #ffffff;
      box-shadow: 0px 1px 5px rgba(74, 74, 74, 0.25);
      border-radius: 4px;
      z-index: var(--z-action-menu);
      cursor: pointer;

      .item {
        cursor: pointer;
        padding: 4px 12px;
        &:hover {
          background: #e6e6e6;
        }
        &.disabled {
          color: #b3b3b3;
          cursor: default;
        }
      }
    }
  }
}
</style>
