<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import { NOTIFICATION_TYPE } from '@/constants';
import { DgrIcon } from '@stockmarkteam/donguri-ui';
import Avatar from '@/components/common/atoms/avatar.vue';
import CommentReaction from '@/components/common/comment/comment-reaction.vue';
import RelativeTime from '@/components/common/molecules/relative-time.vue';
import { NotificationItem } from '@/types';

export default defineComponent({
  components: {
    Avatar,
    RelativeTime,
    CommentReaction,
    DgrIcon,
  },
  props: {
    type: {
      type: String as PropType<NotificationItem['type']>,
      required: true,
    },
    url: {
      type: String,
      required: false,
    },
    isUnread: {
      type: Boolean,
      required: true,
    },
    createdAt: {
      type: String,
      required: true,
    },
    userId: {
      type: Number,
      required: false,
      default: null,
    },
    imageUrl: {
      type: String,
      required: false,
      default: null,
    },
    contentTitle: {
      type: String,
      required: false,
      default: null,
    },
    preview: {
      type: String,
      required: false,
      default: null,
    },
    reactions: {
      type: Object as PropType<Record<string, number>>,
      required: false,
    },
    groupName: {
      type: String,
      required: false,
      default: null,
    },
  },
  setup(props) {
    const contentTitleClass = computed(() =>
      props.isUnread ? ['c-title', 'c-title--m'] : ['c-text', 'c-text--m'],
    );

    return {
      NOTIFICATION_TYPE,
      contentTitleClass,
    };
  },
});
</script>

<template>
  <component
    :is="url ? 'router-link' : 'div'"
    class="m-notification-card"
    :to="url"
    :class="{ 'no-link': url === '' }"
  >
    <div
      class="check-mark"
      v-if="type === NOTIFICATION_TYPE.FIRST_PERSONAL_NEWS"
    >
      <DgrIcon
        size="large"
        name="check-circle-fill"
        :keep-fill="false"
        class="primary"
      />
    </div>

    <div
      class="check-mark"
      v-else-if="type === NOTIFICATION_TYPE.UPDATE_THEME_FEED"
    >
      <DgrIcon size="large" :keep-fill="false" name="sync" class="sync" />
    </div>

    <Avatar v-else :image-url="imageUrl"></Avatar>

    <div class="notification-content">
      <div class="notification-text c-text c-text--m">
        <div class="notification-message">
          <div :class="contentTitleClass">
            <slot />
          </div>
          <span v-if="isUnread" class="unread-badge c-title c-title--xs"
            >未読</span
          >
        </div>
        <div class="notification-time c-text c-text--xs">
          <RelativeTime :datetime="createdAt" :hide-tooltip="true" />
        </div>
      </div>

      <div
        v-if="contentTitle"
        class="notification-content-title"
        :class="contentTitleClass"
      >
        {{ contentTitle }}
      </div>

      <div v-if="preview" class="notification-preview">
        <div class="notification-preview-inner c-text c-text--s">
          {{ preview }}
        </div>
      </div>

      <div v-if="reactions" class="notification-reactions">
        <CommentReaction
          v-for="(count, reaction) in reactions"
          :reaction="reaction"
          :count="count"
          :read-only="true"
          :key="reaction"
        />
      </div>

      <div v-if="groupName" class="notification-group-name c-text c-text--s">
        グループ: {{ groupName }}
      </div>
    </div>
  </component>
</template>
<style lang="scss" scoped>
.m-notification-card {
  display: flex;
  gap: 8px;
  align-items: flex-start;
  width: 592px;
  max-width: 100%;
  box-sizing: border-box;
  padding: 16px;
  background-color: white;
  border: 1px solid $color-gray400;
  border-radius: 4px;
  &:hover {
    border: 1px solid $color-gray600;
  }
  .notification-content {
    width: 100%;
    min-width: 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .notification-text {
    color: $color-gray1000;
  }
  &:deep(a) {
    &:hover {
      text-decoration: underline;
    }
  }
  &:deep(.unread a) {
    color: $color-gray1000;
    &:hover {
      text-decoration: underline;
    }
  }
  .notification-time {
    color: $color-gray600;
  }
  .primary {
    fill: $color-green600;
  }
  .sync {
    fill: $color-gray400;
  }
  .avatar {
    width: 48px;
    height: 48px;

    :deep(.avatar-size--l) {
      width: 46px;
      height: 46px;
    }
  }
  .notification-message {
    display: flex;
    align-items: flex-start;
    gap: 4px;
  }
  .unread-badge {
    color: $color-orange1000;
    border: 1px solid $color-orange1000;
    padding: 2px 8px;
    border-radius: 20px;
    margin-left: auto;
    flex: 0 0 auto;
  }
  .notification-content-title {
    color: $color-gray1000;
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  .notification-preview {
    border: 1px solid $color-gray400;
    border-radius: 4px;
    padding: 8px;

    // -webkit-line-clampによるtruncateがある要素にpaddingがあると
    // truncateしたはずのテキストが見切れることがあるのでinnerに分離する
    .notification-preview-inner {
      color: $color-gray1000;
      overflow: hidden;
      display: -webkit-box;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
  }
  .notification-reactions {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  .notification-group-name {
    color: $color-gray1000;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
:deep(.no-link) {
  cursor: auto;
}
</style>
