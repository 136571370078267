<script setup lang="ts">
/**
 * スレッドの内の1つの塊（クエリ, ソース, 回答の1セット）を表すコンポーネント
 */
import { computed, inject, Ref, ref, watch } from 'vue';
import { trackingSurveyEvent } from '@/api/tracking';
import { DOC_TYPE_LABELS } from '@/constants';
import { DgrIcon } from '@stockmarkteam/donguri-ui';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import DeprecatedSurveyAdpDocumentCard from '@/components/survey/deprecated/deprecated-survey-adp-document-card.vue';
import DeprecatedSurveyReportCard from '@/components/survey/deprecated/deprecated-survey-adp-report-card.vue';
import DeprecatedSurveyFeedback from '@/components/survey/deprecated/deprecated-survey-feedback.vue';
import DeprecatedSurveyUserDocumentCard from '@/components/survey/deprecated/deprecated-survey-user-document-card.vue';
import SkeletonCard from '@/components/survey/document/skeleton-card.vue';
import SkeletonSentence from '@/components/survey/document/skeleton-sentence.vue';
import SurveyContentRenderer from '@/components/survey/generate-content/survey-content-renderer.vue';
import { FeedbackType, isReport, Part } from '@/types';
import { AdpDocument, isUserDocument, UserDocument } from '@/types';
import { useCurrentRoutePath } from '@/utils/composables/useCurrentRoutePath';
import { closeAllDocumentsKey } from '@/utils/injectionKeys';
import { SURVEY_ERROR_MESSAGE } from '@/utils/survey/constants';
import { SURVEY_PAGE_NAME } from '@/utils/survey/constants';
import { useUserInfo } from '@/utils/swr';
import { getDocumentUrl } from '@/utils/user-document/common';

interface Props {
  question: string;
  sources: Array<AdpDocument | UserDocument>;
  originMarkdownSummary: string; // LLMの出力したマークダウン形式の要約 (DOMにパースする前の状態)
  summary: Array<Part>;
  isNoHitSources: boolean; // questionで検索した結果が0件の場合
  isError: boolean; // 何かしらエラーが発生した場合 (modelが要約生成中に通信エラーなど)
  sessionId: string; // surveysテーブルのuuidカラムの値 (要約画面のURLに含まれるID)。深掘り検索（同じスレッド）の間は同じID
  requestId: string; // survey_historiesテーブルのuuidカラムの値 (要約ブロックを識別するID)
  isInProgressSurvey: boolean; // survey実行中かどうか
  enableAnimation: boolean; // 要約の表示アニメーションを有効にするかどうか
  feedbackType: FeedbackType | undefined; // survey_feedbacksテーブルのevaluationカラムの値 (ブロックに対して実行したフィードバックの種類)
}

const props = defineProps<Props>();

const emit = defineEmits<{
  'update-document': [{ sourceIndex: number; document: AdpDocument }];
}>();

const { getCurrentRoutePath } = useCurrentRoutePath();
const { createSnackbar } = useSnackbar();

// ドキュメントを表示するかどうかのフラグ (最初からドキュメントすべてを展開すると最大20件と多くスクロールが面倒なため、最初は1.5件ほどしか見せない)
const isAllDocumentsVisible = ref(false);
const closeAllDocuments = inject(closeAllDocumentsKey) as Ref<boolean>;
watch(closeAllDocuments, newValue => {
  if (newValue) isAllDocumentsVisible.value = false;
});

const displayedDocuments = computed(() => {
  if (!props.sources) return [];
  if (props.sources.length < 2) return props.sources;

  return isAllDocumentsVisible.value
    ? props.sources
    : props.sources.slice(0, 2);
});

const { data: userInfo } = useUserInfo();
const userEnableTranslateTitle = computed(
  () => userInfo.value?.enable_translate_article_title,
);

const showAllDocuments = async () => {
  isAllDocumentsVisible.value = true;

  // 計測
  await trackingSurveyEvent('hit_documents_show_all', {
    page: {
      name: 'survey',
      url: getCurrentRoutePath(),
    },
    feature: 'survey',
  });
};

// 検索に引っかかったドキュメントのみで表示するメッセージを生成する
const documentTypes = computed<Array<keyof typeof DOC_TYPE_LABELS>>(() => {
  const types = new Set<keyof typeof DOC_TYPE_LABELS>();
  if (props.sources) {
    props.sources.forEach(source => {
      types.add(source.doc_type);
    });
  }
  const order = Object.keys(DOC_TYPE_LABELS);
  return Array.from(types).sort((a, b) => order.indexOf(a) - order.indexOf(b));
});

const documentTypesMessage = computed(() => {
  const types = documentTypes.value.map(type => DOC_TYPE_LABELS[type] || type);
  const totalCount = props.sources.length;
  return `${totalCount}件の${types.join('、')}を検索しました`;
});

const copySummary = async () => {
  try {
    // バックエンドで脚注部分を削っているので、フロントエンドで整形してコピーする
    const urls = props.sources.map(source =>
      isUserDocument(source) ? getDocumentUrl(source) : source.url,
    );
    const footnote = urls
      .map((url, index) => `[${index + 1}] ${url}`)
      .join('\n');
    const formattedSummary = `${props.originMarkdownSummary.replace(/\^/g, '')}\nソース:\n${footnote}`;

    await navigator.clipboard.writeText(formattedSummary);

    createSnackbar({
      message: `コピーしました`,
      type: 'success',
    });
  } catch (_e) {
    createSnackbar({
      message: `コピーできませんでした`,
      type: 'error',
    });
  }
};
</script>

<template>
  <template v-if="props.isError">
    <p class="error-message">
      {{ SURVEY_ERROR_MESSAGE }}
    </p>
  </template>
  <template v-else>
    <div class="survey-history-container">
      <h2 class="c-title c-title--xl">{{ question }}</h2>
      <div class="spacing-16"></div>
      <!-- 検索結果が0ヒット -->
      <template v-if="props.isNoHitSources">
        <div class="generate-contents">
          <div class="generate-contents__header">
            <DgrIcon
              name="sparkles-fill"
              :keep-fill="false"
              class="generate-contents__ai-spark-icon"
            />
            <h3 class="c-title c-title--l">要約</h3>
          </div>
        </div>
        <div class="spacing-08"></div>
        <p>
          {{ props.question }}
          に関するソースを見つけられなかったため要約を生成できませんでした。
        </p>
        <p>
          質問をより明確にするか、要約に使用する情報源を変更することで要約を作成できる可能性があります。
        </p>
      </template>
      <template v-else>
        <!-- ソース (検索結果) -->
        <div v-if="displayedDocuments.length > 0">
          <div class="searching-word c-title c-title--m">
            <DgrIcon name="search" :keep-fill="false" />
            <p>{{ documentTypesMessage }}</p>
          </div>
          <div class="spacing-08"></div>
          <div
            v-for="(document, index) in displayedDocuments"
            :key="document.id"
            :class="{
              'half-transparent last-card':
                !isAllDocumentsVisible &&
                displayedDocuments.length >= 2 &&
                index === displayedDocuments.length - 1,
            }"
          >
            <DeprecatedSurveyUserDocumentCard
              v-if="isUserDocument(document)"
              :user-document="document"
              :badge-number="index + 1"
              :page-name="SURVEY_PAGE_NAME"
              :feature="SURVEY_PAGE_NAME"
              :rank-in-whole-feed="index + 1"
              :contents-context="{
                event_name: SURVEY_PAGE_NAME,
                session_id: props.sessionId,
              }"
            />
            <DeprecatedSurveyReportCard
              v-else-if="isReport(document)"
              :adp-report="document"
              :badge-number="index + 1"
              :page-name="SURVEY_PAGE_NAME"
              :feature="SURVEY_PAGE_NAME"
              :rank-in-whole-feed="index + 1"
              :contents-context="{
                event_name: SURVEY_PAGE_NAME,
                session_id: props.sessionId,
              }"
            />
            <DeprecatedSurveyAdpDocumentCard
              v-else
              :adp-document="document"
              :badge-number="index + 1"
              :user-enable-translate-title="userEnableTranslateTitle"
              :is-narrow="true"
              :page-name="SURVEY_PAGE_NAME"
              :feature="SURVEY_PAGE_NAME"
              :rank-in-whole-feed="index + 1"
              :contents-context="{
                event_name: SURVEY_PAGE_NAME,
                session_id: props.sessionId,
              }"
              @update-document="
                document =>
                  emit('update-document', { sourceIndex: index, document })
              "
            />
            <div class="spacing-08"></div>
          </div>
        </div>
        <!-- 記事カード ローディング -->
        <div
          class="adp-document-card-skelton-wrapper"
          v-if="displayedDocuments.length === 0"
        >
          <div class="searching-word c-title c-title--m">
            <DgrIcon name="search" :keep-fill="false" />
            検索しています
          </div>
          <div class="spacing-08"></div>
          <template :key="index" v-for="index in 2">
            <SkeletonCard :number-of-lines="3"></SkeletonCard>
            <div class="spacing-08"></div>
          </template>
        </div>
        <div class="show-all-button-wrapper">
          <button
            v-if="displayedDocuments.length >= 2 && !isAllDocumentsVisible"
            @click="showAllDocuments"
            class="show-all-button"
          >
            すべて表示
          </button>
        </div>
        <div class="spacing-16"></div>
        <div class="generate-contents">
          <div class="generate-contents__header">
            <DgrIcon
              name="sparkles-fill"
              :keep-fill="false"
              class="generate-contents__ai-spark-icon"
            />
            <h3 class="c-title c-title--l">要約</h3>
          </div>
        </div>
        <div class="spacing-08"></div>
        <SurveyContentRenderer
          v-if="props.summary.length > 0"
          v-memo="[props.summary, props.enableAnimation]"
          :content-parts="props.summary"
          :enable-animation="props.enableAnimation"
        />
        <!-- 要約 ローディング -->
        <SkeletonSentence :number-of-lines="5" v-else />

        <div v-if="!props.isInProgressSurvey" class="action-buttons-wrapper">
          <button @click="copySummary" class="action-button c-title c-title--m">
            <DgrIcon name="copy" />
            <p>コピーする</p>
          </button>
        </div>
        <div class="spacing-08"></div>
        <div class="summary-feedback">
          <DeprecatedSurveyFeedback
            v-if="!props.isInProgressSurvey && props.requestId !== undefined"
            :session-id="props.sessionId"
            :request-id="props.requestId"
            :feedback-type="props.feedbackType"
          />
        </div>
      </template>
    </div>
  </template>
</template>

<style lang="scss" scoped>
.survey-history-container {
  border-bottom: 1px solid $color-gray400;
  margin: 8px 0 16px;
  padding-bottom: 16px;
}

h2 {
  padding: 8px 0;
}

.error-message {
  margin: 16px 0;
  padding-bottom: 16px;
  border-bottom: 1px solid $color-gray400;
}

.searching-word {
  display: flex;
  gap: 8px;
  padding: 6px;
}

/* ----- "すべて表示"で展開するまでは上限数のカードのみを表示 (最後のカードの下半分は半透明にする) ----- */
.last-card {
  height: 50% !important;
  overflow: hidden;
  mask-image: linear-gradient(to bottom, black 10%, transparent 90%);
  -webkit-mask-image: linear-gradient(to bottom, black 10%, transparent 90%);
  pointer-events: none;
}

.half-transparent {
  position: relative;
  background-color: white;
  overflow: hidden;
}

.half-transparent::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, white 10%, transparent 90%);
}
/* ------------------------------------------------------------ */

.adp-document-card-skelton-wrapper {
  margin-bottom: 16px;
}

.show-all-button-wrapper {
  position: relative;
  height: 0;
}

.show-all-button {
  border: 1px solid $color-gray400;
  background-color: $color-white;
  border-radius: 4px;
  padding: 9px 16px;
  margin: 0 auto;
  position: absolute;
  top: -65px;
  left: 50%;
  transform: translateX(-50%);
}

.generate-contents {
  &__header {
    display: flex;
    align-items: center;
    gap: 8px;
    height: 44px;
  }

  &__ai-spark-icon {
    fill: $color-gray1000;
  }
}

.action-buttons-wrapper {
  display: grid;
  gap: 16px;
  align-items: center;
  margin-top: 8px;

  .action-button {
    display: flex;
    gap: 3px;
    align-items: center;
    width: fit-content;
    padding: 8px 16px;
    border: none;
    background: transparent;
    cursor: pointer;

    &:hover {
      background-color: $color-gray400;
    }
  }
}
</style>
