<script lang="ts" setup>
import { DgrCheckbox } from '@stockmarkteam/donguri-ui';

type Props = {
  title: string;
  disabled?: boolean;
};
withDefaults(defineProps<Props>(), {
  disabled: false,
});

const model = defineModel<boolean>({
  required: true,
});
</script>
<template>
  <DgrCheckbox v-model="model" :disabled="disabled" checkbox-align="top">
    <div class="m-setting-title c-text c-text--m">{{ title }}</div>
    <div class="m-setting-description c-text c-text--s">
      <slot />
    </div>
  </DgrCheckbox>
</template>
<style lang="scss" scoped>
.m-setting-title {
  line-height: 14px;
  margin-bottom: 4px;
}
.m-setting-description {
  color: $color-gray600;
}
</style>
