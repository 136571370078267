import { Module } from 'vuex';
import api from '@/api';
import { NotificationType, NotificationUnviewedAndUnreadCounts } from '@/types';

type UnreadCount = {
  counts: NotificationUnviewedAndUnreadCounts<
    Record<string, readonly NotificationType[]>
  >;
};

export type NotificationState = {
  hasUnviewed: boolean;
  unviewedCount: number;
  unviewedUnreadCountData: UnreadCount;
};

export const initialNotificationState: NotificationState = {
  hasUnviewed: false,
  unviewedCount: 0,
  unviewedUnreadCountData: {
    counts: {},
  },
};

const notification: Module<NotificationState, unknown> = {
  namespaced: true,
  state: () => initialNotificationState,
  mutations: {
    setHasUnviewed(state, payload) {
      state.hasUnviewed = payload;
    },
    setUnviewedCount(state, payload) {
      state.unviewedCount = payload;
    },
    setUnviewedUnreadCountData(state, payload) {
      state.unviewedUnreadCountData = payload;
    },
  },
  actions: {
    /** 対象の通知を既読にする */
    async read(_, payload) {
      const { notification } = payload;
      const id = notification.id;
      await api.readNotification(id);
    },
    /** 通知グループを閲覧済みにする */
    async viewNotificationGrouping(_, payload) {
      const { grouping } = payload;
      await api.viewNotifications(grouping);
    },
    /** 通知グループを既読にする */
    async readNotificationGrouping(_, payload) {
      const { grouping } = payload;
      await api.readNotifications(grouping);
    },
    /** 通知を取得する */
    async fetchNotifications(_, payload) {
      const {
        limit,
        page,
        order,
        notificationTypes,
        unreadOnly,
        trackingData,
      } = payload;
      const notificaitons = await api.fetchNotifications(
        limit,
        page,
        order,
        notificationTypes,
        unreadOnly,
        trackingData,
      );
      return notificaitons;
    },
  },
};

export default notification;
