import { ExtractPublicPropTypes } from 'vue';
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import api from '@/api';
import { MYPAGE_ROUTE_META, MYPAGE_ROUTE_NAMES } from '@/routers/constants';
import {
  redirectToMypage,
  shouldRedirectToLogin,
  shouldRedirectToMypage,
} from '@/routers/routingCommon';
import store from '@/stores/pcStore';
import * as Sentry from '@sentry/vue';
import ContractAdmin from '@/components/admin/contracts/contract-admin.vue';
import ContractMemberAdmin from '@/components/admin/contracts/contract-member-admin.vue';
import EnvironmentSettings from '@/components/admin/environment-settings/environment-settings.vue';
import GroupAdmin from '@/components/admin/groups/group-admin.vue';
import IpAddressRestrictions from '@/components/admin/ip-address-restriction/ip-address-restrictions.vue';
import Members from '@/components/admin/members/members.vue';
import ThemeAdmin from '@/components/admin/themes/theme-admin.vue';
import ThemeMemberAdmin from '@/components/admin/themes/theme-member-admin.vue';
import anewsStorageUploadForm from '@/components/admin/user-documents/anews-storage-upload-form.vue';
import sharepointCredentialForm from '@/components/admin/user-documents/sharepoint-credential-form.vue';
import UserDocuments from '@/components/admin/user-documents/user-documents.vue';
import AdpDocumentPage from '@/components/adp-document/adp-document.vue';
import Comment from '@/components/comment/comment.vue';
import GroupActivities from '@/components/group/group-activities/group-activities.vue';
import GroupDetail from '@/components/group/group-detail.vue';
import GroupEdit from '@/components/group/group-edit.vue';
import GroupMarks from '@/components/group/group-marks.vue';
import GroupMembers from '@/components/group/group-members.vue';
import GroupSearch from '@/components/group/group-search.vue';
import GroupTagList from '@/components/group/group-tag-list.vue';
import AnewsHome from '@/components/home/anews-home.vue';
import followLists from '@/components/home/follow-lists/follow-lists.vue';
import MainView from '@/components/layouts/main-view.vue';
import AppDownload from '@/components/mobile/pages/app-download.vue';
import Notifications from '@/components/notifications/notifications.vue';
import OtherOrganizationTagDetail from '@/components/others/organization-tags/organization-tag-detail/organization-tag-detail.vue';
import OtherOrganizationTags from '@/components/others/organization-tags/organization-tag-list/organization-tag-list.vue';
import PersonalNews from '@/components/personal-news/personal-news.vue';
import PersonalPatents from '@/components/personal-news/personal-patents.vue';
import PersonalResearchPapersNew from '@/components/personal-news/personal-research-papers.vue';
import redirectEmailPage from '@/components/redirect-page/email.vue';
import redirectWebPushPage from '@/components/redirect-page/web-push.vue';
import PromoSearchResults from '@/components/search/promotion-user-document-trial/promo-search-results.vue';
import SearchResults from '@/components/search/search-results.vue';
import UserSettingsEmail from '@/components/settings/user-settings-email.vue';
import UserSettingsNews from '@/components/settings/user-settings-news.vue';
import UserSettingsNotification from '@/components/settings/user-settings-notification.vue';
import UserSettingsPassword from '@/components/settings/user-settings-password.vue';
import UserSettingsProfile from '@/components/settings/user-settings-profile.vue';
import SurveySession from '@/components/survey/survey-session.vue';
import SurveyTop from '@/components/survey/survey-top.vue';
import IndustryNews from '@/components/team/industry-news.vue';
import TeamFeaturedArticles from '@/components/team/team-featured-articles.vue';
import ThemeEdit from '@/components/theme/edit/theme-edit.vue';
import ThemePatents from '@/components/theme/patents.vue';
import ThemeResearchPapers from '@/components/theme/research-papers.vue';
import ThemeKeywordFeed from '@/components/theme/theme-keyword-feed.vue';
import ThemeLayout from '@/components/theme/theme-layout.vue';
import ThemeRelatedFeed from '@/components/theme/theme-related-feed.vue';
import ThemeSearch from '@/components/theme/theme-search.vue';
import UserDocumentPage from '@/components/user-document/user-document-preview-page.vue';
import MyTags from '@/components/user/my-tags.vue';
import MypageMarks from '@/components/user/mypage-marks.vue';
import UserComments from '@/components/user/user-comments.vue';
import UserMarks from '@/components/user/user-marks.vue';
import UserViews from '@/components/user/user-views.vue';
import { isMobileUser } from '@/utils';
import { userSession } from '@/utils/userSession';
import { accountRouteConfig } from './accountRoutes';
import { dashboardRouteConfig } from './dashboardRoutes';
import { labsRouteConfig } from './labsRoutes';
import { onboardingRouteConfig } from './onboardingRoutes';
import { featureFlags } from '@/featureFlags';

type NotificationProps = Partial<
  ExtractPublicPropTypes<InstanceType<typeof Notifications>>
>;

export const routes: RouteRecordRaw[] = [
  // メールから記事を開く際にユーザーのメーラーが、
  // 計測用エンドポイントにアクセスすることを防ぐためにクッションページを挟む
  {
    path: '/r/:docType/:articleId',
    component: redirectEmailPage,
    name: 'redirect_page',
    meta: {
      type: 'no_login_required_redirect',
    },
  },

  accountRouteConfig,
  dashboardRouteConfig,
  onboardingRouteConfig,
  labsRouteConfig,
  {
    path: '/',
    component: MainView,
    children: [
      {
        path: '/',
        component: AnewsHome,
        name: 'anewsHome',
        meta: { title: 'ホーム', type: 'home' },
      },
      {
        path: '/admin/members',
        component: Members,
        name: 'members',
        meta: { title: 'メンバー管理', type: 'admin' },
      },
      {
        path: '/admin/environment_settings',
        component: EnvironmentSettings,
        name: 'environmentSettings',
        meta: { title: '環境設定', type: 'admin' },
      },
      {
        path: '/admin/ip_address_restrictions',
        component: IpAddressRestrictions,
        name: 'ipAddressRestrictions',
        meta: { title: 'IPアドレス制限', type: 'admin' },
      },
      {
        path: '/admin/contracts',
        component: ContractAdmin,
        name: 'contracts',
        meta: { title: '契約管理', type: 'admin' },
      },
      {
        path: '/admin/contracts/:contractId/members',
        component: ContractMemberAdmin,
        name: 'contractMembers',
        meta: { title: '契約管理', type: 'admin' },
      },
      {
        path: '/admin/themes',
        component: ThemeAdmin,
        name: 'themeAdmin',
        meta: { title: 'テーマ管理', type: 'admin' },
      },
      {
        path: '/admin/groups',
        component: GroupAdmin,
        name: 'groupAdmin',
        meta: { title: 'グループ管理', type: 'admin' },
      },
      {
        path: '/admin/user_documents',
        component: UserDocuments,
        name: 'userDocumentsAdmin',
        meta: { title: '社内情報ストレージ管理', type: 'admin' },
      },
      {
        path: 'admin/user_documents/sharepoint_credential/create',
        name: 'sharepointCredentialCreate',
        meta: { title: 'SharePoint認証情報登録', type: 'admin' },
        component: sharepointCredentialForm,
      },
      {
        path: 'admin/user_documents/anews_storage/upload',
        name: 'anewsStorageUpload',
        meta: { title: 'Anewsストレージアップロード', type: 'admin' },
        component: anewsStorageUploadForm,
      },
      {
        path: '/admin/themes/:themeId/members',
        component: ThemeMemberAdmin,
        name: 'themeAdminMembers',
        meta: { title: 'テーマ管理', type: 'admin' },
      },
      {
        path: '/themes/:themeId/edit',
        component: ThemeEdit,
        name: 'themeEdit',
        meta: { title: 'テーマ', type: 'theme' },
        props: true,
      },
      {
        path: '/themes/create',
        component: ThemeEdit,
        name: 'themeCreate',
        meta: { title: 'テーマ作成', type: 'themeCreate' },
      },
      {
        path: '/themes/search',
        component: ThemeSearch,
        name: 'themeSearch',
        meta: { title: 'テーマ検索', type: 'themeSearch' },
      },
      {
        path: '/themes/:themeId',
        component: ThemeLayout,
        props: true,
        children: [
          {
            path: 'foreign_feed',
            redirect: { name: 'themeFeed' },
            meta: { title: 'テーマ', type: 'theme' },
          },
          {
            path: 'related_news',
            component: ThemeRelatedFeed,
            name: 'themeRelatedNews',
            meta: { title: 'テーマ', type: 'theme' },
          },
          {
            path: '',
            component: ThemeKeywordFeed,
            name: 'themeFeed',
            meta: { title: 'テーマ', type: 'theme' },
          },
          {
            path: 'technical_literature',
            name: 'themeTechnicalLiterature',
            redirect: { name: 'themeResearchPapers' },
            meta: { title: 'テーマ', type: 'theme' },
          },
          {
            path: 'research_papers',
            component: ThemeResearchPapers,
            name: 'themeResearchPapers',
            meta: { title: 'テーマ', type: 'theme' },
          },
          {
            path: 'patents',
            component: ThemePatents,
            name: 'themePatents',
            meta: { title: 'テーマ', type: 'theme' },
          },
        ],
      },
      {
        path: '/personal-news',
        component: PersonalNews,
        name: 'personalNews',
        meta: { title: 'パーソナルニュース', type: 'personalNews' },
      },
      {
        path: '/personal-research-papers',
        component: PersonalResearchPapersNew,
        name: 'personalResearchPapers',
        meta: { title: 'パーソナル論文', type: 'personalNews' },
      },
      {
        path: '/personal-patents',
        component: PersonalPatents,
        name: 'personalPatents',
        meta: { title: 'パーソナル特許', type: 'personalNews' },
      },
      {
        path: '/groups',
        component: GroupSearch,
        name: 'groupList',
        meta: { title: 'グループ検索', type: 'group' },
      },
      {
        path: '/groups/:groupId',
        component: GroupActivities,
        name: 'groupActivity',
        meta: { title: 'グループ', type: 'group' },
      },
      ...(featureFlags.ANDEV_4571_ADD_GROUP_TAG_LIST
        ? [
            {
              path: '/groups/:groupId/tag-list',
              component: GroupTagList,
              name: 'groupTagList',
              meta: { title: 'グループ', type: 'group' },
            },
          ]
        : []),
      {
        path: '/groups/:groupId/comments',
        component: GroupDetail,
        name: 'groupDetail',
        meta: { title: 'グループ', type: 'group' },
      },
      {
        path: '/groups/:groupId/marks',
        component: GroupMarks,
        name: 'groupMarks',
        meta: { title: 'グループ', type: 'group' },
      },
      {
        path: '/articles/:id',
        component: AdpDocumentPage,
        name: 'articlePage',
        meta: { title: '記事詳細', type: 'article' },
        props: route => ({
          id: route.params.id,
          doc_type: 'article',
        }),
      },
      {
        path: '/research_papers/:id',
        component: AdpDocumentPage,
        name: 'researchPaperPage',
        meta: { title: '論文詳細', type: 'article' },
        props: route => ({
          id: route.params.id,
          doc_type: 'research_paper',
        }),
      },
      {
        path: '/patents/:id',
        component: AdpDocumentPage,
        name: 'patentPage',
        meta: { title: '特許詳細', type: 'article' },
        props: route => ({
          id: route.params.id,
          doc_type: 'patent',
        }),
      },
      {
        path: '/user_documents/:id',
        component: UserDocumentPage,
        name: 'userDocumentPreviewPage',
        meta: { title: '社内情報プレビュー', type: 'article' },
        props: route => ({
          id: route.params.id,
          doc_type: 'user_document',
          page: route.query.page ? Number(route.query.page) : 1,
        }),
      },
      {
        path: '/comments/:commentId',
        component: Comment,
        name: 'groupComment',
        meta: { title: 'グループ', type: 'group' },
      },
      {
        path: '/groups/:groupId/edit',
        component: GroupEdit,
        name: 'groupEdit',
        meta: { title: 'グループ', type: 'group' },
      },
      {
        path: '/groups/:groupId/members',
        component: GroupMembers,
        name: 'groupMembers',
        meta: { title: 'グループ', type: 'group' },
        props: true,
      },
      {
        path: '/users/:userId',
        name: 'userProfile',
        meta: { title: 'ユーザーページ', type: 'user' },
        redirect: route => {
          return `/users/${route.params.userId}/marks`;
        },
      },
      {
        path: '/users/:userId/marks',
        component: UserMarks,
        name: 'userMarks',
        meta: { title: 'ユーザーページ', type: 'user' },
        props: true,
      },
      {
        path: '/others',
        name: 'others',
        meta: { title: 'その他', type: 'others' },
        redirect: () => {
          return `/others/organization_tags`;
        },
      },
      {
        path: '/others/organization_tags',
        component: OtherOrganizationTags,
        name: 'organizationTags',
        meta: { title: '組織タグ', type: 'others' },
      },
      {
        path: '/others/organization_tags/:organizationTagId',
        component: OtherOrganizationTagDetail,
        name: 'organizationTagDetail',
        props: true,
        meta: { title: '組織タグ', type: 'others' },
      },
      {
        path: '/mypage',
        name: MYPAGE_ROUTE_NAMES.profile,
        meta: MYPAGE_ROUTE_META,
        redirect: { name: 'mypageMarks' },
        children: [
          {
            path: 'marks',
            component: MypageMarks,
            name: MYPAGE_ROUTE_NAMES.marks,
          },
          {
            path: 'views',
            component: UserViews,
            name: MYPAGE_ROUTE_NAMES.views,
          },
          {
            path: 'comments',
            component: UserComments,
            name: MYPAGE_ROUTE_NAMES.comments,
          },
          {
            path: 'export',
            component: () => import('@/components/user/user-export.vue'),
            name: MYPAGE_ROUTE_NAMES.export,
          },
          ...(featureFlags.ANDEV_5137_ADD_MY_TAG_LIST
            ? [
                {
                  path: 'my-tags',
                  component: MyTags,
                  name: MYPAGE_ROUTE_NAMES.myTags,
                },
              ]
            : []),
        ],
      },
      {
        path: '/settings/profile',
        component: UserSettingsProfile,
        name: 'userSettingsProfile',
        meta: { title: 'プロフィール設定', type: 'userSettings' },
      },
      {
        path: '/settings/news',
        component: UserSettingsNews,
        name: 'userSettingsNews',
        meta: { title: 'ニュース配信設定', type: 'userSettings' },
      },
      {
        path: '/settings/email',
        component: UserSettingsEmail,
        name: 'userSettingsEmail',
        meta: { title: 'メール配信設定', type: 'userSettings' },
      },
      {
        path: '/settings/notification',
        component: UserSettingsNotification,
        name: 'userSettingsNotification',
        meta: { title: '通知設定', type: 'userSettings' },
      },
      {
        path: '/settings/password',
        component: UserSettingsPassword,
        name: 'userSettingsPassword',
        meta: { title: 'パスワード設定', type: 'userSettings' },
      },
      {
        path: '/settings',
        name: 'userSettings',
        redirect: { name: 'userSettingsProfile' },
        meta: { title: 'プロフィール設定', type: 'userSettings' },
      },
      {
        path: '/team/featured_articles',
        component: TeamFeaturedArticles,
        name: 'teamFeaturedArticles',
        meta: { title: '注目記事', type: 'team' },
      },
      {
        path: '/team/industry_news',
        component: IndustryNews,
        name: 'industryNews',
        meta: { title: '業界ニュース', type: 'industryNews' },
      },
      {
        path: '/follow_lists',
        component: followLists,
        name: 'followLists',
        meta: { title: 'フォローリスト', type: 'followLists' },
      },
      {
        path: '/notifications',
        redirect: '/notifications/mentions',
        name: 'notifications',
        meta: { title: 'お知らせ', type: 'notifications' },
        children: [
          {
            path: 'mentions',
            component: Notifications,
            name: 'mentionsNotifications',
            meta: {
              title: 'お知らせ',
              type: 'notifications',
            },
            props: {
              notificationGrouping: 'mentions',
            } satisfies NotificationProps,
          },
          {
            path: 'members',
            component: Notifications,
            name: 'membersNotifications',
            meta: {
              title: 'お知らせ',
              type: 'notifications',
            },
            props: {
              notificationGrouping: 'members',
            } satisfies NotificationProps,
          },
          {
            path: 'others',
            component: Notifications,
            name: 'othersNotifications',
            meta: {
              title: 'お知らせ',
              type: 'notifications',
            },
            props: {
              notificationGrouping: 'others',
            } satisfies NotificationProps,
          },
        ],
      },
      {
        path: '/search',
        component: SearchResults,
        name: 'search',
        meta: { title: '検索結果', type: 'search' },
      },
      {
        path: '/search/all',
        component: SearchResults,
        name: 'searchAll',
        meta: { title: '検索結果', type: 'search' },
      },
      {
        path: '/search/news',
        component: SearchResults,
        name: 'searchNews',
        meta: { title: '検索結果', type: 'search' },
      },
      {
        path: '/search/research_papers',
        component: SearchResults,
        name: 'searchResearchPapers',
        meta: { title: '検索結果', type: 'search' },
      },
      {
        path: '/search/patent',
        component: SearchResults,
        name: 'searchPatent',
        meta: { title: '検索結果', type: 'search' },
      },
      {
        path: '/search/report',
        component: SearchResults,
        name: 'searchReport',
        meta: { title: '検索結果', type: 'search' },
      },
      {
        path: '/web-push/redirect-to',
        component: redirectWebPushPage,
        name: 'redirect_web_push_page',
        meta: {
          type: 'no_login_required_redirect',
        },
      },
      {
        path: '/search/user_document',
        component: SearchResults,
        name: 'searchUserDocument',
        meta: { title: '検索結果', type: 'search' },
      },
      {
        path: '/search/user_document_demo',
        component: PromoSearchResults,
        name: 'searchUserDocumentDemo',
        meta: { title: '検索結果', type: 'search' },
      },
      {
        path: '/survey',
        name: 'survey',
        meta: { title: '要約', type: 'survey' },
        children: [
          {
            path: 'top',
            component: SurveyTop,
            name: 'surveyTop',
            meta: { title: '要約', type: 'survey' },
          },
          {
            path: ':survey_session_id',
            component: SurveySession,
            name: 'surveySession',
            meta: { title: '要約', type: 'survey' },
          },
        ],
      },
    ],
  },
  {
    path: '/redirect',
    meta: {
      type: 'redirect',
    },
    beforeEnter: async (to, _, next) => {
      const sendViewParamsRaw = localStorage.getItem('redirectSendViewParams');
      if (sendViewParamsRaw !== null) {
        localStorage.removeItem('redirectSendViewParams');
        try {
          const sendViewParams = JSON.parse(sendViewParamsRaw);
          await api.sendView(sendViewParams);
        } catch (error) {
          Sentry.captureMessage(error as string, 'warning');
        }
      }
      if (typeof to.query.url === 'string') {
        location.href = `${to.query.url}${to.hash}`;

        // リダイレクト先が同じAnewsドメインの場合はリロードする(リロードしないとVueアプリケーションが読み込まれないため)
        if (to.query.url.includes(location.host)) {
          location.reload();
        }
      } else {
        // beforeEnterで記事に飛べないときはホームに飛ばす
        next({ name: 'anewsHome' });
      }
    },
    // 型定義のためにdummyで設定
    component: {
      template: '<div></div>',
    },
  },
  {
    path: '/mobile_app_download',
    meta: {
      type: 'mobile_app_download',
    },
    beforeEnter() {
      location.href = '/api/v1/mobile_app/store_redirect';
    },
    // 型定義のためにdummyで設定
    component: {
      template: '<div></div>',
    },
  },
  { path: '/:pathMatch(.*)', redirect: '/' },
];

const mobileRoutes: RouteRecordRaw[] = [
  // メールから記事を開く際にユーザーのメーラーが、
  // 計測用エンドポイントにアクセスすることを防ぐためにクッションページを挟む
  {
    path: '/r/:docType/:articleId',
    component: redirectEmailPage,
    name: 'redirect_page',
    meta: {
      type: 'no_login_required_redirect',
    },
  },
  {
    path: '/articles/:id',
    component: () => import('@/components/mobile/pages/adp-document.vue'),
    name: 'articlePage',
    meta: { title: '記事詳細', type: 'article' },
    props: route => ({
      id: route.params.id,
      doc_type: 'article',
    }),
  },
  {
    path: '/mobile_login',
    component: () => import('@/components/account/mobile-login.vue'),
    name: 'login',
    meta: { title: 'ログイン', type: 'account' },
  },
  {
    path: '/',
    component: AppDownload,
  },
  { path: '/:pathMatch(.*)', redirect: '/' },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes: isMobileUser() ? mobileRoutes : routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.path === from.path && savedPosition) {
      return savedPosition;
    } else {
      return { left: 0, top: 0 };
    }
  },
});

router.beforeEach(async (to, from, next) => {
  store.commit('route/setPreviousRoute', from);
  if (shouldRedirectToLogin(to)) {
    next({ name: 'login', query: { redirect: to.fullPath } });
    return;
  }
  if (to.name === 'login' && userSession.isActive()) {
    next({ name: 'anewsHome' });
    return;
  }

  if (shouldRedirectToMypage(to)) {
    redirectToMypage(to, next);
  }

  next();
});

const DEFAULT_TITLE = 'Anews';

router.afterEach(to => {
  const title = to.meta.title
    ? `${to.meta.title} | ${DEFAULT_TITLE}`
    : DEFAULT_TITLE;
  document.title = title;
});

export default router;
