<script lang="ts" setup>
import { computed, ref } from 'vue';
import { MAX_ORGANIZATION_TAG_NAME_LENGTH } from '@/constants';
import {
  checkIsTooLongOrganizationTagName,
  validateMessages,
} from '@/utils/validators';
import type { FormData } from './types';

const props = defineProps<{
  formData: FormData;
}>();
const emit = defineEmits<{ onSubmitNewTag: [newTagName: string] }>();
const formData = computed(() => props.formData);
const newTagName = ref('');
const isTooLongName = computed(() =>
  checkIsTooLongOrganizationTagName(newTagName.value),
);
const isDuplicatedName = computed(() =>
  Object.values(formData.value).some(
    formDataItem =>
      !formDataItem.isDeleted &&
      formDataItem.name &&
      formDataItem.name.toLowerCase() === newTagName.value.toLowerCase(),
  ),
);
const isValidName = computed(() => {
  return (
    newTagName.value.length > 0 &&
    !isTooLongName.value &&
    !isDuplicatedName.value
  );
});
const isDisabledSubmitButton = computed(() => {
  return !isValidName.value;
});
const handleSubmit = () => {
  emit('onSubmitNewTag', newTagName.value);
  newTagName.value = '';
};
</script>

<template>
  <div class="wrapper" data-testid="new-tag-form">
    <p class="c-text--m heading-text">組織タグの追加</p>

    <div class="container">
      <form class="new-tag-form" novalidate @submit.prevent="handleSubmit">
        <div class="new-tag-name-container">
          <div class="new-tag-name-textarea-container">
            <label for="new-tag-name" class="c-text--s"
              >入力して組織タグを追加</label
            >
            <textarea
              id="new-tag-name"
              class="c-textArea text-area"
              :class="{
                'c-formTextarea--error': isTooLongName || isDuplicatedName,
              }"
              autocomplete="off"
              placeholder="部署、プロジェクト、チームなど"
              v-model.trim="newTagName"
              data-testid="new-tag-name-textarea"
            />
          </div>

          <div
            class="new-tag-name-counter-container"
            data-testid="new-tag-name-counter-container"
          >
            <span
              v-if="isTooLongName"
              class="new-tag-name-counter new-tag-name-counter__error"
              data-testid="new-tag-name-too-long-error"
              >{{ validateMessages.organizationTagLong }}</span
            >
            <span
              v-else-if="isDuplicatedName"
              class="new-tag-name-counter new-tag-name-counter__error"
              data-testid="new-tag-name-duplicated-error"
              >{{ validateMessages.organizationTagDuplicated }}</span
            >
            <span
              class="new-tag-name-counter"
              :class="{
                'new-tag-name-counter__error':
                  isTooLongName || isDuplicatedName,
              }"
              data-testid="new-tag-name-counter"
              >{{
                `${newTagName.length}/${MAX_ORGANIZATION_TAG_NAME_LENGTH}`
              }}</span
            >
          </div>
        </div>

        <button
          class="o-create-button c-btn c-btn--auto c-btn--AnewsPrimary new-tag-submit-button"
          type="submit"
          :disabled="isDisabledSubmitButton"
          :class="{ disabled: isDisabledSubmitButton }"
          data-testid="new-tag-submit-button"
        >
          追加
        </button>
      </form>
    </div>
  </div>
</template>

<style scoped lang="scss">
.wrapper {
  width: 606px;
}
.heading-text {
  margin-bottom: 16px;
}
.container {
  box-sizing: border-box;
  padding: 16px 0;
  background-color: #fff;
  border-radius: $border-radius;
  border: 1px solid $color-border;
}
.new-tag-form {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 88px;
  gap: 12px;
}
.new-tag-name-container {
  flex: 1;
  max-width: 416px;
}
.new-tag-name-textarea-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.c-textTextarea {
  outline: none;
}
.new-tag-name-counter {
  font-size: 10px;
  color: $color-gray600;
  &__error {
    color: $color-orange1000;
  }
}
.new-tag-name-counter-container {
  display: flex;
  justify-content: flex-end;
  gap: 4px;
}
.o-create-button {
  height: 32px;
  padding: 0 16px;
}
.new-tag-submit-button {
  position: relative;
  top: 4px;
}
button.disabled {
  border: none;
  &:hover {
    border: none;
  }
}
.text-area {
  resize: vertical;
}
</style>
