<script lang="ts" setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import OrganizationTagLabels from '../others/organization-tags/organization-tag-labels.vue';

const store = useStore();
const tagNameList = computed(() => store.state.tagListModalInfo.tagNameList);
const contentTitle = computed(() => store.state.tagListModalInfo.contentTitle);
const hideModal = () => {
  store.commit('modal/hideModal');
};
</script>

<template>
  <div>
    <sm-dialog class="auto-height screen-center" @close="hideModal()">
      <template #header>
        <div class="o-header c-text c-text--m">{{ contentTitle }}</div>
      </template>
      <template #body>
        <div class="label-wrapper">
          <OrganizationTagLabels :labels="tagNameList" :deletable="false" />
        </div>
      </template>
      <template #footer>
        <button
          class="c-btn c-btn--small c-btn--auto"
          @click="() => hideModal()"
        >
          閉じる
        </button>
      </template>
    </sm-dialog>
  </div>
</template>

<style lang="scss" scoped>
.label-wrapper {
  max-height: 55vh;
  overflow-y: auto;
  padding-left: 8px;
}
</style>
