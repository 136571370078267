import { computed, ref } from 'vue';
import {
  notUndefined,
  useVirtualizer,
  VirtualizerOptions,
} from '@tanstack/vue-virtual';

type Props = {
  getCount: () => number;
} & Pick<VirtualizerOptions<Window, Element>, 'estimateSize' | 'overscan'>;

/** 仮想スクロール */
export const useVirtualScroll = ({
  getCount,
  estimateSize, // 仮想スクロール内の各アイテムのheight(px)
  overscan = 0, // 見えている範囲外に描画するアイテム数(スクロール時に要素が欠けて見えてしまう時に調整するために使用)
}: Props) => {
  const scrollableElement = ref<HTMLElement | null>(null);
  const scrollMargin = ref(0);
  const rowVirtualizerOptions = computed(() => {
    return {
      count: getCount(),
      estimateSize,
      getScrollElement: () => scrollableElement.value,
      overscan,
    };
  });
  const rowVirtualizer = useVirtualizer(rowVirtualizerOptions);
  const virtualRows = computed(() => rowVirtualizer.value.getVirtualItems());
  const totalSize = computed(() => rowVirtualizer.value.getTotalSize());

  // Table内部の余白計算
  // "before", "after"をダミーのtrタグのheightに設定し、現在ビューポートに表示されている行の実データ以外のスクロール可能な範囲を埋める
  const before = computed(() => {
    // 表示中のアイテムの先頭要素の開始位置 - スクロール開始位置
    if (virtualRows.value.length > 0) {
      return (
        notUndefined(virtualRows.value[0]).start -
        rowVirtualizer.value.options.scrollMargin
      );
    } else {
      return 0;
    }
  });
  const after = computed(() => {
    // バーチャルスクロール全体の高さ - 表示中の最後のアイテム下部の終了位置
    if (virtualRows.value.length > 0) {
      return (
        rowVirtualizer.value.getTotalSize() -
        notUndefined(virtualRows.value[virtualRows.value.length - 1]).end
      );
    } else {
      return 0;
    }
  });

  return {
    rowVirtualizer,
    virtualRows,
    scrollMargin,
    scrollableElement,
    totalSize,
    before,
    after,
  };
};
