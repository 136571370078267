<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import api from '@/api';
import PasswordForm from '@/components/account/password-form.vue';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import Content from '@/components/layouts/content.vue';
import Header from '@/components/layouts/header.vue';
import { useUserInfo } from '@/utils/swr';

export default defineComponent({
  components: {
    Header,
    Content,
    PasswordForm,
  },
  setup() {
    const { createSnackbar } = useSnackbar();
    const route = useRoute();
    const { data: userInfo } = useUserInfo();
    const timesChanged = ref(0); // 保存してフォームをリセットするためにkeyとして使われている
    const currentPassword = ref('');
    const newPassword = ref('');
    const isNewPasswordValid = ref(false);
    const isNewPasswordDifferent = computed(
      () => currentPassword.value !== newPassword.value,
    );

    const newPasswordUpdated = ({
      password,
      isValid,
    }: {
      password: string;
      isValid: boolean;
    }) => {
      newPassword.value = password;
      isNewPasswordValid.value = isValid;
    };

    const canSubmit = computed(
      () =>
        currentPassword.value.length > 0 &&
        isNewPasswordValid.value &&
        isNewPasswordDifferent.value,
    );

    const submit = async () => {
      try {
        await api.changePassword(currentPassword.value, newPassword.value, {
          pageName: 'password',
          pageUrl: route.fullPath,
        });

        createSnackbar({
          message: 'パスワードを変更しました',
          type: 'success',
        });
        currentPassword.value = '';
        newPassword.value = '';
        timesChanged.value += 1;
      } catch {
        // パスワードが変更できないことは現在のパスワードが正しくない可能性がある
        createSnackbar({
          message:
            'パスワードを変更できませんでした。現在のパスワードを再入力してください。',
          type: 'error',
        });
      }
    };

    return {
      userInfo,
      timesChanged,
      currentPassword,
      isNewPasswordValid,
      isNewPasswordDifferent,
      newPasswordUpdated,
      canSubmit,
      submit,
    };
  },
});
</script>
<template>
  <div class="user-settings" v-if="userInfo">
    <Header title="パスワード"></Header>
    <Content>
      <div class="settings-card">
        <div class="c-formBlock">
          <div class="c-formBlock__label">現在のパスワード</div>
          <input
            class="c-textInput"
            type="password"
            placeholder=""
            v-model="currentPassword"
          />
          <div class="spacing-04"></div>
          <router-link
            class="forgot-password c-text c-text--m"
            :to="{ name: 'forgotPassword' }"
            >パスワードを忘れてしまった場合</router-link
          >
        </div>
        <div class="spacing-12"></div>
        <div class="c-formBlock">
          <PasswordForm @password-changed="newPasswordUpdated"></PasswordForm>
          <div
            class="c-formBlock__text c-formBlock__text--error"
            v-if="
              currentPassword.length > 0 &&
              isNewPasswordValid &&
              !isNewPasswordDifferent
            "
          >
            <div class="c-text--s">
              現在のパスワードと変わらないため、パスワードを変更できません。
            </div>
            <div class="c-text--s">
              再度、新しいパスワードを入力してください。
            </div>
          </div>
        </div>
        <div class="spacing-24"></div>
        <button
          class="c-btn c-btn--small c-btn--AnewsPrimary"
          :class="{ disabled: !canSubmit }"
          :disabled="!canSubmit"
          @click="submit"
        >
          変更
        </button>
      </div>
    </Content>
  </div>
</template>
<style lang="scss" scoped>
.user-settings {
  width: 100%;
  margin: -24px 0 0 0;

  .settings-card {
    box-sizing: border-box;
    width: 440px;
    padding: 16px;
    background: #fff;
    border: 1px solid $color-border;
    border-radius: $border-radius;

    .forgot-password {
      color: #1da482;
    }

    .o-password-form {
      flex-direction: column;
    }

    .c-formBlock div {
      flex-direction: column;
    }

    .c-btn {
      width: 64px;
      margin: 0;
    }
  }
}
</style>
