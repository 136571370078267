<script setup lang="ts">
import { computed, ref } from 'vue';
import { EMPTY_KEYWORD_FEED_MESSAGE } from '@/constants';
import { DgrLoading } from '@stockmarkteam/donguri-ui';
import AdpDocumentCard from '@/components/common/adp-document-card.vue';
import BasicEmptyFeed from '@/components/common/basic-empty-feed.vue';
import { Article, ExecutionInformation } from '@/types';
import { useEmitter } from '@/utils/vue';

type Props = {
  executionInfo: ExecutionInformation;
  domesticArticles: Article[];
  foreignArticles: Article[];
  previousTotalFeedCount: number;
  isLoaded: boolean;
};
const props = withDefaults(defineProps<Props>(), {
  domesticArticles: () => [],
  foreignArticles: () => [],
});

const emitter = useEmitter();

const executionTime = props.executionInfo.executionTime;

const removedArticleIds = ref([] as number[]);
emitter.on('personal-feed-article-removed', (article: Article) => {
  removedArticleIds.value.push(article.id);
});
emitter.on('personal-feed-article-restored', (article: Article) => {
  removedArticleIds.value = removedArticleIds.value.filter(
    articleId => articleId !== article.id,
  );
});

const visibleDomesticArticles = computed(() =>
  props.domesticArticles.filter(a => !removedArticleIds.value.includes(a.id)),
);
const visibleForeignArticles = computed(() =>
  props.foreignArticles.filter(a => !removedArticleIds.value.includes(a.id)),
);
const articleCount = computed(
  () =>
    visibleDomesticArticles.value.length + visibleForeignArticles.value.length,
);
</script>

<template>
  <div>
    <div class="execution-title c-title c-title--s">
      {{ executionTime }}更新
    </div>
    <div class="articles">
      <DgrLoading v-if="!isLoaded" class="loading" />
      <template v-else-if="articleCount > 0">
        <div
          class="personal-feed-article"
          v-for="article in visibleDomesticArticles"
          :key="article.id"
        >
          <div class="divider"></div>
          <AdpDocumentCard
            page-name="home"
            mark-source="personal_feed"
            feature="personal_feed"
            feed-type="domestic"
            :adp-document="article"
            :show-comment-count="1"
            :disable-related-articles="false"
            :execution="executionInfo.execution"
            width="100%"
          ></AdpDocumentCard>
        </div>
        <div
          class="personal-feed-article"
          v-for="article in visibleForeignArticles"
          :key="article.id"
        >
          <div class="divider"></div>
          <AdpDocumentCard
            page-name="home"
            mark-source="personal_feed"
            feature="personal_feed"
            feed-type="foreign"
            :adp-document="article"
            :show-comment-count="1"
            :disable-related-articles="false"
            :execution="executionInfo.execution"
            width="100%"
          ></AdpDocumentCard>
        </div>
        <div class="footer">
          <router-link :to="{ name: 'personalNews' }">
            <button
              class="c-btn c-btn--small c-btn--AnewsPrimary c-text c-text--m"
            >
              もっと見る
            </button>
          </router-link>
        </div>
      </template>
      <div v-else>
        <div class="divider"></div>
        <BasicEmptyFeed>{{ EMPTY_KEYWORD_FEED_MESSAGE }}</BasicEmptyFeed>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.loading {
  margin: 0 auto;
}
.execution-title {
  padding-top: 8px;
}
.articles {
  min-height: 140px;
}
.personal-feed-article {
  .document-card {
    border: 0;
    padding: 0;
  }
}
.divider {
  width: auto;
  border-bottom: 0.5px solid #e6e6e6;
  margin: 8px 0 14px 0;
}
.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
  .c-btn {
    margin-top: 0;
  }
}
</style>
