<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import BasicEmptyFeed from '@/components/common/basic-empty-feed.vue';
import { DocType, FeedType, Theme } from '@/types';
import { useThemeDisabledOption } from '@/utils/composables/useThemeDisabledOption';

export default defineComponent({
  components: { BasicEmptyFeed },
  props: {
    theme: { type: Object as PropType<Theme>, required: true },
    displayFeedType: { type: String as PropType<FeedType>, required: true },
    themeFeedType: { type: String as PropType<FeedType>, required: true },
    docType: { type: String as PropType<DocType>, required: true },
    enableToEditTheme: { type: Boolean, default: false },
  },
  setup(props) {
    const { disabledOption } = useThemeDisabledOption({
      theme: props.theme,
      themeFeedType: props.themeFeedType,
      docType: props.docType,
    });

    const isDomesticMediaDisplayOnChineseTheme = computed(
      () => props.theme.is_chinese && props.displayFeedType == 'domestic',
    );

    return {
      disabledOption,
      isDomesticMediaDisplayOnChineseTheme,
    };
  },
});
</script>

<template>
  <BasicEmptyFeed>
    <template v-if="disabledOption">
      <div class="disabled-message">
        このテーマは{{ disabledOption.name }}の設定が有効になっていません。
      </div>
      <template v-if="enableToEditTheme">
        {{ disabledOption.name }}の情報を取得したい場合は、テーマの<router-link
          :to="{ name: 'themeEdit', params: { themeId: theme.id } }"
          class="theme-edit-link"
          >編集ページ</router-link
        >にある{{ disabledOption.label }}を有効にしてください。
      </template>
    </template>
    <template v-else-if="isDomesticMediaDisplayOnChineseTheme">
      <div class="disabled-message">このテーマは中国語メディア専用です。</div>
      配信記事を見たい場合はメディアの表示を「国内・海外メディア両方」もしくは「海外メディアのみ」に切り替えてください。
    </template>
    <template v-else>
      <slot></slot>
    </template>
  </BasicEmptyFeed>
</template>

<style scoped lang="scss">
.disabled-message {
  margin-bottom: 4px;
}
.theme-edit-link {
  color: #1da482;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
</style>
