import { THEME_RELATED_NEWS_MAX_COUNT } from '@/constants';
import dayjs from 'dayjs';
import {
  FeedType,
  Group,
  Perspective,
  TeamInfo,
  TeamUser,
  Theme,
  ThemeTopic,
  UserInfo,
} from '@/types';
import { formatDate } from './formatters';

type Tab = {
  name: string;
  count?: number | undefined;
  linkTo: {
    name: string;
    params: {
      themeId: number;
      type?: string;
    };
  };
};

const getFeedTabCounts = (
  feedType: FeedType,
  theme: Theme,
  team: TeamInfo | undefined,
) => {
  const counts = {
    feed: undefined as number | undefined,
    related: undefined as number | undefined,
    technical_literature: undefined as number | undefined,
    research_paper: undefined as number | undefined,
    patent: undefined as number | undefined,
    featured_articles: undefined as number | undefined,
  };
  if (theme.feed_last_updated_at) {
    const countAllMedia =
      feedType === 'all' &&
      (team?.enable_foreign_lang_media ?? false) &&
      !theme.is_chinese;
    const feedTypeKey =
      feedType === 'foreign' || (feedType === 'all' && theme.is_chinese)
        ? 'foreign'
        : 'domestic';
    const latestDate = dayjs(theme.feed_last_updated_at).format('YYYY-MM-DD');
    const latestKeywordFeedCounts = theme.keyword_feed_counts[latestDate] ?? {
      domestic: 0,
      foreign: 0,
    };
    const keywordFeedCount = countAllMedia
      ? latestKeywordFeedCounts.domestic + latestKeywordFeedCounts.foreign
      : latestKeywordFeedCounts[feedTypeKey];
    counts.feed = keywordFeedCount;

    const theme_related_feed_counts = {
      domestic: Math.min(
        THEME_RELATED_NEWS_MAX_COUNT,
        theme.related_feed_counts.domestic,
      ),
      foreign: Math.min(
        THEME_RELATED_NEWS_MAX_COUNT,
        theme.related_feed_counts.foreign,
      ),
    };
    const relatedFeedCount = countAllMedia
      ? theme_related_feed_counts.domestic + theme_related_feed_counts.foreign
      : theme_related_feed_counts[feedTypeKey];
    counts.related = relatedFeedCount;

    const researchPaperCount = countAllMedia
      ? theme.research_paper_feed_counts.domestic +
        theme.research_paper_feed_counts.foreign
      : theme.research_paper_feed_counts[feedTypeKey];

    const patentCount = countAllMedia
      ? theme.patent_feed_counts.domestic + theme.patent_feed_counts.foreign
      : theme.patent_feed_counts[feedTypeKey];
    counts.technical_literature =
      (theme.is_research_paper_enabled ? researchPaperCount : 0) +
      (theme.is_patent_enabled ? patentCount : 0);
    counts.research_paper = researchPaperCount;
    counts.patent = patentCount;

    const featuredArticleCount = theme.featured_article_feed_count;
    counts.featured_articles = featuredArticleCount;
  }
  return counts;
};

export const getTabs = (
  feedType: FeedType,
  theme: Theme,
  team: TeamInfo | undefined,
): Tab[] => {
  const tabCounts = getFeedTabCounts(feedType, theme, team);
  const newsTab: Tab = {
    name: 'ニュース',
    count: tabCounts.feed,
    linkTo: {
      name: 'themeFeed',
      params: { themeId: theme.id },
    },
  };
  const relatedNewsTab: Tab = {
    name: '関連ニュース',
    count: tabCounts.related,
    linkTo: {
      name: 'themeRelatedNews',
      params: { themeId: theme.id, type: 'related_articles' },
    },
  };
  const researchPaperTab: Tab = {
    name: '論文',
    count: tabCounts.research_paper,
    linkTo: {
      name: 'themeResearchPapers',
      params: { themeId: theme.id },
    },
  };
  const patentTab: Tab = {
    name: '特許',
    count: tabCounts.patent,
    linkTo: {
      name: 'themePatents',
      params: { themeId: theme.id },
    },
  };

  let relatedFeaturedTabs = [relatedNewsTab];
  const teamEnabledTechnicalLiterature = team?.enable_technical_literature;
  if (teamEnabledTechnicalLiterature) {
    if (theme.is_research_paper_enabled) {
      relatedFeaturedTabs.push(researchPaperTab);
    }
    if (theme.is_patent_enabled) {
      relatedFeaturedTabs.push(patentTab);
    }
  }

  const hasAllTabs = !theme.is_chinese;
  return hasAllTabs ? [newsTab, ...relatedFeaturedTabs] : [newsTab];
};

export const getThemeHeaderDetail = (
  theme: Theme | undefined,
  groups: Group[],
  users: UserInfo[] | TeamUser[],
  showCreatedAt = false,
): { firstLine: string; secondLine: string } => {
  if (!theme) return { firstLine: '', secondLine: '' };
  if (!theme.access_scope) {
    return { firstLine: '', secondLine: '' };
  }
  const scope = `公開範囲：${
    theme.access_scope === 'group'
      ? groups.find(g => g.id === theme.group_id)?.name
      : theme.access_scope === 'personal'
        ? '個人'
        : '全体'
  }`;
  const creator = theme.creator_id
    ? `作成者：${users.find(u => u.id === theme.creator_id)?.user_name}`
    : '';
  const createdAt = showCreatedAt
    ? `作成日：${formatDate(theme.created_at)}`
    : '';
  return {
    firstLine: [scope, createdAt, creator]
      .filter(s => s && s.length > 0)
      .join('・'),
    secondLine: `${theme.description ? `${theme.description}` : ''}`,
  };
};

export const getSelectedQueries = (
  selectedTopics: ThemeTopic[] | undefined,
  selectedPerspective: Perspective | undefined,
  enableForeignLangMedia: boolean,
): string[] => {
  if (!selectedTopics) return [];
  const perspectiveQueryJa = selectedPerspective
    ? selectedPerspective.query_ja
    : '';
  const perspectiveQueryEn = selectedPerspective
    ? selectedPerspective.query_en
    : '';

  return selectedTopics
    .flatMap(x => {
      if (
        perspectiveQueryJa !== '' &&
        perspectiveQueryEn !== '' &&
        x.query_ja !== '' &&
        x.query_en !== ''
      ) {
        return enableForeignLangMedia
          ? [
              `(${x.query_ja}) (${perspectiveQueryJa})`,
              `(${x.query_en}) (${perspectiveQueryEn})`,
            ]
          : [`(${x.query_ja}) (${perspectiveQueryJa})`];
      } else if (perspectiveQueryJa !== '' && x.query_ja !== '') {
        return enableForeignLangMedia
          ? [`(${x.query_ja}) (${perspectiveQueryJa})`, x.query_en]
          : [`(${x.query_ja}) (${perspectiveQueryJa})`];
      } else if (perspectiveQueryEn !== '' && x.query_en !== '') {
        return enableForeignLangMedia
          ? [x.query_ja, `(${x.query_en}) (${perspectiveQueryEn})`]
          : [x.query_ja];
      } else {
        return enableForeignLangMedia ? [x.query_ja, x.query_en] : [x.query_ja];
      }
    })
    .filter(x => !!x);
};
