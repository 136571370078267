<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import { MAX_NAME_LENGTH } from '@/constants';
import { NotificationReplyCommentType } from '@/types';
import { getArticlePageUrl } from '@/utils';
import { formatUserName } from '@/utils/formatters';
import CardTemplate from './card-template.vue';

// WARN: 通知の表記を変更するときは serviceWorkerHelper.ts の変更漏れがないかも確認する
// WARN: 通知のURLを変更するときは web-push.vue の変更漏れがないかも確認する

export default defineComponent({
  components: {
    CardTemplate,
  },
  props: {
    notification: {
      type: Object as PropType<NotificationReplyCommentType>,
      required: true,
    },
    isUnread: { type: Boolean, required: true },
  },
  setup(props) {
    const url = computed(() =>
      props.notification.document
        ? getArticlePageUrl(
            props.notification.document,
            props.notification.comment.id,
          )
        : `/comments/${props.notification.comment.id}`,
    );
    const formattedUserName = computed(() => {
      return formatUserName(
        props.notification.creator_name,
        props.notification.is_deleted_creator,
        MAX_NAME_LENGTH,
      );
    });

    return {
      url,
      formattedUserName,
    };
  },
});
</script>
<template>
  <CardTemplate
    :type="notification.type"
    :url="url"
    :is-unread="isUnread"
    :created-at="notification.created_at"
    :user-id="notification.creator_id"
    :image-url="notification.creator_image_url"
    :content-title="notification.document?.title"
    :preview="notification.comment.content"
    :group-name="notification.group.name"
  >
    {{ formattedUserName }}さんがあなたのコメントに返信しました。
  </CardTemplate>
</template>
