<script setup lang="ts">
import { DgrDialog } from '@stockmarkteam/donguri-ui';

interface Props {
  progress: number;
  errorText?: string;
}
withDefaults(defineProps<Props>(), {
  errorText: undefined,
});

const isOpen = defineModel<boolean>('isOpen', {
  required: true,
  default: false,
});

const closeModal = () => {
  isOpen.value = false;
};
</script>

<template>
  <DgrDialog
    v-model:open="isOpen"
    :title="'アップロード処理中'"
    :close-on-escape="false"
    :close-on-interact-outside="false"
    :modal="false"
  >
    <template #description>
      <div class="modal-body">
        <div class="progress-bar-wrapper">
          <span class="progress-text">{{ progress }}%</span>
          <progress class="progress-bar" :value="progress" max="100"></progress>
        </div>
        <div v-if="errorText" class="error-text c-text c-text--s">
          {{ errorText }}
        </div>
      </div>
    </template>
    <template #footer>
      <button
        class="modal-cancel-button c-btn c-btn--auto c-btnOutline"
        @click="closeModal"
      >
        キャンセル
      </button>
    </template>
  </DgrDialog>
</template>

<style lang="scss" scoped>
.modal-header {
  padding: 5px 0px;
}
.modal-body {
  width: 568px;
  .progress-bar-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .progress-text {
      margin: 18px 0;
      width: 60px;
    }

    .progress-bar {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;

      margin: auto 0 auto 18px;
      height: 8px;
      width: 100%;
      border: none;
      border-radius: 4px;
      background-color: $color-gray400;
      color: $color-green600;
    }

    // Chrome等
    .progress-bar::-webkit-progress-bar {
      background: transparent;
    }

    .progress-bar::-webkit-progress-value {
      border-radius: 4px;
      background-color: $color-green600;
    }

    // Firefox
    .progress-bar::-moz-progress-bar {
      background-color: $color-green600;
      border-radius: 4px;
    }
  }

  .error-text {
    color: $color-orange1000;
  }
}
</style>
