import { VueGtag } from 'vue-gtag';
import { Router } from 'vue-router';
import api from '@/api';
import store from '@/stores/pcStore';
import { clearSWRVCache } from './swr';
import { userSession } from './userSession';
import { resetWicle } from './wicle';

export default async function signout(
  gtag: VueGtag,
  router: Router,
  redirectPath: string | undefined = undefined,
) {
  userSession.clear(gtag);
  clearSWRVCache();
  store.commit('resetState');
  resetWicle();
  try {
    await api.signout();
  } finally {
    // セッション切れでログアウト処理になる場合、
    // api.signout()が401エラーになるので
    // finallyの中でログインページへの遷移を行う
    await router.push({
      name: 'login',
      query: {
        redirect: redirectPath,
      },
    });
  }
}
