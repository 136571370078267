<script lang="ts">
import {
  defineComponent,
  onMounted,
  onUnmounted,
  PropType,
  Ref,
  UnwrapRef,
} from 'vue';
import { Feature, PageName } from '@/api/tracking';
import AdpDocumentCard from '@/components/common/adp-document-card.vue';
import SwrScrollingPagination from '@/components/common/swr-scrolling-pagination.vue';
import { Comment, Group } from '@/types';
import { useGroupComments } from '@/utils/swr';
import { useEmitter } from '@/utils/vue';

export default defineComponent({
  props: {
    pageName: { type: String as PropType<PageName>, required: true },
    feature: String as PropType<Feature>,
    showGroup: { type: Boolean, default: false },
    pageLimit: { type: Number, default: 10 },
    paginationFunc: {
      type: Function as PropType<
        (
          pageRef: Ref<number>,
          pageLimit: number,
        ) => ReturnType<typeof useGroupComments>
      >,
      required: true,
    },
    dataAccessor: {
      type: Function as PropType<
        (
          d: UnwrapRef<ReturnType<typeof useGroupComments>['data']>,
        ) => Comment[] | undefined
      >,
      required: true,
    },
    group: { type: Object as PropType<Group> },
  },
  components: { AdpDocumentCard, SwrScrollingPagination },
  setup() {
    const emitter = useEmitter();

    const deleteComment = (comments: Comment[], id: Comment['id']) => {
      const parentIndex = comments.findIndex(c => c.id === id);
      if (parentIndex >= 0) {
        comments.splice(parentIndex, 1);
      } else {
        comments.forEach(comment => {
          const idx = comment.children?.findIndex(child => {
            return child.id === id;
          });
          if (idx >= 0) {
            comment.children.splice(idx, 1);
          }
        });
      }
    };

    const commentFilter = (comment: Comment, id: Comment['id']) => {
      return (
        comment.id === id ||
        comment.children?.some(child => {
          return child.id === id;
        })
      );
    };

    const updateComments = (comments: Comment[], updatedComment: Comment) => {
      const index = comments.findIndex(c => {
        return c.id === updatedComment.id;
      });
      if (index >= 0) {
        comments[index].content = updatedComment.content;
      } else {
        comments.forEach(c => {
          const idx = c.children?.findIndex(
            child => child.id === updatedComment.id,
          );
          if (idx >= 0) {
            c.children[idx].content = updatedComment.content;
          }
        });
      }
    };

    const handleDelete = (id: Comment['id']) => {
      emitter.emit('pagination-item-delete', (items: Comment[]) =>
        deleteComment(items, id),
      );
    };
    const handleUpdate = (comment: Comment) => {
      emitter.emit('pagination-items-update', {
        filterFunc: (i: Comment) => commentFilter(i, comment.id),
        updateFunc: (items: Comment[]) => updateComments(items, comment),
      });
    };

    onMounted(() => {
      emitter.on('comment-deleted', handleDelete);
      emitter.on('comment-updated', handleUpdate);
    });

    onUnmounted(() => {
      emitter.off('comment-deleted', handleDelete);
      emitter.off('comment-updated', handleUpdate);
    });

    return {};
  },
});
</script>

<template>
  <div class="group-comment-list">
    <SwrScrollingPagination
      :page-limit="pageLimit"
      :pagination-func="paginationFunc"
      :data-accessor="dataAccessor"
    >
      <template v-slot="{ items, loaded }">
        <div
          class="group-comment-card"
          v-for="comment in items"
          :key="comment.id"
        >
          <AdpDocumentCard
            class="article-card"
            :adp-document="comment.article"
            :theme-id="comment.theme_id"
            :comment="comment"
            :group="group"
            :page-name="pageName"
            :feature="feature"
          ></AdpDocumentCard>
        </div>
        <slot name="emptyState" v-if="loaded && items.length === 0"></slot>
      </template>
    </SwrScrollingPagination>
  </div>
</template>

<style lang="scss" scoped>
.group-comment-list {
  .group-comment-card {
    margin-bottom: 12px;
  }
}
</style>
