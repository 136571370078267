const LOWERCASE = RegExp(/[a-z]+/);
const UPPERCASE = RegExp(/[A-Z]+/);
const NUMBERS = RegExp(/[0-9]+/);

export const MIN_PASSWORD_LENGTH = 12;
export const VALID_SYMBOLS = [
  '(',
  ')',
  '{',
  '}',
  '[',
  ']',
  '>',
  '<',
  '?',
  '"',
  '!',
  '@',
  '#',
  '%',
  '&',
  '/',
  '\\',
  ',',
  "'",
  ':',
  ';',
  '|',
  '_',
  '~',
  '`',
  '=',
  '$',
  '^',
  '*',
  '+',
  '-',
  '.',
];

const ESCAPE_CHARACTERS = [']', '\\'];

const SYMBOLS_FOR_REGEX = VALID_SYMBOLS.map(s =>
  ESCAPE_CHARACTERS.includes(s) ? `\\${s}` : s,
).join('');

const SYMBOLS = RegExp(`[${SYMBOLS_FOR_REGEX}]+`);
const ALL_VALID_CHARACTERS = RegExp(`[a-zA-Z0-9${SYMBOLS_FOR_REGEX}]+`, 'g');

export const NOT_USING_REQUIRED_TYPE_CHARACTERS_MESSAGE =
  '小文字の英字、大文字の英字、数字、記号をそれぞれ１文字以上使用してください。';

export const USING_INVALID_CHARACTER_MESSAGE =
  '使用できない文字が含まれています。';

export const TOO_SHORT_PASSWORD_MESSAGE = `${MIN_PASSWORD_LENGTH}文字以上入力してください。`;

export const isPasswordValid = (password: string, validationPassword: string) =>
  password.length > 0 &&
  password === validationPassword &&
  isPasswordLengthValid(password) &&
  isPasswordContentValid(password) &&
  isPasswordOnlyValidCharacters(password);

export const isPasswordLengthValid = (password: string) =>
  password.length >= MIN_PASSWORD_LENGTH;

export const isPasswordContentValid = (password: string) =>
  LOWERCASE.test(password) &&
  UPPERCASE.test(password) &&
  NUMBERS.test(password) &&
  SYMBOLS.test(password);

export const isPasswordOnlyValidCharacters = (password: string) =>
  password.replaceAll(ALL_VALID_CHARACTERS, '').length === 0;
