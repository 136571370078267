<script setup lang="ts">
import { useRoute } from 'vue-router';

const route = useRoute();
const params = route.params;
const articleId = params.articleId;
const docType = params.docType;
const queryString = route.fullPath.split('?')[1];
const redirectUrl = `/api/v1/redirect/${docType}/${articleId}?${queryString}`;
location.href = redirectUrl;
</script>

<template>
  <div></div>
</template>
