<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { MAX_ARTICLE_TAG_LENGTH, MAX_TAG_NAME_LENGTH } from '@/constants';
import TagItem from '@/components/common/atoms/tag.vue';
import { Tag } from '@/types';
import TextCounter from '../molecules/text-counter.vue';

interface Props {
  tagList?: Tag[];
  modelValue?: string[];
  placeholder?: string;
  isEditing?: boolean;
  isGroup: boolean;
  useLabel?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  tagList: () => [],
  modelValue: () => [],
  placeholder: '',
  isEditing: false,
  useLabel: true,
});

const emit = defineEmits<{
  'update:modelValue': [tags: string[]];
}>();

const inputValue = ref('');
const listVisible = ref(false);
const emptyTag = ref(false);
const inputTag = ref<HTMLInputElement>();

const tagName = computed(() => {
  return props.isGroup ? 'グループタグ' : 'マイタグ';
});

const clickEventAction = (e: Event) => {
  const target = e.target as Node | null;
  if (inputTag.value && !inputTag.value?.contains(target)) {
    hideList();
  }
};

const showList = () => {
  listVisible.value = true;
  document.addEventListener('click', clickEventAction);
  const modal = document.querySelector('.dialog-container');
  if (modal) {
    modal.addEventListener('click', clickEventAction);
  }
};

const hideList = () => {
  listVisible.value = false;
  document.removeEventListener('click', clickEventAction);
  const modal = document.querySelector('.dialog-container');
  if (modal) {
    modal.removeEventListener('click', clickEventAction);
  }
};

const addItem = (name?: string) => {
  if (tooLongTag.value) {
    return;
  }
  if (!name && inputValue.value === '') {
    emptyTag.value = true;
    return;
  }
  if (selectedTagList.value.indexOf(inputValue.value) > -1) {
    return;
  }
  let currentSelectedTags = selectedTagList.value;
  if (name) {
    currentSelectedTags.push(name);
  } else {
    currentSelectedTags.push(inputValue.value);
  }
  emit('update:modelValue', currentSelectedTags);
  inputTag.value?.blur();
  inputValue.value = '';
  hideList();
};

const removeItem = (name: string) => {
  let currentSelectedTags = selectedTagList.value;
  currentSelectedTags = currentSelectedTags.filter(tagName => tagName !== name);
  emit('update:modelValue', currentSelectedTags);
};

const filteredList = computed(() => {
  return props.tagList.filter(
    i =>
      !selectedTagList.value.includes(i.name) &&
      i.name.includes(inputValue.value),
  );
});

const selectedTagList = computed(() => {
  return props.modelValue;
});

const isEnableTagInput = computed(() => {
  return selectedTagList.value.length < MAX_ARTICLE_TAG_LENGTH;
});

const tooLongTag = computed(() => {
  return inputValue.value.length > MAX_TAG_NAME_LENGTH;
});

const errorMessage = computed(() => {
  let message = '';
  if (tooLongTag.value) {
    message = `${tagName.value}は${MAX_TAG_NAME_LENGTH}文字以内で入力してください。`;
  } else if (emptyTag.value) {
    message = '文字を入力してください';
    return message;
  }
  return message;
});

const emptyErrorMessage = computed(() => {
  if (emptyTag.value) {
    return '文字を入力してください';
  }
  return undefined;
});

watch(
  () => props.isEditing,
  () => {
    inputValue.value = '';
    if (!props.isEditing) {
      hideList();
    }
  },
);

watch(
  () => inputValue.value,
  () => {
    if (inputValue.value !== '') {
      emptyTag.value = false;
    }
  },
);
</script>

<template>
  <div class="m-input-tag">
    <div class="c-formBlock" v-if="isEditing">
      <div class="c-formBlock__label" v-if="useLabel">
        {{ tagName }}
      </div>
      <div
        class="c-formBlock__text c-formBlock__text--error"
        v-if="tooLongTag || emptyTag"
      >
        {{ errorMessage }}
      </div>
      <input
        class="m-input-tag__input c-textInput"
        :class="{ 'c-formInput--error': tooLongTag || emptyTag }"
        type="text"
        v-model.trim="inputValue"
        :placeholder="placeholder"
        ref="inputTag"
        :disabled="!isEnableTagInput"
        @click.stop.prevent="() => undefined"
        @focus="showList"
        @keypress.enter="addItem()"
      />
      <TextCounter
        v-if="isEnableTagInput"
        :max-length="MAX_TAG_NAME_LENGTH"
        :custom-error-message="emptyErrorMessage"
        :text="inputValue"
      />
    </div>
    <div class="m-input-tag__list" v-if="listVisible">
      <div class="item disabled" v-if="filteredList.length === 0">
        <div class="info">
          <div class="name c-text c-text--m">{{ tagName }}がありません</div>
        </div>
      </div>
      <template v-else>
        <div
          class="item"
          v-for="item in filteredList"
          :key="item.name"
          @click.stop.prevent="addItem(item.name)"
        >
          <div class="info">
            <div class="name c-text c-text--m">{{ item.name }}</div>
          </div>
          <div class="count c-text c-text--m">{{ item.count }}</div>
        </div>
      </template>
    </div>
    <div class="selected-items">
      <div
        class="item"
        v-for="selectedTagName in selectedTagList"
        :key="selectedTagName"
      >
        <TagItem
          :name="selectedTagName"
          :is-editing="isEditing"
          @delete-tag="removeItem(selectedTagName)"
        ></TagItem>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.m-input-tag {
  box-sizing: border-box;
  position: relative;
  .m-input-tag__input {
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    outline: none;
  }
  .m-input-tag__list {
    box-sizing: border-box;
    position: absolute;
    z-index: var(--z-dropdown);
    max-height: 160px;
    width: 100%;
    overflow-y: scroll;
    background-color: #ffffff;
    border-radius: 4px;
    border-left: 1px solid #e6e6e6;
    border-right: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
    padding: 16px 0;

    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 12px;
      &.disabled {
        cursor: default;
        color: #b3b3b3;
        padding: 0 12px;
      }
      &:not(.disabled):hover {
        cursor: pointer;
        background: #f2f2f2;
      }
    }
    .info {
      width: calc(100% - 32px);
      .name {
        width: 100%;
        word-wrap: nowrap;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .count {
      color: #b3b3b3;
    }
  }

  .selected-items {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin-top: 12px;
    .item {
      display: inline-block;
      position: relative;
      cursor: default;
      margin-bottom: 8px;
      margin-right: 8px;
      max-width: 100%;
    }
    .info {
      .name {
        word-wrap: nowrap;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.tag-error-txt {
  text-align: end;
  color: $color-orange1000;
  font-size: 10px;
}
</style>
