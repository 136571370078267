<script setup lang="ts">
import { computed, onUnmounted, reactive, Ref, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import api from '@/api';
import { DgrIcon } from '@stockmarkteam/donguri-ui';
import SearchBar from '@/components/common/molecules/search-bar.vue';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import GroupHeader from '@/components/group/group-header.vue';
import Content from '@/components/layouts/content.vue';
import {
  Group,
  GroupUser,
  UserEntityWithAddUserOptions,
  UserRole,
} from '@/types';
import { hasEditPermission, hasMemberPermission } from '@/utils/group';
import {
  STATES,
  useGroups,
  useGroupUsers,
  useTeamUsersWithAddUserOptions,
  useUserInfo,
} from '@/utils/swr';
import AddUsersModal, { AddableUser } from '../modals/add-users-modal.vue';

interface Props {
  groupId: string;
  isMenuVisible?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  isMenuVisible: true,
});

const router = useRouter();
const { createSnackbar } = useSnackbar();

const groupId = computed(() => Number(props.groupId));
const { data: groups } = useGroups();
const { data: userInfo, state } = useUserInfo();
const { data: teamUsers, state: teamUsersState } =
  useTeamUsersWithAddUserOptions();
const {
  data: groupUsers,
  error: groupUsersError,
  mutate: mutateGroupUsers,
  state: groupUsersState,
} = useGroupUsers(groupId);
const groupMembers = computed(() => groupUsers.value?.group_users ?? []);

const isOpenAddUsersModal = ref(false);

onUnmounted(() => {
  isOpenAddUsersModal.value = false;
});

const openAddUsersModal = async () => {
  isOpenAddUsersModal.value = true;
};

const addableUserList = computed<AddableUser[]>(() => {
  const alreadyAddedUserIds = groupMembers.value.map(
    (groupUser: GroupUser) => groupUser.id,
  );
  return (
    teamUsers.value?.users.filter(teamUser => {
      return (
        !alreadyAddedUserIds.includes(teamUser.id) &&
        userInfo.value?.id !== teamUser.id
      );
    }) ?? []
  );
});

const isAddModalLoading = computed(
  () =>
    groupUsersState.value === STATES.PENDING ||
    groupUsersState.value === STATES.VALIDATING ||
    teamUsersState.value === STATES.PENDING ||
    teamUsersState.value === STATES.VALIDATING,
);

const group = computed(
  () =>
    (groups.value ?? { groups: [] }).groups.find(g => g.id === groupId.value) ??
    ({} as Group),
);

const isNotAllUserGroup = computed(
  () => group.value.group_type !== 'all_user_group',
);

const filteredGroupMembers = computed(() =>
  filterMembers(groupMembers, filterQuery),
);

const useSort = () => {
  const sortState = reactive<SortState>({
    user_name: '',
    email: '',
    role: '',
  });

  const sortByArgumentProperty = (property: keyof SortState) => {
    changeSortState(property);
  };

  const changeSortState = (property: keyof SortState) => {
    if (sortState[property] === 'asc') {
      sortState[property] = 'desc';
    } else if (sortState[property] === 'desc') {
      sortState[property] = 'asc';
    } else {
      Object.keys(sortState).forEach(key => {
        sortState[key as keyof SortState] = '';
      });
      sortState[property] = 'asc';
    }
  };

  const sort = (groupMembers: GroupUser[]) => {
    const property = Object.keys(sortState).filter(
      key => sortState[key as keyof SortState] !== '',
    )[0] as keyof SortState | undefined;
    if (!property) {
      return groupMembers;
    }

    if (property === 'role') {
      const result = groupMembers.slice();
      result.sort((u1, u2) => {
        const [a, b] = sortState[property] == 'asc' ? [u1, u2] : [u2, u1];
        return roleOrder[a.role] - roleOrder[b.role];
      });
      return result;
    }

    let membersHavingTargetProperty = groupMembers.filter(
      member => member[property] !== '',
    );
    let membersNotHavingTargetProperty = groupMembers.filter(
      member => member[property] === '',
    );

    if (sortState[property] === 'asc') {
      membersHavingTargetProperty.sort((x, y) =>
        x[property].localeCompare(y[property], 'ja'),
      );
    } else {
      membersHavingTargetProperty.sort(
        (x, y) => -x[property].localeCompare(y[property], 'ja'),
      );
    }

    return membersHavingTargetProperty.concat(membersNotHavingTargetProperty);
  };

  const sortedBy = (property: keyof SortState) => {
    return sortState[property];
  };

  return {
    sortByArgumentProperty,
    sortedBy,
    sort,
  };
};

const { sortByArgumentProperty, sortedBy, sort } = useSort();
const sortedGroupMembers = computed(() => sort(filteredGroupMembers.value));

const authority = (user: GroupUser) => {
  return {
    admin: 'グループ管理者',
    group_admin: 'グループ管理者',
    editor: '編集者',
    viewer: '閲覧者',
  }[user.role];
};

const useMenu = (
  groupId: number,
  mutateGroupUsersCallback: (userId: number) => void,
) => {
  const onEditButton = ref(false);
  const editMenuOpen = ref(false);
  const selectedUserId = ref(-1);

  const toggleOnEditButton = () => {
    onEditButton.value = !onEditButton.value;
  };

  const editMenu = (member: GroupUser) => {
    selectedUserId.value = member.id;
    editMenuOpen.value = !editMenuOpen.value;
  };

  const closeEditMenu = () => {
    editMenuOpen.value = false;
  };

  const removeMember = async () => {
    onEditButton.value = false;
    await api.leaveGroup(groupId, selectedUserId.value);
    mutateGroupUsersCallback(selectedUserId.value);
  };

  return {
    onEditButton,
    editMenuOpen,
    selectedUserId,
    toggleOnEditButton,
    editMenu,
    closeEditMenu,
    removeMember,
  };
};

const {
  onEditButton,
  editMenuOpen,
  editMenu,
  closeEditMenu,
  selectedUserId,
  toggleOnEditButton,
  removeMember,
} = useMenu(groupId.value, userId => {
  mutateGroupUsers(() => ({
    group_users: groupMembers.value.filter(member => member.id !== userId),
  }));
  createSnackbar({
    message: 'メンバーを更新しました',
    type: 'success',
  });
});

watch(groupUsersError, to => {
  if (!to) {
    return;
  }
  router.push({
    name: 'groupActivity',
  });
});

watch(
  () => group.value,
  () =>
    group.value && !hasMemberPermission(group.value, userInfo.value)
      ? router.push({ name: 'groupList' })
      : undefined,
);

const filterQuery = ref('');
const onChangeQuery = (query: string) => {
  filterQuery.value = query;
};

const groupMemberAdded = (users: UserEntityWithAddUserOptions[]) => {
  const existingUserIds = groupMembers.value.map((u: GroupUser) => u.id);
  const newUsers = users.filter(user => !existingUserIds.includes(user.id));
  mutateGroupUsers(() => ({
    group_users: groupMembers.value.concat(
      newUsers.map(u => ({
        id: u.id,
        user_name: u.user_name,
        email: u.email,
        role: u.role,
        last_viewed_at: '',
        created_at: u.created_at,
        updated_at: u.updated_at,
      })),
    ),
  }));
};

const closeAddUsersModal = () => {
  isOpenAddUsersModal.value = false;
};

const addUsers = async (userIds: number[]) => {
  try {
    await api.createGroupInvitation(groupId.value, userIds);
    const addUsers =
      teamUsers.value?.users.filter(u => userIds.includes(u.id)) ?? [];
    createSnackbar({
      message: `グループに招待しました`,
      type: 'success',
    });
    groupMemberAdded(addUsers);
    closeAddUsersModal();
  } catch (err) {
    createSnackbar({
      message: `グループに招待できませんでした`,
      type: 'error',
    });
    throw err;
  }
};

const isShowContractOption = computed(() => {
  return (
    (teamUsers.value?.users.some(
      user => user.managed_contract_name_by_current_user !== '',
    ) ??
      false) &&
    userInfo.value?.role === 'admin'
  );
});

type SortState = {
  user_name: '' | 'asc' | 'desc';
  email: '' | 'asc' | 'desc';
  role: '' | 'asc' | 'desc';
};

const roleOrder: { [key in UserRole]: number } = {
  admin: 1,
  group_admin: 2,
  editor: 3,
  viewer: 4,
} as const;

const filterMembers = (groupMembers: Ref<GroupUser[]>, query: Ref<string>) => {
  if (query.value === '') {
    return groupMembers.value;
  }
  return groupMembers.value.filter(user => {
    let searchTargetWord = Object.values(user).join().toLowerCase();
    return searchTargetWord.includes(query.value.toLowerCase());
  });
};
</script>

<template>
  <div
    class="o-group-members"
    v-if="state === STATES.SUCCESS && userInfo"
    @click="closeEditMenu"
    @keydown.esc="closeEditMenu"
  >
    <GroupHeader
      :group="group"
      :user-info="userInfo"
      :is-menu-visible="isMenuVisible"
    />
    <Content>
      <div class="o-group-members-wrap">
        <div class="o-header">
          <div class="o-group-search-info">
            <SearchBar @on-change-query="onChangeQuery"></SearchBar>
          </div>
          <button
            class="o-add-member-button c-btn c-btn--primary"
            v-if="isNotAllUserGroup && hasEditPermission(group, userInfo)"
            @click="openAddUsersModal()"
          >
            メンバー追加
          </button>
        </div>
        <div class="o-group-members-body">
          <table class="o-member-body">
            <thead>
              <tr class="o-column-description">
                <th
                  class="m-member-name"
                  @click="sortByArgumentProperty('user_name')"
                >
                  <div class="header-content">
                    名前<span
                      class="o-sort-icon"
                      v-if="sortedBy('user_name') === 'asc'"
                      >↑</span
                    ><span
                      class="o-sort-icon"
                      v-if="sortedBy('user_name') === 'desc'"
                      >↓</span
                    >
                  </div>
                </th>
                <th
                  class="m-mail-address"
                  @click="sortByArgumentProperty('email')"
                >
                  <div class="header-content">
                    メールアドレス<span
                      class="o-sort-icon"
                      v-if="sortedBy('email') === 'asc'"
                      >↑</span
                    ><span
                      class="o-sort-icon"
                      v-if="sortedBy('email') === 'desc'"
                      >↓</span
                    >
                  </div>
                </th>
                <th
                  class="m-authority"
                  @click="sortByArgumentProperty('role')"
                  v-if="isNotAllUserGroup"
                >
                  <div class="header-content">
                    権限<span
                      class="o-sort-icon"
                      v-if="sortedBy('role') === 'asc'"
                      >↑</span
                    ><span
                      class="o-sort-icon"
                      v-if="sortedBy('role') === 'desc'"
                      >↓</span
                    >
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="o-member-list-row"
                v-for="member in sortedGroupMembers"
                :key="member.id"
                :class="{ 'on-edit-button': onEditButton }"
              >
                <td class="m-member-name c-text c-text--m">
                  <router-link :to="`/users/${member.id}`"
                    >{{ member.user_name }}
                  </router-link>
                </td>
                <td class="m-authority c-text c-text--m">
                  {{ member.email }}
                </td>
                <td
                  class="m-authority c-text c-text--m"
                  v-if="isNotAllUserGroup"
                >
                  {{ authority(member) }}
                </td>
                <td
                  class="m-edit-icon"
                  v-if="hasEditPermission(group, userInfo) && isNotAllUserGroup"
                >
                  <div
                    class="m-edit-menu-ellipsis"
                    v-if="userInfo && userInfo.id !== member.id"
                    @mouseover="toggleOnEditButton"
                    @mouseout="toggleOnEditButton"
                    @click.stop="editMenu(member)"
                  >
                    <DgrIcon name="ellipsis-h" />
                    <div
                      class="o-user-edit-menu c-text c-text--m"
                      v-if="editMenuOpen && selectedUserId === member.id"
                      @keydown.esc="closeEditMenu"
                    >
                      <div class="o-items-wrapper">
                        <div
                          class="o-remove-member c-text c-text--m"
                          @click="removeMember"
                        >
                          グループから外す
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Content>
    <AddUsersModal
      :is-open="isOpenAddUsersModal"
      :joinable-users="addableUserList"
      @on-close="closeAddUsersModal"
      @on-submit="addUsers"
      :is-use-contract-option="isShowContractOption"
      :is-loading="isAddModalLoading"
    />
  </div>
</template>

<style lang="scss" scoped>
.o-group-members {
  width: 100%;
  height: calc(100vh - 144px);
  margin: -24px 0 0 0;
}

.o-header-content {
  width: 616px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.o-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  button.o-add-member-button {
    margin-left: 16px;
    width: 120px;
    flex-shrink: 0;
  }
}

.o-group-members-body {
  background-color: white;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 16px;
  padding: 16px;
}

.o-member-body {
  border-spacing: 0;
}

.o-group-members-nav {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;

  .o-nav-group-name {
    font-size: 14px;
  }
}

.o-group-search-info {
  display: flex;
  align-items: center;
  width: 100%;
}

.o-group-members-wrap {
  margin-bottom: 32px;
}

.o-chevron-right {
  margin: 0 8px;
}

.o-group-info {
  margin-top: 2px;
  color: #717171;
}

.edit-link {
  margin-left: 12px;
  padding: 4px 8px;
  border-radius: 4px;
  color: #1da482;

  &:hover {
    background: #f2f2f2;
  }
}

table {
  width: 100%;
  border: none;
}

th {
  box-sizing: border-box;
  white-space: nowrap;
  text-align: start;
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  cursor: pointer;

  .header-content {
    display: flex;
    justify-content: space-between;
    padding: 14px 16px;
    border: none;
    border-radius: 4px;
    margin-bottom: 6px;
  }

  &:hover {
    .header-content {
      background-color: #f2f2f2;
    }
  }

  span.o-sort-icon {
    padding: 0 4px;
  }
}

.o-sort-icon-spacing {
  padding-right: 20px;
}

td {
  border-top: 1px solid #e6e6e6;
  padding: 0 16px;
  box-sizing: border-box;

  &.m-member-name {
    padding: 14px 16px;

    &:hover {
      text-decoration: underline;
    }
  }
}

.m-member-name {
  width: 20%;
}

.m-mail-address {
  width: 60%;
}

.m-authority {
  width: 20%;
}

.m-edit-icon {
  width: 20%;
  padding: 0;
}

tr.o-member-list-row {
  &:hover {
    &:not(.on-edit-button) {
      background-color: #f2f2f2;
    }
  }
}

.m-edit-menu-ellipsis {
  display: flex;
  justify-content: center;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;

  &:hover {
    background-color: #f2f2f2;
  }
}

.o-user-edit-menu {
  position: absolute;
  top: -2px;
  right: 2px;
  width: 140px;
  padding: 12px 0;
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(74, 74, 74, 0.25);
  border-radius: 4px;
  z-index: var(--z-action-menu);

  .o-items-wrapper {
    margin-top: -3px;

    > * {
      padding: 4px 12px;

      &:not(.disabled) {
        cursor: pointer;

        &:hover {
          background-color: #e6e6e6;
        }
      }

      &.disabled {
        color: #b3b3b3;
        cursor: default;
      }
    }
  }
}
</style>
