<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import { MAX_TOOLTIP_LIST_LENGTH } from '@/constants';
import CommentReaction from '@/components/common/comment/comment-reaction.vue';
import { Comment, CommentReaction as CommentReactionType } from '@/types';
import { processCommentEmojiReactions } from '@/utils';
import { useStore } from '@/utils/vue';

export default defineComponent({
  components: {
    CommentReaction,
  },
  props: {
    comment: { type: Object as PropType<Comment>, required: true },
  },
  emits: {
    click: (_payload: { comment: Comment; reaction: string }) => true,
  },
  setup(props, context) {
    const store = useStore();
    const userInfo = computed(() => store.state.userInfo.userInfo);

    const onClickEmojiReaction = (reaction: string): void => {
      context.emit('click', { comment: props.comment, reaction: reaction });
    };

    const processEmojiReactions = computed<CommentReactionType[]>(() => {
      return processCommentEmojiReactions(props.comment.emoji_reactions);
    });

    const containsMyReaction = (users: { id: number }[]): boolean => {
      const userIds = users.map(user => user.id);
      return userIds.some(id => id === userInfo.value?.id);
    };

    return {
      onClickEmojiReaction,
      processEmojiReactions,
      containsMyReaction,
      MAX_TOOLTIP_LIST_LENGTH,
    };
  },
});
</script>

<template>
  <div class="m-comment-reaction-list">
    <div
      class="m-comment-reaction-wrap"
      @click.prevent="onClickEmojiReaction(reaction.reaction)"
      v-for="reaction in processEmojiReactions"
      :key="reaction.reaction"
    >
      <VTooltip theme="menu" placement="top">
        <CommentReaction
          :is-reacted-by-me="containsMyReaction(reaction.users)"
          :count="reaction.users.length"
          :reaction="reaction.reaction"
        ></CommentReaction>
        <template #popper>
          <template v-if="reaction.users.length > 0">
            <div
              v-for="user in reaction.users.slice(0, MAX_TOOLTIP_LIST_LENGTH)"
              :key="user.id"
            >
              <router-link
                :to="{
                  name: 'userProfile',
                  params: { userId: user.id },
                }"
              >
                ・{{ user.name }}
              </router-link>
            </div>
          </template>
          <template v-if="reaction.users.length > MAX_TOOLTIP_LIST_LENGTH">
            <span
              >他{{ reaction.users.length - MAX_TOOLTIP_LIST_LENGTH }}件</span
            >
          </template>
        </template>
      </VTooltip>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.m-comment-reaction-list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  .m-comment-reaction-wrap {
    margin: 8px 4px 0 0;
  }
}
</style>
