import { Module } from 'vuex';
import { FeedType } from '@/types';
import { userSession } from '@/utils/userSession';

export type FeedTypeState = {
  feedType: FeedType;
};

export const initialFeedTypeState: FeedTypeState = {
  feedType: userSession.getFeedType(),
};

const feedType: Module<FeedTypeState, unknown> = {
  namespaced: true,
  state: {
    feedType: userSession.getFeedType(),
  },
  mutations: {
    setFeedType(state, feedType) {
      state.feedType = feedType;
    },
  },
  actions: {
    async updateFeedType({ commit }, feedType) {
      userSession.setFeedType(feedType);
      await commit('setFeedType', feedType);
    },
  },
};

export default feedType;
