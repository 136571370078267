<script setup lang="ts">
import { ref, StyleValue } from 'vue';

interface Props {
  position?: string;
  top?: string;
  left?: string;
  right?: string;
  bottom?: string;
  size?: number;
  showText?: boolean;
  text?: string;
}

const props = withDefaults(defineProps<Props>(), {
  position: 'absolute',
  size: 8,
  showText: false,
});

const markStyle = ref({
  position: props.position,
  top: props.top,
  left: props.left,
  right: props.right,
  bottom: props.bottom,
  'min-width': `${props.size}px`,
  'min-height': `${props.size}px`,
  'border-radius': `${props.size}px`, // 直径と同じ値を指定することで丸になる
});
</script>

<template>
  <div class="notification-mark" :style="markStyle as StyleValue">
    <span
      class="notification-mark-text"
      :style="{ lineHeight: `${props.size / 2}px` }"
      v-if="showText"
      >{{ text }}</span
    >
  </div>
</template>

<style lang="scss" scoped>
.notification-mark {
  color: white;
  background-color: $color-orange1000;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.notification-mark-text {
  padding: 4px;
  font-size: 10px;
}
</style>
