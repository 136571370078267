<script lang="ts" setup>
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import api from '@/api/index';
import SettingToggleCard from '@/components/common/atoms/setting-toggle-card.vue';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import Content from '@/components/layouts/content.vue';
import Header from '@/components/layouts/header.vue';
import { useUserSettingsNews } from '@/utils/swr';

const route = useRoute();
const { createSnackbar } = useSnackbar();

const { data: userSettings, mutate } = useUserSettingsNews();

const enableTranslateArticleTitle = ref(false);
const enableHideLoginRequiredArticle = ref(false);
const enablePersonalNewsEntertainmentFilter = ref(false);

watch(
  userSettings,
  settings => {
    if (!settings) return;
    enableTranslateArticleTitle.value = settings.enable_translate_article_title;
    enableHideLoginRequiredArticle.value =
      settings.enable_hide_login_required_article;
    enablePersonalNewsEntertainmentFilter.value =
      settings.enable_personal_news_entertainment_filter;
  },
  { immediate: true },
);

const submit = async () => {
  if (!userSettings.value) return;

  try {
    await api.updateUserSettingsNews(
      {
        ...userSettings.value,
        enable_translate_article_title: enableTranslateArticleTitle.value,
        enable_hide_login_required_article:
          enableHideLoginRequiredArticle.value,
        enable_personal_news_entertainment_filter:
          enablePersonalNewsEntertainmentFilter.value,
      },
      {
        pageName: 'news',
        pageUrl: route.fullPath,
      },
    );
    mutate();
    createSnackbar({
      message: '設定を更新しました',
      type: 'success',
    });
  } catch (e) {
    createSnackbar({
      message: '設定を更新できませんでした',
      type: 'error',
    });
    throw e;
  }
};
</script>
<template>
  <div class="user-settings" v-if="userSettings">
    <Header title="ニュース配信"></Header>
    <Content>
      <div class="settings-card">
        <div class="c-formBlock">
          <SettingToggleCard
            title="英語記事のタイトル翻訳"
            v-model="enableTranslateArticleTitle"
          >
            海外メディア記事のタイトルが翻訳された形で表示されます。
          </SettingToggleCard>
          <SettingToggleCard
            title="要ログイン記事の非表示"
            v-model="enableHideLoginRequiredArticle"
          >
            ログインの必要な記事が表示されなくなります。
          </SettingToggleCard>
          <SettingToggleCard
            title="パーソナルニュースのエンタメ系ニュースの配信停止"
            v-model="enablePersonalNewsEntertainmentFilter"
          >
            パーソナルニュースにエンタメ系ニュースが配信されなくなります。なお、設定を変更したときの適応は翌日の配信分からになります。
          </SettingToggleCard>
        </div>
        <div class="spacing-24"></div>
        <button class="c-btn c-btn--small c-btn--AnewsPrimary" @click="submit">
          保存
        </button>
      </div>
    </Content>
  </div>
</template>
<style lang="scss" scoped>
.user-settings {
  width: 100%;
  margin: -24px 0 0 0;

  .settings-card {
    box-sizing: border-box;
    width: 440px;
    padding: 16px;
    background: #fff;
    border: 1px solid $color-border;
    border-radius: $border-radius;

    .c-formBlock {
      margin: 12px 0 24px;
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    .scope-select {
      width: 200px;
    }

    .c-btn {
      width: 60px;
      margin: 0;
    }
  }
}
</style>
