<script setup lang="ts">
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import api from '@/api';
import { Group } from '@/types';
import { useEmitter } from '@/utils/vue';

interface Props {
  group: Group;
}

const props = defineProps<Props>();
const emitter = useEmitter();
const isLoading = ref(false);
const route = useRoute();

/**
 * ボタンに表示するテキスト
 */
const buttonText = computed(() => (props.group.is_member ? '退会' : '参加'));

/**
 * ボタンのCSSクラス
 */
const buttonClass = computed(() =>
  props.group.is_member ? 'c-btn--AnewsSecondary' : 'c-btn--AnewsPrimary',
);

/**
 * ボタンがクリックされた際のアクション
 */
const toggle = async (group: Group) => {
  isLoading.value = true;
  if (group.is_member) {
    await api.leaveGroup(group?.id);
  } else {
    await api.joinGroup(group?.id);
    await api.updateLastViewedTimeForGroup(group?.id);
  }
  emitter.emit('update-group-list');
  isLoading.value = false;
};

const isEditingGroup = computed(() => {
  return route.name === 'groupEdit';
});
</script>

<template>
  <div class="group-toggle-button">
    <button
      class="c-btn c-btn--small button"
      v-if="!isEditingGroup && group.group_type !== 'all_user_group'"
      :disabled="isLoading"
      :class="buttonClass"
      @click.prevent="() => toggle(group)"
    >
      {{ buttonText }}
    </button>
  </div>
</template>

<style lang="scss" scoped>
.group-toggle-button {
  button {
    width: 62px;
    &:disabled {
      color: white;
      border-color: #e6e6e6;
      background-color: #e6e6e6;
    }
  }
}
</style>
