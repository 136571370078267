<script lang="ts" setup>
import { useXScrollbarWidth } from '@/utils/composables/useXScrollbarWidth';
import { sanitize } from '@/utils/sanitize';

type Props = {
  detail?: string;
  isMenuVisible?: boolean;
  isResponsive?: boolean;
};

withDefaults(defineProps<Props>(), {
  isMenuVisible: true,
  isResponsive: false,
});
const xScrollBarWidth = useXScrollbarWidth();
</script>
<template>
  <div
    class="detail-content"
    :class="{
      'is-menu-visible': isMenuVisible,
      responsive: isResponsive,
    }"
    :style="{
      '--xscroll-bar-width': `${xScrollBarWidth}px`,
      '--left-menu-width': `${isMenuVisible ? 288 : 0}px`,
    }"
  >
    <slot name="detail">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <span class="contents" v-html="sanitize(detail || '')" />
    </slot>
  </div>
</template>
<style scoped lang="scss">
.detail-content {
  display: flex;

  &.responsive {
    max-width: 100%;
    width: calc(
      100vw -
        (80px + var(--left-menu-width) + var(--xscroll-bar-width, 0) + 24px)
        /* 常時表示されているメニューのwidth + 折りたたみ可能なメニューのwidth + ページ全体のx軸スクロールバーのwidth + ヘッダーのpadding-left */
    );
    white-space: pre-wrap;
  }
}

.contents {
  word-break: break-all;
}
</style>
