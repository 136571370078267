<script lang="ts">
import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  reactive,
  ref,
  VNodeRef,
  watch,
} from 'vue';
import { useRouter } from 'vue-router';
import api from '@/api';
import { TagListModalInfo } from '@/stores/pc-modules/tagListModal';
import { DgrIcon } from '@stockmarkteam/donguri-ui';
import Avatar from '@/components/common/atoms/avatar.vue';
import Filters from '@/components/common/filters.vue';
import SearchBar from '@/components/common/molecules/search-bar.vue';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import AdminContent from '@/components/layouts/admin-content.vue';
import Header from '@/components/layouts/header.vue';
import { AdminGroup, AdminUserInfo } from '@/types';
import type { Contract } from '@/types';
import * as Formatter from '@/utils/formatters';
import {
  useAdminUsers,
  useManagementContracts,
  useOrganizationTagList,
  useThemeList,
} from '@/utils/swr';
import { checkMoveToAdmin } from '@/utils/user';
import { useStore } from '@/utils/vue';
import AdminBaseTable, { Field } from '../admin-base-table.vue';
import ActionMenuForMembersTable from './action-menu-for-members-table.vue';

type AdminTableItem = {
  id: number;
  name: string;
  image_url: string;
  email: string;
  product_contract_id: string;
  role: string;
  organization_tags: string;
  created_at: Date;
  formatted_created_at: string;
  last_loggedin_date: Date;
  formatted_last_loggedin_date: string;
  last_used_date: Date;
  formatted_last_used_date: string;
  can_resend_invitation: boolean;
};

export default defineComponent({
  components: {
    Header,
    SearchBar,
    Avatar,
    AdminContent,
    ActionMenuForMembersTable,
    Filters,
    AdminBaseTable,
    DgrIcon,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const { createSnackbar } = useSnackbar();

    const feedType = computed(() => store.state.feedType.feedType);

    const { data: themes } = useThemeList(feedType);
    const { data: teamUsersForAdmin, mutate } = useAdminUsers();
    const activeTeamUsersForAdmin = computed(
      () =>
        teamUsersForAdmin.value?.users.filter(user => !user.is_deleted) ?? [],
    );
    const { data: managementContracts } = useManagementContracts();
    const contracts = computed(
      () => managementContracts.value?.management_contracts,
    );
    watch(
      () => contracts.value,
      (changedContracts: Contract[] | undefined) => {
        const isMemberListViewable =
          changedContracts != undefined && changedContracts?.length > 0;
        if (!isMemberListViewable) router.push({ name: 'themeAdmin' });
      },
    );

    const contractName = (user: AdminUserInfo) => {
      return (
        contracts.value?.find(
          c => c.product_contract_id === user.product_contract_id,
        )?.contract_name ?? '未設定'
      );
    };

    const { data: organizationTags } = useOrganizationTagList();

    const fields: Field<AdminTableItem>[] = [
      {
        name: 'name',
        displayName: '名前',
        isSortable: true,
        type: 'string',
        minWidth: 180,
        fieldPosition: 'left',
        cellPosition: 'left',
      },
      {
        name: 'product_contract_id',
        displayName: '契約',
        isSortable: true,
        type: 'string',
        minWidth: 180,
        fieldPosition: 'left',
        cellPosition: 'left',
      },
      {
        name: 'role',
        displayName: '権限',
        isSortable: true,
        type: 'string',
        minWidth: 80,
        fieldPosition: 'left',
        cellPosition: 'left',
      },
      {
        name: 'organization_tags',
        displayName: '組織タグ',
        isSortable: false, // 組織タグは複数表示される可能性があるため、単純なソートロジックが適用できないため対象外とする
        type: 'string' as const,
        minWidth: 180,
        fieldPosition: 'left' as const,
        cellPosition: 'left' as const,
      },
      {
        name: 'created_at',
        displayName: '登録日',
        isSortable: true,
        type: 'date',
        minWidth: 86,
        fieldPosition: 'left',
        cellPosition: 'left',
      },
      {
        name: 'last_loggedin_date',
        displayName: '最終ログイン日',
        isSortable: true,
        type: 'date' as const,
        minWidth: 86,
        fieldPosition: 'left' as const,
        cellPosition: 'left' as const,
        thClass: ['m-last-loggedin-date'],
      },
      {
        name: 'last_used_date',
        displayName: '最終利用日',
        isSortable: true,
        type: 'date' as const,
        minWidth: 86,
        fieldPosition: 'left' as const,
        cellPosition: 'left' as const,
        thClass: ['m-last-used-date'],
      },
      {
        name: 'actions',
        displayName: '',
        isSortable: false,
        type: 'string',
        minWidth: 70,
        fieldPosition: 'center',
        cellPosition: 'center',
      },
    ];

    const formatDate = (date: string | undefined) => {
      return date ? Formatter.formatDate(date) : undefined;
    };

    const items = computed<AdminTableItem[]>(() => {
      return filteredUsers.value.map(user => {
        return {
          id: user.id,
          name: user.user_name,
          image_url: user.image_url,
          email: user.email,
          product_contract_id: contractName(user),
          role: authority(user),
          organization_tags: user.organization_tags.join(', '),
          created_at: new Date(user.created_at),
          formatted_created_at: formatDate(user.created_at) ?? '',
          last_loggedin_date: new Date(user.last_loggedin_date ?? 0),
          formatted_last_loggedin_date:
            formatDate(user.last_loggedin_date) ?? '',
          last_used_date: new Date(user.last_used_date ?? 0),
          formatted_last_used_date: formatDate(user.last_used_date) ?? '',
          can_resend_invitation: !user.last_accessed_at,
        };
      });
    });

    // 登録日昇順をデフォルトソート順にする
    const initialSortField = fields.find(f => f.name === 'created_at');

    const filters = reactive({
      roles: [] as string[],
      contractNames: [] as string[],
      organizationTags: [] as string[],
    });

    const filterQuery = ref('');

    onMounted(() => {
      checkMoveToAdmin(router);
    });

    const openUserEditModal = (type: string, userId?: number) => {
      const user = activeTeamUsersForAdmin.value.find(u => u.id === userId);
      const targetUser = type === 'edit' ? user : undefined;
      store.commit('userEditModal/showUserEditModal', {
        type: type,
        user: targetUser,
      });
    };

    const filteredMemberCount = computed(() => {
      return filteredUsers.value.length;
    });

    const filterUsers = (query: string) => {
      filterQuery.value = query;
    };
    const filteredUsers = computed(() => {
      return activeTeamUsersForAdmin.value.filter(user => {
        let searchTargetWord = [user.email, user.user_name]
          .join()
          .toLowerCase();
        searchTargetWord += authority(user);

        let visible = true;
        if (filters.roles.length > 0) {
          visible =
            visible && filters.roles.some(role => authority(user) === role);
        }
        if (filters.contractNames.length > 0) {
          visible =
            visible &&
            filters.contractNames.some(name => contractName(user) === name);
        }
        if (filters.organizationTags.length > 0) {
          visible =
            visible &&
            filters.organizationTags.some(tag =>
              tag === ''
                ? user.organization_tags.length === 0
                : user.organization_tags.includes(tag),
            );
        }

        return (
          searchTargetWord.includes(filterQuery.value.toLowerCase()) && visible
        );
      });
    });

    const resendPasswordEmail = async (userId: number) => {
      const user = activeTeamUsersForAdmin.value.find(u => u.id === userId);
      if (!user) {
        return;
      }
      try {
        await api.resendPasswordEmail(user.id);
        createSnackbar({
          message: '招待メールを再送しました',
          type: 'success',
        });
      } catch (err) {
        createSnackbar({
          message: '招待メールを再送できませんでした',
          type: 'error',
        });
        throw err;
      }
    };
    const deleteUser = async (userId: number) => {
      const user = activeTeamUsersForAdmin.value.find(u => u.id === userId);
      if (!user) {
        return;
      }

      const deleteAction = async () => {
        try {
          await api.deleteUser(user.id);
          createSnackbar({
            message: 'メンバーを削除しました',
            type: 'success',
          });
          await store.dispatch('teamUsers/fetchTeamUsersForAdmin');
          mutate();
        } catch (err) {
          createSnackbar({
            message: 'メンバーを削除できませんでした',
            type: 'error',
          });
          throw err;
        }
      };
      const moveToGroupAdmin = () => {
        router.push({ name: 'groupAdmin' });
      };

      const oneAdminGroups = await getGroupsWithOneAdmin(user ?? undefined);

      if (oneAdminGroups.length > 0) {
        const groupNames = oneAdminGroups.map(g => g.name);
        const bodyText = `${
          oneAdminGroups[0].admins[0].user_name
        }は${groupNames.join(
          ',',
        )}の管理者で、他にこのグループの管理者がいないので、削除できません。\n グループ管理者の権限委譲してから、削除してください。`;
        const payload = {
          headerText: 'メンバーを削除できません',
          bodyText,
          confirmationType: 'submit',
          btnText: 'グループ画面',
          action: moveToGroupAdmin,
        };
        store.commit('confirmationModal/showConfirmationModal');
        store.commit('confirmationModal/setTextAndAction', payload);
      } else {
        let bodyText = `${user.user_name}を削除します。`;
        if (
          themes.value &&
          themes.value.themes.filter(
            t => t.access_scope === 'personal' && t.user_id === user.id,
          ).length > 0
        ) {
          bodyText +=
            'また、このユーザーを削除するとこのユーザーで公開範囲を指定しているテーマも一緒に削除されます。\n削除したくないテーマは、このユーザーでログインし、公開範囲設定を変更してください。一度削除したテーマは復元できません。';
        }
        const payload = {
          headerText: 'メンバーを削除しますか？',
          bodyText,
          action: deleteAction,
        };
        store.commit('confirmationModal/showConfirmationModal');
        store.commit('confirmationModal/setTextAndAction', payload);
      }
    };

    const getGroupsWithOneAdmin = async (user: AdminUserInfo | undefined) => {
      const oneAdminGroups: AdminGroup[] = [];
      const groupsForAdmin = await api.fetchGroupsForAdmin();
      groupsForAdmin.forEach((group: AdminGroup) => {
        if (group.admins.length === 1 && group.admins[0].id === user?.id) {
          oneAdminGroups.push(group);
        }
      });
      return oneAdminGroups;
    };

    const authority = (user: AdminUserInfo) => {
      return {
        admin: '管理者',
        group_admin: 'グループ管理者',
        editor: '編集者',
        viewer: '閲覧者',
      }[user.role];
    };

    const countOccurrences = (arr: string[]) => {
      const countMap = arr.reduce(
        (acc, curr) => {
          acc[curr] = (acc[curr] ?? 0) + 1;
          return acc;
        },
        {} as { [key: string]: number },
      );
      return Object.entries(countMap).map(([name, count]) => ({
        name,
        count,
      }));
    };

    // フィルター系の処理
    const roles = computed(() =>
      countOccurrences(activeTeamUsersForAdmin.value.map(d => authority(d))),
    );
    const contractNames = computed(() =>
      countOccurrences(activeTeamUsersForAdmin.value.map(d => contractName(d))),
    );
    const organizationTagOptions = computed(() => {
      const tagNames = [
        ...(organizationTags.value?.organization_tags.map(tag => tag.name) ??
          []),
        '',
      ];

      const options = tagNames.map(tagName => ({
        name: tagName,
        count: activeTeamUsersForAdmin.value.filter(user =>
          tagName === ''
            ? user.organization_tags.length === 0
            : user.organization_tags.includes(tagName),
        ).length,
      }));

      return options.filter(option => option.count > 0);
    });

    const searchFilters = ref([
      {
        label: '契約',
        options: contractNames.value,
        selectedOptions: [] as string[],
      },
      {
        label: '権限',
        options: roles.value,
        selectedOptions: [] as string[],
      },
      {
        label: '組織タグ',
        options: organizationTagOptions.value,
        selectedOptions: [] as string[],
      },
    ]);

    watch(filteredUsers, () => {
      searchFilters.value[0].options = contractNames.value;
      searchFilters.value[1].options = roles.value;
      searchFilters.value[2].options = organizationTagOptions.value;
    });

    const applyFilter = () => {
      filters.contractNames = searchFilters.value[0].selectedOptions;
      filters.roles = searchFilters.value[1].selectedOptions;
      filters.organizationTags = searchFilters.value[2].selectedOptions;
    };

    // クリックされたときにダイアログを出すかどうかを判定するために
    // 組織タグがtruncateされた行のユーザーIDを覚えておく
    const truncatedCellIdsSet = new Set<number>();

    const checkWidth: VNodeRef = element => {
      if (element === null || !(element instanceof Element)) return;

      nextTick().then(() => {
        // 数ピクセルの誤差が出ることがあるので2pxぐらいは許容しつつ
        // offsetWidthとscrollWidthが違う = truncateされていると判定する
        const isTruncated =
          Math.abs(element.scrollWidth - element.clientWidth) > 2;
        const userId = Number((element as HTMLElement).dataset.userId);

        if (isTruncated) {
          truncatedCellIdsSet.add(userId);
        } else {
          truncatedCellIdsSet.delete(userId);
        }

        element.classList.toggle('truncated', isTruncated);
      });
    };

    const showOrganizationTagsDialog = (userId: number) => {
      if (!truncatedCellIdsSet.has(userId)) return;

      const user = activeTeamUsersForAdmin.value.find(u => u.id === userId);
      if (!user) return;

      const tagListModalInfo: TagListModalInfo = {
        tagNameList: user.organization_tags,
        contentTitle: '組織タグ',
      };
      store.commit('tagListModalInfo/setTagInfoList', tagListModalInfo);
      store.commit('modal/showModal', 'tagListModal');
    };

    return {
      filterUsers,
      filteredMemberCount,
      openUserEditModal,
      authority,
      resendPasswordEmail,
      deleteUser,
      contractName,
      searchFilters,
      applyFilter,
      fields,
      items,
      initialSortField,
      checkWidth,
      showOrganizationTagsDialog,
    };
  },
});
</script>

<template>
  <div class="container">
    <Header title="メンバー管理" header-width="100%"></Header>
    <AdminContent>
      <div class="o-members">
        <div class="search-bar">
          <SearchBar @on-change-query="filterUsers"></SearchBar>
        </div>
        <div class="spacing-16"></div>
        <div class="o-header">
          <div class="o-search-info">
            <div class="c-text c-text--m">メンバー</div>
            <div class="o-member-count c-text c-text--s">
              ・{{ filteredMemberCount }}名
            </div>
          </div>
          <div class="o-header-buttons">
            <div>
              <Filters
                v-model="searchFilters"
                @input="applyFilter"
                class="m-search-settings"
                is-right-aligned
              ></Filters>
            </div>
            <div class="spacing-12"></div>
            <div class="o-create-member-button">
              <button
                class="m-create-button c-btn--auto c-btn--AnewsPrimary c-text c-text--m"
                @click="openUserEditModal('create')"
              >
                メンバー追加
              </button>
            </div>
          </div>
        </div>
        <div class="o-member-table">
          <AdminBaseTable
            :fields="fields"
            :items="items"
            :initial-sort-field="initialSortField"
            :is-sticky-header="true"
            row-height="thin"
            class="admin-base-table"
          >
            <template #cell-name="{ item: user }">
              <div class="m-user-name">
                <router-link :to="`/users/${user.id}`">
                  <Avatar :image-url="user.image_url" size="xs"></Avatar>
                </router-link>
                <div class="name-and-email">
                  <router-link :to="`/users/${user.id}`">
                    <div class="name c-text c-text--m">
                      {{ user.name }}
                    </div>
                  </router-link>
                  <div class="email c-text c-text--xs">{{ user.email }}</div>
                </div>
              </div>
            </template>
            <template #cell-product_contract_id="{ item: user }">
              <div class="c-text c-text--m m-product_contract_id">
                {{ user.product_contract_id }}
              </div>
            </template>
            <template #cell-role="{ item: user }">
              <div class="c-text c-text--m m-role">
                {{ user.role }}
              </div>
            </template>
            <template #cell-organization_tags="{ item: user }">
              <div
                class="c-text c-text--m m-organization-tags"
                :data-user-id="user.id"
                :ref="checkWidth"
                @click="showOrganizationTagsDialog(user.id)"
              >
                {{ user.organization_tags }}
              </div>
            </template>
            <template #cell-created_at="{ item: user }">
              <div class="c-text c-text--m m-created_at">
                {{ user.formatted_created_at }}
              </div>
            </template>
            <template #cell-last_loggedin_date="{ item: user }">
              <div class="c-text c-text--m m-last_loggedin_date">
                {{ user.formatted_last_loggedin_date }}
              </div>
            </template>
            <template #cell-last_used_date="{ item: user }">
              <div class="c-text c-text--m m-last_used_date">
                {{ user.formatted_last_used_date }}
              </div>
            </template>
            <template #cell-actions="{ item: user }">
              <ActionMenuForMembersTable class="actions m-edit-menu-ellipsis">
                <div class="o-user-edit-menu c-text c-text--m">
                  <button
                    class="o-user-edit c-text c-text--m"
                    @click="openUserEditModal('edit', user.id)"
                  >
                    <DgrIcon size="small" name="pencil" class="item-icon" />編集
                  </button>
                  <button
                    class="o-user-resend-password c-text c-text--m"
                    v-if="user.can_resend_invitation"
                    @click="resendPasswordEmail(user.id)"
                  >
                    <DgrIcon
                      size="small"
                      name="envelop"
                      class="item-icon"
                    />招待メールを再送
                  </button>
                  <div class="divider"></div>
                  <button
                    class="o-user-remove c-text c-text--m"
                    @click="deleteUser(user.id)"
                  >
                    <DgrIcon size="small" name="trash" class="item-icon" />削除
                  </button>
                </div>
              </ActionMenuForMembersTable>
            </template>
          </AdminBaseTable>
        </div>
      </div>
    </AdminContent>
  </div>
</template>

<style lang="scss" scoped>
.container {
  width: 100%;
  margin: -24px 0 0 0;
  padding: 0 !important;
}

div.o-members {
  padding-bottom: 280px;
  .search-bar {
    display: flex;
  }
  .o-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .o-search-info {
      display: flex;
      flex-direction: row;
      align-items: center;
      .o-member-count {
        color: #b3b3b3;
        margin-right: 8px;
      }
    }
    button.m-create-button {
      padding-top: 5px;
      height: 32px;
      width: 120px;
      margin: 0;
    }
    .o-header-buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
  .o-member-table {
    margin: 16px 0 0 0;
    height: calc(100vh - 160px);
    // ヘッダーからテーブルトップまでスクロールできるように調整
    .admin-base-table {
      max-height: 100%;
    }
  }
  .m-user-name {
    display: flex;
    align-items: center;
    min-width: 180px;
    .name-and-email {
      width: calc(100% - 16px);
      margin-left: 12px;
      .name {
        word-wrap: break-word;
        overflow-wrap: break-word;
        &:hover {
          text-decoration: underline;
        }
      }
      .email {
        color: #b3b3b3;
        word-wrap: break-word;
        overflow-wrap: break-word;
      }
    }
  }
  .m-organization-tags {
    width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &.truncated {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .o-user-edit-menu {
    padding: 12px 0;
    background: #ffffff;
    box-shadow: 0px 1px 5px rgba(74, 74, 74, 0.25);
    border-radius: 4px;
    width: max-content;

    .o-user-edit,
    .o-user-remove,
    .o-user-resend-password {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background-color: inherit;
      border: none;
      width: 100%;
      height: auto;
      .item-icon {
        margin-right: 4px;
      }
    }

    > * {
      padding: 5px 12px;

      cursor: pointer;
      &:hover {
        background-color: #f2f2f2;
      }
    }
    .divider {
      height: 1px;
      padding: 0;
      margin: 4px 0;
      background: #e6e6e6;
      &:hover {
        background-color: white;
      }
    }
  }
}
</style>
