import {
  ContentsContext,
  createTrackingData,
  IndustryData,
  SearchData,
  TrackingBaseData,
} from '@/api/tracking';
import axios from 'axios';
import { AdpDocument, ArticleMarkSource, GroupMark } from '@/types';

export async function deleteGroupMark(markId: number): Promise<void> {
  await axios.delete<void>(`/group_marks/${markId}`);
}

export async function postGroupMark(
  groupId: number,
  adpDocument: AdpDocument,
  themeId: number | null,
  markSource: ArticleMarkSource,
  trackingBaseData: TrackingBaseData,
  rankInWholeFeed?: number,
  memo?: string,
  tags?: string[],
  searchData?: SearchData,
  industryData?: IndustryData,
  description?: string,
  contentsContext?: ContentsContext,
  execution?: string,
): Promise<GroupMark> {
  const trackingData = {
    ...createTrackingData({
      adpDocument,
      groupId,
      themeId: themeId ?? undefined,
      rankInWholeFeed,
      ...trackingBaseData,
      searchData,
      industryData,
      description,
      contentsContext,
      execution,
    }),
    article_memo: memo,
    article_tags: tags,
  };
  const { data } = await axios.post<GroupMark>(`/group_marks`, {
    group_id: groupId,
    article_id: adpDocument.id,
    doc_type: adpDocument.doc_type ?? 'article',
    theme_id: themeId || undefined,
    source: markSource,
    tracking_data: trackingData,
  });
  return data;
}
