<script setup lang="ts">
import { AdpDocumentOptionAction } from '@/types';
import AdpDocumentCommentButton from './adp-document-comment-button.vue';
import AdpDocumentGroupMarkButton from './adp-document-group-mark-button.vue';
import AdpDocumentMarkButton from './adp-document-mark-button.vue';
import AdpDocumentOptionMenusButton from './adp-document-option-menus-button.vue';

withDefaults(
  defineProps<{
    isMarked: boolean;
    markUsers: {
      id: number;
      name: string;
    }[];
    markedGroups: {
      id: number;
      name: string;
    }[];
    commentUsers: {
      id: number;
      name: string;
      commentId: number;
    }[];
    optionActions: AdpDocumentOptionAction[];
    layoutTheme: 'style-start' | 'style-between';
  }>(),
  {
    layoutTheme: 'style-start',
  },
);

const emit = defineEmits<{
  handlePopupMenuClick: [];
  handleMarkClick: [];
  handleGroupMarkClick: [];
  handleCommentClick: [];
}>();
const handleMarkClick = () => {
  emit('handleMarkClick');
};
const handlePopupMenuClick = () => {
  emit('handlePopupMenuClick');
};

const handleGroupMarkClick = () => {
  emit('handleGroupMarkClick');
};

const handleCommentClick = () => {
  emit('handleCommentClick');
};
</script>

<template>
  <div class="adp-document-menu">
    <div class="base-menu-area">
      <AdpDocumentMarkButton
        :is-marked="isMarked"
        :mark-users="markUsers"
        @handle-mark-click="handleMarkClick"
        @handle-popup-menu-click="handlePopupMenuClick"
      />
      <AdpDocumentGroupMarkButton
        :marked-groups="markedGroups"
        @click="handleGroupMarkClick"
      />
      <AdpDocumentCommentButton
        :comment-users="commentUsers"
        @click="handleCommentClick"
      />
    </div>
    <div class="option-menu-area">
      <AdpDocumentOptionMenusButton :option-actions="optionActions" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.adp-document-menu {
  display: flex;
  justify-content: v-bind(
    'layoutTheme === "style-start" ? "flex-start" : "space-between"'
  );
  align-items: center;
}

.base-menu-area {
  display: flex;
  align-items: center;
}

.option-menu-area {
  display: flex;
  align-items: center;
}
</style>
