<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import api from '@/api';
import { DgrIcon } from '@stockmarkteam/donguri-ui';
import Loading from '@/components/common/loading.vue';
import SearchBar from '@/components/common/molecules/search-bar.vue';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import Header from '@/components/layouts/header.vue';
import AddUsersModal from '@/components/modals/add-users-modal.vue';
import ItemMember from '@/components/others/organization-tags/organization-tag-detail/item-member.vue';
import {
  STATES,
  useManagementContracts,
  useOrganizationTagDetail,
  useOrganizationTagJoinableUsers,
} from '@/utils/swr';
import { useIsAdmin } from '@/utils/user';

const props = defineProps<{
  organizationTagId: { type: string | number; default: undefined };
}>();

const { createSnackbar } = useSnackbar();

/** 閲覧ユーザが管理者権限を持っているかどうか */
const { isAdmin } = useIsAdmin();
const organizationTagId = computed(() => Number(props.organizationTagId));
const isOpenAddableModal = ref(false);

const {
  data: tag,
  state: orgTagState,
  mutate,
} = useOrganizationTagDetail(organizationTagId.value);
watch(
  () => orgTagState.value,
  () => {
    if (
      orgTagState.value === STATES.ERROR ||
      orgTagState.value === STATES.STALE_IF_ERROR
    ) {
      createSnackbar({
        message: '組織タグを取得できませんでした',
        type: 'error',
      });
    }
  },
);

const isLoading = computed(
  () =>
    orgTagState.value === STATES.PENDING ||
    orgTagState.value === STATES.VALIDATING,
);
const isSubmitting = ref(false);

const filterQuery = ref('');
const filteredTagUsers = computed(() => {
  if (!tag.value) return [];
  if (!filterQuery.value) return tag.value.users;

  return tag.value.users.filter(user => {
    const includesUserName = user.user_name
      .toLowerCase()
      .includes(filterQuery.value.toLowerCase());
    const includesEmail = user.email
      .toLowerCase()
      .includes(filterQuery.value.toLowerCase());

    return includesUserName || includesEmail;
  });
});

const sortDirection = ref<'asc' | 'desc'>('asc');
const sortedUsers = computed(() =>
  [...filteredTagUsers.value].sort((a, b) => {
    if (a.user_name < b.user_name) {
      return sortDirection.value === 'asc' ? -1 : 1;
    }
    if (a.user_name > b.user_name) {
      return sortDirection.value === 'asc' ? 1 : -1;
    }
    return 0;
  }),
);

const handleChangeFilterQuery = (query: string) => {
  filterQuery.value = query;
};

const handleToggleSortDirection = () => {
  sortDirection.value = sortDirection.value === 'asc' ? 'desc' : 'asc';
};

const {
  data: fetchedJoinableUsers,
  mutate: joinableUsersMutate,
  state: joinableUsersState,
} = useOrganizationTagJoinableUsers(Number(props.organizationTagId));

const { data: fetchedManagementContracts, state: managementContractsState } =
  useManagementContracts();
const managementContracts = computed(
  () => fetchedManagementContracts.value?.management_contracts ?? [],
);

const isModalLoading = computed(
  () =>
    joinableUsersState.value === STATES.PENDING ||
    joinableUsersState.value === STATES.VALIDATING ||
    managementContractsState.value === STATES.PENDING ||
    managementContractsState.value === STATES.VALIDATING ||
    orgTagState.value === STATES.PENDING ||
    orgTagState.value === STATES.VALIDATING,
);

const addableUsers = computed(() =>
  (fetchedJoinableUsers.value?.users ?? []).map(user => {
    const contractName =
      managementContracts.value.find(
        contract => contract.product_contract_id === user.product_contract_id,
      )?.contract_name ?? '';

    return {
      id: user.id,
      user_name: user.user_name,
      email: user.email,
      image_url: user.image_url,
      role: user.role,
      organization_tags: user.organization_tags,
      joined_group_names: user.groups,
      managed_contract_name_by_current_user: contractName,
    };
  }),
);

const openAddUsersModal = async () => {
  isOpenAddableModal.value = true;
};

const closeAddUsersModal = () => {
  isOpenAddableModal.value = false;
};

const addUsers = async (userIds: number[]) => {
  isSubmitting.value = true;

  const response = await api.addOrganizationTagUsers({
    organizationTagId: organizationTagId.value,
    userIds: userIds,
  });

  if (response.isSuccess) {
    createSnackbar({
      message: '追加されました',
      type: 'success',
    });
    mutate();
    joinableUsersMutate();
    closeAddUsersModal();
  } else {
    createSnackbar({
      message: response.message,
      type: 'error',
    });
  }

  isSubmitting.value = false;
};

const isShowAddUsersModalContractFilter = computed(() => {
  return managementContracts.value.length > 0;
});
</script>

<template>
  <div class="organization-tag-detail">
    <Header
      data-testid="tag-name"
      v-if="tag"
      :title="tag.name"
      header-width="100%"
    />
    <Loading v-if="isLoading && !tag" class="initial-loading" />

    <div v-else-if="tag" class="container">
      <Loading v-if="isLoading || isSubmitting" class="loading" />
      <div class="inner">
        <div class="header">
          <div class="page-header">
            <div class="page-title">
              <span class="name">メンバー</span>
              <span class="count" data-testid="member-count">
                {{ tag.users.length }}名
              </span>
            </div>

            <button
              v-if="isAdmin"
              type="button"
              class="add-member-button o-create-button c-btn c-btn--auto c-btn--AnewsPrimary"
              @click="openAddUsersModal()"
            >
              メンバー追加
            </button>
          </div>
        </div>

        <div v-if="tag.users.length > 0" class="search-bar-container">
          <SearchBar
            @on-change-query="handleChangeFilterQuery"
            data-testid="search-bar"
            :placeholder="'メンバーを検索'"
          ></SearchBar>
        </div>

        <div
          v-if="sortedUsers.length <= 0"
          class="empty-user-list-container"
          data-testid="empty-state"
        >
          <div class="empty-user-list">
            <p class="c-text--m">メンバーがいません</p>
            <img src="@/assets/state-empty-article.png" />
          </div>
        </div>

        <template v-else>
          <div class="user-list-container" data-testid="user-list">
            <div
              class="user-list-header"
              @click="handleToggleSortDirection"
              data-testid="toggle-sort-direction-button"
            >
              <p class="c-text--s">名前</p>

              <DgrIcon
                v-if="sortDirection === 'asc'"
                name="long-arrow-up"
                class="sort-direction-arrow-container"
              />
              <DgrIcon
                v-else
                name="long-arrow-down"
                class="sort-direction-arrow-container"
              />
            </div>

            <ul class="user-list">
              <li
                v-for="user in sortedUsers"
                :key="user.id"
                class="user-list-item"
              >
                <ItemMember
                  :user="user"
                  :is-admin-user="isAdmin"
                  :organization-tag-id="organizationTagId"
                ></ItemMember>
              </li>
            </ul>
          </div>
        </template>
      </div>

      <AddUsersModal
        :is-open="isOpenAddableModal"
        :joinable-users="addableUsers"
        :is-use-contract-option="isShowAddUsersModalContractFilter"
        @on-close="closeAddUsersModal"
        @on-submit="addUsers"
        :is-loading="isModalLoading"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.organization-tag-detail {
  margin: -24px 0 0 0;
  padding: 0 !important;
  min-height: 102vh;
}

.container {
  position: relative;
  display: flex;
  justify-content: center;
  padding-top: 24px;
}

.initial-loading {
  height: 102vh;
  z-index: calc(var(--z-content-header) - 1);
}

.loading {
  min-height: 102vh;
  z-index: calc(var(--z-content-header) - 1);
}

.inner {
  width: 480px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin-bottom: 56px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 12px;
}

.tag-name-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tag-name {
  flex: 1;
}

.member-count {
  color: $color-text-secondary;
}

.add-member-button {
  height: 32px;
}

.search-bar-container {
  width: 100%;
}

.empty-user-list-container {
  width: 100%;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  padding: 0 16px;
  border-radius: 4px;
  background-color: #fff;
}

.empty-user-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 32px;

  img {
    width: 96px;
  }
}

.user-list-container {
  width: 100%;
  border: 1px solid $color-border;
  box-sizing: border-box;
  padding: 16px 16px 0;
  border-radius: $border-radius;
  background-color: #fff;
}

.user-list-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  padding: 8px 0;
  border-bottom: 1px solid $color-border;
  box-sizing: border-box;
  cursor: pointer;

  &:hover {
    background-color: $color-btn-hover;
  }
}

.icon-box {
  margin-top: 2px;
  width: 14px !important;
  height: 14px !important;
}

.user-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  border-bottom: 1px solid $color-border;
  box-sizing: border-box;
  padding: 8px 0;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: $color-btn-hover;

    &:has(.action-menu-button:hover) {
      background-color: #fff;
    }
  }
}

.page-title {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  .name {
    font-size: 14px;
    line-height: 1.5;
    color: $color-gray1000;
  }
  .count {
    font-size: 12px;
    font-weight: normal;
    color: $color-gray600;
  }
}

.page-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
