<script setup lang="ts">
import { computed, Ref, UnwrapRef } from 'vue';
import { DgrIcon, DgrLoading, DgrPopover } from '@stockmarkteam/donguri-ui';
import SwrScrollingPagination from '@/components/common/swr-scrolling-pagination.vue';
import { MyTagSortAttribute, MyTagSortTypes } from '@/types';
import { useMyTagItems } from '@/utils/swr';
import { userSession } from '@/utils/userSession';

const pageLimit = 50;

const props = defineProps<{
  sortType: MyTagSortTypes | undefined;
}>();

const userId = computed(() => userSession.getUserId() ?? undefined);

const emit = defineEmits<{
  openEditTagModal: [params: { myTagId: number; tagName: string }];
  openDeleteTagModal: [params: { myTagId: number; tagName: string }];
  sortItems: [sortType: MyTagSortAttribute];
}>();

const openEditTagModal = (params: { myTagId: number; tagName: string }) => {
  emit('openEditTagModal', params);
};
const openDeleteTagModal = (params: { myTagId: number; tagName: string }) => {
  emit('openDeleteTagModal', params);
};

const sortItems = (sortType: MyTagSortAttribute) => {
  emit('sortItems', sortType);
};

const sortType = computed(() => props.sortType);

const paginationFunc = (pageRef: Ref<number>, pageLimit: number) =>
  useMyTagItems(sortType, pageRef, pageLimit);

const dataAccessor = (
  data: UnwrapRef<ReturnType<typeof useMyTagItems>['data']>,
) => data;
</script>

<template>
  <div class="my-tag-list-content">
    <SwrScrollingPagination
      :key="`${userId}-${sortType}`"
      :page-limit="pageLimit"
      :pagination-func="paginationFunc"
      :data-accessor="dataAccessor"
      scroll-target="self"
      class="table-container"
    >
      <template v-slot="{ items, loaded }">
        <table class="table">
          <thead>
            <tr class="table-header-row">
              <th
                class="table-header-cell ta-l c-text c-text--s sortable-table-header"
                @click="sortItems('name')"
              >
                <span>マイタグ名</span>
                <span v-if="sortType === 'name_asc'"> ↑ </span>
                <span v-else-if="sortType === 'name_desc'"> ↓ </span>
              </th>
              <th
                width="80"
                class="table-header-cell ta-l c-text c-text--s sortable-table-header"
                @click="sortItems('article_count')"
              >
                <span>記事数</span>
                <span v-if="sortType === 'article_count_asc'"> ↑ </span>
                <span v-else-if="sortType === 'article_count_desc'"> ↓ </span>
              </th>
              <th width="80" class="table-header-cell"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!loaded">
              <td colspan="3">
                <div class="loading-container">
                  <DgrLoading />
                </div>
              </td>
            </tr>
            <tr v-else-if="items.length === 0">
              <td colspan="3">
                <div class="empty-text-container">
                  <span class="c-text c-text--m">マイタグがありません</span>
                </div>
              </td>
            </tr>
            <template v-else>
              <tr v-for="myTagItem in items" :key="myTagItem.id">
                <td>
                  <div class="cell-inner-block my-tag-name c-text--m">
                    <span>{{ myTagItem.name }}</span>
                  </div>
                </td>
                <td>
                  <div class="cell-inner-block my-tag-count-text c-text--m">
                    <span>{{ myTagItem.total_count }}</span>
                  </div>
                </td>
                <td>
                  <div class="cell-inner-block my-tag-action-button-container">
                    <DgrPopover>
                      <template #default="{ triggerProps }">
                        <button class="action-button" v-bind="triggerProps">
                          <DgrIcon name="ellipsis-h" />
                        </button>
                      </template>
                      <template #content="{ close }">
                        <div class="item-container">
                          <button
                            class="item c-text c-text--m"
                            @click="
                              close();
                              openEditTagModal({
                                myTagId: myTagItem.id,
                                tagName: myTagItem.name,
                              });
                            "
                          >
                            <DgrIcon
                              size="small"
                              :name="'pencil'"
                              class="icon small"
                            />
                            編集
                          </button>
                          <hr class="separator" />
                          <button
                            class="item c-text c-text--m"
                            @click="
                              close();
                              openDeleteTagModal({
                                myTagId: myTagItem.id,
                                tagName: myTagItem.name,
                              });
                            "
                          >
                            <DgrIcon
                              size="small"
                              :name="'trash'"
                              class="icon small"
                            />削除
                          </button>
                        </div>
                      </template>
                    </DgrPopover>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </template>
    </SwrScrollingPagination>
  </div>
</template>

<style lang="scss" scoped>
.table-container {
  border-radius: 4px;
  background-color: $color-white;
  border: 1px solid $color-border;
  padding: 0 16px;
  overflow-y: auto;
  max-height: calc(100vh - 280px);
}

.table {
  width: 100%;
  border-spacing: 0 0;

  tr:first-child {
    td {
      padding-top: 6px;
    }
  }
  tr:last-child {
    td {
      padding-bottom: 16px;
    }
  }
  td,
  th {
    height: 40px;
  }

  thead {
    background-color: $color-white;
    position: sticky;
    top: 0;
    z-index: 1;
  }
}

.cell-inner-block {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid $color-border;
}

.my-tag-count-text {
  padding-right: 12px;
  justify-content: flex-end;
}

.my-tag-action-button-container {
  justify-content: flex-end;
}

.my-tag-name {
  padding-left: 8px;
  justify-content: flex-start;
}

.action-button {
  display: flex;
  justify-content: center;
  width: 32px;
  height: 32px;
  padding: 8px;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: inherit;
  border: none;

  &:hover {
    border: none;
    background-color: $color-gray200;
  }
}

.table-header-cell {
  text-indent: 8px;
  padding: 12px 0;
  border-bottom: 1px solid $color-border;
}

.sortable-table-header {
  cursor: pointer;
  &:hover {
    background-color: $color-gray200;
  }
}

.ta-l {
  text-align: left;
}
.ta-r {
  text-align: right;
}

.item-container {
  padding: 0;

  .item {
    display: flex;
    align-items: center;
    width: 100%;
    border: none;
    background: none;

    &:hover {
      background: $color-gray400;
    }
    .icon {
      margin-right: 4px;
    }
  }
  .separator {
    border: none;
    border-top: 1px solid $color-gray400;
    margin: 0;
  }
}

.empty-text-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 65px;
  color: $color-gray600;
}

.loading-container {
  display: flex;
  justify-content: center;
}
</style>
