<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { PageName } from '@/api/tracking';
import AdpDocumentExport from '@/components/common/adp-document/adp-document-export.vue';
import { AdpDocument } from '@/types';

export default defineComponent({
  props: {
    isGroupView: { type: Boolean, default: false },
    adpDocument: { type: Array as PropType<AdpDocument[]>, required: false },
    pageName: { type: String as PropType<PageName>, required: false },
    groupId: { type: Number, required: false },
    hasDocumentExport: { type: Boolean, required: false, default: false },
  },
  components: {
    AdpDocumentExport,
  },
});
</script>

<template>
  <div class="adp-document-info">
    <template v-if="adpDocument && adpDocument.length !== 0">
      <template v-if="pageName && hasDocumentExport">
        <AdpDocumentExport
          :adp-documents="adpDocument"
          :page-name="pageName"
          :group-id="groupId"
        ></AdpDocumentExport>
        <div class="spacing-24"></div>
      </template>
    </template>
    <div class="heading">
      <div class="c-title c-title--s">記事の情報</div>
    </div>
    <div class="o-image"><img src="@/assets/state-empty-article.png" /></div>
    <div class="c-text c-text--m">
      {{
        `記事を選択すると記事につけた${
          isGroupView ? 'グループタグ' : 'マイタグ'
        }や${isGroupView ? 'グループメモ' : 'マイメモ'}などが表示されます`
      }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.adp-document-info {
  background: #ffffff;
  padding: 16px;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 4px;

  .o-image {
    display: flex;
    justify-content: center;

    img {
      width: 140px;
      height: 90px;
      object-fit: cover;
    }
  }
}
</style>
