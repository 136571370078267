<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  setup() {
    return {};
  },
});
</script>

<template>
  <div class="no-feed">
    <div class="c-text c-text--m">
      <slot />
    </div>
    <img src="@/assets/state-empty-article.png" />
  </div>
</template>

<style scoped lang="scss">
.no-feed {
  display: flex;
  flex-direction: row;
  height: 142px;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 0 16px;
  img {
    height: 140px;
    width: 140px;
  }
}
</style>
