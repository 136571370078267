<script lang="ts">
import { defineComponent, PropType, ref } from 'vue';
import { useRoute } from 'vue-router';
import api from '@/api';
import { Feature, PageName } from '@/api/tracking';
import AdpDocumentCard from '@/components/common/adp-document-card.vue';
import MentionAutocompleteTextarea from '@/components/common/molecules/mention-autocomplete-textarea.vue';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import { AdpDocument, Comment } from '@/types/index';
import { isTabletUser } from '@/utils';
import { useUserInfo } from '@/utils/swr';

export default defineComponent({
  props: {
    article: { type: Object as PropType<AdpDocument> },
    comment: { type: Object as PropType<Comment>, required: true },
    groupId: { type: Number, required: true },
    groupName: { type: String },
    showGroup: { type: Boolean, default: false },
    pageName: { type: String as PropType<PageName>, required: true },
    feature: String as PropType<Feature>,
    expandsAllChild: { type: Boolean, default: false },
    childDisplayCount: { type: Number, default: 2 },
  },
  components: {
    AdpDocumentCard,
  },
  setup(props, context) {
    const route = useRoute();
    const { createSnackbar } = useSnackbar();

    const { data: userInfo } = useUserInfo();

    const replyRef = ref<InstanceType<typeof MentionAutocompleteTextarea>>();
    const isReplyTextEmpty = () =>
      (replyRef.value?.trimmedComment ?? '').length === 0;

    const callPostComment = () => {
      if (replyRef.value?.trimmedComment !== '') {
        replyRef.value?.postComment();
      } else {
        postComment();
      }
    };

    const postComment = async () => {
      if (replyRef.value) {
        const createdComment = await api
          .sendComment({
            groupId: props.groupId,
            adpDocument: props.article,
            themeId: props.comment?.theme_id,
            content: replyRef.value.trimmedComment,
            mention: replyRef.value.mention,
            parentCommentId: props.comment?.id,
            trackingBaseData: {
              pageName: props.pageName,
              feature: props.feature,
              pageUrl: route.fullPath,
            },
          })
          .catch(err => {
            createSnackbar({
              message: 'コメントを投稿できませんでした',
              type: 'error',
            });
            throw err;
          });
        createSnackbar({
          message: 'コメントを投稿しました',
          type: 'success',
        });
        context.emit('reply', createdComment); // eslint-disable-line vue/require-explicit-emits
        replyRef.value.resetTrimmedComment();
      }
    };

    const replyFocused = ref(false);

    return {
      userInfo,
      isReplyTextEmpty,
      postComment,
      callPostComment,
      replyFocused,
      replyRef,
      isTabletUser,
      host: location.host,
    };
  },
});
</script>

<template>
  <div class="group-comment" v-if="comment && userInfo">
    <AdpDocumentCard
      width="100%"
      :adp-document="article"
      :theme-id="comment.theme_id"
      :comment="comment"
      :page-name="pageName"
      :feature="feature"
    ></AdpDocumentCard>
  </div>
</template>

<style lang="scss" scoped>
.group-comment {
  outline: none;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 4px;

  .router-link-exact-active {
    cursor: default;
  }

  .group {
    display: inline-block;
    vertical-align: bottom;
    .group-content {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      border-bottom: solid 1px white;
      .group-name {
        margin-left: 5px;
        font-weight: bold;
      }
      &:hover {
        border-color: #4a4a4a;
        cursor: pointer;
      }
    }
  }

  .article {
    margin-top: 12px;
  }

  .divider {
    margin-top: 12px;
    height: 1px;
    background: #e6e6e6;
  }
  .reply {
    display: flex;
    justify-content: start;
    margin-top: 12px;
    .reply-button {
      width: 100%;
      margin-left: 8px;
      background: #f2f2f2;
      border-radius: 8px;
      padding: 8px 12px;
      cursor: pointer;
      outline: none;
      .message {
        color: #b3b3b3;
      }
    }
  }
  .replay-button-area {
    margin-left: 8px;
    .reply-button-small {
      width: 68px;
      height: 38px;
    }
  }
  .hint-text {
    margin: 2px 0 0 56px;
    color: #717171;
  }
  .reply-textarea {
    margin-left: 8px;
  }

  .document-card {
    border: 0;
  }
}
</style>
