<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { onBeforeRouteLeave } from 'vue-router';
import api from '@/api';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import type { OrganizationTagList } from '@/types';
import { useStore } from '@/utils/vue';
import NewTagForm from './new-tag-form.vue';
import TableEditor from './table-editor.vue';
import type { FormData, NewTagDataItem } from './types';
import {
  checkIsValidAllTagNames,
  initializeFormData,
  isUpdatedTagDataItem,
  transformFormDataToApiParams,
} from './utils';

const props = defineProps<{
  organizationTagList: OrganizationTagList;
}>();
const emit = defineEmits<{ onSuccess: []; onCancel: [] }>();
const store = useStore();
const { createSnackbar } = useSnackbar();

const formData = ref(initializeFormData(props.organizationTagList));
const sortedFormItemList = computed(() =>
  Object.values(formData.value).sort((a, b) => a.displayOrder - b.displayOrder),
);
const isLoading = ref(false);
const apiParams = computed(() => transformFormDataToApiParams(formData.value));
const hasChangedAnyTags = computed(() =>
  Object.values(apiParams.value).some(value => value.length > 0),
);
const isDisabledSubmitButton = computed<boolean>(
  () => !hasChangedAnyTags.value || isLoading.value,
);
const handleSubmitNewTag = (newTagName: string) => {
  for (const id in formData.value) {
    formData.value[id].displayOrder += 1;
  }

  const newTag: NewTagDataItem = {
    id: Math.max(...Object.values(formData.value).map(item => item.id)) + 1,
    name: newTagName,
    displayOrder: 1,
    isDeleted: false,
    isNewTag: true,
    memberCount: '【New】',
  };

  formData.value[newTag.id] = newTag;
};
const handleUpdateCheckbox = ({
  tagIds,
  newIsDeleted,
}: {
  tagIds: Array<keyof FormData>;
  newIsDeleted: boolean;
}) => {
  for (const tagId of tagIds) {
    const tag = formData.value[tagId];
    tag.isDeleted = newIsDeleted;

    if (isUpdatedTagDataItem(tag)) {
      tag.name = tag.previousName;
    }
  }
};
const handleDragFormItem = ({
  oldIndex,
  newIndex,
}: {
  oldIndex: number;
  newIndex: number;
}) => {
  if (oldIndex === newIndex) {
    return;
  }

  if (sortedFormItemList.value[oldIndex].isDeleted) {
    return;
  }

  const targetTags = sortedFormItemList.value.slice(
    Math.min(oldIndex, newIndex),
    Math.max(oldIndex, newIndex) + 1,
  );

  const direction = newIndex < oldIndex ? 'up' : 'down';

  targetTags.forEach((tag, index) => {
    let newDisplayOrder: number;
    if (direction === 'up') {
      newDisplayOrder =
        index === targetTags.length - 1
          ? tag.displayOrder - index
          : tag.displayOrder + 1;
    } else {
      newDisplayOrder =
        index === 0
          ? tag.displayOrder + targetTags.length - 1
          : tag.displayOrder - 1;
    }

    formData.value[tag.id].displayOrder = newDisplayOrder;
  });
};
const submitForm = async () => {
  isLoading.value = true;
  const response = await api.bulkOperateOrganizationTags(apiParams.value);
  if (response.isSuccess) {
    createSnackbar({
      message: '組織タグを編集しました',
      type: 'success',
    });
    emit('onSuccess');
  } else {
    createSnackbar({
      message: response.message,
      type: 'error',
    });
  }
  isLoading.value = false;
};
const handleSubmit = () => {
  const payload = {
    headerText: '組織タグの更新',
    bodyText:
      '編集した組織タグを保存しますか？チェックボックスをオンにした組織タグは削除されます。',
    confirmationType: 'submit',
    btnText: '保存',
    cancelBtnText: '編集に戻る',
    shouldShowLoading: true,
    action: submitForm,
  };
  store.commit('confirmationModal/setTextAndAction', payload);
  store.commit('confirmationModal/showConfirmationModal');
};
const handleCancel = () => {
  if (hasChangedAnyTags.value || !checkIsValidAllTagNames(formData.value)) {
    const payload = {
      headerText: '編集した組織タグ名のキャンセル',
      bodyText:
        '編集した組織タグを放棄しますか？\n削除・変更した組織タグ名は変更されません。',
      confirmationType: 'submit',
      btnText: 'OK',
      cancelBtnText: '編集に戻る',
      action: () => {
        emit('onCancel');
      },
    };
    store.commit('confirmationModal/setTextAndAction', payload);
    store.commit('confirmationModal/showConfirmationModal');
  } else {
    emit('onCancel');
  }
};
const handleBeforeUnload = (event: BeforeUnloadEvent) => {
  if (hasChangedAnyTags.value) {
    event.preventDefault();
    event.returnValue = '';
  }
};
onMounted(() => {
  window.addEventListener('beforeunload', handleBeforeUnload);
});
onUnmounted(() => {
  window.removeEventListener('beforeunload', handleBeforeUnload);
});
onBeforeRouteLeave((_to, _from, next) => {
  if (hasChangedAnyTags.value) {
    const shouldLeave = window.confirm(
      'ページを離れますか？編集した内容は保存されません。\n保存したい場合はキャンセルし、編集画面の「編集を保存」ボタンを押してください。',
    );
    shouldLeave ? next() : next(false);
  } else {
    next();
  }
});
</script>
<template>
  <div class="page-container">
    <div class="page-header">
      <button
        type="button"
        class="o-cancel-button c-btn c-btn--auto c-btnOutline"
        @click="handleCancel"
        data-testid="cancel-button"
      >
        編集をキャンセル
      </button>
      <button
        type="button"
        class="o-create-button c-btn c-btn--auto c-btn--AnewsPrimary"
        :class="{ disabled: isDisabledSubmitButton }"
        @click="handleSubmit"
        :disabled="isDisabledSubmitButton"
        data-testid="save-button"
      >
        編集を保存
      </button>
    </div>

    <div class="new-tag-adding-form-container">
      <NewTagForm
        :form-data="formData"
        @on-submit-new-tag="handleSubmitNewTag"
      />
    </div>

    <div class="table-editor-container">
      <TableEditor
        :form-data="formData"
        :sorted-form-item-list="sortedFormItemList"
        @on-update-checkbox="handleUpdateCheckbox"
        @on-drag-form-item="handleDragFormItem"
      />
    </div>
  </div>
</template>
<style lang="scss" scoped>
.page-container {
  height: 100%;
  padding: 16px 0 16px 24px;
  box-sizing: border-box;
  overflow: auto;
}
.page-header {
  width: 606px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
}
.o-cancel-button {
  background-color: #fff;
  &:hover {
    background-color: $color-gray400;
  }
}
button.disabled {
  border: none;
  &:hover {
    border: none;
  }
}
.new-tag-adding-form-container {
  margin-bottom: 16px;
}
.table-editor-container {
  height: 100%;
}
</style>
