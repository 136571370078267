<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { RouteLocationRaw } from 'vue-router';
import { IconTypes } from '@stockmarkteam/donguri-ui';
import ItemInner from '@/components/menu/common/item-inner.vue';

export default defineComponent({
  components: {
    ItemInner,
  },
  props: {
    id: { type: [String, Number], required: true },
    name: { type: String, required: true },
    route: {
      type: [String, Object] as PropType<RouteLocationRaw>,
      default: undefined,
    },

    onClick: {
      type: Function as PropType<(event: MouseEvent) => void>,
      default: () => undefined,
    },
    icon: { type: String as PropType<IconTypes>, default: undefined },
    count: { type: Number, default: undefined },
    isDisabled: { type: Boolean, default: false },
    isExternalLink: { type: Boolean, default: false },
    isSelected: { type: Boolean, default: false }, // 現在のページはprops.routeと違っても強制的に選択状態にするため
    isSelectedExactRoute: { type: Boolean, default: false }, // falseだと現在のページと子供ページでも選択されるけど、trueの場合は現在のページのみ
    isDraggable: { type: Boolean, default: false },
  },
  setup() {
    return {};
  },
});
</script>

<template>
  <div>
    <router-link
      v-if="route !== undefined && !isDisabled"
      :to="route"
      custom
      v-slot="{ href, isActive, isExactActive }"
    >
      <a
        :href="href"
        :target="isExternalLink ? '_blank' : undefined"
        @click="onClick"
      >
        <ItemInner
          :name="name"
          :icon="icon"
          :count="count"
          :is-disabled="isDisabled"
          :is-external-link="isExternalLink"
          :is-selected="
            isSelected || (isActive && !isSelectedExactRoute) || isExactActive
          "
          :is-draggable="isDraggable"
        />
      </a>
    </router-link>
    <div v-else @click="onClick">
      <ItemInner
        :name="name"
        :icon="icon"
        :count="count"
        :is-disabled="isDisabled"
        :is-draggable="isDraggable"
        :is-selected="isSelected"
      />
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
