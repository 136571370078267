<script lang="ts">
import { defineComponent, PropType } from 'vue';

export type ToggleSwitchItem = {
  leftLabelName: string;
  rightLabelName: string;
  activeOption: 'left' | 'right';
};

export default defineComponent({
  props: {
    toggleSwitchItem: {
      type: Object as PropType<ToggleSwitchItem>,
      required: true,
    },
  },
  setup(props, context) {
    const toggleSwitch = (event: MouseEvent) => {
      const targetClasses = (event.target as HTMLDivElement).classList;
      const unselectedOption =
        props.toggleSwitchItem.activeOption === 'left' ? 'right' : 'left';
      if (!targetClasses.contains(unselectedOption)) return;

      context.emit('toggleSwitch'); // eslint-disable-line vue/require-explicit-emits
    };

    return {
      toggleSwitch,
    };
  },
});
</script>

<template>
  <div class="toggle" @click="toggleSwitch">
    <div
      class="left"
      :class="{ active: toggleSwitchItem.activeOption === 'left' }"
    >
      {{ toggleSwitchItem.leftLabelName }}
    </div>
    <div
      class="right"
      :class="{ active: toggleSwitchItem.activeOption === 'right' }"
    >
      {{ toggleSwitchItem.rightLabelName }}
    </div>
  </div>
</template>

<style scoped lang="scss">
.toggle {
  display: flex;
  background: #e6e6e6;
  border-radius: 5px;
  font-size: 12px;
  line-height: 16px;
  color: #b3b3b3;
  cursor: pointer;
  > * {
    width: 50%;
    padding: 4px 0;
    border-radius: 4px;
    margin: 1px;
    text-align: center;
  }
  .active {
    color: #4a4a4a;
    background: #fff;
  }
}
</style>
