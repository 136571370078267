<script setup lang="ts">
import {
  DgrIcon,
  DgrSnackbar,
  DgrSnackbarContainer,
} from '@stockmarkteam/donguri-ui';
import { defineSnackbarCreator, SnackbarType } from './use-snackbar';

defineSnackbarCreator();
const AnewsSnackbarContainer = DgrSnackbarContainer<SnackbarType>;
</script>
<template>
  <AnewsSnackbarContainer>
    <template #link="{ actor }">
      <DgrSnackbar :actor="actor" v-slot="{ message, params }">
        <div class="link-container">
          <RouterLink
            :to="params.link"
            target="_blank"
            rel="noreferrer noopener"
            class="link"
          >
            {{ message }}
          </RouterLink>
          {{ params.suffixText }}
          <DgrIcon name="external-link" :keep-fill="false" class="icon" />
        </div>
      </DgrSnackbar>
    </template>
  </AnewsSnackbarContainer>
</template>
<style lang="scss" scoped>
.link-container {
  display: flex;
  align-items: center;
  .link {
    color: white;
    text-decoration: underline;
  }
  .icon {
    fill: white;
    margin-left: 4px;
  }
}
</style>
