<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import AdpDocumentAllianceMediaSkeleton from '@/components/common/adp-document/adp-document-alliance-media-skeleton.vue';
import { AdpDocumentBasicInfo, TeamInfo } from '@/types';
import { formatDate } from '@/utils/formatters';
import { isMobileUser } from '../../../utils';

export default defineComponent({
  components: { AdpDocumentAllianceMediaSkeleton },
  props: {
    adpDocument: {
      type: Object as PropType<AdpDocumentBasicInfo>,
      required: true,
    },
    teamInfo: Object as PropType<TeamInfo | undefined>,
  },
  setup(props) {
    const content = computed(() => {
      if (!props.adpDocument.content) return [];
      return props.adpDocument.content.split('\n');
    });

    const imageUrl = computed(() => {
      if (props.adpDocument?.image_url) {
        return props.adpDocument.image_url;
      }
      return 'https://www.nikkan.co.jp/images/hd_logo.png';
    });

    const publishedAt = computed(() =>
      props.adpDocument?.published_at
        ? formatDate(props.adpDocument.published_at)
        : undefined,
    );
    return {
      content,
      isMobileUser: isMobileUser(),
      imageUrl,
      publishedAt,
    };
  },
});
</script>

<template>
  <div class="document-basic-info">
    <img
      class="lazyload alliance-image"
      :class="{ 'mobile-alliance-image': isMobileUser }"
      loading="lazy"
      :src="imageUrl"
      :data-src="imageUrl"
    />
    <div class="spacing-24"></div>
    <div class="document-info-box">
      <div class="document-title c-text c-text--xm">
        {{ adpDocument.title }}
      </div>
      <div class="spacing-08"></div>
      <div class="document-date c-text c-text--s" v-if="publishedAt">
        {{ publishedAt }}
      </div>
      <div v-if="teamInfo">
        <div
          class="uncontracted-alliance c-text c-text--m"
          v-if="!teamInfo.enable_alliance_media"
        >
          本文を読むには、有料記事オプションを契約ください。<br />
          （管理者またはサポートにお問い合わせください）
        </div>
        <div role="article" v-else>
          <p
            v-for="text in content"
            :key="text"
            class="content-line c-text c-text--m"
          >
            {{ text }}
          </p>
        </div>
      </div>
      <AdpDocumentAllianceMediaSkeleton
        v-else
      ></AdpDocumentAllianceMediaSkeleton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.document-basic-info {
  width: 100%;
  padding: 16px 16px 8px 16px;
  box-sizing: border-box;

  .alliance-image {
    width: 40%;
    padding-left: 20px;
  }

  .mobile-alliance-image {
    display: flex;
    margin: 20px auto;
  }

  div.document-info-box {
    margin-left: 2px;
    div.document-title {
      word-wrap: break-word;
      word-break: break-word;
      overflow-wrap: break-word;

      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      overflow: hidden;

      margin: 4px 0 4px 4px;
    }

    div.document-date {
      margin-bottom: 4px;
    }
  }

  .uncontracted-alliance {
    color: $color-orange1000;
  }

  .content-line {
    white-space: pre-line;
    word-wrap: break-word;
    margin-bottom: 1em;
  }
}
</style>
