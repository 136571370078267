import type {
  NotificationGroupingName,
  NotificationItem,
  NotificationType,
  Perspective,
} from '@/types';
import { DateRangePreset } from '@/types/index';
import {
  getADayAgo,
  getAHalfYearAgo,
  getAMonthAgo,
  getAWeekAgo,
  getAYearAgo,
  getThreeMonthsAgo,
  getToday,
  getTwoYearsAgo,
} from '@/utils/dates';

export const MAX_NAME_LENGTH_FOR_COMMENT = 15;
export const MAX_NAME_LENGTH = 50;
export const MAX_COMMENT_LENGTH = 500;
export const MAX_THEME_NAME_LENGTH = 50;
export const MAX_DESCRIPTION_LENGTH = 50;
export const MAX_SITE_EXCLUSIONS = 30;
export const MAX_KEYWORDS_LENGTH = 2000;
export const MAX_KEYWORDS_LENGTH_BUFFER = 100;
export const MAX_GROUP_NAME_LENGTH = 20;
export const MAX_GROUP_DESCRIPTION_LENGTH = 500;
export const DAYS_OF_PAST_FEED = 7;
export const SITES_COUNT_PER_REQUEST = 100;
export const SITE_API_DEBOUNCE_WAIT_MS = 500;
export const SEARCH_API_DEBOUNCE_WAIT_MS = 700;
export const IOS_STORE_URL = 'https://apps.apple.com/jp/app/id1530143368';
export const ANDROID_STORE_URL =
  'https://play.google.com/store/apps/details?id=ai.stockmark.anews';
export const MAX_TOOLTIP_LIST_LENGTH = 10;
export const MAX_ARTICLE_MEMO_LENGTH = 1000;
// 1記事あたり、タグの登録は10件までとする
export const MAX_ARTICLE_TAG_LENGTH = 10;
// 登録するタグ名は以下定義の文字数までとする
export const MAX_TAG_NAME_LENGTH = 20;

export const TOOLTIP_FOR_TEAM_FEATURED_ARTICLES =
  '過去7日間のマークを集計しています。';
export const DAY_OF_WEEK_LIST = ['日', '月', '火', '水', '木', '金', '土'];
export const HTTP_URL_STRING_REGEXP =
  /^https?:\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|]$/i;
export const HTTP_URL_REGEXP =
  /(\b(https?):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;
export const NON_THEME_PERSPECTIVE: Perspective = {
  id: 1,
  category: '',
  question: '目的を限定せずに幅広く情報を取りたい',
  name: '',
  description: '',
  query_ja: '',
  query_en: '',
};
//コメント作成モーダルで表示する既存コメントの数
export const DISPLAY_LATEST_COMMENTS_COUNT = 3;
export const THEME_RELATED_NEWS_MAX_COUNT = 10;
export const MAX_REACTION_TYPE_COUNT = 20;
export const SUPPORT_UNICODE_VERSION = 12.0;
export const KEYWORD_RECOMMEND_DEBOUNCE_TIME = 500;
export const THEME_PREVIEW_DEBOUNCE_TIME = 500;
export const KEYWORD_COMPLETION_DEBOUNCE_TIME = 300;

export const UNEXPECTED_CONTENT_TYPE_ERROR =
  'response content-type is not JSON';

export const USER_MARK_SHARE = 'マーク：情報を蓄積する';
export const GROUP_MARK_SHARE = 'グループマーク：グループに情報を共有する';
export const COMMENT_SHARE = 'コメントする';

export const EMPTY_KEYWORD_FEED_MESSAGE = '新着ニュースはありませんでした。';

// AnewsLabsでレポートを作成できる上限数
export const MAX_REPORTS_COUNT = 50;
export const STOCKMARK_LABS_HELP_URL =
  'https://stockmark.notion.site/Stockmark-Labs-Help-0165fd3ed87c4bf2b0d0141d7b4fdedc';
// 企業辞書にある業界数
export const INDUSTRY_COUNT = 15;
// ChatGptに解析させる業界ごとの事例数の上限
export const ANALYSIS_SOLUTIONS_LIMIT = 7;
// 企業情報作成のために取得する記事の上限数
export const USER_SOLUTION_ARTICLES_LIMIT = 100;
// 企業情報作成のためにChatGptに解析させる事例数の上限
export const ANALYSIS_SOLUTIONS_LIMIT_FOR_SETTING = 50;
// Labs用途探索で使用するAstrategyの市場構造へのリンク
export const ASTRATEGY_RELATION_NET_URL =
  'https://astrategy.stockmark.ai/#/board/list/relationnet';
// Labs用途探索で使用するAstrategyの事例集へのリンク
export const ASTRATEGY_CASE_EXAMPLE_URL =
  'https://astrategy.stockmark.ai/#/board/list/caseexamples';

// 問い合わせメールリンク
export const CONTACT_MAIL_LINK =
  'mailto:stockmark_labs@stockmark.co.jp?subject=【Stockmark Labs/調査AIエージェント】お問い合わせ&body=*内容を追記し、ご送付ください。%0D%0Aご質問内容：%0D%0Aこちらに質問を記載ください。%0D%0Aできるだけ詳細に頂けますと幸いです。';
export const SERVICE_START_DATE = new Date(2020, 5, 19);
export const FIRST_PUBLISHED_AT = new Date(2019, 0, 1);
export const OLDEST_CALENDAR_DATE = new Date(1900, 0, 1);
export const presetDates: DateRangePreset[] = [
  {
    key: 'all',
    label: 'すべての期間',
    range: {
      from: SERVICE_START_DATE,
      to: getToday(),
    },
  },
  {
    key: '1m',
    label: '過去1ヶ月',
    range: {
      from: getAMonthAgo(),
      to: getToday(),
    },
  },
  {
    key: '3m',
    label: '過去3ヶ月',
    range: {
      from: getThreeMonthsAgo(),
      to: getToday(),
    },
  },
  {
    key: '6m',
    label: '過去6ヶ月',
    range: {
      from: getAHalfYearAgo(),
      to: getToday(),
    },
  },
  {
    key: '1y',
    label: '過去1年',
    range: {
      from: getAYearAgo(),
      to: getToday(),
    },
  },
];
export const presetADayAgoDates: DateRangePreset[] = [
  {
    key: 'all',
    label: 'すべての期間',
    range: {
      from: getTwoYearsAgo(),
      to: getADayAgo(),
    },
  },
  {
    key: '1m',
    label: '過去1ヶ月',
    range: {
      from: getAMonthAgo(),
      to: getADayAgo(),
    },
  },
  {
    key: '3m',
    label: '過去3ヶ月',
    range: {
      from: getThreeMonthsAgo(),
      to: getADayAgo(),
    },
  },
  {
    key: '6m',
    label: '過去6ヶ月',
    range: {
      from: getAHalfYearAgo(),
      to: getADayAgo(),
    },
  },
  {
    key: '1y',
    label: '過去1年',
    range: {
      from: getAYearAgo(),
      to: getADayAgo(),
    },
  },
];
export const searchPresetDates: DateRangePreset[] = [
  {
    key: 'all',
    label: 'すべての期間',
    range: {
      from: undefined,
      to: undefined,
    },
  },
  {
    key: '1d',
    label: '過去24時間',
    range: {
      from: getADayAgo(),
      to: getToday(),
    },
  },
  {
    key: '1w',
    label: '過去1週間',
    range: {
      from: getAWeekAgo(),
      to: getToday(),
    },
  },
  {
    key: '1m',
    label: '過去1ヶ月',
    range: {
      from: getAMonthAgo(),
      to: getToday(),
    },
  },
  {
    key: '6m',
    label: '過去6ヶ月',
    range: {
      from: getAHalfYearAgo(),
      to: getToday(),
    },
  },
  {
    key: '1y',
    label: '過去1年',
    range: {
      from: getAYearAgo(),
      to: getToday(),
    },
  },
];

export const userDocumentSearchPresetDates: DateRangePreset[] = [
  {
    key: 'all',
    label: 'すべての期間',
    range: {
      from: undefined,
      to: undefined,
    },
  },
  {
    key: '1d',
    label: '過去24時間',
    range: {
      from: getADayAgo(),
      to: getToday(),
    },
  },
  {
    key: '1w',
    label: '過去1週間',
    range: {
      from: getAWeekAgo(),
      to: getToday(),
    },
  },
  {
    key: '1m',
    label: '過去1ヶ月',
    range: {
      from: getAMonthAgo(),
      to: getToday(),
    },
  },
  {
    key: '6m',
    label: '過去6ヶ月',
    range: {
      from: getAHalfYearAgo(),
      to: getToday(),
    },
  },
  {
    key: '1y',
    label: '過去1年',
    range: {
      from: getAYearAgo(),
      to: getToday(),
    },
  },
];

// アクティビティの初期検索条件
// NOTE: OpenSearchに対して期間の絞り込みを行うと外部リンク共有した記事が表示されなくなるため
// 期間で絞り込みを行っていないデフォルト値である「すべての期間」は値をundefinedにしている
export const searchPresetActivity: DateRangePreset[] = [
  {
    key: 'all',
    label: 'すべての期間',
    range: {
      from: undefined,
      to: undefined,
    },
  },
  {
    key: '1d',
    label: '過去24時間',
    range: {
      from: getADayAgo(),
      to: getToday(),
    },
  },
  {
    key: '1w',
    label: '過去1週間',
    range: {
      from: getAWeekAgo(),
      to: getToday(),
    },
  },
  {
    key: '1m',
    label: '過去1ヶ月',
    range: {
      from: getAMonthAgo(),
      to: getToday(),
    },
  },
  {
    key: '6m',
    label: '過去6ヶ月',
    range: {
      from: getAHalfYearAgo(),
      to: getToday(),
    },
  },
  {
    key: '1y',
    label: '過去1年',
    range: {
      from: getAYearAgo(),
      to: getToday(),
    },
  },
];

export const MAX_ORGANIZATION_TAG_NAME_LENGTH = 40;
export const NOTIFICATION_TYPE: {
  [key in Uppercase<NotificationItem['type']>]: Lowercase<key>;
} = {
  MENTION: 'mention',
  REPLY_COMMENT: 'reply_comment',
  COMMENT_REACTION: 'comment_reaction',
  GROUP_INVITATION: 'group_invitation',
  FIRST_PERSONAL_NEWS: 'first_personal_news',
  THEME_INVITATION: 'theme_invitation',
  UPDATE_THEME_FEED: 'update_theme_feed',
  FORCE_THEME_FAVORITE: 'force_theme_favorite',
  GROUP_MARK: 'group_mark',
  GROUP_COMMENT: 'group_comment',
};

export const NOTIFICATION_GROUPINGS = {
  mentions: [NOTIFICATION_TYPE.MENTION, NOTIFICATION_TYPE.REPLY_COMMENT],
  members: [
    NOTIFICATION_TYPE.GROUP_INVITATION,
    NOTIFICATION_TYPE.COMMENT_REACTION,
    NOTIFICATION_TYPE.THEME_INVITATION,
    NOTIFICATION_TYPE.FORCE_THEME_FAVORITE,
    NOTIFICATION_TYPE.GROUP_MARK,
    NOTIFICATION_TYPE.GROUP_COMMENT,
  ],
  others: [
    NOTIFICATION_TYPE.FIRST_PERSONAL_NEWS,
    NOTIFICATION_TYPE.UPDATE_THEME_FEED,
  ],
} satisfies Record<NotificationGroupingName, NotificationType[]>;

export const MAX_IP_ADDRESS_RESTRICTION_DESCRIPTION = 20;
export const MAX_IP_ADDRESS_RESTRICTIONS = 50;
export const MAX_IP_ADDRESS_RESTRICTION_FORM = 10;

// search expanded queryのキャッシュ期間(ms)
export const SEARCH_EXPANDED_QUERY_CACHE_EXPIRATION = 1000 * 60 * 60 * 24 * 7; // 1 week

// expanded queryのindexeddbストア名
export const SEARCH_EXPANDED_QUERY_CACHE_STORE_NAME = 'searchExpandedQueries';
export const USER_DOCUMENT_SEARCH_EXPANDED_QUERY_CACHE_STORE_NAME =
  'userDocumentSearchExpandedQueries';

// sharepoint登録エラー
export const SHAREPOINT_ERROR_MESSAGES: { [key: string]: string } = {
  'credential is expired': '認証情報が期限切れです',
  'required role is missing': '必要な権限がありません',
  'invalid credential': '認証情報が無効です',
  'site not found': 'サイトが見つかりません',
  'invalid site url': 'サイトのURLが無効です',
  'token type is not allowed': 'SharePointへの連携が許可されていません',
  'sharepoint rest forbidden': 'SharePointへのアクセス権限が設定されていません',
  'required sharepoint rest api permission is missing':
    'SharePointへのアクセスに必要な権限がありません',
};

// contractの上限エラー
export const CONTRACT_UPPER_LIMIT_ERROR_MESSAGES: { [key: string]: string } = {
  all_upper_limit:
    'すべての契約が上限に達しています。\nメンバーを追加する場合は、不要なメンバーの削除、またはサポート担当までご連絡下さい。',
  upper_limit:
    'この契約は上限に達しています。\nこの契約をお選びいただく場合は、該当契約に紐づいている不要なメンバーを削除、またはサポート担当までご連絡下さい。',
};

/**
 * ### PESTの各カテゴリーの配列
 * - 以下の4つのカテゴリーに対応する要素を持つ
 *   - 政治: politics
 *   - 経済: economy
 *   - 社会: society
 *   - 技術: technology
 */
export const pests = ['politics', 'economy', 'society', 'technology'] as const;

export const pestNames = {
  politics: '政治カテゴリー',
  economy: '経済カテゴリー',
  society: '社会カテゴリー',
  technology: '技術カテゴリー',
} as const satisfies Record<(typeof pests)[number], string>;

export const DOC_TYPE_LABELS = {
  article: 'ニュース',
  research_paper: '論文',
  patent: '特許',
  report: 'レポート',
  user_document: '社内情報',
} as const;

// 社内情報プレビュー用画像生成用テンプレートのページ番号プレースホルダ
export const PAGE_NUMBER_PLACEHOLDER = encodeURIComponent('{page_number}');
