<script lang="ts">
import {
  computed,
  defineComponent,
  nextTick,
  PropType,
  ref,
  toRefs,
} from 'vue';
import { useRoute } from 'vue-router';
import api from '@/api';
import { PageName } from '@/api/tracking';
import { DgrIcon } from '@stockmarkteam/donguri-ui';
import AdpDocumentCardUserAction from '@/components/common/adp-document/adp-document-card-user-action.vue';
import AdpDocumentBasicInfo from '@/components/common/adp-document/basic-info.vue';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import { AdpDocument } from '@/types';
import { useStore } from '@/utils/vue';

export default defineComponent({
  components: {
    AdpDocumentCardUserAction,
    AdpDocumentBasicInfo,
    DgrIcon,
  },
  props: {
    pageName: { type: String as PropType<PageName>, required: true },
    relatedArticles: { type: Array as PropType<AdpDocument[]>, required: true },
    relatedQuery: { type: String, default: '', required: true },
    requestId: { type: String, default: '' },
  },
  emits: ['close'],
  setup(props) {
    const route = useRoute();
    const store = useStore();
    const { createSnackbar } = useSnackbar();

    // NOTE: 計測ログなど実行時にroute.fullPathを使用すると、
    // route.fullPathで取得する前に画面遷移など行われて、
    // 想定と異なるパスが取得される可能性があるため定数で保持する
    const PAGE_URL = route.fullPath;

    const isMarkingArticle = ref(false);
    const { relatedArticles, relatedQuery } = toRefs(props);
    const userInfo = computed(() => store.state.userInfo.userInfo);
    const articleContainer = ref(undefined as HTMLDivElement | undefined);
    const currentArticleIndex = ref(0);
    const adpDocument = computed(
      () => relatedArticles.value?.[currentArticleIndex.value],
    );
    const hasMyMark = computed(() =>
      adpDocument.value?.marks.find(m => m.user_id === userInfo.value?.id),
    );
    const showAnotherArticle = () => {
      if (currentArticleIndex.value == 0) {
        currentArticleIndex.value = 1;
        articleContainer.value?.scrollBy({
          left: articleContainer.value.scrollWidth,
        });
      } else {
        currentArticleIndex.value = 0;
        articleContainer.value?.scrollBy({
          left: -articleContainer.value.scrollWidth,
        });
      }
    };
    const markArticle = async () => {
      if (isMarkingArticle.value || !userInfo.value || !adpDocument.value)
        return;
      isMarkingArticle.value = true;
      if (hasMyMark.value) {
        await removeMyMark(userInfo.value.id, adpDocument.value);
      } else {
        await sendMark(adpDocument.value);
      }
      await nextTick();
      isMarkingArticle.value = false;
    };
    const removeMyMark = async (userId: number, article: AdpDocument) => {
      try {
        const myMark = article.marks.find(mark => mark.user_id === userId);
        if (myMark) {
          await api.deleteMark(myMark.id);
          article.marks = article.marks.filter(mark => mark.id !== myMark.id);
          if (adpDocument.value) {
            adpDocument.value.marks = article.marks;
          }
        }
      } catch (err) {
        createSnackbar({
          message: 'マークを取り消せませんでした',
          type: 'error',
        });
        throw err;
      }
      createSnackbar({
        message: 'マークを取り消しました',
        type: 'success',
      });
    };
    const sendMark = async (article: AdpDocument) => {
      try {
        const createdMark = await api.sendMark(
          article,
          null,
          'article',
          {
            feature: 'related_contents',
            pageName: props.pageName,
            pageUrl: PAGE_URL,
          },
          currentArticleIndex.value + 1,
          undefined,
          undefined,
          undefined,
          {
            event_name: 'related_contents',
            request_id: props.requestId,
            search_query: props.relatedQuery,
          },
        );
        if (userInfo.value?.user_name) {
          article.marks.push({
            ...createdMark,
            user_name: userInfo.value?.user_name,
          });
        }
      } catch (err) {
        createSnackbar({
          message: 'マークできませんでした',
          type: 'error',
        });
        throw err;
      }
      createSnackbar({
        type: 'link',
        message: 'マイページ',
        params: {
          link: `/users/${userInfo.value?.id}`,
          suffixText: 'にマークしました',
        },
      });
    };
    const setupModal = () => {
      store.commit('modal/setArticle', adpDocument.value);
      store.commit('modal/setPageName', props.pageName);
      store.commit('modal/setMarkSource', 'article');
      store.commit('modal/setFeature', 'related_contents');
      store.commit('modal/setContentsContext', {
        event_name: 'related_contents',
        request_id: props.requestId,
        search_query: props.relatedQuery,
      });
      store.commit('modal/setRankInWholeFeed', currentArticleIndex.value + 1);
    };
    const showCreateCommentModal = () => {
      setupModal();
      store.commit('modal/showModal', 'commentCreate');
    };
    const showGroupMarkModal = () => {
      setupModal();
      store.commit('modal/showModal', 'groupMark');
    };
    const showMarkModal = () => {
      setupModal();
      store.commit('modal/showModal', 'mark');
    };
    const setRelatedQuery = (
      requestId: string,
      relatedQuery: string,
      rank: number,
    ) => {
      localStorage.setItem(
        'relatedQuery',
        JSON.stringify({
          feature: 'related_contents',
          requestId: requestId,
          searchQuery: relatedQuery,
          rank: rank,
        }),
      );
    };

    const queryWords = computed(() => relatedQuery.value.split(' '));

    return {
      articleContainer,
      adpDocument,
      currentArticleIndex,
      isMarkingArticle,
      markArticle,
      setRelatedQuery,
      showAnotherArticle,
      showCreateCommentModal,
      showGroupMarkModal,
      showMarkModal,
      queryWords,
    };
  },
});
</script>

<template>
  <div class="related-articles-display c-text c-text--m">
    <div class="header">
      <div class="related-articles-label c-text c-text--s">
        <DgrIcon name="sparkles-fill" />
        <span>
          <template
            v-for="(query, i) in queryWords"
            :key="`query-${i}-${query}`"
          >
            <span class="c-title c-title--s">「{{ query }}」</span>
            <template v-if="i < queryWords.length - 1">と</template>
          </template>
          に関するニュースを見つけました。
        </span>
      </div>
      <DgrIcon
        size="small"
        :keep-fill="false"
        name="times"
        class="clickable close-icon"
        @click="$emit('close')"
      />
    </div>
    <template v-if="adpDocument">
      <div
        :class="relatedArticles.length === 1 ? 'one-article' : 'two-articles'"
        :ref="el => (articleContainer = el as HTMLDivElement)"
      >
        <template v-for="(article, index) in relatedArticles" :key="article.id">
          <div class="article-container">
            <AdpDocumentBasicInfo
              :adp-document="article"
              :page-name="pageName"
              :feature="'related_contents'"
              :rank-in-whole-feed="index + 1"
              :contents-context="{
                event_name: 'related_contents',
                request_id: requestId,
                search_query: relatedQuery,
              }"
              :is-related-article="true"
              size="m"
            ></AdpDocumentBasicInfo>
            <AdpDocumentCardUserAction
              :adp-document="article"
              :comments="article.comments"
              :has-action-menu="true"
              :is-undisplay-button-shown="false"
              :is-site-exclusion-button-shown="false"
              :is-marking-article="isMarkingArticle"
              @click-mark-icon="markArticle"
              @click-open-mark-modal="showMarkModal"
              @click-comment-icon="showCreateCommentModal"
              @click-group-mark-icon="showGroupMarkModal"
            ></AdpDocumentCardUserAction>
          </div>
        </template>
      </div>
      <div class="query-container">
        <DgrIcon size="xs" name="search" class="primary search-icon" />
        <router-link
          :to="{
            name: 'searchAll',
            query: { query: relatedQuery },
          }"
          target="_blank"
          rel="noopener noreferrer"
          class="related-query"
          @click="
            setRelatedQuery(requestId, relatedQuery, currentArticleIndex + 1)
          "
        >
          <span class="c-title c-title--s">{{ relatedQuery }}で検索する</span>
        </router-link>
      </div>
      <div v-if="relatedArticles.length > 1">
        <button
          @click="showAnotherArticle"
          class="another-article-button"
          :class="{
            right: currentArticleIndex == 0,
            left: currentArticleIndex == 1,
          }"
        >
          <DgrIcon
            size="small"
            name="angle-right"
            v-if="currentArticleIndex == 0"
          />
          <DgrIcon size="small" name="angle-left" v-else />
        </button>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.related-articles-display {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  background: $color-gray200;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px;

  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;

  .icon-box {
    flex-shrink: 0;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .related-articles-label {
      display: flex;
      align-items: center;
    }

    .close-icon {
      fill: $color-gray600;
    }
  }

  .article-container {
    border-radius: 4px;
    border: 1px solid $color-border;
    background: #fff;
    display: flex;
    flex-direction: column;
    padding: 8px;
    margin-top: 12px;
  }

  .query-container {
    display: flex;
    align-items: center;
    margin-top: 10px;
    z-index: 1;
    .search-icon {
      margin-right: 4px;
    }
    .related-query {
      color: $color-green600;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &:hover .another-article-button {
    display: flex;
  }
  .another-article-button {
    display: none;
    align-items: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: #ffffff;
    border: none;
    cursor: pointer;
    border-radius: 50%;
    padding: 0;
    width: 28px;
    height: 28px;
    box-shadow: 0px 1px 5px 0px rgba(74, 74, 74, 0.25);

    &.left {
      left: 0px;
    }
    &.right {
      right: 0px;
    }
  }

  .one-article {
    position: relative;
    width: 100%;
    .article-container {
      width: auto;
    }
  }
  .two-articles {
    align-items: center;
    position: relative;
    width: 100%;
    display: flex;
    gap: 4px;
    overflow: hidden;
    padding-bottom: 40px;
    margin-bottom: -40px;
    .article-container {
      width: 90%;
      flex-shrink: 0;
    }
  }
}
</style>
