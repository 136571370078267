<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import { NotificationGroupMarkType } from '@/types';
import CardTemplate from './card-template.vue';

// WARN: 通知の表記を変更するときは serviceWorkerHelper.ts の変更漏れがないかも確認する
// WARN: 通知のURLを変更するときは web-push.vue の変更漏れがないかも確認する

export default defineComponent({
  components: {
    CardTemplate,
  },
  props: {
    notification: {
      type: Object as PropType<NotificationGroupMarkType>,
      required: true,
    },
    isUnread: { type: Boolean, required: true },
  },
  setup(props) {
    const url = computed(
      () =>
        `/groups/${props.notification.group.id}/marks?doc_type=${props.notification.document.doc_type}&document_id=${props.notification.document.id}`,
    );

    return {
      url,
    };
  },
});
</script>
<template>
  <CardTemplate
    :type="notification.type"
    :url="url"
    :is-unread="isUnread"
    :created-at="notification.created_at"
    :user-id="notification.creator_id"
    :image-url="notification.creator_image_url"
    :content-title="notification.document.title"
    :group-name="notification.group.name"
  >
    グループに新しいグループマークがあります。
  </CardTemplate>
</template>
