<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import api from '@/api';
import TechnicalLiteratureFeed from '@/components/common/technical-literature/technical-literature-feed.vue';
import { FeedType, ThemeFeedPatent } from '@/types';
import { useTheme, useThemePatentFeed, useUserInfo } from '@/utils/swr';
import { useStore } from '@/utils/vue';

const router = useRouter();
const route = useRoute();
const store = useStore();

const { data: userInfo } = useUserInfo();
const feedType = computed(() => store.state.feedType.feedType as FeedType);
const themeId = computed(() => Number(route.params.themeId));
const { data: themeResponse } = useTheme(themeId.value);
const theme = computed(() => themeResponse.value?.themes);

const isForeignMediaEnabled = computed(
  () => theme.value?.is_foreign_media_enabled ?? false,
);

const feedCount = computed(() => {
  if (feedType.value === 'foreign' && !isForeignMediaEnabled.value) {
    // 海外メディア設定がオフの場合、カウントを表示しない
    return undefined;
  }
  const counts = theme.value?.patent_feed_counts ?? {
    domestic: 0,
    foreign: 0,
  };
  return feedType.value === 'all'
    ? counts.domestic + counts.foreign
    : counts[feedType.value];
});

const redirectToKeywordFeed = () => {
  router.push({
    name: 'themeFeed',
    params: { themeId: String(themeId.value) },
  });
};

watch(
  () => theme.value,
  () => {
    if (theme.value && !theme.value?.is_research_paper_enabled) {
      redirectToKeywordFeed();
    }
  },
);

onMounted(async () => {
  api.trackPageView({
    pageName: 'patents',
    pageUrl: route.fullPath,
    theme: {
      id: themeId.value,
    },
    feedType: feedType.value,
  });
});

const enableToEditTheme = computed(
  () =>
    userInfo.value?.role !== 'viewer' ||
    (theme.value?.access_scope === 'personal' &&
      theme.value?.user_id === userInfo.value?.id),
);

const { data: domesticPatentResponse, state: domesticPatentState } =
  useThemePatentFeed(feedType, themeId.value, 'domestic', ref(1), 200);

const domesticPatents = computed(() => ({
  items:
    domesticPatentResponse.value?.patent_feeds ?? ([] as ThemeFeedPatent[]),
  state: domesticPatentState.value,
}));

const feedDate = computed(
  () => theme.value?.research_paper_feed_last_updated_at ?? undefined,
);
</script>

<template>
  <TechnicalLiteratureFeed
    v-if="theme"
    feed="theme"
    doc-type="patent"
    :feed-type="feedType"
    :feed-count="feedCount"
    :feed-date="feedDate"
    :domestic-documents="domesticPatents"
    :theme="theme"
    :enable-to-edit-theme="enableToEditTheme"
  />
</template>

<style lang="scss" scoped></style>
