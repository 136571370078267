import axios, {
  AxiosProgressEvent,
  AxiosRequestConfig,
  CreateAxiosDefaults,
} from 'axios';
import { AnewsStorageUploadUrlsResponse } from '@/types';

export async function getAnewsStorageUploadUrls(
  partCount: number,
): Promise<AnewsStorageUploadUrlsResponse> {
  const params = { part_count: partCount };
  const { data } = await axios.get<AnewsStorageUploadUrlsResponse>(
    `/contents/user_documents/anews_storage_upload_urls`,
    { params },
  );
  return data;
}

export async function postAnewsStorageUploadComplete(
  uploadFileKey: string,
  uploadId: string | undefined,
): Promise<void> {
  await axios.post(`/contents/user_documents/anews_storage_upload_complete`, {
    upload_file_key: uploadFileKey,
    upload_id: uploadId,
  });
}

export async function putAnewsStorageUpload(
  url: string,
  file: File | Uint8Array,
  onUploadProgress: (loaded: number, total: number | undefined) => void,
  signal: AbortSignal,
): Promise<void> {
  const onAxiosUploadProgress = (progressEvent: AxiosProgressEvent) => {
    onUploadProgress(progressEvent.loaded, progressEvent.total);
  };

  const config: AxiosRequestConfig = {
    responseType: 'blob',
    onUploadProgress: onAxiosUploadProgress,
    validateStatus: null,
    signal,
  };

  // アップロードのレスポンスのcontent-typeがundefinedなので、interceptorsのエラー処理(index.ts)をスキップする
  const axiosConfig: CreateAxiosDefaults = {
    // デフォルトの35秒タイムアウトは短いため、10分のタイムアウトにする
    timeout: 10 * 60 * 1000,
  };
  await axios
    .create(axiosConfig)
    .put(url, file, config)
    .then(response => {
      // 404エラーがthrowされないため、ここで成功したかを判断
      if (response.status !== 200) {
        throw new Error('Failed to upload file');
      }
    });
}
