<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import api from '@/api';
import Avatar from '@/components/common/atoms/avatar.vue';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import AdminContent from '@/components/layouts/admin-content.vue';
import Header from '@/components/layouts/header.vue';
import {
  STATES,
  useManagementContract,
  useManagementContractManagingUsers,
  useTeamUsersWithAddUserOptions,
} from '@/utils/swr';
import { checkMoveToAdmin } from '@/utils/user';
import { useEmitter } from '@/utils/vue';
import AddUsersModal, { AddableUser } from '../../modals/add-users-modal.vue';
import AdminBaseTable, { Field } from '../admin-base-table.vue';
import ActionMenuForMembersTable from '../members/action-menu-for-members-table.vue';

type Item = {
  imageUrl: string;
  name: string;
  email: string;
  id: number;
};

const route = useRoute();
const router = useRouter();
const { createSnackbar } = useSnackbar();
const emitter = useEmitter();

const isOpenAddUsersModal = ref(false);
const contractId = computed(() => Number(route.params.contractId));
const { data: managementContract } = useManagementContract(contractId);
const contractName = computed(
  () => managementContract.value?.management_contract?.contract_name ?? '',
);
const {
  data: managingUsers,
  mutate,
  state: managingUsersState,
} = useManagementContractManagingUsers(contractId);
const users = computed(() => managingUsers.value?.users ?? []);
const { data: teamUsers, state: teamUsersState } =
  useTeamUsersWithAddUserOptions();
const fields: Field<Item>[] = [
  {
    name: 'name',
    displayName: '名前',
    isSortable: true,
    type: 'string',
    minWidth: 300,
    fieldPosition: 'left',
    cellPosition: 'left',
  },
  {
    name: 'actions',
    displayName: '',
    isSortable: false,
    type: 'string',
    fieldPosition: 'center',
    cellPosition: 'center',
  },
];

const items = computed<Item[]>(() => {
  return users.value.map(user => {
    return {
      imageUrl: user.image_url,
      name: user.user_name,
      email: user.email,
      id: user.id,
    };
  });
});

const addableUserList = computed<AddableUser[]>(() => {
  const alreadyAddedUserIds = managingUsers.value?.users.map(u => u.id) ?? [];
  return (
    teamUsers.value?.users.filter(
      u =>
        u.is_deleted === false &&
        !alreadyAddedUserIds.includes(u.id) &&
        u.role === 'admin',
    ) ?? []
  );
});

const isAddModalLoading = computed(
  () =>
    managingUsersState.value === STATES.PENDING ||
    managingUsersState.value === STATES.VALIDATING ||
    teamUsersState.value === STATES.PENDING ||
    teamUsersState.value === STATES.VALIDATING,
);

const actionMenuItem = ref<Item | undefined>(undefined);
const closeActionMenu = () => (actionMenuItem.value = undefined);
onBeforeUnmount(() => document.removeEventListener('click', closeActionMenu));
onMounted(() => {
  checkMoveToAdmin(router);
  document.addEventListener('click', closeActionMenu);
});

const openAddUsersModal = async () => {
  isOpenAddUsersModal.value = true;
};

const closeAddUserModal = () => {
  isOpenAddUsersModal.value = false;
};

const addManagingUsers = async (userIds: number[]) => {
  try {
    await api.addManagementContractManagingUsers(contractId.value, userIds);
    createSnackbar({
      message: `契約管理者に追加しました`,
      type: 'success',
    });
    mutate();
  } catch (e) {
    createSnackbar({
      message: '契約管理者に追加できませんでした',
      type: 'error',
    });
    throw e;
  }
  closeAddUserModal();
};

const deleteManagingUser = async (item: Item) => {
  try {
    await api.deleteManagementContractManagingUser(contractId.value, item.id);
    mutate();
    createSnackbar({
      message: 'メンバーを解除しました',
      type: 'success',
    });
  } catch (err) {
    createSnackbar({
      message: '解除できませんでした',
      type: 'error',
    });
    throw err;
  }
};

emitter.on('contract-member-added', () => {
  mutate();
});
</script>

<template>
  <div class="contract-admin">
    <Header title="契約管理" header-width="100%" />
    <AdminContent>
      <div class="contract-content">
        <div class="members">
          <div class="header">
            <div>
              <span class="c-title c-title--m">{{ contractName }}</span>
              <span class="contract-managing-user-count c-text c-text--s">
                ・{{ users.length }}名
              </span>
            </div>
            <button
              class="add-member-button c-btn--small c-btn--auto c-btn--AnewsPrimary c-text c-text--m"
              @click="openAddUsersModal()"
            >
              メンバー追加
            </button>
          </div>
          <div class="spacing-16"></div>
          <AdminBaseTable
            class="contract-managing-user-table"
            :fields="fields"
            :items="items"
          >
            <template #cell-name="{ item }">
              <div class="contract-managing-user-name">
                <Avatar
                  :image-url="item.imageUrl"
                  size="xs"
                  class="user-avatar"
                />
                <div>
                  <div class="name">
                    <span class="c-text c-text--m">{{ item.name }}</span>
                  </div>
                  <div class="email">
                    <span class="c-text c-text--s">{{ item.email }}</span>
                  </div>
                </div>
              </div>
            </template>
            <template #cell-actions="{ item }">
              <ActionMenuForMembersTable class="actions m-edit-menu-ellipsis">
                <div class="remove-button-container">
                  <button
                    type="button"
                    class="c-text--m"
                    @click="deleteManagingUser(item)"
                  >
                    解除
                  </button>
                </div>
              </ActionMenuForMembersTable>
            </template>
          </AdminBaseTable>
        </div>
      </div>
    </AdminContent>
    <AddUsersModal
      :is-open="isOpenAddUsersModal"
      :joinable-users="addableUserList"
      @on-close="closeAddUserModal"
      @on-submit="addManagingUsers"
      :is-use-role-option="false"
      :is-loading="isAddModalLoading"
    />
  </div>
</template>

<style lang="scss" scoped>
.contract-admin {
  margin: -24px 0 0 0;
  .contract-content {
    display: flex;
    justify-content: center;
  }

  .contract-managing-user-name {
    display: flex;
    align-items: center;
    .user-avatar {
      margin-right: 12px;
    }
    .email {
      color: #b3b3b3;
      font-size: 10px;
    }
  }
  .members {
    min-width: 480px;
    max-width: fit-content;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .add-member-button {
        padding-top: 4px;
      }
      .contract-managing-user-count {
        color: #b3b3b3;
      }
    }
    .contract-managing-user-table {
      margin-bottom: 30px;
    }
  }

  .remove-button-container {
    box-shadow: 0px 1px 5px rgba(74, 74, 74, 0.25);
    border-radius: $border-radius;
    width: max-content;
    padding: 11px 0;
    background-color: #fff;

    button {
      cursor: pointer;
      border: none;
      outline: none;
      appearance: none;
      width: 100%;
      height: auto;
      padding: 5px 12px;
      background-color: #fff;

      &:hover {
        background-color: #f2f2f2;
      }
    }
  }
}
</style>
