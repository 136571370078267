import { InjectionKey, Ref } from 'vue';

export const adpDocumentCardWrapperRefKey = Symbol(
  'adpDocumentCardWrapperRef',
) as InjectionKey<Ref<HTMLElement | null>>;

export const visibleAdpCardKey = Symbol(
  'adpDocumentCardWrapperRef',
) as InjectionKey<Ref<boolean>>;

export const closeAllDocumentsKey = Symbol(
  'closeAllDocumentsKey',
) as InjectionKey<Ref<boolean>>;
