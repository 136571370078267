<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { DgrIcon } from '@stockmarkteam/donguri-ui';

export default defineComponent({
  components: {
    DgrIcon,
  },
  props: {
    inputId: { type: String, default: '' },
    modelValue: { type: String, default: '' },
    hasError: { type: Boolean, default: false },
  },
  emits: {
    'update:modelValue': (_password: string) => true,
    keydown: (_event: KeyboardEvent) => true,
  },
  setup(props, { emit }) {
    const passwordValue = computed({
      get: () => props.modelValue,
      set: value => emit('update:modelValue', value),
    });

    const isPasswordVisible = ref(false);

    const togglePasswordVisible = () => {
      isPasswordVisible.value = !isPasswordVisible.value;
    };

    const keyDown = (event: KeyboardEvent) => {
      emit('keydown', event);
    };

    return {
      passwordValue,
      isPasswordVisible,
      togglePasswordVisible,
      keyDown,
    };
  },
});
</script>

<template>
  <div class="password-input">
    <input
      :id="inputId"
      class="password c-textInput"
      name="password"
      placeholder=""
      :type="!isPasswordVisible ? 'password' : 'text'"
      :class="{ 'c-formInput--error': hasError }"
      v-model="passwordValue"
      @keydown="keyDown"
    />
    <div class="eye-switch">
      <div class="eye-icon" @click="togglePasswordVisible">
        <DgrIcon :name="isPasswordVisible ? 'eye' : 'eye-alt'" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.password-input {
  position: relative;
  .password {
    outline: none;
    padding-right: 48px;
  }
  .eye-switch {
    position: absolute;
    top: 0;
    right: 0;
    .eye-icon {
      padding: 8px;
      cursor: pointer;
    }
  }
}
</style>
