import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { NAMES_FOR_REDIRECT_FROM_USER_TO_MYPAGE } from '@/routers/constants';
import { userSession } from '@/utils/userSession';

export function shouldRedirectToLogin(to: RouteLocationNormalized) {
  // https://router.vuejs.org/ja/guide/advanced/meta.htmlを参考に実装
  if (
    !to.matched.some(
      record =>
        record.meta.type === 'account' ||
        record.meta.type === 'mobile_app_download' ||
        record.meta.type === 'no_login_required_redirect',
    )
  ) {
    if (!userSession.isActive()) {
      return true;
    }
  }
  return false;
}

export function shouldRedirectToMypage(to: RouteLocationNormalized) {
  if (
    to.matched.some(record => record.meta.type === 'user') &&
    to.params.userId
  ) {
    return userSession.getUserId() === Number(to.params.userId);
  }
  return false;
}

export function redirectToMypage(
  to: RouteLocationNormalized,
  next: NavigationGuardNext,
) {
  const name =
    NAMES_FOR_REDIRECT_FROM_USER_TO_MYPAGE[
      to.name as keyof typeof NAMES_FOR_REDIRECT_FROM_USER_TO_MYPAGE
    ];
  if (name) {
    next({ name });
  }
}
