// 右クリック時にリンクをリダイレクトリンクに変更するためのメソッド
// コンテキストメニューの別タブで表示を選択した場合に、閲覧をカウントするために必要
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setRedirectUrl = (event: Event, sendParams: any) => {
  const element = event.currentTarget as HTMLAnchorElement;
  const originalUrl = element.href;
  const sendViewParams = sendParams;
  localStorage.setItem(
    'redirectSendViewParams',
    JSON.stringify(sendViewParams),
  );
  element.href = `/#/redirect?url=${encodeURIComponent(originalUrl)}`;
};

// リダイレクトリンクを元のリンクに戻すためのメソッド
export const unsetRedirectUrl = (event: Event) => {
  const linkElement = event.target as HTMLAnchorElement;
  const redirectUrl = linkElement.href;
  const addedWordForRedirect = '/#/redirect?url=';
  const originalUrlPosition = redirectUrl.indexOf(addedWordForRedirect);
  if (originalUrlPosition === -1) return;
  const originalUrl = decodeURIComponent(
    redirectUrl.substring(originalUrlPosition + addedWordForRedirect.length),
  );
  linkElement.href = originalUrl;
};
