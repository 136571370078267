<script setup lang="ts">
import { computed, ref } from 'vue';
import { MAX_TOOLTIP_LIST_LENGTH, USER_MARK_SHARE } from '@/constants';
import { DgrIcon } from '@stockmarkteam/donguri-ui';

const emit = defineEmits<{
  handlePopupMenuClick: [];
  handleMarkClick: [];
}>();

const props = defineProps<{
  isMarked: boolean;
  markUsers: {
    id: number;
    name: string;
  }[];
}>();

const isInteractingMarkButton = ref(false);

const isShowPopupMenu = computed(
  () => isInteractingMarkButton.value && !props.isMarked,
);

const markCount = computed(() => props.markUsers.length);

const tooltipContents = computed(() => {
  return props.markUsers.slice(0, MAX_TOOLTIP_LIST_LENGTH);
});

const otherMarkCount = computed(() => {
  return markCount.value - MAX_TOOLTIP_LIST_LENGTH;
});

const handlePopupMenuButtonClick = () => {
  isInteractingMarkButton.value = false;
  emit('handlePopupMenuClick');
};

const handleMarkButtonClick = () => {
  isInteractingMarkButton.value = false;
  emit('handleMarkClick');
};
</script>

<template>
  <div
    class="mark-button-field"
    :class="{ 'mark-button-active': isMarked }"
    @mouseleave="isInteractingMarkButton = false"
  >
    <VTooltip theme="menu" placement="top" @click="handleMarkButtonClick">
      <div class="mark-button-area">
        <button class="mark-button">
          <DgrIcon
            class="mark-icon"
            size="small"
            :name="isMarked ? 'check-circle-fill' : 'check-circle'"
            :keep-fill="false"
            alt="mark icon"
          />
          <div v-if="markCount > 0" class="mark-total-count c-title c-title--s">
            {{ markCount }}
          </div>
        </button>
      </div>

      <template #popper>
        <template v-if="tooltipContents.length === 0">
          {{ USER_MARK_SHARE }}
        </template>
        <template v-else>
          <template v-for="user in tooltipContents" :key="user.id">
            <router-link
              :to="{ name: 'userMarks', params: { userId: user.id } }"
            >
              ・{{ user.name }}
            </router-link>
            <br />
          </template>
          <p v-if="otherMarkCount > 0">
            {{ `他${otherMarkCount}名` }}
          </p>
        </template>
      </template>
    </VTooltip>
    <button
      v-if="!isMarked"
      class="arrow-button-area"
      @mouseover="isInteractingMarkButton = true"
    >
      <DgrIcon
        size="small"
        class="arrow-icon"
        :name="isInteractingMarkButton ? 'angle-up' : 'angle-down'"
        alt="arrow icon"
      />
    </button>
    <div v-if="isShowPopupMenu" class="action-menu-container">
      <button
        class="popup-menu-item c-text c-text--m"
        @click="handlePopupMenuButtonClick"
      >
        マイタグ・マイメモをしてマーク
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.mark-button-field {
  position: relative;
  width: fit-content;
  display: flex;
  min-width: 60px;
  border-radius: 4px;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: $color-gray200;
  }

  .action-menu-container {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: var(--z-action-menu);
    width: 240px;
    padding: 8px 0;
    background: #fff;
    border-radius: 4px;
    border: 1px solid $color-gray400;
    cursor: default;
  }
}

.mark-button-area {
  display: flex;
  align-items: center;
  padding: 8px 0px 8px 10px;
}

.mark-button {
  all: unset;
  display: flex;
  align-items: center;
}

.mark-icon {
  margin-right: 4px;
}

.arrow-button-area {
  all: unset;
  display: flex;
  align-items: center;
  padding: 8px 10px 8px 0;
}

.popup-menu-item {
  border-radius: unset;
  background-color: unset;
  width: 100%;
  cursor: pointer;
  padding: 4px 12px;
  height: auto;
  border: none;

  &:hover {
    background: $color-gray200;
  }
}

.arrow-icon {
  padding-left: 4px;
}

.mark-button-active {
  & .mark-icon {
    fill: $color-green600;
  }

  & .mark-total-count {
    color: $color-green600;
  }

  & .mark-button-area {
    width: 100%;
  }
}
</style>
