import axios from 'axios';
import {
  AdminGroup,
  AdminUserInfo,
  IpAddressRestriction,
  MailDeliverySchedule,
  MSTeamsSettings,
  SharepointCredential,
  SharepointSite,
  UserDocumentStorageStatus,
} from '@/types';
import { userSession } from '@/utils/userSession';

export async function fetchUsersForAdmin(): Promise<AdminUserInfo[]> {
  const myTeamId = userSession.getTeamId();
  const { data } = await axios.get<{ users: AdminUserInfo[] }>(
    `/admin/teams/${myTeamId}/users`,
  );
  return data.users;
}

export async function fetchGroupsForAdmin(): Promise<AdminGroup[]> {
  const myTeamId = userSession.getTeamId();
  const { data } = await axios.get<{ groups: AdminGroup[] }>(
    `/admin/teams/${myTeamId}/groups`,
  );
  return data.groups;
}

export async function updateMSTeamsSettings(
  settings: MSTeamsSettings,
): Promise<MSTeamsSettings> {
  const myTeamId = userSession.getTeamId();
  const { data } = await axios.put<MSTeamsSettings>(
    `/admin/teams/${myTeamId}/ms_teams_settings`,
    settings,
  );
  return data;
}

export async function fetchMSTeamsSettings(): Promise<MSTeamsSettings> {
  const myTeamId = userSession.getTeamId();
  const { data } = await axios.get<MSTeamsSettings>(
    `/admin/teams/${myTeamId}/ms_teams_settings`,
  );
  return data;
}

// HTTPコードを返す
export async function headTeamBoxApiToken(): Promise<number> {
  const myTeamId = userSession.getTeamId();
  return await axios
    .head<void>(`/admin/teams/${myTeamId}/box_api_token`)
    .then(response => response.status)
    .catch(error => error.response.status);
}

export async function deleteTeamBoxApiToken(): Promise<void> {
  await axios.delete<void>(
    `/admin/teams/${userSession.getTeamId()}/box_api_token`,
  );
}

export async function getTeamStorageStatus(
  storageType: 'box' | 'share_point' | 'anews_storage',
): Promise<UserDocumentStorageStatus> {
  const { data } = await axios.get<UserDocumentStorageStatus>(
    `/admin/teams/${userSession.getTeamId()}/storage_status`,
    {
      params: {
        storage_type: storageType,
      },
    },
  );
  return data;
}

// HTTPコードを返す
export async function headTeamSharepointCredential(): Promise<number> {
  const myTeamId = userSession.getTeamId();
  return await axios
    .head<void>(`/admin/teams/${myTeamId}/sharepoint_credential`)
    .then(response => response.status)
    .catch(error => error.response.status);
}

export async function getSharepointCredential(): Promise<SharepointCredential> {
  const myTeamId = userSession.getTeamId();
  const { data } = await axios.get<SharepointCredential>(
    `/admin/teams/${myTeamId}/sharepoint_credential`,
  );
  return data;
}

export async function postSharepointCredential(
  clientSecret: string,
  clientId: string,
  tenantId: string,
  siteUrl?: string,
): Promise<void> {
  const myTeamId = userSession.getTeamId();
  await axios.post<void>(`/admin/teams/${myTeamId}/sharepoint_credential`, {
    client_id: clientId,
    client_secret: clientSecret,
    tenant_id: tenantId,
    site_url: siteUrl,
  });
}

export async function putSharepointCredential(
  clientSecret: string,
  clientId?: string,
  tenantId?: string,
  siteUrl?: string,
): Promise<void> {
  const myTeamId = userSession.getTeamId();
  await axios.put<void>(`/admin/teams/${myTeamId}/sharepoint_credential`, {
    client_id: clientId,
    client_secret: clientSecret,
    tenant_id: tenantId,
    site_url: siteUrl,
  });
}

export async function deleteSharepointCredential(): Promise<void> {
  const myTeamId = userSession.getTeamId();
  await axios.delete<void>(`/admin/teams/${myTeamId}/sharepoint_credential`);
}

export async function getSharepointSites(): Promise<SharepointSite[]> {
  const myTeamId = userSession.getTeamId();
  const { data } = await axios.get<SharepointSite[]>(
    `/admin/teams/${myTeamId}/sharepoint_sites`,
  );
  return data;
}

export async function postOneSharepointSite(siteUrl: string): Promise<void> {
  const myTeamId = userSession.getTeamId();
  await axios.post<void>(`/admin/teams/${myTeamId}/one_sharepoint_site`, {
    site_url: siteUrl,
  });
}

// HTTPコードを返す
export async function headTeamAnewsStorageSyncHistory(): Promise<number> {
  const myTeamId = userSession.getTeamId();
  return await axios
    .head<void>(`/admin/teams/${myTeamId}/anews_storage_sync_history`)
    .then(response => response.status)
    .catch(error => error.response.status);
}

export async function deleteTeamAnewsStorageDatasource(): Promise<void> {
  const myTeamId = userSession.getTeamId();
  await axios.delete<void>(`/admin/teams/${myTeamId}/anews_storage_datasource`);
}

export async function postTeamAnewsStorageDatasource(): Promise<void> {
  const myTeamId = userSession.getTeamId();
  await axios.post<void>(`/admin/teams/${myTeamId}/anews_storage_datasource`);
}

export async function deleteManagementContractManagingUser(
  contractId: number,
  userId: number,
): Promise<void> {
  await axios.delete<void>(
    `/admin/management_contracts/${contractId}/managing_users/${userId}`,
  );
}

export async function addManagementContractManagingUsers(
  contractId: number,
  userIds: number[],
): Promise<void> {
  await axios.put<void>(
    `/admin/management_contracts/${contractId}/managing_users`,
    {
      user_ids: userIds,
    },
  );
}

export async function putEnableIpAddressRestriction(
  enableIPAddressRestriction: boolean,
) {
  const myTeamId = userSession.getTeamId();
  await axios.put<void>(
    `/admin/teams/${myTeamId}/enable_ip_address_restriction`,
    {
      enable_ip_address_restriction: enableIPAddressRestriction,
    },
  );
}

export async function putIpAddressRestriction(
  ipAddressRestriction: IpAddressRestriction,
): Promise<void> {
  const myTeamId = userSession.getTeamId();
  await axios.put<void>(
    `/admin/teams/${myTeamId}/ip_address_restrictions/${ipAddressRestriction.id}`,
    {
      ip_address: ipAddressRestriction.ip_address,
      description: ipAddressRestriction.description,
    },
  );
}

export async function deleteIpAddressRestriction(
  ipAddressRestrictionId: number,
): Promise<void> {
  const myTeamId = userSession.getTeamId();
  await axios.delete<void>(
    `/admin/teams/${myTeamId}/ip_address_restrictions/${ipAddressRestrictionId}`,
  );
}

export async function postIpAddressRestrictionBulkCreate(
  ipAddressRestrictions: IpAddressRestriction[],
): Promise<void> {
  const myTeamId = userSession.getTeamId();
  await axios.post<void>(
    `/admin/teams/${myTeamId}/ip_address_restrictions/bulk_create`,
    {
      ip_address_restrictions: ipAddressRestrictions,
    },
  );
}

export async function updateMailDeliverySchedules(
  schedules: MailDeliverySchedule[],
): Promise<void> {
  const teamId = userSession.getTeamId();
  await axios.put<void>(`/admin/teams/${teamId}/mail_delivery_schedules`, {
    mail_delivery_schedules: schedules,
  });
}
