<script setup lang="ts">
import { computed, watch } from 'vue';
import { useRoute } from 'vue-router';
import { NOTIFICATION_GROUPINGS } from '@/constants';
import { DgrIcon } from '@stockmarkteam/donguri-ui';
import { NavigationMenu } from '@/types';
import {
  useGroups,
  useNotificationsUnviewedUnreadCount,
  useUserInfo,
} from '@/utils/swr';

interface Props {
  activeMenu?: NavigationMenu;
}

withDefaults(defineProps<Props>(), {
  activeMenu: '',
});

const { data: userInfo } = useUserInfo();
const { data: groups } = useGroups();

const allUserGroupId = computed(
  () =>
    groups.value?.groups.find(group => group.group_type === 'all_user_group')
      ?.id,
);

const hasPermission = computed(() => userInfo.value?.role === 'admin');

const route = useRoute();
const { mutate: mutateUnviewedUnreadCount } =
  useNotificationsUnviewedUnreadCount(NOTIFICATION_GROUPINGS);
const updateNotifications = async () => {
  mutateUnviewedUnreadCount();
};
/** ルーティングが切り替わる都度通知の再フェッチ */
watch(() => route.path, updateNotifications);
</script>

<template>
  <div class="o-section-container">
    <ul class="o-section-items">
      <li>
        <router-link
          :to="{ name: 'anewsHome' }"
          class="o-section o-section-item"
          :class="{ active: activeMenu === 'news' }"
          target="_self"
        >
          <DgrIcon name="home" :keep-fill="false" class="o-section-icon" />
          <div class="o-section-text">ホーム</div>
        </router-link>
      </li>
      <li>
        <router-link
          :to="{ name: 'mypageProfile' }"
          class="o-section o-section-item"
          :class="{ active: activeMenu === 'my_page' }"
          target="_self"
        >
          <DgrIcon
            name="user-circle"
            :keep-fill="false"
            class="o-section-icon"
          />
          <div class="o-section-text">マイページ</div>
        </router-link>
      </li>
      <li>
        <router-link
          :to="{
            name: 'groupActivity',
            params: { groupId: String(allUserGroupId) },
          }"
          class="o-section o-section-item"
          :class="{ active: activeMenu === 'group' }"
          target="_self"
        >
          <DgrIcon name="users" :keep-fill="false" class="o-section-icon" />
          <div class="o-section-text">グループ</div>
        </router-link>
      </li>
      <li>
        <router-link
          :to="{
            name: 'surveyTop',
          }"
          class="o-section o-section-item"
          :class="{ active: activeMenu === 'survey' }"
          target="_self"
        >
          <DgrIcon
            name="sparkles-fill"
            :keep-fill="false"
            class="o-section-icon"
          />
          <div class="o-section-text">要約</div>
        </router-link>
      </li>
      <li>
        <router-link
          :to="{ name: 'others' }"
          class="o-section o-section-item"
          :class="{ active: activeMenu === 'others' }"
          target="_self"
        >
          <DgrIcon name="bars" :keep-fill="false" class="o-section-icon" />
          <div class="o-section-text">その他</div>
        </router-link>
      </li>
      <li v-if="hasPermission">
        <router-link
          :to="{
            name: 'contracts',
          }"
          class="o-section o-section-item"
          :class="{ active: activeMenu === 'admin' }"
          target="_self"
        >
          <DgrIcon name="cog" :keep-fill="false" class="o-section-icon" />
          <div class="o-section-text">管理・設定</div>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.o-section-container {
  position: fixed;
  display: flex;
  justify-content: center;
  width: 80px;
  height: 100vh;
  padding: 20px 8px;
  box-sizing: border-box;
  border-right: 1px solid $color-gray400;
}

ul.o-section-items {
  list-style: none;
  :hover.o-section-item {
    background-color: $color-gray200;
  }
  :hover.o-section-item.active {
    background: rgba(152, 208, 188, 0.2);
  }
}

.o-section {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 58px;
  border-radius: 4px;
  margin-bottom: 8px;
  &.active {
    background: rgba(152, 208, 188, 0.2);
  }
}

.o-section-text {
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 10px;
  text-align: center;
  margin-top: 6px;
}

/** 親要素がactiveのclassを持つ場合子要素をあわせてスタイルをハイライトする */
.o-section-item.active > .o-section-icon {
  fill: $color-green600;
}
.o-section-item.active > .o-section-text {
  color: $color-green600;
}
</style>
