import getCaretCoordinates from 'textarea-caret';
import { MentionItem } from '@/types';

export const getTextCaretAndIndex = (
  el: HTMLTextAreaElement,
): { textBeforeCaret: string; triggerIdx: number } => {
  const positionIndex = el.selectionStart ?? 0;
  const textBeforeCaret = el.value.slice(0, positionIndex);
  const tokens = textBeforeCaret.split(/\s/);
  const lastToken = tokens[tokens.length - 1];
  const triggerIdx = textBeforeCaret.endsWith(lastToken)
    ? textBeforeCaret.length - lastToken.length
    : -1;
  return { textBeforeCaret: textBeforeCaret, triggerIdx: triggerIdx };
};

export const getIsTriggeredMention = (text: string, index: number): boolean => {
  const maybeTrigger = text[index];
  return maybeTrigger === '@' || maybeTrigger === '＠';
};

export const getMentionList = (
  mentionList: MentionItem[],
  query: string,
): MentionItem[] => {
  const filteredMentionList =
    query === ''
      ? mentionList
      : mentionList.filter(user => user.textInCandidateList.includes(query));
  filteredMentionList.sort((m1, m2) => {
    return (
      m1.textInCandidateList.indexOf(query) -
      m2.textInCandidateList.indexOf(query)
    );
  });
  return filteredMentionList;
};

export const getMentionListPosition = (
  el: HTMLTextAreaElement,
  leftOffset: number = -18,
): { top: number; left: number } => {
  const coords = getCaretCoordinates(el, el.selectionEnd ?? 0);
  const { top, left } = el.getBoundingClientRect();

  const leftPosition = Math.min(
    left + coords.left + leftOffset,
    window.innerWidth - 316,
  );
  const topPosition = top + coords.top + 22;

  return { top: topPosition, left: leftPosition };
};

export const mentionName = (user: MentionItem, trigger: string) =>
  `${trigger}${user.textInComment} `.replace('＠', '@');
