<script setup lang="ts">
type Props = {
  hasPermission: boolean;
  disableEditButton: boolean;
};
defineProps<Props>();

const emit = defineEmits(['onEdit']);
</script>
<template>
  <button
    class="o-create-button c-btn c-btn--auto c-btn--AnewsPrimary"
    type="button"
    :class="{ disabled: disableEditButton }"
    :disabled="disableEditButton"
    @click="emit('onEdit')"
    data-testid="edit-organization-tag-list-button"
    v-if="hasPermission"
  >
    <span class="c-text c-text--m">組織タグを一括で編集・追加・並び替え</span>
  </button>
</template>
