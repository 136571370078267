<script lang="ts">
import { defineComponent, PropType, ref } from 'vue';
import { useRoute } from 'vue-router';
import api from '@/api';
import { Feature, PageName } from '@/api/tracking';
import Avatar from '@/components/common/atoms/avatar.vue';
import ArticleComment from '@/components/common/comment/article-comment.vue';
import MentionAutocompleteTextarea from '@/components/common/molecules/mention-autocomplete-textarea.vue';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import { AdpDocument, Comment } from '@/types';
import { useUserInfo } from '@/utils/swr';
import { useEmitter } from '@/utils/vue';

export default defineComponent({
  props: {
    comments: { type: Array as PropType<Comment[]>, default: () => [] },
    omitting: { type: Boolean, default: false },
    adpDocument: { type: Object as PropType<AdpDocument> },
    showAvatar: { type: Boolean, default: true },
    showReplyComment: { type: Boolean, default: true },
    showReplyButton: { type: Boolean, default: true },
    feature: { type: String as PropType<Feature>, default: '' },
    pageName: { type: String as PropType<PageName>, default: '' },
    isSummaryGroup: { type: Boolean, default: false },
    showCommentWidget: { type: Boolean, default: false },
    groupId: { type: Number },
    foldReplies: { type: Boolean, default: true },
    showJoinGroupButton: { type: Boolean, default: false },
    showReaction: { type: Boolean, default: true },
    showCommentMenu: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    isNarrow: { type: Boolean, default: false },
  },
  components: {
    ArticleComment,
    Avatar,
    MentionAutocompleteTextarea,
  },
  setup(props) {
    const route = useRoute();
    const { createSnackbar } = useSnackbar();
    const emitter = useEmitter();

    // NOTE: 計測ログなど実行時にroute.fullPathを使用すると、
    // route.fullPathで取得する前に画面遷移など行われて、
    // 想定と異なるパスが取得される可能性があるため定数で保持する
    const PAGE_URL = route.fullPath;

    const { data: userInfo } = useUserInfo();
    const commentRef = ref<InstanceType<typeof MentionAutocompleteTextarea>>();

    const isCommentTextEmpty = (): boolean => {
      return (commentRef.value?.trimmedComment ?? '').length === 0;
    };
    const showGroupName = (index: number) => {
      if (!props.isSummaryGroup) return true;
      return index === 0;
    };

    const callPostComment = () => {
      commentRef.value?.postComment();
    };

    const postComment = async () => {
      if (commentRef.value) {
        try {
          const createdComment = await api.sendComment({
            groupId: props.groupId!,
            adpDocument: props.adpDocument,
            content: commentRef.value?.trimmedComment,
            mention: commentRef.value?.mention,
            trackingBaseData: {
              pageName: 'article',
              feature: 'group_comments',
              pageUrl: PAGE_URL,
            },
          });

          const updateArticle = props.adpDocument;
          updateArticle?.comments?.push(createdComment);
          emitter.emit('article-updated', updateArticle);
          emitter.emit('comment-created', {
            ...createdComment,
            updateArticle,
          });
          createSnackbar({
            message: 'コメントを投稿しました',
            type: 'success',
          });
          commentRef.value?.resetTrimmedComment();
        } catch (err) {
          createSnackbar({
            message: 'コメントを投稿できませんでした',
            type: 'error',
          });
          throw err;
        }
      }
    };
    return {
      userInfo,
      commentRef,
      isCommentTextEmpty,
      showGroupName,
      callPostComment,
      postComment,
    };
  },
});
</script>

<template>
  <div class="m-comment-list">
    <div
      class="m-comment-list-body"
      v-for="(comment, index) in comments"
      :key="comment.id"
    >
      <ArticleComment
        :article="adpDocument"
        :comment="comment"
        :omitting="omitting"
        :show-avatar="showAvatar"
        :show-reply-comment="showReplyComment"
        :show-group="showGroupName(index)"
        :show-reply-button="showReplyButton"
        :is-summary-group="isSummaryGroup"
        :show-join-group-button="showJoinGroupButton"
        :feature="feature"
        :page-name="pageName"
        :fold-replies="foldReplies"
        :show-reaction="showReaction"
        :show-comment-menu="showCommentMenu"
        :is-narrow="isNarrow"
        @reply="c => comment.children.push(c)"
      ></ArticleComment>
    </div>
    <div class="comment-widget" v-if="userInfo && showCommentWidget">
      <router-link :to="`/users/${userInfo.id}`">
        <Avatar size="m" :image-url="userInfo.image_url"></Avatar>
      </router-link>
      <div class="spacing-08"></div>
      <MentionAutocompleteTextarea
        ref="commentRef"
        placeholder="コメントする..."
        :min-height="40"
        :rows="1"
        :auto-focus="false"
        :disabled="disabled"
        @post-comment="postComment"
      ></MentionAutocompleteTextarea>
      <div class="spacing-08"></div>
      <button
        class="comment-button-small c-btn c-btn--small c-btn--AnewsPrimary"
        :class="{ disabled: isCommentTextEmpty() }"
        :disabled="isCommentTextEmpty()"
        @click="callPostComment"
      >
        投稿
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
div.m-comment-list {
  display: flex;
  flex-direction: column;
}
.comment-widget {
  display: flex;
  align-items: center;
  margin-top: 8px;
}
.comment-button-small {
  width: 60px;
  height: 40px;
  padding: 0;
}
</style>
