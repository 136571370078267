import { computed, watch } from 'vue';
import { Router, useRoute, useRouter } from 'vue-router';
import api from '@/api';
import { MYPAGE_ROUTE_NAMES } from '@/routers/constants';
import store from '@/stores/pcStore';
import * as Sentry from '@sentry/vue';
import { isAxiosError } from 'axios';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import { UserDetailEntity } from '@/types';
import signout from './signout';
import { STATES, useUserInfo } from './swr';
import { userSession } from './userSession';
import { useGTag, useStore } from './vue';
import { featureFlags } from '@/featureFlags';

export const getTabs = () => {
  return [
    {
      name: 'マーク',
      linkTo: { name: MYPAGE_ROUTE_NAMES.marks },
    },
    {
      name: 'コメント',
      linkTo: { name: MYPAGE_ROUTE_NAMES.comments },
    },
    {
      name: '閲覧',
      linkTo: { name: MYPAGE_ROUTE_NAMES.views },
    },
    ...(featureFlags.ANDEV_5137_ADD_MY_TAG_LIST
      ? [
          {
            name: 'マイタグ',
            linkTo: { name: MYPAGE_ROUTE_NAMES.myTags },
          },
        ]
      : []),
  ];
};

export const checkMoveToAdmin = async (router: Router) => {
  let userInfo = store.state.userInfo.userInfo;
  if (!userInfo || !userInfo.role) {
    const userId = userSession.getUserId();
    userInfo = await api.fetchUserInfo(userId);
    store.commit('userInfo/setUserInfo', userInfo);
  }
  if (!userInfo || userInfo.role !== 'admin') {
    router.push({ name: 'anewsHome' });

    const { createSnackbar } = useSnackbar();
    createSnackbar({
      message: '権限がありません',
      type: 'error',
    });
  }
};

const setUserInfoForSentry = (userInfo: UserDetailEntity) => {
  Sentry.getCurrentScope().setUser({
    id: String(userInfo.id),
  });
};

export const useIsAdmin = () => {
  const { data: userInfo } = useUserInfo();
  const isAdmin = computed(() => {
    return userInfo.value?.role === 'admin';
  });
  return { isAdmin };
};

export const useUserSession = () => {
  const router = useRouter();
  const route = useRoute();
  const store = useStore();
  const gTag = useGTag();
  const { data: userInfo, state, error } = useUserInfo();

  const stopUserInfoWatch = watch(
    [userInfo, state, error],
    async ([u, s, e]) => {
      // ユーザーの状態を確認
      const isSessionInactive = !userSession.isActive();
      const hasUserApiError =
        e &&
        isAxiosError(e) &&
        (e.response?.status === 401 || e.response?.status === 403);
      const isUserDeleted = u?.is_deleted;
      if (isSessionInactive || hasUserApiError || isUserDeleted) {
        await signout(gTag, router, route.fullPath);
        stopUserInfoWatch();
      }

      // ログインしている状態ならstoreとSentryの処理
      if (s === STATES.SUCCESS && u) {
        userSession.start(u.id, u.team_id, gTag);
        store.commit('userInfo/setUserInfo', userInfo.value);
        setUserInfoForSentry(u);
        stopUserInfoWatch();
      }
    },
    { immediate: true },
  );

  return { userInfo, state };
};
