<script setup lang="ts">
import { computed } from 'vue';
import { GROUP_MARK_SHARE, MAX_TOOLTIP_LIST_LENGTH } from '@/constants';
import { DgrIcon } from '@stockmarkteam/donguri-ui';

const props = defineProps<{
  markedGroups: {
    id: number;
    name: string;
  }[];
}>();

const groupMarkCount = computed(() => {
  return props.markedGroups.length;
});

const tooltipContents = computed(() => {
  return props.markedGroups.slice(0, MAX_TOOLTIP_LIST_LENGTH);
});

const otherMarkedGroupCount = computed(() => {
  return groupMarkCount.value - MAX_TOOLTIP_LIST_LENGTH;
});
</script>

<template>
  <VTooltip theme="menu" placement="top">
    <button class="group-mark-button">
      <DgrIcon
        class="group-mark-icon"
        size="small"
        name="group-mark"
        alt="group mark icon"
      />
      <div class="c-title c-title--s" v-if="groupMarkCount > 0">
        {{ groupMarkCount }}
      </div>
    </button>

    <template #popper>
      <template v-if="tooltipContents.length === 0">
        {{ GROUP_MARK_SHARE }}
      </template>
      <template v-else>
        <template v-for="group in tooltipContents" :key="group.id">
          <router-link
            :to="{ name: 'groupMarks', params: { groupId: group.id } }"
          >
            ・{{ group.name }}
          </router-link>
          <br />
        </template>
        <p v-if="otherMarkedGroupCount > 0">
          {{ `他${otherMarkedGroupCount}件` }}
        </p>
      </template>
    </template>
  </VTooltip>
</template>

<style lang="scss" scoped>
.group-mark-button {
  all: unset;
  align-items: center;
  padding: 8px 10px;
  width: fit-content;
  display: flex;
  min-width: 32px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: $color-gray200;
  }

  .group-mark-icon {
    margin-right: 4px;
  }
}
</style>
