<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref, toRaw, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import api from '@/api';
import { FIRST_PUBLISHED_AT, searchPresetActivity } from '@/constants';
import { DgrSelectbox, DgrToggleButton } from '@stockmarkteam/donguri-ui';
import dayjs from 'dayjs';
import ActivitiesFilter from '@/components/activities/activities-filter.vue';
import ActivitiesSectionedCheckboxFilter from '@/components/activities/activities-sectioned-checkbox-filter.vue';
import DropdownDateRangeCalendar from '@/components/common/dropdown-date-range-calendar.vue';
import SearchBar from '@/components/common/molecules/search-bar.vue';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import GroupHeader from '@/components/group/group-header.vue';
import Content from '@/components/layouts/content.vue';
import type {
  DateRangePreset,
  DocType,
  FilterCheckBoxItem,
  FilterSelectBoxItem,
  GroupActivitySortType,
  Lang,
  MemoFilterValue,
  SearchDate,
  SelectedLangsForTrackingType,
  TagSelectItem,
} from '@/types';
import { getToday } from '@/utils/dates';
import { groupActivitiesQueryItem } from '@/utils/group-activities';
import {
  getActivityNewsCategoriesByName,
  getPestNewsCategoryFilterSections,
} from '@/utils/news_category';
import {
  STATES,
  useGroupActivitiesCount,
  useGroupActivityNewsCategories,
  useGroups,
  useGroupTagItems,
  useUserInfo,
} from '@/utils/swr';
import { useEmitter } from '@/utils/vue';
import GroupActivitiesDownload from './group-activities-download.vue';
import GroupActivitiesList from './group-activities-list.vue';
import GroupCommentButton from './group-comment-button.vue';

type IsShowCommentFilterValue = 'showComment' | 'hideComment';
type FilterValue = GroupActivitySortType | IsShowCommentFilterValue;
type FilterItem<T extends FilterValue> = {
  value: T;
  label: string;
};

const SORT_FILTER_ITEMS: FilterItem<GroupActivitySortType>[] = [
  {
    value: 'updated',
    label: '更新順',
  },
  {
    value: 'created',
    label: '最新順',
  },
];

const IS_SHOW_COMMENT_FILTER_ITEMS: FilterItem<IsShowCommentFilterValue>[] = [
  {
    value: 'showComment',
    label: 'コメントを表示',
  },
  {
    value: 'hideComment',
    label: 'コメントを非表示',
  },
];

withDefaults(
  defineProps<{
    isMenuVisible: boolean;
  }>(),
  {
    isMenuVisible: true,
  },
);

const route = useRoute();
const router = useRouter();
const { createSnackbar } = useSnackbar();

const { data: groups, state: groupsState } = useGroups();
const group = computed(() =>
  groups.value?.groups.find(g => g.id === groupId.value),
);
// FIXME: 現状個別のgroup取得するAPIがないためかなり回りくどい処理になっている(全件取得→filter)
const groupId = computed(() => {
  if (
    route.params.groupId instanceof Array &&
    route.params.groupId[0] !== undefined
  ) {
    return Number(route.params.groupId[0]);
  }
  if (route.params.groupId !== undefined) {
    return Number(route.params.groupId);
  }

  return undefined;
});

const searchQuery = ref<string>('');
const searchQueryForChanging = ref<string>('');
// 期間指定フィルタ
const startDate = ref<Date | undefined>();
const formattedStartDate = computed<string | undefined>(() => {
  return startDate.value
    ? dayjs(startDate.value).format('YYYY-MM-DD')
    : undefined;
});
const endDate = ref<Date | undefined>();
const formattedEndDate = computed<string | undefined>(() => {
  return endDate.value ? dayjs(endDate.value).format('YYYY-MM-DD') : undefined;
});
const dateRangeKeyForTracking = ref<DateRangePreset['key'] | null>(
  searchPresetActivity[0].key,
);

// 絞り込みフィルター
const selectedTags = ref<string[]>([]);
const memoFilterItems = ref<FilterSelectBoxItem<MemoFilterValue>[]>([
  {
    value: 'all',
    label: 'すべての記事',
    isSelected: false,
  },
  {
    value: 'existsMemo',
    label: 'グループメモした記事',
    isSelected: false,
  },
  {
    value: 'noMemos',
    label: 'グループメモしていない記事',
    isSelected: false,
  },
]);
const articleLangs = ref<FilterCheckBoxItem<Lang>[]>([
  { label: '国内', value: 'ja', isChecked: false },
  { label: '海外', value: 'en', isChecked: false },
]);
const docTypes = ref<FilterCheckBoxItem<DocType>[]>([
  { label: 'ニュース', value: 'article', isChecked: false },
  { label: '論文', value: 'research_paper', isChecked: false },
  { label: '特許', value: 'patent', isChecked: false },
]);

const selectedMemoExistsTypeForApi = computed(() => {
  const selectedMemoType = memoFilterItems.value.find(item => item.isSelected);
  if (selectedMemoType?.value === undefined || selectedMemoType.value === 'all')
    return undefined;
  return selectedMemoType.value === 'existsMemo';
});
const memoFilterLabel = computed<string>(() => {
  const selectedItem = memoFilterItems.value.find(item => item.isSelected);
  return selectedItem ? selectedItem.label : memoFilterItems.value[0].label;
});

const selectedLangForApi = computed<Lang[]>(() => {
  return articleLangs.value
    .filter(data => data.isChecked)
    .map(data => data.value);
});
const selectedLangsForTracking = computed<SelectedLangsForTrackingType>(() => {
  const checkedLangs: Array<Lang> = articleLangs.value
    .filter(data => data.isChecked)
    .map(data => data.value);

  if (
    checkedLangs.length === 0 ||
    checkedLangs.length === articleLangs.value.length
  )
    return 'all';
  return checkedLangs[0];
});

const selectedDocTypesForApi = computed<DocType[]>(() => {
  return docTypes.value.filter(data => data.isChecked).map(data => data.value);
});
const selectedDocTypesForTracking = computed<DocType[]>(() => {
  const checkedLangs = docTypes.value
    .filter(data => data.isChecked)
    .map(data => data.value);
  return checkedLangs.length > 0
    ? checkedLangs
    : docTypes.value.map(data => data.value);
});

const selectedPestNewsCategories = ref<string[]>([]);

const selectedIsShowCommentFilterItem = ref<IsShowCommentFilterValue>(
  groupActivitiesQueryItem.getComment() ?? 'showComment',
);
const isShowArticleCardComment = computed({
  get: () => selectedIsShowCommentFilterItem.value === 'showComment',
  set: async value => await toggleShowComments(value),
});
const commentToggleLabel = computed<string>(() => {
  const selectedItem = IS_SHOW_COMMENT_FILTER_ITEMS.find(
    item => item.value === selectedIsShowCommentFilterItem.value,
  );
  return selectedItem
    ? selectedItem.label
    : IS_SHOW_COMMENT_FILTER_ITEMS[0].label;
});

const sortType = ref<GroupActivitySortType>(
  groupActivitiesQueryItem.getSort() ?? 'updated',
);
const sortFilterLabel = computed(() => {
  const selectedItem = SORT_FILTER_ITEMS.find(
    item => item.value === sortType.value,
  );
  return selectedItem ? selectedItem.label : SORT_FILTER_ITEMS[0].label;
});

// trackingSessionId は activities の取得時に取得する
const trackingSessionId = ref<string>('');
// search, filter, comment on/off の操作で発火させている
const trackFilterActions = async () => {
  const period = {
    from: formattedStartDate.value,
    to: formattedEndDate.value,
  };
  await api.trackEvent(
    'group_activities_view',
    {
      pageName: 'group_activities',
      pageUrl: route.fullPath,
      pageGroupId: groupId.value,
    },
    undefined,
    undefined,
    {
      request: {
        tags: selectedTags.value,
        // NOTE: メモがあるかないかのフィルター選択値を計測ログでは'article_type' として使用している （経緯は不明）
        article_type: memoFilterLabel.value,
        sort: sortFilterLabel.value,
        show_comment: commentToggleLabel.value,
        lang: selectedLangsForTracking.value,
        query: searchQuery.value,
        data_sources: selectedDocTypesForTracking.value,
        period: dateRangeKeyForTracking.value,
        period_detail: dateRangeKeyForTracking.value ? null : period,
        categories: pestNewsCategoryFilterTrackingData.value,
      },
      session_id: trackingSessionId.value,
    },
  );
};

const changeTrackingSessionId = (newSessionId: string | null) => {
  if (newSessionId === null) return;
  trackingSessionId.value = newSessionId;
};
const emitter = useEmitter();
onMounted(async () => {
  if (!validateGroup()) return;
  await updateLastViewedTimeForGroup();
  emitter.on(
    'change-tracking-session-id-for-group-activity',
    changeTrackingSessionId,
  );
});
onUnmounted(() => {
  emitter.off(
    'change-tracking-session-id-for-group-activity',
    changeTrackingSessionId,
  );
});

const updateLastViewedTimeForGroup = async () => {
  if (group.value?.is_member) {
    await api.updateLastViewedTimeForGroup(group.value.id);
  }
};

const validateGroup = () => {
  // グループから他のページに移動したらバリデーションが必要ない
  if (groupId.value === undefined) return false;
  if (groupsState.value === STATES.SUCCESS && group.value === undefined) {
    createSnackbar({
      message: '指定されたグループは存在しません',
      type: 'error',
    });
    router.replace({ name: 'anewsHome' });
    return false;
  }
  return true;
};

/**
 * URLのgroupIdが変わった時は、保持していたステートをリセットし、情報を再取得する
 * (サイドメニューのグループを変えてもonMountedは動かないため考慮が必要)
 */
watch(
  () => groupId.value,
  async () => {
    if (!validateGroup()) return;
    startDate.value = undefined;
    endDate.value = undefined;
    searchQuery.value = '';
    searchQueryForChanging.value = '';
    selectedPestNewsCategories.value = [];
    await resetActivitiesFilter();
    selectedTags.value = [];
    await updateLastViewedTimeForGroup();
  },
);

watch(sortType, () => {
  groupActivitiesQueryItem.setSort(sortType.value);
});

watch(trackingSessionId, async () => {
  await trackFilterActions();
});

const toggleShowComments = async (value: boolean) => {
  selectedIsShowCommentFilterItem.value = value ? 'showComment' : 'hideComment';
  groupActivitiesQueryItem.setComment(selectedIsShowCommentFilterItem.value);
  await trackFilterActions();
};

const applyDate = (option: {
  presetKey: string;
  value: { from: SearchDate; to: SearchDate };
}) => {
  const isCustomRange = option.presetKey === 'custom';
  dateRangeKeyForTracking.value = isCustomRange ? null : option.presetKey;
  startDate.value = option.value.from;
  endDate.value = option.value.to;
};

const handleChangeInput = (query: string) => {
  searchQueryForChanging.value = query;
};
const onSearch = () => {
  searchQuery.value = searchQueryForChanging.value;
};

const resetActivitiesFilter = () => {
  selectedTags.value = [];
  memoFilterItems.value.forEach(item => {
    item.isSelected = false;
  });
  articleLangs.value.forEach(item => {
    item.isChecked = false;
  });
  docTypes.value.forEach(item => {
    item.isChecked = false;
  });
};
const applyActivitiesFilter = async (values: {
  tmpSelectedTags: string[];
  tmpMemoFilterItems: FilterSelectBoxItem<MemoFilterValue>[];
  tmpArticleLangs: FilterCheckBoxItem<Lang>[];
  tmpDocTypes: FilterCheckBoxItem<DocType>[];
}) => {
  selectedTags.value = structuredClone(toRaw(values.tmpSelectedTags));
  memoFilterItems.value = structuredClone(toRaw(values.tmpMemoFilterItems));
  articleLangs.value = structuredClone(toRaw(values.tmpArticleLangs));
  docTypes.value = structuredClone(toRaw(values.tmpDocTypes));
};

// PESTニュースカテゴリー情報フィルター
const { data: fetchedActivityNewsCategories } =
  useGroupActivityNewsCategories(groupId);

const activityNewsCategoriesByName = computed<Record<string, number>>(() => {
  if (fetchedActivityNewsCategories.value === undefined) return {};

  return getActivityNewsCategoriesByName(
    fetchedActivityNewsCategories.value.activity_news_categories,
  );
});

const pestNewsCategoryFilterSections = computed(() => {
  return getPestNewsCategoryFilterSections(activityNewsCategoriesByName.value);
});

const pestNewsCategoryFilterTrackingData = computed(() =>
  pestNewsCategoryFilterSections.value.flatMap(section => {
    const selectedOptions = section.options.filter(option =>
      selectedPestNewsCategories.value.includes(option.value),
    );

    return selectedOptions.length > 0
      ? [
          {
            name: section.sectionName,
            items: selectedOptions.map(option => ({ name: option.value })),
          },
        ]
      : [];
  }),
);

const clickApplyPestNewsCategoryFilter = (tmpPestNewsCategories: string[]) => {
  selectedPestNewsCategories.value = structuredClone(
    toRaw(tmpPestNewsCategories),
  );
};

const { data: activitiesCount } = useGroupActivitiesCount(
  groupId,
  selectedTags,
  selectedMemoExistsTypeForApi,
  formattedStartDate,
  formattedEndDate,
  selectedLangForApi,
  selectedDocTypesForApi,
  searchQuery,
  selectedPestNewsCategories,
);

const { data: fetchedTags } = useGroupTagItems(groupId);
const tags = computed<TagSelectItem[]>(
  () =>
    fetchedTags.value?.map(t => ({
      name: t.name,
      // FIXME: nameがidは違和感しかないので後ほど修正する
      id: t.name,
      count: t.total_count,
    })) ?? [],
);

const { data: myInfo } = useUserInfo();
</script>

<template>
  <div class="group-activity" v-if="group && myInfo">
    <GroupHeader
      :group="group"
      :user-info="myInfo"
      :is-menu-visible="isMenuVisible"
    />
    <Content>
      <div class="group-content">
        <div class="action-bar">
          <div class="group-activity-header">
            <div class="title">
              <span class="c-title c-title--m">アクティビティ</span>
              <span class="c-text c-text--s"
                >・{{ activitiesCount ? activitiesCount.count : '-' }}件</span
              >
            </div>
            <GroupCommentButton :group="group" :key="group.id" />
          </div>
          <div>
            <div class="spacing-24"></div>
            <div class="group-activity-search-input">
              <!-- グループごとにインスタンスが再生成させるように:keyでgroupIdを設定 -->
              <SearchBar
                :key="groupId"
                :placeholder="'記事を検索'"
                @on-change-query="handleChangeInput"
                @on-search-query="onSearch"
              ></SearchBar>
            </div>
          </div>
          <div class="spacing-16"></div>
          <div class="group-activity-btns-header">
            <div class="group-activity-selects">
              <div class="tag-selectbox">
                <DgrSelectbox
                  :options="SORT_FILTER_ITEMS"
                  v-model="sortType"
                  size="small"
                ></DgrSelectbox>
              </div>
              <div class="spacing-16"></div>
              <ActivitiesFilter
                :is-group-page="true"
                :article-tag-list="tags"
                :selected-tags="selectedTags"
                :memo-filter-items="memoFilterItems"
                :article-langs="articleLangs"
                :doc-types="docTypes"
                @click-reset="resetActivitiesFilter"
                @click-apply="applyActivitiesFilter"
              />
              <div class="spacing-16"></div>
              <DropdownDateRangeCalendar
                :range="{
                  from: startDate,
                  to: endDate,
                }"
                :preset="searchPresetActivity"
                :min="FIRST_PUBLISHED_AT"
                :max="getToday()"
                :is-open="true"
                :disabled="false"
                :font-size="'small'"
                :icon-size="'small'"
                @apply-date="applyDate"
              ></DropdownDateRangeCalendar>
              <div class="spacing-16"></div>
              <ActivitiesSectionedCheckboxFilter
                :initial-form-value="selectedPestNewsCategories"
                :sections="pestNewsCategoryFilterSections"
                @submit="clickApplyPestNewsCategoryFilter"
              />
              <div class="spacing-16"></div>
              <DgrToggleButton
                class="c-text c-text--s show-comment-toggle"
                v-model="isShowArticleCardComment"
              >
                コメントを表示
              </DgrToggleButton>
            </div>
            <GroupActivitiesDownload
              :group-id="groupId"
              :selected-tags="selectedTags"
              :selected-memo-exists-type-for-api="selectedMemoExistsTypeForApi"
              :start-date="formattedStartDate"
              :end-date="formattedEndDate"
              :selected-doc-types-for-api="selectedDocTypesForApi"
              :search-query="searchQuery"
              :selected-pest-news-categories="selectedPestNewsCategories"
              :selected-langs-for-tracking="selectedLangsForTracking"
              :sort-type="sortType"
              :memo-filter-label="memoFilterLabel"
              :comment-toggle-label="commentToggleLabel"
              :selected-doc-types-for-tracking="selectedDocTypesForTracking"
            />
          </div>
          <GroupActivitiesList
            :key="group.id"
            :group="group"
            :activities-count="activitiesCount?.count"
            :is-show-article-card-comment="isShowArticleCardComment"
            :selected-tags="selectedTags"
            :tracking-session-id="trackingSessionId"
            :sort-type="sortType"
            :selected-memo-exists-type-for-api="selectedMemoExistsTypeForApi"
            :start-date="formattedStartDate"
            :end-date="formattedEndDate"
            :selected-lang-for-api="selectedLangForApi"
            :selected-doc-types-for-api="selectedDocTypesForApi"
            :search-query-for-count-api="searchQuery"
            :selected-pest-news-categories="selectedPestNewsCategories"
            :article-tag-list="tags"
          />
        </div>
      </div>
    </Content>
  </div>
</template>

<style lang="scss" scoped>
.group-activity {
  margin: -24px 0 0 0;
}

.group-activity-btns-header {
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  width: clamp(400px, calc(100vw - 400px), 912px);
}

.group-activity-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: clamp(400px, calc(100vw - 400px), 912px);

  .title {
    .c-text--s {
      color: $color-gray600;
      font-weight: bold;
    }
  }
}

.group-activity-selects {
  display: flex;
}

.group-activity-search-input {
  width: 616px;
}

.show-comment-toggle {
  align-items: center;
}
</style>
