<script setup lang="ts"></script>

<template>
  <div class="skeleton">
    <div class="title">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <div class="actions">
      <div></div>
      <div></div>
      <div></div>
    </div>
    <div class="thumbnail"></div>
  </div>
</template>

<style lang="scss" scoped>
.skeleton {
  display: grid;
  background: white;

  grid-template:
    'title   .........' 66px
    'actions thumbnail' 60px
    / 1fr 80px;
}

.title {
  grid-area: title;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;

  div {
    height: 8px;
    border-radius: 4px;
    background: $color-gray400;

    &:nth-child(1) {
      width: 135px;
    }
    &:nth-child(2) {
      width: 197px;
    }
    &:nth-child(3) {
      width: 149px;
    }
    &:nth-child(4) {
      width: 79px;
    }
  }
}
.actions {
  grid-area: actions;
  width: 100%;
  height: 100%;

  display: flex;
  gap: 32px;
  align-items: flex-end;

  div {
    background: $color-gray400;
    border-radius: 4px;
    width: 20px;
    height: 20px;
  }
}
.thumbnail {
  grid-area: thumbnail;
  width: 80px;
  height: 60px;
  background: $color-gray400;
  border-radius: 4px;
}
</style>
