<script setup lang="ts">
import { computed, ref } from 'vue';
import { EMPTY_KEYWORD_FEED_MESSAGE } from '@/constants';
import { DgrLoading } from '@stockmarkteam/donguri-ui';
import AdpDocumentCard from '@/components/common/adp-document-card.vue';
import BasicEmptyFeed from '@/components/common/basic-empty-feed.vue';
import { DateArticles } from '@/components/personal-news/articles-day.vue';
import {
  Article,
  ExecutionInformation,
  FeedType,
  ThemeFeedType,
} from '@/types';
import {
  ALL_MEDIA_INITIAL_VISIBLE_ARTICLE_COUNT,
  MULTI_EXECUTION_MAX_ARTICLE_COUNT,
} from '@/utils/personalNews';

type Props = {
  articlesByDate: DateArticles[];
  executionInfo: ExecutionInformation;
  articles: Article[];
  removedArticleIds: number[];
  previousTotalFeedCount: number;
  isLoaded: boolean;
  isFetchingData: boolean;
  displayFeedType: FeedType;
  themeFeedType: ThemeFeedType;
};
const props = withDefaults(defineProps<Props>(), {
  articlesByDate: () => [],
  articles: () => [],
  removedArticles: () => [],
});

const executionTime = props.executionInfo.executionTime;

const visibleCount = ref(
  props.displayFeedType === 'all'
    ? ALL_MEDIA_INITIAL_VISIBLE_ARTICLE_COUNT
    : MULTI_EXECUTION_MAX_ARTICLE_COUNT,
);

const visibleFeedArticles = computed(() =>
  props.articles.slice(0, visibleCount.value),
);

const isViewMoreButtonVisible = computed(
  () => props.articles.length > visibleCount.value,
);
</script>

<template>
  <div>
    <div class="execution-title c-title c-title--s">
      {{ executionTime }}更新
    </div>
    <div
      class="article-card"
      v-for="article in visibleFeedArticles"
      :key="`afternoon-${article.id}`"
    >
      <div class="divider"></div>
      <AdpDocumentCard
        width="100%"
        :adp-document="article"
        :feed-type="themeFeedType"
        :rank-in-whole-feed="previousTotalFeedCount + article.rank"
        :show-comment-count="1"
        mark-source="personal_feed"
        page-name="personal_news"
        feature="personal_feed"
        :disable-related-articles="false"
        :execution="executionInfo.execution"
      ></AdpDocumentCard>
    </div>
    <div class="footer">
      <template v-if="isViewMoreButtonVisible">
        <div
          class="view-more-button c-text c-text--m"
          @click="() => (visibleCount = MULTI_EXECUTION_MAX_ARTICLE_COUNT)"
        >
          すべて表示（{{ articles.length - visibleCount }}）
        </div>
        <div class="spacing-16" />
      </template>
      <DgrLoading
        v-if="!isLoaded || isFetchingData"
        class="loading loading-spacer"
      />
    </div>
    <template v-if="isLoaded && !isFetchingData && articles.length === 0">
      <div class="divider"></div>
      <BasicEmptyFeed>{{ EMPTY_KEYWORD_FEED_MESSAGE }}</BasicEmptyFeed>
    </template>
  </div>
</template>

<style scoped lang="scss">
.execution-title {
  padding: 8px 0;
}

.article-card {
  .document-card {
    border: 0;
    padding: 16px 0 12px 0;
  }
}

.divider {
  width: auto;
  border-bottom: 0.5px solid $color-gray400;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.view-more-button {
  display: inline-flex;
  align-self: center;
  padding: 4px 12px;
  border-radius: 4px;
  border: 1px solid $color-green600;
  background: #fff;
  color: $color-green600;
  cursor: pointer;
}

.loading {
  align-self: center;
}
.loading-spacer {
  padding-bottom: 80vh;
}
</style>
