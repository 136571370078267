<script lang="ts">
import { computed, defineComponent, nextTick, PropType, ref, watch } from 'vue';
import logoSrc from '@/assets/ms-teams-logo.png';
import { DgrCheckbox } from '@stockmarkteam/donguri-ui';
import { MSTeamsSettings, MSTeamsSettingsWithType } from '@/types';
import { validateWebhookUrl } from '@/utils/validators';

export default defineComponent({
  components: {
    DgrCheckbox,
  },
  props: {
    modelValue: {
      type: Object as PropType<MSTeamsSettingsWithType>,
    },
    title: { type: String },
  },
  emits: {
    'update:modelValue': (_settings: MSTeamsSettingsWithType) => true,
  },
  setup(props, context) {
    const isEnabledTeamsNotification = computed({
      get: () =>
        props.modelValue?.settings?.enabled_ms_teams_notification ?? false,
      set: value => {
        updateSettings({ enabled_ms_teams_notification: value });
      },
    });
    const webhookUrl = computed({
      get: () => props.modelValue?.settings?.webhook_url ?? '',
      set: value => {
        updateSettings({ webhook_url: value });
      },
    });
    const isEnabledWeeklyHighlight = computed({
      get: () =>
        props.modelValue?.settings?.enabled_team_activity_content ?? false,
      set: value => {
        updateSettings({ enabled_team_activity_content: value });
      },
    });
    const isEnabledMarkedArticle = computed({
      get: () => props.modelValue?.settings?.enabled_mark_content ?? false,
      set: value => {
        updateSettings({ enabled_mark_content: value });
      },
    });
    const isEnabledComment = computed({
      get: () => props.modelValue?.settings?.enabled_comment_content ?? false,
      set: value => {
        updateSettings({ enabled_comment_content: value });
      },
    });
    const isEnabledGroupMark = computed({
      get: () =>
        props.modelValue?.settings?.enabled_group_mark_content ?? false,
      set: value => {
        updateSettings({ enabled_group_mark_content: value });
      },
    });

    const isValidWebhookUrl = computed(() =>
      validateWebhookUrl(webhookUrl.value),
    );
    const validateCheckStatus = (
      type: 'team' | 'group',
      settings: Omit<MSTeamsSettings, 'webhook_url'>,
    ) => {
      if (type === 'team') {
        return (
          settings.enabled_team_activity_content ||
          settings.enabled_mark_content ||
          settings.enabled_comment_content ||
          settings.enabled_group_mark_content
        );
      } else if (type === 'group') {
        return (
          settings.enabled_comment_content ||
          settings.enabled_group_mark_content
        );
      }
    };

    const isValidCheckStatus = computed(() => {
      if (!props.modelValue) return false;
      return validateCheckStatus(
        props.modelValue.type,
        props.modelValue.settings,
      );
    });

    const webhookUrlInput = ref<HTMLInputElement>();
    watch(
      () => isEnabledTeamsNotification.value,
      () => {
        if (isEnabledTeamsNotification.value) {
          nextTick(() => {
            webhookUrlInput.value?.focus();
          });
        }
      },
    );

    const updateSettings = (newSettings: Partial<MSTeamsSettings>) => {
      if (!props.modelValue) return;

      const settings = {
        ...props.modelValue.settings,
        ...newSettings,
      };
      const validWebhookUrl = validateWebhookUrl(settings.webhook_url);
      const validCheckStatus = validateCheckStatus(
        props.modelValue.type,
        settings,
      );
      const submittable =
        (validWebhookUrl && validCheckStatus) ||
        !settings.enabled_ms_teams_notification;

      context.emit('update:modelValue', {
        submittable: submittable,
        type: props.modelValue.type,
        settings,
      });
    };

    return {
      isEnabledTeamsNotification,
      isEnabledWeeklyHighlight,
      isEnabledMarkedArticle,
      isEnabledComment,
      isEnabledGroupMark,
      webhookUrl,
      isValidWebhookUrl,
      isValidCheckStatus,
      webhookUrlInput,
      updateSettings,
      logoSrc,
    };
  },
});
</script>

<template>
  <div class="ms-teams-settings-card">
    <DgrCheckbox
      class="o-teams-toggle-card"
      v-model="isEnabledTeamsNotification"
    >
      <div class="checkbox-content-wrapper">
        <img :src="logoSrc" alt="Microsoft Teams" />
        <div class="title c-text c-text--m">{{ title }}</div>
        <div class="description c-text c-text--s"><slot /></div>
      </div>
    </DgrCheckbox>
    <div class="o-teams-detail-settings">
      <div class="o-teams-webhook">
        <div
          class="o-teams-webhook-title c-formBlock__label c-title c-title--m"
          :class="{ disabled: !isEnabledTeamsNotification }"
        >
          Web hook URL（接続するチャネル）
        </div>
        <div
          class="o-teams-webhook-error c-formBlock__text c-formBlock__text--error"
          v-if="isEnabledTeamsNotification && !isValidWebhookUrl"
        >
          Web Hook URLを入力してください。
        </div>
        <input
          class="o-teams-webhook-input c-textInput"
          type="text"
          ref="webhookUrlInput"
          v-model.trim="webhookUrl"
          :disabled="!isEnabledTeamsNotification"
        />
      </div>
      <div class="o-teams-contents">
        <div
          class="o-teams-contents-title c-formBlock__label c-title c-title--m"
          :class="{ disabled: !isEnabledTeamsNotification }"
        >
          配信するコンテンツ
        </div>
        <div
          class="o-teams-checkbox-error c-formBlock__text c-formBlock__text--error"
          v-if="isEnabledTeamsNotification && !isValidCheckStatus"
        >
          1つ以上選択してください。
        </div>
        <div v-if="modelValue && modelValue.type === 'team'">
          <DgrCheckbox
            class="c-text c-text--m"
            v-model="isEnabledWeeklyHighlight"
            :disabled="!isEnabledTeamsNotification"
          >
            先週のハイライト（毎週1回）
          </DgrCheckbox>
          <div class="o-checkbox-desc c-text c-text--s">
            マークされたニュースをランキング形式で配信します。
          </div>
          <DgrCheckbox
            class="c-text c-text--m"
            v-model="isEnabledMarkedArticle"
            :disabled="!isEnabledTeamsNotification"
          >
            マークした記事（随時）
          </DgrCheckbox>
          <div class="o-checkbox-desc c-text c-text--s">
            マークした記事を配信します。
          </div>
          <DgrCheckbox
            class="c-text c-text--m"
            v-model="isEnabledGroupMark"
            :disabled="!isEnabledTeamsNotification"
          >
            グループマークした記事（随時）
          </DgrCheckbox>
          <div class="o-checkbox-desc c-text c-text--s">
            「全体」にグループマークした記事を配信します。
          </div>
          <DgrCheckbox
            class="c-text c-text--m"
            v-model="isEnabledComment"
            :disabled="!isEnabledTeamsNotification"
          >
            コメントした記事（随時）
          </DgrCheckbox>
          <div class="o-checkbox-desc c-text c-text--s">
            「全体」にコメントした記事とコメント内容を配信します。
          </div>
        </div>
        <div v-else-if="modelValue && modelValue.type === 'group'">
          <DgrCheckbox
            class="c-text c-text--m"
            v-model="isEnabledComment"
            :disabled="!isEnabledTeamsNotification"
          >
            コメント（随時）
          </DgrCheckbox>
          <div class="o-checkbox-desc c-text c-text--s">
            投稿されたコメントを配信します。
          </div>
          <DgrCheckbox
            class="c-text c-text--m"
            v-model="isEnabledGroupMark"
            :disabled="!isEnabledTeamsNotification"
          >
            グループマーク（随時）
          </DgrCheckbox>
          <div class="o-checkbox-desc c-text c-text--s">
            グループマークした記事を配信します。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.ms-teams-settings-card {
  background: #ffffff;
}
.o-teams-toggle-card {
  display: block;
  margin-bottom: 20px;

  .checkbox-content-wrapper {
    display: grid;
    grid-template:
      'logo title' 22px
      'logo description' minmax(0, 1fr)
      / 36px 1fr;
    gap: 0 8px;
  }

  img {
    grid-area: logo;
    align-self: center;
    width: 36px;
    height: 36px;
  }

  .title {
    grid-area: title;
  }

  .description {
    grid-area: description;
    color: $color-gray600;
  }
}
.o-submit-button-wrap {
  display: flex;
  justify-content: flex-end;
  width: 616px;
  .o-submit-button {
    margin: 0;
    width: 120px;
    height: 32px;
  }
}
.o-teams-detail-settings {
  padding-bottom: 12px;
}
.o-teams-webhook-title,
.o-teams-contents-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
  &.disabled {
    color: $color-gray600;
  }
  .icon-box {
    cursor: pointer;
    fill: $color-gray600;
  }
}
.o-teams-webhook-input {
  width: 448px;
  outline: none;
  margin: 4px 0 0 0;
}
.o-teams-webhook-help-link {
  color: #1da482;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.o-checkbox-desc {
  margin: 4px 0 8px 20px;
  color: $color-gray600;
}
</style>
