<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { SearchConditionKey } from '@/stores/pc-modules/searchResults';
import { DgrIcon } from '@stockmarkteam/donguri-ui';
import { useStore } from '@/utils/vue';

type Props = {
  openDocumentsCount?: number;
  userDocumentsCount?: number;
  isEnableTechnicalLiterature: boolean;
  isButtonDisabled: boolean;
};
const props = defineProps<Props>();

type Emits = {
  'click-theme-setting': [];
};
const emit = defineEmits<Emits>();

const route = useRoute();
const store = useStore();

const teamInfo = computed(() => store.state.teamInfo.teamInfo);
const enableUserDocument = computed(
  () => teamInfo.value?.enable_user_document ?? false,
);

const searchConditionKey = computed<SearchConditionKey>(() => {
  if (route.name === 'searchAll') return 'all';
  if (route.name === 'searchNews') return 'news';
  if (route.name === 'searchResearchPapers') return 'research_paper';
  if (route.name === 'searchPatent') return 'patent';
  if (route.name === 'searchReport') return 'report';
  if (route.name === 'searchUserDocument') return 'user_document';
  if (route.name === 'searchUserDocumentDemo') return 'user_document_demo';
  return 'all';
});

const openInfoDocTypes = computed(() => {
  const baseDocTypes = [
    {
      name: 'すべて',
      type: 'all',
      linkTo: { name: 'searchAll', query: route.query },
      isActive: searchConditionKey.value === 'all',
    },
    {
      name: 'ニュース',
      type: 'news',
      linkTo: { name: 'searchNews', query: route.query },
      isActive: searchConditionKey.value === 'news',
    },
    {
      name: 'レポート',
      type: 'report',
      linkTo: { name: 'searchReport', query: route.query },
      isActive: searchConditionKey.value === 'report',
    },
  ];
  const technicalLiteratures = [
    {
      name: '論文',
      type: 'research_paper',
      linkTo: { name: 'searchResearchPapers', query: route.query },
      isActive: searchConditionKey.value === 'research_paper',
    },
    {
      name: '特許',
      type: 'patent',
      linkTo: { name: 'searchPatent', query: route.query },
      isActive: searchConditionKey.value === 'patent',
    },
  ];
  return props.isEnableTechnicalLiterature
    ? baseDocTypes.concat(technicalLiteratures)
    : baseDocTypes;
});

const isUserDocument = computed(
  () =>
    searchConditionKey.value === 'user_document' ||
    searchConditionKey.value === 'user_document_demo',
);

const infoTypes = computed(() => {
  return [
    {
      name: '公開情報',
      type: 'open_info',
      count: props.openDocumentsCount,
      icon: 'globe-asia',
      linkTo: { name: 'searchAll', query: route.query },
      isActive: !isUserDocument.value,
    },
    {
      name: '社内情報',
      type: 'user_document',
      count: props.userDocumentsCount,
      icon: 'building',
      linkTo: enableUserDocument.value
        ? { name: 'searchUserDocument', query: route.query }
        : { name: 'searchUserDocumentDemo', query: route.query },
      isActive: isUserDocument.value,
    },
  ] as const;
});

const clickThemeSetting = () => {
  emit('click-theme-setting');
};
</script>

<template>
  <div class="search-header">
    <div class="search-header-title">
      <div class="search-header-title-content">
        <div class="c-title c-title--l">検索結果</div>
        <div v-if="!isUserDocument">
          <button
            @click="clickThemeSetting"
            class="to-create-theme outlined c-outlineBtn c-btn--auto c-outlineBtn--secondary"
            :disabled="isButtonDisabled"
          >
            テーマに設定し新着を受け取る
          </button>
        </div>
      </div>
    </div>
    <div class="search-header-tabs">
      <div class="search-header-info-type">
        <router-link
          class="info-type-tab"
          v-for="infoType in infoTypes"
          :key="infoType.type"
          :class="{ active: infoType.isActive }"
          :to="infoType.linkTo"
          :style="{ 'pointer-events': infoType.isActive ? 'none' : '' }"
        >
          <div class="tab-icon">
            <DgrIcon :name="infoType.icon" :keep-fill="false" />
          </div>
          <div class="tab-name c-title c-title--m">{{ infoType.name }}</div>
          <div
            class="tab-number c-text c-text--s"
            v-if="infoType.count !== undefined"
          >
            {{ infoType.count }}
          </div>
        </router-link>
      </div>
      <div class="search-header-doc-type" v-if="!isUserDocument">
        <router-link
          v-for="docType in openInfoDocTypes"
          :key="docType.type"
          class="c-title c-title--m doc-type-tab"
          :class="{ 'm-selected-tab-item': docType.isActive }"
          :to="docType.linkTo"
          :style="{ 'pointer-events': docType.isActive ? 'none' : '' }"
        >
          {{ docType.name }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.search-header {
  $search-header-max-width: 970px;
  $search-header-min-width: 830px;
  $search-header-min-height: 72px;
  background-color: white;
  min-width: min-content;
  display: contents;
  .search-header-title {
    border-bottom: solid 1px $color-gray400;
    background-color: white;
    .search-header-title-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 32px 16px 24px;
      max-width: $search-header-max-width;
      min-height: $search-header-min-height;
      box-sizing: border-box;
    }
    button.to-create-theme {
      padding: 10px 16px;
      border-radius: 5px;
      flex-shrink: 0;
      &:not(:disabled) {
        border: solid 1px $color-green600;
        color: $color-green600;

        @media (any-hover: hover) {
          &:hover {
            background-color: $color-green100;
          }
        }
      }
      &:disabled {
        color: $color-gray600;
        @media (any-hover: hover) {
          &:hover {
            background-color: none;
          }
        }
        cursor: not-allowed;
      }
    }
  }
  .search-header-tabs {
    background-color: white;
    position: sticky;
    top: 64px;
    z-index: var(--z-content-header);
    .search-header-info-type {
      display: flex;
      padding: 4px 32px;
      max-width: $search-header-max-width;
      min-width: $search-header-min-width;
      column-gap: 4px;
      .info-type-tab {
        color: $color-gray800;
        fill: $color-gray800;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 6px;
        min-width: 220px;
        box-sizing: border-box;
        padding: 0 20px;
        height: 40px;
        border-radius: 4px;
        cursor: pointer;
        &:hover {
          background-color: $color-gray200;
        }
        &.active {
          background-color: $color-green100;
          color: $color-green600;
          fill: $color-green600;
        }
      }
    }
    .search-header-doc-type {
      display: flex;
      padding: 0 32px;
      max-width: $search-header-max-width;
      min-width: $search-header-min-width;
      .doc-type-tab {
        width: 120px;
        height: 40px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      border-bottom: solid 1px $color-gray400;
    }
  }
}
.m-selected-tab-item {
  color: $color-green600;
  border-bottom: 2px solid #1da482;
  &:hover {
    background: none;
    border-bottom: 2px solid #1da482;
  }
  .count {
    color: $color-green600;
  }
}
</style>
