import api from '@/api';
import { userDocumentSearchCache } from '@/apiCache';
import { StorageDetails, UseStorage } from './types';

/** 連携状態のポーリング間隔 */
const BOX_CONNECT_STATUS_POLLING_INTERVAL = 5 * 1000;
/** 連携失敗とみなすポーリング時間 */
const BOX_CONNECT_STATUS_POLLING_TIMEOUT = 3 * 60 * 1000;

const connect = (item: StorageDetails) => {
  const clientId = import.meta.env.VITE_BOX_CLIENT_ID;
  const redirectUri = `${location.origin}/api/v1/admin/box_api_token_callback`;
  const authorizeUrl = `https://account.box.com/api/oauth2/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code`;
  window.open(authorizeUrl, '_blank');
  startPollingBoxStatus(item);
};

const isConnected = async () => (await api.headTeamBoxApiToken()) === 200;

const startPollingBoxStatus = async (item: StorageDetails) => {
  // 連携状態をポーリングし、連携成功したら状態を変更
  const intervalId = setInterval(async () => {
    if (await isConnected()) {
      clearTimeout(timeoutId);
      clearInterval(intervalId);
      updateDetails(item);
    }
  }, BOX_CONNECT_STATUS_POLLING_INTERVAL);

  // 指定時間経過しても連携が成功しなかった場合、失敗とみなす
  const timeoutId = setTimeout(async () => {
    clearInterval(intervalId);
    if (!(await isConnected())) item.status = 'CONNECT_FAILED';
  }, BOX_CONNECT_STATUS_POLLING_TIMEOUT);
};

const disconnect = (item: StorageDetails) => {
  return async () => {
    // 連携情報を削除
    await api.deleteTeamBoxApiToken();
    await updateDetails(item);
    // 認証情報を削除後、検索できなくなるため、社内情報に関するキャッシュをクリア
    userDocumentSearchCache.cleanup();
  };
};

const updateDetails = async (item: StorageDetails) => {
  if (await isConnected()) {
    item.isConnected = true;
    try {
      const status = await api.getTeamStorageStatus('box');
      switch (status.status) {
        case 'syncing':
          item.status = 'SYNCING';
          break;
        case 'synced':
          item.status = 'SYNCED';
          break;
        case 'sync_failed':
          item.status = 'SYNC_FAILED';
          break;
      }
      item.fileCount = status.file_count?.toLocaleString() ?? '-';
      item.syncStartedAt = status.sync_started_at ?? '-';
      item.syncEndedAt = status.sync_ended_at ?? '-';
    } catch {
      item.status = 'FETCH_ERROR';
      item.fileCount = '-';
      item.syncStartedAt = '-';
      item.syncEndedAt = '-';
      return;
    }
  } else {
    item.isConnected = false;
    item.status = 'NOT_CONNECTED';
    item.fileCount = '-';
    item.syncStartedAt = '-';
    item.syncEndedAt = '-';
  }
  item.isLoaded = true;
};

export const useBox: () => UseStorage = () => ({
  connect,
  disconnect,
  updateDetails: updateDetails,
});
