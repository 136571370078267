<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import api from '@/api';
import { DgrLoading } from '@stockmarkteam/donguri-ui';
import Loading from '@/components/common/loading.vue';
import SearchBar from '@/components/common/molecules/search-bar.vue';
import TextTabItem from '@/components/common/molecules/text-tab-item.vue';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import { FollowListType } from '@/types';
import { useOrganizationTagList, useUserInfo, useUsers } from '@/utils/swr';

const { createSnackbar } = useSnackbar();

// get 関連の処理
const { data: organizationTagList, state: organizationTagState } =
  useOrganizationTagList();
const { data: userInfo, state: userInfoState } = useUserInfo();
const { data: rawUsers, state: usersState } = useUsers();
// NOTE: API response が users.users みたいな形でわかりにくいため整形している
// このAPI自体は他の箇所でも使用されているので、API側の修正はしていない
const users = computed(() => rawUsers.value?.users);

watch([organizationTagState, userInfoState], () => {
  if (
    organizationTagState.value === 'ERROR' ||
    userInfoState.value === 'ERROR'
  ) {
    createSnackbar({
      message: '組織タグの取得に失敗しました',
      type: 'error',
    });
  }
});
watch([usersState], () => {
  if (usersState.value === 'ERROR') {
    createSnackbar({
      message: 'メンバーの取得に失敗しました',
      type: 'error',
    });
  }
});
const belongingData = computed(() => {
  return organizationTagList.value?.organization_tags?.filter(item =>
    userInfo.value?.organization_tags.includes(item.name),
  );
});
const unBelongingData = computed(() => {
  return organizationTagList.value?.organization_tags?.filter(
    item => belongingData.value?.indexOf(item) === -1,
  );
});

type TabName = 'organization_tag' | 'user';
const visibleTab = ref<TabName>('organization_tag');
const changeVisible = (visibleState: TabName): void => {
  if (visibleState === visibleTab.value) return;
  visibleTab.value = visibleState;
};

// 選択機能
const selectedOrganizationTags = ref<number[]>([]);
const onSelectOrganizationTag = (id: number) => {
  selectedOrganizationTags.value = [id];
};
const selectedUsers = ref<number[]>([]);
const onSelectUser = (id: number) => {
  selectedUsers.value = [id];
};

// state
const isLoadingForOrganizationTags = computed(() => {
  return (
    organizationTagState.value === 'PENDING' ||
    userInfoState.value === 'PENDING' ||
    usersState.value === 'PENDING'
  );
});
const isLoadingForUsers = computed(() => {
  return (
    organizationTagState.value === 'PENDING' ||
    userInfoState.value === 'PENDING' ||
    usersState.value === 'PENDING'
  );
});
const isDisabledForOrganizationTags = computed(() => {
  if (isLoadingForOrganizationTags.value || isSubmitting.value) return true;
  return selectedOrganizationTags.value.length <= 0;
});
const isDisabledForUsers = computed(() => {
  if (isLoadingForUsers.value || isSubmitting.value) return true;
  return selectedUsers.value.length <= 0;
});

// footer function
const emits = defineEmits<{
  (e: 'submit', followList: FollowListType): void;
  (e: 'close'): void;
}>();
const isSubmitting = ref(false);
const onSubmit = async () => {
  if (
    visibleTab.value === 'organization_tag' &&
    selectedOrganizationTags.value.length === 0
  )
    return;
  if (visibleTab.value === 'user' && selectedUsers.value.length === 0) return;
  isSubmitting.value = true;
  try {
    const createdFollowList = await api.createFollowList(
      [
        {
          id:
            visibleTab.value === 'organization_tag'
              ? selectedOrganizationTags.value[0]
              : selectedUsers.value[0],
          type: visibleTab.value,
        },
      ],
      visibleTab.value === 'organization_tag' ? 'recommended' : 'latest',
    );
    emits('submit', createdFollowList);
    createSnackbar({
      message: '新規リストを追加しました。',
      type: 'success',
    });
  } catch (error) {
    createSnackbar({
      message: '新規リストの作成に失敗しました',
      type: 'error',
    });
    if (error instanceof Error) {
      throw error;
    }
    throw new Error('予期しないエラーが発生しました');
  } finally {
    isSubmitting.value = false;
  }
};

// メンバー検索機能
const filterQuery = ref('');
const filteredUsers = computed(() => {
  if (!users.value) return [];
  if (!filterQuery.value) return users.value;

  return users.value.filter(user => {
    const isIncludeUserName = () =>
      user.user_name.toLowerCase().includes(filterQuery.value.toLowerCase());
    const isIncludeEmail = () =>
      user.email.toLowerCase().includes(filterQuery.value.toLowerCase());

    return isIncludeUserName() || isIncludeEmail();
  });
});
const sortedUsers = computed(() =>
  [...filteredUsers.value].sort((a, b) => {
    return a.user_name.localeCompare(b.user_name);
  }),
);
const handleChangeFilterQuery = (query: string) => {
  filterQuery.value = query;
};
watch(visibleTab, () => {
  filterQuery.value = '';
});
</script>

<template>
  <template v-if="isSubmitting">
    <Loading z-index="9999" />
  </template>
  <div class="wrapper">
    <sm-dialog
      class="auto-height screen-center auto-width"
      @close="emits('close')"
    >
      <template #header>
        <h3 class="title">新規リストの作成</h3>
      </template>
      <template #body>
        <div class="contents-area">
          <div class="tab-wrapper">
            <button class="button" @click="changeVisible('organization_tag')">
              <TextTabItem
                text="組織タグ"
                :is-selected="visibleTab === 'organization_tag'"
                :disabled="isSubmitting"
              />
            </button>
            <button class="button" @click="changeVisible('user')">
              <TextTabItem
                text="メンバー"
                :is-selected="visibleTab === 'user'"
                :disabled="isSubmitting"
              />
            </button>
          </div>
          <template v-if="visibleTab === 'organization_tag'">
            <div v-if="isLoadingForOrganizationTags" class="loading">
              <DgrLoading />
            </div>
            <div
              v-if="organizationTagList?.organization_tags.length === 0"
              class="message-wrapper contents-height"
            >
              <div class="empty-message">
                <div class="c-title c-title--s">組織タグは未作成です。</div>
                <div class="c-text c-text--s">
                  組織タグを作成するには、管理者権限が必要です。
                </div>
              </div>
            </div>
            <div v-else class="organization-tag-contents contents-height">
              <div
                v-if="belongingData && belongingData?.length > 0"
                class="organization-tag-content"
              >
                <p class="sub-title">所属中</p>
                <button
                  @click="
                    isSubmitting ? false : onSelectOrganizationTag(item.id)
                  "
                  v-for="item in belongingData"
                  :key="item.id"
                  :aria-selected="selectedOrganizationTags.includes(item.id)"
                  :aria-disabled="isSubmitting"
                  class="list-item button"
                >
                  {{ item.name }}
                </button>
              </div>
              <div
                v-if="unBelongingData && unBelongingData?.length > 0"
                class="organization-tag-content"
              >
                <p class="sub-title">未所属</p>
                <button
                  @click="
                    isSubmitting ? false : onSelectOrganizationTag(item.id)
                  "
                  v-for="item in unBelongingData"
                  :key="item.id"
                  :aria-selected="selectedOrganizationTags.includes(item.id)"
                  :aria-disabled="isSubmitting"
                  class="list-item button"
                >
                  {{ item.name }}
                </button>
              </div>
            </div>
          </template>
          <template v-else>
            <div v-if="isLoadingForUsers" class="loading">
              <DgrLoading />
            </div>
            <div v-else class="user-contents contents-height">
              <SearchBar
                @on-change-query="handleChangeFilterQuery"
                placeholder="検索"
                class="search-bar"
              />
              <div class="user-list">
                <div class="user-list-header">
                  <p class="sub-title table-title">名前</p>
                  <p class="sub-title">マークの公開範囲</p>
                </div>
                <template v-if="sortedUsers">
                  <button
                    v-for="user in sortedUsers"
                    :key="user.id"
                    :aria-selected="selectedUsers.includes(user.id)"
                    :aria-disabled="isSubmitting"
                    @click="isSubmitting ? false : onSelectUser(user.id)"
                    class="list-item button user-list-item"
                  >
                    <div class="name-column">
                      <p>{{ user.user_name }}</p>
                      <p class="email c-text--xs">{{ user.email }}</p>
                    </div>
                    <div class="scope-column">
                      {{
                        user.mypage_access_scope === 'public'
                          ? '全体'
                          : '自分のみ'
                      }}
                    </div>
                  </button>
                </template>
              </div>
            </div>
          </template>
        </div>
      </template>
      <template #footer>
        <div class="footer-wrapper">
          <button
            :class="{ disabled: isSubmitting }"
            :disabled="isSubmitting"
            class="dashboard-button"
            @click="() => emits('close')"
          >
            キャンセル
          </button>
          <button
            class="dashboard-button--primary"
            :class="{
              disabled:
                visibleTab === 'organization_tag'
                  ? isDisabledForOrganizationTags
                  : isDisabledForUsers,
            }"
            :disabled="
              visibleTab === 'organization_tag'
                ? isDisabledForOrganizationTags
                : isDisabledForUsers
            "
            @click="onSubmit"
          >
            追加
          </button>
        </div>
      </template>
    </sm-dialog>
  </div>
</template>

<style lang="scss" scoped>
.wrapper {
  border: 1px solid $color-gray400;
  border-radius: 8px;
  background-color: white;
  &:deep(.dialog-header) {
    height: auto;
  }
  &:deep(.dialog-body) {
    padding: 0;
  }
  &:deep(.dialog-footer) {
    border-top: 1px solid $color-gray400;
  }
}

.title {
  margin: 5px 0;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
}
.loading {
  padding: 16px;
}
.contents-area {
  // 現状のsm-dialogではwidthを指定できないためやむを得ないwidth↓
  width: 800px;
  display: flex;
  flex-direction: column;
}
.tab-wrapper {
  display: flex;
  margin: 4px 16px 0;
  border-bottom: 1px solid $color-gray400;
  // NOTE: 本来はclassを渡して対応したいですが、tab text itemの内部実装の影響で強めの指定をしています
  * {
    padding: 0;
    width: 120px;
  }
}
.contents-height {
  height: 370px;
}
.organization-tag-contents {
  overflow-y: auto;
}
.organization-tag-content {
  padding: 20px 16px;
  &:not(:last-of-type) {
    border-bottom: 1px solid $color-gray400;
  }
}
.sub-title {
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  padding: 5px 0;
}
.footer-wrapper {
  display: flex;
  justify-content: flex-end;
}
.user-contents {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.user-list {
  padding: 20px 16px;
  overflow-y: auto;
  flex-grow: 1;
}
.user-list-header {
  display: flex;
}
.search-bar {
  padding: 20px 16px 0;
  box-sizing: border-box;
}
.button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  appearance: none;
  height: auto;
}
.list-item {
  padding: 12px 8px;
  font-size: 14px;
  line-height: 22px;
  border-radius: 0;
  border-bottom: 1px solid $color-gray400;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  &:last-of-type {
    border-bottom: none;
  }
  &:first-of-type {
    border-top: 1px solid $color-gray400 !important;
  }
  &:hover {
    background-color: $color-gray200;
  }
  &[aria-selected='true'] {
    background-color: $color-green100;
    color: $color-green600;
  }
  &[aria-disabled='true'] {
    cursor: not-allowed;
  }
}
.user-list-item {
  display: flex;
}
.table-title {
  width: 580px;
}
.name-column {
  width: 600px;
  * {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.email {
  color: $color-text-secondary;
}
.scope-column {
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.message-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.empty-message {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 16px;
  gap: 12px;
  border: 1px solid $color-gray400;
  border-radius: 4px;
}
</style>
