import { onMounted, onUnmounted, Ref, ref } from 'vue';

type ElementRefType = Ref<Element | undefined>;

/**
 * カスタムフック呼び出し側でセレクトボックスごとに複数要素を監視できる作りにするため
 * 複数回呼ばれても冗長にならないように返却値はオブジェクトリテラルを使用していない
 *
 * 使用例)
 * const hogeRef = ref<Element>();
 * const isClickedOutsideHoge = useOutsideClick([hogeRef, '.fuga', '#hoge']);
 *
 * <template>
 *  <div ref="hogeRef">
 *    <DgrSelectbox></DgrSelectbox>
 *  </div>
 * <template>
 *
 * ※ 'fuga', '#hoge' はUIライブラリで生成されたDOMを指定する例
 */
export function useOutsideClick(refElements: Array<ElementRefType | string>) {
  const isClickedOutside = ref(false);

  const handleClickOutside = async (event: MouseEvent) => {
    const target = event.target as Element | null;
    if (!document.contains(target)) return; // click対象がない場合はスキップ
    if (!refElements) return;

    const clickedInsideRefElement = refElements.some(el => {
      if (typeof el === 'string') {
        // vue-datepicker-next など監視したいDOMの外側に生成される系のUIライブラリはclassやidで個別に監視が必要
        const element = document.querySelector(el);
        return element?.contains(target);
      } else {
        return el.value && el.value.contains(target);
      }
    });

    isClickedOutside.value = !clickedInsideRefElement;
  };

  onMounted(() => window.addEventListener('click', handleClickOutside));
  onUnmounted(() => window.removeEventListener('click', handleClickOutside));

  return isClickedOutside;
}
