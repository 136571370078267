<script setup lang="ts">
import { ref } from 'vue';
import { DgrIcon } from '@stockmarkteam/donguri-ui';

interface Props {
  onFileChange: (file: File | null) => void;
  inputAccept?: string;
  /** 選択ボタン文言 */
  buttonText?: string;
  /** 選択ボタンのヒント文言 */
  buttonHintText?: string;
  /** ファイルのヒント文言 */
  fileHintText?: string;
  /** ファイルのエラー文言 */
  fileErrorText?: string;
}

const props = withDefaults(defineProps<Props>(), {
  onFileChange: () => {},
  inputAccept: '*',
  buttonText: 'ファイルを選択',
  buttonHintText: undefined,
  fileHintText: undefined,
  fileErrorText: undefined,
});

const file = ref<File | null>(null);
const fileInputRef = ref<HTMLInputElement>();

const handleFileChange = (eventFile: File | null) => {
  file.value = eventFile;
  props.onFileChange(file.value);
};

const handleFileSelect = (event: Event) => {
  const files = (event.target as HTMLInputElement).files;
  if (!files) return;
  const file = files.item(0);
  handleFileChange(file);
};

const resetFile = () => {
  handleFileChange(null);
  if (fileInputRef.value) {
    fileInputRef.value.value = '';
  }
};

defineExpose({ file });
</script>

<template>
  <input
    hidden
    type="file"
    ref="fileInputRef"
    @change="handleFileSelect"
    :accept="props.inputAccept"
  />
  <div class="file-select">
    <button
      @click="fileInputRef?.click()"
      class="c-outlineBtn c-btn--auto c-outlineBtn--secondary"
    >
      {{ buttonText }}
    </button>
    <span class="c-text c-text--m button-hint-text">{{
      props.buttonHintText
    }}</span>
  </div>
  <div class="selected-file" v-if="file">
    <div class="file-name-wrapper">
      <div class="file-name-text">
        <span class="c-text c-text--m">{{ file.name }}</span>
        <DgrIcon
          class="reset-icon"
          size="small"
          name="times"
          @click="resetFile"
        />
      </div>
      <span class="c-text c-text--s file-hint-text">{{ fileHintText }}</span>
    </div>
    <div v-if="fileErrorText" class="c-text c-text--s file-error-text">
      {{ fileErrorText }}
    </div>
  </div>
</template>

<style scoped lang="scss">
.file-select {
  display: flex;
  align-items: center;
  .button-hint-text {
    margin-left: 8px;
    color: $color-gray800;
  }
}
.selected-file {
  background-color: $color-gray200;
  margin: 8px 0;
  padding: 0 8px;
  display: flex;
  flex-direction: column;
  .file-name-wrapper {
    min-height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .file-name-text {
      display: flex;
      align-items: center;
    }
    .reset-icon {
      margin-left: 8px;
      cursor: pointer;
    }

    .file-hint-text {
      color: $color-gray800;
    }
  }
  .file-error-text {
    margin: 8px 0;
    color: $color-orange1000;
  }
}
</style>
