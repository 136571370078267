<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  PropType,
  ref,
} from 'vue';
import dayjs from 'dayjs';
import ArticleCardSmall from '@/components/common/article/article-card-small.vue';
import { Article, Theme } from '@/types';
import { useThemeRelatedFeed } from '@/utils/swr';
import { useEmitter, useStore } from '@/utils/vue';

export default defineComponent({
  props: {
    theme: { type: Object as PropType<Theme>, required: true },
  },
  components: {
    ArticleCardSmall,
  },
  setup({ theme }) {
    const store = useStore();
    const emitter = useEmitter();
    const feedType = computed(() => store.state.feedType.feedType);

    const feedStartDate = computed(() =>
      theme.feed_last_updated_at ? dayjs(theme.feed_last_updated_at) : dayjs(),
    );

    // NOTE: feed_typeが増えた場合はBackend側で配列で受け取るように修正する
    const { data: domesticData } = useThemeRelatedFeed(
      feedType.value !== 'foreign' ? theme.id : null,
      ref('domestic'),
      ref(feedStartDate.value.toDate()),
      ref(1),
      2,
    );
    const { data: foreignData } = useThemeRelatedFeed(
      feedType.value !== 'domestic' ? theme.id : null,
      ref('foreign'),
      ref(feedStartDate.value.toDate()),
      ref(1),
      2,
    );
    const articles = computed(() => {
      if (feedType.value === 'all') {
        return domesticData.value && foreignData.value
          ? [
              ...(domesticData.value?.related_feeds ?? []),
              ...(foreignData.value?.related_feeds ?? []),
            ]
          : undefined;
      } else if (feedType.value === 'domestic') {
        return domesticData.value?.related_feeds;
      } else {
        return foreignData.value?.related_feeds;
      }
    });

    onMounted(() => {
      emitter.on('article-updated', updateArticle);
    });

    onUnmounted(() => {
      emitter.off('article-updated', updateArticle);
    });

    const updateArticle = (updatedArticle: Article) => {
      if (!articles.value) return;
      const index = articles.value.findIndex(a => {
        return a.id === updatedArticle.id;
      });
      if (index >= 0) {
        articles.value[index].marks = updatedArticle.marks;
      }
      return articles;
    };

    return {
      feedType,
      articles,
    };
  },
});
</script>

<template>
  <div class="related-news-preview">
    <div class="title c-title c-title--s">関連ニュース</div>
    <template v-if="articles !== undefined">
      <div class="articles" v-for="article in articles" :key="article.id">
        <ArticleCardSmall
          :article="article"
          :theme-id="theme.id"
          :feed-type="feedType"
          page-name="theme"
          feature="related_feed"
        ></ArticleCardSmall>
        <div class="divider"></div>
      </div>
      <div
        class="related-news-page-link c-title c-title--s"
        v-if="articles.length > 0"
      >
        <router-link class="link" :to="{ name: 'themeRelatedNews' }"
          >もっと見る</router-link
        >
      </div>
    </template>
    <div class="empty-articles" v-if="articles && articles.length === 0">
      <div class="image-wrap">
        <img class="image" src="@/assets/state-prepare-feed-small.png" />
      </div>
      <div class="message c-text c-text--m">配信記事はありません。</div>
      <div class="message c-text c-text--m">
        記事を読んだりマークすると、おすすめ記事が配信されます。
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.related-news-preview {
  width: 280px;
  padding: 16px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  box-sizing: border-box;
  background: #ffffff;

  .title {
    display: flex;
    margin-bottom: 16px;
  }

  .divider {
    height: 1px;
    background: #e6e6e6;
    margin: 12px 0;
  }
  .related-news-page-link {
    display: flex;
    justify-content: center;

    .link {
      padding: 4px;
      color: #1da482;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .empty-articles {
    .image-wrap {
      display: flex;
      justify-content: center;
      margin-bottom: 12px;
      .image {
        width: 100px;
        height: 100px;
      }
    }
  }
}
</style>
