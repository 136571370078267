import { TeamInfo, ThemeList, UserInfo } from '@/types';

export function integrateWithIntercom(
  userInfo: UserInfo,
  teamInfo: TeamInfo,
  favoriteThemes: ThemeList[],
  markCount: number,
): void {
  const intercomScriptId = 'intercom-script';
  const intercomScript = document.getElementById(intercomScriptId);
  if (!intercomScript) {
    const script = document.createElement('script');
    script.id = intercomScriptId;
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('async', '');
    script.innerText =
      "(function(){var w=window;var ic=w.Intercom;if(typeof ic===\"function\"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/pa4jpete';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();";
    document.head.appendChild(script);
  }

  const params: Intercom_.IntercomSettings = {
    app_id: import.meta.env.VITE_INTERCOM_APP_ID,
    hide_default_launcher: true,
    user_id: String(userInfo.id),
    email: userInfo.email,
    user_role: userInfo.role,
    personal_feed: userInfo.has_personal_news,
    theme_favorite: favoriteThemes.length > 0,
    mark: markCount > 0,
    team_id: teamInfo.id,
    created_at: Math.round(Date.parse(userInfo.created_at) / 1000),
  };

  window.Intercom('boot', params);
}

export function startTourOfIntercom(tourId: number): void {
  window.Intercom('startTour', tourId);
}

export const INTERCOM_TOUR_IDS = {
  SHOW_INDUSTRY: 408909,
  SHOW_LATEST_MARKS: 408917,
  SHOW_SEARCH: 409269,
};
