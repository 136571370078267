<script lang="ts">
import {
  computed,
  defineComponent,
  inject,
  nextTick,
  onBeforeUnmount,
  onMounted,
  onUnmounted,
  PropType,
  provide,
  ref,
  toRef,
  watch,
} from 'vue';
import { useRoute } from 'vue-router';
import api from '@/api';
import {
  ContentsContext,
  Feature,
  FeedType,
  IndustryData,
  PageName,
  SearchData,
} from '@/api/tracking';
import { MAX_SITE_EXCLUSIONS } from '@/constants';
import { MYPAGE_ROUTE_NAMES } from '@/routers/constants';
import { DgrIcon } from '@stockmarkteam/donguri-ui';
import { isAxiosError } from 'axios';
import dayjs from 'dayjs';
import AdpDocumentCardUserAction from '@/components/common/adp-document/adp-document-card-user-action.vue';
import AdpDocumentBasicInfo from '@/components/common/adp-document/basic-info.vue';
import RelatedArticlesDisplay from '@/components/common/adp-document/related-articles-display.vue';
import TagItem from '@/components/common/atoms/tag.vue';
import CommentList from '@/components/common/comment/comment-list.vue';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import {
  AdpDocument,
  ArticleMarkSource,
  isArticle,
  KeywordFeed,
  Mark,
  PutExcludeSiteAndArticleError,
} from '@/types';
import { Comment, Group } from '@/types';
import { getDocTypeDisplayName } from '@/utils/adpDocument';
import {
  adpDocumentCardWrapperRefKey,
  visibleAdpCardKey,
} from '@/utils/injectionKeys';
import { userSession } from '@/utils/userSession';
import { useEmitter, useStore } from '@/utils/vue';

export default defineComponent({
  components: {
    CommentList,
    AdpDocumentCardUserAction,
    AdpDocumentBasicInfo,
    RelatedArticlesDisplay,
    TagItem,
    DgrIcon,
  },
  emits: {
    'click-remove-group-mark': () => true,
    'article-clicked': (adpDocument: AdpDocument) => adpDocument,
  },
  props: {
    adpDocument: {
      type: Object as PropType<AdpDocument>,
    },
    comment: {
      type: Object as PropType<Comment>,
    },
    themeId: {
      type: Number,
    },
    themeExcludedSitesCount: {
      type: Number,
    },
    width: { type: String, default: '616px' },
    showComment: { type: Boolean, default: true },
    articleExclusionId: {
      type: Number,
    },
    pageName: { type: String as PropType<PageName>, required: true },
    feature: { type: String as PropType<Feature> },
    feedType: { type: String as PropType<FeedType> },
    markSource: {
      type: String as PropType<ArticleMarkSource>,
    },
    rankInWholeFeed: { type: Number },
    searchData: { type: Object as PropType<SearchData> },
    isSelected: { type: Boolean, default: false },
    enableHover: { type: Boolean, default: false },
    showCommentCount: { type: Number, default: 3 },
    group: { type: Object as PropType<Group> },
    foldReplies: { type: Boolean, default: true },
    caseStudyText: { type: String, default: '' },
    trendCompanyText: { type: String, default: '' },
    industryData: { type: Object as PropType<IndustryData> },
    hideUndisplayButton: { type: Boolean, default: false },
    enableSiteExclusionButton: { type: Boolean, default: false },
    showUserAction: { type: Boolean, default: true },
    isUseRemoveGroupMark: { type: Boolean, default: false },
    contentsContext: { type: Object as PropType<ContentsContext> },
    badgeNumber: { type: Number },
    badgeAnimationDelay: { type: Number },
    isBadgeAnimationEnabled: { type: Boolean, default: false },
    disableRelatedArticles: { type: Boolean, default: true },
    showTagList: { type: Boolean, default: false },
    priorityDisplayTags: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    execution: { type: String },
    isNarrow: { type: Boolean, default: false },
  },
  setup(props, context) {
    const store = useStore();
    const route = useRoute();
    const emitter = useEmitter();
    const { createSnackbar } = useSnackbar();

    // NOTE: 計測ログなど実行時にroute.fullPathを使用すると、
    // route.fullPathで取得する前に画面遷移など行われて、
    // 想定と異なるパスが取得される可能性があるため定数で保持する
    const PAGE_URL = route.fullPath;

    const userInfo = computed(() => store.state.userInfo.userInfo);
    const teamInfo = computed(() => store.state.teamInfo.teamInfo);
    const enableAllianceMedia = computed(
      () => teamInfo.value?.enable_alliance_media ?? false,
    );

    const isMarkingArticle = ref(false);
    const adpDocument = toRef(props, 'adpDocument');
    const isArticleClicked = ref(false);
    const showRelatedArticles = ref(false);
    const relatedArticles = ref<AdpDocument[]>([]);
    const relatedQuery = ref('');
    const requestId = ref('');

    const displayTags = computed(() => {
      const tagList = adpDocument.value?.tags ?? [];
      const priorityTags = tagList.filter(tag =>
        props.priorityDisplayTags.includes(tag),
      );

      return [
        ...priorityTags,
        ...tagList.filter(tag => !priorityTags.includes(tag)),
      ];
    });

    const hasMyMark = computed(() => {
      return (
        adpDocument.value?.marks.some(
          mark => mark.user_id === userInfo.value?.id,
        ) ?? false
      );
    });

    const userEnableTranslateTitle = computed(
      () => userInfo.value?.enable_translate_article_title,
    );

    const isNewArticle = computed(() => {
      if (!adpDocument.value) return false;
      const published = dayjs(adpDocument.value.published_at);
      const isYesterday = published.isSame(dayjs().subtract(1, 'day'), 'day');
      const isToday = published.isSame(dayjs(), 'day');
      return isYesterday || isToday;
    });

    const undisplayArticle = async () => {
      if (!isArticle(adpDocument.value)) return;
      const article = adpDocument.value;

      try {
        await api.excludeArticle(
          article.theme_id,
          article,
          {
            // FIXME: pageNameが設定されていないときに、送らなかったり、
            // 別の値にフォールバックしたりすると、トラック情報が
            // 変わってしまうので一旦「!」で型エラーを回避する
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            pageName: props.pageName!,
            pageUrl: PAGE_URL,
            feature: props.feature,
            feedType: props.feedType,
          },
          props.rankInWholeFeed,
        );
        createSnackbar({
          message: '記事を非表示にしました',
          type: 'success',
        });
      } catch (err) {
        createSnackbar({
          message: '記事を非表示にできませんでした',
          type: 'error',
        });
        throw err;
      }

      emitter.emit('article-removed', {
        themeId: props.themeId,
        articleId: adpDocument.value?.id,
        lang: adpDocument.value?.lang,
        feedType: props.feedType,
        // FIXME: 無条件にupcastしてしまっているが、一旦修正保留
        feedDate: (adpDocument.value as KeywordFeed).feed_date,
      });
    };

    const siteExclusionArticle = async () => {
      if (!isArticle(adpDocument.value)) return;
      const article = adpDocument.value;

      if (
        props.themeExcludedSitesCount &&
        props.themeExcludedSitesCount >= MAX_SITE_EXCLUSIONS
      ) {
        createSnackbar({
          message: `上限（${MAX_SITE_EXCLUSIONS}件）に達しています。登録したメディアは設定から変更できます。`,
          type: 'error',
        });
        return;
      }

      try {
        await api.excludeSiteAndArticle(
          article,
          {
            // FIXME: pageNameが設定されていないときに、送らなかったり、
            // 別の値にフォールバックしたりすると、トラック情報が
            // 変わってしまうので一旦「!」で型エラーを回避する
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            pageName: props.pageName!,
            pageUrl: PAGE_URL,
            feature: props.feature,
            feedType: props.feedType,
          },
          props.rankInWholeFeed,
        );
        createSnackbar({
          message: 'メディアを除外しました（次回の更新から反映されます）',
          type: 'success',
        });
      } catch (err) {
        let errorMessage = 'メディアを除外できませんでした';
        if (
          isAxiosError<PutExcludeSiteAndArticleError>(err) &&
          err.response?.data.error === 'site limit exceed'
        ) {
          errorMessage = `上限（${MAX_SITE_EXCLUSIONS}件）に達しています。登録したメディアは設定から変更できます。`;
        } else if (
          isAxiosError<PutExcludeSiteAndArticleError>(err) &&
          err.response?.data.error === 'site already excluded'
        ) {
          errorMessage = '既に登録しているメディアです';
        }
        createSnackbar({
          message: errorMessage,
          type: 'error',
        });
        return;
      }

      emitter.emit('article-removed', {
        themeId: props.themeId,
        articleId: adpDocument.value?.id,
        lang: adpDocument.value?.lang,
        feedType: props.feedType,
        // FIXME: 無条件にupcastしてしまっているが、一旦修正保留
        feedDate: (adpDocument.value as KeywordFeed).feed_date,
      });
    };

    const notInterestedArticle = async () => {
      if (!adpDocument.value) return;

      const excludeResp = await api.excludeArticle(
        undefined,
        adpDocument.value,
        {
          // FIXME: pageNameが設定されていないときに、送らなかったり、
          // 別の値にフォールバックしたりすると、トラック情報が
          // 変わってしまうので一旦「!」で型エラーを回避する
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          pageName: props.pageName!,
          pageUrl: PAGE_URL,
          feature: props.feature,
          feedType: props.feedType,
        },
        props.rankInWholeFeed,
      );
      emitter.emit('article-updated', {
        ...adpDocument.value,
        article_exclusion_id: excludeResp.id,
      });
      try {
        createSnackbar({
          message: 'フィードバックをいただきありがとうございます',
          type: 'success',
          undoFunc: async () => {
            await api.deleteExcludeArticle(excludeResp.id);
            emitter.emit('article-updated', {
              ...adpDocument.value,
              article_exclusion_id: null,
            });
            createSnackbar({
              message: 'フィードバックを取り消しました',
              type: 'success',
            });
            emitter.emit('personal-feed-article-restored', adpDocument.value);
          },
        });
      } catch (err) {
        createSnackbar({
          message: 'フィードバックエラーになりました。',
          type: 'error',
        });
        throw err;
      }
      emitter.emit('personal-feed-article-removed', adpDocument.value);
    };

    const removeNotInterestedArticle = async () => {
      if (!adpDocument.value) return;

      if (props.articleExclusionId) {
        await api.deleteExcludeArticle(props.articleExclusionId);
        emitter.emit('article-updated', {
          ...adpDocument.value,
          article_exclusion_id: null,
        });
        try {
          createSnackbar({
            message: 'フィードバックをいただきありがとうございます',
            type: 'success',
            undoFunc: async () => {
              if (!adpDocument.value) return;

              const excludeResp = await api.excludeArticle(
                undefined,
                adpDocument.value,
                {
                  // FIXME: pageNameが設定されていないときに、送らなかったり、
                  // 別の値にフォールバックしたりすると、トラック情報が
                  // 変わってしまうので一旦「!」で型エラーを回避する
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  pageName: props.pageName!,
                  pageUrl: PAGE_URL,
                  feature: props.feature,
                  feedType: props.feedType,
                },
                props.rankInWholeFeed,
              );
              emitter.emit('article-updated', {
                ...adpDocument.value,
                article_exclusion_id: excludeResp.id,
              });
              createSnackbar({
                message: 'フィードバックを取り消しました',
                type: 'success',
              });
            },
          });
        } catch (err) {
          createSnackbar({
            message: 'フィードバックエラーになりました。',
            type: 'error',
          });
          throw err;
        }
      }
    };

    const markArticle = async () => {
      if (isMarkingArticle.value || !userInfo.value || !adpDocument.value)
        return;
      isMarkingArticle.value = true;

      try {
        if (hasMyMark.value) {
          await removeMyMark(userInfo.value.id, adpDocument.value);
        } else {
          await sendMark(
            adpDocument.value,
            props.themeId ?? null,
            userInfo.value.id,
            props.markSource,
          );
        }
      } finally {
        await nextTick();
        isMarkingArticle.value = false;
      }
    };

    const hitKeywords = computed(() => {
      const hit_keywords = adpDocument.value?.hit_keywords ?? [];
      const synonym_keywords = adpDocument.value?.synonym_keywords ?? [];
      const keywords = [...hit_keywords, ...synonym_keywords].filter(
        keyword => keyword.length > 0,
      );
      return keywords;
    });

    const hasComment = computed(() => {
      return props.comment || comments.value.length > 0;
    });

    const articleComments = computed(() => adpDocument.value?.comments ?? []);
    const comments = computed(() => {
      if (props.comment) return [props.comment];
      return articleComments.value;
    });

    const countedComments = computed(() => {
      return articleComments.value;
    });

    const latestComments = computed(() => {
      return comments.value.slice(-props.showCommentCount);
    });

    const isUndisplayButtonShown = computed(() => {
      if (props.hideUndisplayButton) return false;
      if (!props.themeId || !isArticle(adpDocument.value)) return false;
      switch (route.name) {
        case 'themeFeed':
        case 'anewsHome':
          return true;
      }
      return false;
    });

    const isSiteExclusionButtonShown = computed(() => {
      if (!props.enableSiteExclusionButton) return false;
      if (!props.themeId || !isArticle(adpDocument.value)) return false;
      const routeName = route.name;
      switch (routeName) {
        case 'themeFeed':
          return true;
      }
      return false;
    });

    const onClickRemoveGroupMark = () => {
      context.emit('click-remove-group-mark');
    };

    const onClickLink = () => {
      if (!adpDocument.value) return;
      context.emit('article-clicked', adpDocument.value);
    };

    const isIndustryNews = computed(() => {
      const isEnabled =
        props.caseStudyText !== '' && props.trendCompanyText !== '';
      return isEnabled;
    });

    const isMarkNews = computed(() => {
      return props.feature === 'team_marks';
    });

    const isNotInterested = computed(() => {
      if (props.themeId || isIndustryNews.value || isMarkNews.value)
        return false;

      const routeName = route.name;
      switch (routeName) {
        case 'anewsHome':
          return true;
        case 'personalNews':
          return true;
        case 'personalResearchPapers':
          return true;
        case 'personalPatents':
          return true;
        case MYPAGE_ROUTE_NAMES.views:
          return true;
      }
      return false;
    });

    const setupModal = () => {
      store.commit('modal/setArticle', adpDocument.value);
      store.commit(
        'modal/setTheme',
        store.state.themeList.themes.find(theme => theme.id === props.themeId),
      );
      store.commit('modal/setGroup', props.group);
      store.commit('modal/setPageName', props.pageName);
      store.commit('modal/setFeature', props.feature);
      store.commit('modal/setFeedType', props.feedType);
      store.commit('modal/setMarkSource', props.markSource);
      store.commit('modal/setRankInWholeFeed', props.rankInWholeFeed);
      store.commit('modal/setSearchData', props.searchData);
      store.commit('modal/setIndustryData', props.industryData);
      store.commit('modal/setDescription', props.trendCompanyText);
      store.commit('modal/setContentsContext', props.contentsContext);
      store.commit('modal/setExecution', props.execution);
    };

    const showCreateCommentModal = () => {
      setupModal();
      store.commit('modal/showModal', 'commentCreate');
    };

    const showGroupMarkModal = () => {
      setupModal();
      store.commit('modal/showModal', 'groupMark');
    };

    const showMarkModal = () => {
      setupModal();
      store.commit('modal/showModal', 'mark');
    };

    const removeMyMark = async (
      userId: number,
      adpDocumentData: AdpDocument | undefined,
    ) => {
      if (!adpDocumentData) return;
      const myMark = adpDocumentData.marks.find(
        mark => mark.user_id === userId,
      );

      if (!myMark) return;

      let article: AdpDocument;
      try {
        await api.deleteMark(myMark.id);
        article = await api.fetchDocument(
          adpDocumentData.id,
          adpDocumentData.doc_type,
        );
      } catch (e) {
        createSnackbar({
          message: 'マークを取り消せませんでした',
          type: 'error',
        });
        throw e;
      }
      adpDocumentData.marks = article.marks;
      article.hit_keywords = adpDocumentData.hit_keywords;
      article.synonym_keywords = adpDocumentData.synonym_keywords;
      emitter.emit('remove-article-for-article-list', adpDocumentData);
      await updateFeeds(article);
      createSnackbar({
        message: 'マークを取り消しました',
        type: 'success',
        undoFunc: async () => {
          // NOTE: reactive値をtargetにしないと画面更新がされないため、こちらだけ adpDocument.valueで対応している
          // こちらは暫定的な対応です。経緯: https://anews.atlassian.net/browse/ANDEV-4398
          if (!adpDocument.value) return;
          // テーマがない時は記事取得のために0になることがあるのでnullにする
          const themeId = props.themeId ?? null;
          sendMark(adpDocument.value, themeId, userId, props.markSource, false);
        },
      });
    };

    const sendMark = async (
      article: AdpDocument,
      themeId: number | null,
      _userId: number,
      markSource: ArticleMarkSource,
      showSnackbar = true,
    ) => {
      if (!userInfo.value) return;
      let createdMark: Mark;
      try {
        createdMark = await api.sendMark(
          article,
          themeId,
          markSource,
          {
            // FIXME: pageNameが設定されていないときに、送らなかったり、
            // 別の値にフォールバックしたりすると、トラック情報が
            // 変わってしまうので一旦「!」で型エラーを回避する
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            pageName: props.pageName!,
            pageUrl: PAGE_URL,
            feature: props.feature,
            feedType: props.feedType,
            groupId: props.group?.id,
          },
          props.rankInWholeFeed,
          props.searchData,
          props.industryData,
          props.trendCompanyText,
          props.contentsContext,
          props.execution,
        );
      } catch (e) {
        createSnackbar({
          message: 'マークできませんでした',
          type: 'error',
        });
        throw e;
      }
      article.marks.push({
        ...createdMark,
        user_name: userInfo.value.user_name,
      });
      emitter.emit('add-article-for-article-list', article);
      await updateFeeds(article);
      if (showSnackbar) {
        createSnackbar({
          type: 'link',
          message: 'マイページ',
          params: {
            link: `/users/${userInfo.value?.id}`,
            suffixText: 'にマークしました',
          },
        });
      }
    };

    const updateFeeds = async (article: AdpDocument) => {
      emitter.emit('article-updated', article);
      const routeName = route.name;
      switch (routeName) {
        case 'userMarks':
          await store.dispatch('userActions/updateUserMarkArticle', {
            userId: Number(route.params.userId),
            themeId: Number(props.themeId),
            articleId: article.id,
            docType: article.doc_type,
          });
          break;
        case MYPAGE_ROUTE_NAMES.marks:
          await store.dispatch('userActions/updateUserMarkArticle', {
            userId: Number(userSession.getUserId()),
            themeId: Number(props.themeId),
            articleId: article.id,
            docType: article.doc_type,
          });
          break;
        case 'search':
          await store.dispatch('searchResults/updateArticle', {
            articleId: article.id,
            docType: article.doc_type,
          });
          break;
      }
    };

    const docTypeDisplayName = computed(() =>
      getDocTypeDisplayName(adpDocument.value),
    );

    const isAllianceMedia = computed(() => {
      if (!adpDocument.value) return false;
      return (
        'is_alliance_media' in adpDocument.value &&
        adpDocument.value.is_alliance_media
      );
    });

    const getRelatedArticles = async () => {
      if (
        !adpDocument.value ||
        props.disableRelatedArticles ||
        !isArticle(props.adpDocument) ||
        props.adpDocument.lang !== 'ja' ||
        !props.adpDocument.related_articles_available
      )
        return;

      isArticleClicked.value = true;
      document.addEventListener(
        'visibilitychange',
        getRelatedArticlesIfVisible,
      );
      if (document.visibilityState === 'hidden') return;
      try {
        const response = await api.getRelatedArticles(adpDocument.value.id);
        relatedArticles.value = response.articles;
        relatedQuery.value = response.search_query;
        requestId.value = response.request_id;
      } catch {
        relatedArticles.value = [];
        relatedQuery.value = '';
        requestId.value = '';
      }

      document.removeEventListener(
        'visibilitychange',
        getRelatedArticlesIfVisible,
      );

      await nextTick();
      showRelatedArticles.value = true;
    };

    watch(showRelatedArticles, show => {
      if (!adpDocument.value) return;

      const container = document.getElementById(
        `related-articles-${adpDocument.value.id}`,
      );
      if (!container) return;

      const relatedArticles = container.firstChild as HTMLElement;

      const openHeight = `${relatedArticles.scrollHeight}px`;
      const containerFrames = [
        { height: '0', opacity: 0 },
        { height: openHeight, opacity: 1 },
      ];
      const relatedArticlesFrames = [
        { transform: 'translateY(-100%)' },
        { transform: 'translateY(0)' },
      ];
      const options = {
        duration: 600,
        iterations: 1,
        easing: 'ease-in-out',
      };
      container.animate(
        show ? containerFrames : [...containerFrames].reverse(),
        options,
      );

      relatedArticles.animate(
        show ? relatedArticlesFrames : [...relatedArticlesFrames].reverse(),
        options,
      );

      if (show) {
        container.classList.add('open');
        container.style.height = openHeight;
      } else {
        container.classList.remove('open');
        container.style.height = '0';
      }
    });

    const getRelatedArticlesIfVisible = () => {
      if (document.visibilityState === 'visible' && isArticleClicked.value) {
        getRelatedArticles();
      }
    };

    const isInactiveTag = (item: string) => {
      if (props.priorityDisplayTags.length === 0) return false;
      return !props.priorityDisplayTags.includes(item);
    };

    onMounted(async () => {
      document.addEventListener(
        'visibilitychange',
        getRelatedArticlesIfVisible,
      );
    });

    onBeforeUnmount(() =>
      document.removeEventListener(
        'visibilitychange',
        getRelatedArticlesIfVisible,
      ),
    );

    // FIXME: 共通コンポーネントに独自の処理を入れない
    const wrapperRef =
      route.fullPath === '/follow_lists'
        ? inject(adpDocumentCardWrapperRefKey)
        : null;
    const options = {
      root: wrapperRef?.value,
      rootMargin: '0px',
      threshold: 0,
    };
    const visibleAdpCard = ref(true);
    provide(visibleAdpCardKey, visibleAdpCard);

    const callback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach(entry => {
        visibleAdpCard.value = entry.isIntersecting;
      });
    };
    const adpDocumentCardRef = ref<HTMLElement | null>(null);
    let observer: IntersectionObserver | null = null;
    onMounted(() => {
      if (!wrapperRef?.value) return;
      observer = new IntersectionObserver(callback, options);
      if (adpDocumentCardRef.value) {
        observer.observe(adpDocumentCardRef.value);
      }
    });
    onUnmounted(() => {
      if (observer) {
        observer.disconnect();
        observer = null;
      }
    });

    const updateIsRead = () => {
      if (!adpDocument.value) return;
      emitter.emit('article-updated', { ...adpDocument.value, is_read: true });
    };

    return {
      userInfo,
      isMarkingArticle,
      userEnableTranslateTitle,
      isNewArticle,
      undisplayArticle,
      siteExclusionArticle,
      notInterestedArticle,
      removeNotInterestedArticle,
      markArticle,
      hasComment,
      comments,
      countedComments,
      latestComments,
      isUndisplayButtonShown,
      isSiteExclusionButtonShown,
      onClickRemoveGroupMark,
      isIndustryNews,
      isNotInterested,
      relatedArticles,
      relatedQuery,
      requestId,
      showCreateCommentModal,
      showGroupMarkModal,
      showMarkModal,
      showRelatedArticles,
      getRelatedArticles,
      docTypeDisplayName,
      isAllianceMedia,
      enableAllianceMedia,
      isArticle,
      displayTags,
      isInactiveTag,
      adpDocumentCardRef,
      hitKeywords,
      updateIsRead,
      onClickLink,
    };
  },
});
</script>

<template>
  <div
    class="document-card"
    :style="{ width: width }"
    :class="{
      'document-card__inner': adpDocument && comments.length === 0,
      'document-card-comment__inner': adpDocument && comments.length > 0,
      selected: isSelected,
      hovered: enableHover,
    }"
    ref="adpDocumentCardRef"
  >
    <template v-if="articleExclusionId">
      <div class="o-tags">
        <div class="o-not-interested-tag">
          <DgrIcon size="small" name="thumbs-down" />
          <div class="o-text c-text c-text--s">
            この{{ docTypeDisplayName }}に興味がない
          </div>
        </div>
      </div>
    </template>
    <template v-if="displayTags.length !== 0 && showTagList">
      <div class="tag-items">
        <DgrIcon size="small" name="tag" class="tag-icon" />
        <div v-for="item in displayTags" :key="item">
          <TagItem
            class="selected-tag-item"
            :key="item"
            :name="item"
            :is-editing="false"
            :is-small="true"
            :inactive="isInactiveTag(item)"
          >
          </TagItem>
        </div>
      </div>
    </template>

    <VTooltip placement="top" :disabled="hitKeywords.length === 0">
      <div v-if="adpDocument" class="document-info">
        <div class="document-body">
          <AdpDocumentBasicInfo
            :adp-document="adpDocument"
            :trend-company-text="isIndustryNews ? trendCompanyText : undefined"
            :is-new-article="isIndustryNews && isNewArticle"
            :user-enable-translate-title="userEnableTranslateTitle"
            :theme-id="themeId"
            :group-id="group?.id"
            :rank-in-whole-feed="rankInWholeFeed"
            :page-name="pageName"
            :feature="feature"
            :feed-type="feedType"
            :search-data="searchData"
            :industry-data="industryData"
            :contents-context="contentsContext"
            :execution="execution"
            :badge-number="badgeNumber"
            :badge-animation-delay="badgeAnimationDelay"
            :is-badge-animation-enabled="isBadgeAnimationEnabled"
            :is-narrow="isNarrow"
            @article-clicked="
              () => {
                onClickLink();
                updateIsRead();
                getRelatedArticles();
              }
            "
          />
        </div>
      </div>
      <template #popper>
        <span>含むキーワード</span>
        <div v-for="(keyword, keywordIndex) in hitKeywords" :key="keywordIndex">
          ・{{ keyword }}
        </div>
      </template>
    </VTooltip>
    <AdpDocumentCardUserAction
      v-if="adpDocument && showUserAction && adpDocument.doc_type !== 'report'"
      :adp-document="adpDocument"
      :comments="countedComments"
      :has-action-menu="true"
      :is-undisplay-button-shown="isUndisplayButtonShown"
      :is-site-exclusion-button-shown="isSiteExclusionButtonShown"
      :is-remove-group-mark-button-shown="isUseRemoveGroupMark"
      :is-marking-article="isMarkingArticle"
      :is-not-interested="isNotInterested"
      :article-exclusion-id="articleExclusionId"
      :disabled="!enableAllianceMedia && isAllianceMedia"
      :is-narrow="isNarrow"
      @click-mark-icon="markArticle"
      @click-open-mark-modal="showMarkModal"
      @click-comment-icon="showCreateCommentModal"
      @click-group-mark-icon="showGroupMarkModal"
      @click-undisplay="undisplayArticle"
      @click-site-exclusion="siteExclusionArticle"
      @click-not-interested="notInterestedArticle"
      @click-remove-not-interested="removeNotInterestedArticle"
      @click-remove-group-mark="onClickRemoveGroupMark"
    />
    <div
      class="spacing-12"
      v-else-if="adpDocument?.doc_type === 'report'"
    ></div>
    <div
      v-if="
        isArticle(adpDocument) &&
        adpDocument.related_articles_available &&
        relatedArticles.length > 0 &&
        relatedQuery
      "
      :id="`related-articles-${adpDocument.id}`"
      class="related-articles-container"
    >
      <RelatedArticlesDisplay
        class="related-articles"
        :page-name="pageName"
        :related-articles="relatedArticles"
        :related-query="relatedQuery"
        :request-id="requestId"
        @close="showRelatedArticles = false"
      />
    </div>
    <div
      class="o-divider"
      v-if="
        adpDocument &&
        showComment &&
        latestComments.length > 0 &&
        !isIndustryNews
      "
    ></div>
    <CommentList
      v-if="hasComment && showComment"
      :adp-document="adpDocument"
      :comments="latestComments"
      :feature="feature"
      :page-name="pageName"
      :fold-replies="foldReplies"
      :is-narrow="isNarrow"
      :show-avatar="!isNarrow"
      @click-comment="showCreateCommentModal"
    />
  </div>
</template>

<style scoped lang="scss">
div.document-card {
  .tag-items {
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    row-gap: 8px;
    .selected-tag-item {
      margin-right: 4px;
    }

    .tag-icon {
      margin-right: 4px;
      fill: $color-gray600;
    }
  }

  width: 'auto';
  background-color: white;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 4px;

  &.selected {
    border-color: #1da482;
  }
  &.hovered {
    &:hover {
      border-color: $color-gray800;
    }
  }

  .o-tags {
    display: flex;
    flex-direction: row;
    margin-bottom: 4px;
    .o-theme-tag {
      margin: -8px 0 2px 0;
      border-radius: 4px;
      padding: 1px;
      color: #4a4a4a;
      &:before {
        content: '# ';
      }
      &:not(:last-child) {
        &:after {
          content: ', ';
        }
      }
    }
    a .o-theme-tag {
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .o-not-interested-tag {
    display: flex;
    align-items: center;
    .o-text {
      margin-left: 2px;
    }
  }
  .document-info {
    display: flex;
    flex-direction: row;

    .document-body {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      gap: 16px;
    }
  }
  button.o-comment-count-label {
    height: auto;
    background-color: transparent;
    border: none;
    cursor: pointer;
    appearance: none;
    padding: 4px 0;
    color: #1da482;
    width: fit-content;
    margin-top: 8px;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
    &:focus {
      outline: 0;
    }
  }

  .related-articles-container {
    overflow: hidden;
    will-change: contents;
    height: 0;
    opacity: 0;

    .related-articles {
      transform: translateY(-100%);
    }

    &.open {
      opacity: 1;
      .related-articles {
        transform: translateY(0);
      }
    }
  }
  .o-divider {
    width: auto;
    height: 1px;
    background: #e6e6e6;
    margin: 4px 0;
  }
}
.document-card__inner {
  padding: 16px 16px 4px 16px;
}
.document-card-comment__inner {
  padding: 16px;
}
</style>
