export const isDev = import.meta.env.VITE_ENV === 'development';
export const isStg = import.meta.env.VITE_ENV === 'staging';
const isTest = import.meta.env.VITE_ENV === 'test';

// flagの内容
// false: 開発中
// true: リリース
const flags = {
  ANDEV_4571_ADD_GROUP_TAG_LIST: true,
  ANDEV_5137_ADD_MY_TAG_LIST: true,
  ANDEV_5318_PREVIEW_MODAL: true,
  ANDEV_5365_SURVEY_V4: false,
  ANDEV_5508_KEYWORD_FEED_FROM_SEARCH: false,
  ANDEV_5481_USER_MARK_SURVEY: false,
};

const isKey = (x: string): x is keyof typeof flags => x in flags;
Object.keys(flags).forEach(key => {
  if (!isKey(key)) return;
  flags[key] = isDev || isTest || flags[key];
});

// 検索結果のキーワードフィードはdevelopmentとstagingに反映される
flags.ANDEV_5508_KEYWORD_FEED_FROM_SEARCH =
  isStg || flags.ANDEV_5508_KEYWORD_FEED_FROM_SEARCH;

export const featureFlags = flags;
