<script lang="ts">
import { computed, defineComponent, watch } from 'vue';
import { useRouter } from 'vue-router';
import AdminBaseTable, { Field } from '@/components/admin/admin-base-table.vue';
import AdminContent from '@/components/layouts/admin-content.vue';
import Header from '@/components/layouts/header.vue';
import { formatDate } from '@/utils/formatters';
import { useManagementContracts } from '@/utils/swr';

type AdminTableItem = {
  id: number;
  contractName: string;
  maxAccount: number;
  currentAccountCount: number;
  managingUsersCount: number;
  formattedEndDate: string;
  endDate: Date;
};

export default defineComponent({
  components: {
    Header,
    AdminContent,
    AdminBaseTable,
  },
  setup() {
    const router = useRouter();
    const { data: managementContracts, state } = useManagementContracts();
    const contracts = computed(
      () => managementContracts.value?.management_contracts ?? [],
    );

    watch(
      contracts,
      changedContracts => {
        // 契約一覧が取得できない場合は、テーマ画面に遷移する
        if (state.value === 'SUCCESS' && changedContracts.length === 0)
          router.push({ name: 'themeAdmin' });
      },
      { immediate: true },
    );

    const fields: Field<AdminTableItem>[] = [
      {
        name: 'contractName',
        displayName: '契約名',
        isSortable: true,
        type: 'string',
        minWidth: 300,
        fieldPosition: 'left',
        cellPosition: 'left',
      },
      {
        name: 'currentAccountCount',
        displayName: 'アカウント数',
        isSortable: true,
        type: 'number',
        fieldPosition: 'center',
        cellPosition: 'right',
      },
      {
        name: 'managingUsersCount',
        displayName: '契約管理者数',
        isSortable: true,
        type: 'number',
        fieldPosition: 'center',
        cellPosition: 'right',
      },
      {
        name: 'endDate',
        displayName: '期限',
        isSortable: true,
        type: 'date',
        fieldPosition: 'left',
        cellPosition: 'left',
      },
    ];
    const items = computed<AdminTableItem[]>(() => {
      return contracts.value.map(contract => {
        return {
          contractName: contract.contract_name,
          maxAccount: contract.max_account,
          currentAccountCount: contract.current_account_count,
          managingUsersCount: contract.managing_users_count,
          formattedEndDate: formatDate(contract.end_date),
          endDate: new Date(contract.end_date),
          id: contract.id,
        };
      });
    });

    return {
      items,
      fields,
    };
  },
});
</script>

<template>
  <div class="contract-admin">
    <Header title="契約管理" header-width="100%" />
    <AdminContent>
      <div>
        <div class="title">
          <span class="c-title c-title--m">契約</span
          ><span class="count c-text c-text--s">・{{ items.length }}件</span>
        </div>
        <div class="spacing-16"></div>
        <AdminBaseTable :items="items" :fields="fields">
          <template #cell-contractName="{ item }">
            <VTooltip placement="bottom">
              <div class="contract-name-cell">
                {{ item.contractName }}
              </div>
              <template #popper>
                <span>{{ item.contractName }}</span>
              </template>
            </VTooltip>
          </template>
          <template #cell-currentAccountCount="{ item }">
            {{ item.currentAccountCount }} / {{ item.maxAccount }}
          </template>
          <template #cell-managingUsersCount="{ item }">
            <router-link
              class="member-page-link"
              :to="`contracts/${item.id}/members`"
              >{{ item.managingUsersCount }}</router-link
            >
          </template>
          <template #cell-endDate="{ item }">
            {{ item.formattedEndDate }}
          </template>
        </AdminBaseTable>
      </div>
    </AdminContent>
  </div>
</template>

<style lang="scss" scoped>
.contract-admin {
  width: 100%;
  margin: -24px 0 0 0;
  .title {
    display: flex;
    align-items: center;
    .count {
      color: #b3b3b3;
    }
  }
  .contract-name-cell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .member-page-link {
    text-decoration: underline;
  }
}
</style>
