import { getCurrentInstance } from 'vue';
import {
  config,
  customMap,
  event,
  exception,
  linker,
  optIn,
  optOut,
  pageview,
  purchase,
  query,
  refund,
  screenview,
  set,
  time,
  VueGtag,
} from 'vue-gtag';
import { Store, useStore as useStoreVuex } from 'vuex';
import { RootState } from '@/stores/pcStore';
import mitt from 'mitt';

function _getInstance() {
  const instance = getCurrentInstance();
  if (instance === null) {
    throw new Error('no vue instance');
  }
  return instance;
}

export function useGTag(): VueGtag {
  return {
    query,
    optIn,
    optOut,
    pageview,
    event,
    screenview,
    customMap,
    purchase,
    refund,
    linker,
    exception,
    set,
    config,
    time,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const emitter = mitt<any>();
export function useEmitter() {
  return emitter;
}

export function useStore(): Store<RootState> {
  return useStoreVuex<RootState>();
}
