<script lang="ts">
import {
  computed,
  defineComponent,
  PropType,
  reactive,
  Ref,
  ref,
  watch,
} from 'vue';
import { useRoute } from 'vue-router';
import { DgrCheckbox } from '@stockmarkteam/donguri-ui';
import Avatar from '@/components/common/atoms/avatar.vue';
import Filters, { SearchFilters } from '@/components/common/filters.vue';
import Loading from '@/components/common/loading.vue';
import SearchBar from '@/components/common/molecules/search-bar.vue';
import { UserRole } from '@/types';

export type AddableUser = {
  id: number;
  user_name: string;
  email: string;
  image_url: string;
  role: UserRole;
  organization_tags: string[];
  joined_group_names: string[];
  managed_contract_name_by_current_user: string | null;
};

export default defineComponent({
  components: {
    Avatar,
    SearchBar,
    Filters,
    Loading,
    DgrCheckbox,
  },
  props: {
    title: { type: String, default: 'メンバーの追加' },
    subtitle: { type: String, default: '追加できるメンバー' },
    noUsersMessage: { type: String, default: '追加できるメンバーはいません。' },
    isOpen: { type: Boolean, required: true },
    joinableUsers: {
      type: Array as PropType<AddableUser[]>,
      required: true,
    },
    disableFilters: {
      type: Boolean,
      default: false,
    },
    isUseContractOption: {
      type: Boolean,
      default: true,
    },
    isUseRoleOption: {
      type: Boolean,
      default: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    onClose: () => true,
    onSubmit: (_userIds: number[]) => true,
  },
  setup(props, { emit }) {
    const close = () => {
      selectedUsers.value = [];
      searchTextQuery.value = '';

      activeFilters.value.forEach(filter => {
        filter.selectedOptions = [];
      });

      selectedFilterOptions.productContractNames = [];
      selectedFilterOptions.organizationTagNames = [];
      selectedFilterOptions.roles = [];
      selectedFilterOptions.groups = [];
      emit('onClose');
    };
    const route = useRoute();

    watch(
      () => route.path,
      () => {
        close();
      },
    );

    const selectedFilterOptions = reactive({
      productContractNames: [] as string[],
      organizationTagNames: [] as string[],
      roles: [] as string[],
      groups: [] as string[],
    });

    const searchTextQuery = ref<string>('');

    const handleChangeSearchQuery = (query: string) => {
      searchTextQuery.value = query;
    };

    const queriedUsers = computed(() => {
      if (!searchTextQuery.value) return props.joinableUsers;

      return props.joinableUsers.filter(user => {
        const includesUserName = user.user_name
          .toLowerCase()
          .includes(searchTextQuery.value.toLowerCase());
        const includesEmail = user.email
          .toLowerCase()
          .includes(searchTextQuery.value.toLowerCase());
        return includesUserName || includesEmail;
      });
    });

    const filteredUsers = computed(() => {
      const hasSelectedFilterOptions = Object.values(
        selectedFilterOptions,
      ).some(option => option.length > 0);

      if (!hasSelectedFilterOptions) return queriedUsers.value;
      let selectedUser = queriedUsers.value.filter(user => {
        const roleFilterResult =
          selectedFilterOptions.roles.length > 0
            ? selectedFilterOptions.roles.some(role => {
                return roleMap[user.role] === role;
              })
            : true;

        const contractNameFilterResult =
          selectedFilterOptions.productContractNames.length > 0
            ? selectedFilterOptions.productContractNames.some(contract => {
                return user.managed_contract_name_by_current_user === contract;
              })
            : true;

        const groupFilterResult =
          selectedFilterOptions.groups.length > 0
            ? selectedFilterOptions.groups.some(group => {
                return user.joined_group_names.includes(group);
              })
            : true;

        const orgTagFilterResult =
          selectedFilterOptions.organizationTagNames.length > 0
            ? selectedFilterOptions.organizationTagNames.some(tag => {
                return user.organization_tags.includes(tag);
              })
            : true;

        return (
          roleFilterResult &&
          contractNameFilterResult &&
          groupFilterResult &&
          orgTagFilterResult
        );
      });
      return selectedUser;
    });

    const onSubmit = () => {
      const userIds = selectedUsers.value.map(u => u.id);
      emit('onSubmit', userIds);
      close();
    };

    const allSelect = () => {
      if (isAllSelected.value) {
        selectedUsers.value = [];
      } else {
        selectedUsers.value = [...selectedUsers.value, ...filteredUsers.value];
      }
    };

    const toggleUser = (targetUser: AddableUser) => {
      const selectedUserIds = selectedUsers.value.map(u => u.id);

      if (selectedUserIds.includes(targetUser.id)) {
        selectedUsers.value = selectedUsers.value.filter(
          u => u.id !== targetUser.id,
        );
      } else {
        selectedUsers.value.push(targetUser);
      }
    };

    const selectedUsers = ref<AddableUser[]>([]);

    const isAllSelected = computed({
      get: () => {
        return (
          filteredUsers.value.length > 0 &&
          filteredUsers.value.every(user => selectedUsers.value.includes(user))
        );
      },
      set: value => {
        if (value) {
          selectedUsers.value = [
            ...selectedUsers.value,
            ...filteredUsers.value,
          ];
        } else {
          selectedUsers.value = [];
        }
      },
    });

    const roleMap: Record<string, string> = {
      admin: '管理者',
      group_admin: 'グループ管理者',
      editor: '編集者',
      viewer: '閲覧者',
    };

    function moveToLast<T>(array: T[], valueToMove: T): T[] {
      const index = array.indexOf(valueToMove);
      if (index > -1) {
        array.splice(index, 1);
        array.push(valueToMove);
      }
      return array;
    }

    const orgTagOptions = computed(() => {
      const orgTagList = props.joinableUsers
        .map(user => {
          user.organization_tags = user.organization_tags.length
            ? user.organization_tags
            : [''];
          return user.organization_tags;
        })
        .flat();

      const sortedUniqueList = moveToLast([...new Set(orgTagList)], '');
      const options = sortedUniqueList.map(orgTag => ({
        name: orgTag,
        count: props.joinableUsers.filter(user =>
          user.organization_tags.includes(orgTag),
        ).length,
      }));
      return options;
    });

    const contractOptions = computed(() => {
      const contractList = props.joinableUsers
        .map(user => user.managed_contract_name_by_current_user ?? '')
        .flat();
      const sortedUniqueList = moveToLast([...new Set(contractList)], '');

      const options = sortedUniqueList.map(contract => ({
        name: contract,
        count: props.joinableUsers.filter(
          user => user.managed_contract_name_by_current_user === contract,
        ).length,
      }));
      return options;
    });

    const groupOptions = computed(() => {
      const groupList = props.joinableUsers
        .map(user => {
          user.joined_group_names = user.joined_group_names.length
            ? user.joined_group_names
            : [''];
          return user.joined_group_names;
        })
        .flat();
      const sortedUniqueList = moveToLast([...new Set(groupList)], '');

      const options = sortedUniqueList.map(group => ({
        name: group,
        count: props.joinableUsers.filter(user => {
          return user.joined_group_names.includes(group);
        }).length,
      }));
      return options;
    });

    const roleOptions = computed(() => {
      const options = Object.entries(roleMap)
        .map(([role, roleName]) => ({
          name: roleName,
          count: props.joinableUsers.filter(user => user.role === role).length,
        }))
        .filter(option => option.count > 0);
      return options;
    });

    function createFilter(
      label: string,
      options: {
        name: string;
        count: number;
      }[],
    ) {
      return {
        label,
        options,
        selectedOptions: [] as string[],
      };
    }

    const computedFilters = computed(() => {
      const filters = [];
      if (props.isUseContractOption) {
        filters.push(createFilter('契約', contractOptions.value));
      }
      filters.push(createFilter('組織タグ', orgTagOptions.value));
      if (props.isUseRoleOption) {
        filters.push(createFilter('権限', roleOptions.value));
      }
      filters.push(createFilter('グループ', groupOptions.value));

      return filters;
    });

    watch(
      () => computedFilters.value,
      () => {
        activeFilters.value = computedFilters.value;
      },
    );

    const activeFilters: Ref<SearchFilters> = ref(computedFilters.value);

    const filterSubmit = () => {
      activeFilters.value.forEach(filter => {
        switch (filter.label) {
          case '契約':
            selectedFilterOptions.productContractNames = filter.selectedOptions;
            break;
          case '権限':
            selectedFilterOptions.roles = filter.selectedOptions;
            break;
          case 'グループ':
            selectedFilterOptions.groups = filter.selectedOptions;
            break;
          case '組織タグ':
            selectedFilterOptions.organizationTagNames = filter.selectedOptions;
            break;
          default:
            break;
        }
      });
    };

    const isDisabledSubmitButton = computed(() => {
      return selectedUsers.value.length === 0;
    });

    return {
      close,
      isAllSelected,
      allSelect,
      selectedUsers,
      toggleUser,
      onSubmit,
      searchTextQuery,
      filteredUsers,
      handleChangeSearchQuery,
      activeFilters,
      filterSubmit,
      isDisabledSubmitButton,
    };
  },
});
</script>

<template>
  <Teleport to="body">
    <sm-dialog
      v-if="isOpen"
      class="auto-height auto-width no-padding screen-center"
      data-testid="add-users-modal"
      :show-header="true"
      @close="close"
    >
      <template #header>
        <p>{{ title }}</p>
      </template>
      <template #body>
        <div class="modal-body">
          <div class="modal-body-inner">
            <Loading v-if="isLoading" />
            <div
              v-else-if="joinableUsers.length === 0"
              class="empty-user-list-container"
            >
              <div class="empty-user-list" data-testid="empty-state">
                <p class="c-text--m">{{ noUsersMessage }}</p>
                <img src="@/assets/state-empty-article.png" />
              </div>
            </div>
            <template v-else>
              <div class="search-bar-container">
                <SearchBar
                  @on-change-query="handleChangeSearchQuery"
                  data-testid="search-bar"
                />
              </div>

              <div class="user-list-filter-container">
                <div class="member-count-container">
                  <p class="c-text--m">{{ subtitle }}</p>
                  <p class="c-text--s" data-testid="joinable-users-count">
                    ・{{ joinableUsers.length }}名
                  </p>
                </div>
                <div class="toggle-and-filters">
                  <slot name="toggle"></slot>
                  <Filters
                    v-if="!disableFilters"
                    v-model="activeFilters"
                    @input="filterSubmit"
                    class="m-search-settings"
                    data-testid="filters"
                    is-right-aligned
                  />
                </div>
              </div>
              <div class="user-list-container">
                <div class="user-list-header">
                  <div class="select-all-checkbox-container">
                    <span class="c-text--xs">すべて </span>
                    <DgrCheckbox
                      v-model="isAllSelected"
                      data-testid="add-all-checkbox"
                    />
                  </div>
                  <p class="c-text--s header-name">名前</p>
                </div>
                <ul class="user-list" data-testid="user-list">
                  <li
                    v-for="user in filteredUsers"
                    :key="user.id"
                    class="user-list-item"
                  >
                    <div class="select-checkbox-container">
                      <DgrCheckbox
                        :model-value="selectedUsers.includes(user)"
                        @update:model-value="toggleUser(user)"
                        data-testid="add-checkbox"
                      />
                    </div>
                    <div class="user-info-container">
                      <div>
                        <router-link :to="`/users/${user.id}`">
                          <Avatar
                            :image-url="user.image_url"
                            size="xs"
                          ></Avatar>
                        </router-link>
                      </div>

                      <div class="user-text-container">
                        <div class="name-container">
                          <router-link
                            :to="`/users/${user.id}`"
                            class="c-text--m name"
                            data-testid="user-name"
                          >
                            {{ user.user_name }}
                          </router-link>
                        </div>

                        <div class="email-container">
                          <p class="c-text--xs email" data-testid="user-email">
                            {{ user.email }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </template>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="o-footer c-text c-text--m">
          <button
            type="button"
            class="o-cancel-button c-btn c-btn--auto c-btnOutline"
            data-testid="cancel-button"
            @click="close"
          >
            キャンセル
          </button>
          <button
            type="button"
            class="o-create-button c-btn c-btn--auto c-btn--AnewsPrimary"
            :class="{ disabled: isDisabledSubmitButton }"
            :disabled="isDisabledSubmitButton"
            @click="onSubmit()"
            data-testid="submit-button"
          >
            追加
          </button>
        </div>
      </template>
    </sm-dialog>
  </Teleport>
</template>
<style lang="scss" scoped>
.modal-body {
  position: relative;
  width: 90vw;
  max-width: 800px;
  height: calc(80vh - 33px - 32px); // モーダルのヘッダーとフッターの高さを引く
  min-height: 480px;
  overflow-y: auto;
  background-color: $color-background;
}

.modal-body--empty {
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-body-inner {
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
}

.search-bar-container {
  margin-bottom: 16px;
}

.empty-user-list-container {
  width: 100%;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  padding: 0 16px;
  border-radius: $border-radius;
  background-color: #fff;
}

.empty-user-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 32px;

  img {
    width: 96px;
  }
}

.user-list-filter-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.member-count-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .c-text--s {
    color: $color-text-secondary;
  }
}

.user-list-container {
  width: 100%;
  border: 1px solid $color-border;
  box-sizing: border-box;
  padding: 16px 16px 0;
  border-radius: $border-radius;
  background-color: #fff;
}

.user-list-header {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 4px;
  padding: 8px 0;
  border-bottom: 1px solid $color-border;
  box-sizing: border-box;
}

.select-all-checkbox-container {
  width: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header-name {
  margin-bottom: 1px;
}

.user-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  border-bottom: 1px solid $color-border;
  box-sizing: border-box;
  padding: 8px 0;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: $color-btn-hover;

    &:has(.remove-button-container:hover),
    &:has(.action-menu-button:hover) {
      background-color: #fff;
    }
  }
}

.select-checkbox-container {
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-info-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  width: 100%;
  flex: 1;
}

.user-text-container {
  overflow: hidden;
}

.name-container {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.name {
  &:hover {
    text-decoration: underline;
  }
}

.email-container {
  overflow-wrap: break-word;
}
.email {
  color: $color-text-secondary;
}

:deep(.dialog-footer) {
  border-top: 1px solid $color-border;
}

.o-footer {
  padding: 16px;
}

.o-create-button.disabled {
  border: none;
}

.toggle-and-filters {
  display: flex;
  gap: 16px;

  :deep(.m-sm-toggle-button) {
    align-self: center;
  }
}
</style>
