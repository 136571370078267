<script setup lang="ts"></script>

<template>
  <div class="modal-mask">
    <!-- eslint-disable vue/require-explicit-emits -->
    <div class="modal-wrapper" @mousedown.self="$emit('close', false)">
      <div class="modal-container" @click.stop="() => undefined">
        <slot name="body"></slot>
      </div>
    </div>
  </div>
  <!-- eslint-enable vue/require-explicit-emits -->
</template>
<style scoped lang="scss">
// `--z-modal`を使用するとキーワード補完の`autocomplete-input.vue`より前に来てしまうため、
// `--z-dropdown` - 1の値を使用する
$z-demo-modal: 2;

.modal-mask {
  position: absolute;
  z-index: $z-demo-modal;
  width: 100%;
  height: 100%;
  background-color: rgba($color-gray1000, 0.5);
  transition: opacity 0.3s ease;
  display: flex;
  justify-content: center;
}

.modal-wrapper {
  margin-top: 5%;
  max-width: 480px;
  max-height: 509px;
  display: flex;
  flex-direction: column;
}

.modal-container {
  padding: 0px 16px 16px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  font-family: Helvetica, Arial, sans-serif;
}

@media (min-width: 800px) and (max-height: 790px) {
  .modal-wrapper {
    margin-top: 3%;
  }
}

@media (max-width: 800px) {
  .modal-wrapper {
    max-width: 386px;
    max-height: 509px;
  }

  .modal-container {
    width: auto;
  }
}
</style>
