import {
  AdpDocumentBasicInfo,
  DocType,
  isArticle,
  isPatent,
  isReport,
  isResearchPaper,
} from '@/types';

export const docTypeDisplayNameMap: Record<DocType, string> = {
  article: '記事',
  research_paper: '論文',
  patent: '特許',
  report: 'レポート',
};

export const getDocTypeDisplayName = (adpDocument?: AdpDocumentBasicInfo) =>
  adpDocument ? docTypeDisplayNameMap[adpDocument.doc_type] : '';

export const getPublisher = (adpDocument?: AdpDocumentBasicInfo) => {
  if (isArticle(adpDocument)) {
    return adpDocument.media_name;
  } else if (isResearchPaper(adpDocument)) {
    return adpDocument.journal_name;
  } else if (isPatent(adpDocument)) {
    return adpDocument.applicant;
  } else if (isReport(adpDocument)) {
    return adpDocument.site_name;
  }
  return '';
};
