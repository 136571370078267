<script lang="ts" setup>
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { NOTIFICATION_GROUPINGS } from '@/constants';
import { DgrIcon } from '@stockmarkteam/donguri-ui';
import NotificationMark from '@/components/notifications/notification-mark.vue';
import KeywordSearchBar from '@/components/search/keyword-search-bar.vue';
import { useWebPush } from '@/utils/composables/useWebPush';
import { useNotificationsUnviewedUnreadCount } from '@/utils/swr';
import { useStore } from '@/utils/vue';

const store = useStore();
const notificationButton = ref<HTMLButtonElement>();
const { canReceiveWebPushNotification: canReceiveWebPushNotification } =
  useWebPush();

// 未読通知の取得
const { data: unviewedUnreadCountData, mutate: mutateUnviewedUnreadCount } =
  useNotificationsUnviewedUnreadCount(NOTIFICATION_GROUPINGS);
// unviewdUnreadCountDataのfetchが完了したら残未読数をセットする
watch(
  unviewedUnreadCountData,
  () => {
    fetchViewedNotifiaction();
  },
  { immediate: true },
);

// 残未閲覧のセット
function fetchViewedNotifiaction() {
  if (unviewedUnreadCountData.value) {
    // オブジェクト内のunviewed_countの合計を取得して通知の未閲覧数を得る
    const unviewedCount = Object.values(
      unviewedUnreadCountData.value.counts,
    ).reduce((acc, cur) => {
      return acc + cur.unviewed_count;
    }, 0);
    store.commit(
      'notification/setUnviewedUnreadCountData',
      unviewedUnreadCountData.value,
    );
    store.commit('notification/setUnviewedCount', unviewedCount);
    store.commit('notification/setHasUnviewed', !!unviewedCount);
  }
}

const hasUnviewedNotification = computed(
  () => store.state.notification.hasUnviewed,
);

const showArticleShareModal = () => {
  store.commit('modal/showModal', 'articleShare');
};

/** 通知ポップアップの開閉状態 */
const isOpenNotificationPopup = computed(
  () => store.state.notificationPopup.isOpen,
);
const toggleNotificationPopup = () => {
  if (isOpenNotificationPopup.value) {
    store.commit('notificationPopup/close');
  } else {
    // 通知ボタンの相対位置からpopupの位置を決定する
    const rect = notificationButton.value?.getBoundingClientRect();
    if (!rect) return;
    store.commit('notificationPopup/show', {
      position: {
        top: `${rect.y + rect.height}px`,
        right: `${window.innerWidth - rect.x - rect.width}px`,
      },
    });
  }
};

/** Web Push通知を受け取ったときのハンドラー */
const handleWebPushNotificationMessageEvent = async (event: MessageEvent) => {
  if (event.data.type === 'notification-received') {
    await mutateUnviewedUnreadCount();
  }
};

onMounted(() => {
  if (canReceiveWebPushNotification()) {
    navigator.serviceWorker.addEventListener(
      'message',
      handleWebPushNotificationMessageEvent,
    );
  }
});

onBeforeUnmount(() => {
  if (canReceiveWebPushNotification()) {
    navigator.serviceWorker.removeEventListener(
      'message',
      handleWebPushNotificationMessageEvent,
    );
  }
});
</script>

<template>
  <div class="o-feed-header">
    <a class="o-anews-logo-link" :href="'/'"
      ><img class="p-anews-logo" src="@/assets/logo_anews.png"
    /></a>
    <KeywordSearchBar></KeywordSearchBar>
    <div class="o-navigation">
      <div class="o-navigation-icon-list">
        <button
          class="o-article-share-icon"
          v-tooltip.top="'通知'"
          ref="notificationButton"
          @click.stop="toggleNotificationPopup"
        >
          <DgrIcon name="bell" class="o-icon" />
          <NotificationMark
            v-if="hasUnviewedNotification"
            :right="'2px'"
            :top="'2px'"
          />
        </button>
        <button
          class="o-article-share-icon"
          v-tooltip.top="'外部リンクを共有'"
          @click="showArticleShareModal"
        >
          <DgrIcon name="clip-plus" class="o-icon" />
        </button>
        <a
          class="o-help"
          :href="'https://anews-stockmark.zendesk.com/hc/ja'"
          target="_blank"
          rel="noopener noreferrer"
          v-tooltip.top="'ヘルプ'"
        >
          <DgrIcon name="question-circle" class="o-icon"
        /></a>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.o-feed-header {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: $header-height;
  width: 100%;
  background-color: white;
  box-shadow: 0px 1px 5px rgba(74, 74, 74, 0.25);

  .o-anews-logo-link {
    margin: 0 0 0 24px;
    img.p-anews-logo {
      height: 32px;
      width: auto;
    }
  }

  .o-navigation {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    .o-navigation-icon-list {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: auto;
      margin-right: 24px;
      padding-left: 24px; /** intercom余白調整 */
      height: 64px;
      gap: 8px;
      .o-bell-icon,
      .o-article-share-icon,
      .o-help,
      .o-user-icon {
        position: relative;
        width: 32px;
        height: 32px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0;
        border-radius: 4px;
        border: none;
        background-color: white;

        &:hover {
          background-color: $color-gray200;
        }

        .o-icon {
          width: 21px;
          height: 21px;
          margin: 5.5px;
        }
      }
    }
  }
}

.o-icon {
  width: 28px;
  height: 28px;
}
</style>
