import { HTTP_URL_REGEXP, HTTP_URL_STRING_REGEXP } from '@/constants';
import { Group, MentionTarget, UserInfo } from '@/types';

export const getSortedMentionTargets = (
  targets: (UserInfo & Group)[],
  user: UserInfo,
): MentionTarget[] => {
  return targets
    .slice()
    .filter(t => t.group_type !== 'all_user_group')
    .sort((t1, t2) => {
      const name1 = t1.user_name ? t1.user_name : t1.name;
      const name2 = t2.user_name ? t2.user_name : t2.name;
      return name2.length - name1.length;
    })
    .map(t => {
      return {
        target: t,
        type: t.user_name ? 'user' : 'group',
        name: t.user_name ?? t.name,
        link: t.user_name ? `/#/users/${t.id}` : `/#/groups/${t.id}`,
        mentionToUser: t.user_name
          ? t.id === user.id
          : targets
              .filter(g => g.is_member && g.group_type !== 'all_user_group')
              .includes(t),
      };
    });
};

const parseIndividualName = (
  user: UserInfo,
  targets: (UserInfo & Group)[],
  text: string,
): string => {
  let parsedText = text;
  const targetListSortedByNameLength = getSortedMentionTargets(targets, user);
  for (const target of targetListSortedByNameLength) {
    const mentionName = RegExp(`@${escapeRegex(target.name)}`, 'g');
    if (target.mentionToUser && mentionName.test(parsedText)) {
      parsedText = parsedText.replace(
        mentionName,
        `<a class="mention-name mention-to-user">${target.type}${target.target.id}</a>`,
      );
    } else if (mentionName.test(parsedText)) {
      parsedText = parsedText.replace(
        mentionName,
        `<a class="mention-name">${target.type}${target.target.id}</a>`,
      );
    }
  }
  for (const target of targetListSortedByNameLength) {
    let mentionName = RegExp(
      `<a class="mention-name mention-to-user">${target.type}${target.target.id}</a>`,
      'g',
    );
    parsedText = parsedText.replace(
      mentionName,
      `<a href="${target.link}" class="mention-name mention-to-user">@${target.name}</a>`,
    );
    mentionName = RegExp(
      `<a class="mention-name">${target.type}${target.target.id}</a>`,
      'g',
    );
    parsedText = parsedText.replace(
      mentionName,
      `<a href="${target.link}" class="mention-name">@${target.name}</a>`,
    );
  }
  return parsedText;
};

const parseMentionName = (
  text: string,
  users: UserInfo[],
  groups: Group[],
  user: UserInfo,
) => {
  let parsedText = text;
  const mentionAll = RegExp('@全員', 'g');
  if (mentionAll.test(parsedText)) {
    parsedText = parsedText.replace(mentionAll, match => {
      return `<a class="mention-name mention-to-user">${match}</a>`;
    });
  }
  const targets = [...users, ...groups] as (UserInfo & Group)[];
  parsedText = parseIndividualName(user, targets, parsedText);
  return parsedText;
};

// テーマ名やユーザー名に半角の()がはいってると正規表現が壊れるのでエスケープ

export const escapeRegex = (string: string) => {
  return string.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
};

export const urlParse = (text: string): string => {
  if (!HTTP_URL_REGEXP.test(text)) return text;

  return text.replace(HTTP_URL_REGEXP, match => {
    return `<a href="${match}" class="url-link" target="_blank" rel="noopener noreferrer">${match}</a>`;
  });
};

const splitUrl = (
  text: string,
): { text: string; type: 'plaintext' | 'url' }[] => {
  if (HTTP_URL_REGEXP.test(text)) {
    const matchedUrlRegExp = text.match(HTTP_URL_REGEXP);
    return text
      .split(new RegExp(`(${matchedUrlRegExp?.join('|')})`))
      .map((t, index) => {
        if (index % 2 === 0) {
          return {
            text: t,
            type: 'plaintext',
          };
        }
        return {
          text: t,
          type: 'url',
        };
      });
  }
  return [
    {
      text,
      type: 'plaintext',
    },
  ];
};

const urlToLink = (url: string): string => {
  return `<a href="${url}" class="url-link" target="_blank" rel="noopener noreferrer">${url}</a>`;
};

export const commentParse = (
  text: string,
  users: any[], // eslint-disable-line @typescript-eslint/no-explicit-any
  groups: Group[],
  user: UserInfo,
  articleUrl?: string,
): string => {
  if (!users) return text;
  if (text === articleUrl) {
    return urlToLink(text);
  }
  const fragments = splitUrl(text);
  const urlExcludedFragments = fragments
    // articleUrlと一致するURLの場合は表示しない
    .filter(f => !f.text || f.text !== articleUrl)
    .map(f => {
      if (f.type === 'url') {
        return urlToLink(f.text);
      }
      return f.text;
    });
  text = urlExcludedFragments.join('');
  const hasAtSign = /.*@.*/;
  if (hasAtSign.test(text)) {
    text = parseMentionName(text, users, groups, user);
  }

  return text;
};

export const isHttpUrl = (url?: string): url is string => {
  if (url === undefined) return false;
  return url?.startsWith('https://') || url?.startsWith('http://');
};

export const isValidHttpUrl = (url?: string) => {
  if (url === undefined) return false;

  return isHttpUrl(url) && HTTP_URL_STRING_REGEXP.test(url);
};
