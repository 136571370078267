import noImage1to1 from '@/assets/noimage-1to1.jpg';
import noImage3to1 from '@/assets/noimage-3to1.jpg';
import noImage4to3 from '@/assets/noimage-4to3.jpg';
import noImage16to9 from '@/assets/noimage-16to9.jpg';
import * as Sentry from '@sentry/vue';
import { AxiosError, isAxiosError } from 'axios';
import signout from '@/utils/signout';
import { UNEXPECTED_CONTENT_TYPE_ERROR } from '../constants';

export const errorHandler = async (
  error: Error | unknown,
  vm: any, // eslint-disable-line @typescript-eslint/no-explicit-any
  _info: string,
): Promise<void> => {
  // 認証切れが発生(401)もしくはIP制限エラー(403)が発生したときはLocalStorageをクリアしてloginページに移動させる
  if (
    isAxiosError(error) &&
    (error.response?.status === 401 || error.response?.status === 403) &&
    vm.$route.name !== 'login'
  ) {
    await signout(vm.$gtag, vm.$router, vm.$route.fullPath);
    return;
  }

  // API呼び出しでtimeoutが発生したときにAPI endpointも記録する
  if (isAxiosError(error) && error.code === AxiosError.ECONNABORTED) {
    Sentry.captureMessage(
      `timeout occurs at the request to ${
        (error.config?.baseURL?.slice(0, -1) ?? '') + (error.config?.url ?? '')
      }`,
      'error',
    );
    return;
  }

  // api/index.tsで拾った予期しないContent-Typeに関するエラーのハンドリング
  if (
    error instanceof Error &&
    error.message === UNEXPECTED_CONTENT_TYPE_ERROR
  ) {
    // TODO: ここではinjectが使えないのでSnackbarが使えず、かつ、Sentryを見るかぎりは頻発はしていないので、
    // 一旦エラー表示はコメントアウトする。Sentryの状況を引き続き見て対応を検討する。
    // SentryのURL: https://stockmark.sentry.io/issues/4089094736/?project=1873285&referrer=issue-stream&statsPeriod=90d&stream_index=2
    // const { createSnackbar } = useSnackbar();
    // createSnackbar({
    //   message:
    //     '予期しない通信エラーが発生しました。<br>お使いのネットワークの設定などをご確認ください。',
    //   type: 'error',
    // });

    // Sentryにはwarningで記録する
    Sentry.captureMessage(UNEXPECTED_CONTENT_TYPE_ERROR, 'warning');
    return;
  }

  // ここでハンドルしないものはそのまま投げ直す
  throw error;
};

const sizeToImage = {
  '1to1': noImage1to1,
  '3to1': noImage3to1,
  '4to3': noImage4to3,
  '16to9': noImage16to9,
} as const;
export const imageErrorHandler = (e: Event, size: keyof typeof sizeToImage) => {
  if (e.target instanceof HTMLImageElement) {
    e.target.src = sizeToImage[size];
  }
};
