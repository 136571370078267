<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import api from '@/api';
import { UserDocument } from '@/types';
import { formatDate } from '@/utils/formatters';
import { getPreviewImageUrls } from '@/utils/user-document/common';

interface Props {
  id: string;
  page: number;
}
const props = defineProps<Props>();
const userDocument = ref<UserDocument>();

const previewImageUrls = computed(() =>
  userDocument.value
    ? getPreviewImageUrls(
        userDocument.value.file_total_pages,
        userDocument.value.preview_image_path_template,
      )
    : [],
);

const modifiedAt = computed(() =>
  userDocument.value?.modified_at
    ? formatDate(userDocument.value.modified_at)
    : undefined,
);

watch(
  () => props.id,
  async newId => (userDocument.value = await api.getUserDocumentById(newId)),
  { immediate: true },
);

// 特定のidまでスクロールする
const scrollToId = (id: string) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: 'instant' });
    // ヘッダーが重なるので上にスクロール
    window.scrollBy(0, -80);
  }
};

// 画像が読み込まれた際にスクロールする
const imageLoaded = (page: number) => {
  if (page === props.page) {
    scrollToId(`preview-${page}`);
  }
};

const getImageLoadingStrategy = (index: number) =>
  props.page && index < props.page + 5 && index > props.page - 5
    ? 'eager'
    : 'lazy';
</script>
<template>
  <div class="document-container-wrap">
    <div class="document-container">
      <div class="document-content">
        <div class="document-content-header">
          <div class="c-title c-title--xm">{{ userDocument?.title }}</div>
          <div class="document-update-info c-text c-text--s">
            <div>更新日：{{ modifiedAt }}</div>
            <div>更新者：{{ userDocument?.last_updated_by }}</div>
          </div>
        </div>
        <div
          class="preview-image"
          v-for="(url, i) in previewImageUrls"
          :key="url"
        >
          <img
            :id="`preview-${i + 1}`"
            :src="url"
            @load="imageLoaded(i + 1)"
            :loading="getImageLoadingStrategy(i + 1)"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.document-container-wrap {
  width: 100%;
  .document-container {
    margin: 0 auto;
    padding: 0 32px;
    max-width: 970px;
    .document-content {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      border-radius: 4px;
      background-color: #fff;
      border: 1px solid #e6e6e6;
      padding: 16px;
      .document-content-header {
        display: flex;
        flex-direction: column;
        row-gap: 8px;
        .document-update-info {
          display: flex;
          gap: 8px;
          color: $color-gray800;
        }
      }
      .preview-image {
        img {
          width: 100%;
        }
      }
    }
  }
}
</style>
