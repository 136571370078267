<script lang="ts" setup>
import { computed, ref } from 'vue';
import api from '@/api';
import { MAX_ORGANIZATION_TAG_NAME_LENGTH } from '@/constants';
import Loading from '@/components/common/loading.vue';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';

defineProps({
  hasPermission: Boolean,
});

const emits = defineEmits(['onSuccess']);

const { createSnackbar } = useSnackbar();

const newOrganizationTagName = ref('');
const isLoading = ref(false);

const isValidTagName = computed(() => {
  return (
    newOrganizationTagName.value.length > 0 &&
    newOrganizationTagName.value.length <= MAX_ORGANIZATION_TAG_NAME_LENGTH
  );
});

const isTooLongTagName = computed(() => {
  return newOrganizationTagName.value.length > MAX_ORGANIZATION_TAG_NAME_LENGTH;
});

const isDisabledSubmitButton = computed(() => {
  return !isValidTagName.value || isLoading.value;
});

const handleSubmit = async () => {
  isLoading.value = true;

  const response = await api.createOrganizationTag(
    newOrganizationTagName.value,
  );

  if (response.isSuccess) {
    createSnackbar({
      message: '組織タグを作成しました',
      type: 'success',
    });

    emits('onSuccess');
  } else {
    createSnackbar({
      message: response.message,
      type: 'error',
    });
  }

  isLoading.value = false;
};
</script>

<template>
  <div class="wrapper">
    <div class="container">
      <Loading v-if="isLoading" />

      <p class="title">組織タグはまだありません</p>

      <div class="sentence-container">
        <p class="sentence">
          組織タグは特定のメンバーをまとめることができます。
        </p>
        <p class="sentence">※管理者権限を持つユーザーが作成・編集できます。</p>
      </div>

      <form
        v-if="hasPermission"
        class="new-tag-form"
        novalidate
        @submit.prevent="handleSubmit"
      >
        <div>
          <textarea
            class="c-textArea text-area"
            :class="{ 'c-formTextarea--error': isTooLongTagName }"
            placeholder="新規組織タグ名を記入"
            v-model.trim="newOrganizationTagName"
            data-testid="tag-name-input"
          />
          <div class="tag-name-input-counter-container">
            <span
              v-if="isTooLongTagName"
              class="tag-name-input-counter tag-name-input-counter__error"
              data-testid="tag-name-error"
              >{{
                `${MAX_ORGANIZATION_TAG_NAME_LENGTH}文字以内で入力してください`
              }}</span
            >
            <span
              class="tag-name-input-counter"
              :class="{ 'tag-name-input-counter__error': isTooLongTagName }"
              data-testid="tag-name-counter"
              >{{
                `${newOrganizationTagName.length}/${MAX_ORGANIZATION_TAG_NAME_LENGTH}`
              }}</span
            >
          </div>
        </div>

        <button
          class="o-create-button c-btn c-btn--auto c-btn--AnewsPrimary"
          type="submit"
          :disabled="isDisabledSubmitButton"
          :class="{ disabled: isDisabledSubmitButton }"
          data-testid="submit-button"
        >
          作成
        </button>
      </form>
    </div>
  </div>
</template>

<style scoped lang="scss">
.wrapper {
  padding: 24px;
  box-sizing: border-box;
}
.container {
  width: fit-content;
  padding: 24px 16px;
  background-color: #fff;
}
.title {
  font-size: 16px;
  margin-bottom: 12px;
  text-align: center;
}
.sentence-container {
  margin-bottom: 12px;
}
.sentence {
  font-size: 14px;
  line-height: 1.5;
  margin: 0;
  text-align: center;
}
.c-textInput {
  width: 416px;
  outline: none;
}
.tag-name-input-counter {
  font-size: 10px;
  color: $color-gray600;

  &__error {
    color: $color-orange1000;
  }
}
.tag-name-input-counter-container {
  display: flex;
  justify-content: flex-end;
  gap: 4px;
}
.o-create-button {
  height: 32px;
  padding: 0 16px;
}
.new-tag-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
}
.text-area {
  resize: vertical;
  width: 416px;
}
</style>
