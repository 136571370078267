<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import api from '@/api';
import { DgrSelectbox, SelectboxItem } from '@stockmarkteam/donguri-ui';
import { isAxiosError } from 'axios';
import MSTeamsSettingsCard from '@/components/common/ms-teams-settings-card.vue';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import Content from '@/components/layouts/content.vue';
import Header from '@/components/layouts/header.vue';
import {
  DeliveryAt,
  MailDeliverySchedule,
  MSTeamsSettingsWithType,
  ScheduledNotificationType,
} from '@/types';
import { STATES, useMailDeliverySchedules, useTeamInfo } from '@/utils/swr';
import { checkMoveToAdmin } from '@/utils/user';

type MailDeliveryDisplayItem = {
  scheduledNotificationType: ScheduledNotificationType;
  name: string;
  options: SelectboxItem<DeliveryAt>[];
  selectedValue: DeliveryAt;
};

export default defineComponent({
  components: {
    Header,
    Content,
    MSTeamsSettingsCard,
    DgrSelectbox,
  },
  setup() {
    const router = useRouter();
    const { createSnackbar } = useSnackbar();

    const initialized = ref(false);
    const settings = ref<MSTeamsSettingsWithType | undefined>(undefined);

    const { data: teamInfo } = useTeamInfo();

    const { data: mailDeliverySchedules, state: mailDeliveryScheduleState } =
      useMailDeliverySchedules();

    const enableTechnicalLiterature = computed(
      () => teamInfo.value?.enable_technical_literature,
    );

    // メール配信設定の表示および、選択した時刻の保存に使用する
    const mailDeliveryDisplayItems = ref<MailDeliveryDisplayItem[]>([
      {
        scheduledNotificationType: 'favorite_theme_mail',
        name: enableTechnicalLiterature.value
          ? '新着ニュース 、新着論文・特許'
          : '新着ニュース',
        options: [
          {
            label: '7時〜8時',
            value: '07:00',
          },
          {
            label: '8時〜9時',
            value: '08:00',
          },
          {
            label: '9時〜10時',
            value: '09:00',
          },
          {
            label: '10時〜11時',
            value: '10:00',
          },
        ],
        selectedValue: '08:00',
      },
      {
        scheduledNotificationType: 'team_activity_mail',
        name: '先週のハイライト',
        options: [
          {
            label: '7時〜8時',
            value: '07:00',
          },
          {
            label: '8時〜9時',
            value: '08:00',
          },
          {
            label: '9時〜10時',
            value: '09:00',
          },
          {
            label: '10時〜11時',
            value: '10:00',
          },
        ],
        selectedValue: '08:00',
      },
      {
        scheduledNotificationType: 'overlooked_mail',
        name: '本日の読み逃しニュース',
        options: [
          {
            label: '16時〜17時',
            value: '16:00',
          },
          {
            label: '17時〜18時',
            value: '17:00',
          },
          {
            label: '18時〜19時',
            value: '18:00',
          },
        ],
        selectedValue: '17:00',
      },
    ]);
    watch(mailDeliverySchedules, () => {
      if (!mailDeliverySchedules.value) return;

      for (const item of mailDeliveryDisplayItems.value) {
        const schedule =
          mailDeliverySchedules.value.mail_delivery_schedules.find(
            x =>
              x.scheduled_notification_type === item.scheduledNotificationType,
          )?.deliver_at;

        if (schedule) {
          item.selectedValue = schedule;
        }
      }
    });

    onMounted(async () => {
      checkMoveToAdmin(router);
      await fetchTeamsSettings();
      initialized.value = true;
    });

    const fetchTeamsSettings = async () => {
      try {
        const teamsSettings = await api.fetchMSTeamsSettings();
        settings.value = {
          settings: teamsSettings,
          submittable: true,
          type: 'team',
        };
      } catch (e) {
        if (isAxiosError(e) && e.response?.status === 404) {
          settings.value = {
            settings: {
              webhook_url: '',
              enabled_ms_teams_notification: false,
              enabled_team_activity_content: false,
              enabled_mark_content: false,
              enabled_comment_content: false,
              enabled_group_mark_content: false,
            },
            type: 'team',
            submittable: true,
          };
          return;
        }
        throw e;
      }
    };

    const submit = async () => {
      const submitSchedules: MailDeliverySchedule[] =
        mailDeliveryDisplayItems.value.map(item => ({
          scheduled_notification_type: item.scheduledNotificationType,
          deliver_at: item.selectedValue,
        }));

      if (enableTechnicalLiterature.value) {
        // 画面上は同じ設定を使っているので、新着論文の配信時刻は新着ニュースの配信時刻と同じにする。
        const newResearchPaperMailSchedule: MailDeliverySchedule = {
          scheduled_notification_type: 'new_technical_literature_mail',
          deliver_at:
            mailDeliveryDisplayItems.value.find(
              item => item.scheduledNotificationType === 'favorite_theme_mail',
            )?.selectedValue ?? '08:00', // 08:00 はデフォルト値
        };
        submitSchedules.push(newResearchPaperMailSchedule);
      }

      if (!settings.value?.submittable) return;
      try {
        if (teamInfo.value?.enable_ms_teams_integration) {
          await api.updateMSTeamsSettings(settings.value.settings);
        }
        await api.updateMailDeliverySchedules(submitSchedules);
        createSnackbar({
          message: '環境設定を更新しました',
          type: 'success',
        });
      } catch {
        createSnackbar({
          message: '環境設定を更新できませんでした',
          type: 'error',
        });
      }
    };

    return {
      initialized,
      submit,
      settings,
      mailDeliveryDisplayItems,
      mailDeliveryScheduleState,
      STATES,
      teamInfo,
    };
  },
});
</script>

<template>
  <div class="o-service-integrations">
    <Header title="環境設定"></Header>
    <Content>
      <div class="environment-settings-body">
        <div
          class="o-integration"
          v-if="teamInfo && teamInfo.enable_ms_teams_integration"
        >
          <div class="teams-integration">
            <div class="environment-setting-category-title c-title c-title--s">
              Teams連携
            </div>
            <MSTeamsSettingsCard
              class="environment-settings-content teams"
              title="Anews全体の情報をTeamsに配信"
              v-model="settings"
            >
              Microsoft TeamsのチャネルにAnewsの情報を配信します。<br />グループ毎に設定する場合は、<a
                class="doc-link"
                href="https://anews-stockmark.zendesk.com/hc/ja/articles/4402116895897"
                target="_blank"
                rel="noopener noreferrer"
                >こちら</a
              >をご覧ください。
            </MSTeamsSettingsCard>
          </div>
          <div class="spacing-12"></div>
        </div>
        <div class="mail-delivery">
          <div class="environment-setting-category-title c-title c-title--s">
            定期配信メール設定
          </div>
          <div class="spacing-12"></div>
          <div class="c-text c-text--m">
            メール配信時間設定を行うことができます
          </div>
          <div class="spacing-12"></div>
          <div
            class="mail-delivery-content-wap"
            v-if="mailDeliveryScheduleState === STATES.SUCCESS"
          >
            <div
              class="mail-delivery-content environment-settings-content"
              v-for="item in mailDeliveryDisplayItems"
              :key="item.scheduledNotificationType"
            >
              <div class="mail-delivery-item">
                <div class="c-formBlock__label">{{ item.name }}</div>
                <DgrSelectbox
                  class="mail-delivery-selectbox"
                  :options="item.options"
                  v-model="item.selectedValue"
                  size="small"
                ></DgrSelectbox>
                <div class="spacing-12"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="spacing-24"></div>
        <button
          class="o-submit-button c-btn c-btn--small c-btn--AnewsPrimary"
          :disabled="!settings?.submittable"
          :class="{ disabled: !settings?.submittable }"
          @click.stop="submit"
        >
          保存
        </button>
      </div>
    </Content>
  </div>
</template>

<style lang="scss" scoped>
.o-service-integrations {
  width: 100%;
  margin: -24px 0 0 0;
}
.environment-settings-body {
  width: 616px;
  background-color: white;
  border: 1px solid $color-gray400;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 20px 16px;
  .environment-setting-category-title {
    color: $color-gray600;
  }
  .environment-settings-content.teams {
    margin-top: 12px;
  }
  .mail-delivery {
    .mail-delivery-content-wap {
      .mail-delivery-content {
        .mail-delivery-item {
          .mail-delivery-selectbox {
            padding-top: 4px;
            > * {
              min-width: 110px;
            }
          }
        }
      }
    }
  }
  .o-submit-button {
    width: 62px;
  }
}
</style>
