import { onMounted, ref } from 'vue';

/**
 * ウィンドウのx軸スクロールバーのwidthを算出
 */
export function useXScrollbarWidth() {
  const xScrollBarWidth = ref<number>(0);

  const computeXScrollbarWidth = () => {
    return window.innerWidth - document.documentElement.clientWidth;
  };

  onMounted(async () => {
    xScrollBarWidth.value = computeXScrollbarWidth();
  });

  return xScrollBarWidth;
}
