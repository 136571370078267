<!-- 
targetElementで指定される要素内でスクロールして、残りの高さがtriggerHeight以下になったときに、swrvを使ってページネーションを行うコンポーネント。
基本的にレスポンスが1件以上ある場合にページを進めるようになっており、0件の場合はAPIへの問い合わせをストップさせる。
ただし、hasNextAccessorが指定された場合、レスポンスが0件でも渡されたhasNextAccessorがtrueのときにページを進める 
-->
<script setup lang="ts" generic="ResponseType, ItemType">
import {
  onBeforeUnmount,
  onMounted,
  onUpdated,
  reactive,
  ref,
  Ref,
  watch,
} from 'vue';
import { useRoute } from 'vue-router';
import { Pagination } from '@/utils';
import { STATES, useSWRVWithState } from '@/utils/swr';
import { useEmitter } from '@/utils/vue';

const props = withDefaults(
  defineProps<{
    pageLimit: number;
    paginationFunc: (
      pageRef: Ref<number>,
      pageLimit: number,
    ) => ReturnType<typeof useSWRVWithState<ResponseType>>;
    dataAccessor: (
      response: ResponseType | undefined,
    ) => ItemType[] | undefined;
    hasNextAccessor?: (response: ResponseType | undefined) => boolean;
    scrollTarget?: 'window' | 'self';
  }>(),
  {
    scrollTarget: 'window',
  },
);

type PageHistory = {
  page: number;
  totalItemCountStump: number;
  itemCountStump: number;
};

const route = useRoute();
const emitter = useEmitter();

// MEMO: propsのdestructuringはtoRefsを使うべきだが、
// コンポーネントの作りとして、paginationFuncやdataAccessorの
// 変更には対応していないので、そのままdestructuringしている。
const { pageLimit, paginationFunc, dataAccessor, hasNextAccessor } = props;

const loaded = ref(false);
const container: { items: ItemType[] } = reactive({ items: [] });

const page = ref(1);
const { data, state, error } = paginationFunc(page, pageLimit);

let existItemPageHistory: PageHistory[] = [];

const responseItems = () => dataAccessor(data?.value) ?? [];
const responseHasNext = () =>
  hasNextAccessor ? hasNextAccessor(data?.value) : false;
const useNextAccessor = hasNextAccessor !== undefined;

let pagination: Pagination | undefined = undefined;

const emit = defineEmits<{
  error: [status?: number];
  fetch: [data: ResponseType];
}>();

watch(
  () => error.value,
  () => {
    if (data.value === undefined && error.value) {
      emit('error', error.value.response?.status);
    }
  },
  { immediate: true },
);

const addPage = () => {
  if (
    loaded.value &&
    state.value === STATES.SUCCESS &&
    (useNextAccessor ? responseHasNext() : responseItems().length > 0)
  ) {
    page.value++;
  }
};

if (props.scrollTarget === 'window') {
  pagination = new Pagination(addPage, 0);
}

// 「問い合わせ中」「問い合わせ完了時」の2パターンでitemsが返却される。
// itemsの内訳は以下の通り、
// ①「問い合わせ中」の場合にitemsが存在すれば、itemsの内訳は、swrvが前回の問い合わせ時にfetchしたキャッシュデータ
// ②「問い合わせ完了時」の場合、itemsの内訳は、APIからfetchした最新データ
// また、キャッシュデータと新規データを置換するハンドリングを行うため、itemsの置換位置特定を目的としてpageHistoryを利用する
watch(
  data,
  () => {
    const items = responseItems();
    // hasNextAccessorが指定されている場合、レスポンスが0件でもその結果に従ってページを進める
    if (items.length === 0 && responseHasNext()) {
      page.value++;
      return;
    }

    if (items.length > 0) {
      let startIndexForItems: number;
      let deleteCount = 0;
      const alreadyPage = existItemPageHistory.find(
        history => history.page === page.value,
      );

      // swrvのキャッシュがアクティブかどうかで条件分岐している
      if (alreadyPage) {
        startIndexForItems = alreadyPage.totalItemCountStump;
        deleteCount = alreadyPage.itemCountStump;
      } else {
        startIndexForItems = container.items.length;
        existItemPageHistory.push({
          page: page.value,
          totalItemCountStump: container.items.length,
          itemCountStump: items.length,
        });
      }

      container.items.splice(startIndexForItems, deleteCount, ...items);
      if (data.value !== undefined) {
        emit('fetch', data.value);
      }
    }
    loaded.value = data.value !== undefined;
  },
  { immediate: true },
);

onUpdated(() => {
  if (useNextAccessor && pagination?.checkOverContentHeightByScrollElement()) {
    addPage();
  }
});

const reset = () => {
  page.value = 1;
  loaded.value = false;
  container.items.splice(0, container.items.length);
  existItemPageHistory = [];
};

watch(
  () => route.path,
  () => reset(),
);

const handleCreate = (item: ItemType) => {
  container.items.unshift(item);
};
const handleUpdate = (funcs: {
  filterFunc: (i: ItemType) => boolean;
  updateFunc: (items: ItemType[]) => void;
}) => {
  const items = container.items.filter(funcs.filterFunc);
  if (items.length > 0) {
    funcs.updateFunc(items);
  }
};

const handleDelete = (deleteFunc: (items: ItemType[]) => void) => {
  deleteFunc(container.items);
};

const scrollerRef = ref<HTMLElement>();
onMounted(() => {
  if (props.scrollTarget === 'self') {
    pagination = new Pagination(addPage, 0, {
      element: scrollerRef.value,
    });
  }
  emitter.on('pagination-item-create', handleCreate);
  emitter.on('pagination-items-update', handleUpdate);
  emitter.on('pagination-item-delete', handleDelete);
});

onBeforeUnmount(() => {
  pagination?.removeEvent();
  reset();
  emitter.off('pagination-item-create', handleCreate);
  emitter.off('pagination-items-update', handleUpdate);
  emitter.off('pagination-item-delete', handleDelete);
});
</script>
<template>
  <div class="items" ref="scrollerRef">
    <slot :items="container.items" :loaded="loaded"></slot>
  </div>
</template>
