<script lang="ts">
import { computed, defineComponent } from 'vue';

export default defineComponent({
  props: {
    count: { type: Number },
    isReactedByMe: { type: Boolean },
    reaction: { type: String },
    readOnly: { type: Boolean, default: false },
  },
  components: {},
  setup(props) {
    const validImage = computed(() => {
      return props.reaction !== undefined;
    });

    return {
      validImage,
    };
  },
});
</script>

<template>
  <div
    class="m-comment-reaction"
    v-if="validImage"
    :class="{
      'm-reacted': isReactedByMe,
      'read-only': readOnly,
    }"
  >
    <div class="reaction-emoji">
      {{ reaction }}
    </div>
    <div
      class="m-reaction-count"
      :class="
        isReactedByMe ? ['c-title', 'c-title--s'] : ['c-text', 'c-text--s']
      "
    >
      {{ count }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.m-comment-reaction {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #ffffff;
  border-radius: 4px;
  padding: 1px 6px 1px 4px;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  cursor: pointer;
  &:not(.read-only):hover {
    border: 1px solid #b3b3b3;
  }

  .m-reaction-count {
    line-height: 18px;
  }
  .reaction-emoji {
    margin-right: 2px;
    font-size: 14px;
    font-family: $emoji-font-family;
  }
}
.m-reacted {
  border: 1px solid #44b8a3;
  background: #eaf6f2;
  color: #44b8a3;
  &:hover {
    border: 1px solid #44b8a3;
  }
}
</style>
