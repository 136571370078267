<script lang="ts">
import { defineComponent } from 'vue';
import { DgrIcon } from '@stockmarkteam/donguri-ui';

export default defineComponent({
  components: {
    DgrIcon,
  },
  props: {
    name: { type: String, default: '' },
    isEditing: { type: Boolean, default: false },
    showTooltip: { type: Boolean, default: true },
    isPreventOverflow: { type: Boolean, default: true },
    isSmall: { type: Boolean, default: false },
    inactive: { type: Boolean, default: false },
  },
  setup(props, context) {
    const deleteTag = () => {
      context.emit('deleteTag', props.name); // eslint-disable-line vue/require-explicit-emits
    };

    return {
      deleteTag,
    };
  },
});
</script>

<template>
  <div
    class="a-tag c-outlineTag"
    :class="{
      'c-outlineTag--delete': isEditing,
      'limit-width': isPreventOverflow,
      'small-tag': isSmall,
      inactive: inactive,
    }"
    v-tooltip.bottom="
      showTooltip
        ? { content: name, delay: { show: 300 }, autoHide: false }
        : undefined
    "
  >
    <div class="a-tag-text c-text c-text--m">
      {{ name }}
    </div>
    <DgrIcon
      name="times"
      class="shrink-guard"
      size="xs"
      v-if="isEditing"
      @click="deleteTag"
    />
  </div>
</template>

<style scoped lang="scss">
.a-tag {
  .a-tag-text {
    color: #4a4a4a;
    word-wrap: nowrap;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
  }
}
.limit-width {
  max-width: 100%;
}
.small-tag {
  padding: 2px 8px;
  .a-tag-text {
    color: $color-gray800;
    font-size: 10px;
    line-height: 14px;
  }
}
.inactive {
  border-color: $color-gray400;
  .a-tag-text {
    color: $color-gray400;
  }
}
.shrink-guard {
  flex-shrink: 0;
}
</style>
