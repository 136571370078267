import { Module } from 'vuex';
import api from '@/api';
import { AdpDocument, Comment, UserInfo } from '@/types';
import { deduplicateArticles } from '@/utils';
import { sortMarkArticles } from '@/utils/sorters';
import { userSession } from '@/utils/userSession';

export type UserActionsState = {
  userMarkArticles: AdpDocument[];
  fetchingUserMarkArticles: boolean;
  userComments: Comment[];
  targetUserInfo: UserInfo | Record<string, never>;
  isLastPage: boolean;
};

export const initialUserActionsState = {
  userMarkArticles: [],
  fetchingUserMarkArticles: false,
  userComments: [],
  targetUserInfo: {},
  isLastPage: false,
};

const userActions: Module<UserActionsState, unknown> = {
  namespaced: true,
  state: () => initialUserActionsState,
  actions: {
    async fetchUserMarkArticles({ commit, state }, payload) {
      if (state.isLastPage) return;
      state.fetchingUserMarkArticles = true;
      const userMarkArticles = await api.fetchUserMarkArticles(
        payload.userId,
        payload.limit,
        payload.page,
        payload.memoExists,
        payload.tags,
        payload.lang,
        payload.toDate,
        payload.fromDate,
        payload.query,
        payload.docTypes,
      );
      state.fetchingUserMarkArticles = false;
      if (userMarkArticles.length === 0) commit('setIsLastPage', true);
      if (payload.reset) commit('resetUserMarkArticles');
      userMarkArticles.forEach(article => {
        article.theme_id = article.marks?.[0]?.theme_id ?? 0;
      });
      commit('setUserMarkArticles', userMarkArticles);
    },
    async fetchUserComments({ commit, state }, payload) {
      if (state.isLastPage) return;
      const userComments = await api.fetchUserComments(
        payload.userId,
        payload.limit,
        payload.page,
      );
      if (userComments.length === 0) commit('setIsLastPage', true);
      if (payload.reset) commit('resetUserComments');
      commit('setUserComments', userComments);
    },
    async fetchTargetUserInfo({ commit }, userId) {
      const userInfo = await api.fetchUserInfo(userId);
      commit('setTargetUserInfo', userInfo);
    },
    async updateUserMarkArticleLocally({ state }, { article }) {
      const index = state.userMarkArticles.map(a => a.id).indexOf(article.id);
      if (index === -1) return;
      const articles = state.userMarkArticles.slice();
      articles[index] = article;
      state.userMarkArticles = articles;
    },
    async updateUserMarkArticle(
      { state },
      { userId, themeId, articleId, docType },
    ) {
      const article = await api.fetchDocument(articleId, docType);
      article.theme_id = themeId;
      const index = state.userMarkArticles.map(a => a.id).indexOf(articleId);
      const articles = state.userMarkArticles.slice();
      if (index !== -1) {
        articles[index] = article;
      } else {
        articles.unshift(article);
      }
      const filteredArticles = articles.filter(a =>
        a.marks.some(m => m.user_id === userId),
      );
      const myUserId = userSession.getUserId();
      if (myUserId === state.targetUserInfo?.id) {
        state.userMarkArticles = sortMarkArticles(filteredArticles);
      } else {
        state.userMarkArticles = articles;
      }
    },
    async updateUserComment({ state }, { comment }) {
      const index = state.userComments.findIndex(c => {
        return c.id === comment.id;
      });
      if (index >= 0) {
        state.userComments[index].content = comment.content;
      } else {
        state.userComments.forEach(c => {
          const idx = c.children?.findIndex(child => child.id === comment.id);
          if (idx >= 0) {
            c.children[idx].content = comment.content;
          }
        });
      }
    },
    async deleteUserComment({ state }, { id }) {
      const parentIndex = state.userComments.findIndex(c => c.id === id);
      if (parentIndex >= 0) {
        state.userComments.splice(parentIndex, 1);
      } else {
        state.userComments.forEach(comment => {
          const idx = comment.children?.findIndex(child => {
            return child.id === id;
          });
          if (idx >= 0) {
            comment.children.splice(idx, 1);
          }
        });
      }
    },
    addUserComment({ state }, { comment }) {
      state.userComments.unshift(comment);
    },
  },
  mutations: {
    setIsLastPage(state, flag) {
      state.isLastPage = flag;
    },
    setUserMarkArticles(state, userMarkArticles) {
      state.userMarkArticles = deduplicateArticles(
        state.userMarkArticles.concat(userMarkArticles),
      );
    },
    addUserMarkArticles(state, userMarkArticles) {
      state.userMarkArticles.unshift(userMarkArticles);
    },
    resetUserMarkArticles(state) {
      state.userMarkArticles = [];
      state.isLastPage = false;
    },
    setUserComments(state, userComments) {
      state.userComments = [...state.userComments, ...userComments];
    },
    resetUserComments(state) {
      state.userComments = [];
      state.isLastPage = false;
    },
    setTargetUserInfo(state, userInfo) {
      state.targetUserInfo = userInfo;
    },
  },
};

export default userActions;
