<script lang="ts">
import { defineComponent } from 'vue';
import { DgrIcon } from '@stockmarkteam/donguri-ui';

// TODO: donguri-uiにまだないcomponentなのでいったんここに実装して、donguriにできたら削除
export default defineComponent({
  components: {
    DgrIcon,
  },
  props: {
    showHeader: { type: Boolean, default: true },
  },

  setup() {
    return {};
  },
});
</script>

<template>
  <div class="modal-mask">
    <!-- eslint-disable vue/require-explicit-emits -->
    <div class="modal-wrapper" @mousedown.self="$emit('close', false)">
      <div class="modal-container" @click.stop="() => undefined">
        <div class="modal-header" v-if="showHeader">
          <slot name="header"></slot>
          <DgrIcon
            size="small"
            :keep-fill="false"
            name="times"
            class="clickable close-icon"
            @click="$emit('close', true)"
          />
        </div>
        <div class="modal-content">
          <slot name="body"></slot>
        </div>
      </div>
    </div>
  </div>
  <!-- eslint-enable vue/require-explicit-emits -->
</template>
<style scoped lang="scss">
.modal-mask {
  position: fixed;
  z-index: var(--z-modal);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.modal-container {
  width: 648px;
  margin: auto;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  font-family: Helvetica, Arial, sans-serif;
  color: #4a4a4a;
  height: auto;
  min-height: auto;
  position: relative;
  max-height: calc(100vh - 100px);
}

.modal-header {
  position: static;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 8px;
  height: 48px;
  padding: 0px 16px;
}

.modal-content {
  padding: 0 16px;
  max-height: calc(100vh - 150px);
  overflow-y: scroll;
}

.close-icon {
  cursor: pointer;
  fill: $color-gray600;

  &:hover {
    opacity: 0.8;
  }
}
</style>
