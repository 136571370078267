<script lang="ts" setup>
import { computed, ref } from 'vue';
import { DgrCheckbox, DgrRadio } from '@stockmarkteam/donguri-ui';
import LoadableButton from '@/components/common/atoms/loadable-button.vue';
import { ExportFormatType, ExportGroupContentType } from '@/types';

interface Props {
  isLoading?: boolean;
  errorText?: string;
}

const props = withDefaults(defineProps<Props>(), {
  isLoading: () => false,
  errorText: () => '',
});

type Emits = {
  onClose: [];
  onSubmit: [
    {
      selectedTypes: ExportGroupContentType[];
      exportType: ExportFormatType;
    },
  ];
};
const emit = defineEmits<Emits>();
const types = ref([
  {
    type: 'comment',
    selected: true,
    label: 'コメント',
  },
  {
    type: 'group_mark',
    selected: true,
    label: 'グループマーク',
  },
] as const);
const selectedTypes = computed(() =>
  types.value.filter(t => t.selected).map(t => t.type),
);
const downloadFormat = ref<ExportFormatType>('excel');
const downloadFormatItems: {
  value: ExportFormatType;
  label: string;
}[] = [
  {
    value: 'excel',
    label: 'Excel',
  },
  { value: 'csv', label: 'CSV' },
];

const isButtonDisabled = computed(
  () =>
    props.errorText !== '' ||
    props.isLoading ||
    selectedTypes.value.length === 0,
);

const close = () => {
  emit('onClose');
};

const submit = () => {
  if (isButtonDisabled.value) return;

  emit('onSubmit', {
    selectedTypes: selectedTypes.value,
    exportType: downloadFormat.value,
  });
};
</script>

<template>
  <div class="export-activity">
    <div class="ex-ac-main-body">
      <div class="c-text c-text--m ex-ac-header">
        <p>指定した期間の全件をダウンロードすることができます。</p>
      </div>
      <div v-if="errorText !== ''">
        <p class="c-text--m ex-ac-error">
          {{ errorText }}
        </p>
      </div>
      <div class="ex-ac-dw-section">
        <p class="c-title c-title--m ex-ac-section-title">アクション</p>
        <div class="ex-ac-action-types">
          <DgrCheckbox
            v-for="type in types"
            :key="type.type"
            class="checkbox c-text c-text--m"
            v-model="type.selected"
            data-testid="action-checkbox"
          >
            {{ type.label }}
          </DgrCheckbox>
        </div>
      </div>
      <div class="ex-ac-dw-section">
        <p class="c-title c-title--m ex-ac-section-title">ダウンロード形式</p>
        <DgrRadio
          :items="downloadFormatItems"
          v-model="downloadFormat"
          class="c-text c-text--m"
        />
      </div>
    </div>
    <div class="separate"></div>
    <div class="ex-ac-btn-area">
      <button
        class="o-cancel-button c-btn--small c-btn c-btn--auto c-btnOutline"
        @click="close"
        data-testid="dw-cancel-button"
      >
        キャンセル
      </button>
      <LoadableButton
        :class="{ disabled: isButtonDisabled }"
        :disabled="isButtonDisabled"
        :is-loading="isLoading"
        @click="submit"
        class="dashboard-button--primary"
        data-testid="dw-submit-button"
      >
        ダウンロード
      </LoadableButton>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.export-activity {
  background-color: white;
  border-radius: 4px;
  border-top: 1px solid $color-gray400;
  box-shadow: 0 1px 5px rgba(74, 74, 74, 0.25);
  .ex-ac-main-body {
    padding: 16px;
    padding-bottom: 0;
    .ex-ac-header {
      margin-bottom: 16px;
    }
    .ex-ac-error {
      color: $color-orange1000;
      margin-bottom: 16px;
    }
    .ex-ac-dw-section {
      margin-bottom: 16px;

      .ex-ac-section-title {
        margin-bottom: 2px;
      }
    }
    .ex-ac-action-types {
      .checkbox {
        margin-bottom: 8px;
      }
    }
    .mark-filter {
      margin: 16px 0 4px 20px;

      & > :first-child {
        margin-bottom: 12px;
      }
    }
    .ex-ac-format {
      display: flex;
      gap: 16px;
    }
  }
  .separate {
    border-top: 1px solid $color-gray400;
    margin-top: 16px;
  }

  .ex-ac-btn-area {
    display: flex;
    justify-content: flex-end;
    padding: 10px 16px;
    gap: 16px;
  }
}
</style>
