import { UserInfo } from '@/types';

export const setupTechTouch = (userInfo: UserInfo): void => {
  const techTouchId = import.meta.env.VITE_TECH_TOUCH_ID;
  if (techTouchId) {
    const techTouchCode = document.createElement('script');
    techTouchCode.setAttribute('type', 'text/javascript');
    techTouchCode.textContent = `
      !function () {
        window.TechtouchObject = {
          startTechtouch: function() {
            // iframe内で動作させる場合は以下の1行を削除もしくはコメントアウトしてください
            try { if (window.top !== window.self) return; } catch(e) { return; }
            if ('TechtouchObject' in window && document.querySelector('script#techtouch-snippet')) return;
            if ('TechtouchAddon' in window) return;
            window.TechtouchObject = {
              organizationUuid: "${techTouchId}"
            };
            var e = document.createElement("script"); e.async = 1, e.src = "https://apps.techtouch.jp/script/${techTouchId}/main.js?projectUuid=proj-666151c3-c2d0-4ad7-ad41-4bb2d71a155a"; e.id = "techtouch-snippet";
            var t = document.getElementsByTagName("script")[0];
            t.parentNode.insertBefore(e, t);
          }
        };

        window.TechtouchAdmin={customProperty:{_data:{},get:function(t){return this._data[t]},set:function(t,e){this._data[t]=e},delete:function(t){delete this._data[t]},keys:function(){return Object.keys(this._data)}}};
        // ラベル「ユーザーID」に対応
        TechtouchAdmin.customProperty.set("f1b034bd-59f9-49d2-b920-e70c6206b4ec", "${userInfo.id}")
        // ラベル「チームID」に対応
        TechtouchAdmin.customProperty.set("da39bf21-7ddd-41eb-8fb0-4b82600ce40a", "${userInfo.team_id}")
        // ラベル「契約ID」に対応
        TechtouchAdmin.customProperty.set("0c892e30-b832-4a40-afb6-58bc3bcb50dd", "${userInfo.product_contract_id}")
        // ラベル「ユーザーロール」に対応
        TechtouchAdmin.customProperty.set("94c3e514-5aec-4fe4-9fcf-b95373f38325", "${userInfo.role}")

        window.TechtouchObject.startTechtouch();
      }()
    `;
    document.head.appendChild(techTouchCode);
  }
};
