<script setup lang="ts"></script>

<template>
  <div class="wrapper">
    <div class="message c-text c-text--s">
      このメンバーはマークの公開範囲を「自分のみ」に設定しています。
    </div>
  </div>
</template>

<style lang="scss" scoped>
.wrapper {
  height: 100%;
  display: grid;
  place-items: center;
}

.message {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 16px 12px;
  border: 1px solid $color-gray400;
  border-radius: 4px;
}
</style>
