<script setup lang="ts">
import { computed } from 'vue';
import { DgrCheckbox, DgrIcon } from '@stockmarkteam/donguri-ui';

interface Props {
  title: string;
  items: { label: string; value: string }[];
  checkedValues: string[];
  isOpen: boolean;
  disabled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  disabled: () => false,
});

interface Emits {
  (e: 'toggleIsOpen'): void;
  (e: 'checkedAllItem'): void;
  (e: 'unCheckedAllItem'): void;
  (e: 'checkedItem', value: string): void;
  (e: 'unCheckedItem', value: string): void;
}

const emit = defineEmits<Emits>();

const isCheckedItem = (item: string) => props.checkedValues.includes(item);

const toggleCheckedItem = (item: string) => {
  if (isCheckedItem(item)) {
    emit('unCheckedItem', item);
  } else {
    emit('checkedItem', item);
  }
};
const disabledRemoveItemBtn = computed(() => {
  return props.disabled || props.checkedValues.length === 0;
});
</script>

<template>
  <div class="search-menu-checkbox">
    <div
      class="title-icon c-title c-title--s"
      @click="() => emit('toggleIsOpen')"
    >
      <span>{{ title }}</span>
      <DgrIcon
        size="small"
        :name="isOpen ? 'chevron-up' : 'chevron-down'"
        class="toggle-icon small"
      />
    </div>
    <div v-if="isOpen" class="items">
      <DgrCheckbox
        v-for="item in items"
        :key="item.value"
        class="item c-text c-text--m"
        :model-value="isCheckedItem(item.value)"
        :disabled="disabled"
        @update:model-value="toggleCheckedItem(item.value)"
        >{{ item.label }}</DgrCheckbox
      >
      <div class="remove-item-btn-area">
        <button
          class="remove-item-btn c-text c-text--m"
          :class="{ disabled: disabledRemoveItemBtn }"
          @click="emit('unCheckedAllItem')"
          :disabled="disabledRemoveItemBtn"
        >
          選択解除
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.search-menu-checkbox {
  padding: 0px 16px;
  margin: 17px 0px;

  .title-icon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2px;
    box-sizing: border-box;
    height: 24px;
    cursor: pointer;
  }

  .items {
    margin-top: 12px;
    margin-bottom: 20px;
    .item {
      display: block;
      margin: 16px 0px;
    }
  }

  .remove-item-btn-area {
    display: flex;
    align-items: center;
    justify-content: end;

    .remove-item-btn {
      height: 32px;
      padding: 4px;
      background-color: transparent;
      color: #1da482;
      border: none;
      cursor: pointer;
      align-items: center;
      border-radius: 4px;

      &:disabled {
        color: $color-gray600;
        cursor: auto;
      }
    }
  }
}
</style>
