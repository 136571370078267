import { DAYS_OF_PAST_FEED } from '@/constants';
import { Dayjs } from 'dayjs';
import isMobile from 'ismobilejs';
import Qs from 'qs';
import {
  AdpDocument,
  EmojiReaction,
  NotificationDocument,
  UserDocument,
} from '@/types';
import { CommentReaction } from '../types';

export const deduplicateArticles = (articles: AdpDocument[]) => {
  const articleIds = articles.map(article => article.id);
  return articles.filter(
    (article, index) => articleIds.indexOf(article.id) === index,
  );
};

export const deduplicateUserDocuments = (userDocuments: UserDocument[]) => {
  const userDocumentIds = userDocuments.map(userDocument => userDocument.id);
  return userDocuments.filter(
    (userDocument, index) => userDocumentIds.indexOf(userDocument.id) === index,
  );
};
type PaginationAction = (
  page: number,
  limit: number,
  date?: Dayjs,
) => void | Promise<void>;

type PaginationOption = {
  triggerHeight?: number;
  element?: HTMLElement;
  date?: Dayjs;
  maxDaysLimit?: number;
};

export class Pagination {
  page: number;
  limit: number;
  date: Dayjs | undefined;
  action: PaginationAction;
  executing: boolean;
  lastDate: Dayjs | undefined;
  func: (initial?: boolean) => void;
  listener: () => void;
  scrollContainer: HTMLElement | Window;
  triggerHeight = 600;

  constructor(
    action: PaginationAction,
    limit: number,
    option?: PaginationOption,
  ) {
    this.triggerHeight = option?.triggerHeight ?? 600;
    const date = option?.date;
    const maxDaysLimit = option?.maxDaysLimit ?? DAYS_OF_PAST_FEED;
    const scrollContainer = option?.element ?? window;

    this.page = 1;
    this.date = date;
    this.limit = limit;
    this.action = action;
    this.executing = false;
    this.lastDate = this.date
      ? this.date.subtract(maxDaysLimit, 'day')
      : undefined;
    this.scrollContainer = scrollContainer;

    this.func = async (initial = false) => {
      if (
        initial ||
        (this.checkOverContentHeightByScrollElement() && !this.executing)
      ) {
        this.executing = true;
        await this.fetch();
        this.executing = false;
      }
    };
    this.listener = () => this.func();

    const setup = async () => {
      scrollContainer.addEventListener('scroll', this.listener);
      await this.func(true);
    };
    setup();
  }

  async fetch(): Promise<void> {
    await this.action(this.page, this.limit, this.date);
    this.page++;
  }

  checkOverContentHeightByScrollElement(): boolean {
    const contentsHeight =
      this.scrollContainer instanceof HTMLElement
        ? this.scrollContainer.scrollHeight
        : document.body.scrollHeight;
    const scrollY =
      this.scrollContainer instanceof HTMLElement
        ? this.scrollContainer.scrollTop
        : this.scrollContainer.scrollY;
    const innerHeight =
      this.scrollContainer instanceof HTMLElement
        ? this.scrollContainer.clientHeight
        : this.scrollContainer.innerHeight;
    const currentYPosition = scrollY + innerHeight;
    return contentsHeight <= currentYPosition + this.triggerHeight;
  }

  removeEvent(): void {
    this.scrollContainer.removeEventListener('scroll', this.listener);
  }
}

export const isMobileUser = () => {
  return isMobile().phone;
};

export const isTabletUser = () => {
  return isMobile().tablet;
};

export const processCommentEmojiReactions = (
  reactions: EmojiReaction[],
): CommentReaction[] => {
  if (reactions == null) return [];
  return reactions.reduce<
    {
      reaction: string;
      count: number;
      users: { id: number; name: string }[];
    }[]
  >((result, current) => {
    const targetReaction = result.find(r => r.reaction === current.reaction);
    if (targetReaction) {
      targetReaction.count++;
      targetReaction.users.push({
        id: current.user_id,
        name: current.user_name,
      });
    } else {
      result.push({
        reaction: current.reaction,
        count: 1,
        users: [{ id: current.user_id, name: current.user_name }],
      });
    }
    return result;
  }, []);
};

function isAdpDocument(
  article: AdpDocument | NotificationDocument,
): article is AdpDocument {
  return (article as AdpDocument).url !== undefined;
}

export const getArticlePageUrl = (
  article: AdpDocument | NotificationDocument | undefined,
  commentId?: number,
) => {
  if (!article) return '';

  const params = {
    themeId: isAdpDocument(article) ? article.theme_id : undefined,
    commentId: commentId,
  };
  const paramString = Qs.stringify(params, {
    arrayFormat: 'brackets',
  });
  return `/${article.doc_type ?? 'article'}s/${article.id}${
    paramString ? `?${paramString}` : ''
  }`;
};

export const isEmptyObject = (
  x: Record<string, unknown>,
): x is Record<string, never> => Object.keys(x).length === 0;
