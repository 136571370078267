<script lang="ts">
import { computed, defineComponent, PropType, ref, watch } from 'vue';
import { DgrIcon, DgrPopover } from '@stockmarkteam/donguri-ui';
import Clipboard from 'clipboard';
import { AdpDocument } from '@/types';
import { getDocTypeDisplayName } from '@/utils/adpDocument';

export default defineComponent({
  components: {
    DgrPopover,
    DgrIcon,
  },
  props: {
    adpDocument: { type: Object as PropType<AdpDocument>, required: true },
    marginTop: { type: String, default: '0px' },
    marginLeft: { type: String, default: '-150px' },
    isActionMenuShown: { type: Boolean },
    showUndisplayMenu: { type: Boolean, default: true },
    isUndisplayButtonShown: { type: Boolean, required: true },
    isSiteExclusionButtonShown: { type: Boolean, required: true },
    isNotInterested: { type: Boolean, default: false },
    articleExclusionId: {
      type: Number as PropType<number | null>,
      default: null,
    },
    isRemoveGroupMarkButtonShown: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  emits: {
    'click-copy': (_success: boolean) => true,
    'click-undisplay': () => true,
    'click-site-exclusion': () => true,
    'click-not-interested': () => true,
    'click-remove-not-interested': () => true,
    'click-remove-group-mark': () => true,
  },
  setup(props, context) {
    const clipboardButtonRef = ref<HTMLButtonElement>();
    let clipboard: Clipboard | null = null;

    watch(clipboardButtonRef, () => {
      if (!clipboardButtonRef.value) return;
      if (clipboard) {
        clipboard.destroy();
      }
      clipboard = new Clipboard(clipboardButtonRef.value);
      clipboard.on('success', () => {
        context.emit('click-copy', true);
      });
      clipboard.on('error', () => {
        context.emit('click-copy', false);
      });
    });

    const onClickUndisplay = () => {
      context.emit('click-undisplay');
    };

    const onClickSiteExclusion = () => {
      context.emit('click-site-exclusion');
    };

    const onClickNotInterested = () => {
      context.emit('click-not-interested');
    };

    const onClickRemoveNotInterested = () => {
      context.emit('click-remove-not-interested');
    };

    const onClickRemoveGroupMark = () => {
      context.emit('click-remove-group-mark');
    };

    const docTypeDisplayName = computed(() =>
      getDocTypeDisplayName(props.adpDocument),
    );

    const copyLinkUrl = computed(() =>
      props.adpDocument.doc_type === 'research_paper' &&
      props.adpDocument.pdf_url
        ? props.adpDocument.pdf_url
        : props.adpDocument.url,
    );
    const dropdownZIndex = Number(
      getComputedStyle(document.body).getPropertyValue('--z-dropdown'),
    );

    return {
      clipboardButtonRef,
      onClickUndisplay,
      onClickNotInterested,
      onClickRemoveNotInterested,
      onClickRemoveGroupMark,
      onClickSiteExclusion,
      docTypeDisplayName,
      copyLinkUrl,
      dropdownZIndex,
    };
  },
});
</script>

<template>
  <div class="m-action-menu">
    <DgrPopover :z-index="dropdownZIndex">
      <template #default="{ triggerProps }">
        <button
          class="m-menu-button"
          v-bind="triggerProps"
          :disabled="disabled"
          @click.stop
        >
          <DgrIcon
            size="small"
            name="ellipsis-h"
            :class="{ disabled: disabled }"
          />
        </button>
      </template>
      <template #content="{ close }">
        <div class="m-menu-list">
          <ul>
            <li>
              <button
                class="m-item-button"
                :data-clipboard-text="copyLinkUrl"
                ref="clipboardButtonRef"
                data-testid="list-item-copy"
              >
                <DgrIcon size="small" name="chain" class="m-icon" /><span
                  class="c-text c-text--m"
                  >リンクをコピー</span
                >
              </button>
            </li>
            <li v-if="isUndisplayButtonShown && showUndisplayMenu">
              <button
                class="m-item-button m-item"
                @click="
                  onClickUndisplay();
                  close();
                "
                data-testid="list-item-undisplay"
              >
                <DgrIcon
                  size="small"
                  name="eye-alt"
                  class="m-item-icon m-icon"
                />
                <div class="m-item-text">
                  <span class="c-text c-text--m">記事を非表示</span>
                  <div
                    class="m-item-explanation c-text c-text--xs"
                    v-if="adpDocument.lang !== 'zh'"
                  >
                    AIが学習し、このような記事の<br />配信が少なくなります
                  </div>
                </div>
              </button>
            </li>
            <li v-if="isSiteExclusionButtonShown">
              <button
                class="m-item-button m-item"
                @click="
                  onClickSiteExclusion();
                  close();
                "
                data-testid="list-item-site-exclusion"
              >
                <DgrIcon size="small" name="ban" class="m-item-icon m-icon" />
                <div class="m-item-text">
                  <span class="c-text c-text--m">このメディアを除外</span>
                  <div class="m-item-explanation c-text c-text--xs">
                    このメディアが配信されないように<br />設定タブからも編集できます
                  </div>
                </div>
              </button>
            </li>
            <li v-if="isNotInterested && articleExclusionId === null">
              <button
                class="m-item-button"
                @click="
                  onClickNotInterested();
                  close();
                "
                data-testid="list-item-not-interested"
              >
                <DgrIcon size="small" name="thumbs-down" class="m-icon" /><span
                  class="c-text c-text--m"
                  >この{{ docTypeDisplayName }}に興味がない</span
                >
              </button>
            </li>
            <li v-if="isNotInterested && articleExclusionId !== null">
              <button
                class="m-item-button"
                @click="
                  onClickRemoveNotInterested();
                  close();
                "
                data-testid="list-item-remove-not-interested"
              >
                <DgrIcon size="small" name="thumbs-down" class="m-icon" /><span
                  class="c-text c-text--m"
                  >「この{{ docTypeDisplayName }}に興味がない」を取り消し</span
                >
              </button>
            </li>
            <li v-if="isRemoveGroupMarkButtonShown">
              <button
                class="m-item-button"
                @click="
                  onClickRemoveGroupMark();
                  close();
                "
                data-testid="list-item-remove-group-mark"
              >
                <DgrIcon size="small" name="user" class="m-icon" /><span
                  class="c-text c-text--m"
                  >このグループからグループマークを外す</span
                >
              </button>
            </li>
          </ul>
        </div>
      </template>
    </DgrPopover>
  </div>
</template>

<style lang="scss" scoped>
.m-action-menu {
  position: relative;
  cursor: auto;
  .m-menu-button {
    height: auto;
    padding: 8px;
    border: none;
    border-radius: 4px;
    background-color: white;

    &:not([disabled]):hover {
      background-color: $color-gray200;
    }

    &[disabled] {
      cursor: auto;
    }
  }

  .disabled {
    cursor: auto;
    fill: #b3b3b3;
  }
}
.m-menu-list {
  display: flex;
  flex-direction: column;
  width: max-content;
  padding: 12px 0;
  .m-item-explanation {
    color: $color-gray800;
    margin-left: 6px;
  }
}
ul {
  list-style: none;
}
span {
  margin-left: 6px;
  color: #4a4a4a;
}
.m-item-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  border: none;
  padding: 2px 8px 0;
  background-color: white;
  text-align: start;
  &:hover {
    background-color: $color-gray200;
  }
}
.m-icon {
  flex-shrink: 0;
}
.m-item-icon {
  margin-top: 5px;
}
.m-item {
  display: flex;
  align-items: flex-start;
}
</style>
