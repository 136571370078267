import { Module } from 'vuex';

export type MailAddressState = {
  mailAddress: string | undefined;
};

export const initialMailAddressState = {
  mailAddress: undefined,
};

const mailAddress: Module<MailAddressState, unknown> = {
  namespaced: true,
  state: {
    mailAddress: undefined,
  },
  mutations: {
    setMailAddress(state, mailAddress) {
      state.mailAddress = mailAddress;
    },
  },
};

export default mailAddress;
