<script setup lang="ts">
import { computed, onMounted, provide, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import api from '@/api';
import { MYPAGE_ROUTE_META } from '@/routers/constants';
import UserEditModal from '@/components/admin/members/user-edit-modal.vue';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import GroupCreate from '@/components/group/group-create.vue';
import FeedHeader from '@/components/layouts/feed-header.vue';
import LeftMenu from '@/components/menu/left-menu.vue';
import ArticleShare from '@/components/modals/article-share.vue';
import CommentCreate from '@/components/modals/comment-create-modal.vue';
import CommentCreateWithoutArticleModal from '@/components/modals/comment-create-without-article-modal.vue';
import CommentEdit from '@/components/modals/comment-edit-modal.vue';
import ConfirmationModal from '@/components/modals/confirmation-modal.vue';
import DialogGroupMark from '@/components/modals/dialog-group-mark.vue';
import MarkModal from '@/components/modals/mark-modal.vue';
import TagListModal from '@/components/modals/tag-list-modal.vue';
import ThemeTopicSelectModal from '@/components/modals/theme-topic-select-modal.vue';
import TranslateNotificationModal from '@/components/modals/translate-notification-modal.vue';
import NotificationPopupDialog from '@/components/notifications/header/popup-dialog.vue';
import ThemeSettingsInfoModal from '@/components/theme/edit/theme-settings-info-modal.vue';
import { Article } from '@/types';
import { useWebPush } from '@/utils/composables/useWebPush';
import { integrateWithIntercom } from '@/utils/intercom';
import {
  useGroups,
  useTeamInfo,
  useTeamUsers,
  useThemeList,
  useUserActionCount,
} from '@/utils/swr';
import { setupTechTouch } from '@/utils/techTouch';
import { useUserSession } from '@/utils/user';
import { userSession } from '@/utils/userSession';
import { useStore } from '@/utils/vue';
import { useEmitter } from '@/utils/vue';
import { setupWicle } from '@/utils/wicle';

const store = useStore();
const router = useRouter();
const route = useRoute();
const emitter = useEmitter();

const confirmationModalOpen = computed(
  () => store.state.confirmationModal.confirmationModalOpen,
);
const userEditModalOpen = computed(
  () => store.state.userEditModal.userEditModalOpen,
);
const modalType = computed(() => store.state.modal.modalType);
const modalOpen = computed(() => store.state.modal.modalOpen);
const feedType = computed(() => store.state.feedType.feedType);
const { userInfo } = useUserSession();
const { data: teamInfo } = useTeamInfo();
const { data: groups } = useGroups();
const { data: themes } = useThemeList(feedType);
const { data: teamUsers } = useTeamUsers();

const userId = computed(() => userInfo.value?.id);
const { data: userActionCount } = useUserActionCount(userId, 'mark');

onMounted(async () => {
  emitter.on('article-updated', (article: Article) => {
    updateArticle(article);
  });
  document.addEventListener('visibilitychange', () => {
    if (!userSession.isActive()) return;
    if (document.visibilityState === 'visible') {
      api.trackAccess();
    }
  });
});

const stopInitialDataWatch = watch(
  [teamInfo, groups, themes, teamUsers],
  ([t, gs, ts, tus]) => {
    if (!t || !gs || !ts || !tus) return;

    store.commit('teamInfo/setTeamInfo', t);
    store.commit('groups/setGroups', gs.groups);
    store.commit('themeList/setThemes', ts.themes);
    store.commit('teamUsers/setTeamUsers', tus.users);

    const gotFeedType = localStorage.getItem('UserSession.FeedType');
    if (gotFeedType === null) {
      const feedType = t.enable_foreign_lang_media ? 'all' : 'domestic';
      userSession.setFeedType(feedType);
      store.commit('feedType/setFeedType', feedType);
    }
    stopInitialDataWatch();
  },
);

const stopUserInfoWatch = watch(userInfo, async u => {
  if (!u) return;

  if (hasCommentQueryParams.value) {
    redirectToCommentPage();
  }
  showTranslateNotification();
  await api.trackAccess();
  stopUserInfoWatch();
});

const stopThirdPartyDataWatch = watch(
  [userInfo, teamInfo, groups, themes, teamUsers, userActionCount],
  ([u, t, gs, ts, tus, uac]) => {
    if (!u || !t || !gs || !ts || !tus || !uac) return;

    setupTechTouch(u);

    // 他のデータが取得したら最後にzendesk/wicle/intercomをロードする
    integrateWithZendesk();
    setupWicle(u);
    const favoriteThemes =
      ts.themes.filter(t => !t.is_deleted && t.is_favorite) ?? [];
    integrateWithIntercom(u, t, favoriteThemes, uac.count);
    stopThirdPartyDataWatch();
  },
);

const integrateWithZendesk = () => {
  if (import.meta.env.VITE_ENV !== 'development') {
    // zendesk
    const zendeskScript = document.createElement('script');
    zendeskScript.setAttribute('id', 'ze-snippet');
    zendeskScript.setAttribute('defer', '');
    zendeskScript.setAttribute(
      'src',
      'https://static.zdassets.com/ekr/snippet.js?key=847dd7fe-899c-4d7c-8996-f43e9d25499a',
    );
    document.head.appendChild(zendeskScript);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).zESettings = {
      webWidget: {
        color: { theme: '#98D0BC' },
        offset: {
          horizontal: '4px',
          vertical: '14px',
        },
        authenticate: {
          chat: {
            jwtFn: function (callback: (jwt: string) => void) {
              fetch('/api/v1/integrations/zendesk').then(function (res) {
                res.text().then(function (jwt) {
                  callback(jwt);
                });
              });
            },
          },
        },
      },
    };
  }
};

const hasCommentQueryParams = computed(() => {
  const query = route.query;
  return query && query.widget === 'comment' && query.articleId;
});

const redirectToCommentPage = async (): Promise<void> => {
  const article = await fetchArticle();
  if (article.comments?.length > 0) {
    router.replace(`/comments/${article.comments[0].id}`);
  } else {
    router.replace('/');
  }
};

const fetchArticle = async () => {
  const article = await api.fetchDocument(
    Number(route.query.articleId),
    'article',
  );
  return article;
};

const showTranslateNotification = (): void => {
  const targetRoutes = ['anewsHome', 'themeFeed'];
  const isShowedTranslateNotification =
    userSession.getIsShownTranslateNotification();
  if (
    feedType.value === 'foreign' &&
    // includesがstringしか受け取れないのでasで型を指定
    targetRoutes.includes(route.name as string) &&
    !isShowedTranslateNotification
  ) {
    store.commit('modal/showModal', 'translateNotification');
  }
};

watch(
  () => route.query,
  () => {
    if (hasCommentQueryParams.value) {
      redirectToCommentPage();
    }
  },
);

watch(() => [feedType.value, route.name], showTranslateNotification);

watch(teamInfo, () => {
  if (
    teamInfo.value?.enable_foreign_lang_media === false &&
    feedType.value !== 'domestic'
  ) {
    store.dispatch('feedType/updateFeedType', 'domestic');
  }
});

const isNotificationPages = computed(() =>
  route.matched.some(r => r.name === 'notifications'),
);
const isUserPage = computed(() => route.meta.type === 'user');
const isMyPage = computed(
  () =>
    route.meta.type === MYPAGE_ROUTE_META.type ||
    route.meta.type === 'userSettings',
);
const isSubNavigationEnable = computed(() => {
  return isMyPage.value || !(isUserPage.value || isNotificationPages.value);
});

const hidePopUp = () => {
  if (userEditModalOpen.value) {
    store.commit('userEditModal/hideUserEditModal');
  }
  store.commit('modal/hideModal');
};

const updateArticle = async (article: Article) => {
  const routeName = route.name;
  switch (routeName) {
    case 'userMarks':
      store.dispatch('userActions/updateUserMarkArticleLocally', {
        article: article,
      });
      break;
  }
};

const isSearchMenuVisible = ref(false);
watch(
  () => route.meta.type,
  (newRouteMetaType, oldRouteMetaType) => {
    if (oldRouteMetaType !== 'search' && newRouteMetaType === 'search') {
      // 別画面から検索画面に遷移する際にはメニューを開くように設定
      isSearchMenuVisible.value = true;
      isExpanded.value = true;
    } else if (oldRouteMetaType === 'search' && newRouteMetaType !== 'search') {
      // 検索画面から別画面に遷移する際にはisExpandedを再設定し、
      // isSearchMenuVisibleを初期化
      isSearchMenuVisible.value = false;
      isExpanded.value = userSession.getLeftMenuIsExpanded();
    }
  },
);

let isExpanded = ref(true);
isExpanded.value = userSession.getLeftMenuIsExpanded();
const isHovering = ref(false);

const toggleIsFolded = () => {
  if (isExpanded.value) {
    isHovering.value = false;
  }
  if (route.meta.type === 'search') {
    isSearchMenuVisible.value = false;
    isExpanded.value = !isExpanded.value;
    return;
  }
  userSession.setLeftMenuIsExpanded(!isExpanded.value);
  isExpanded.value = !isExpanded.value;
};
const openFoldedTemporary = () => {
  isHovering.value = true;
};

const isMenuTransitioning = ref(false);
watch(
  () => isExpanded.value,
  () => {
    isMenuTransitioning.value = true;
    setTimeout(() => (isMenuTransitioning.value = false), 320);
  },
);

const isMenuVisible = computed(() => isExpanded.value || isHovering.value);

/**
 * 検索履歴を使用して検索したかどうかのフラグ
 * NOTE: `keyword-search-bar.vue`と`search-results.vue`
 * の親子関係にないコンポーネント間で値を共有するためにprovideをここで定義
 */
const useSearchHistory = ref(false);
provide('useSearchHistory', useSearchHistory);
const { createSnackbar } = useSnackbar();

onMounted(async () => {
  const {
    canReceiveWebPushNotification,
    subscribeToWebPush,
    getSubscription,
    initializeWebPushStore,
  } = useWebPush();
  await initializeWebPushStore();
  const subscription = getSubscription();
  if (canReceiveWebPushNotification() && !subscription.value) {
    const result = await subscribeToWebPush();
    if (!result) return;
    if (result === 'SUCCESS') {
      createSnackbar({
        message: 'Push通知が有効化されました',
        type: 'success',
      });
    } else {
      createSnackbar({
        message: 'Push通知の有効化に失敗しました',
        type: 'error',
      });
    }
  }
});
</script>

<template>
  <div class="p-main-view" @keydown.esc="hidePopUp" tabindex="0">
    <div>
      <ConfirmationModal v-if="confirmationModalOpen"></ConfirmationModal>
      <UserEditModal v-if="userEditModalOpen"></UserEditModal>
      <ArticleShare
        v-if="modalOpen && modalType === 'articleShare'"
      ></ArticleShare>
      <GroupCreate
        v-if="modalOpen && modalType === 'groupCreate'"
      ></GroupCreate>
      <CommentCreate
        v-if="modalOpen && modalType === 'commentCreate'"
      ></CommentCreate>
      <CommentCreateWithoutArticleModal
        v-if="modalOpen && modalType === 'commentCreateWithoutArticle'"
      ></CommentCreateWithoutArticleModal>
      <CommentEdit
        v-if="modalOpen && modalType === 'commentEdit'"
      ></CommentEdit>
      <ThemeSettingsInfoModal
        v-if="modalOpen && modalType === 'themeSettingsInfo'"
      ></ThemeSettingsInfoModal>
      <TranslateNotificationModal
        v-if="modalOpen && modalType === 'translateNotification'"
      ></TranslateNotificationModal>
      <DialogGroupMark
        v-if="modalOpen && modalType === 'groupMark'"
      ></DialogGroupMark>
      <MarkModal v-if="modalOpen && modalType === 'mark'"></MarkModal>
      <ThemeTopicSelectModal
        v-if="modalOpen && modalType === 'themeTopicSelect'"
      ></ThemeTopicSelectModal>
      <TagListModal
        v-if="modalOpen && modalType === 'tagListModal'"
      ></TagListModal>
      <div id="modal-teleport-target"></div>
    </div>
    <div class="header">
      <FeedHeader></FeedHeader>
    </div>
    <NotificationPopupDialog></NotificationPopupDialog>
    <div class="p-feed-page-body">
      <div
        class="p-feed-left-menu-wrapper"
        @mouseenter="
          () => (!isMenuTransitioning ? (isHovering = true) : undefined)
        "
        @mouseleave="
          () => (!isMenuTransitioning ? (isHovering = false) : undefined)
        "
      >
        <div
          class="p-feed-left-menu"
          :class="{
            'p-feed-left-menu-no-subnavi':
              !isSubNavigationEnable || !isMenuVisible,
            'p-feed-left-menu-open':
              isSubNavigationEnable && (isMenuVisible || isSearchMenuVisible),
          }"
        >
          <LeftMenu
            :is-menu-visible="isMenuVisible || isSearchMenuVisible"
            :is-expanded="isExpanded"
            @toggle-is-folded="toggleIsFolded"
          ></LeftMenu>
        </div>
      </div>
      <div
        class="p-feed-content"
        :class="{
          'p-feed-content-no-subnavi': !isSubNavigationEnable || !isMenuVisible,
        }"
      >
        <router-view
          :is-menu-visible="isMenuVisible || isSearchMenuVisible"
          @open-left-menu="openFoldedTemporary"
        ></router-view>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
div.p-main-view {
  outline: none;

  .header {
    position: relative;
    z-index: var(--z-header);
  }
  div.p-feed-page-body {
    position: relative;
    display: flex;
    justify-content: start;

    div.p-feed-left-menu-wrapper {
      position: relative;
      z-index: var(--z-left-menu);

      div.p-feed-left-menu {
        position: fixed;
        width: 369px;
        left: 0;
        top: 64px;
        background: #ffffff;
        height: 100vh;
        &.p-feed-left-menu-open {
          border-right: 1px solid #e6e6e6;
        }
      }

      div.p-feed-left-menu-no-subnavi {
        width: 80px;
      }
    }

    div.p-feed-content {
      position: relative;
      width: calc(100vw - 368px);
      height: calc(100vh - 24px - 80px - 64px);
      padding-top: 24px;
      margin-top: 64px;
      margin-left: 368px;
    }
    .p-feed-content > div {
      padding-bottom: 80px;
    }
    div.p-feed-content-no-subnavi {
      width: calc(100vw - 80px);
      margin-left: 80px;
    }
  }
}
</style>
