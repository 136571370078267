<script lang="ts">
import { computed, defineComponent, nextTick, PropType, ref } from 'vue';
import { DgrIcon } from '@stockmarkteam/donguri-ui';
import {
  ErrorMessages,
  validateKeyword,
  validateMultiKeywords,
} from '@/utils/validators';

export default defineComponent({
  components: {
    DgrIcon,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    otherKeywords: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    hasSyntaxError: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    input: (_value: string) => true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    remove: (_e: Event) => true,
  },
  setup(props, context) {
    const inputRef = ref<HTMLInputElement>();
    const isEditing = ref(false);
    let prevValue = props.value;

    const keywordErrors = computed(() => {
      const errors = validateKeyword(props.value);
      if (props.value) {
        const uniqueOtherKeywords = Array.from(new Set(props.otherKeywords));
        errors.push(
          ...validateMultiKeywords([props.value, ...uniqueOtherKeywords]),
        );
      }
      const errorMessages = errors.map(error => ErrorMessages[error]);
      if (props.hasSyntaxError) {
        errorMessages.push('入力内容に誤りがあります。');
      }
      return errorMessages;
    });

    const startEditing = () => {
      if (props.disabled) return;

      isEditing.value = true;
      prevValue = props.value;

      nextTick(() => {
        inputRef.value?.focus();
      });
    };
    const finishEditing = () => {
      if (isDuringComposition.value) return;
      isEditing.value = false;
    };
    const cancelEditing = () => {
      context.emit('input', prevValue);
      isEditing.value = false;
    };

    const isDuringComposition = ref(false);

    const onInput = (event: Event) => {
      const value = (event.target as HTMLInputElement).value;
      context.emit('input', value);
    };

    return {
      inputRef,
      isEditing,
      keywordErrors,
      startEditing,
      finishEditing,
      cancelEditing,
      isDuringComposition,
      onInput,
    };
  },
});
</script>

<template>
  <div>
    <div v-if="keywordErrors.length > 0" class="o-errors">
      <div
        class="m-error c-formBlock__text c-formBlock__text--error"
        v-for="(errorMessage, errorIndex) in keywordErrors"
        :key="errorIndex + '_' + errorMessage"
      >
        {{ errorMessage }}
      </div>
    </div>
    <div
      class="theme-keyword-editable"
      :class="{
        'not-editing': !isEditing,
        error: keywordErrors.length > 0,
        disabled,
      }"
    >
      <template v-if="!isEditing">
        <VTooltip placement="bottom" class="text">
          <div class="c-text c-text--m" @click="startEditing">
            {{ value }}
          </div>
          <template #popper>
            {{ value }}
          </template>
        </VTooltip>

        <DgrIcon
          size="small"
          name="pencil"
          v-if="!disabled"
          class="pencil-icon"
          @click="startEditing"
        />
      </template>
      <input
        v-else
        ref="inputRef"
        class="c-text c-text--m c-textInput"
        :class="{
          'c-formInput--error': keywordErrors.length > 0,
        }"
        :value="value"
        @input="onInput"
        @blur="finishEditing"
        @keydown.enter="finishEditing"
        @keyup.esc="cancelEditing"
        @compositionstart="isDuringComposition = true"
        @compositionend="isDuringComposition = false"
      />
      <DgrIcon
        size="small"
        name="times"
        v-if="!disabled"
        class="remove-icon"
        @click="$emit('remove', $event)"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.theme-keyword-editable {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 100%;

  &.not-editing {
    cursor: pointer;
    border: 1px solid $color-gray400;
    border-radius: 4px;
    padding: 3px 0 3px 12px;

    &.error {
      border: 1px solid $color-orange1000;
    }

    &.disabled {
      background: $color-gray200;
    }
  }

  .text {
    width: 180%;
    height: 24px;
    text-align: left;
    line-height: 24px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .pencil-icon {
    flex: 0 0 auto;
  }

  .remove-icon {
    flex: 0 0 auto;
    cursor: pointer;
    padding: 8px;
    &:hover {
      background: $color-gray200;
      border-radius: 4px;
    }
  }
}

.c-formInput--error {
  outline: none;
}
.o-errors {
  margin-top: 8px;
}
.m-error {
  font-size: 12px;
  line-height: 12px;
}

.keyword-recommend-btn {
  border-radius: 20px;
  width: 90px;
  font-size: 12px;
  padding: 0px 10px;
  height: 25px;
}
</style>
