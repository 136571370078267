<script lang="ts">
import { defineComponent, PropType } from 'vue';
import api from '@/api';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import { OrganizationTag } from '@/types';

export default defineComponent({
  components: {},
  props: {
    isOpen: { type: Boolean, required: true },
    organizationTag: {
      type: Object as PropType<OrganizationTag>,
    },
    organizationTags: {
      type: Array as PropType<OrganizationTag[]>,
      default: () => [],
    },
  },
  emits: {
    onClose: () => true,
    onSubmit: () => true,
  },
  setup(props, { emit }) {
    const { createSnackbar } = useSnackbar();

    const close = () => {
      emit('onClose');
    };

    const onSubmit = async () => {
      try {
        if (!props.organizationTag) {
          throw new Error('organizationTag is undefined');
        }
        const orgId = props.organizationTag.id;
        const orgOrder = props.organizationTag.order;
        const shouldUpdateOrderOrganizationTagParams =
          props.organizationTags.flatMap(tag =>
            tag.order > orgOrder
              ? [
                  {
                    id: tag.id,
                    order: tag.order - 1,
                  },
                ]
              : [],
          );
        await api.bulkOperateOrganizationTags({
          deleteTagIds: [orgId],
          updateTags: shouldUpdateOrderOrganizationTagParams,
        });
        emit('onSubmit');
        createSnackbar({
          message: '組織タグを削除しました',
          type: 'success',
        });
      } catch {
        createSnackbar({
          message: '組織タグの削除に失敗しました',
          type: 'error',
        });
      }
      close();
    };

    return {
      close,
      onSubmit,
    };
  },
});
</script>

<template>
  <Teleport to="body">
    <sm-dialog
      v-if="isOpen"
      class="auto-height no-padding screen-center modal-container"
      :show-header="true"
      @close="close"
    >
      <template #header>
        <p>組織タグの削除</p>
      </template>
      <template #body>
        <div class="modal-body">
          <div class="modal-content c-text--m">
            <p>{{ organizationTag?.name }}</p>
            <p>を削除しますか？</p>
          </div>
          <div class="modal-btns-container c-text c-text--m">
            <button
              type="button"
              class="o-cancel-button c-btn c-btn--auto c-btnOutline"
              data-testid="cancel-button"
              @click="close"
            >
              キャンセル
            </button>
            <button
              type="button"
              class="o-create-button c-btn c-btn--auto c-btn--negative"
              @click="onSubmit()"
              data-testid="submit-button"
            >
              削除
            </button>
          </div>
        </div>
      </template>
    </sm-dialog>
  </Teleport>
</template>
<style lang="scss" scoped>
.modal-container {
  width: 480px;

  .modal-body {
    padding: 16px;

    .modal-content {
      margin-bottom: 25px;
    }
    .modal-btns-container {
      display: flex;
      justify-content: flex-end;

      :first-child {
        margin-right: 16px;
      }
    }
  }
}
</style>
