<script lang="ts">
import { computed, defineComponent, PropType, ref, toRef } from 'vue';
import { useRoute } from 'vue-router';
import api from '@/api';
import { Feature, PageName } from '@/api/tracking';
import {
  COMMENT_SHARE,
  GROUP_MARK_SHARE,
  MAX_TOOLTIP_LIST_LENGTH,
} from '@/constants';
import { DgrIcon } from '@stockmarkteam/donguri-ui';
import 'lazysizes';
import MarkButton from '@/components/common/adp-document/mark-button.vue';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import { AdpDocument, Article, FeedType } from '@/types';
import { ArticleMarkSource } from '@/types';
import { useThemeList, useUserInfo } from '@/utils/swr';
import { useEmitter, useStore } from '@/utils/vue';

export default defineComponent({
  props: {
    article: { type: Object as PropType<Article>, required: true },
    themeId: { type: Number, default: null },
    pageName: { type: String as PropType<PageName>, required: true },
    feature: { type: String as PropType<Feature>, required: true },
    feedType: { type: String as PropType<FeedType>, default: undefined },
    markSource: {
      type: String as PropType<ArticleMarkSource>,
      default: undefined,
    },
  },
  components: {
    MarkButton,
    DgrIcon,
  },
  setup(props) {
    const route = useRoute();
    const store = useStore();
    const emitter = useEmitter();
    const { createSnackbar } = useSnackbar();

    const { data: userInfo } = useUserInfo();
    const feedType = computed(() => store.state.feedType.feedType);

    const isMarking = ref(false);

    const hasMyMark = computed(() => {
      return props.article?.marks.some(
        mark => mark.user_id === userInfo.value?.id,
      );
    });

    const marked = computed(() => {
      return (props.article?.marks ?? []).length > 0;
    });

    const groupMarks = toRef(() => props.article.group_marks ?? []);

    const groupMarked = computed(() => {
      return (props.article?.group_marks ?? []).length > 0;
    });

    const markCount = computed(() => {
      return (props.article?.marks ?? []).length;
    });

    const groupMarkCount = computed(() => props.article.group_marks.length);

    const commentCount = computed(() => {
      return comments.value.length;
    });

    const { data: themes } = useThemeList(feedType);

    const comments = computed(() => props.article?.comments ?? []);

    const markArticle = async () => {
      if (isMarking.value) return;
      isMarking.value = true;
      if (hasMyMark.value) {
        await removeMark();
      } else {
        await sendMark(props.article, props.themeId, props.markSource, true);
      }
      isMarking.value = false;
    };

    const removeMark = async () => {
      try {
        const [myMark] = props.article.marks.filter(
          mark => mark.user_id === userInfo.value?.id,
        );
        if (myMark) {
          await api.deleteMark(myMark.id);
          const updatedArticle = {
            ...props.article,
            marks: props.article.marks.filter(
              mark => mark.user_id !== userInfo.value?.id,
            ),
          };
          await updateFeeds(updatedArticle);
        }
      } catch (err) {
        createSnackbar({
          message: 'マークを取り消せませんでした',
          type: 'error',
        });
        throw err;
      }
      createSnackbar({
        message: 'マークを取り消しました',
        type: 'success',
        undoFunc: () => {
          sendMark(props.article, props.themeId, props.markSource, false);
        },
      });
    };

    const updateFeeds = async (updatedArticle: Article) => {
      emitter.emit('article-updated', updatedArticle);
    };

    const sendMark = async (
      article: AdpDocument,
      themeId: number | null,
      markSource: ArticleMarkSource,
      showSnackbar = true,
    ): Promise<void> => {
      try {
        const createdMark = await api.sendMark(article, themeId, markSource, {
          pageName: props.pageName,
          pageUrl: route.fullPath,
          feature: props.feature,
          feedType: props.feedType,
        });
        const updatedArticle = {
          ...props.article,
          marks: [
            ...props.article.marks,
            { ...createdMark, user_name: userInfo.value?.user_name },
          ],
        };
        await updateFeeds(updatedArticle as Article);
      } catch (err) {
        createSnackbar({
          message: 'マークできませんでした',
          type: 'error',
        });
        throw err;
      }
      if (showSnackbar) {
        createSnackbar({
          type: 'link',
          message: 'マイページ',
          params: {
            link: `/#/users/${userInfo.value?.id}`,
            suffixText: 'にマークしました',
          },
        });
      }
    };

    const setupModal = () => {
      store.commit('modal/setArticle', props.article);
      store.commit(
        'modal/setTheme',
        themes.value?.themes.find(theme => theme.id === Number(props.themeId)),
      );
      store.commit('modal/setPageName', props.pageName);
      store.commit('modal/setFeature', props.feature);
      store.commit('modal/setFeedType', props.feedType);
    };

    const showCreateCommentModal = () => {
      setupModal();
      store.commit('modal/showModal', 'commentCreate');
    };

    const clickGroupMarkIcon = () => {
      setupModal();
      store.commit('modal/showModal', 'groupMark');
    };

    const showMarkModal = () => {
      setupModal();
      store.commit('modal/showModal', 'mark');
    };

    return {
      hasMyMark,
      markCount,
      groupMarkCount,
      commentCount,
      groupMarked,
      marked,
      clickGroupMarkIcon,
      markArticle,
      showCreateCommentModal,
      showMarkModal,
      groupMarks,
      GROUP_MARK_SHARE,
      MAX_TOOLTIP_LIST_LENGTH,
      COMMENT_SHARE,
      comments,
    };
  },
});
</script>

<template>
  <div class="article-actions">
    <div class="m-mark-comment-wrap">
      <MarkButton
        :adp-document="article"
        :small="true"
        @click-mark-button="markArticle"
        @click-open-mark-modal-button="showMarkModal"
      ></MarkButton>

      <VTooltip theme="menu" placement="top" @click="clickGroupMarkIcon">
        <div class="group-mark">
          <div class="m-mark-not-active-wrap" v-if="groupMarkCount > 0">
            <div
              class="m-mark-not-active hover-action"
              @click="clickGroupMarkIcon"
            >
              <DgrIcon size="small" name="group-mark" class="m-icon icon" />
              <div class="m-mark-text c-title c-title--s">
                {{ groupMarkCount }}
              </div>
            </div>
          </div>
          <div class="m-no-mark-wrap" v-else>
            <div class="m-no-mark hover-action" @click="clickGroupMarkIcon">
              <DgrIcon size="small" name="group-mark" class="m-icon icon" />
            </div>
          </div>
        </div>

        <template #popper>
          <template v-if="groupMarkCount === 0">
            <span>{{ GROUP_MARK_SHARE }}</span>
          </template>
          <template v-if="groupMarkCount > 0">
            <ul class="list-group-link">
              <li
                v-for="groupMark in groupMarks.slice(
                  0,
                  MAX_TOOLTIP_LIST_LENGTH,
                )"
                :key="groupMark.id"
              >
                <router-link
                  :to="{
                    name: 'groupMarks',
                    params: { groupId: groupMark.group_id },
                  }"
                >
                  ・{{ groupMark.group_name }}
                </router-link>
              </li>
            </ul>
          </template>
          <template v-if="groupMarkCount > MAX_TOOLTIP_LIST_LENGTH">
            <span>他{{ groupMarkCount - MAX_TOOLTIP_LIST_LENGTH }}件</span>
          </template>
        </template>
      </VTooltip>

      <VTooltip theme="menu" placement="top">
        <div
          class="m-comment-info hover-action"
          v-if="commentCount > 0"
          @click="showCreateCommentModal"
        >
          <DgrIcon size="small" name="comment" class="m-icon" />
          <div class="m-comment-text c-title c-title--s">
            {{ commentCount }}
          </div>
        </div>
        <div
          class="m-no-comment hover-action"
          v-else
          @click="showCreateCommentModal"
        >
          <DgrIcon size="small" name="comment" class="m-icon" />
        </div>

        <template #popper>
          <template v-if="commentCount === 0">
            <span>{{ COMMENT_SHARE }}</span>
          </template>
          <template v-if="commentCount > 0">
            <div
              v-for="comment in comments.slice(0, MAX_TOOLTIP_LIST_LENGTH)"
              :key="comment.id"
            >
              <router-link
                :to="{
                  name: 'groupComment',
                  params: { commentId: comment.id },
                }"
              >
                ・{{ comment.user_name }}
              </router-link>
            </div>
          </template>
          <template v-if="commentCount > MAX_TOOLTIP_LIST_LENGTH">
            <span>他{{ commentCount - MAX_TOOLTIP_LIST_LENGTH }}件</span>
          </template>
        </template>
      </VTooltip>
    </div>
  </div>
</template>

<style scoped lang="scss">
.article-actions {
  .m-mark-comment-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 4px;

    & > div:not(:last-child) {
      margin-right: 8px;
    }

    .c-title {
      margin-left: 4px;
    }
  }
  .m-mark-info,
  .m-comment-info,
  .m-mark-not-active,
  .m-no-mark,
  .m-no-comment {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
  }
  .m-comment-info,
  .m-no-comment {
    margin-left: 0;
  }
  .m-article-card-mark-count-active {
    font-weight: bold;
    font-size: 12px;
    line-height: 12px;
    color: #1da482;
  }
  .m-mark-text,
  .m-comment-text {
    color: #717171;
    font-weight: bold;
    font-size: 12px;
    line-height: 12px;
  }
  .hover-action {
    &:hover {
      background: #f2f2f2;
      border-radius: 4px;
    }
    padding: 4px;
  }
  .group-mark,
  .m-comment-info {
    width: 60px;
  }
}
.list-group-link {
  list-style: none;
}
</style>
