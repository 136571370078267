<script lang="ts">
import { defineComponent, onMounted, PropType, ref } from 'vue';
import { DgrIcon, IconTypes } from '@stockmarkteam/donguri-ui';

export default defineComponent({
  components: {
    DgrIcon,
  },
  props: {
    name: { type: String, required: true },
    icon: { type: String as PropType<IconTypes>, default: undefined },
    count: { type: Number, default: undefined },
    isDisabled: { type: Boolean, default: false },
    isExternalLink: { type: Boolean, default: false },
    isSelected: { type: Boolean, default: false },
    isDraggable: { type: Boolean, default: false },
  },
  setup() {
    const titleRef = ref<HTMLElement>();
    const isTooltipVisible = ref(false);

    onMounted(() => {
      const titleDisplaySpan = titleRef.value;
      if (titleDisplaySpan == undefined) {
        isTooltipVisible.value = false;
        return;
      }
      const parentTitleDisplaySpan = titleDisplaySpan.parentElement;

      // 親要素が存在しない場合は親要素幅を超過しないのでtooltipを表示しない
      if (parentTitleDisplaySpan == undefined) {
        isTooltipVisible.value = false;
        return;
      }

      // 親要素よりも横幅が大きい場合はtooltipを表示する
      isTooltipVisible.value =
        titleDisplaySpan !== undefined &&
        titleDisplaySpan.offsetWidth > parentTitleDisplaySpan.offsetWidth;
    });

    return {
      titleRef,
      isTooltipVisible,
    };
  },
});
</script>

<template>
  <div
    class="item"
    :class="{
      selected: isSelected,
      disabled: isDisabled,
    }"
  >
    <span class="name-icon c-text c-text--m">
      <template v-if="icon">
        <DgrIcon
          size="small"
          :name="icon"
          :keep-fill="false"
          class="icon"
          :class="{ draggable: isDraggable }"
        />
        <DgrIcon
          size="small"
          name="bars"
          :keep-fill="false"
          v-if="isDraggable"
          class="icon drag-icon"
        />
      </template>
      <VTooltip
        placement="bottom"
        :disabled="!isTooltipVisible"
        :prevent-overflow="true"
        class="name"
      >
        <span ref="titleRef">{{ name }}</span>
        <template #popper>{{ name }}</template>
      </VTooltip>
      <DgrIcon size="small" name="external-link" v-if="isExternalLink" />
    </span>
    <span v-if="count && count > 0" class="count c-text c-text--xs">{{
      count
    }}</span>
  </div>
</template>

<style scoped lang="scss">
.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;

  box-sizing: border-box;
  width: 240px;
  height: 40px;
  border-radius: 4px;
  padding: 8px;

  background: #fff;
  cursor: pointer;

  &:hover {
    background: $color-gray200;

    .name-icon {
      .icon.draggable {
        display: none;
      }
      .drag-icon {
        display: flex;
      }
    }
  }

  &.selected {
    color: $color-green600;
    background: rgba(152, 208, 188, 0.2);

    &:hover {
      background: rgba(152, 208, 188, 0.2);
    }
    .icon-box {
      fill: $color-green600;
    }
    .count {
      color: $color-green600;
    }
  }

  &.disabled {
    cursor: pointer;
    pointer-events: none;
    color: $color-gray600;

    .icon-box {
      fill: $color-gray600;
    }

    &:hover {
      background: #fff;
    }
  }

  .name-icon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;

    overflow: hidden;

    .icon {
      flex-shrink: 0;
    }
    .icon.draggable,
    .drag-icon {
      cursor: move;
    }

    .drag-icon {
      display: none;
    }

    .name {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .count {
    flex-shrink: 0;
    color: $color-gray600;
  }
}
</style>
