import {
  ContentsContext,
  createTrackingData,
  IndustryData,
  SearchData,
  TrackingBaseData,
} from '@/api/tracking';
import axios from 'axios';
import { AdpDocument, Comment, EmojiReaction, Mention } from '@/types';

type SendCommentProps = {
  groupId: number;
  content: string;
  mention?: Mention;
  adpDocument?: AdpDocument;
  themeId?: number;
  parentCommentId?: number;
  rankInWholeFeed?: number;
  trackingBaseData: TrackingBaseData;
  searchData?: SearchData;
  industryData?: IndustryData;
  description?: string;
  contentsContext?: ContentsContext;
  execution?: string;
};

export async function sendComment({
  groupId,
  adpDocument,
  themeId,
  content,
  mention = { all: false, target_user_ids: [], target_group_ids: [] },
  parentCommentId,
  trackingBaseData,
  rankInWholeFeed,
  searchData,
  industryData,
  description,
  contentsContext,
  execution,
}: SendCommentProps): Promise<Comment> {
  const { data } = await axios.post<Comment>('/comments', {
    group_id: groupId,
    article_id: adpDocument?.id,
    doc_type: adpDocument?.doc_type,
    theme_id: themeId ?? undefined,
    content: content,
    mention: mention,
    parent_comment_id: parentCommentId,
    tracking_data: createTrackingData({
      groupId,
      adpDocument,
      themeId,
      rankInWholeFeed,
      ...trackingBaseData,
      searchData,
      industryData,
      description,
      contentsContext,
      execution,
    }),
  });
  return data;
}

export async function updateComment(
  commentId: number,
  content: string,
): Promise<Comment> {
  const { data } = await axios.put<Comment>(`/comments/${commentId}`, {
    content,
  });
  return data;
}

export async function deleteComment(commentId: number): Promise<void> {
  await axios.delete<void>(`/comments/${commentId}`);
}

export async function postEmojiReaction(
  commentId: number,
  reaction: string,
): Promise<EmojiReaction> {
  const { data } = await axios.post<EmojiReaction>(
    `/comments/${commentId}/comment_reactions`,
    {
      reaction: reaction,
    },
  );
  return data;
}

export async function deleteReaction(
  commentId: number,
  commentReactionId: number,
): Promise<void> {
  await axios.delete<void>(
    `/comments/${commentId}/comment_reactions/${commentReactionId}`,
  );
}
