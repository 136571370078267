<script lang="ts">
import { defineComponent, PropType, ref, toRefs, watch } from 'vue';
import Draggable from 'vuedraggable';
import { DgrIcon } from '@stockmarkteam/donguri-ui';
import Item from '@/components/menu/common/item.vue';

type ItemProps = InstanceType<typeof Item>['$props'];

export default defineComponent({
  components: {
    Draggable,
    Item,
    DgrIcon,
  },
  props: {
    title: { type: String, default: undefined },
    items: { type: Array as PropType<ItemProps[]>, required: true },
    isFoldable: { type: Boolean, default: false },
    initialIsOpen: { type: Boolean, default: true },
    isSortable: { type: Boolean, default: false },
  },
  setup(props, context) {
    const { items } = toRefs(props);
    const { initialIsOpen, isSortable } = props;

    const itemsInternal = ref([...items.value]);
    watch(items, () => (itemsInternal.value = [...items.value]));

    const isOpen = ref(initialIsOpen);
    const toggleIsOpen = () => {
      isOpen.value = !isOpen.value;
      context.emit('menu-list-is-open', isOpen.value); // eslint-disable-line vue/require-explicit-emits
    };

    const isDragging = ref(false);
    const start = () => (isSortable ? (isDragging.value = true) : undefined);
    const move = () => isDragging.value;
    const update = async (item: { oldIndex: number; newIndex: number }) => {
      isDragging.value = false;
      if (item.oldIndex === item.newIndex) {
        return;
      }

      // eslint-disable-next-line vue/require-explicit-emits
      context.emit('menu-list-order-changed', {
        from: item.oldIndex,
        to: item.newIndex,
        items: [...itemsInternal.value],
      });
    };

    return {
      itemsInternal,
      isOpen,
      toggleIsOpen,
      start,
      move,
      update,
    };
  },
});
</script>

<template>
  <div class="item-list" :class="{ foldable: isFoldable }">
    <div
      v-if="title"
      class="title-icon c-title c-title--s"
      @click="() => (isFoldable ? toggleIsOpen() : undefined)"
    >
      <span class="title">
        {{ title }}
      </span>
      <DgrIcon
        size="small"
        :name="isOpen ? 'chevron-up' : 'chevron-down'"
        class="toggle-icon small"
        v-if="isFoldable"
      />
    </div>
    <div v-if="isOpen" class="items">
      <Draggable
        :disabled="!isSortable"
        :list="itemsInternal"
        item-key="id"
        tag="ul"
        ghost-class="ghost"
        :move="move"
        @start="start"
        @end="update"
      >
        <template #item="{ element: item }">
          <li class="item">
            <Item v-bind="{ ...item, isDraggable: isSortable }" />
          </li>
        </template>
      </Draggable>
    </div>
  </div>
</template>

<style scoped lang="scss">
.item-list {
  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  width: 240px;

  .title-icon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2px;

    box-sizing: border-box;
    height: 24px;
    padding: 4px;

    .title {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &.foldable {
    .title-icon {
      cursor: pointer;
      height: 24px;
      padding: 8px;
    }

    .toggle-icon {
      flex-shrink: 0;
    }
  }

  .items ul {
    list-style: none;
    padding: 0;
    .ghost {
      visibility: hidden;
    }
  }
}
</style>
