import { computed, ComputedRef, Ref, ref, watchEffect } from 'vue';
import axios, { AxiosError } from 'axios';
import dayjs, { Dayjs } from 'dayjs';
import Qs from 'qs';
import SWRVCache from 'swrv/dist/cache';
import { IConfig, IKey, IResponse } from 'swrv/dist/types';
import useSWRV from 'swrv/dist/use-swrv';
import {
  AdminGroup,
  AdminTheme,
  AdminUserInfo,
  AdpDocument,
  Article,
  Comment,
  Contract,
  DocType,
  FollowListMarkSortType,
  FollowListType,
  Group,
  GroupActivityCounts,
  GroupActivityNewsCategories,
  GroupActivitySortType,
  GroupTagSortTypes,
  GroupTagWithCount,
  GroupUser,
  Industry,
  IndustryArticle,
  IpAddressRestriction,
  JobType,
  KeywordCompletionRequest,
  KeywordCompletionResponse,
  KeywordRecommendResponse,
  Lang,
  MailDeliverySchedule,
  MyTagSortTypes,
  MyTagWithCount,
  NetworkGraphEdges,
  NetworkGraphNodes,
  NewsCategoryWithCount,
  NotificationItem,
  NotificationType,
  NotificationUnviewedAndUnreadCounts,
  OrganizationTagDetail,
  OrganizationTagList,
  OrganizationTagUserInfo,
  PersonalNewsCounts,
  Perspective,
  Tag,
  TeamInfo,
  Theme,
  ThemeFeedPatent,
  ThemeFeedResearchPaper,
  ThemeTopicCategory,
  ThemeUser,
  TrackingPageType,
  UserEntity,
  UserEntityWithAddUserOptions,
  UserInfo,
  UserMarkedArticleCounts,
  UserSettings,
  UserSettingsEmail,
  UserSettingsNews,
  UserSettingsNotification,
  UserSettingsProfile,
  ViewedArticle,
} from '@/types';
import { isAfternoonFeedEnabled } from './personalNews';
import { userSession } from './userSession';

export const STATES = {
  VALIDATING: 'VALIDATING',
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  STALE_IF_ERROR: 'STALE_IF_ERROR',
} as const;

const useSwrvState = (
  data: ReturnType<typeof useSWRV>['data'],
  error: ReturnType<typeof useSWRV>['error'],
  isValidating: ReturnType<typeof useSWRV>['isValidating'],
) => {
  const state = ref<(typeof STATES)[keyof typeof STATES] | 'idle'>('idle');
  watchEffect(() => {
    if (data.value && isValidating.value) {
      state.value = STATES.VALIDATING;
      return;
    }
    if (data.value && error.value) {
      state.value = STATES.STALE_IF_ERROR;
      return;
    }
    if (data.value === undefined && !error.value) {
      state.value = STATES.PENDING;
      return;
    }
    if (data.value && !error.value) {
      state.value = STATES.SUCCESS;
      return;
    }
    if (data.value === undefined && error.value) {
      state.value = STATES.ERROR;
      return;
    }
  });

  return {
    state,
  };
};

const SWRV_CACHE_KEYS = new Set<string>();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
class ClearableSWRVCache extends SWRVCache<any> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set(k: string, v: any, ttl: number): void {
    SWRV_CACHE_KEYS.add(k);
    super.set(k, v, ttl);
  }
}

const SWRV_OPTIONS = {
  refreshInterval: 0,
  ttl: 0,
  shouldRetryOnError: false,
  revalidateOnFocus: false,
  cache: new ClearableSWRVCache(),
} satisfies IConfig;

const DEFAULT_PAGE_LIMIT = 10;

const fetcher = async (url: string) => {
  const { data } = await axios.get(url);
  return data;
};

export const clearSWRVCache = () => {
  SWRV_CACHE_KEYS.forEach(key => SWRV_OPTIONS.cache.delete(key));
  SWRV_CACHE_KEYS.clear();
};

export const useSWRVWithState = <T>(key: IKey) => {
  const swrvData = useSWRV<T, AxiosError>(key, fetcher, SWRV_OPTIONS);
  const state = useSwrvState(
    swrvData.data,
    swrvData.error,
    swrvData.isValidating,
  );
  return { ...swrvData, ...state };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useSWRVPostWithState = <T, Data = any>(
  url: string,
  payload: Ref<Data>,
  hasData: (payload: Ref<Data>) => boolean,
) => {
  const key = () =>
    hasData(payload)
      ? axios.getUri({ url: url, params: payload.value })
      : undefined;
  const fetcher = async <T>() => {
    const { data } = await axios.post<T>(url, payload.value);
    return data;
  };
  const swrvData = useSWRV<T, AxiosError>(key, fetcher, SWRV_OPTIONS);
  const state = useSwrvState(
    swrvData.data,
    swrvData.error,
    swrvData.isValidating,
  );
  return { ...swrvData, ...state };
};

// 前回の別のキーのときの値を返さないSWRVのラッパー
// 参考: https://zenn.dev/visasq/articles/difference-between-swr-and-swrv
const swrvCache = new SWRVCache();
const useNonStickySWRV: typeof useSWRV = <T>(keyFn: IKey) => {
  const { data, error, isValidating, mutate } = useSWRV<T, AxiosError>(
    keyFn,
    fetcher,
    {
      ...SWRV_OPTIONS,
      cache: swrvCache,
    },
  );

  const patchedData =
    typeof keyFn === 'function'
      ? computed(() => {
          const value = data.value;
          const key = keyFn();
          if (!key || (typeof key === 'string' && !swrvCache.get(key))) {
            return undefined;
          }
          return value;
        })
      : data;

  return {
    data: patchedData,
    error,
    isValidating,
    mutate,
  };
};

export const useUserInfo = (userId?: Ref<number>) => {
  const uid = userId?.value ?? userSession.getUserId();
  const key = () => `/users/${uid}?include_action_counts=false`;
  return useSWRVWithState<UserInfo>(key);
};

export const useUserActionCount = (
  userId: Ref<number | string | undefined>,
  actionType: 'mark' | 'comment' | 'view',
) => {
  const key = () =>
    userId.value
      ? `/users/${userId.value}/action_count?action_type=${actionType}`
      : undefined;
  return useSWRVWithState<{ count: number }>(key);
};

export const useUserPersonalNewsDocumentCounts = () => {
  const key = () =>
    `/users/${userSession.getUserId()}/personal_news_document_counts`;
  return useSWRVWithState<PersonalNewsCounts>(key);
};

export const useNotifications = (
  pageRef: Ref<number>,
  pageLimit: number = DEFAULT_PAGE_LIMIT,
  notificationTypes: readonly NotificationType[],
  unreadOnly = false,
  trackingData: {
    page: {
      name: string;
      url: string;
    };
  },
  order: 'desc' | 'asc' = 'desc',
) => {
  const userId = userSession.getUserId();
  const key = () => {
    const params = {
      page: pageRef.value,
      limit: pageLimit,
      order: order,
      notification_types: notificationTypes,
      unread_only: unreadOnly,
      tracking_data: trackingData,
    };
    return `/users/${userId}/notifications?${Qs.stringify(params, {
      arrayFormat: 'brackets',
    })}`;
  };
  return useSWRVWithState<{
    notifications: NotificationItem[];
  }>(key);
};

export const useNotificationsUnviewedUnreadCount = <
  T extends Record<string, readonly NotificationType[]>,
>(
  notificationGrouping: T,
) => {
  const userId = userSession.getUserId();
  const key = `/users/${userId}/notifications/unviewed_unread_counts?${Qs.stringify(
    { notification_grouping: notificationGrouping },
  )}`;
  return useSWRVWithState<{ counts: NotificationUnviewedAndUnreadCounts<T> }>(
    key,
  );
};

export const useThemeList = (feedType: Ref<string>) => {
  const userId = userSession.getUserId();
  const key = () => {
    const params = {
      feed_type: feedType?.value,
      include_keyword_feed_counts: false,
    };
    return `/users/${userId}/themes?${Qs.stringify(params, {
      arrayFormat: 'brackets',
    })}`;
  };
  return useSWRVWithState<{ themes: Theme[] }>(key);
};

export const useThemeKeywordFeedCounts = (feedType?: Ref<string>) => {
  const key = () => {
    const params = {
      feed_type: feedType?.value,
    };
    return `/themes/keyword_feed_counts?${Qs.stringify(params, {
      arrayFormat: 'brackets',
    })}`;
  };
  return useSWRVWithState<{
    theme_keyword_feed_counts: Record<string, number>;
  }>(key);
};

export const useGroups = () => {
  const userId = userSession.getUserId();
  const key = () =>
    `/users/${userId}/groups?include_unread_comment_count=false`;
  return useSWRVWithState<{ groups: Group[] }>(key);
};

export const useGroupUnreadActivityCounts = () => {
  const userId = userSession.getUserId();
  const key = () => `/users/${userId}/groups/unread_activity_counts`;
  return useSWRVWithState<{
    unread_activity_counts: Record<string, number>;
  }>(key);
};

export const useGroupActivities = (
  groupId: number | undefined,
  page: Ref<number>,
  offset: Ref<number>,
  limit: number,
  sort: GroupActivitySortType,
  tags: string[],
  existsMemo: boolean | undefined,
  fromDate: string | undefined,
  toDate: string | undefined,
  lang: Lang[],
  docTypes: DocType[],
  query: string | undefined,
  newsCategories: string[],
) => {
  const key = () => {
    if (groupId === undefined) return undefined;
    const params = {
      page: page.value,
      offset: offset.value,
      limit: limit,
      sort: sort,
      tags: tags.length > 0 ? tags : undefined,
      exists_memo: existsMemo,
      from_date: fromDate,
      to_date: toDate,
      lang: lang.length > 0 ? lang : undefined,
      doc_types: docTypes.length > 0 ? docTypes : undefined,
      query: query,
      news_categories: newsCategories,
    };
    return `/groups/${groupId}/activities?${Qs.stringify(params, {
      arrayFormat: 'brackets',
    })}`;
  };
  return useSWRVWithState<{
    activities: (AdpDocument | Comment)[];
    next: number | null;
    page: number;
    tracking_session_id: string | undefined;
  }>(key);
};

export const useTeamUsers = () => {
  const teamId = userSession.getTeamId();
  const key = () => `/teams/${teamId}/users`;
  return useSWRVWithState<{
    users: UserEntity[];
  }>(key);
};

export const useTeamUsersWithAddUserOptions = () => {
  const teamId = userSession.getTeamId();
  const key = () => `/teams/${teamId}/users/with_add_user_options`;
  return useSWRVWithState<{
    users: UserEntityWithAddUserOptions[];
  }>(key);
};

export const useTeamInfo = () => {
  const myTeamId = userSession.getTeamId();
  const key = () => `/teams/${myTeamId}`;
  return useSWRVWithState<TeamInfo>(key);
};

export const useGroupUsers = (groupId: Ref<number | undefined>) => {
  const key = () =>
    groupId.value ? `/groups/${groupId.value}/users` : undefined;
  return useSWRVWithState<{ group_users: GroupUser[] }>(key);
};

// TODO: 削除予定
export const useGroupActivityCountsDeprecated = (
  groupId: Ref<number | undefined>,
  tags: Ref<string[]>,
  existsMemo: Ref<boolean | undefined>,
  fromDate: Ref<Date | undefined>,
  toDate: Ref<Date | undefined>,
  lang: ComputedRef<Lang[]>,
  docTypes: Ref<DocType[]>,
  query: Ref<string>,
  newsCategories: Ref<string[]>,
) => {
  const key = () => {
    if (groupId.value === undefined) return undefined;
    const params = {
      exists_memo: existsMemo.value,
      tags: tags.value.length > 0 ? tags.value : undefined,
      from_date: fromDate.value
        ? dayjs(fromDate.value).format('YYYY-MM-DD')
        : undefined,
      to_date: toDate.value
        ? dayjs(toDate.value).format('YYYY-MM-DD')
        : undefined,
      lang: lang.value.length > 0 ? lang.value : undefined,
      doc_types: docTypes.value.length > 0 ? docTypes.value : undefined,
      query: query.value,
      news_categories: newsCategories.value,
    };
    return `/groups/${groupId.value}/activity_total_count?${Qs.stringify(
      params,
      {
        arrayFormat: 'brackets',
      },
    )}`;
  };
  return useSWRVWithState<{ counts: GroupActivityCounts }>(key);
};
export const useGroupActivitiesCount = (
  groupId: Ref<number | undefined>,
  tags: Ref<string[]>,
  existsMemo: Ref<boolean | undefined>,
  fromDate: Ref<string | undefined>,
  toDate: Ref<string | undefined>,
  lang: ComputedRef<Lang[]>,
  docTypes: Ref<DocType[]>,
  query: Ref<string>,
  newsCategories: Ref<string[]>,
) => {
  const key = () => {
    if (groupId.value === undefined) return undefined;
    const params = {
      exists_memo: existsMemo.value,
      tags: tags.value.length > 0 ? tags.value : undefined,
      from_date: fromDate.value,
      to_date: toDate.value,
      lang: lang.value.length > 0 ? lang.value : undefined,
      doc_types: docTypes.value.length > 0 ? docTypes.value : undefined,
      query: query.value,
      news_categories: newsCategories.value,
    };
    return `/groups/${groupId.value}/activities_count?${Qs.stringify(params, {
      arrayFormat: 'brackets',
    })}`;
  };
  return useNonStickySWRV<{ count: number }>(key);
};

export const useMarkedArticleCountsMypage = (
  userId: Ref<string | number | undefined> | undefined,
  tags: Ref<string[]>,
  memoExists: Ref<boolean | undefined>,
  fromDate: Ref<Date | undefined>,
  toDate: Ref<Date | undefined>,
  lang: Ref<Lang[]>,
  docTypes: Ref<DocType[]>,
  query: Ref<string | undefined>,
  news_categories: Ref<string[]>,
) => {
  const key = () => {
    if (userId?.value === undefined) return undefined;
    const params = {
      memo_exists: memoExists.value,
      tags: tags.value.length > 0 ? tags.value : undefined,
      from_date: fromDate.value
        ? dayjs(fromDate.value).format('YYYY-MM-DD')
        : undefined,
      to_date: toDate.value
        ? dayjs(toDate.value).format('YYYY-MM-DD')
        : undefined,
      lang: lang.value.length > 0 ? lang.value : undefined,
      doc_types: docTypes.value.length > 0 ? docTypes.value : undefined,
      query: query.value,
      news_categories: news_categories.value,
    };
    return `/users/${userId.value}/marked_articles_total_count?${Qs.stringify(
      params,
      {
        arrayFormat: 'brackets',
      },
    )}`;
  };
  return useSWRVWithState<{ counts: UserMarkedArticleCounts }>(key);
};

// TODO: endpointの修正
export const useUserMarkedArticleCounts = (
  userId: Ref<string | number | undefined>,
  query: Ref<string | undefined>,
) => {
  const key = () => {
    if (userId?.value === undefined) return undefined;
    const params = {
      query: query.value,
    };
    return `/users/${userId.value}/marked_articles_total_count?${Qs.stringify(
      params,
      {
        arrayFormat: 'brackets',
      },
    )}`;
  };
  return useSWRVWithState<{ counts: UserMarkedArticleCounts }>(key);
};

export const useMarkedArticlesMypage = (
  pageRef: Ref<number>,
  pageLimit: number = DEFAULT_PAGE_LIMIT,
  userId: Ref<string | number | undefined> | undefined,
  tags: Ref<string[]>,
  memoExists: Ref<boolean | undefined>,
  fromDate: Ref<Date | undefined>,
  toDate: Ref<Date | undefined>,
  lang: Ref<Lang[]>,
  docTypes: Ref<DocType[]>,
  query: Ref<string | undefined>,
  news_categories: Ref<string[]>,
) => {
  const key = () => {
    if (userId?.value === undefined) return undefined;
    const params = {
      page: pageRef.value,
      limit: pageLimit,
      order: 'desc',
      memo_exists: memoExists.value,
      tags: tags.value.length > 0 ? tags.value : undefined,
      from_date: fromDate.value
        ? dayjs(fromDate.value).format('YYYY-MM-DD')
        : undefined,
      to_date: toDate.value
        ? dayjs(toDate.value).format('YYYY-MM-DD')
        : undefined,
      lang: lang.value.length > 0 ? lang.value : undefined,
      doc_types: docTypes.value.length > 0 ? docTypes.value : undefined,
      query: query.value,
      include_technical_literature: true,
      news_categories: news_categories.value,
    };
    return `/users/${userId.value}/marked_articles?${Qs.stringify(params, {
      arrayFormat: 'brackets',
    })}`;
  };
  return useSWRVWithState<{
    marked_articles: Article[];
    has_next_page: boolean;
    tracking_session_id: string | undefined;
  }>(key);
};

// TODO: endpointの修正
export const useUserMarkedArticles = (
  pageRef: Ref<number>,
  pageLimit: number = DEFAULT_PAGE_LIMIT,
  userId: Ref<string | number | undefined> | undefined,
  query: Ref<string | undefined>,
) => {
  const key = () => {
    if (userId?.value === undefined) return undefined;
    const params = {
      page: pageRef.value,
      limit: pageLimit,
      order: 'desc',
      query: query.value,
      include_technical_literature: true,
    };
    return `/users/${userId.value}/marked_articles?${Qs.stringify(params, {
      arrayFormat: 'brackets',
    })}`;
  };
  return useSWRVWithState<{
    marked_articles: Article[];
    has_next_page: boolean;
  }>(key);
};

export const useGroupComments = (
  groupId: Ref<string>,
  pageRef: Ref<number>,
  pageLimit: number = DEFAULT_PAGE_LIMIT,
) => {
  const key = () => {
    if (groupId.value === undefined) return undefined;
    const params = {
      page: pageRef.value,
      limit: pageLimit,
      order: 'desc',
      include_technical_literature: true,
    };
    return `/groups/${groupId.value}/comments?${Qs.stringify(params, {
      arrayFormat: 'brackets',
    })}`;
  };
  return useSWRVWithState<{ group_comments: Comment[] }>(key);
};

export const useGroupMarks = (
  groupId: Ref<string | undefined>,
  pageRef: Ref<number>,
  pageLimit: number = DEFAULT_PAGE_LIMIT,
  memoExists: Ref<string>,
  tags: Ref<{ items: string[] }>,
) => {
  const key = () => {
    if (groupId.value === undefined) return null;

    const params = {
      page: pageRef.value,
      limit: pageLimit,
      order: 'desc',
      memo_exists: memoExists.value !== 'all' ? memoExists.value : undefined,
      tags: tags.value.items.length > 0 ? tags.value.items : undefined,
      include_technical_literature: true,
    };
    return `/groups/${groupId.value}/marked_articles?${Qs.stringify(params, {
      arrayFormat: 'brackets',
    })}`;
  };
  return useSWRVWithState<{ marked_articles: AdpDocument[]; count: number }>(
    key,
  );
};

export const useGroupMarksCount = (
  groupId: Ref<string | undefined>,
  memoExists: Ref<string>,
  tags: Ref<{ items: string[] }>,
) => {
  const key = () => {
    if (groupId.value === undefined) return null;

    const params = {
      exists_memo: memoExists.value !== 'all' ? memoExists.value : undefined,
      tags: tags.value.items.length > 0 ? tags.value.items : undefined,
    };
    return `/groups/${groupId.value}/marked_articles/count?${Qs.stringify(
      params,
      {
        arrayFormat: 'brackets',
      },
    )}`;
  };
  return useSWRVWithState<{ count: number }>(key);
};

export const useComment = (commentId: Ref<string>) => {
  const key = () => `/comments/${commentId.value}`;
  return useSWRVWithState<Comment>(key);
};

export const useDocument = (
  id: Ref<string | null>,
  doc_type: Ref<DocType | null>,
  options?: Ref<{
    group_id_for_memo_and_tag: number | undefined;
  }>,
) => {
  const key = () => {
    if (!id.value || !doc_type.value) return null;

    const params = {
      group_id_for_memo_and_tag: options?.value.group_id_for_memo_and_tag,
    };
    return `/${doc_type.value}s/${id.value}?${Qs.stringify(params)}`;
  };
  return useSWRVWithState<AdpDocument>(key);
};

export const useGroupTags = (groupId: string) => {
  const key = () => `/groups/${groupId}/tags`;
  return useSWRVWithState<{ tags: Tag[] }>(key);
};

export const useTeamFeaturedArticles = (
  pageRef: Ref<number>,
  pageLimit: number = DEFAULT_PAGE_LIMIT,
) => {
  const teamId = userSession.getTeamId();
  const key = () =>
    `/teams/${teamId}/featured_articles?limit=${pageLimit}&page=${pageRef.value}&order=desc`;
  return useSWRVWithState<{ featured_articles: Article[] }>(key);
};

export const useIndustryArticles = (industryId: Ref<number | undefined>) => {
  const teamId = userSession.getTeamId();
  const key = () => {
    // industryIdがundefinedのときはswrvのkeyにnullを渡して
    // リクエストしないようにする
    if (industryId.value === undefined) return null;

    const params = {
      industry_id: industryId.value,
    };
    return `/teams/${teamId}/industry_articles?${Qs.stringify(params)}`;
  };

  return useSWRVWithState<{ industry_articles: IndustryArticle[] }>(key);
};

export const useIndustries = () => {
  const key = () => `/industries`;
  return useSWRVWithState<{ industries: Industry[] }>(key);
};

export const useUserOrganizationTags = (userId: Ref<number | undefined>) => {
  const key = () => {
    if (!userId.value) return null;
    return `/users/${userId.value}/organization_tags`;
  };
  return useSWRVWithState<{ organization_tags: OrganizationTagDetail[] }>(key);
};

export const useUserTags = () => {
  const userId = userSession.getUserId();
  const key = () => `/users/${userId}/tags`;
  return useSWRVWithState<{ user_tags: Tag[] }>(key);
};

export const useMailDeliverySchedules = () => {
  const teamId = userSession.getTeamId();
  const key = () => `/admin/teams/${teamId}/mail_delivery_schedules`;
  return useSWRVWithState<{ mail_delivery_schedules: MailDeliverySchedule[] }>(
    key,
  );
};

export const useThemeUsers = (themeId: number) => {
  const key = () => `/themes/${themeId}/users`;
  return useSWRVWithState<{ theme_users: ThemeUser[] }>(key);
};

export const usePersonalNewsMultipleExecution = (
  displayFeedType: Ref<string>,
  feedType: string,
  date: Dayjs,
  execution: string,
  page: Ref<number>,
  pageLimit: Ref<number> = ref(DEFAULT_PAGE_LIMIT),
) => {
  const userId = userSession.getUserId();
  const params = {
    feed_type: feedType,
    date: date.format('YYYY-MM-DD'),
    execution: execution,
    page: page.value,
    limit: pageLimit.value,
  };
  const key = (() =>
    (displayFeedType.value === 'all' || displayFeedType.value === feedType) &&
    (execution !== 'afternoon' || isAfternoonFeedEnabled(date))
      ? `/users/${userId}/personal_feeds?${Qs.stringify(params)}`
      : undefined) as IKey;
  return useSWRVWithState<{ personal_feeds: Article[]; count: number }>(key);
};

export const useThemeKeywordFeed = (
  themeId: number,
  feedType: Ref<string>,
  date: Ref<Date> | undefined,
  page: Ref<number> | undefined,
  pageLimit: number | undefined = DEFAULT_PAGE_LIMIT,
) => {
  const params = {
    feed_type: feedType.value,
    date: date ? dayjs(date.value).format('YYYY-MM-DD') : undefined,
    page: page ? page.value : undefined,
    limit: pageLimit,
  };
  const key = () => `/themes/${themeId}/keyword_feeds?${Qs.stringify(params)}`;
  return useSWRVWithState<{ keyword_feeds: Article[] }>(key);
};

export const useThemeRelatedFeed = (
  themeId: number | null,
  feedType: Ref<string>,
  date: Ref<Date> | undefined,
  page: Ref<number> | undefined,
  pageLimit: number | undefined = DEFAULT_PAGE_LIMIT,
) => {
  const params = {
    feed_type: feedType.value,
    date: date ? dayjs(date.value).format('YYYY-MM-DD') : undefined,
    page: page ? page.value : undefined,
    limit: pageLimit,
  };
  const key = () =>
    themeId ? `/themes/${themeId}/related_feeds?${Qs.stringify(params)}` : null;
  return useSWRVWithState<{ related_feeds: Article[] }>(key);
};

export const useThemeResearchPaperFeed = (
  displayFeedType: Ref<string>,
  themeId: number,
  feedType: string,
  page: Ref<number> | undefined,
  pageLimit: number | undefined = DEFAULT_PAGE_LIMIT,
) => {
  const params = {
    feed_type: feedType,
    page: page ? page.value : undefined,
    limit: pageLimit,
  };
  const key = (() =>
    displayFeedType.value === 'all' || displayFeedType.value === feedType
      ? `/themes/${themeId}/research_paper_feeds?${Qs.stringify(params)}`
      : undefined) as IKey;
  return useSWRVWithState<{ research_paper_feeds: ThemeFeedResearchPaper[] }>(
    key,
  );
};

export const useThemePatentFeed = (
  displayFeedType: Ref<string>,
  themeId: number,
  feedType: string,
  page: Ref<number> | undefined,
  pageLimit: number | undefined = DEFAULT_PAGE_LIMIT,
) => {
  const params = {
    feed_type: feedType,
    page: page ? page.value : undefined,
    limit: pageLimit,
  };
  const key = (() =>
    displayFeedType.value === 'all' || displayFeedType.value === feedType
      ? `/themes/${themeId}/patent_feeds?${Qs.stringify(params)}`
      : undefined) as IKey;
  return useSWRVWithState<{ patent_feeds: ThemeFeedPatent[] }>(key);
};

export const useTheme = (themeId: number) => {
  const key = () => `/themes/${themeId}`;
  return useSWRVWithState<{ themes: Theme }>(key);
};

export const useAdminGroups = () => {
  const teamId = userSession.getTeamId();
  const key = () => `/admin/teams/${teamId}/groups`;
  return useSWRVWithState<{ groups: AdminGroup[] }>(key);
};

export const useAdminUsers = () => {
  const teamId = userSession.getTeamId();
  const key = () => `/admin/teams/${teamId}/users`;
  return useSWRVWithState<{ users: AdminUserInfo[] }>(key);
};

export const useManagementContracts = () => {
  const key = () => '/admin/management_contracts';
  return useSWRVWithState<{ management_contracts: Contract[] }>(key);
};

export const useManagementContract = (contractId: Ref<number>) => {
  const key = () => {
    if (!contractId.value) return null;
    return `/admin/management_contracts/${contractId.value}`;
  };
  return useSWRVWithState<{ management_contract: Contract }>(key);
};

export const useManagementContractManagingUsers = (contractId: Ref<number>) => {
  const key = () => {
    if (!contractId.value) return null;
    return `/admin/management_contracts/${contractId.value}/managing_users`;
  };
  return useSWRVWithState<{ users: UserInfo[] }>(key);
};

export const useViewedArticles = (
  pageRef: Ref<number>,
  pageLimit: number = DEFAULT_PAGE_LIMIT,
) => {
  const userId = userSession.getUserId();
  const key = () => {
    const params = {
      page: pageRef.value,
      limit: pageLimit,
      include_technical_literature: true,
    };
    return `/users/${userId}/views?${Qs.stringify(params, {
      arrayFormat: 'brackets',
    })}`;
  };
  return useSWRVWithState<{ viewed_articles: ViewedArticle[] }>(key);
};

// NOTE: useUserSettingsをdeleteするまでの対応
// TODO: fix api endpoint
export const useUserSettingsEmail = () => {
  const userId = userSession.getUserId();
  const key = () => `/users/${userId}/settings`;
  return useSWRVWithState<UserSettingsEmail>(key);
};
export const useUserSettingsNotification = (
  endpoint: ComputedRef<string | undefined>,
) => {
  const userId = userSession.getUserId();
  // NOTE: nullは初期値。空文字はendpointがない場合
  const key = () =>
    `/users/${userId}/settings/notification${
      endpoint.value === undefined
        ? ''
        : `?${Qs.stringify({ endpoint: endpoint.value })}`
    }`;
  return useSWRVWithState<UserSettingsNotification>(key);
};
// TODO: fix api endpoint
export const useUserSettingsNews = () => {
  const userId = userSession.getUserId();
  const key = () => `/users/${userId}/settings/`;
  return useSWRVWithState<UserSettingsNews>(key);
};
// TODO: fix api endpoint
export const useUserSettingsProfile = () => {
  const userId = userSession.getUserId();
  const key = () => `/users/${userId}/settings/`;
  return useSWRVWithState<UserSettingsProfile>(key);
};

export const useAdminThemes = () => {
  const teamId = userSession.getTeamId();
  const key = () => `/admin/teams/${teamId}/themes`;
  return useSWRVWithState<{ themes: AdminTheme[] }>(key);
};

export const useJobTypes = () => {
  const key = () => '/job_types';
  return useSWRVWithState<{ job_types: JobType[] }>(key);
};

export const useThemeTopicCategories = () => {
  const key = () => '/theme_topic_categories';
  return useSWRVWithState<{ theme_topic_categories: ThemeTopicCategory[] }>(
    key,
  );
};

export const useThemePerspectives = () => {
  const key = () => '/theme_perspectives';
  return useSWRVWithState<{ theme_perspectives: Perspective[] }>(key);
};

export const usePersonalResearchPaperFeed = (
  displayFeedType: Ref<string>,
  userId: number,
  feedType: string,
  page: Ref<number> | undefined,
  pageLimit: number | undefined = DEFAULT_PAGE_LIMIT,
) => {
  const params = {
    feed_type: feedType,
    page: page ? page.value : undefined,
    limit: pageLimit,
  };
  const key = (() =>
    displayFeedType.value === 'all' || displayFeedType.value === feedType
      ? `/users/${userId}/personal_research_paper_feeds?${Qs.stringify(params)}`
      : undefined) as IKey;
  return useSWRVWithState<{ research_paper_feeds: ThemeFeedResearchPaper[] }>(
    key,
  );
};

export const usePersonalPatentFeed = (
  displayFeedType: Ref<string>,
  userId: number,
  feedType: string,
  page: Ref<number> | undefined,
  pageLimit: number | undefined = DEFAULT_PAGE_LIMIT,
) => {
  const params = {
    feed_type: feedType,
    page: page ? page.value : undefined,
    limit: pageLimit,
  };
  const key = (() =>
    displayFeedType.value === 'all' || displayFeedType.value === feedType
      ? `/users/${userId}/personal_patent_feeds?${Qs.stringify(params)}`
      : undefined) as IKey;
  return useSWRVWithState<{ patent_feeds: ThemeFeedPatent[] }>(key);
};

export const useKeywordRecommend = (
  query: Ref<string>,
  minScore?: number,
): IResponse<KeywordRecommendResponse> => {
  const key = () => {
    if (query.value.trim() === '') return null;

    const param = new URLSearchParams();
    param.set('query', query.value);
    if (minScore) {
      param.set('min_score', String(minScore));
    }
    return `/keyword_recommendation?${param}`;
  };
  return useNonStickySWRV<KeywordRecommendResponse>(key);
};

export const useKeywordCompletion = (
  input: Ref<KeywordCompletionRequest>,
): IResponse<KeywordCompletionResponse> => {
  return useSWRVPostWithState<KeywordCompletionResponse>(
    '/keyword_completion',
    input,
    p => p.value.text.length > 0,
  );
};

export const useOrganizationTagDetail = (
  organizationTagId: number | Ref<number>,
) => {
  if (typeof organizationTagId === 'number') {
    return useSWRVWithState<OrganizationTagDetail>(
      `/organization_tags/${organizationTagId}`,
    );
  }
  const key = () => {
    return `/organization_tags/${organizationTagId.value}`;
  };
  return useSWRVWithState<OrganizationTagDetail>(key);
};

export const useOrganizationTagList = () => {
  const teamId = userSession.getTeamId();
  return useSWRVWithState<{
    organization_tags: OrganizationTagList;
  }>(`/organization_tags/teams/${teamId}`);
};

export const useOrganizationTagJoinableUsers = (organizationTagId: number) => {
  return useSWRVWithState<{
    users: OrganizationTagUserInfo[];
  }>(`/organization_tags/${organizationTagId}/joinable_users`);
};

export const useIpAddressRestrictions = () => {
  const teamId = userSession.getTeamId();
  return useSWRVWithState<{
    ip_address_restrictions: IpAddressRestriction[];
  }>(`/admin/teams/${teamId}/ip_address_restrictions`);
};

export const useFollowLists = () => {
  const key = () => `/follow_lists`;

  return useSWRVWithState<{ follow_lists: FollowListType[] }>(key);
};

export const useFollowList = (followListId: Ref<number>) => {
  const key = () => `/follow_lists/${followListId.value}`;

  return useSWRVWithState<FollowListType>(key);
};

export const useFollowListMarkedArticles = (
  followListId: Ref<number>,
  sort: Ref<FollowListMarkSortType | undefined>,
  page: Ref<number>,
  pageLimit: Ref<number> = ref(DEFAULT_PAGE_LIMIT),
  trackingData: TrackingPageType<'follow_list'>,
) => {
  const key = () => {
    if (sort.value === undefined) return null;

    const params = {
      page: page.value,
      limit: pageLimit.value,
      sort: sort.value,
      tracking_data: trackingData,

      // 呼び出し先のコンポーネントから渡されると、値が変わるたびにrefetchされてしまうため、自身でlocalStorageを参照する
      show_comment: userSession.getFollowListCommentsVisible(),
    };
    return `/follow_lists/${followListId.value}/marked_articles?${Qs.stringify(
      params,
      { arrayFormat: 'brackets' },
    )}`;
  };
  return useSWRVWithState<{ marked_articles: Article[] }>(key);
};

export const useNetworkGraph = (
  productContractIds: ComputedRef<string[]>,
  organizationTagIds: ComputedRef<number[]>,
) => {
  const key = () => {
    const params = {
      product_contract_ids: productContractIds.value,
      organization_tag_ids: organizationTagIds.value,
      type: 'member',
    };
    return `/labs/network_graph?${Qs.stringify(params, { arrayFormat: 'brackets' })}`;
  };
  return useSWRVWithState<{
    nodes: NetworkGraphNodes[];
    edges: NetworkGraphEdges[];
  }>(key);
};

export const useNetworkGraphOrganizationTag = (
  organizationTagNames: Ref<string[]>,
  from: Ref<Date>,
  to: Ref<Date>,
) => {
  const key = () => {
    const params = {
      from: from.value,
      to: to.value,
      organization_tag_names: organizationTagNames.value,
      type: 'organization_tag',
    };
    return `/labs/network_graph?${Qs.stringify(params, { arrayFormat: 'brackets' })}`;
  };
  return useSWRVWithState<{
    nodes: NetworkGraphNodes[];
    edges: NetworkGraphEdges[];
  }>(key);
};

export const useAdpDocumentByIds = (ids: Ref<number[]>) => {
  const key = () => {
    if (ids.value.length === 0) return null;
    return `/articles/collection?${Qs.stringify({ ids: ids.value }, { arrayFormat: 'brackets' })}`;
  };
  return useSWRVWithState<AdpDocument[]>(key);
};

export const useUsers = () => {
  const myTeamId = userSession.getTeamId();
  const key = () => (myTeamId ? `/teams/${myTeamId}/users` : null);
  return useSWRVWithState<{ users: UserEntity[] }>(key);
};

export const useUserSettings = (userId: ComputedRef<number | undefined>) => {
  const key = () => (userId.value ? `/users/${userId.value}/settings` : null);
  return useSWRVWithState<UserSettings>(key);
};

export const useGroupTagTotalCount = (
  groupId: ComputedRef<number | undefined>,
) => {
  const key = () =>
    groupId.value ? `/groups/${groupId.value}/group_tag_count` : null;
  return useSWRVWithState<{ group_tag_count: number }>(key);
};

export const useMyTagTotalCount = (userId: ComputedRef<number | undefined>) => {
  const key = () => `/users/${userId.value}/my_tag_count`;
  return useSWRVWithState<{ my_tag_count: number }>(key);
};

export const useGroupTagItems = (
  groupId: ComputedRef<number | undefined>,
  sortBy?: Ref<GroupTagSortTypes | undefined>,
  pageRef?: Ref<number>,
  pageLimit: number = DEFAULT_PAGE_LIMIT,
) => {
  const key = () =>
    `/groups/${groupId.value}/group_tags?${Qs.stringify({
      sort_by: sortBy?.value,
      page: pageRef?.value,
      limit: pageLimit,
    })}`;
  return useSWRVWithState<Array<GroupTagWithCount>>(key);
};

export const useGroupActivityNewsCategories = (
  groupId: Ref<number | undefined> | ComputedRef<number | undefined>,
) => {
  const key = () =>
    groupId.value ? `/groups/${groupId.value}/activity_news_categories` : null;
  return useSWRVWithState<{
    activity_news_categories: GroupActivityNewsCategories;
  }>(key);
};

export const useMypageNewsCategories = () => {
  return useSWRVWithState<{
    marked_articles_news_categories: NewsCategoryWithCount[];
  }>('/users/marked_articles_news_categories');
};

export const useMyTagItems = (
  sortBy: Ref<MyTagSortTypes | undefined>,
  pageRef: Ref<number>,
  pageLimit: number = DEFAULT_PAGE_LIMIT,
) => {
  const userId = userSession.getUserId();
  if (!userId) throw new Error('userId is not defined');

  const key = () =>
    `/users/${userId}/my_tags?${Qs.stringify({
      sort_by: sortBy.value,
      user_id: userId,
      page: pageRef.value,
      limit: pageLimit,
    })}`;
  return useSWRVWithState<Array<MyTagWithCount>>(key);
};
