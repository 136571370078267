<script setup lang="ts">
import { NOTIFICATION_TYPE } from '@/constants';
import CommentReactionCard from '@/components/notifications/cards/comment-reaction-card.vue';
import FirstPersonalNewsCard from '@/components/notifications/cards/first-personal-news-card.vue';
import ForceThemeFavoriteCard from '@/components/notifications/cards/force-theme-favorite-card.vue';
import GroupCommentCard from '@/components/notifications/cards/group-comment-card.vue';
import GroupInvitationCard from '@/components/notifications/cards/group-invitation-card.vue';
import GroupMarkCard from '@/components/notifications/cards/group-mark-card.vue';
import MentionCard from '@/components/notifications/cards/mention-card.vue';
import ReplyCommentCard from '@/components/notifications/cards/reply-comment-card.vue';
import ThemeInvitationCard from '@/components/notifications/cards/theme-invitation-card.vue';
import UpdateThemeFeedCard from '@/components/notifications/cards/update-theme-feed-card.vue';
import { NotificationItem } from '@/types';

type Props = {
  notification: NotificationItem;
  isUnread: boolean;
};
defineProps<Props>();
type Emits = {
  'read-notification': [notification: NotificationItem];
};
const emit = defineEmits<Emits>();
</script>
<template>
  <MentionCard
    v-if="notification.type === NOTIFICATION_TYPE.MENTION"
    :notification="notification"
    :is-unread="isUnread"
    @click="emit('read-notification', notification)"
  />
  <ReplyCommentCard
    v-if="notification.type === NOTIFICATION_TYPE.REPLY_COMMENT"
    :notification="notification"
    :is-unread="isUnread"
    @click="emit('read-notification', notification)"
  />
  <CommentReactionCard
    v-if="notification.type === NOTIFICATION_TYPE.COMMENT_REACTION"
    :notification="notification"
    :is-unread="isUnread"
    @click="emit('read-notification', notification)"
  />
  <ThemeInvitationCard
    v-if="notification.type === NOTIFICATION_TYPE.THEME_INVITATION"
    :notification="notification"
    :is-unread="isUnread"
    @click="emit('read-notification', notification)"
  />

  <FirstPersonalNewsCard
    v-if="notification.type === NOTIFICATION_TYPE.FIRST_PERSONAL_NEWS"
    :notification="notification"
    :is-unread="isUnread"
    @click="emit('read-notification', notification)"
  />

  <UpdateThemeFeedCard
    v-if="notification.type === NOTIFICATION_TYPE.UPDATE_THEME_FEED"
    :notification="notification"
    :is-unread="isUnread"
    @click="emit('read-notification', notification)"
  />

  <ForceThemeFavoriteCard
    v-if="notification.type === NOTIFICATION_TYPE.FORCE_THEME_FAVORITE"
    :notification="notification"
    :is-unread="isUnread"
    @click="emit('read-notification', notification)"
  />

  <GroupMarkCard
    v-if="notification.type === NOTIFICATION_TYPE.GROUP_MARK"
    :notification="notification"
    :is-unread="isUnread"
    @click="emit('read-notification', notification)"
  />

  <GroupCommentCard
    v-if="notification.type === NOTIFICATION_TYPE.GROUP_COMMENT"
    :notification="notification"
    :is-unread="isUnread"
    @click="emit('read-notification', notification)"
  />
  <GroupInvitationCard
    v-if="notification.type === NOTIFICATION_TYPE.GROUP_INVITATION"
    :notification="notification"
    :is-unread="isUnread"
    @click="emit('read-notification', notification)"
  />
</template>
