<script setup lang="ts">
import { ref, watch } from 'vue';
import api from '@/api';
import { MAX_TAG_NAME_LENGTH } from '@/constants';
import { isAxiosError } from 'axios';
import ArticleTagInput from '@/components/article/article-tags-input.vue';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import { MyTag } from '@/types';

type Props = {
  isOpen: boolean;
};

defineProps<Props>();

const emit = defineEmits<{
  close: [];
  submit: [
    {
      myTag: MyTag;
    },
  ];
}>();

const close = () => {
  inputValue.value = '';
  clearErrorMessage();
  emit('close');
};

const { createSnackbar } = useSnackbar();
const errorMessage = ref<string | undefined>(undefined);
const inputValue = ref('');
const isLoading = ref(false);
const DUPLICATE_ERROR_MESSAGE = '同じ名称のマイタグがあります。';

const clearErrorMessage = () => {
  errorMessage.value = undefined;
};

watch(() => inputValue.value, clearErrorMessage);

const submit = async () => {
  isLoading.value = true;
  try {
    if (inputValue.value === '') {
      errorMessage.value = 'マイタグを入力してください';
      return;
    }
    const myTag = await api.createMyTag(inputValue.value);
    emit('submit', { myTag: myTag });
    createSnackbar({
      message: 'マイタグを作成しました',
      type: 'success',
    });
    inputValue.value = '';
  } catch (e) {
    if (isAxiosError(e) && e.response?.data['error_type'] === 'duplicate') {
      errorMessage.value = DUPLICATE_ERROR_MESSAGE;
    } else {
      createSnackbar({
        message: 'マイタグの作成に失敗しました',
        type: 'error',
      });
      throw e;
    }
  } finally {
    isLoading.value = false;
  }
};
</script>

<template>
  <Teleport to="body">
    <sm-dialog
      v-if="isOpen"
      class="no-padding screen-center auto-height"
      :show-header="true"
      @close="close"
    >
      <template #header>
        <p>マイタグを作成</p>
      </template>
      <template #body>
        <div class="modal-body">
          <div class="modal-content">
            <ArticleTagInput
              v-model="inputValue"
              :error-message="errorMessage"
              :max-length-error-message="`${MAX_TAG_NAME_LENGTH}文字以内のマイタグが作成できます`"
            />
          </div>
          <div class="modal-buttons-container c-text c-text--m">
            <button
              type="button"
              class="o-cancel-button c-btn c-btn--auto c-btnOutline"
              @click="close"
            >
              キャンセル
            </button>
            <button
              type="button"
              class="c-btn c-btn--auto c-btn--AnewsPrimary"
              @click="submit"
              :disabled="isLoading"
            >
              保存
            </button>
          </div>
        </div>
      </template>
    </sm-dialog>
  </Teleport>
</template>

<style lang="scss" scoped>
.modal-body {
  padding: 16px;
  input {
    outline: none;
  }

  .modal-content {
    margin-bottom: 25px;
  }

  .modal-buttons-container {
    display: flex;
    justify-content: flex-end;

    :first-child {
      margin-right: 16px;
    }
  }
}
</style>
