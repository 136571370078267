<script lang="ts">
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  PropType,
  reactive,
  ref,
  toRefs,
} from 'vue';
import { SITES_COUNT_PER_REQUEST } from '@/constants';
import { DgrIcon } from '@stockmarkteam/donguri-ui';
import { Site } from '@/types';

export default defineComponent({
  components: {
    DgrIcon,
  },
  props: {
    modelValue: { type: Array as PropType<Site[]>, required: true },
    keyword: { type: String },
    items: { type: Array as PropType<Site[]>, required: true },
    maxItems: { type: Number, default: 10 },
    hasNextExcludeSites: { type: Boolean, default: false },
  },
  emits: {
    'update:modelValue': (_selectedItems: Site[]) => true,
    'update:keyword': (_keyword: string) => true,
  },
  setup(props, context) {
    const { items, modelValue: selectedItems } = toRefs(props);
    const currentSelectedItems = reactive({
      items: selectedItems?.value ?? [],
    });
    const itemListVisible = ref(false);
    const hideList = () => (itemListVisible.value = false);

    onMounted(() => {
      window.addEventListener('click', hideList);
    });
    onBeforeUnmount(() => window.addEventListener('click', hideList));

    const addItem = (item: Site) => {
      currentSelectedItems.items?.push(item);
      hideList();
      context.emit('update:modelValue', currentSelectedItems.items);
    };

    const removeItem = (item: Site) => {
      currentSelectedItems.items = currentSelectedItems.items.filter(
        i => i.id !== item.id,
      );
      context.emit('update:modelValue', currentSelectedItems.items);
    };

    const visibleItems = computed(() => {
      const selectedItemIds = (currentSelectedItems.items ?? []).map(i => i.id);
      return items.value.filter(i => !selectedItemIds.includes(i.id));
    });

    return {
      currentSelectedItems,
      itemListVisible,
      visibleItems,
      addItem,
      removeItem,
      SITES_COUNT_PER_REQUEST,
    };
  },
});
</script>

<template>
  <div class="item-select">
    <input
      class="c-textInput"
      type="text"
      :value="keyword"
      placeholder="メディア名を入力"
      :disabled="currentSelectedItems.items.length === maxItems"
      @click.stop.prevent="() => undefined"
      @focus="itemListVisible = true"
      @input="
        $emit('update:keyword', ($event.target as HTMLInputElement).value)
      "
    />
    <div class="item-list" v-if="itemListVisible">
      <div class="item disabled heading">
        <div class="c-text c-text--xs">
          <span class="media-label">メディア</span>
          <span>{{ visibleItems.length }}件</span>
        </div>
        <div class="c-text c-text--xs">週間記事数</div>
      </div>
      <div
        class="item"
        v-for="item in visibleItems"
        :key="item.id"
        @click="addItem(item)"
      >
        <div class="info">
          <div class="name c-text c-text--m">{{ item.name }}</div>
          <div class="hostname c-text c-text--xs">{{ item.hostname }}</div>
        </div>
        <div class="count c-text c-text--xs">
          {{ Number(item.article_count_ja).toLocaleString() }}件
        </div>
      </div>
    </div>
    <div class="selected-items" v-if="currentSelectedItems.items.length > 0">
      <VTooltip
        placement="bottom"
        theme="menu"
        v-for="item in currentSelectedItems.items"
        :key="item.id"
      >
        <div class="item">
          <div class="c-outlineTag c-outlineTag--delete">
            <div class="name c-text c-text--s">{{ item.name }}</div>
            <DgrIcon name="times" size="xs" @click="removeItem(item)" />
          </div>
        </div>
        <template #popper>
          <div>{{ item.name }}<br />{{ item.hostname }}</div>
        </template>
      </VTooltip>
    </div>
  </div>
</template>

<style scoped lang="scss">
.item-select {
  box-sizing: border-box;
  position: relative;

  input {
    width: 100% !important;
    max-width: 100% !important;
  }

  .media-label {
    margin-right: 4px;
  }

  .item-list {
    box-sizing: border-box;
    position: absolute;
    z-index: var(--z-dropdown);
    max-height: 220px;
    width: 100%;
    overflow-y: scroll;
    background-color: #ffffff;
    border-radius: 4px;
    border-left: 1px solid #e6e6e6;
    border-right: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;

    .item {
      cursor: pointer;
      display: flex;
      padding: 4px 12px;
      align-items: center;

      &.disabled {
        cursor: default;
        color: #b3b3b3;
        justify-content: space-between;
      }
      &:not(.disabled):hover {
        background: #f2f2f2;
      }
      &.heading {
        padding: 10px 12px 12px 12px;
        border-bottom: 1px solid #e6e6e6;
      }
      .hostname,
      .count {
        color: #717171;
      }
      .info {
        flex-grow: 1;
        max-width: calc(100% - 65px);
        .name,
        .hostname {
          word-wrap: nowrap;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .count {
        width: 65px;
        margin-left: 8px;
        text-align: right;
      }
    }
  }

  .selected-items {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin-top: 4px;
    .item {
      display: inline-block;
      position: relative;
      cursor: default;
      margin-top: 8px;
      margin-right: 8px;
      .name {
        max-width: 94px;
        word-wrap: nowrap;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .icon-box {
        cursor: pointer;
        padding: 4px 2px;
      }
    }
  }
}
</style>
