import { DAY_OF_WEEK_LIST } from '@/constants';
import dayjs, { Dayjs } from 'dayjs';

export function formatDate(dateString: string): string {
  const day = dayjs(dateString);
  return day.isValid() ? dayjs(dateString).format('YYYY/M/D') : '';
}

const _formatDateTime = (date: Dayjs): string => {
  return date.format('YYYY/MM/DD H:mm');
};

const _formatDateTimeNoYear = (date: Dayjs): string => {
  return date.format('MM/DD H:mm');
};

const _formatDateNoYear = (date: Dayjs): string => {
  return date.format('M/D');
};

export function formatDateTime(dateTimeString: string): string {
  if (!dateTimeString) {
    return '';
  }
  return _formatDateTime(dayjs(dateTimeString));
}

export function formatTimestamp(timestampString?: string | null): string {
  if (!timestampString) {
    return '';
  }
  return _formatDateTime(dayjs(timestampString));
}

export function formatDayOfWeek(dateTimeString: string): string {
  if (!dateTimeString) {
    return '';
  }
  const day = dayjs(dateTimeString);
  const dayOfWeek = day.day();
  const formatedDay = _formatDateNoYear(day);
  return formatedDay + ' (' + DAY_OF_WEEK_LIST[dayOfWeek] + ')';
}

export function showRelativeTime(date: Dayjs): boolean {
  const maxTimeAgo = dayjs().subtract(4, 'day');
  return date.isAfter(maxTimeAgo) && date.isSameOrBefore(dayjs());
}

export function formatDateForComment(dateString: string): string {
  const day = dayjs(dateString);
  if (showRelativeTime(day)) {
    return day.fromNow();
  } else if (dayjs().year() === day.year()) {
    return _formatDateTimeNoYear(day);
  }
  return formatDateTime(dateString);
}

export function formatDateForStorage(dateString: string): string {
  const day = dayjs(dateString);
  if (!day.isValid()) {
    return '-';
  }
  // chromaticでtzがUTCになるためtz()を追加
  return day.tz().format('M/D H:mm');
}

export const formatDateForFeedLabel = (
  date: Dayjs,
  feedStartDate: Dayjs,
): string => {
  const dayOfWeek = ['日', '月', '火', '水', '木', '金', '土'][date?.day()];
  const year = feedStartDate.year();
  if (year && date?.year() < year) {
    // 年をまたぐ場合は年も表示
    return date.format(`YYYY/M/D(${dayOfWeek})`);
  }
  return date?.format(`M/D(${dayOfWeek})`);
};

export function formatUserName(
  userName: string,
  isDeleted = false,
  maxNameLength = 8,
): string | undefined {
  if (!userName) return;
  let formattedName = userName;
  if (userName.length > maxNameLength) {
    formattedName = userName.slice(0, maxNameLength) + '⋯';
  }
  if (isDeleted) {
    formattedName += ' (削除済み)';
  }
  return formattedName;
}

export function formatThemeName(themeName: string, isDeleted: boolean): string {
  if (isDeleted) {
    themeName += ' (削除済み)';
  }
  return themeName;
}
