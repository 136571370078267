import { Module } from 'vuex';

export type HeaderState = {
  isSticky: boolean;
};

export const initialHeaderState: HeaderState = {
  isSticky: false,
};

const header: Module<HeaderState, unknown> = {
  namespaced: true,
  state: () => initialHeaderState,
  mutations: {
    changeIsSticky(state, payload) {
      state.isSticky = payload;
    },
  },
};

export default header;
