import { Module } from 'vuex';
import api from '@/api';
import { ThemeList } from '@/types';

export type ThemeListState = {
  themes: ThemeList[];
  hasFetchedThemes: boolean;
};

export const initialThemeListState: ThemeListState = {
  themes: [],
  hasFetchedThemes: false,
};

const themes: Module<ThemeListState, unknown> = {
  namespaced: true,
  state: () => initialThemeListState,
  actions: {
    async fetchThemeList({ commit }, feedType) {
      const themes = await api.fetchThemeList(feedType);
      commit('setThemes', themes);
    },
  },
  mutations: {
    setThemes(state, themes) {
      state.themes = themes;
      state.hasFetchedThemes = true;
    },
    updateThemes(state, themes) {
      Object.assign(state.themes, themes);
    },
    updateTheme(state, theme) {
      const themes = state.themes.slice();
      const targetIndex = themes.map(theme => theme.id).indexOf(theme.id);
      themes[targetIndex] = theme;
      state.themes = themes;
    },
  },
  getters: {
    activeThemes(state) {
      return state.themes.filter(t => !t.is_deleted);
    },
    favoriteThemes(state) {
      return state.themes.filter(t => !t.is_deleted && t.is_favorite);
    },
    notFavoriteThemes(state) {
      return state.themes.filter(t => !t.is_deleted && !t.is_favorite);
    },
    memberThemes(state) {
      return state.themes.filter(t => !t.is_deleted && t.is_member);
    },
  },
};

export default themes;
