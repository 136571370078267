import axios from 'axios';

export async function createOrganizationTag(
  name: string,
): Promise<{ isSuccess: true } | { isSuccess: false; message: string }> {
  try {
    await axios.post<void>(`/organization_tags`, {
      name,
    });
    return { isSuccess: true };
  } catch (e) {
    if (axios.isAxiosError(e)) {
      if (
        e.response?.status === 400 ||
        e.response?.status === 404 ||
        e.response?.status === 500
      ) {
        return {
          isSuccess: false,
          message: e.response.data.error || '組織タグの作成に失敗しました',
        };
      }
    }

    if (e instanceof Error) {
      throw e;
    }

    throw new Error('予期しないエラーが発生しました');
  }
}

export async function deleteOrganizationTagUser({
  organizationTagId,
  userId,
}: {
  organizationTagId: number;
  userId: number;
}): Promise<{ isSuccess: true } | { isSuccess: false; message: string }> {
  try {
    await axios.delete<void>(
      `/organization_tags/${organizationTagId}/users/${userId}`,
    );
    return { isSuccess: true };
  } catch (e) {
    if (axios.isAxiosError(e)) {
      if (e.response?.status === 404 || e.response?.status === 500) {
        return { isSuccess: false, message: e.response.data.error };
      }
    }

    if (e instanceof Error) {
      throw e;
    }

    throw new Error('予期しないエラーが発生しました');
  }
}

export async function addOrganizationTagUsers({
  organizationTagId,
  userIds,
}: {
  organizationTagId: number;
  userIds: number[];
}): Promise<{ isSuccess: true } | { isSuccess: false; message: string }> {
  try {
    await axios.post<void>(`/organization_tags/${organizationTagId}/users`, {
      user_ids: userIds,
    });
    return { isSuccess: true };
  } catch (e) {
    if (axios.isAxiosError(e)) {
      if (
        e.response?.status === 400 ||
        e.response?.status === 404 ||
        e.response?.status === 500
      ) {
        return { isSuccess: false, message: e.response.data.error };
      }
    }

    if (e instanceof Error) {
      throw e;
    }

    throw new Error('予期しないエラーが発生しました');
  }
}

export async function bulkOperateOrganizationTags({
  deleteTagIds,
  updateTags,
  newTags,
}: {
  deleteTagIds?: number[];
  updateTags?: {
    id: number;
    name?: string;
    order?: number;
  }[];
  newTags?: {
    name: string;
    order?: number;
  }[];
}): Promise<{ isSuccess: true } | { isSuccess: false; message: string }> {
  try {
    await axios.post<void>(`/organization_tags/bulk_operations`, {
      delete_tag_ids: deleteTagIds,
      update_tags: updateTags,
      new_tags: newTags,
    });
    return { isSuccess: true };
  } catch (e) {
    if (axios.isAxiosError(e)) {
      if (
        e.response?.status === 400 ||
        e.response?.status === 404 ||
        e.response?.status === 500
      ) {
        return { isSuccess: false, message: e.response.data.error };
      }
    }

    if (e instanceof Error) {
      throw e;
    }

    throw new Error('予期しないエラーが発生しました');
  }
}
