<script lang="ts" setup>
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import { DgrIcon } from '@stockmarkteam/donguri-ui';
import ActionBalloon from '@/components/common/molecules/balloon.vue';
import SearchBar from '@/components/common/molecules/search-bar.vue';
import OrganizationTagDeleteModal from '@/components/modals/organization-tag-delete-modal.vue';
import OrganizationTagEditModal from '@/components/modals/organization-tag-edit-modal.vue';
import { OrganizationTag } from '@/types';
import { useIsAdmin } from '@/utils/user';
import ButtonEditOrganizationTagList from './button-edit-organization-tag-list.vue';
import SidePeak from './side-peak.vue';

interface Props {
  organizationTagList: Array<OrganizationTag>;
}
const props = withDefaults(defineProps<Props>(), {
  organizationTagList: () => [],
});

interface Emits {
  (e: 'onEdit'): () => void;
  (e: 'dataRefresh'): () => void;
}
const emit = defineEmits<Emits>();

const searchQuery = ref<string>('');
const selectedMenuIndex = ref<number>();
const currentOpenModalType = ref<'edit' | 'delete' | undefined>(undefined);
const selectedOrganizationTag = ref<OrganizationTag | undefined>(undefined);

/**
 * order順に並び替えた組織タグリスト
 */
const organizationTagListData = computed(() =>
  [...props.organizationTagList].sort((a, b) => a.order - b.order),
);

const filterOrganizationTagListData = computed(() => {
  return organizationTagListData.value.filter(organizationTag => {
    return organizationTag.name
      .toLowerCase()
      .includes(searchQuery.value.toLowerCase());
  });
});

const tagIdOnSidePeak = ref<number | null>(null);

/**
 * 編集ボタンの非活性状態
 */
const disableEditButton = computed(() => {
  // 表示対象の組織タグが1件以下で編集する対象がない場合は非活性にする
  return (
    organizationTagListData.value.length !==
    filterOrganizationTagListData.value.length
  );
});

/**
 * 指定の組織タグを編集するモーダルを開く
 */
const openEditOrganizationTagNameModal = (organizationTag: OrganizationTag) => {
  selectedOrganizationTag.value = organizationTag;
  currentOpenModalType.value = 'edit';
};

/**
 * 指定の組織タグを削除するモーダルを開く
 */
const openDeleteOrganizationTagModal = (organizationTag: OrganizationTag) => {
  selectedOrganizationTag.value = organizationTag;
  currentOpenModalType.value = 'delete';
};

const handleClickMemberCount = (tagId: number) => {
  tagIdOnSidePeak.value = tagId;
};

const handleChangeSearchQuery = (query: string) => {
  searchQuery.value = query;
};

const dataRefresh = () => {
  emit('dataRefresh');
};

onMounted(async () => {
  document.addEventListener('click', closeActionMenu);
});
onBeforeUnmount(() => document.removeEventListener('click', closeActionMenu));
const closeActionMenu = () => (selectedMenuIndex.value = undefined);

const selectMenu = (index: number) => {
  selectedMenuIndex.value = index;
};
const closeModal = () => {
  selectedOrganizationTag.value = undefined;
  currentOpenModalType.value = undefined;
};

const { isAdmin } = useIsAdmin();
</script>
<template>
  <div class="page-container">
    <div class="main">
      <div class="page-header">
        <div class="page-title">
          <span class="name">組織タグ</span>
          <span class="tag-count" data-testid="tag-count">
            ・{{ organizationTagListData.length }}件
          </span>
        </div>
        <ButtonEditOrganizationTagList
          :has-permission="isAdmin"
          :disable-edit-button="disableEditButton"
          @on-edit="emit('onEdit')"
        />
      </div>
      <div class="search-bar-container">
        <SearchBar
          @on-change-query="handleChangeSearchQuery"
          data-testid="search-bar"
          :placeholder="`組織タグを検索`"
        />
      </div>

      <div class="table-container">
        <div class="table-inner">
          <table class="table">
            <thead>
              <tr class="table-header-row">
                <th class="table-header-cell ta-l c-text c-text--s">
                  <span>組織タグ名</span>
                </th>
                <th width="80" class="table-header-cell ta-r c-text c-text--s">
                  <span>メンバー数</span>
                </th>
                <th v-if="isAdmin" width="48" class="table-header-cell">
                  <div>&nbsp;</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(
                  organizationTag, index
                ) in filterOrganizationTagListData"
                :key="organizationTag.id"
              >
                <td data-testid="tagname">
                  <span class="org-tagname">{{ organizationTag.name }}</span>
                </td>
                <td class="member-count-cell" data-testid="member-count">
                  <div class="cell-inner-block member-count-block">
                    <button
                      class="member-count"
                      type="button"
                      @click="handleClickMemberCount(organizationTag.id)"
                    >
                      <span>{{ organizationTag.member_count }}</span>
                    </button>
                  </div>
                </td>
                <td v-if="isAdmin">
                  <div class="cell-inner-block">
                    <div class="actions">
                      <button
                        class="action-button"
                        @click.stop="selectMenu(index)"
                      >
                        <DgrIcon name="ellipsis-h" />
                      </button>
                      <ActionBalloon
                        :balloon-position="`left bottom`"
                        :position="`fixed`"
                        :is-show="index === selectedMenuIndex"
                      >
                        <button
                          @click="
                            openEditOrganizationTagNameModal(organizationTag)
                          "
                          class="text-button c-text--m"
                        >
                          名前の編集
                        </button>
                        <button
                          @click="
                            openDeleteOrganizationTagModal(organizationTag)
                          "
                          class="text-button c-text--m"
                        >
                          削除
                        </button>
                      </ActionBalloon>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div v-if="tagIdOnSidePeak !== null" class="side-peak-container">
      <SidePeak
        :has-permission="isAdmin"
        :tag-id="tagIdOnSidePeak"
        @on-close="tagIdOnSidePeak = null"
      />
    </div>
  </div>
  <OrganizationTagEditModal
    :is-open="currentOpenModalType === 'edit'"
    :organization-tag-names="organizationTagListData"
    :organization-tag="selectedOrganizationTag"
    @on-close="closeModal"
    @on-submit="dataRefresh"
  />
  <OrganizationTagDeleteModal
    :is-open="currentOpenModalType === 'delete'"
    :organization-tag="selectedOrganizationTag"
    :organization-tags="organizationTagListData"
    @on-close="closeModal"
    @on-submit="dataRefresh"
  />
</template>
<style scoped lang="scss">
/** ボタンのスタイルリセット */
button {
  border: none;
}

.page-title {
  display: flex;
  align-items: center;
  justify-content: center;

  .name {
    font-size: 14px;
    line-height: 1.5;
    color: $color-gray1000;
  }

  .tag-count {
    font-size: 12px;
    font-weight: normal;
    color: $color-gray600;
  }
}

.page-container {
  display: flex;
  gap: 16px;
  height: 100%;
  padding-left: 24px;
  padding-bottom: 16px;
  box-sizing: border-box;
  overflow: auto;
}

.search-bar-container {
  margin-top: 10px;
  margin-bottom: 16px;
}
.main {
  width: 588px; // fixme: マジックナンバーの削除・レイアウトの共通化
  min-width: 400px; // サイドピークによる幅の変動を抑制
}
.page-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 16px;
}
.table-container {
  width: 100%;
  height: 100%;
  padding-bottom: 16px;
  box-sizing: border-box;
}
.table-inner {
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid $color-border;
  max-height: 100%;
  overflow: auto;
  padding: 0 16px;
}

.c-btn {
  height: fit-content;
  padding: 6px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table {
  width: 100%;
  color: $color-gray1000;
  border-radius: 4px;
  /** セル境界線を別に指定することでテーブルスクロールしたときに罫線の位置を保持する */
  border-collapse: separate;
  /** 線幅の隙間を埋める */
  border-spacing: 0 0;

  tr:first-child {
    td {
      padding-top: 12px;
    }
  }
  td {
    padding-bottom: 12px;
  }

  thead {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #fff;
  }
}

.cell-inner-block {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-content: center;
}

/** メンバー数は数値なので右寄せ */
.member-count-block {
  justify-content: flex-end;
}
.member-count-cell {
  padding-right: 12px;
}
.member-count {
  min-width: 32px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-decoration-line: underline;
  span {
    font-size: 14px;
  }
}

.side-peak-container {
  position: sticky;
  top: 24px;
  height: fit-content;
  max-height: 80%;
  flex: 1;
  display: flex;
  justify-content: flex-start;
}

.actions {
  width: 40px;
  height: 40px;
  padding-right: 0;
  display: flex;
  position: relative;
  flex-direction: column;
}
.action-button {
  display: flex;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: inherit;

  &:hover {
    border: none;
    background-color: $color-gray200;
  }
}
.action-menu {
  position: relative;
}
.action-menu-items {
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
  padding: 12px 0;
  background: #ffffff;
  border: 1px solid $color-border;
  border-radius: 4px;
  z-index: var(--z-action-menu);
}

.table-header-row {
  margin-bottom: 12px;
}
.table-header-cell {
  text-indent: 8px;
  padding: 12px 0;
  border-bottom: 1px solid $color-border;
}

/** 非活性入力要素風のspan */
.org-tagname {
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding: 8px 12px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  background-color: #f2f2f2;
  font-size: 14px;
}

.text-button {
  word-wrap: nowrap;
  white-space: nowrap;
  background: inherit;
  border: none;
  border-radius: 0;
  display: block;
  width: 100%;
  text-align: left;
  padding: 5px 12px;
  font-size: 14px;
  line-height: 1.5;
  height: auto;
  &:hover {
    border: 0px;
    background: $color-gray200;
  }
}

/** 汎化可能クラス */
.ta-l {
  text-align: left;
}
.ta-r {
  text-align: right;
}
</style>
