<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { DgrLoading } from '@stockmarkteam/donguri-ui';
import { useStore } from '@/utils/vue';

export default defineComponent({
  components: {
    DgrLoading,
  },
  setup() {
    const store = useStore();

    const headerText = computed(() => store.state.confirmationModal.headerText);
    const bodyText = computed(() => store.state.confirmationModal.bodyText);
    const confirmationType = computed(
      () => store.state.confirmationModal.confirmationType,
    );
    const btnText = computed(() => store.state.confirmationModal.btnText);
    const cancelBtnText = computed(
      () => store.state.confirmationModal.cancelBtnText,
    );
    const isBtnDisabled = computed(
      () => store.state.confirmationModal.isBtnDisabled,
    );
    const warningText = computed(
      () => store.state.confirmationModal.warningText,
    );
    const hover = ref(false);
    const isExecuting = ref(false);
    const isLoading = computed(
      () =>
        store.state.confirmationModal.shouldShowLoading && isExecuting.value,
    );

    const confirm = async () => {
      isExecuting.value = true;
      await store.dispatch('confirmationModal/executeAction');
      isExecuting.value = false;
      hideConfirmationModal();
    };

    const hideConfirmationModal = () => {
      store.commit('confirmationModal/hideConfirmationModal');
    };

    const bodyTextEmpty = computed(() => bodyText.value.length === 0);

    return {
      headerText,
      confirmationType,
      btnText,
      cancelBtnText,
      isBtnDisabled,
      warningText,
      bodyText,
      hover,
      isExecuting,
      isLoading,
      confirm,
      hideConfirmationModal,
      bodyTextEmpty,
    };
  },
});
</script>

<template>
  <div>
    <div v-if="isLoading" class="overlay">
      <DgrLoading />
    </div>

    <sm-dialog class="auto-height screen-center" @close="hideConfirmationModal">
      <template #header>
        <div class="m-header">
          <pre class="c-text c-text--m">{{ headerText }}</pre>
        </div>
      </template>
      <template #body>
        <div class="m-body">
          <pre class="m-body-text c-text c-text--m">{{ bodyText }}</pre>
          <pre
            v-if="warningText"
            class="m-body-text-warning c-text c-text--s"
            >{{ warningText }}</pre
          >
        </div>
      </template>
      <template #footer>
        <div class="m-footer" :class="{ empty: bodyTextEmpty }">
          <div class="m-footer-buttons">
            <button
              class="m-cancel-button c-btn c-btn--auto c-btnOutline"
              @click="hideConfirmationModal"
            >
              {{ cancelBtnText }}
            </button>
            <button
              class="m-create-button c-btn c-btn--auto"
              :class="{
                'c-btn--AnewsPrimary': confirmationType === 'submit',
                'c-btn--negative': confirmationType !== 'submit',
                disabled: isBtnDisabled || isExecuting,
              }"
              @click="isBtnDisabled || isExecuting ? undefined : confirm()"
            >
              {{ btnText }}
            </button>
          </div>
        </div>
      </template>
    </sm-dialog>
  </div>
</template>
<style lang="scss" scoped>
.m-header {
  display: flex;
  flex-direction: row;
}

.m-body {
  margin-left: -4px;
  margin-bottom: -12px;
  pre.m-body-text {
    white-space: pre-wrap;
  }
  pre.m-body-text-warning {
    white-space: pre-wrap;
    margin-top: 25px;
    color: $color-orange1000;
  }
}
.m-footer {
  &.empty {
    margin-top: -40px;
  }
  .m-footer-buttons {
    display: flex;
    flex-direction: row;
    .m-create-button,
    .m-cancel-button {
      width: 120px;
      outline: none;
    }
    .m-create-button {
      margin-left: 16px;
      margin-right: 0;
    }
  }
}
:deep(.dialog-body) {
  padding-top: 16px;
}
.overlay {
  position: fixed;
  top: 0%;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: var(--z-snackbar);
}
</style>
