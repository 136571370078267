<script lang="ts">
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import api from '@/api/index';
import { DgrIcon } from '@stockmarkteam/donguri-ui';
import GroupComment from '@/components/group/group-comment.vue';
import Content from '@/components/layouts/content.vue';
import Header from '@/components/layouts/header.vue';
import { Comment, Group, UserSettings as UserSettingsType } from '@/types';
import { isEmptyObject, Pagination } from '@/utils';
import { redirectUnauthorized } from '@/utils/mypage';
import {
  isMypageRestricted,
  mypageAccessScope,
} from '@/utils/mypageAccessScope';
import { useUserOrganizationTags } from '@/utils/swr';
import { STATES, useUserActionCount } from '@/utils/swr';
import { getTabs } from '@/utils/user';
import { userSession } from '@/utils/userSession';
import { useEmitter, useStore } from '@/utils/vue';

const LIMIT = 10;

export default defineComponent({
  components: {
    Header,
    Content,
    GroupComment,
    DgrIcon,
  },
  setup() {
    const store = useStore();
    const emitter = useEmitter();
    const router = useRouter();
    const route = useRoute();

    const userId = computed(() => userSession.getUserId() ?? undefined);
    const { data: userOrganizationTags } = useUserOrganizationTags(userId);

    const {
      data: userActionCount,
      state: actionCountState,
      mutate: mutateCount,
    } = useUserActionCount(userId, 'comment');

    const commentCount = computed(() => userActionCount.value?.count ?? 0);

    const userComments = computed(() => store.state.userActions.userComments);

    const targetUserInfo = computed(
      () => store.state.userActions.targetUserInfo,
    );
    const userInfo = computed(() => store.state.userInfo.userInfo);
    const groups = computed<Group[]>(() => store.state.groups.groups);

    const hasNoComments = computed(
      () =>
        actionCountState.value === STATES.SUCCESS && commentCount.value === 0,
    );

    const tabs = getTabs();

    onMounted(async () => {
      emitter.on('comment-created', (comment: Comment) => {
        store.dispatch('userActions/addUserComment', { comment });
        mutateCount();
      });
      emitter.on('comment-updated', (comment: Comment) => {
        store.dispatch('userActions/updateUserComment', {
          comment,
        });
      });

      emitter.on('comment-deleted', (id: Comment['id']) => {
        store.dispatch('userActions/deleteUserComment', { id });
        mutateCount();
      });

      redirectUnauthorized(userId.value, router);
      await setUp();
    });

    const targetUserSettings = ref<UserSettingsType | null>(null);
    const pagination = ref<Pagination | undefined>(undefined);

    const setUp = async () => {
      if (userId.value === undefined) {
        redirectUnauthorized(userId.value, router);
        return;
      }
      store.dispatch('userActions/fetchTargetUserInfo', userId.value);
      store.commit('userActions/resetUserComments');
      targetUserSettings.value = await api.getUserSettings(userId.value);
      const fetchComments = async (page: number, limit: number) => {
        await getFetchComments(userId.value!, page, limit);
      };
      if (pagination.value) {
        pagination.value.removeEvent();
      }
      pagination.value = new Pagination(fetchComments, LIMIT);
      await trackPageView();
    };

    const groupName = (groupId: Comment['group_id']) => {
      return groups.value?.find(g => g.id === groupId)?.name;
    };

    watch(
      () => userId.value,
      () => {
        redirectUnauthorized(userId.value, router);
        setUp();
      },
    );

    onBeforeUnmount(() => {
      pagination.value?.removeEvent();
      store.commit('userActions/resetUserComments');
      emitter.off('comment-created');
      emitter.off('comment-updated');
      emitter.off('comment-deleted');
    });

    const getFetchComments = async (
      userId: number,
      page: number,
      limit: number,
    ) => {
      const payload = {
        limit: limit,
        page: page,
        reset: false,
        userId: userId,
      };
      await store.dispatch('userActions/fetchUserComments', payload);
    };

    const trackPageView = async (): Promise<void> => {
      await api.trackPageView({
        pageName: 'user',
        pageUrl: route.fullPath,
      });
    };

    const mypageRestricted = computed<boolean>(() => {
      // マイページ公開制限
      if (!targetUserSettings.value) {
        return false;
      }
      if (isEmptyObject(targetUserInfo.value)) {
        return false;
      }

      return isMypageRestricted(
        userInfo.value,
        targetUserInfo.value,
        targetUserSettings.value,
      );
    });

    const isMypageAccessScope = computed(() => {
      // マイページ公開範囲
      if (!targetUserSettings.value) {
        return '';
      }

      return mypageAccessScope(targetUserSettings.value);
    });

    return {
      userComments,
      commentCount,
      targetUserInfo,
      userInfo,
      groups,
      hasNoComments,
      tabs,
      getTabs,
      groupName,
      mypageRestricted,
      isMypageAccessScope,
      userOrganizationTags,
    };
  },
});
</script>

<template>
  <div class="o-my-activity-feed">
    <Header
      :title="targetUserInfo.user_name"
      :detail="`${targetUserInfo.email} ${isMypageAccessScope}`"
      :avatar-image-url="targetUserInfo.image_url"
      :tabs="tabs"
      :organization-tag-list="userOrganizationTags?.organization_tags"
    ></Header>
    <Content>
      <template v-if="mypageRestricted">
        <div class="page-content">
          <div class="restrict-disclosure">
            <DgrIcon size="xlarge" name="lock" />{{ targetUserInfo.user_name }}
            さんはページの公開範囲を制限しています。
          </div>
        </div>
      </template>
      <template v-else>
        <div class="o-feed">
          <div class="o-caption c-title c-title--m">
            コメント<span class="c-text- c-text--s"
              >・{{ commentCount }} 件</span
            >
          </div>
          <div
            class="o-article-card"
            v-for="comment in userComments"
            :key="comment.id"
          >
            <GroupComment
              :comment="comment"
              :article="comment.article"
              :group-id="comment.group_id"
              :group-name="groupName(comment.group_id)"
              :show-group="true"
              page-name="user"
              :feature="'my_comments'"
              @reply="comment.children.push($event)"
            />
          </div>
          <div class="o-no-user-comments" v-if="hasNoComments">
            <div class="o-description c-text c-text--m">
              該当するコメントはありませんでした。
            </div>
            <img src="@/assets/state-empty-comment.png" />
          </div>
        </div>
      </template>
    </Content>
  </div>
</template>
<style lang="scss" scoped>
.o-my-activity-feed {
  width: 100%;
  margin: -24px 0 0 0;

  .o-feed {
    margin-top: 0px;
    width: 616px;

    .o-caption {
      margin-bottom: 16px;

      span {
        color: #b3b3b3;
      }
    }
  }

  .o-no-user-comments {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 616px;
    height: 212px;
    background: #ffffff;
    border: 1px solid #e6e6e6;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0 32px 0 48px;
    img {
      height: 200px;
      width: 200px;
    }
  }
  .o-article-card {
    margin-bottom: 10px;
  }
}

.restrict-disclosure {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 616px;
  height: 212px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0 32px 0 48px;
}
</style>
