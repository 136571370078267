<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { fetchGroupActivityDownload } from '@/api/group';
import { DgrIcon } from '@stockmarkteam/donguri-ui';
import GroupExportActivity from '@/components/group/group-export-activity.vue';
import {
  ExportFormatType,
  ExportGroupContentType,
  GroupActivityProps,
} from '@/types/index';
import { useOutsideClick } from '@/utils/composables/useOutsideClick';
import { downloadActionData } from '@/utils/downloadActionData';
import { useStore } from '@/utils/vue';

const props = defineProps<GroupActivityProps>();
const route = useRoute();
const store = useStore();

const isOpenExportModal = ref(false);
const exportErrorText = ref('');
const exportPanelRef = ref<Element | undefined>();
const openPanelBtnRef = ref<Element | undefined>();

const isExportLoading = computed(
  () => store.state.downloadActionData.isDownloadingMyActivity,
);

const closeExportModal = () => {
  exportErrorText.value = '';
  isOpenExportModal.value = false;
};

const isClickedOutside = useOutsideClick([exportPanelRef, openPanelBtnRef]);

watch(isClickedOutside, newValue => {
  if (newValue) {
    exportErrorText.value = '';
    closeExportModal();
  }
});

const exportSubmit = async (option: {
  selectedTypes: ExportGroupContentType[];
  exportType: ExportFormatType;
}) => {
  store.commit('downloadActionData/setIsDownloadingMyActivity', true);
  const { data, error } = await fetchGroupActivityDownload(
    props,
    option,
    route,
  );

  if (error) {
    if (error.message === 'largeError') {
      exportErrorText.value =
        'ダウンロードの上限（1万行）に達しています。お手数ですが、期間を分けてご利用ください。';
    } else {
      exportErrorText.value =
        'エラーが発生しました。しばらく時間をおいてお試しください。解消しない場合はサポートまでお問い合わせください。';
    }
    store.commit('downloadActionData/setIsDownloadingMyActivity', false);
    return;
  }

  const fileNames = ['action_data'];
  if (props.startDate) {
    fileNames.push(props.startDate);
  }
  if (props.endDate) {
    fileNames.push(props.endDate);
  }
  const extension = option.exportType === 'excel' ? 'xlsx' : 'csv';
  const filename = `${fileNames.join('_')}.${extension}`;
  await downloadActionData(filename, data ?? [], option.exportType);
  store.commit('downloadActionData/setIsDownloadingMyActivity', false);
  isOpenExportModal.value = false;
};
</script>

<template>
  <div class="dw-panel-container">
    <button
      class="dw-open-panel-btn dashboard-button"
      @click="() => (isOpenExportModal = !isOpenExportModal)"
      ref="openPanelBtnRef"
    >
      <DgrIcon size="small" name="download" class="c-btn__icon" />
      <div class="c-btn__text">ダウンロード</div>
      <DgrIcon size="small" name="angle-down" class="c-selectBox__arrow" />
    </button>
    <div ref="exportPanelRef" class="export-panel">
      <GroupExportActivity
        v-if="isOpenExportModal"
        :is-loading="isExportLoading"
        :error-text="exportErrorText"
        @on-submit="exportSubmit"
        @on-close="closeExportModal"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.dw-panel-container {
  position: relative;
}

.dw-open-panel-btn {
  display: flex;
  align-items: center;
}

.icon-box {
  margin-right: 8px;
}

.c-selectBox__arrow {
  margin-right: 0;
}

.export-panel {
  position: absolute;
  width: 600px;
  z-index: 100;
  right: 0;
}
</style>
