<script lang="ts">
export type ItemBaseType = {
  id: number;
};

export type Field<Item> = {
  name:
    | 'actions' // actionsはkeyof Itemになくても指定されることがある
    | (keyof Item extends string ? keyof Item : never); // keyof Itemのうちstring部分だけを取り出す
  displayName: string;
  type: 'string' | 'number' | 'date';
  isSortable: boolean;
  sorter?: (a: Item, b: Item) => number;
  minWidth?: number;
  fieldPosition?: 'left' | 'right' | 'center';
  cellPosition?: 'left' | 'right' | 'center';
  thClass?: string[];
};
</script>

<script setup lang="ts" generic="Item extends ItemBaseType">
import { computed, ref } from 'vue';

type FieldType = Field<Item>;

const props = withDefaults(
  defineProps<{
    fields: FieldType[];
    items: Item[];
    initialSortField?: FieldType;
    initialSortOrder?: 'asc' | 'desc';
    rowHeight?: 'normal' | 'thin';
    isStickyHeader?: boolean;
  }>(),
  {
    initialSortOrder: 'asc',
    rowHeight: 'normal',
    isStickyHeader: false,
  },
);

const sortData = (field: FieldType | undefined, order: 'asc' | 'desc') => {
  if (!field) return props.items;
  const orderMultiplier = order === 'asc' ? 1 : -1;
  return [...props.items].sort((a, b) => {
    if (field.name === 'actions') {
      return 0;
    } else if (field.sorter) {
      return field.sorter(a, b) * orderMultiplier;
    } else if (field.type === 'string') {
      return (
        orderMultiplier *
        (a[field.name] as string)
          .toLowerCase()
          .localeCompare((b[field.name] as string).toLowerCase())
      );
    } else if (field.type === 'date') {
      return (a[field.name] > b[field.name] ? 1 : -1) * orderMultiplier;
    } else {
      return (
        orderMultiplier *
        ((a[field.name] as number) - (b[field.name] as number))
      );
    }
  });
};

const sortField = ref(
  props.initialSortField ?? props.fields.find(f => f.isSortable),
);
const sortOrder = ref(props.initialSortOrder);

const sort = (fieldName: keyof Item) => {
  const field = props.fields.find(f => f.name === fieldName);
  if (!field || !field.isSortable || !sortField.value) return;

  if (field.name === sortField.value.name) {
    sortOrder.value = sortOrder.value === 'asc' ? 'desc' : 'asc';
  } else {
    // sortFieldのnameプロパティがUnwrapRef<...>型になってしまうのでasで回避
    sortField.value = field as typeof sortField.value;
    sortOrder.value = 'asc';
  }
};
const sortedData = computed(() =>
  // sortFieldのnameプロパティがUnwrapRef<...>型になってしまうのでasで回避
  sortData(sortField.value as FieldType | undefined, sortOrder.value),
);
</script>

<template>
  <div class="admin-table">
    <table class="admin-table-body" cellspacing="0">
      <thead :class="{ ['sticky-header']: isStickyHeader }">
        <tr>
          <th
            class="c-text c-text--s"
            v-for="field in fields"
            :key="field.name"
            :class="[
              ...(field.thClass ?? []),
              ...(field.isSortable ? ['sortable'] : []),
            ]"
            :style="{
              'min-width': field.minWidth + 'px',
              'text-align': field.fieldPosition,
            }"
            @click="field.name !== 'actions' && sort(field.name)"
            :data-testid="`admin-base-table-column-${field.name}`"
          >
            <slot :name="`header-${field.name}`" :item="field">
              <div>
                {{ field.displayName }}
                <template v-if="sortField && field.isSortable">
                  <span
                    class="sort-icon"
                    v-if="sortField.name === field.name && sortOrder === 'asc'"
                    >↑</span
                  ><span
                    class="sort-icon"
                    v-else-if="
                      sortField.name === field.name && sortOrder === 'desc'
                    "
                    >↓</span
                  >
                </template>
              </div>
            </slot>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="sortedData.length === 0">
          <td :colspan="fields.length">
            <slot name="empty-tbody" />
          </td>
        </tr>
        <tr v-for="row in sortedData" :key="row.id">
          <td
            v-for="col in fields"
            :key="col.name"
            :class="{ thin: rowHeight === 'thin' }"
            :style="{ 'text-align': col.cellPosition }"
          >
            <slot :name="`cell-${col.name}`" :item="row">
              <div v-if="col.name !== 'actions'">
                {{ row[col.name] }}
              </div>
            </slot>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style lang="scss" scoped>
.admin-table {
  overflow-x: auto;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
}
.admin-table-body {
  width: 100%;
  background: #fff;
  box-sizing: border-box;
  padding: 0px 16px 16px 16px;
  overflow-x: auto;
  border-radius: 4px;
  .sticky-header th {
    position: sticky;
    top: 0;
    z-index: 1;
    background: #fff;
  }

  th,
  td {
    text-align: left;
    border-bottom: 1px solid #e6e6e6;
    min-width: 100px;
  }
  td {
    font-size: 14px;
    height: 40px;
    padding: 8px;

    &.thin {
      height: 24px;
      padding: 2px 8px;
    }

    a:hover {
      text-decoration: underline;
    }
  }
  th {
    padding: 20px 8px 16px 8px;
    white-space: nowrap;

    &.sortable {
      cursor: pointer;
      &:hover {
        background: $color-gray200;
      }
    }
  }
  tbody tr {
    /* 利用元でアクションメニューを定義するときは.actionsクラスを指定する想定 */
    &:hover:not(:has(.actions:hover)) {
      background: $color-gray200;
    }
  }
  tr:last-child td {
    border-bottom: 0;
  }
  th,
  td:last-child {
    min-width: 16px;
  }

  .sort-icon {
    padding: 0 4px;
  }
}
</style>
