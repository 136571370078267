import axios from 'axios';
import {
  sfResponseItem,
  sfUseCaseCreationResponse,
} from '@/utils/labs/reports/types';

export async function startUseCase(
  solution: string,
  functionKeyword: string,
): Promise<sfUseCaseCreationResponse> {
  const response = await axios.post<sfUseCaseCreationResponse>(
    'labs/sfuc/sessions',
    {
      solution,
      function_keyword: functionKeyword,
    },
  );
  return response.data;
}

export async function getUseCase(
  sessionId: string,
  useCaseId: number,
): Promise<sfResponseItem> {
  const response = await axios.get<sfResponseItem>(
    `labs/sfuc/sessions/${sessionId}/${useCaseId}`,
  );
  return response.data;
}
