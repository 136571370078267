import {
  ContentsContext,
  createTrackingData,
  IndustryData,
  SearchData,
  TrackingBaseData,
} from '@/api/tracking';
import axios from 'axios';
import { AdpDocument, ArticleMarkSource, Mark } from '@/types';

export async function sendMark(
  adpDocument: AdpDocument,
  themeId: number | null,
  markSource: ArticleMarkSource,
  trackingBaseData: TrackingBaseData,
  rankInWholeFeed?: number,
  searchData?: SearchData,
  industryData?: IndustryData,
  description?: string,
  contentsContext?: ContentsContext,
  execution?: string,
): Promise<Mark> {
  const { data } = await axios.post<Mark>('/marks', {
    article_id: adpDocument.id,
    doc_type: adpDocument.doc_type ?? 'article',
    theme_id: themeId ?? undefined,
    source: markSource,
    tracking_data: createTrackingData({
      adpDocument,
      themeId: themeId ?? undefined,
      rankInWholeFeed,
      ...trackingBaseData,
      searchData,
      industryData,
      description,
      contentsContext,
      execution,
    }),
  });
  return data;
}

export async function updateMark(markId: number): Promise<Mark> {
  // content-length isn't set to request header without data parameter.
  // 411 error occurs depending on some customer's environment, so an empty object is set.
  const { data } = await axios.patch<Mark>(`/marks/${markId}`, {});
  return data;
}

export async function deleteMark(markId: number): Promise<void> {
  await axios.delete<void>(`/marks/${markId}`);
}
