<script setup lang="ts">
import { ref } from 'vue';
import userSelectedIcon from '@/assets/icon-user-primary.svg';
import userIcon from '@/assets/icon-user.svg';
import usersSelectedIcon from '@/assets/icon-users-primary.svg';
import usersIcon from '@/assets/icon-users.svg';
import { MentionItem } from '@/types';

interface Props {
  top: number;
  left: number;
  mentionList: MentionItem[];
  activeIndex: number;
}

const props = withDefaults(defineProps<Props>(), {
  top: () => 0,
  left: () => 0,
  mentionList: () => [],
  activeIndex: () => 0,
});

type Emits = {
  'on-item-selected': [value: number];
  'on-mouse-enter': [value: number];
};

const emit = defineEmits<Emits>();

const mentionArea = ref<HTMLElement>();

const getIconPath = (mentionItem: MentionItem, isActive: boolean) => {
  if (mentionItem.iconName === 'user') {
    return isActive ? userSelectedIcon : userIcon;
  } else {
    return isActive ? usersSelectedIcon : usersIcon;
  }
};

const fixScrollTop = () => {
  if (!mentionArea.value) return;
  const itemHeight = 35;
  const offsetFromTop = 100;
  mentionArea.value.scrollTop =
    (props.activeIndex - 1) * itemHeight - offsetFromTop;
};

defineExpose({
  fixScrollTop,
});
</script>

<template>
  <ul
    ref="mentionArea"
    class="mention-list-mount-point"
    :style="{ top: top + 'px', left: left + 'px' }"
  >
    <li
      v-for="(mentionItem, index) in mentionList"
      :key="mentionItem.id"
      class="mention-list-item"
      :class="{ selected: activeIndex === index }"
      @click="emit('on-item-selected', index)"
      @mouseenter="emit('on-mouse-enter', index)"
    >
      <img :src="getIconPath(mentionItem, activeIndex === index)" /><span>{{
        mentionItem.textInComment
      }}</span
      >{{ mentionItem.textInCandidateList }}
    </li>
  </ul>
</template>

<style lang="scss" scoped></style>
