import { Module } from 'vuex';

export type SurveyState = {
  surveyProgress: Map<string, boolean>;
};

export const initialSurveyState: SurveyState = {
  surveyProgress: new Map<string, boolean>(),
};

export type SurveyProgress = {
  sessionId: string;
  inProgress: boolean;
};

const survey: Module<SurveyState, unknown> = {
  namespaced: true,
  state: () => initialSurveyState,
  mutations: {
    setSurveyProgress(
      state: SurveyState,
      { sessionId, inProgress }: SurveyProgress,
    ) {
      state.surveyProgress.set(sessionId, inProgress);
    },
  },
  getters: {
    isSurveyInProgress:
      (state: SurveyState) =>
      (sessionId: string): boolean => {
        return state.surveyProgress.get(sessionId) ?? false;
      },
  },
};

export default survey;
