import { LocationQuery, LocationQueryValue } from 'vue-router';
import type {
  DocType,
  NotificationBaseType,
  NotificationComment,
  NotificationCommentReactionType,
  NotificationDocument,
  NotificationGroup,
  NotificationTheme,
  WebPushCommentReactionNotification,
  WebPushForceThemeFavoriteNotification,
  WebPushGroupCommentNotification,
  WebPushGroupInvitationNotification,
  WebPushGroupMarkNotification,
  WebPushMentionNotification,
  WebPushNotificationType,
  WebPushReplyCommentNotification,
  WebPushThemeInvitationNotification,
} from '@/types';
import { getArticlePageUrl } from '@/utils';

// なかった場合は一旦home画面に遷移させる。基本的にはならない想定
export const UNDEFINED_PATH = '/';

function deserializeStringedBoolean(
  value: LocationQueryValue | LocationQueryValue[],
): boolean {
  if (value === 'true') return true;
  return false;
}

function deserializeStringedDocType(docType: string): DocType {
  switch (docType) {
    case 'article':
    case 'research_paper':
    case 'patent':
    case 'report':
      return docType;
    default:
      throw new Error('Invalid doc_type');
  }
}

/**
 * queryをobjectに変換する
 * @example data = 'id=1&name=2'
 * @return {id: '1', name: '2'}
 */
function deserializeQueriesToObject(
  data: LocationQueryValue | LocationQueryValue[],
): Record<string, string> | undefined {
  if (typeof data === 'string') {
    const searchParams = new URLSearchParams(data);
    const obj: Record<string, string> = {};
    searchParams.forEach((value, key) => {
      obj[key] = value;
    });
    return obj;
  }
}

function deserializePushedBaseData(
  pushedNotification: LocationQuery,
): Pick<NotificationBaseType, 'id' | 'user_id'> {
  return {
    id: Number(pushedNotification.id),
    user_id: Number(pushedNotification.user_id),
  };
}

function deserializePushedCreatorData(pushedNotification: LocationQuery): {
  creator_id: number;
  creator_name: string;
  creator_image_url: string;
  is_deleted_creator: boolean;
} {
  return {
    creator_id: Number(pushedNotification.creator_id),
    creator_name: pushedNotification.creator_name?.toString() ?? '',
    creator_image_url: pushedNotification.creator_image_url?.toString() ?? '',
    is_deleted_creator: deserializeStringedBoolean(
      pushedNotification.is_deleted_creator,
    ),
  };
}

function deserializePushedComment(
  comment: LocationQueryValue | LocationQueryValue[],
): NotificationComment {
  const commentObj = deserializeQueriesToObject(comment);

  return {
    id: Number(commentObj?.id),
    content: commentObj?.content ?? '',
  };
}

function deserializePushedGroup(
  group: LocationQueryValue | LocationQueryValue[],
): NotificationGroup {
  const groupObj = deserializeQueriesToObject(group);
  return {
    id: Number(groupObj?.id),
    name: groupObj?.name ?? '',
  };
}

function deserializePushedDocument(
  document: LocationQueryValue | LocationQueryValue[],
): NotificationDocument {
  const documentObj = deserializeQueriesToObject(document);
  return {
    id: Number(documentObj?.id),
    title: documentObj?.title ?? '',
    url: documentObj?.url ?? '',
    doc_type: deserializeStringedDocType(documentObj?.doc_type ?? ''),
  };
}

function deserializeTargetReaction(
  document: LocationQueryValue | LocationQueryValue[],
): NotificationCommentReactionType['target_comment_reaction'] {
  const documentObj = deserializeQueriesToObject(document);
  return {
    id: Number(documentObj?.id),
    reaction: documentObj?.reaction ?? '',
  };
}

function deserializeCommentReactions(
  document: LocationQueryValue | LocationQueryValue[],
): NotificationCommentReactionType['comment_reactions'] {
  // NOTE: 他のtypeと違いkeyが動的な絵文字のため関数を別途使用している
  if (typeof document === 'string') {
    const searchParams = new URLSearchParams(document);
    const obj: Record<string, number> = {};
    searchParams.forEach((value, key) => {
      obj[key] = Number(value);
    });
    return obj;
  } else {
    return {};
  }
}

function deserializePushedTheme(
  theme: LocationQueryValue | LocationQueryValue[],
): NotificationTheme {
  const themeObj = deserializeQueriesToObject(theme);
  return {
    id: Number(themeObj?.id),
    name: themeObj?.name ?? '',
    is_deleted: deserializeStringedBoolean(themeObj?.is_deleted ?? ''),
  };
}

/** queryをobjectに変換する */
export function deserializePushedNotification(
  pushedNotification: LocationQuery,
): WebPushNotificationType {
  switch (pushedNotification.type) {
    case 'mention': {
      const notification: WebPushMentionNotification = {
        type: 'mention',
        ...deserializePushedBaseData(pushedNotification),
        ...deserializePushedCreatorData(pushedNotification),
        comment: deserializePushedComment(pushedNotification.comment),
        group: deserializePushedGroup(pushedNotification.group),
        ...(pushedNotification.document && {
          document: deserializePushedDocument(pushedNotification.document),
        }),
      };
      return notification;
    }

    case 'reply_comment': {
      const notification: WebPushReplyCommentNotification = {
        type: 'reply_comment',
        ...deserializePushedBaseData(pushedNotification),
        ...deserializePushedCreatorData(pushedNotification),
        comment: deserializePushedComment(pushedNotification.comment),
        group: deserializePushedGroup(pushedNotification.group),
        ...(pushedNotification.document && {
          document: deserializePushedDocument(pushedNotification.document),
        }),
      };
      return notification;
    }

    case 'group_invitation': {
      const notification: WebPushGroupInvitationNotification = {
        type: 'group_invitation',
        ...deserializePushedBaseData(pushedNotification),
        ...deserializePushedCreatorData(pushedNotification),
        group: deserializePushedGroup(pushedNotification.group),
      };
      return notification;
    }

    case 'comment_reaction': {
      const notification: WebPushCommentReactionNotification = {
        type: 'comment_reaction',
        ...deserializePushedBaseData(pushedNotification),
        ...deserializePushedCreatorData(pushedNotification),
        comment: deserializePushedComment(pushedNotification.comment),
        group: deserializePushedGroup(pushedNotification.group),
        ...(pushedNotification.document && {
          document: deserializePushedDocument(pushedNotification.document),
        }),
        comment_reactions: deserializeCommentReactions(
          pushedNotification.comment_reactions,
        ),
        target_comment_reaction: deserializeTargetReaction(
          pushedNotification.target_comment_reaction,
        ),
      };
      return notification;
    }

    case 'theme_invitation': {
      const notification: WebPushThemeInvitationNotification = {
        type: 'theme_invitation',
        ...deserializePushedBaseData(pushedNotification),
        ...deserializePushedCreatorData(pushedNotification),
        theme: deserializePushedTheme(pushedNotification.theme),
      };
      return notification;
    }

    case 'force_theme_favorite': {
      const notification: WebPushForceThemeFavoriteNotification = {
        type: 'force_theme_favorite',
        ...deserializePushedBaseData(pushedNotification),
        ...deserializePushedCreatorData(pushedNotification),
        theme: deserializePushedTheme(pushedNotification.theme),
      };
      return notification;
    }

    case 'group_comment': {
      const notification: WebPushGroupCommentNotification = {
        type: 'group_comment',
        ...deserializePushedBaseData(pushedNotification),
        ...deserializePushedCreatorData(pushedNotification),
        comment: deserializePushedComment(pushedNotification.comment),
        group: deserializePushedGroup(pushedNotification.group),
        ...(pushedNotification.document && {
          document: deserializePushedDocument(pushedNotification.document),
        }),
      };
      return notification;
    }

    case 'group_mark': {
      const notification: WebPushGroupMarkNotification = {
        type: 'group_mark',
        ...deserializePushedBaseData(pushedNotification),
        ...deserializePushedCreatorData(pushedNotification),
        group: deserializePushedGroup(pushedNotification.group),
        document: deserializePushedDocument(pushedNotification.document),
      };
      return notification;
    }

    default:
      throw new Error('Invalid type');
  }
}

// WARN: 通知のURLを変更するときはnotifications.vueの変更漏れがないかも確認する
export function buildPathToNotificationTarget(
  notification: WebPushNotificationType,
): string {
  switch (notification.type) {
    case 'mention':
      return notification.document
        ? getArticlePageUrl(notification.document, notification.comment.id)
        : `/comments/${notification.comment.id}`;
    case 'reply_comment':
      return notification.document
        ? getArticlePageUrl(notification.document, notification.comment.id)
        : `/comments/${notification.comment.id}`;
    case 'group_invitation':
      return `/groups/${notification.group.id}`;
    case 'comment_reaction':
      return notification.document
        ? getArticlePageUrl(notification.document, notification.comment?.id)
        : `/comments/${notification.comment?.id}`;
    case 'theme_invitation':
      return notification.theme.is_deleted
        ? UNDEFINED_PATH
        : `/themes/${notification.theme.id}`;
    case 'force_theme_favorite':
      return notification.theme.is_deleted
        ? UNDEFINED_PATH
        : `/themes/${notification.theme.id}`;
    case 'group_comment':
      return `/comments/${notification.comment.id}`;
    case 'group_mark':
      return `/groups/${notification.group.id}/marks?doc_type=${notification.document.doc_type}&document_id=${notification.document.id}`;
    default:
      return UNDEFINED_PATH;
  }
}
