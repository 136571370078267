<script lang="ts">
import { defineComponent } from 'vue';
import { DgrIcon } from '@stockmarkteam/donguri-ui';

export default defineComponent({
  components: {
    DgrIcon,
  },
  props: {
    isChinese: { type: Boolean, default: false },
  },
  setup() {
    return {};
  },
});
</script>

<template>
  <div class="theme-initial-state">
    <div class="title">
      <DgrIcon name="check-circle" :keep-fill="false" />
      <div class="spacing-08" />
      <div class="c-title c-title--xm">テーマが作成されました</div>
    </div>
    <div class="ready-image">
      <img width="120" height="120" src="@/assets/loading2.gif" />
    </div>
    <div class="ready-message">
      <div class="c-text c-text--xl">準備しています...</div>
      <div class="c-text c-text--m">(5~10分)</div>
    </div>
    <div class="message">
      <div class="c-text c-text--m">
        <span v-if="!isChinese"
          >準備が終わるまで、昨日までの記事を確認してみましょう。<br
        /></span>
        ニュースは<span class="green-600">閲覧</span>や<span class="green-600"
          >マーク</span
        >などを元に興味にあわせてお届けします。
      </div>
    </div>
    <div class="c-text c-text--m">
      <span class="favorite-message">お気に入りにするとメール配信対象</span
      ><span>になります。</span>
      <div class="favorite-icon">
        <DgrIcon name="star-fill" class="star" :keep-fill="false" />
        <DgrIcon name="long-arrow-right" />
        <DgrIcon name="envelop" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.theme-initial-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  padding: 24px 16px;
  margin-bottom: 24px;
  border: 1px solid $color-gray400;
  border-radius: 4px;
  box-sizing: border-box;

  .green-600 {
    color: $color-green600;
  }

  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-green600;
    fill: $color-green600;
  }

  .message {
    text-align: center;
  }
  .ready-image {
    margin-bottom: 24px;
  }

  .ready-message {
    text-align: center;
    margin-bottom: 24px;
  }
  .favorite-message {
    font-weight: bold;
  }

  .favorite-icon {
    display: flex;
    justify-content: space-evenly;
    margin: 16px 30% 0 30%;

    .star {
      fill: #ef811f; /* donguri-uiで定義されているastartegy-primaryの色 */
    }
  }
}
</style>
