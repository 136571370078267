<script lang="ts" setup>
import { computed } from 'vue';

const props = defineProps<{
  maxLength: number;
  text: string;
  customErrorMessage?: string;
  maxLengthErrorMessage?: string;
}>();

const errorMessage = computed(() => {
  if (props.customErrorMessage) {
    return props.customErrorMessage;
  }
  if (props.text.length > props.maxLength) {
    const validateMessage = props.maxLengthErrorMessage
      ? props.maxLengthErrorMessage
      : `${props.maxLength}文字以内で入力してください`;
    return validateMessage;
  }
  return '';
});
</script>
<template>
  <div class="text-counter">
    <div
      class="text-counter-container"
      :class="{ 'text-counter-error': errorMessage }"
    >
      <span v-if="errorMessage" class="text-counter-error-message">
        {{ errorMessage }}
      </span>
      <span>
        {{ text.length + '/' + maxLength }}
      </span>
    </div>
  </div>
</template>
<style scoped lang="scss">
.text-counter {
  display: flex;
  justify-content: flex-end;
  font-size: 10px;
  margin-top: 4px;
  color: $color-gray600;

  .text-counter-container {
    margin-left: 5px;
  }
  .text-counter-error {
    color: $color-orange1000;

    .text-counter-error-message {
      margin-right: 5px;
    }
  }
}
</style>
