import { Module } from 'vuex';

export type DownloadActionDataState = {
  isDownloadingMyActivity: boolean;
  isDownloadingTeamActivity: boolean;
};

export const initialDownloadActionDataState: DownloadActionDataState = {
  isDownloadingMyActivity: false,
  isDownloadingTeamActivity: false,
};

const downloadActionData: Module<DownloadActionDataState, unknown> = {
  namespaced: true,
  state: {
    isDownloadingMyActivity:
      initialDownloadActionDataState.isDownloadingMyActivity,
    isDownloadingTeamActivity:
      initialDownloadActionDataState.isDownloadingTeamActivity,
  },

  mutations: {
    setIsDownloadingMyActivity(state, isDownloading) {
      state.isDownloadingMyActivity = isDownloading;
    },
    setIsDownloadingTeamActivity(state, isDownloading) {
      state.isDownloadingTeamActivity = isDownloading;
    },
  },
};

export default downloadActionData;
