import { Module } from 'vuex';

export type TagListModalInfo = {
  tagNameList: string[];
  contentTitle: string;
};

export const tagListModalInfoInitState: TagListModalInfo = {
  tagNameList: [],
  contentTitle: '',
};

const tagListModalInfo: Module<TagListModalInfo, unknown> = {
  namespaced: true,
  state: {
    tagNameList: [],
    contentTitle: '',
  },
  mutations: {
    setTagInfoList(state, payload: TagListModalInfo) {
      state.tagNameList = payload.tagNameList;
      state.contentTitle = payload.contentTitle;
    },
  },
};

export default tagListModalInfo;
