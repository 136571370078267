<script setup lang="ts">
import { computed } from 'vue';
import { MAX_TAG_NAME_LENGTH } from '@/constants';
import TextCounter from '@/components/common/molecules/text-counter.vue';

type Props = {
  errorMessage?: string;
  maxLengthErrorMessage: string;
};

const props = defineProps<Props>();

const modelValue = defineModel<string>({
  required: true,
});

const emit = defineEmits<{
  keypressEnter: [{ value: string }];
  'update:modelValue': [value: string];
}>();

const addItem = () => {
  emit('keypressEnter', {
    value: modelValue.value,
  });
};

const isValidate = computed(() => {
  return props.errorMessage || modelValue.value.length > MAX_TAG_NAME_LENGTH;
});
</script>

<template>
  <div>
    <div class="c-formBlock__text c-formBlock__text--error" v-if="errorMessage">
      {{ errorMessage }}
    </div>
    <input
      class="m-input-tag__input c-textInput"
      :class="{ 'c-formInput--error': isValidate }"
      type="text"
      v-model.trim="modelValue"
      @keypress.enter="addItem"
    />
    <TextCounter
      :max-length="MAX_TAG_NAME_LENGTH"
      :text="modelValue"
      :max-length-error-message="maxLengthErrorMessage"
    />
  </div>
</template>

<style scoped lang="scss">
input:focus {
  outline: none;
}
</style>
