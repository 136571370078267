<script lang="ts">
import { computed, defineComponent, onMounted, ref } from 'vue';
import { DgrIcon } from '@stockmarkteam/donguri-ui';
import PickedUpArticles from '@/components/home/picked-up-articles.vue';
import { useThemeKeywordFeedCounts, useThemeList } from '@/utils/swr';
import { useStore } from '@/utils/vue';

export default defineComponent({
  components: {
    PickedUpArticles,
    DgrIcon,
  },
  setup() {
    const store = useStore();
    const feedType = computed(() => store.state.feedType.feedType);

    const { data: themeKeywordFeedCounts } =
      useThemeKeywordFeedCounts(feedType);

    const getThemeKeywordFeedCount = (themeId: number) =>
      themeKeywordFeedCounts.value?.theme_keyword_feed_counts[
        String(themeId)
      ] ?? 0;

    const { data: themes } = useThemeList(feedType);
    const favoriteThemes = computed(() =>
      [...(themes.value?.themes ?? [])]
        .filter(t => !t.is_deleted && t.is_favorite)
        .sort((a, b) => a.order - b.order),
    );

    let feedObserver: IntersectionObserver | undefined = undefined;
    const feedRefs = ref<HTMLElement[] | undefined>();
    const visibleFeeds = ref<Record<number, boolean>>({});
    const initializeFeedsObserver = () => {
      feedObserver?.disconnect();
      feedObserver = new IntersectionObserver(
        (entries, observer) => {
          entries.forEach(entry => {
            const id = Number((entry.target as HTMLElement).dataset.themeId);
            if (entry.intersectionRatio > 0) {
              visibleFeeds.value[id] = true;
              observer.unobserve(entry.target);
            }
          });
        },
        { threshold: 0 },
      );
      feedRefs.value?.forEach(el => {
        feedObserver?.observe(el);
      });
    };

    onMounted(async () => {
      initializeFeedsObserver();
    });

    return {
      favoriteThemes,
      getThemeKeywordFeedCount,
      feedRefs,
      visibleFeeds,
    };
  },
});
</script>
<template>
  <div class="o-picked-up-feeds">
    <div class="o-themes" v-if="favoriteThemes.length > 0">
      <div
        class="o-theme"
        v-for="theme in favoriteThemes"
        :key="theme.id"
        ref="feedRefs"
        :data-theme-id="theme.id"
      >
        <div class="o-theme-label">
          <router-link
            class="o-theme-name"
            :to="{ name: 'themeFeed', params: { themeId: theme.id } }"
          >
            <DgrIcon size="small" name="hashtag" class="o-theme-hash" />
            <div class="o-theme-name-text c-text c-text--l">
              {{ theme.name }}
            </div>
            <div class="o-total-article-count c-text c-text--m">
              ・{{ getThemeKeywordFeedCount(theme.id) }}件
            </div>
          </router-link>
        </div>
        <PickedUpArticles
          v-if="visibleFeeds[theme.id]"
          :theme="theme"
        ></PickedUpArticles>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.o-picked-up-feeds {
  .o-themes {
    .o-theme {
      &:not(:first-child) {
        margin-top: 16px;
      }

      .o-theme-label {
        width: fit-content;
        .o-theme-name {
          margin-bottom: 8px;
          width: fit-content;
          padding: 4px 8px;
          border-radius: 4px;
          .o-theme-name-text {
            &:hover {
              text-decoration: underline;
            }
          }
        }
        .o-total-article-count {
          color: #b3b3b3;
        }
      }
    }
  }
}

.o-theme-name {
  color: #4a4a4a;
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>
