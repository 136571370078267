<script lang="ts">
import { defineComponent } from 'vue';
import { ANDROID_STORE_URL, IOS_STORE_URL } from '@/constants';

export default defineComponent({
  setup() {
    const iosUrl = IOS_STORE_URL;
    const androidUrl = ANDROID_STORE_URL;
    return { iosUrl, androidUrl };
  },
});
</script>

<template>
  <div class="main">
    <div class="main-header">
      <div><img class="anews-logo" src="@/assets/logo_anews.png" /></div>
    </div>
    <div class="main-body">
      <div class="title c-title c-title--l">アプリでもっと手軽に！</div>
      <div class="subtitle">
        <span>アプリのダウンロードすると</span
        ><span>Anewsをモバイル利用できます。</span>
      </div>
      <img class="app-logo" src="@/assets/app-logo.svg" />
      <div class="download-buttons">
        <a
          class="download-button ios"
          :href="iosUrl"
          target="_blank"
          rel="noopener noreferrer"
          ><img src="@/assets/app-download-apple.svg" /></a
        ><a
          class="download-button android"
          :href="androidUrl"
          target="_blank"
          rel="noopener noreferrer"
          ><img src="@/assets/app-download-google.png"
        /></a>
      </div>
    </div>
    <div class="main-footer">
      <div class="main-footer-content">
        <a
          href="https://stockmark.co.jp/"
          target="_blank"
          rel="noopener noreferrer"
          ><img class="logo-stockmark" src="@/assets/logo_stockmark.png"
        /></a>
        <div class="footer-links">
          <a
            class="about-company c-title c-title--m"
            href="https://stockmark.co.jp/company/"
            target="_blank"
            rel="noopener noreferrer"
            >会社概要</a
          ><a
            class="about-anews c-title c-title--m"
            href="https://stockmark.co.jp/product/anews/"
            target="_blank"
            rel="noopener noreferrer"
            >Anewsについて</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.main {
  .main-header {
    height: $header-height;
    width: 100%;
    border-bottom: 1px solid #e6e6e6;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    box-shadow: 0px 1px 5px rgba(74, 74, 74, 0.25);
    img.anews-logo {
      height: 32px;
      width: auto;
    }
  }

  .main-body {
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 0 60px 0;

    .title {
      margin-bottom: 12px;
    }

    .subtitle {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .app-logo {
      margin: 24px 0 32px 0;
    }

    .download-buttons {
      display: flex;
      .download-button {
        width: 132px;
        height: 48px;

        img {
          height: 100%;
          width: 100%;
        }

        &.android {
          width: 162px;
          height: 48px;
          margin-left: 16px;
        }
      }
    }
  }

  .main-footer {
    width: 100%;
    background: #ffffff;
    border-top: 1px solid #e6e6e6;
    display: flex;
    justify-content: center;
    .main-footer-content {
      padding-bottom: 26px;
      a.about-anews,
      a.about-company {
        color: #717171;
      }
      a.about-anews {
        margin-left: 16px;
      }
      img.logo-stockmark {
        height: 24px;
        width: auto;
        margin: 24px 0 16px 0;
      }
    }
  }
}
</style>
