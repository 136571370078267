<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { DgrIcon } from '@stockmarkteam/donguri-ui';
import LeftSectionMenu from '@/components/menu/left-section-menu.vue';
import SearchMenu from '@/components/menu/search-menu/search-menu.vue';
import AdminMenu from '@/components/menu/sections/admin-menu.vue';
import GroupMenu from '@/components/menu/sections/group-menu.vue';
import MyPageMenu from '@/components/menu/sections/my-page-menu.vue';
import NewsMenu from '@/components/menu/sections/news-menu.vue';
import OthersMenu from '@/components/menu/sections/others-menu.vue';
import SurveyMenu from '@/components/menu/sections/survey-menu.vue';
import { NavigationMenu } from '@/types';
import { useUserInfo } from '@/utils/swr';

interface Props {
  isMenuVisible: boolean;
  isExpanded: boolean;
}
defineProps<Props>();

defineEmits(['toggleIsFolded']);

const route = useRoute();

const { data: userInfo } = useUserInfo();

const isAdminPages = computed(() => route.meta.type === 'admin');
const isNewsPages = computed(
  () =>
    ['theme', 'themeSearch', 'themeCreate'].includes(
      route.meta.type as string, // includesがstringしか受け取れないのでasで型を指定
    ) ||
    route.meta.type === 'home' ||
    route.meta.type === 'article' ||
    route.meta.type === 'personalNews' ||
    route.name === 'teamFeaturedArticles' ||
    route.name === 'industryNews' ||
    route.name === 'teamMarks' ||
    route.name === 'followLists',
);
const isSearchPages = computed(() => route.meta.type === 'search');
const isMyPage = computed(() => {
  return route.meta.type === 'mypage' || route.meta.type === 'userSettings';
});
const isGroupPage = computed(() => {
  return route.meta.type === 'group';
});
const isSurveyPage = computed(() => {
  return route.meta.type === 'survey';
});
const isOtherPage = computed(() => {
  return route.meta.type === 'others';
});
const isExpandable = computed(() => {
  // 左サイドメニューにないページは折りたたみボタンを表示しない(一部pageのみ対応)
  // NOTE: https://anews.atlassian.net/browse/ANDEV-4421?focusedCommentId=39764
  switch (route.meta.type) {
    case 'notifications':
    case 'user':
      return false;
    default:
      return true;
  }
});
const activeMenu = computed<NavigationMenu>(() => {
  if (isAdminPages.value) {
    return 'admin';
  }
  if (isSearchPages.value) {
    return '';
  }
  if (isNewsPages.value || isSearchPages.value) {
    return 'news';
  }
  if (isMyPage.value) {
    return 'my_page';
  }
  if (isGroupPage.value) {
    return 'group';
  }
  if (isSurveyPage.value) {
    return 'survey';
  }
  if (isOtherPage.value) {
    return 'others';
  }
  return '';
});
</script>

<template>
  <div class="o-left-menu" v-if="userInfo">
    <LeftSectionMenu :active-menu="activeMenu"></LeftSectionMenu>
    <KeepAlive>
      <template v-if="isMenuVisible">
        <AdminMenu class="left-menu__wrapper" v-if="isAdminPages" />
        <SearchMenu class="left-menu__wrapper" v-else-if="isSearchPages" />
        <NewsMenu
          class="left-menu__wrapper"
          v-else-if="isNewsPages || isSearchPages"
        />
        <GroupMenu class="left-menu__wrapper" v-else-if="isGroupPage" />
        <MyPageMenu class="left-menu__wrapper" v-else-if="isMyPage" />
        <OthersMenu class="left-menu__wrapper" v-else-if="isOtherPage" />
        <SurveyMenu class="left-menu__wrapper" v-else-if="isSurveyPage" />
      </template>
    </KeepAlive>

    <button
      v-if="isExpandable"
      v-tooltip.top="!isExpanded ? '開いたままにする' : '折りたたむ'"
      class="left-menu-arrow c-text c-text--s"
      :class="{ 'visible-menu': isMenuVisible }"
      @click="$emit('toggleIsFolded')"
    >
      <DgrIcon
        size="small"
        :name="`chevron-${isExpanded ? 'left' : 'right'}`"
        class="small"
      />
    </button>
  </div>
</template>
<style lang="scss" scoped>
div.o-left-menu {
  display: flex;
  color: #4a4a4a;
}
.left-menu__wrapper {
  position: fixed;
  left: 80px;
}

.left-menu-arrow {
  list-style: none;
  cursor: pointer;
  position: absolute;
  bottom: 58px;
  left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  margin-bottom: 8px;
  background-color: white;
  border: none;

  &:hover {
    background: $color-link-menu-hover;
  }

  &.visible {
    visibility: visible;
  }

  &.visible-menu {
    left: 320px;
  }
}

.left-menu-arrow:hover {
  background-color: #f2f2f2;
}
</style>
