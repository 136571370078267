import sanitizeHtml from 'sanitize-html';

const defaultOptions: sanitizeHtml.IOptions = {
  allowedTags: ['a'],
  allowedAttributes: {
    a: ['href', 'target', 'style', 'class', 'rel'],
  },
};

export const sanitize = (
  dirtyHtml: string,
  options: sanitizeHtml.IOptions = defaultOptions,
): string => {
  return sanitizeHtml(dirtyHtml, options);
};

export const escapeHTML = (text: string): string => {
  return text
    .replaceAll('&', '&amp;')
    .replaceAll('<', '&lt;')
    .replaceAll('>', '&gt;')
    .replaceAll("'", '&#39;')
    .replaceAll('"', '&quot;');
};
