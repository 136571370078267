<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import api from '@/api';
import { MAX_TAG_NAME_LENGTH } from '@/constants';
import { isAxiosError } from 'axios';
import ArticleTagsInput from '@/components/article/article-tags-input.vue';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';

interface Props {
  isOpen: boolean;
  groupTagId: number;
  groupTagName: string;
}
const props = defineProps<Props>();

const emit = defineEmits<{
  close: [];
  submit: [
    {
      tagName: string;
      groupTagId: number;
    },
  ];
}>();

const close = () => {
  clearErrorMessage();
  emit('close');
};

const DUPLICATE_ERROR_MESSAGE = '同じ名称のグループタグがあります。';
const { createSnackbar } = useSnackbar();
const errorMessage = ref<string | undefined>(undefined);
const inputValue = ref(props.groupTagName);
const isLoading = ref(false);
const clearErrorMessage = () => {
  errorMessage.value = undefined;
};
watch(
  () => props.isOpen,
  () => (inputValue.value = props.groupTagName),
);
watch(() => inputValue.value, clearErrorMessage);

const isDisabled = computed(() => {
  return inputValue.value.length > MAX_TAG_NAME_LENGTH || isLoading.value;
});

const submit = async () => {
  if (isDisabled.value) return;
  if (inputValue.value === props.groupTagName) {
    errorMessage.value = DUPLICATE_ERROR_MESSAGE;
    return;
  }
  isLoading.value = true;

  try {
    if (inputValue.value === '') {
      errorMessage.value = 'グループタグを入力してください';
      return;
    }

    const response = await api.editGroupTag(props.groupTagId, inputValue.value);
    emit('submit', { tagName: inputValue.value, groupTagId: response.id });
    createSnackbar({
      message: 'グループタグを編集しました',
      type: 'success',
    });
    close();
  } catch (e) {
    if (isAxiosError(e) && e.response?.data['error_type'] === 'duplicate') {
      errorMessage.value = DUPLICATE_ERROR_MESSAGE;
    } else {
      createSnackbar({
        message: 'グループタグの編集に失敗しました',
        type: 'error',
      });
      throw e;
    }
  } finally {
    isLoading.value = false;
  }
};
</script>

<template>
  <Teleport to="body">
    <sm-dialog
      v-if="isOpen"
      class="no-padding screen-center auto-height"
      :show-header="true"
      @close="close"
    >
      <template #header>
        <p>グループタグを編集</p>
      </template>
      <template #body>
        <div class="modal-body">
          <div class="modal-content">
            <ArticleTagsInput
              v-model="inputValue"
              :error-message="errorMessage"
              :max-length-error-message="`${MAX_TAG_NAME_LENGTH}文字以内のグループタグが作成できます`"
            />
          </div>
          <div class="modal-buttons-container c-text c-text--m">
            <button
              type="button"
              class="o-cancel-button c-btn c-btn--auto c-btnOutline"
              @click="close"
            >
              キャンセル
            </button>
            <button
              type="button"
              class="c-btn c-btn--auto c-btn--AnewsPrimary"
              :class="{ disabled: isDisabled }"
              :disabled="isDisabled"
              @click="submit"
            >
              保存
            </button>
          </div>
        </div>
      </template>
    </sm-dialog>
  </Teleport>
</template>

<style lang="scss" scoped>
.modal-body {
  padding: 16px;
  input {
    outline: none;
  }
  .modal-content {
    margin-bottom: 25px;
  }
  .modal-buttons-container {
    display: flex;
    justify-content: flex-end;
    :first-child {
      margin-right: 16px;
    }
  }
}
</style>
