<script setup lang="ts">
import { computed, onMounted, ref, toRefs, watch } from 'vue';
import { useRoute } from 'vue-router';
import { DOC_TYPE_LABELS } from '@/constants';
import { DgrIcon } from '@stockmarkteam/donguri-ui';
import SurveyTargetSourceFilter from '@/components/survey/input/survey-target-source-filter.vue';
import { DeprecatedSurveyInputValue, DocType, SearchScope } from '@/types';
import { useOutsideClick } from '@/utils/composables/useOutsideClick';
import { getDocTypesQueryParam } from '@/utils/survey/common';

interface Props {
  enableTransformInput?: boolean;
  isLoading?: boolean;
  initialSearchScope?: SearchScope;
  initialDocTypes?: Array<DocType | 'user_document'>;
}

/**
 * Props
 */

const props = withDefaults(defineProps<Props>(), {
  enableTransformInput: false,
  isLoading: false,
  initialSearchScope: 'all',
  initialDocTypes: () => [],
});

/**
 * Emits
 */

const emit = defineEmits<{
  submit: [DeprecatedSurveyInputValue];
}>();

/**
 * Local State
 */

const route = useRoute();

// フォームの値と操作
const textareaRef = ref<HTMLTextAreaElement>();
const inputText = ref<string>('');
const isComposing = ref(false); // IMEなどのテキスト変換システムが変換中かどうか

const { initialSearchScope, initialDocTypes } = toRefs(props);
const searchScope = ref<SearchScope>(props.initialSearchScope);
const docTypes = ref<Array<DocType | 'user_document'>>(props.initialDocTypes);

watch(
  initialSearchScope,
  newSearchScope => (searchScope.value = newSearchScope),
);
watch(initialDocTypes, newDocTypes => (docTypes.value = newDocTypes));

const textareaPlaceholder = computed(() =>
  route.name === 'surveyTop'
    ? 'なんでも質問してください'
    : 'キーワードを追加してさらに探索',
);
const enableSubmitButton = computed(() => {
  if (props.isLoading) return false;
  return inputText.value.trim().length > 0;
});
const popupDisplayPosition = computed<'top' | 'bottom'>(() => {
  if (route.name === 'surveySession') return 'top';
  return 'bottom';
});

// props.enableTransformInputがtrueの場合の、"生成"ボタンの位置調整に関する処理
const isTextareaFocused = ref<boolean>(false);
const containerRef = ref<HTMLDivElement>();
const submitButtonRef = ref<HTMLButtonElement>();
const isClickedOutsideInput = useOutsideClick([containerRef]);
const isClickedOutsideSubmitButton = useOutsideClick([submitButtonRef]);
const shouldButtonChangePosition = computed(() => {
  if (inputText.value) return false;
  // 絞り込みフィルターをクリックした際に、インプットが縮まらないようにするための考慮
  if (!isClickedOutsideInput.value && isClickedOutsideSubmitButton.value)
    return false;

  return props.enableTransformInput && !isTextareaFocused.value;
});

/**
 * Handlers
 */

const handleKeyDown = (event: KeyboardEvent) => {
  if (event.key === 'Enter' && !event.shiftKey && !isComposing.value) {
    event.preventDefault(); // 改行させないためにデフォルトのEnterキーの動作を防ぐ
    sendInput();
  }
};

const handleCompositionStart = () => {
  isComposing.value = true;
};

const handleCompositionEnd = () => {
  isComposing.value = false;
};

const sendInput = () => {
  if (props.isLoading) return; // survey実行中の場合は、新規でsurveyを開始させないようにイベントを発火しない

  emit('submit', {
    question: inputText.value,
    searchScope: searchScope.value,
    docTypes: docTypes.value,
  });
  inputText.value = '';
  if (textareaRef.value) {
    textareaRef.value.style.height = 'auto';
    textareaRef.value?.blur();
  }
  handleBlur();
  if (props.enableTransformInput) isClickedOutsideInput.value = true; // 入力欄が変形可能な場合は、submit時に入力欄を縮める
};

const removeSelectedDoctype = (docType: string) => {
  docTypes.value = docTypes.value.filter(value => value !== docType);
};

const applyFilter = (value: {
  searchScope: SearchScope;
  docTypes: string[];
}) => {
  searchScope.value = value.searchScope;
  docTypes.value = value.docTypes as Array<DocType | 'user_document'>;
};

const handleFocus = () => {
  isTextareaFocused.value = true;
};

const handleBlur = () => {
  isTextareaFocused.value = false;
};

const adjustTextareaHeight = (event: Event) => {
  const textarea = event.target as HTMLTextAreaElement;
  textarea.style.height = 'auto';
  textarea.style.height = `${textarea.scrollHeight}px`;
};

onMounted(() => {
  // 入力欄が変形可能な場合は、初期値をtrueにして縮めておく
  if (props.enableTransformInput) isClickedOutsideInput.value = true;

  const textarea = document.querySelector('textarea');
  if (textarea) {
    textareaRef.value = textarea as HTMLTextAreaElement;
  }

  if (route.name === 'surveySession') {
    // Topや検索から遷移した際に、絞り込みの内容を引き継ぐ
    docTypes.value = getDocTypesQueryParam(route.query.docTypes);
  }
});
</script>

<template>
  <div
    ref="containerRef"
    class="container"
    :class="{ 'fixed-bottom': props.enableTransformInput }"
  >
    <div class="input-section">
      <textarea
        class="c-text c-text--m"
        v-model="inputText"
        maxlength="200"
        @keydown="handleKeyDown"
        @compositionstart="handleCompositionStart"
        @compositionend="handleCompositionEnd"
        @input="adjustTextareaHeight"
        @focus="handleFocus"
        @blur="handleBlur"
        :placeholder="textareaPlaceholder"
        rows="1"
      ></textarea>
    </div>
    <div
      class="filter-submit-section"
      :class="{ 'hidden-filter-submit-section': shouldButtonChangePosition }"
    >
      <div class="source-filter" v-if="!shouldButtonChangePosition">
        <SurveyTargetSourceFilter
          v-model:search-scope="searchScope"
          v-model:doc-types="docTypes"
          :position="popupDisplayPosition"
          @apply-filter="applyFilter"
        />
        <div
          v-if="docTypes.length > 0"
          class="c-text c-text--m selected-values"
        >
          <div
            class="selected-value"
            v-for="docType in docTypes"
            :key="docType"
          >
            <p>
              {{ searchScope === 'user_marks' ? 'マークした' : ''
              }}{{ DOC_TYPE_LABELS[docType] }}
            </p>
            <DgrIcon
              @click="removeSelectedDoctype(docType)"
              class="delete-icon"
              name="times"
              :size="'xs'"
            />
          </div>
        </div>
      </div>
      <button
        ref="submitButtonRef"
        class="c-btn--AnewsPrimary"
        :class="{
          disabled: !enableSubmitButton,
          'change-position': shouldButtonChangePosition,
        }"
        :disabled="!enableSubmitButton"
        @click="sendInput"
      >
        <DgrIcon
          :size="'small'"
          :keep-fill="false"
          name="sparkles-fill"
          class="ai-icon"
        />生成
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.container {
  margin-top: 16px;
  width: 100%;
  background-color: $color-white;
  border-radius: 4px;

  // チャット形式のUIの画面では、入力欄を画面下部に固定する
  &.fixed-bottom {
    position: fixed;
    bottom: 16px;
    max-width: var(--survey-session-width);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1;
    // レスポンシブ対応
    width: -webkit-fill-available;
    width: -moz-available;
    margin-right: 32px;
  }

  .filter-submit-section {
    display: grid;
    grid-template-columns: auto auto;
    gap: 8px;
    place-items: center;
    justify-content: space-between;
    height: 64px;
    padding: 16px;
    white-space: nowrap;
    height: auto;

    .source-filter {
      display: flex;
      gap: 8px;

      .selected-values {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        margin: 0 8px;

        .selected-value {
          display: flex;
          gap: 8px;
          align-items: center;
          justify-content: center;
          border-radius: 100px;
          border: solid 1px $color-gray400;
          padding: 5px 12px;

          .delete-icon {
            cursor: pointer;
          }
        }
      }
    }

    button {
      padding: 8px 16px;
      cursor: pointer;
      align-self: flex-end;

      // NOTE: チャット形式のUIの画面では、入力欄を画面下部に固定する影響で画面縦幅が狭くなる
      // そのため、入力欄が空 かつ textareaにフォーカスがない場合は、入力欄を縮める ("生成"ボタンはtextareaの1行目の位置に配置)
      &.change-position {
        position: absolute;
        top: 16px;
        right: 16px;
      }
    }

    .ai-icon {
      margin-right: 3px;
      fill: $color-white;
    }
  }

  .input-section {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 10px;
    align-items: center;
    justify-content: start;
    height: auto;
    padding: 16px;

    textarea {
      width: 100%;
      outline: none;
      border: none;
      resize: none;
      min-height: 1.5em;
      max-height: 200px;
      overflow-y: auto;
      padding: 8px;
      box-sizing: border-box;
      grid-row: span 1;
    }

    ::placeholder {
      color: $color-gray600;
    }
  }

  // 高さをなくしてこのエリア自体ないように表示しつつ、元のスタイルを上書きしてボタンの位置を調整
  .hidden-filter-submit-section {
    height: 0;
    padding: 0;
    overflow: hidden;
    place-items: end;
  }
}
</style>
