<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { DgrIcon } from '@stockmarkteam/donguri-ui';
import GroupActionButtons from '@/components/group/group-action-buttons.vue';
import { Group } from '@/types';
import { isGroupAdmin } from '@/utils/group';
import { useUserInfo } from '@/utils/swr';

export default defineComponent({
  components: {
    GroupActionButtons,
    DgrIcon,
  },

  props: {
    group: { type: Object as PropType<Group>, required: true },
  },

  setup() {
    const { data: userInfo } = useUserInfo();

    return {
      userInfo,
      isGroupAdmin,
    };
  },
});
</script>

<template>
  <router-link class="m-group-search-card" :to="`/groups/${group.id}`">
    <div class="group-content">
      <div class="group-name c-title c-title--m">
        <DgrIcon size="small" name="users" class="small space-right" />{{
          group.name
        }}
        <div class="added" v-if="group.is_member">
          <DgrIcon size="xs" name="check" :keep-fill="false" /><span
            class="c-text c-text--xs"
            >参加中</span
          >
        </div>
      </div>
      <div class="group-info c-text c-text--s">
        <div class="admin" v-if="userInfo && isGroupAdmin(userInfo.role)">
          管理者・
        </div>
        <div class="member-count">メンバー{{ group.member_count }}人</div>
        <div class="description" v-if="group.description !== ''">
          ・{{ group.description }}
        </div>
      </div>
    </div>
    <div class="group-button">
      <GroupActionButtons :group="group"></GroupActionButtons>
    </div>
  </router-link>
</template>

<style lang="scss" scoped>
.m-group-search-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: white;
  border-radius: 4px;
  border: 1px solid $color-gray400;
  min-width: 500px;
  &:hover {
    border-color: $color-gray600;
    .group-button {
      display: inline-block;
    }
  }
  .group-content {
    width: calc(100% - 100px);
    .group-name {
      display: flex;
      align-items: center;
      .space-right {
        margin-right: 4px;
      }
      .added {
        display: flex;
        align-items: center;
        margin-left: 4px;
        span {
          color: $color-green600;
        }
        fill: $color-green600;
      }
    }
    .group-info {
      display: flex;
      align-items: center;
      color: $color-gray600;
      white-space: nowrap;
      .description {
        width: calc(100% - 100px);
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .group-button {
    display: none;
  }
}
</style>
