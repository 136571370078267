<script setup lang="ts">
import { computed, nextTick, ref, Ref } from 'vue';
import { DgrSelectbox } from '@stockmarkteam/donguri-ui';
import { Dayjs } from 'dayjs';
import ArticlesExecution from '@/components/personal-news/articles-execution.vue';
import { Article, Execution, FeedType, ThemeFeedType } from '@/types';
import {
  executionInformationForDate,
  isAfternoonFeedEnabled,
} from '@/utils/personalNews';

export type FeedData = {
  articles: Article[];
  isLoaded: boolean;
  isFetchingData: boolean;
  load: () => void;
};

export type MultipleExecutionFeedData = {
  morning: FeedData;
  afternoon: FeedData;
};

export type DateArticles = {
  date: Dayjs;
  dateStr: string;
  dateLabel: string;
  domestic: Ref<MultipleExecutionFeedData>;
  foreign: Ref<MultipleExecutionFeedData>;
};

type Props = {
  articlesByDate: DateArticles[];
  date: Dayjs;
  dateStr: string;
  dateLabel: string;
  sentFirstPersonalNewsFeedAt?: Dayjs;
  feedData: MultipleExecutionFeedData;
  removedArticleIds: number[];
  displayFeedType: FeedType;
  themeFeedType: ThemeFeedType;
};
const props = withDefaults(defineProps<Props>(), {
  articlesByDate: () => [],
  removedArticles: () => [],
});

const emit = defineEmits(['select-date']);

const feedArticles = computed(() => ({
  morning: props.feedData.morning.articles.filter(
    a => !props.removedArticleIds.includes(a.id),
  ),
  afternoon: props.feedData.afternoon.articles.filter(
    a => !props.removedArticleIds.includes(a.id),
  ),
}));

const articleCount = computed(
  () => feedArticles.value.morning.length + feedArticles.value.afternoon.length,
);

const currentDate = ref(props.dateStr);
const selectDate = (value: string | undefined) => {
  if (value === undefined) return;

  emit('select-date', value);

  // セレクトボックスの表示を強制的に戻すために、
  // 選択された値を代入してから元の値を入れ直して変更検知を走らせる
  currentDate.value = value;
  nextTick(() => {
    currentDate.value = props.dateStr;
  });
};

const dateOptions = computed(() =>
  props.articlesByDate.map(d => ({
    label: d.dateLabel,
    value: d.dateStr,
  })),
);

const previousTotalFeedCount = (
  date: Dayjs,
  feedType: ThemeFeedType,
  execution: Execution,
) => {
  const lengthList = props.articlesByDate
    .filter(d => d.date.isAfter(date))
    .map(
      d =>
        d[props.themeFeedType].value.morning.articles.length +
        d[props.themeFeedType].value.afternoon.articles.length,
    );
  const previousTotal =
    lengthList.length > 0 ? lengthList.reduce((sum, n) => sum + n) : 0;
  const dayData = props.articlesByDate.filter(d =>
    d.date.isSame(date, 'day'),
  )[0];
  return previousTotal + execution === 'morning' && dayData
    ? dayData[feedType].value.afternoon.articles.length
    : 0;
};

const executionInfoMorning = computed(() =>
  executionInformationForDate(
    props.date,
    'morning',
    props.sentFirstPersonalNewsFeedAt,
  ),
);
const executionInfoAfternoon = computed(() =>
  executionInformationForDate(
    props.date,
    'afternoon',
    props.sentFirstPersonalNewsFeedAt,
  ),
);
</script>

<template>
  <div class="feed-articles-media">
    <div class="media-title c-title c-title--xm">
      <div class="media-title-name">
        {{ themeFeedType === 'domestic' ? '国内メディア' : '海外メディア' }}
      </div>
      <div>
        <DgrSelectbox
          class="date-select-box"
          :options="dateOptions"
          :model-value="currentDate"
          @update:model-value="selectDate"
        />
      </div>
      <div class="media-title-count c-text c-text--s">
        ・{{ articleCount }}件
      </div>
    </div>
    <template
      v-if="
        (isAfternoonFeedEnabled(date) ||
          executionInfoAfternoon.isFirstExecution) &&
        !executionInfoMorning.isFirstExecution
      "
    >
      <ArticlesExecution
        :articles-by-date="articlesByDate"
        :execution-info="executionInfoAfternoon"
        :articles="feedArticles.afternoon"
        :removed-article-ids="removedArticleIds"
        :previous-total-feed-count="
          previousTotalFeedCount(date, themeFeedType, 'afternoon')
        "
        :is-loaded="feedData.afternoon.isLoaded"
        :is-fetching-data="feedData.afternoon.isFetchingData"
        :display-feed-type="displayFeedType"
        :theme-feed-type="themeFeedType"
      />
    </template>
    <template v-if="!executionInfoAfternoon.isFirstExecution">
      <ArticlesExecution
        :articles-by-date="articlesByDate"
        :execution-info="executionInfoMorning"
        :articles="feedArticles.morning"
        :removed-article-ids="removedArticleIds"
        :previous-total-feed-count="
          previousTotalFeedCount(date, themeFeedType, 'morning')
        "
        :is-loaded="feedData.morning.isLoaded"
        :is-fetching-data="feedData.morning.isFetchingData"
        :display-feed-type="displayFeedType"
        :theme-feed-type="themeFeedType"
      />
    </template>
  </div>
</template>

<style scoped lang="scss">
.feed-articles-media {
  position: relative;
  background: #fff;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 0 16px;
}
.media-title {
  display: flex;
  align-items: center;
  position: sticky;
  top: 112px;
  background: #fff;
  z-index: 1;
  border-radius: 4px;
}
.media-title-count {
  color: $color-gray600;
}
.date-select-box {
  /* 強制的にセレクトボックスのスタイルを変える */
  :deep(.c-selectBox) {
    border: none;
    font-weight: 700;
  }
}
</style>
