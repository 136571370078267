import axios from 'axios';
import { RelatedArticlesResponse } from '@/types';

export async function getRelatedArticles(
  adpDocumentId: number,
): Promise<RelatedArticlesResponse> {
  const { data } = await axios.get<RelatedArticlesResponse>(
    '/related_articles',
    {
      params: {
        id: adpDocumentId,
      },
    },
  );
  return data as RelatedArticlesResponse;
}
