<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  Ref,
  UnwrapRef,
  watch,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import api from '@/api';
import AdpDocumentCard from '@/components/common/adp-document-card.vue';
import SwrScrollingPagination from '@/components/common/swr-scrolling-pagination.vue';
import Content from '@/components/layouts/content.vue';
import Header from '@/components/layouts/header.vue';
import { ViewedArticle } from '@/types';
import { redirectUnauthorized } from '@/utils/mypage';
import { mypageAccessScope } from '@/utils/mypageAccessScope';
import { useUserOrganizationTags } from '@/utils/swr';
import {
  useUserActionCount,
  useUserInfo,
  useViewedArticles,
} from '@/utils/swr';
import { getTabs } from '@/utils/user';
import { userSession } from '@/utils/userSession';
import { useEmitter } from '@/utils/vue';

export default defineComponent({
  components: {
    Header,
    Content,
    AdpDocumentCard,
    SwrScrollingPagination,
  },
  setup() {
    const emitter = useEmitter();
    const router = useRouter();
    const route = useRoute();

    const userId = computed(() => userSession.getUserId() ?? undefined);
    const { data: userOrganizationTags } = useUserOrganizationTags(userId);

    const { data: userInfo } = useUserInfo();
    const { data: userActionCount } = useUserActionCount(userId, 'view');

    const articleViewCount = computed(() => userActionCount.value?.count ?? 0);

    const tabs = getTabs();

    const accessScope = computed(() => {
      if (userInfo.value) {
        return mypageAccessScope(userInfo.value);
      } else {
        return '';
      }
    });

    watch(
      () => userId.value,
      () => {
        redirectUnauthorized(userId.value, router);
      },
    );

    onMounted(async () => {
      redirectUnauthorized(userId.value, router);
      emitter.on('article-updated', handleArticleUpdate);

      await api.trackPageView({
        pageName: 'user',
        pageUrl: route.path,
      });
    });

    onUnmounted(() => {
      emitter.off('article-updated', handleArticleUpdate);
    });

    const articleFilter = (article: ViewedArticle, id: number) => {
      return article.id === id;
    };

    const updateArticle = (
      articles: ViewedArticle[],
      updatedArticle: ViewedArticle,
    ) => {
      articles.forEach(c => {
        if (c.id === updatedArticle.id) {
          c.article_exclusion_id = updatedArticle.article_exclusion_id;
        }
      });
      return articles;
    };

    const handleArticleUpdate = (article: ViewedArticle) => {
      emitter.emit('pagination-items-update', {
        filterFunc: (i: ViewedArticle) => articleFilter(i, article.id),
        updateFunc: (items: ViewedArticle[]) => updateArticle(items, article),
      });
    };

    return {
      userInfo,
      accessScope,
      pageLimit: 10,
      articleViewCount,
      tabs,
      mypageAccessScope,
      userOrganizationTags,
      paginationFunc: (pageRef: Ref<number>, pageLimit: number) =>
        useViewedArticles(pageRef, pageLimit),
      dataAccessor: (
        d: UnwrapRef<ReturnType<typeof useViewedArticles>['data']>,
      ) => d?.viewed_articles,
    };
  },
});
</script>

<template>
  <div class="o-user-views" v-if="userInfo">
    <Header
      :title="userInfo.user_name"
      :detail="`${userInfo.email} ${accessScope}`"
      :avatar-image-url="userInfo.image_url"
      :tabs="tabs"
      :organization-tag-list="userOrganizationTags?.organization_tags"
    />
    <Content>
      <div>
        <div class="o-caption c-title c-title--m">
          閲覧
          <span class="o-count c-text c-text--s"
            >・{{ articleViewCount }} 件</span
          >
        </div>
        <div class="spacing-16"></div>
        <SwrScrollingPagination
          :page-limit="pageLimit"
          :pagination-func="paginationFunc"
          :data-accessor="dataAccessor"
        >
          <template v-slot="{ items, loaded }">
            <div
              class="o-viewed-articles"
              v-if="loaded && articleViewCount > 0"
            >
              <AdpDocumentCard
                class="o-article"
                v-for="article in items"
                :key="article.id"
                :adp-document="article"
                :show-comment="false"
                :article-exclusion-id="
                  article.article_exclusion_id ?? undefined
                "
                page-name="user"
                feature="my_views"
              />
            </div>
            <div
              class="o-no-user-views"
              v-if="loaded && articleViewCount === 0"
            >
              <div class="o-description c-text c-text--m">
                該当する記事はありませんでした。
              </div>
              <img src="@/assets/state-empty-article.png" />
            </div>
          </template>
        </SwrScrollingPagination>
      </div>
    </Content>
  </div>
</template>

<style lang="scss" scoped>
.o-user-views {
  width: 100%;
  margin: -24px 0 0 0;
  .o-caption {
    .o-count {
      color: #b3b3b3;
    }
  }
  .o-viewed-articles {
    .o-article {
      margin-bottom: 12px;
    }
  }
}
.o-no-user-views {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 616px;
  height: 212px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0 32px 0 48px;

  img {
    height: 200px;
    width: 200px;
  }
}
</style>
