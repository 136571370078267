<script setup lang="ts">
import { computed, ref, toRaw, watch } from 'vue';
import { DgrCheckbox, DgrIcon, DgrSelectbox } from '@stockmarkteam/donguri-ui';
import TagSelect from '@/components/common/multi-select/tag-select.vue';
import {
  DocType,
  FilterCheckBoxItem,
  FilterSelectBoxItem,
  Lang,
  MemoFilterValue,
  TagSelectItem,
} from '@/types/index';
import { useOutsideClick } from '@/utils/composables/useOutsideClick';

interface Props {
  isGroupPage: boolean;
  articleTagList: TagSelectItem[]; // タグの絞り込みに使用する記事に紐づくタグリスト
  selectedTags: string[];
  memoFilterItems: FilterSelectBoxItem<MemoFilterValue>[];
  articleLangs: FilterCheckBoxItem<Lang>[];
  docTypes: FilterCheckBoxItem<DocType>[];
}

const props = defineProps<Props>();

const emit = defineEmits<{
  'click-reset': [];
  'click-apply': [
    values: {
      tmpSelectedTags: string[];
      tmpMemoFilterItems: FilterSelectBoxItem<MemoFilterValue>[];
      tmpArticleLangs: FilterCheckBoxItem<Lang>[];
      tmpDocTypes: FilterCheckBoxItem<DocType>[];
    },
  ];
}>();

const DEFAULT_MEMO_FILTER_VALUE = props.memoFilterItems[0].value;
const isOpen = ref(false);
const isFilterApplied = computed(
  () =>
    props.selectedTags.length > 0 ||
    props.memoFilterItems.some(
      data => data.isSelected && data.value !== DEFAULT_MEMO_FILTER_VALUE,
    ) ||
    props.articleLangs.some(data => data.isChecked) ||
    props.docTypes.some(data => data.isChecked),
);

/* フィルター項目 */
// NOTE: proxyオブジェクトをstructuredCloneでdeep copyするとstorybook上でエラーになるため、
// toRawでproxyから元のオブジェクトを取り出してからdeep copyする
const ITEM_PREFIX = props.isGroupPage ? 'グループ' : 'マイ';
const tmpSelectedTags = ref<string[]>(
  structuredClone(toRaw(props.selectedTags)),
);
const tmpMemoFilterItems = ref<FilterSelectBoxItem<MemoFilterValue>[]>(
  structuredClone(toRaw(props.memoFilterItems)),
);
const tmpArticleLangs = ref<FilterCheckBoxItem<Lang>[]>(
  structuredClone(toRaw(props.articleLangs)),
);
const tmpDocTypes = ref<FilterCheckBoxItem<DocType>[]>(
  structuredClone(toRaw(props.docTypes)),
);

/* ハンドリング */
const handleTagFilterArticles = (tags: string[]) => {
  tmpSelectedTags.value = tags;
};

const memoFilter = computed({
  get: () => {
    return (
      tmpMemoFilterItems.value.filter(item => item.isSelected)[0]?.value ||
      tmpMemoFilterItems.value[0]?.value
    );
  },
  set: value => {
    tmpMemoFilterItems.value.forEach(item => {
      item.isSelected = item.value === value;
    });
  },
});

/**
 * 絞り込みフィルターの表示値をpropsの値で更新
 *
 * - キャンセルボタン, 枠外をクリックした場合 => 最後の絞り込み適用時の値に戻す （絞り込み未実行の場合は初期値に戻す）
 */
const restoreFormValues = () => {
  tmpSelectedTags.value = structuredClone(toRaw(props.selectedTags));
  tmpMemoFilterItems.value = structuredClone(toRaw(props.memoFilterItems));
  tmpArticleLangs.value = structuredClone(toRaw(props.articleLangs));
  tmpDocTypes.value = structuredClone(toRaw(props.docTypes));
};

const clickFilter = () => {
  if (isOpen.value) {
    closeFilter();
    restoreFormValues();
  } else {
    isOpen.value = true;
  }
};

const closeFilter = () => {
  isOpen.value = false;
};

const clickReset = () => {
  closeFilter();
  emit('click-reset');
};

const clickCancel = () => {
  closeFilter();
  restoreFormValues();
};

const clickApply = () => {
  closeFilter();
  emit('click-apply', {
    tmpSelectedTags: tmpSelectedTags.value,
    tmpMemoFilterItems: tmpMemoFilterItems.value,
    tmpArticleLangs: tmpArticleLangs.value,
    tmpDocTypes: tmpDocTypes.value,
  });
};

const filteredArticleTagList = computed(() => {
  return props.articleTagList.filter(t => t.count !== 0);
});

/**
 * 【propsが変わるパターン】
 * - リセットボタンをクリックした場合 => 初期値に戻す (親コンポーネントの処理に依存)
 * - 適用ボタンをクリックした場合 => 当コンポーネントで最後に選択した値に更新 (親コンポーネントの処理に依存)
 */
watch(props, () => {
  tmpSelectedTags.value = structuredClone(toRaw(props.selectedTags));
  tmpMemoFilterItems.value = structuredClone(toRaw(props.memoFilterItems));
  tmpArticleLangs.value = structuredClone(toRaw(props.articleLangs));
  tmpDocTypes.value = structuredClone(toRaw(props.docTypes));
});

const myMarkFilterRef = ref<Element>();
const isClickedOutsideFilter = useOutsideClick([
  myMarkFilterRef,
  '.tag-select',
]);
watch(isClickedOutsideFilter, newValue => {
  if (newValue) {
    clickCancel();
  }
});
</script>

<template>
  <div
    role="button"
    ref="myMarkFilterRef"
    class="search-settings"
    @click="clickFilter"
  >
    <div class="c-selectBox c-selectBox--small">
      <span class="c-text--s">
        {{ isFilterApplied ? '絞り込み適用中' : '詳細絞り込み' }}
      </span>
      <DgrIcon size="small" name="angle-down" class="c-selectBox__arrow" />
    </div>
    <div
      class="option-select dashboard-card c-text--m"
      data-testid="filter-option"
      v-if="isOpen"
      @click.stop="() => undefined"
    >
      <div class="option">
        <p>{{ `${ITEM_PREFIX}タグ` }}</p>
        <div class="tag-box">
          <div class="selectBox-container">
            <TagSelect
              v-if="filteredArticleTagList"
              :tag-list="filteredArticleTagList"
              :target-tags="tmpSelectedTags"
              :placeholder="
                tmpSelectedTags.length > 0
                  ? `${ITEM_PREFIX}タグ${tmpSelectedTags.length}`
                  : 'すべての記事'
              "
              :empty-filter-message="`指定した${ITEM_PREFIX}タグはありません。`"
              @filter-articles="handleTagFilterArticles"
            ></TagSelect>
          </div>
        </div>
        <p>{{ `${ITEM_PREFIX}メモ` }}</p>
        <div class="selectBox-container">
          <DgrSelectbox
            :options="tmpMemoFilterItems"
            v-model="memoFilter"
            data-testid="memo-filter"
            size="small"
          ></DgrSelectbox>
        </div>
        <p>記事の言語</p>
        <div class="checkbox-container" data-testid="lang-filter">
          <div
            v-for="data in tmpArticleLangs"
            :key="data.label"
            class="checkbox-item"
          >
            <DgrCheckbox v-model="data.isChecked">{{ data.label }}</DgrCheckbox>
          </div>
        </div>
        <p>記事の種類</p>
        <div class="checkbox-container" data-testid="doc-filter">
          <div
            v-for="data in tmpDocTypes"
            :key="data.label"
            class="checkbox-item"
          >
            <DgrCheckbox v-model="data.isChecked">{{ data.label }}</DgrCheckbox>
          </div>
        </div>
      </div>
      <div class="option-buttons">
        <button
          class="dashboard-button--borderless"
          @click="clickReset"
          data-testid="reset-button"
        >
          リセット
        </button>
        <div class="right-buttons">
          <button
            class="dashboard-button"
            @click="clickCancel"
            data-testid="cancel-button"
          >
            キャンセル
          </button>
          <div class="spacing-16"></div>
          <button
            class="dashboard-button--primary"
            @click="clickApply"
            data-testid="apply-button"
          >
            適用
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.search-settings {
  position: relative;
  .c-selectBox {
    .c-selectBox__placeholder.c-text--s {
      line-height: 14px;
    }
  }
  .right-aligned {
    right: 0px;
  }
  .option-select {
    position: absolute;
    z-index: var(--z-dropdown);
    width: 456px;
    box-shadow: 0 1px 5px rgba(74, 74, 74, 0.25);
    .option {
      display: grid;
      grid-template-columns: 1fr 1fr;
      row-gap: 16px;
      align-items: center;
      padding-bottom: 16px;
      border-bottom: 1px solid $color-border;
    }
    .selectBox-container {
      width: fit-content;
    }
    .checkbox-container {
      display: flex;
      align-items: center;
      gap: 16px;
      .checkbox-item {
        display: flex;
        align-items: center;
      }
    }
    .option-buttons {
      display: flex;
      justify-content: space-between;
      padding-top: 16px;
      .right-buttons {
        display: flex;
      }

      .dashboard-button--borderless {
        color: $color-green600;
      }
    }
  }
}
</style>
