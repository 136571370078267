<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  PropType,
  ref,
  watch,
} from 'vue';
import { DgrIcon } from '@stockmarkteam/donguri-ui';
import MultiSelect from '@/components/common/multi-select/dashboard-multi-select.vue';

export type SearchFilters = {
  label: string;
  options: {
    name: string;
    count: number;
  }[];
  selectedOptions: string[];
}[];

export default defineComponent({
  components: {
    MultiSelect,
    DgrIcon,
  },
  props: {
    modelValue: {
      type: Array as PropType<SearchFilters>,
      required: true,
    },
    isRightAligned: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    'update:modelValue': (_filters: SearchFilters) => true,
    input: () => true,
  },
  setup(props, context) {
    const filters = ref<SearchFilters>([]);
    const displayText = computed(() => {
      const text = filters.value
        .map(filter =>
          filter.selectedOptions.length > 0
            ? String(filter.selectedOptions.length) + filter.label
            : null,
        )
        .filter(Boolean)
        .join('・');
      return text === '' ? 'メンバーの絞り込み' : text;
    });

    watch(
      () => props.modelValue,
      () => (filters.value = JSON.parse(JSON.stringify(props.modelValue))),
      { deep: true, immediate: true },
    );

    const isSelectionOpen = ref(false);

    const clickApply = () => {
      isSelectionOpen.value = false;
      context.emit('update:modelValue', filters.value);
      context.emit('input');
    };

    const clickReset = () => {
      filters.value.forEach(x => (x.selectedOptions = []));
      isSelectionOpen.value = false;
      context.emit('update:modelValue', filters.value);
      context.emit('input');
    };

    const searchSettingsRef = ref<HTMLElement>();
    const closeSelection = (e?: Event) => {
      const eventTargets = e?.composedPath() ?? [];
      if (
        searchSettingsRef.value &&
        (!e || !eventTargets.includes(searchSettingsRef.value))
      ) {
        isSelectionOpen.value = false;
      }
    };

    onMounted(() => {
      document.addEventListener('click', closeSelection, { capture: true });
    });

    onUnmounted(() => {
      document.removeEventListener('click', closeSelection, { capture: true });
    });

    return {
      searchSettingsRef,
      isSelectionOpen,
      clickApply,
      clickReset,
      filters,
      displayText,
    };
  },
});
</script>

<template>
  <div class="search-settings" ref="searchSettingsRef">
    <div class="setting-box" @click="isSelectionOpen = !isSelectionOpen">
      <div class="c-selectBox c-selectBox--small">
        <span class="c-selectBox__placeholder c-text--m">
          {{ displayText }}
        </span>
        <DgrIcon size="small" name="angle-down" class="c-selectBox__arrow" />
      </div>
    </div>
    <div
      class="option-select dashboard-card"
      v-if="isSelectionOpen"
      :class="{ 'right-aligned': isRightAligned }"
    >
      <template v-for="filter in filters" :key="filter.label">
        <div class="filter-line">
          <div>{{ filter.label }}</div>
          <div>
            <MultiSelect
              :option-name="filter.label"
              :options="filter.options"
              :selected-options="filter.selectedOptions"
              @selection-updated="opts => (filter.selectedOptions = opts)"
            />
          </div>
          <div class="spacing-16"></div>
        </div>
      </template>
      <div class="option-buttons">
        <button class="dashboard-button--borderless" @click="clickReset">
          リセット
        </button>
        <div class="right-buttons">
          <button class="dashboard-button" @click="isSelectionOpen = false">
            キャンセル
          </button>
          <div class="spacing-16"></div>
          <button class="dashboard-button--primary" @click="clickApply">
            適用
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.search-settings {
  position: relative;
  .selectbox {
    .c-selectBox__placeholder.c-text--s {
      line-height: 16px;
    }
  }
  .filter-line {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .right-aligned {
    right: 0px;
  }
  .option-select {
    position: absolute;
    z-index: var(--z-dropdown);
    width: 456px;
    padding: 16px;
    box-shadow: 0 1px 5px rgba(74, 74, 74, 0.25);
    .option-buttons {
      display: flex;
      justify-content: space-between;
      padding-top: 16px;
      border-top: 1px solid $color-border;
      .right-buttons {
        display: flex;
      }

      .dashboard-button--borderless {
        color: $color-green600;
      }
    }
  }
}
</style>
