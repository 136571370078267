import { RouteLocationNormalized } from 'vue-router';
import { Module } from 'vuex';

export type RouteState = {
  previousRoute: RouteLocationNormalized | undefined;
};

export const initialRouteState: RouteState = {
  previousRoute: undefined,
};

const route: Module<RouteState, unknown> = {
  namespaced: true,
  state: () => initialRouteState,
  mutations: {
    setPreviousRoute(state, route) {
      state.previousRoute = route;
    },
  },
};

export default route;
