import { RouteRecordRaw } from 'vue-router';

export const labsRouteConfig: RouteRecordRaw = {
  name: 'labs',
  path: '/labs',
  component: () => import('@/components/labs/main.vue'),
  meta: { title: '調査AIエージェント | Stockmark Labs', type: 'labs' },
  children: [
    {
      path: '/labs/reports',
      component: () => import('@/components/labs/select-template.vue'),
      meta: { title: '調査AIエージェント | Stockmark Labs', type: 'labs' },
      name: 'selectTemplate',
    },
    {
      path: '/labs/apidemo',
      component: () => import('@/components/labs/stockmark-api-demo.vue'),
      meta: { title: '調査AIエージェント | Stockmark Labs', type: 'labs' },
      name: 'stockmarkApiDemo',
    },
    {
      path: '/labs/reportapidemo',
      component: () => import('@/components/labs/report-api-demo.vue'),
      meta: { title: '調査AIエージェント | Stockmark Labs', type: 'labs' },
      name: 'reportApiDemo',
    },
    {
      path: '/labs/reports/setting',
      component: () => import('@/components/labs/reports/report-setting.vue'),
      meta: { title: '調査AIエージェント | Stockmark Labs', type: 'labs' },
      name: 'reportSetting',
    },
    {
      path: '/labs/reports/templates/todayNews',
      meta: { title: '調査AIエージェント | Stockmark Labs', type: 'labs' },
      component: () =>
        import('@/components/labs/reports/templates/today-news.vue'),
      name: 'todayNewsTemplate',
    },
    {
      path: '/labs/reports/templates/caseStudy',
      meta: { title: '調査AIエージェント | Stockmark Labs', type: 'labs' },
      component: () =>
        import('@/components/labs/reports/templates/case-study.vue'),
      name: 'caseStudyTemplate',
    },
    {
      path: '/labs/reports/templates/sfucReport',
      meta: { title: '調査AIエージェント | Stockmark Labs', type: 'labs' },
      component: () =>
        import('@/components/labs/reports/templates/sfuc-report.vue'),
      name: 'solutionFunctionUseCase',
    },
    {
      path: '/labs/reports/templates/network-graph',
      name: 'networkGraph',
      redirect: () => {
        return `/labs/reports/templates/network-graph/member`;
      },
    },
    {
      path: '/labs/reports/templates/network-graph/member',
      meta: {
        title: 'ネットワークグラフ・メンバー | Stockmark Labs',
        type: 'labs',
      },
      component: () =>
        import('@/components/labs/reports/templates/network-graph.vue'),
      name: 'networkGraphMember',
    },
    {
      path: '/labs/reports/templates/network-graph/organization-tags',
      meta: {
        title: 'ネットワークグラフ・組織タグ | Stockmark Labs',
        type: 'labs',
      },
      component: () =>
        import(
          '@/components/labs/reports/templates/network-graph-organization-tags.vue'
        ),
      name: 'networkGraphOrganizationTags',
    },
    {
      path: '/labs/reports/:reportId',
      meta: { title: '調査AIエージェント | Stockmark Labs', type: 'labs' },
      component: () => import('@/components/labs/reports/saved-report.vue'),
      name: 'savedReport',
      props: route => {
        return {
          reportId: Number(route.params.reportId),
          templateName: route.query.templateName,
          keyword: route.query.keyword,
          langs: route.query.langs,
          fromDate: route.query.fromDate,
          toDate: route.query.toDate,
        };
      },
    },
  ],
};
