<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  PropType,
  ref,
} from 'vue';
import { MAX_TOOLTIP_LIST_LENGTH, USER_MARK_SHARE } from '@/constants';
import { DgrIcon } from '@stockmarkteam/donguri-ui';
import { AdpDocument } from '@/types';
import { userSession } from '@/utils/userSession';

export default defineComponent({
  components: {
    DgrIcon,
  },
  props: {
    adpDocument: { type: Object as PropType<AdpDocument>, required: true },
    small: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  emits: {
    clickOpenMarkModalButton: () => true,
    clickMarkButton: () => true,
  },
  setup(props, { emit }) {
    const userId = Number(userSession.getUserId());
    const marks = computed(() => props.adpDocument.marks);

    const marked = computed(() => {
      return (marks.value ?? []).length > 0;
    });

    const markCount = computed(() => {
      return marks.value?.length;
    });

    const hasMyMark = computed(() => {
      return marks.value?.some(mark => mark.user_id === userId);
    });

    const isMarkActionVisible = ref(false);
    const isClickedOpen = ref(false);
    const showMarkAction = () => {
      if (props.disabled) return;
      !hasMyMark.value ? (isMarkActionVisible.value = true) : undefined;
    };
    const hideMarkAction = () => {
      !isClickedOpen.value ? (isMarkActionVisible.value = false) : undefined;
    };
    const openMarkAction = () => {
      if (props.disabled) return;
      isClickedOpen.value = true;
      isMarkActionVisible.value = true;
    };
    const closeMarkAction = () => {
      isClickedOpen.value = false;
      isMarkActionVisible.value = false;
    };
    const clickOpenModal = () => {
      closeMarkAction();
      emit('clickOpenMarkModalButton');
    };
    const clickMarkButton = () => {
      if (props.disabled) return;
      closeMarkAction();
      emit('clickMarkButton');
    };

    onMounted(() => {
      document.addEventListener('click', closeMarkAction);
    });

    onUnmounted(() => {
      document.removeEventListener('click', closeMarkAction);
    });

    return {
      marked,
      markCount,
      hasMyMark,
      showMarkAction,
      hideMarkAction,
      isMarkActionVisible,
      openMarkAction,
      clickMarkButton,
      clickOpenModal,
      USER_MARK_SHARE,
      MAX_TOOLTIP_LIST_LENGTH,
      marks,
    };
  },
});
</script>

<template>
  <div
    class="mark-button"
    :class="{
      'non-disabled': !disabled,
      disabled: disabled,
    }"
    @mouseleave="hideMarkAction"
  >
    <VTooltip theme="menu" placement="top" @click="clickMarkButton">
      <div
        class="content"
        :class="{ small: small, marked: hasMyMark }"
        @mouseleave="hideMarkAction"
      >
        <DgrIcon
          size="small"
          :keep-fill="false"
          :name="hasMyMark ? 'check-circle-fill' : 'check-circle'"
          class="mark-icon"
          :class="{ primary: hasMyMark && !disabled, disabled: disabled }"
        />
        <div
          class="mark-text c-title c-title--s"
          :class="{ active: hasMyMark && !disabled }"
        >
          {{ markCount > 0 ? markCount : '' }}
        </div>
      </div>

      <template #popper>
        <template v-if="markCount === 0">
          <span>{{ USER_MARK_SHARE }}</span>
        </template>
        <template v-if="markCount > 0">
          <div
            v-for="userMark in marks.slice(0, MAX_TOOLTIP_LIST_LENGTH)"
            :key="userMark.id"
          >
            <router-link
              :to="{ name: 'userMarks', params: { userId: userMark.user_id } }"
            >
              ・{{ userMark.user_name }}
            </router-link>
          </div>
        </template>
        <template v-if="markCount > MAX_TOOLTIP_LIST_LENGTH">
          <span>他{{ markCount - MAX_TOOLTIP_LIST_LENGTH }}名</span>
        </template>
      </template>
    </VTooltip>
    <div
      class="action-toggle"
      v-if="!hasMyMark"
      :key="`open-${isMarkActionVisible}`"
      :class="{ 'small-button': small }"
      @click.stop.prevent="openMarkAction"
      @mouseover="showMarkAction"
    >
      <DgrIcon
        size="small"
        :name="isMarkActionVisible ? 'angle-up' : 'angle-down'"
        :class="{ disabled: disabled }"
      />
    </div>
    <div class="mark-action" v-if="isMarkActionVisible">
      <div
        class="modal-button c-text c-text--m"
        @click.stop.prevent="clickOpenModal"
      >
        マイタグ・マイメモをしてマーク
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.mark-button {
  position: relative;
  display: flex;
  align-items: center;
  min-width: 32px;
  border-radius: 4px;
  width: 60px;

  &.disabled {
    cursor: default;
  }

  &.non-disabled {
    cursor: pointer;
    &:hover {
      background: #f2f2f2;
    }
  }

  .content {
    display: flex;
    align-items: center;
    padding: 8px 0 8px 10px;

    &.small {
      padding: 4px 0 4px 4px;
    }

    &.marked {
      padding: 8px 10px;

      &.small {
        padding: 4px;
      }
    }

    .mark-text {
      color: #717171;
      line-height: 12px;

      &.active {
        color: #1da482;
      }
    }
    .mark-icon {
      margin-right: 4px;
      &.primary {
        fill: $color-green600;
      }
    }
  }

  .action-toggle {
    padding: 8px 10px 8px 4px;

    &.small-button {
      padding: 4px 4px 4px 2px;
    }
  }

  .mark-action {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: var(--z-action-menu);
    width: 240px;
    padding: 8px 0;
    background: #fff;
    border-radius: 4px;
    border: 1px solid #e6e6e6;
    cursor: default;

    .modal-button {
      cursor: pointer;
      padding: 4px 12px;
      &:hover {
        background: #f2f2f2;
      }
    }
  }

  .disabled {
    fill: #b3b3b3;
  }
}
</style>
