<template>
  <div class="o-buttons">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.o-buttons {
  flex-shrink: 0;
  display: flex;
  gap: 8px;
}
</style>
