import axios from 'axios';
import { TeamInfo, UserEntity } from '@/types';
import { userSession } from '@/utils/userSession';

// NOTE: このAPIは使わない useUsers に置き換える
export async function fetchUsers(): Promise<UserEntity[]> {
  const myTeamId = userSession.getTeamId();
  const { data } = await axios.get<{ users: UserEntity[] }>(
    `/teams/${myTeamId}/users`,
  );
  return data.users;
}

export async function fetchTeamInfo(): Promise<TeamInfo> {
  const myTeamId = userSession.getTeamId();
  const { data } = await axios.get<TeamInfo>(`/teams/${myTeamId}`);
  return data;
}
