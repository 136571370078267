<script setup lang="ts">
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import {
  FIRST_PUBLISHED_AT,
  OLDEST_CALENDAR_DATE,
  searchPresetDates,
  userDocumentSearchPresetDates,
} from '@/constants';
import {
  SearchCondition,
  SearchConditionKey,
} from '@/stores/pc-modules/searchResults';
import SearchMenuAll from '@/components/menu/search-menu/search-menu-all.vue';
import SearchMenuCheckbox from '@/components/menu/search-menu/search-menu-checkbox.vue';
import SearchMenuDropdownCalendar from '@/components/menu/search-menu/search-menu-dropdown-calendar.vue';
import SearchMenuNews from '@/components/menu/search-menu/search-menu-news.vue';
import { Lang, SearchDate } from '@/types';
import { getToday } from '@/utils/dates';
import {
  ALLIANCE_MEDIA_VALUE,
  SearchSiteCategory,
  SITE_CATEGORY_ITEMS,
  SiteCategoryItems,
} from '@/utils/siteCategories';
import { useStore } from '@/utils/vue';

// ヘッダー(68px),絞り込みタイトル(64px),開閉ボタン(40px)を引いた高さに設定
const height = 'calc(100vh - (68px + 64px + 40px))';

const store = useStore();
const route = useRoute();

const teamInfo = computed(() => store.state.teamInfo.teamInfo);

const searchConditionKey = computed<SearchConditionKey>(() => {
  if (route.name === 'searchAll') return 'all';
  if (route.name === 'searchNews') return 'news';
  if (route.name === 'searchResearchPapers') return 'research_paper';
  if (route.name === 'searchPatent') return 'patent';
  if (route.name === 'searchReport') return 'report';
  if (route.name === 'searchUserDocument') return 'user_document';
  if (route.name === 'searchUserDocumentDemo') return 'user_document_demo';
  return 'all';
});

const searchCondition = computed<SearchCondition>(() => {
  return store.getters['searchResults/searchCondition'](
    searchConditionKey.value,
  );
});

const isOpenSiteCategories = ref(true);

const siteCategoryItems = computed<SiteCategoryItems[]>(() =>
  teamInfo.value?.enable_alliance_media
    ? SITE_CATEGORY_ITEMS
    : SITE_CATEGORY_ITEMS.filter(c => c.value !== ALLIANCE_MEDIA_VALUE),
);

const checkedSiteCategoryItems = computed<string[]>(() =>
  teamInfo.value?.enable_alliance_media
    ? searchCondition.value['site_categories']
    : searchCondition.value['site_categories'].filter(
        c => c !== ALLIANCE_MEDIA_VALUE,
      ),
);

const removeAllSiteCategory = () => {
  store.commit('searchResults/setSiteCategoryCondition', {
    key: searchConditionKey.value,
    value: [],
  });
};

const addSiteCategory = (value: string) => {
  const siteCategories = searchCondition.value['site_categories'].slice();
  siteCategories.push(value as SearchSiteCategory);
  store.commit('searchResults/setSiteCategoryCondition', {
    key: searchConditionKey.value,
    value: siteCategories,
  });
};

const removeSiteCategory = (value: string) => {
  const siteCategories = searchCondition.value['site_categories'].slice();
  store.commit('searchResults/setSiteCategoryCondition', {
    key: searchConditionKey.value,
    value: siteCategories.filter(item => item !== value),
  });
};

// メディア種別
const isMediaTypeVisible = computed(() => {
  return (
    teamInfo.value?.enable_foreign_lang_media &&
    searchConditionKey.value !== 'patent' &&
    searchConditionKey.value !== 'report'
  );
});
const isOpenMediaType = ref(true);
const mediaTypeItems = ref([
  { label: '国内', value: 'ja' },
  { label: '海外', value: 'en' },
]);

const checkedMediaTypeItems = computed<string[]>(() => {
  return searchCondition.value['lang'];
});

const addAllMediaType = () => {
  store.commit('searchResults/setLanguageCondition', {
    key: searchConditionKey.value,
    value: ['ja', 'en'],
  });
};

const removeAllMediaType = () => {
  store.commit('searchResults/setLanguageCondition', {
    key: searchConditionKey.value,
    value: [],
  });
};

const addMediaType = (value: string) => {
  const lang = searchCondition.value['lang'].slice();
  lang.push(value as Lang);
  store.commit('searchResults/setLanguageCondition', {
    key: searchConditionKey.value,
    value: lang,
  });
};

const removeMediaType = (value: string) => {
  const lang = searchCondition.value['lang'].slice();
  store.commit('searchResults/setLanguageCondition', {
    key: searchConditionKey.value,
    value: lang.filter(item => item !== value),
  });
};

// 期間
const isOpenDropDownCalendar = ref(true);
const TODAY = ref(getToday());

const startDate = computed(() => {
  return searchCondition.value['from'];
});
const endDate = computed(() => {
  return searchCondition.value['to'];
});

const applyDate = (option: {
  presetKey: string;
  value: { from: SearchDate; to: SearchDate };
}) => {
  const changedSearchCondition = searchCondition.value;
  changedSearchCondition['period'] = option.presetKey;
  changedSearchCondition['from'] = option.value.from;
  changedSearchCondition['to'] = option.value.to;
  changedSearchCondition['news_categories'] = [];

  store.commit('searchResults/setSearchCondition', {
    key: searchConditionKey.value,
    value: changedSearchCondition,
  });
};

// エンタメフィルタ
const isEntertainmentFilter = computed(() => {
  return searchCondition.value['is_entertainment_sites_filtered'];
});

const toggleEntertainmentFilter = () => {
  const changedSearchCondition = searchCondition.value;
  changedSearchCondition['is_entertainment_sites_filtered'] =
    !changedSearchCondition['is_entertainment_sites_filtered'];
  changedSearchCondition['news_categories'] = [];

  store.commit('searchResults/setSearchCondition', {
    key: searchConditionKey.value,
    value: changedSearchCondition,
  });
};

// 社内情報更新者
const isOpenUserDocumentUpdater = ref(true);

const userDocumentUpdaterOptionItems = computed(() => {
  return store.state.searchResults.lastUpdatedBy.map(updater => {
    return { label: updater, value: updater };
  });
});

const checkedUserDocumentUpdaterItems = computed(
  () => searchCondition.value['last_updated_by'],
);

const addUserDocumentUpdater = (value: string) => {
  if (!searchCondition.value['last_updated_by']) return;
  const lastUpdatedBy = searchCondition.value['last_updated_by'].slice();
  lastUpdatedBy.push(value);
  store.commit('searchResults/setLastUpdatedByCondition', {
    key: searchConditionKey.value,
    value: lastUpdatedBy,
  });
};

const removeUserDocumentUpdater = (value: string) => {
  if (!searchCondition.value['last_updated_by']) return;
  const lastUpdatedBy = searchCondition.value['last_updated_by'].slice();
  store.commit('searchResults/setLastUpdatedByCondition', {
    key: searchConditionKey.value,
    value: lastUpdatedBy.filter(item => item !== value),
  });
};

const removeAllUserDocumentUpdater = () =>
  store.commit('searchResults/setLastUpdatedByCondition', {
    key: searchConditionKey.value,
    value: [],
  });
</script>

<template>
  <div class="search-menu">
    <div class="title">
      <span class="c-title c-title--m">絞り込み</span>
    </div>

    <div class="search-conditions">
      <SearchMenuAll
        v-if="route.name === 'searchAll'"
        :is-media-type-visible="isMediaTypeVisible"
        :is-open-media-type="isOpenMediaType"
        :media-type-items="mediaTypeItems"
        :checked-media-type-items="checkedMediaTypeItems"
        :is-open-drop-down-calendar="isOpenDropDownCalendar"
        :start-date="startDate"
        :end-date="endDate"
        :is-open-site-categories="isOpenSiteCategories"
        :site-category-items="siteCategoryItems"
        :checked-site-category-items="checkedSiteCategoryItems"
        :is-entertainment-filter="isEntertainmentFilter"
        @open-media-type="isOpenMediaType = !isOpenMediaType"
        @add-all-media-type="addAllMediaType"
        @remove-all-media-type="removeAllMediaType"
        @add-media-type="addMediaType"
        @remove-media-type="removeMediaType"
        @open-drop-down-calendar="
          isOpenDropDownCalendar = !isOpenDropDownCalendar
        "
        @apply-date="applyDate"
        @open-site-categories="isOpenSiteCategories = !isOpenSiteCategories"
        @remove-all-site-category="removeAllSiteCategory"
        @add-site-category="addSiteCategory"
        @remove-site-category="removeSiteCategory"
        @toggle-entertainment-filter="toggleEntertainmentFilter"
      ></SearchMenuAll>

      <SearchMenuNews
        v-else-if="route.name === 'searchNews'"
        :is-open-site-categories="isOpenSiteCategories"
        :site-category-items="siteCategoryItems"
        :checked-site-category-items="checkedSiteCategoryItems"
        :is-media-type-visible="isMediaTypeVisible"
        :is-open-media-type="isOpenMediaType"
        :media-type-items="mediaTypeItems"
        :checked-media-type-items="checkedMediaTypeItems"
        :is-open-drop-down-calendar="isOpenDropDownCalendar"
        :start-date="startDate"
        :end-date="endDate"
        :is-entertainment-filter="isEntertainmentFilter"
        @open-site-categories="isOpenSiteCategories = !isOpenSiteCategories"
        @remove-all-site-category="removeAllSiteCategory"
        @add-site-category="addSiteCategory"
        @remove-site-category="removeSiteCategory"
        @open-media-type="isOpenMediaType = !isOpenMediaType"
        @add-all-media-type="addAllMediaType"
        @remove-all-media-type="removeAllMediaType"
        @add-media-type="addMediaType"
        @remove-media-type="removeMediaType"
        @open-drop-down-calendar="
          isOpenDropDownCalendar = !isOpenDropDownCalendar
        "
        @apply-date="applyDate"
        @toggle-entertainment-filter="toggleEntertainmentFilter"
      ></SearchMenuNews>
      <template v-else-if="route.name === 'searchUserDocument'">
        <SearchMenuDropdownCalendar
          title="期間"
          :is-open="isOpenDropDownCalendar"
          :range="{
            from: startDate,
            to: endDate,
          }"
          :preset="userDocumentSearchPresetDates"
          :min="OLDEST_CALENDAR_DATE"
          :max="TODAY"
          :disabled="false"
          @toggle-is-open="isOpenDropDownCalendar = !isOpenDropDownCalendar"
          @apply-date="applyDate"
        ></SearchMenuDropdownCalendar>
        <div class="divider"></div>
        <SearchMenuCheckbox
          title="更新者"
          :items="userDocumentUpdaterOptionItems"
          :checked-values="checkedUserDocumentUpdaterItems || []"
          :is-open="isOpenUserDocumentUpdater"
          @toggle-is-open="
            isOpenUserDocumentUpdater = !isOpenUserDocumentUpdater
          "
          @un-checked-all-item="removeAllUserDocumentUpdater"
          @checked-item="addUserDocumentUpdater"
          @un-checked-item="removeUserDocumentUpdater"
        ></SearchMenuCheckbox>
      </template>

      <template v-else-if="route.name === 'searchUserDocumentDemo'">
        <SearchMenuDropdownCalendar
          title="期間"
          :is-open="isOpenDropDownCalendar"
          :range="{
            from: startDate,
            to: endDate,
          }"
          :preset="userDocumentSearchPresetDates"
          :min="OLDEST_CALENDAR_DATE"
          :max="TODAY"
          :disabled="true"
          @toggle-is-open="isOpenDropDownCalendar = !isOpenDropDownCalendar"
          @apply-date="applyDate"
        ></SearchMenuDropdownCalendar>
        <div class="divider"></div>
        <SearchMenuCheckbox
          title="更新者"
          :items="userDocumentUpdaterOptionItems"
          :checked-values="checkedUserDocumentUpdaterItems || []"
          :is-open="isOpenUserDocumentUpdater"
          :disabled="true"
          @toggle-is-open="
            isOpenUserDocumentUpdater = !isOpenUserDocumentUpdater
          "
          @un-checked-all-item="removeAllUserDocumentUpdater"
          @checked-item="addUserDocumentUpdater"
          @un-checked-item="removeUserDocumentUpdater"
        ></SearchMenuCheckbox>
      </template>

      <template v-else>
        <template v-if="isMediaTypeVisible">
          <div class="divider"></div>

          <SearchMenuCheckbox
            title="メディア種別"
            :items="mediaTypeItems"
            :checked-values="checkedMediaTypeItems"
            :is-open="isOpenMediaType"
            @toggle-is-open="isOpenMediaType = !isOpenMediaType"
            @checked-all-item="addAllMediaType"
            @un-checked-all-item="removeAllMediaType"
            @checked-item="addMediaType"
            @un-checked-item="removeMediaType"
          ></SearchMenuCheckbox>
        </template>

        <div class="divider"></div>

        <SearchMenuDropdownCalendar
          title="期間"
          :is-open="isOpenDropDownCalendar"
          :range="{
            from: startDate,
            to: endDate,
          }"
          :preset="searchPresetDates"
          :min="FIRST_PUBLISHED_AT"
          :max="TODAY"
          :disabled="false"
          @toggle-is-open="isOpenDropDownCalendar = !isOpenDropDownCalendar"
          @apply-date="applyDate"
        ></SearchMenuDropdownCalendar>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.search-menu {
  width: 290px;

  .title {
    padding-left: 16px;
    height: 72px;
    display: flex;
    align-items: center;
  }

  .divider {
    height: 1px;
    padding: 0;
    margin: 4px 0px;
    background: $color-gray400;
  }

  .search-conditions {
    height: v-bind(height);
    overflow-y: scroll;
  }
}
</style>
