<script lang="ts">
import { defineComponent, PropType, toRefs } from 'vue';
import { useRoute } from 'vue-router';
import api from '@/api/index';
import { PageName } from '@/api/tracking';
import { DgrIcon } from '@stockmarkteam/donguri-ui';
import Clipboard from 'clipboard';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import { AdpDocument, isArticle, isPatent, isResearchPaper } from '@/types';
import { formatDate } from '@/utils/formatters';

export default defineComponent({
  components: {
    DgrIcon,
  },
  props: {
    adpDocuments: { type: Array as PropType<AdpDocument[]>, required: true },
    pageName: { type: String as PropType<PageName>, required: true },
    groupId: { type: Number, required: false },
    isExportEditableInfo: { type: Boolean, required: false, default: true },
  },
  setup(props) {
    const route = useRoute();
    const { createSnackbar } = useSnackbar();

    const { adpDocuments } = toRefs(props);

    const isGroupView = props.groupId !== undefined;

    const formatArticle = (adpDocument: AdpDocument) => {
      const publisherName = isArticle(adpDocument)
        ? adpDocument.media_name
        : isResearchPaper(adpDocument)
          ? adpDocument.journal_name
          : isPatent(adpDocument)
            ? adpDocument.applicant
            : '';
      return [
        `${adpDocument.title}`,
        `日付： ${
          adpDocument.published_at ? formatDate(adpDocument.published_at) : ''
        }`,
        `掲載メディア： ${publisherName}`,
        `URL： ${adpDocument.url}`,
        props.isExportEditableInfo
          ? `${isGroupView ? 'グループメモ' : 'マイメモ'}： ${
              adpDocument.memo ?? ''
            }`
          : null,
        props.isExportEditableInfo
          ? `${isGroupView ? 'グループタグ' : 'マイタグ'}： ${
              adpDocument.tags?.join(',') ?? ''
            }`
          : null,
      ]
        .filter(val => val !== null)
        .join('\n');
    };

    const copy = () => {
      const articleData = adpDocuments.value.map(formatArticle).join('\n\n');

      const clipboard = new Clipboard('.copy-button', {
        text: () => articleData,
      });
      clipboard.on('success', e => {
        // IEではクリップボードアクセスが許可されていない場合があるため
        // クリップボードがコピーした内容と同じか確認している
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if ((window as any).clipboardData) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const text = (window as any).clipboardData
            .getData('text')
            .replace(/\r\n/g, '\n');
          if (e.text !== text) {
            createSnackbar({
              message: '記事情報をコピーできませんでした',
              type: 'error',
            });
            return;
          }
        }
        api.trackEvent(
          'copy_articles_to_clipboard',
          {
            pageName: props.pageName,
            pageUrl: route.fullPath,
          },
          undefined,
          props.groupId,
          {
            articles: adpDocuments.value.map(a => a.id),
          },
        );
        createSnackbar({
          message: '記事情報をコピーしました',
          type: 'success',
        });
        clipboard.destroy();
      });
    };
    return {
      copy,
    };
  },
});
</script>

<template>
  <span class="copy-button" @click="copy">
    <DgrIcon size="small" name="copy" /><span class="c-title c-title--m"
      >記事情報をコピー</span
    >
  </span>
</template>

<style lang="scss" scoped>
.copy-button {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 4px 12px;
  width: 100%;
  box-sizing: border-box;
  justify-content: center;

  .c-title {
    margin-left: 6px;
  }
}
</style>
