import { RouteRecordRaw } from 'vue-router';

export const onboardingRouteConfig: RouteRecordRaw = {
  path: '/onboarding',
  component: () => import('@/components/layouts/onboarding.vue'),
  children: [
    {
      path: 'job_select',
      component: () => import('@/components/onboarding/job-select.vue'),
      name: 'jobSelect',
      meta: { title: '職種選択', type: 'onboarding' },
    },
    {
      path: 'theme_topic_select',
      component: () => import('@/components/onboarding/theme-topic-select.vue'),
      name: 'themeTopicSelect',
      meta: { title: '興味選択', type: 'onboarding' },
    },
    {
      path: 'theme_perspective_select',
      component: () =>
        import('@/components/onboarding/theme-perspective-select.vue'),
      name: 'themePerspectiveSelect',
      meta: { title: '組み合わせ条件選択', type: 'onboarding' },
    },
  ],
};
