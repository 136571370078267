<script setup lang="ts">
import {
  computed,
  onMounted,
  reactive,
  ref,
  watch,
  WatchStopHandle,
} from 'vue';
import { useRouter } from 'vue-router';
import api from '@/api';
import {
  CONTRACT_UPPER_LIMIT_ERROR_MESSAGES,
  MAX_NAME_LENGTH,
} from '@/constants';
import { DgrSelectbox } from '@stockmarkteam/donguri-ui';
import { isAxiosError } from 'axios';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import LoadableButton from '@/components/common/atoms/loadable-button.vue';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import OrganizationTagLabels from '@/components/others/organization-tags/organization-tag-labels.vue';
import { AdminUserInfo, CreateUserError, UserRole } from '@/types';
import {
  useAdminUsers,
  useManagementContracts,
  useUserInfo,
} from '@/utils/swr';
import { useOrganizationTagList } from '@/utils/swr';
import { useStore } from '@/utils/vue';

dayjs.extend(isSameOrAfter);

const router = useRouter();
const store = useStore();
const { createSnackbar } = useSnackbar();

const { data: userInfo } = useUserInfo();
const { data: managementContracts } = useManagementContracts();
const type = ref(
  (store.state.userEditModal.type as 'create' | 'edit' | undefined) ?? 'create',
);
const targetUserInfo = ref(
  store.state.userEditModal.targetUserInfo as AdminUserInfo | undefined,
);
const { mutate } = useAdminUsers();

const isEditing = ref(false);

const trimmedMailAddress = ref(targetUserInfo.value?.email ?? '');
const displayName = ref(targetUserInfo.value?.user_name ?? '');
const role = ref(targetUserInfo.value?.role ?? ('viewer' as UserRole));

const activeTeamUsersForAdmin = reactive({ users: [] as AdminUserInfo[] });
const roleOptions: {
  value: UserRole;
  label: string;
}[] = [
  { value: 'admin', label: '管理者' },
  { value: 'group_admin', label: 'グループ管理者' },
  { value: 'editor', label: '編集者' },
  { value: 'viewer', label: '閲覧者' },
];

const userCreationError = ref<string>('');

const hasNameChanged = ref(false);
watch(
  () => displayName.value,
  () => (hasNameChanged.value = true),
);

const { data: organizationTagData } = useOrganizationTagList();
const existingOrganizationTags = computed(() => {
  return (
    organizationTagData.value?.organization_tags
      .filter(t => !addedOrganizationTags.value.includes(t.name))
      .map(tag => ({
        value: tag.name,
        label: tag.name,
      })) ?? []
  );
});
// 常にプレースホルダを表示するために、undefinedで固定だが型は合わせる
const organizationTagSelectedValue = ref<string | undefined>(undefined);

const addedOrganizationTags = ref(
  targetUserInfo.value?.organization_tags ?? [],
);
const addedOrganizationTagIds = computed(() => {
  const map = new Map<string, number>();
  organizationTagData.value?.organization_tags.forEach(t => {
    map.set(t.name, t.id);
  });

  return addedOrganizationTags.value
    .map(t => map.get(t))
    .filter((id): id is number => id !== undefined);
});

const organizationTagSelectBoxEnabled = computed(() => {
  return existingOrganizationTags.value.length > 0;
});
const organizationTagSelectBoxLabel = computed(() => {
  if (existingOrganizationTags.value.length === 0) {
    return '選択できる組織タグはありません';
  }
  return '選択してください';
});

const selectOrganizationTag = (newTag: string | undefined) => {
  if (!newTag || addedOrganizationTags.value.includes(newTag)) return;

  addedOrganizationTags.value.push(newTag);
};
const deleteOrganizationTag = (tag: string) => {
  const index = addedOrganizationTags.value.indexOf(tag);
  if (index == -1) return;

  addedOrganizationTags.value.splice(index, 1);
};

const updateUsers = async () => {
  if (userInfo.value?.role === 'admin') {
    await store.dispatch('teamUsers/fetchTeamUsersForAdmin');
    activeTeamUsersForAdmin.users =
      store.getters['teamUsers/activeTeamUsersForAdmin'];
  }
};
const checkPermission = async () => {
  // データが取得されるまでは権限判定しない
  if (userInfo.value === undefined) return;

  if (userInfo.value?.role !== 'admin') {
    router.push({ name: 'anewsHome' });
    createSnackbar({
      message: '権限がありません',
      type: 'error',
    });
  }
};
watch(
  () => userInfo.value?.id,
  async () => {
    await checkPermission();
    await updateUsers();
  },
);
onMounted(async () => {
  await checkPermission();
  await updateUsers();
});

const headerText = computed(() => {
  if (type.value === 'create') {
    return 'メンバーの追加';
  } else if (type.value === 'edit') {
    return 'メンバーの編集';
  }
  return '';
});

const buttonText = computed(() => {
  if (type.value === 'create') {
    return '追加';
  } else if (type.value === 'edit') {
    return '保存';
  }
  return '';
});

const isEmptyName = computed(() => displayName.value.length === 0);
const notIncludingAt = computed(() => {
  if (trimmedMailAddress.value === '') return false;
  const includingAt = RegExp('.*@.+');
  return !includingAt.test(trimmedMailAddress.value);
});
const tooLongName = computed(() => displayName.value.length > MAX_NAME_LENGTH);
const enableToEdit = computed(() => {
  return (
    (trimmedMailAddress.value &&
      !isEmptyName.value &&
      !notIncludingAt.value &&
      productContractId.value &&
      !tooLongName.value &&
      contractErrorMessage.value === '') ||
    isEditing.value
  );
});

const hideUserEditModal = () => store.commit('userEditModal/hideUserEditModal');

const editUser = async () => {
  if (!enableToEdit.value) return;
  if (isEmptyName.value) return;
  if (isEditing.value) return;
  isEditing.value = true;
  if (targetUserInfo.value === undefined) {
    await postUser();
  } else {
    await putUser();
  }
  // ユーザの更新操作を行った後、情報を再取得する
  await store.dispatch('teamUsers/fetchTeamUsers');
  isEditing.value = false;
};

const postUser = async () => {
  try {
    await api.createUser(
      trimmedMailAddress.value,
      displayName.value,
      role.value,
      productContractId.value ?? null,
      addedOrganizationTagIds.value,
    );
  } catch (err) {
    if (isAxiosError<CreateUserError>(err)) {
      if (err.response?.data.type === 'email_exists') {
        userCreationError.value =
          '同じメールアドレスのユーザーが既に存在します';
        return;
      }
      if (err.response?.data.type === 'already_deleted') {
        userCreationError.value =
          '削除済みユーザーはこの画面から追加することができません。お手数ですがサポート担当までご連絡ください。';
        return;
      }
      if (err.response?.data.type === 'validate_contract_upper_limit') {
        createSnackbar({
          message:
            '追加できませんでした<br>既に別のユーザーによって処理されています。',
          type: 'error',
        });
        return;
      }
    }
    userCreationError.value = 'メンバーを追加できませんでした';
    isEditing.value = false;
    throw err;
  }
  createSnackbar({
    message: 'メンバーを追加しました',
    type: 'success',
  });
  await updateUsers();
  hideUserEditModal();
  mutate();
};

const putUser = async () => {
  if (!targetUserInfo.value) return;
  try {
    await api.updateUser(
      targetUserInfo.value.id,
      trimmedMailAddress.value,
      displayName.value,
      role.value,
      productContractId.value ?? null,
      addedOrganizationTagIds.value,
    );
    createSnackbar({
      message: 'メンバーの設定を更新しました',
      type: 'success',
    });
  } catch (err) {
    if (isAxiosError(err) && err.response?.status === 400) {
      if (err.response?.data.type === 'validate_contract_upper_limit') {
        createSnackbar({
          message:
            '保存できませんでした<br>既に別のユーザーによって処理されています。',
          type: 'error',
        });
        return;
      }
      userCreationError.value = '同じメールアドレスのユーザーが既に存在します';
    } else {
      userCreationError.value = 'メンバー情報を更新できませんでした';
    }
    isEditing.value = false;
    throw err;
  }
  createSnackbar({
    message: 'メンバーの設定を更新しました',
    type: 'success',
  });
  await updateUsers();
  hideUserEditModal();
  mutate();
};

const displayChangeRoleWarning = computed(() => {
  return (
    targetUserInfo.value?.role === 'admin' &&
    role.value !== 'admin' &&
    (targetUserInfo.value?.management_contract_ids?.length ?? 0) > 0
  );
});

// -------------------
// 契約関連
// -------------------
const selectableContracts = computed(() => {
  //end_dateが今日以降の契約のみ表示
  return managementContracts.value?.management_contracts.filter(contract => {
    const today = dayjs();
    const endDate = dayjs(contract.end_date);
    return (
      endDate.isSameOrAfter(today, 'day') &&
      contract.product_contract_id !== null
    );
  });
});

const productContractId = ref(
  targetUserInfo.value?.product_contract_id ?? undefined,
);

const selectableContractsList = computed(() => {
  if (!selectableContracts.value) return [];
  return selectableContracts.value.map(contract => {
    return {
      label: contract.contract_name,
      value: contract.product_contract_id ?? undefined,
      current_account_count: contract.current_account_count,
      max_account: contract.max_account,
      is_current_contract:
        targetUserInfo.value?.product_contract_id ===
        contract.product_contract_id,
    };
  });
});

const selectedContract = computed(() => {
  return selectableContracts.value?.find(
    i => i.product_contract_id === productContractId.value,
  );
});

const addableContracts = computed(() => {
  return selectableContractsList.value.filter(
    i => i.max_account > i.current_account_count,
  );
});

// 選択可能な契約の中で最初のもの初期値にする
let unwatchSelectableContractList: WatchStopHandle | undefined = undefined;
unwatchSelectableContractList = watch(
  selectableContractsList,
  () => {
    // 既に何かしらの契約が選択されている場合は何もしない
    if (productContractId.value !== undefined) {
      if (unwatchSelectableContractList) unwatchSelectableContractList();
      return;
    }

    if (addableContracts.value.length > 0) {
      productContractId.value = addableContracts.value[0].value ?? undefined;
    } else if (selectableContractsList.value.length > 0) {
      productContractId.value = selectableContractsList.value[0].value;
    }
  },
  { immediate: true },
);

const contractErrorMessage = computed(() => {
  if (selectableContractsList.value.length === 0) return '';
  if (!selectedContract.value) return '';

  // すべての契約が上限かつ新規の場合
  if (addableContracts.value.length === 0 && targetUserInfo.value === undefined)
    return CONTRACT_UPPER_LIMIT_ERROR_MESSAGES.all_upper_limit;
  if (
    selectedContract.value.max_account <=
      selectedContract.value.current_account_count &&
    targetUserInfo.value?.product_contract_id !=
      selectedContract.value.product_contract_id
  )
    return CONTRACT_UPPER_LIMIT_ERROR_MESSAGES.upper_limit;
  return '';
});
</script>

<template>
  <div class="m-user-edit-modal">
    <sm-dialog @close="hideUserEditModal" class="screen-center">
      <template #header>
        <div class="c-dialog__title">{{ headerText }}</div>
      </template>
      <template #body>
        <div class="m-body body-wrapper">
          <div
            v-if="userCreationError"
            class="deleted-user-error-message c-text c-text--m"
          >
            {{ userCreationError }}
          </div>
          <div
            v-if="contractErrorMessage"
            class="add-contract-error-message c-text c-text--m"
          >
            {{ contractErrorMessage }}
          </div>
          <div class="c-formBlock">
            <div class="c-formBlock__label">メールアドレス</div>
            <div
              class="c-formBlock__text c-formBlock__text--error"
              v-if="notIncludingAt"
            >
              <pre class="c-text--s">メールアドレスを入力してください。</pre>
            </div>
            <input
              class="m-input c-text c-text--m c-textInput"
              ref="mailAddressArea"
              :class="{ 'c-formInput--error': notIncludingAt }"
              v-model.trim="trimmedMailAddress"
              @keydown.ctrl.enter="editUser"
              @keydown.meta.enter="editUser"
              data-testid="mail-address-input"
            />
          </div>
          <div class="c-formBlock">
            <div class="c-formBlock__label">名前</div>
            <div
              class="c-formBlock__text c-formBlock__text--error"
              v-if="tooLongName"
            >
              <pre class="c-text--s">名前は50文字以内で入力してください。</pre>
            </div>
            <div
              class="c-formBlock__text c-formBlock__text--error"
              v-if="hasNameChanged && isEmptyName"
            >
              <pre class="c-text--s">名前が空欄です。</pre>
            </div>
            <input
              class="m-input c-text c-text--m c-textInput"
              v-model.trim="displayName"
              ref="nameArea"
              :class="{
                'c-formInput--error':
                  tooLongName || (hasNameChanged && isEmptyName),
              }"
              @keydown.ctrl.enter="editUser"
              @keydown.meta.enter="editUser"
              data-testid="name-input"
            />
          </div>
          <div class="c-formBlock">
            <div class="c-formBlock__label">契約</div>
            <DgrSelectbox
              class="m-contract"
              :options="selectableContractsList"
              v-model="productContractId"
            >
              <template #option="{ option }">
                {{ option.label }}
                <div class="contract-select-option">
                  <span class="contact-count c-text c-text--xs">
                    {{ option.current_account_count }}/{{ option.max_account }}
                  </span>
                  <div
                    v-if="option.is_current_contract"
                    class="current-contract"
                  >
                    <DgrIcon size="xs" name="check" :keep-fill="false" />
                    <span class="c-text c-text--xs">現在の契約</span>
                  </div>
                </div>
              </template>
            </DgrSelectbox>
          </div>
          <div class="c-formBlock">
            <div class="c-formBlock__label">権限</div>
            <DgrSelectbox
              class="m-role"
              :options="roleOptions"
              v-model="role"
            ></DgrSelectbox>
            <div
              class="c-formBlock__text c-formBlock__text--error"
              v-if="displayChangeRoleWarning"
            >
              <div class="c-text--s">
                <div>すでに管理権限を持っている契約があります。</div>
                <div>
                  権限を変更すると管理者から解除され、管理することができなくなりますがよろしいでしょうか？
                </div>
              </div>
            </div>
          </div>
          <div class="c-formBlock">
            <div class="c-formBlock__label">組織タグ</div>
            <div class="m-select-organization-tags">
              <DgrSelectbox
                class="m-organization-tags"
                :options="existingOrganizationTags"
                :placeholder="organizationTagSelectBoxLabel"
                :disabled="!organizationTagSelectBoxEnabled"
                :model-value="organizationTagSelectedValue"
                @update:model-value="selectOrganizationTag"
                options-max-height="200px"
              ></DgrSelectbox>
            </div>
            <OrganizationTagLabels
              @delete-tag="deleteOrganizationTag"
              :labels="addedOrganizationTags"
              class="m-added-organization-tags"
              deletable
            />
          </div>
        </div>
      </template>
      <template #footer>
        <div class="m-footer">
          <div class="m-footer-buttons">
            <button
              class="m-cancel-button c-btn c-btn--auto c-btnOutline"
              @click="hideUserEditModal"
            >
              キャンセル
            </button>
            <LoadableButton
              class="m-create-button c-btn c-btn--auto c-btn--AnewsPrimary"
              :class="{ disabled: !enableToEdit }"
              :disabled="!enableToEdit"
              @click="editUser"
              :is-loading="isEditing"
            >
              {{ buttonText }}
            </LoadableButton>
          </div>
        </div>
      </template>
    </sm-dialog>
  </div>
</template>
<style lang="scss" scoped>
.m-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  pre.m-header-text {
    font-size: 14px;
    height: 40px;
    line-height: 40px;
  }
}

.m-body {
  margin-bottom: -20px;
  .m-input {
    margin-bottom: 8px;
  }
  .o-normal-text-area {
    border: 1px solid #e6e6e6;
  }
  .m-description {
    color: #b3b3b3;
    margin-top: -5px;
    margin-bottom: 7px;
  }
  .m-role {
    display: block;
    max-width: 80%;
    margin-bottom: 8px;
  }
  .m-contract {
    display: block;
    max-width: 80%;
    margin-bottom: 8px;
  }
}
.m-footer-buttons {
  display: flex;
  flex-direction: row;
  .m-create-button,
  .m-cancel-button {
    height: 40px;
    width: 120px;
    outline: none;
  }
  .m-create-button {
    margin-left: 16px;
  }
}
:deep(.modal-body) {
  padding-top: 10px;
}

.deleted-user-error-message {
  color: $color-orange1000;
  margin-bottom: 20px;
}

.add-contract-error-message {
  color: $color-orange1000;
  margin-bottom: 20px;
  white-space: pre-wrap;
}

.contract-select-option {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 4px;
  .contact-count {
    color: $color-gray800;
  }
  .current-contract {
    display: flex;
    align-items: center;
    justify-content: start;
    color: $color-green600;
    fill: $color-green600;
  }
}

.m-select-organization-tags {
  display: flex;
  gap: 4px;
  margin-bottom: 8px;
}

.m-organization-tags {
  max-width: 80%;
  flex-grow: 1;
}

.m-added-organization-tags {
  min-height: 40px;
  max-height: 120px;
  padding-left: 8px;
  overflow-y: auto;
}
.body-wrapper {
  max-height: 70vh;
  overflow-y: auto;
}
</style>
