<script setup lang="ts">
import { computed, onMounted, reactive, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import api from '@/api';
import { TrackingDataProps } from '@/api/tracking';
import noImage from '@/assets/noimage-4to3.jpg';
import { MYPAGE_ROUTE_NAMES } from '@/routers/constants';
import AdpDocumentCardSkeleton from '@/components/common/adp-document/adp-document-card-skeleton.vue';
import LoadableButton from '@/components/common/atoms/loadable-button.vue';
import MentionAutocompleteTextarea from '@/components/common/molecules/mention-autocomplete-textarea.vue';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import GroupSelect from '@/components/modals/group-select.vue';
import Modal from '@/components/modals/modal.vue';
import { Article, Group, UrlPreview } from '@/types';
import { formatDate } from '@/utils/formatters';
import { isValidHttpUrl } from '@/utils/parsers';
import { userSession } from '@/utils/userSession';
import { useStore } from '@/utils/vue';

const store = useStore();
const route = useRoute();
const router = useRouter();
const { createSnackbar } = useSnackbar();
const trimmedUrl = ref('');
const commentTextRef = ref<InstanceType<typeof MentionAutocompleteTextarea>>();
const urlInput = ref<HTMLInputElement>();

onMounted(() => urlInput.value?.focus());

const isUrlValid = computed(() => {
  return isValidHttpUrl(trimmedUrl.value);
});

const loadingPreview = ref(false);
const hasError = ref(false);
const preview = ref<UrlPreview>({});
const article = reactive({ value: {} as Article });
const isSharing = ref(false);

const initialGroupId = userSession.getLastUsedGroupId();

watch(
  () => trimmedUrl.value,
  async () => {
    hasError.value = false;
    loadingPreview.value = true;
    preview.value = {};
    if (trimmedUrl.value.length > 0 && isUrlValid.value) {
      try {
        preview.value = await api.fetchUrlPreview(trimmedUrl.value);
      } catch {
        hasError.value = true;
      }
    }
    loadingPreview.value = false;
  },
);

const hasPreview = computed(() => preview.value?.title !== undefined);

const selectedGroup = ref(undefined as Group | undefined);
const setSelectedGroup = (group: Group) => {
  selectedGroup.value = group;
};

const shareEnabled = computed(() => {
  return isUrlValid.value && hasPreview.value && !isSharing.value;
});

const hideModal = () => {
  if (isSharing.value === false) {
    store.commit('modal/hideModal');
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const postMark = async (trackingData: any) => {
  const myUserId = userSession.getUserId();
  const existingMark = article.value.marks.find(m => m.user_id === myUserId);
  const mark = existingMark
    ? await api.updateMark(existingMark.id)
    : await api.sendMark(article.value, null, 'article_share', trackingData);
  if (existingMark) {
    const index = article.value.marks.findIndex(m => m.user_id === myUserId);
    article.value.marks[index] = mark;
  } else {
    article.value.marks.push(mark);
  }
  store.commit('modal/setArticle', article.value);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const postComment = async (trackingData: any) => {
  if (commentTextRef.value) {
    const comment = commentTextRef.value.trimmedComment;
    const mention = commentTextRef.value.mention;
    if (comment.length > 0) {
      const commentData = await api.sendComment({
        groupId: selectedGroup.value!.id,
        adpDocument: article.value,
        content: comment,
        mention,
        trackingBaseData: trackingData,
      });
      store.commit('modal/setComment', commentData);
    }
  }
};

const share = async () => {
  if (shareEnabled.value && selectedGroup.value !== undefined) {
    isSharing.value = true;
    const trackingData: TrackingDataProps = {
      pageName: 'article_share',
      pageUrl: route.fullPath,
    };
    article.value = await api.createArticleShare(
      trimmedUrl.value,
      trackingData,
    );
    await postMark(trackingData);
    await postComment(trackingData);

    const groupId = selectedGroup.value.id;
    if (initialGroupId !== groupId) {
      userSession.setLastUsedGroupId(groupId);
    }
    createSnackbar({
      message: '外部リンクを共有しました',
      type: 'success',
    });

    const commentText = commentTextRef.value?.trimmedComment;

    if (commentText !== '') {
      moveToGroupDetail();
    } else {
      moveToUserMark();
    }
  }
  isSharing.value = false;
  hideModal();
};

// TODO::Featureが取れたら命名を再検討する
const moveToGroupDetail = () => {
  const routePramGroupId = route.params.groupId;
  const routeName = route.name ?? '';
  if (
    routeName === 'groupActivity' &&
    Number(routePramGroupId) === selectedGroup.value!.id
  ) {
    store.commit('modal/setIsSharing', true);
  } else {
    router.push({
      name: 'groupActivity',
      params: { groupId: String(selectedGroup.value!.id) },
    });
  }
};

const moveToUserMark = () => {
  const routeName = route.name ?? '';
  if (routeName === MYPAGE_ROUTE_NAMES.marks) {
    store.commit('modal/setIsSharing', true);
  } else {
    router.push({
      name: MYPAGE_ROUTE_NAMES.marks,
    });
  }
};

const callPostComment = () => {
  if (commentTextRef.value?.trimmedComment !== '') {
    commentTextRef.value?.postComment();
  } else {
    share();
  }
};
</script>

<template>
  <Modal class="m-article-share" @close="hideModal">
    <template #header>
      <div class="m-header">
        <div class="m-header-text c-text c-text--m">
          URLを入力して、おすすめの記事を共有しよう
        </div>
      </div>
    </template>
    <template #body>
      <div class="m-body">
        <input
          class="m-url-input c-text c-text--m c-textInput"
          ref="urlInput"
          :class="isUrlValid ? 'm-normal-text-area' : 'c-formInput--error'"
          placeholder="https://..."
          v-model.trim="trimmedUrl"
        />
        <div class="m-article-and-comment-container" v-show="shareEnabled">
          <a
            class="m-article-card"
            :href="preview.url"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div class="m-article-data">
              <div class="m-article-site-name c-title c-title--s">
                {{ preview.media_name }}
              </div>
              <div class="m-article-title c-text c-text-xm">
                {{ preview.title }}
              </div>
              <div class="m-article-site-name c-text c-text--s">
                {{
                  preview.published_at ? formatDate(preview.published_at) : ''
                }}
              </div>
            </div>
            <div class="m-article-image-wrap">
              <img
                :src="preview.image_url"
                @error="preview.image_url = noImage"
              />
            </div>
          </a>
          <div class="o-group-select">
            <GroupSelect
              @selected="setSelectedGroup"
              options-max-height="150px"
              :initial-group-id="initialGroupId"
            ></GroupSelect>
            <div class="o-group-select__text c-text c-text--m">
              にコメントを投稿
            </div>
          </div>
          <MentionAutocompleteTextarea
            class="o-comment-text-area"
            ref="commentTextRef"
            :auto-focus="false"
            :placeholder="'コメントを書く（任意）'"
            :disabled="isSharing"
            @post-comment="share"
          ></MentionAutocompleteTextarea>
        </div>
        <div
          class="o-error c-formBlock__text c-formBlock__text--error"
          v-if="hasError"
        >
          URLから情報を取得できませんでした。再度失敗する場合はお問合せください。
        </div>
        <AdpDocumentCardSkeleton
          class="m-article-card-skeleton"
          v-else-if="!shareEnabled"
          :loading="loadingPreview"
        ></AdpDocumentCardSkeleton>
        <div class="m-footer">
          <div class="m-footer-buttons" v-if="hasPreview">
            <button
              class="m-cancel-button c-btn c-btn--auto c-btnOutline"
              :class="{ disabled: isSharing }"
              :disabled="isSharing"
              @click="hideModal"
            >
              キャンセル
            </button>
            <LoadableButton
              class="m-share-button c-btn c-btn--auto c-btn--AnewsPrimary"
              :class="{ disabled: !shareEnabled }"
              :disabled="!shareEnabled"
              @click="callPostComment"
              :is-loading="isSharing"
            >
              共有する
            </LoadableButton>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>
<style lang="scss" scoped>
.m-article-share {
  .m-body {
    box-sizing: border-box;
    padding: 16px 0;

    .m-url-input {
      width: 616px;
      margin: 0 !important;
    }

    .m-article-card,
    .m-article-card-skeleton {
      border: 1px solid #e6e6e6;
      border-radius: 4px;
      margin-top: 8px;
    }
    .m-article-card {
      display: flex;
      flex-direction: row;
      padding: 16px;
      margin-bottom: 8px;
      justify-content: space-between;

      .m-article-image-wrap {
        width: 80px;
        height: 80px;
        img {
          width: 80px;
          height: 80px;
          object-fit: cover;
          object-position: 50% 25%;
          border: 1px solid #e6e6e6;
          aspect-ratio: 1/1;
          border-radius: 4px;
        }
      }
      .m-article-data {
        display: flex;
        flex-direction: column;
        .m-article-title {
          font-size: 16px;
          line-height: 22px;
          max-width: 440px;
        }
        .m-article-site-name {
          color: #b3b3b3;
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
    .o-group-select {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      .o-group-select__text {
        margin-left: 8px;
      }
    }

    .o-error {
      margin-top: 8px;
    }
  }
  .m-footer {
    margin-top: 32px;

    .m-footer-buttons {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      button {
        min-width: 120px;
        margin-bottom: 0;
      }

      .m-share-button {
        margin-left: 16px;
      }
    }
  }
}
</style>
