import { AdpDocument } from '@/types';

export const selectArticle = (
  e: MouseEvent,
  article: AdpDocument,
  selectedArticles: AdpDocument[],
): AdpDocument[] => {
  if (e.metaKey || e.ctrlKey) {
    if (selectedArticles.map(a => a.id).includes(article.id)) {
      return selectedArticles.filter(a => a.id !== article.id);
    }
    return [...selectedArticles, article];
  }

  if (selectedArticles.length === 1 && selectedArticles[0].id === article.id) {
    return [];
  }
  return [article];
};
