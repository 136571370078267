<script lang="ts">
import { computed, defineComponent, PropType, ref, toRefs } from 'vue';
import { Feature, PageName } from '@/api/tracking';
import AdpDocumentCard from '@/components/common/adp-document-card.vue';
import BasicEmptyFeed from '@/components/common/basic-empty-feed.vue';
import ThemeEmptyFeed from '@/components/common/theme-empty-feed.vue';
import {
  AdpDocument,
  ArticleMarkSource,
  DocType,
  FeedType,
  Theme,
  ThemeFeedType,
} from '@/types';
import { docTypeDisplayNameMap } from '@/utils/adpDocument';
import { useThemeDisabledOption } from '@/utils/composables/useThemeDisabledOption';

export default defineComponent({
  components: {
    AdpDocumentCard,
    BasicEmptyFeed,
    ThemeEmptyFeed,
  },
  props: {
    title: { type: String, required: true },
    formattedFeedDate: { type: String },
    displayFeedType: { type: String as PropType<FeedType>, required: true },
    themeFeedType: { type: String as PropType<ThemeFeedType>, required: true },
    docType: { type: String as PropType<DocType>, required: true },
    adpDocuments: {
      type: Array as PropType<AdpDocument[]>,
      required: true,
    },
    initialFeedCount: { type: Number, default: 20 },
    maxFeedCount: { type: Number, default: 200 },
    theme: { type: Object as PropType<Theme> },
    enableToEditTheme: { type: Boolean },
    pageName: { type: String as PropType<PageName>, required: true },
    feature: { type: String as PropType<Feature>, required: true },
    markSource: { type: String as PropType<ArticleMarkSource> },
  },
  setup(props) {
    const { adpDocuments } = toRefs(props);

    const isShowAllButtonVisible = ref(
      adpDocuments.value.length > props.initialFeedCount,
    );
    const visibleAdpDocuments = computed(() =>
      adpDocuments.value.slice(
        0,
        isShowAllButtonVisible.value
          ? props.initialFeedCount
          : props.maxFeedCount,
      ),
    );
    const loadAllAdpDocuments = () => (isShowAllButtonVisible.value = false);

    const noFeedMessage = `${
      docTypeDisplayNameMap[props.docType]
    }はありませんでした。`;

    const { disabledOption } = props.theme
      ? useThemeDisabledOption({
          theme: props.theme,
          themeFeedType: props.themeFeedType,
          docType: props.docType,
        })
      : { disabledOption: undefined };

    return {
      isShowAllButtonVisible,
      visibleAdpDocuments,
      loadAllAdpDocuments,
      noFeedMessage,
      disabledOption,
    };
  },
});
</script>

<template>
  <div class="document-list">
    <div class="title c-title c-title--xm">
      <div class="title-text">{{ title }}</div>
      <div v-if="formattedFeedDate" class="feed-date c-text c-text--m">
        {{ formattedFeedDate }}
      </div>
      <div v-if="!disabledOption" class="feed-count c-text c-text--s">
        ・ {{ adpDocuments.length }}件
      </div>
    </div>
    <div class="divider"></div>
    <div
      class="document-card-wrapper"
      v-for="(document, index) in visibleAdpDocuments"
      :key="document.id"
    >
      <AdpDocumentCard
        width="100%"
        :adp-document="document"
        :theme-id="document.theme_id"
        :page-name="pageName"
        :feature="feature"
        :mark-source="markSource"
        :disable-related-articles="false"
      ></AdpDocumentCard>
      <div
        v-if="index !== visibleAdpDocuments.length - 1"
        class="divider"
      ></div>
    </div>

    <div v-if="isShowAllButtonVisible" class="view-more-button__wrapper">
      <div
        class="view-more-button c-text c-text--m"
        @click="() => loadAllAdpDocuments()"
      >
        すべて表示{{ `（${adpDocuments.length - initialFeedCount}）` }}
      </div>
    </div>

    <template v-if="adpDocuments.length === 0">
      <ThemeEmptyFeed
        v-if="theme"
        :display-feed-type="displayFeedType"
        :theme-feed-type="themeFeedType"
        :doc-type="docType"
        :theme="theme"
        :enable-to-edit-theme="enableToEditTheme"
        >{{ noFeedMessage }}</ThemeEmptyFeed
      >
      <BasicEmptyFeed v-else>{{ noFeedMessage }}</BasicEmptyFeed>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.document-list {
  background: #fff;
  border-radius: 4px;

  .title {
    display: flex;
    position: sticky;
    top: 112px;
    z-index: 1;
    align-items: center;
    padding-left: 16px;
    border-radius: 4px;
    background: #fff;

    .title-text {
      padding-top: 10px;
      padding-bottom: 10px;
    }
    .feed-date {
      padding: 10px 0 10px 12px;
    }
    .feed-count {
      color: $color-gray600;
    }
  }

  .divider {
    width: auto;
    border-bottom: 0.5px solid $color-gray400;
    margin: 0px 16px 0px 16px;
  }

  .document-card-wrapper {
    margin-bottom: 8px;
    border-radius: 4px;

    .document-card {
      border: 0;
    }
  }

  .view-more-button__wrapper {
    text-align: center;
  }
  .view-more-button {
    display: inline-flex;
    align-self: center;
    margin-bottom: 16px;
    padding: 4px 12px;
    border-radius: 4px;
    border: 1px solid #1da482;
    background: #fff;
    color: #1da482;
    cursor: pointer;
  }
}
</style>
