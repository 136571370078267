<script setup lang="ts">
import { computed } from 'vue';
import { pestNames, pests } from '@/constants';
import type { NewsCategoriesByPest } from '@/utils/news_category';

interface Props {
  newsCategoriesByPest: NewsCategoriesByPest;
}
const props = defineProps<Props>();

const newsCategoriesForDisplay = computed(() => {
  return pests
    .map(pest => ({
      pestCategoryName: pestNames[pest],
      categories: props.newsCategoriesByPest[pest],
    }))
    .filter(({ categories }) => categories.length > 0);
});
</script>

<template>
  <dl class="container">
    <div
      v-for="{ pestCategoryName, categories } in newsCategoriesForDisplay"
      :key="pestCategoryName"
    >
      <dt class="pest-title c-text c-text--s">
        {{ pestCategoryName }}
      </dt>
      <div class="pest-categories-container c-text c-text--m">
        <dd v-for="category in categories" :key="category">
          {{ category }}
        </dd>
      </div>
    </div>
  </dl>
</template>

<style scoped lang="scss">
.container {
  box-sizing: border-box;
  padding: 16px;
  background-color: $color-white;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.pest-title {
  margin-bottom: 8px;
}

.pest-categories-container {
  display: flex;
  column-gap: 8px;
  flex-wrap: wrap;
}
</style>
