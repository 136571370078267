<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import api from '@/api';
import TechnicalLiteratureFeed from '@/components/common/technical-literature/technical-literature-feed.vue';
import { FeedType, ThemeFeedResearchPaper } from '@/types';
import { useTheme, useThemeResearchPaperFeed, useUserInfo } from '@/utils/swr';
import { useStore } from '@/utils/vue';

const router = useRouter();
const route = useRoute();
const store = useStore();

const { data: userInfo } = useUserInfo();
const feedType = computed(() => store.state.feedType.feedType as FeedType);
const themeId = computed(() => Number(route.params.themeId));
const { data: themeResponse } = useTheme(themeId.value);
const theme = computed(() => themeResponse.value?.themes);

const isForeignMediaEnabled = computed(
  () => theme.value?.is_foreign_media_enabled ?? false,
);

const feedCount = computed(() => {
  if (feedType.value === 'foreign' && !isForeignMediaEnabled.value) {
    // 海外メディア設定がオフの場合、カウントを表示しない
    return undefined;
  }
  const counts = theme.value?.research_paper_feed_counts ?? {
    domestic: 0,
    foreign: 0,
  };
  return feedType.value === 'all'
    ? counts.domestic + counts.foreign
    : counts[feedType.value];
});

const redirectToKeywordFeed = () => {
  router.push({
    name: 'themeFeed',
    params: { themeId: String(themeId.value) },
  });
};

watch(
  () => theme.value,
  () => {
    if (theme.value && !theme.value?.is_research_paper_enabled) {
      redirectToKeywordFeed();
    }
  },
);

onMounted(async () => {
  api.trackPageView({
    pageName: 'research_papers',
    pageUrl: route.fullPath,
    theme: {
      id: themeId.value,
    },
    feedType: feedType.value,
  });
});

const enableToEditTheme = computed(
  () =>
    userInfo.value?.role !== 'viewer' ||
    (theme.value?.access_scope === 'personal' &&
      theme.value?.user_id === userInfo.value?.id),
);

// useThemeResearchPaperFeedは表示するメディア（`feedType`）と取得するフィード（'domestic'・'foreign'）を
// 参照してリクエストするかどうかを判定する
// たとえばfeedType=foreignと'domestic'の場合、keyはundefinedになってリクエストを発生しない
// undefinedの場合、stateはpendingになるのでテンプレートも表示するメディアも確認する
const { data: domesticResponse, state: domesticState } =
  useThemeResearchPaperFeed(feedType, themeId.value, 'domestic', ref(1), 200);
const { data: foreignResponse, state: foreignState } =
  useThemeResearchPaperFeed(feedType, themeId.value, 'foreign', ref(1), 200);

const domesticPapers = computed(() => ({
  items:
    domesticResponse.value?.research_paper_feeds ??
    ([] as ThemeFeedResearchPaper[]),
  state: domesticState.value,
}));

const foreignPapers = computed(() => ({
  items:
    foreignResponse.value?.research_paper_feeds ??
    ([] as ThemeFeedResearchPaper[]),
  state: foreignState.value,
}));

const feedDate = computed(
  () => theme.value?.research_paper_feed_last_updated_at ?? undefined,
);
</script>

<template>
  <TechnicalLiteratureFeed
    v-if="theme"
    feed="theme"
    doc-type="research_paper"
    :feed-type="feedType"
    :feed-count="feedCount"
    :feed-date="feedDate"
    :domestic-documents="domesticPapers"
    :foreign-documents="foreignPapers"
    :theme="theme"
    :enable-to-edit-theme="enableToEditTheme"
  />
</template>

<style lang="scss" scoped></style>
