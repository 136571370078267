import { GroupActivitySortType } from '@/types';

export const groupActivitiesQueryItem = {
  getComment(): 'showComment' | 'hideComment' | null {
    return localStorage.getItem(`selectedIsShowCommentFilterItem`) as
      | 'showComment'
      | 'hideComment'
      | null;
  },
  setComment(value: 'showComment' | 'hideComment'): void {
    localStorage.setItem(`selectedIsShowCommentFilterItem`, value);
  },
  getSort(): GroupActivitySortType | null {
    return localStorage.getItem(
      'selectedSortFilterItem',
    ) as GroupActivitySortType | null;
  },
  setSort(value: GroupActivitySortType): void {
    localStorage.setItem('selectedSortFilterItem', value);
  },
};
