<script setup lang="ts">
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import api from '@/api';
import { TrackingBaseData } from '@/api/tracking';
import AdpDocumentCard from '@/components/common/adp-document-card.vue';
import LoadableButton from '@/components/common/atoms/loadable-button.vue';
import MentionAutocompleteTextarea from '@/components/common/molecules/mention-autocomplete-textarea.vue';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import GroupSelect from '@/components/modals/group-select.vue';
import LatestCommentDisplay from '@/components/modals/latest-comment-display.vue';
import Modal from '@/components/modals/modal.vue';
import { AdpDocument, Group } from '@/types';
import { useCurrentRoutePath } from '@/utils/composables/useCurrentRoutePath';
import { useUserInfo } from '@/utils/swr';
import { userSession } from '@/utils/userSession';
import { useEmitter, useStore } from '@/utils/vue';

const store = useStore();
const route = useRoute();
const emitter = useEmitter();
const router = useRouter();
const { createSnackbar } = useSnackbar();
const { data: user } = useUserInfo();
const { getCurrentRoutePath } = useCurrentRoutePath();

// FIXME: モーダルを開いている時点でmodal.articleに値は入っているため、undefinedになる場合を考慮しない。要リファクタリング
/* eslint-disable @typescript-eslint/no-non-null-assertion */
const article = store.state.modal.article!;
const theme = store.state.modal.theme!;
const pageName = store.state.modal.pageName!;
const feature = store.state.modal.feature!;
const feedType = store.state.modal.feedType!;
const rankInWholeFeed = store.state.modal.rankInWholeFeed!;
const searchData = store.state.modal.searchData!;
const industryData = store.state.modal.industryData!;
const description = store.state.modal.description!;
const contentsContext = store.state.modal.contentsContext!;
const execution = store.state.modal.execution;
/* eslint-enable @typescript-eslint/no-non-null-assertion */
const group = store.state.modal.group;

const initialGroupId = group?.id ?? userSession.getLastUsedGroupId();

const selectedGroup = ref<Group | undefined>(undefined);
const setSelectedGroup = (group: Group) => (selectedGroup.value = group);

const commentKey = `comment-${article.id}`;
const initialComment = localStorage.getItem(commentKey) ?? undefined;
const commentTextRef = ref<InstanceType<typeof MentionAutocompleteTextarea>>();

const getCommentContent = computed(
  () => (commentTextRef.value ?? { trimmedComment: '' }).trimmedComment,
);

const isPostingComment = ref(false);

const isPostEnabled = computed(() => {
  return (
    getCommentContent.value.length > 0 &&
    selectedGroup.value !== undefined &&
    !isPostingComment.value
  );
});

const postComment = async (
  groupId: number,
  trackingBaseData: TrackingBaseData,
  article?: AdpDocument,
  themeId?: number,
  rankInWholeFeed?: number,
) => {
  if (commentTextRef.value) {
    const comment = commentTextRef.value.trimmedComment;
    const mention = commentTextRef.value.mention;
    if (comment.length > 0) {
      try {
        const createdComment = await api.sendComment({
          groupId,
          adpDocument: article,
          themeId,
          rankInWholeFeed,
          content: comment,
          mention,
          trackingBaseData,
          searchData,
          industryData,
          description,
          contentsContext,
          execution,
        });
        article?.comments.push(createdComment);
        emitter.emit('article-updated', article);
        emitter.emit('comment-created', {
          ...createdComment,
          article,
        });
        createSnackbar({
          message: 'コメントを投稿しました',
          type: 'success',
        });
      } catch {
        createSnackbar({
          message: 'コメントを投稿できませんでした',
          type: 'error',
        });
      }
    }
  }
};

const post = async () => {
  if (isPostEnabled.value) {
    isPostingComment.value = true;
    let pageGroupIdParam = route.params.groupId;
    if (pageGroupIdParam instanceof Array) {
      pageGroupIdParam = pageGroupIdParam[0];
    }
    const groupId = selectedGroup.value?.id;
    if (groupId !== undefined && initialGroupId !== groupId) {
      userSession.setLastUsedGroupId(groupId);
    }
    const trackingData = {
      pageName: pageName ?? 'comment_creation',
      feature: feature,
      pageUrl: getCurrentRoutePath(),
      feedType: feedType,
      pageGroupId: pageGroupIdParam ? parseInt(pageGroupIdParam) : undefined,
    };
    await postComment(
      // Note: isPostEnabled.valueの中でselectedGroup.valueがundefinedでないことが保証されている
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      selectedGroup.value!.id,
      trackingData,
      article,
      theme?.id,
      rankInWholeFeed,
    );
    isPostingComment.value = false;
    localStorage.removeItem(commentKey);
    hideModal();
  }
};

const callPostComment = () => {
  if (commentTextRef.value?.trimmedComment !== '') {
    commentTextRef.value?.postComment();
  } else {
    post();
  }
};

const hideModal = (saveComment = false) => {
  if (isPostingComment.value === false) {
    if (saveComment && commentTextRef.value?.trimmedComment) {
      localStorage.setItem(commentKey, commentTextRef.value?.trimmedComment);
    }
    store.commit('modal/hideModal');
  }
};

const moveToAllComments = () => {
  let pageName = '';
  if (article.doc_type === 'research_paper') {
    pageName = 'researchPaperPage';
  } else if (article.doc_type === 'patent') {
    pageName = 'patentPage';
  } else {
    pageName = 'articlePage';
  }
  router.push({
    name: pageName,
    params: { id: String(article.id), doc_type: article.doc_type },
  });
  hideModal();
};
</script>

<template>
  <Modal
    class="m-article-share"
    @close="clickedCloseButton => hideModal(!clickedCloseButton)"
  >
    <template #header>
      <div class="m-header">
        <div class="m-header-text c-text c-text--m">コメント投稿</div>
      </div>
    </template>
    <template #body>
      <div class="m-body">
        <div class="user-info" v-if="user">
          <img class="user-icon" :src="user.image_url" />
          <div class="username c-title c-title--m">{{ user.user_name }}</div>
        </div>
        <div class="o-group-select">
          <GroupSelect
            @selected="setSelectedGroup"
            options-max-height="240px"
            :initial-group-id="initialGroupId"
          ></GroupSelect>
          <div class="o-group-select__text c-text c-text--m">
            にコメントを投稿
          </div>
        </div>
        <MentionAutocompleteTextarea
          class="o-comment-text-area"
          ref="commentTextRef"
          :placeholder="'コメントを書く'"
          :initial-comment="initialComment"
          :disabled="isPostingComment"
          @post-comment="post"
        ></MentionAutocompleteTextarea>
        <AdpDocumentCard
          :adp-document="article"
          :theme-id="theme ? theme.id : undefined"
          :show-comment="false"
          :show-user-action="false"
          page-name="comment_creation"
          :feature="feature"
        ></AdpDocumentCard>
        <div class="m-buttons">
          <LoadableButton
            class="m-post-button c-btn c-btn--auto c-btn--AnewsPrimary"
            :class="{ disabled: !isPostEnabled }"
            :disabled="!isPostEnabled"
            @click="callPostComment"
            :is-loading="isPostingComment"
          >
            コメントを投稿
          </LoadableButton>
        </div>
        <div
          class="latest-comment"
          v-if="article.comments.length > 0 && user && pageName !== 'article'"
        >
          <div class="spacing-08"></div>
          <LatestCommentDisplay :article="article" :user="user" :theme="theme">
          </LatestCommentDisplay>
          <div class="spacing-12"></div>
          <div class="comment-link">
            <button
              class="outlined c-outlineBtn c-btn--auto c-btn--small"
              @click="moveToAllComments"
            >
              全てのコメントを見る（返信する）
            </button>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>
<style lang="scss" scoped>
.m-article-share {
  .m-body {
    box-sizing: border-box;
    padding: 16px 0;

    .user-info {
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      .user-icon {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        margin-right: 8px;
      }
    }
    .o-group-select {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      .o-group-select__text {
        margin-left: 8px;
      }
    }
    .comment-link {
      display: flex;
      justify-content: flex-end;
      button {
        border: solid 1px $color-green600;
        color: $color-green600;
        background-color: #fff;
      }
      button:hover {
        background-color: $color-green100;
      }
    }
    .o-comment-text-area {
      margin-bottom: 8px;
    }

    .o-error {
      margin-top: 8px;
    }
  }

  .m-buttons {
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    button {
      margin-bottom: 0;
    }
  }
}
</style>
