<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { DgrIcon, DgrLoading } from '@stockmarkteam/donguri-ui';
import CitationSourceNumberBadge from '@/components/search/summary/citation-source-number-badge.vue';
import { AdpDocument, UserDocument } from '@/types';
import {
  CitationSource,
  SummaryCitation,
  SummaryCitationSource,
  SummaryMessage,
} from '@/types';

type DisplaySummary =
  | {
      type: 'message';
      text: string;
      accumulatedWordCount: number;
    }
  | {
      type: 'citations';
      citations: {
        text: string;
        accumulatedWordCount: number;
        sources: {
          sourceDocument: AdpDocument | UserDocument;
          citationNumber: number;
          accumulatedWordCount: number;
        }[];
      }[];
    }
  | undefined;

interface Props {
  responses: Array<
    SummaryMessage | { type: 'citations'; citations: SummaryCitation[] }
  >;
  citationSources: Array<CitationSource>;
  animationDelayPerChar: number;
}

const props = defineProps<Props>();

// NOTE: デモでも実際の要約生成のように見せるために、ローディングを行う
const SUMMARY_DUMMY_LOADING_WAIT_MS = 1500;
const isLoading = ref(true);
onMounted(() => {
  setTimeout(() => {
    isLoading.value = false;
  }, SUMMARY_DUMMY_LOADING_WAIT_MS);
});

const getSources = (sources: SummaryCitationSource[]) => {
  return sources
    .map(source =>
      props.citationSources.find(({ index: i }) => i === source.index),
    )
    .filter(cs => cs !== undefined) as CitationSource[];
};

const displaySummaries = computed<DisplaySummary[]>(() => {
  let accumulatedWordCount = 0;
  return props.responses.map(response => {
    if (response.type === 'message') {
      const displayMessage = {
        type: response.type,
        text: response.text,
        accumulatedWordCount: accumulatedWordCount,
      };
      accumulatedWordCount += response.text.length;
      return displayMessage;
    } else if (response.type === 'citations') {
      return {
        type: response.type,
        citations: response.citations.map(citation => {
          const displayCitation = {
            text: citation.text,
            accumulatedWordCount: accumulatedWordCount,
            sources: getSources(citation.sources).map(source => {
              const displaySource = {
                sourceDocument: source.sourceDocument,
                citationNumber: source.citationNumber,
                accumulatedWordCount: (accumulatedWordCount +=
                  citation.text.length),
              };
              accumulatedWordCount += 1;
              return displaySource;
            }),
          };
          return displayCitation;
        }),
      };
    }
  });
});
</script>

<template>
  <div class="summary-display">
    <div class="summary-header">
      <div class="summary-title c-title c-title--m">
        <DgrIcon name="sparkles-fill" />
        検索結果の要約
      </div>
    </div>
    <div class="spacing-08"></div>
    <div class="loading" v-if="isLoading">
      <span class="c-text c-text--m">回答を生成しています...</span>
      <DgrLoading class="loading-spinner" />
    </div>
    <ul
      class="citations c-text c-text--m"
      v-else
      v-for="(summary, i) in displaySummaries"
      :key="`citation-${i}`"
    >
      <div
        v-if="summary && summary.type === 'message'"
        :aria-label="summary.text"
        class="citations-message"
      >
        <span
          v-for="(char, l) in summary.text"
          :key="`${char}-${l}`"
          class="animation-delay"
          :style="{
            animationDelay: `${
              (l + summary.accumulatedWordCount) * animationDelayPerChar
            }s`,
          }"
          >{{ char }}</span
        >
      </div>
      <template v-else-if="summary && summary.type === 'citations'">
        <li
          v-for="(citation, j) in summary.citations"
          :key="`citation-${i}-${citation.text}`"
          class="animation-delay"
          :aria-label="citation.text"
          :style="{
            animationDelay: `${
              (j + citation.accumulatedWordCount) * animationDelayPerChar
            }s`,
          }"
        >
          <div class="citation-list-content">
            <span aria-hidden="true" class="citation-text-line">
              <span
                class="animation-delay"
                v-for="(char, k) in citation.text"
                :key="`${char}-${k}`"
                :style="{
                  animationDelay: `${
                    (k + citation.accumulatedWordCount) * animationDelayPerChar
                  }s`,
                }"
                >{{ char }}</span
              >
              <template v-if="citation.sources">
                <template v-for="source in citation.sources">
                  <!-- eslint-disable vue/require-explicit-emits -->
                  <CitationSourceNumberBadge
                    class="animation-delay citation-number"
                    :style="{
                      animationDelay: `${
                        source.accumulatedWordCount * animationDelayPerChar
                      }s`,
                    }"
                    v-if="source.sourceDocument"
                    :key="source.citationNumber"
                    :number="source.citationNumber"
                    :source-document="source.sourceDocument"
                    @link-clicked="v => $emit('citation-link-clicked', v)"
                  />
                  <!-- eslint-enable vue/require-explicit-emits -->
                </template>
              </template>
            </span>
          </div>
        </li>
      </template>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.summary-display {
  padding: 16px 0px;
  border-bottom: 1px solid $color-gray400;
  .summary-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 28px;
    .summary-title {
      display: flex;
      color: $color-green600;
      white-space: nowrap;
    }
  }
  ul.citations {
    display: flex;
    flex-direction: column;
    gap: 6px;
    .citations-message {
      margin-bottom: 6px;
    }
    li {
      font-weight: 400;
      margin-left: 2em;
      .citation-list-content {
        .citation-text-line {
          .citation-number {
            display: inline-block;
            margin: 0 2px;
            line-height: 16px;
          }
        }
      }
    }
  }
}
.animation-delay {
  animation: text-animation 1s steps(1, end) forwards;
  opacity: 0;
}

@keyframes text-animation {
  to {
    opacity: 1;
  }
}
</style>
