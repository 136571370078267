<script setup lang="ts">
/**
 * アクションメニュー等に使用する汎用balloon
 * relativeな要素の以下に配置することを想定
 * 親要素には高さ・幅がstyleにより明示指定されていることを想定
 * 原点は親要素の左上とする
 * */
import { computed, nextTick, onUnmounted, ref, watch } from 'vue';

type HorizontalPositionValue = 'left' | 'right' | 'center';
type VerticalPositionValue = 'top' | 'bottom' | 'center';
type CompoundPosition = `${HorizontalPositionValue} ${VerticalPositionValue}`;
type BalloonPosition = HorizontalPositionValue | CompoundPosition;
interface Props {
  /** 親位置に対しての表示位置 */
  balloonPosition?: BalloonPosition;
  /** style positionの設定 */
  position?: 'absolute' | 'fixed' | 'relative';
  /** 表示フラグ */
  isShow: boolean;
  /**
   * 表示中にスクロールを禁止するフラグ
   * Fixedを使用する場合は利用推奨
   */
  scrollLock?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  balloonPosition: `left center`,
  position: 'fixed',
  isShow: false,
  scrollLock: true,
});

/** イベント通知 / バルーンの外側をクリックしたとき */
const emit = defineEmits(['onClickOutside']);

/** スクロール禁止処理 */
const preventMouseWheelEvent = (event: WheelEvent) => {
  if (!props.scrollLock) return; // スクロール禁止処理がオフの場合は何もしない
  event.preventDefault();
};

/** バルーンの外側がクリックされたか */
const onClickOutside = (event: MouseEvent) => {
  if (!actionBalloon.value) return false;
  if (!(actionBalloon.value as HTMLElement).contains(event.target as Node)) {
    emit('onClickOutside');
  }
};

/** 表示状態を監視する */
watch(
  () => props.isShow,
  newVal => {
    if (newVal) {
      // バルーン表示時にスクロール処理を禁止する
      document.addEventListener('wheel', preventMouseWheelEvent, {
        passive: false,
      });
      // 表示時のクリックがトリガーになって発火しないようにするための遅延処理
      setTimeout(() => {
        // バルーンの外側をクリックしたときにemitを送信する
        document.addEventListener('click', onClickOutside);
      }, 1);

      nextTick(() => {
        setBalloonPosition();
      });
    } else {
      resetAdjustStyle();
      // バルーン非表示時にスクロール処理を許可する
      document.removeEventListener('wheel', preventMouseWheelEvent);
      // バルーンの外側をクリックしたときのイベントを削除する
      document.removeEventListener('click', onClickOutside);
    }
  },
);

onUnmounted(() => {
  resetAdjustStyle();
  // 画面遷移等があった場合にイベントを確実に削除する
  document.removeEventListener('wheel', preventMouseWheelEvent);
  document.removeEventListener('click', onClickOutside);
});

/**
 * balloonのDOMの参照
 */
const actionBalloon = ref<HTMLElement>();
// balloonの親要素
const actionBalloonParent = computed(() => {
  if (!actionBalloon.value) return null;
  return (actionBalloon.value as HTMLElement).parentNode as HTMLElement;
});

/**
 * 位置調整用のスタイル値
 */
const adjustStyle = ref({
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
});
/**
 * 調整スタイルをリセットする
 * 再計算時の誤差を防ぐために非表示になるたび呼び出す
 */
const resetAdjustStyle = () => {
  adjustStyle.value = {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  };
};

/**
 * 表示位置調整処理
 * fixedの場合のみ有効
 * 上下左右へのはみ出しがあった場合、画面内に収まるように表示位置を調整する
 * */
const setBalloonPosition = () => {
  if (!actionBalloon.value) return;
  // fixedの場合のみ有効にする
  if (props.position !== 'fixed') return;

  const parentElement = actionBalloonParent.value;
  if (!parentElement) {
    throw new Error('Balloon parent element is not found');
  }
  const parentRect = parentElement.getBoundingClientRect();

  const balloonRect = (
    actionBalloon.value as HTMLElement
  ).getBoundingClientRect();

  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;

  // 下方向へのはみ出し
  if (balloonRect.bottom > windowHeight) {
    // 親要素の上辺に沿わせる
    adjustStyle.value.top = parentRect.top - balloonRect.height;
  } else {
    adjustStyle.value.top = 0;
  }
  // 上方向へのはみ出し
  if (balloonRect.top < 0) {
    // 親要素の下辺に沿わせる
    adjustStyle.value.top = parentRect.bottom;
  }
  // 右方向へのはみ出し
  if (balloonRect.right > windowWidth) {
    // 親要素の右片に沿わせる
    adjustStyle.value.left = parentRect.right - balloonRect.width;
  } else {
    adjustStyle.value.left = 0;
  }
  // 左方向へのはみ出し
  if (balloonRect.left < 0) {
    // 親要素の左片に沿わせる
    adjustStyle.value.left = parentRect.left;
  }
};
/** 表示位置指定 */
const style = computed(() => {
  let computedStyle = {
    position: props.position,
    top: '',
    bottom: '',
    left: '',
    right: '',
  };
  if (!actionBalloon.value) return {};
  const parentElement = actionBalloonParent.value;
  if (!parentElement) {
    throw new Error('Balloon parent element is not found');
  }
  const balloonRect = (
    actionBalloon.value as HTMLElement
  ).getBoundingClientRect();
  const parentRect = parentElement.getBoundingClientRect();

  // balloonPositionの値を分割
  let [vertical, horizontal] = props.balloonPosition.split(' ');
  // horizontalの値がないかつverticalがcenterの場合、horizontalにcenterを設定
  if (vertical === 'center' && !horizontal) {
    horizontal = 'center';
  }

  // fixedの場合は親要素の絶対位置を基準にする
  if (props.position === 'fixed') {
    switch (vertical) {
      case 'left':
        computedStyle.left = `${parentRect.left}px`;
        break;
      case 'right':
        computedStyle.left = `${parentRect.right - balloonRect.width}px`;
        break;
      case 'center':
        computedStyle.left = `${parentRect.left + parentRect.width / 2}px`;
        break;
      default:
        break;
    }
    switch (horizontal) {
      case 'top':
        computedStyle.top = `${parentRect.top}px`;
        break;
      case 'bottom':
        computedStyle.top = `${parentRect.top + parentRect.height}px`;
        break;
      case 'center':
        computedStyle.top = `${parentRect.top + parentRect.height / 2}px`;
        break;
      default:
        break;
    }
    // 調整スタイルの値が0でない場合は適用する
    if (adjustStyle.value.top) {
      computedStyle.top = `${adjustStyle.value.top}px`;
    }
    if (adjustStyle.value.left) {
      computedStyle.left = `${adjustStyle.value.left}px`;
    }
    if (adjustStyle.value.bottom) {
      computedStyle.bottom = `${adjustStyle.value.bottom}px`;
    }
    if (adjustStyle.value.right) {
      computedStyle.right = `${adjustStyle.value.right}px`;
    }
  }

  // relativeとabsoluteの場合は親要素の相対位置を基準にする
  if (props.position === 'relative' || props.position === 'absolute') {
    switch (vertical) {
      case 'left':
        computedStyle.left = '0';
        break;
      case 'right':
        computedStyle.right = '0';
        break;
      case 'center':
        computedStyle.left = `${
          parentRect.width / 2 - balloonRect.width / 2
        }px`;
        break;
      default:
        break;
    }
    switch (horizontal) {
      case 'top':
        computedStyle.top = '0';
        break;
      case 'bottom':
        computedStyle.top = `${parentRect.height}px`;
        break;
      case 'center':
        computedStyle.top = `${
          parentRect.height / 2 - balloonRect.height / 2
        }px`;
        break;
      default:
        break;
    }
  }
  return computedStyle;
});
</script>
<template>
  <div v-if="isShow" ref="actionBalloon" class="action-balloon" :style="style">
    <div class="action-balloon__content">
      <slot></slot>
    </div>
  </div>
</template>
<style scoped lang="scss">
.action-balloon {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0 1px 4px $color-gray400;
  border-radius: 4px;
  border: 1px solid $color-border;
  padding: 12px 0;
  z-index: var(--z-action-menu);
  width: fit-content;
  height: fit-content;
  max-height: 98vh;
  overflow-y: auto;
}
</style>
